import { Dialog, DialogContent, List, ListItem, ListSubheader } from "@material-ui/core";
import React from "react";
import eventList from "./eventList";
import {LocalizeConsumer, useLocalize} from "../shared/contexts/LocalizeContext";
import { useUserMetrics } from "@aetonix/user-metrics";

const BaseEventSelector = ({ open, onSelected = () => {}, onClose }) => {
	let listContent = [];
	const localize = useLocalize();
	const { userMetrics } = useUserMetrics();

	for (const category in eventList) {
		const section = <ListSubheader disableSticky={true}>{localize(`audit.${category}`) || category}</ListSubheader>;
		listContent.push(section);

		eventList[category].forEach(event => {
			const eventItem = (
				<ListItem button onClick={() => {
					onSelected(event);
					userMetrics.trackEvent("org-audit: filter by event", { event });
				}}>
					{localize(`audit.${event}`) || event}
				</ListItem>
			);
			listContent.push(eventItem);
		});
	}

	return (
		<Dialog open={open} onClose={onClose}>
			<DialogContent>
				<List>{listContent}</List>
			</DialogContent>
		</Dialog>
	);
};

const EventSelector = (props) => {
	return (
		<LocalizeConsumer>
			{() => (
				<BaseEventSelector {...props} />
			)}
		</LocalizeConsumer>
	);
};

export default EventSelector;
