/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

import {
	Button,
	Divider,
	ExpansionPanel,
	ExpansionPanelActions,
	ExpansionPanelDetails,
	ExpansionPanelSummary,
	ListItem,
	ListItemText,
	TextField,
	Typography
} from "@material-ui/1.5.1";
import React from "react";
import { useMemo } from "react";
import { useState } from "react";
import LazyList from "../shared/LazyList";
import Dialog from "../shared/Dialog";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import Immutable from "immutable";

const Prepopulate = ({ component, onChange, forms }) => {
	const [panelOpenMap, setPanelOpenMap] = useState(Immutable.Map());
	const [addNewOpen, setAddNewOpen] = useState(false);
	const [pendingDelete, setPendingDelete] = useState("");
	const [newSearchText, setNewSearchText] = useState("");

	var state = component.state;
	var localization = state.localization;
	var updating = state.updating;
	var creating = state.creating;
	var existingId = (updating && updating._id) || null;
	var currentPrepopulate = (updating ? updating.prepopulate : creating.prepopulate) || [];
	var errors = state.prepopulateError || {};
	var currentPerson = state.currentPerson;
	var person = currentPerson.get("personal");
	var language = person.language;

	// State methods
	const setPanelOpen = (key, val) => {
		component.state.userMetrics.trackEvent("org-forms: " + (val ? "expand" : "collapse") + " prepopulate form", {
			"form": key
		});
		setPanelOpenMap(panelOpenMap.set(key, val));
	};

	const collapseAllPanels = () => {
		component.state.userMetrics.trackEvent("org-forms: collapse all prepopulate forms");
		setPanelOpenMap(panelOpenMap.clear());
	};

	const expandAllPanels = () => {
		component.state.userMetrics.trackEvent("org-forms: expand all prepopulate forms");
		setPanelOpenMap(Immutable.Map(currentPrepopulate.map(external => [external.formId, true])));
	};

	const togglePanelOpen = key => {
		return () => {
			component.state.userMetrics.trackEvent("org-forms: " + (panelOpenMap.get(key) ? "collapse" : "expand") + " prepopulate form", {
				"form": key
			});
			setPanelOpenMap(panelOpenMap.set(key, !panelOpenMap.get(key)));
		};
	};

	const closeDeleteConfirmation = () => {
		component.state.userMetrics.trackEvent("org-forms: close remove prepopulate form confirmation popup", {
			"form": pendingDelete
		});
		setPendingDelete("");
	};

	// Component State Methods
	const addPrepopulateForm = formId => {
		let newState = [
			...currentPrepopulate,
			{
				formId: formId,
				formMapText: "[]"
			}
		];
		onChange(newState);
	};

	const removePrepopulateForm = formId => {
		let newState = [...currentPrepopulate];
		let index = newState.findIndex(external => external.formId === formId);
		newState.splice(index, 1);

		onChange(newState);
		setPanelOpen(formId, false);
	};

	const onMapChangeFactory = formId => {
		return e => {
			let text = e.target.value;
			let newState = [...currentPrepopulate];
			let index = newState.findIndex(external => external.formId === formId);
			let newObj = { ...newState[index], formMapText: text };

			newState[index] = newObj;
			onChange(newState);
		};
	};

	const formNameMap = new Map(
		forms.map(form => {
			return [form._id, form.localization.en || form.localization];
		})
	);

	const getFormName = form => {
		var formLocalization = form.localization;
		var name = formLocalization[language] || formLocalization[Object.keys(formLocalization)[0]];
		return name;
	};

	const filteredForms = useMemo(() => {
		const searchStr = newSearchText.toLowerCase().replaceAll(" ", "");
		const existingPrepopulateSet = new Set(currentPrepopulate.map(external => external.formId));

		return forms.filter(form => {
			const formName = getFormName(form)
				.toLowerCase()
				.replaceAll(" ", "");
			return existingId !== form._id && formName.includes(searchStr) && !existingPrepopulateSet.has(form._id);
		});
	}, [newSearchText, forms, currentPrepopulate]);

	const renderFormListItem = form => {
		var name = getFormName(form);
		return (
			<ListItem button key={form._id} onClick={() => {
				component.state.userMetrics.trackEvent("org-forms: add prepopulate form", {
					"form": form._id
				});
				addPrepopulateForm(form._id);
			}}>
				<ListItemText primary={name} />
			</ListItem>
		);
	};

	const addNewActions = [
		(<Button key={"button_back"} onClick={() => {
			setAddNewOpen(false);
			component.state.userMetrics.trackEvent("org-forms: close add prepopulate form popup");
		}}>
			{localization.get("common.back")}
		</Button>)
	];

	const deleteActions = [
		(<Button
			key={"button_confirm"}
			onClick={() => {
				component.state.userMetrics.trackEvent("org-forms: remove prepopulate form", {
					"form": pendingDelete
				});
				removePrepopulateForm(pendingDelete);
				closeDeleteConfirmation();
			}}
		>
			{localization.get("common.confirm")}
		</Button>),
		(<Button key={"button_cancel"} onClick={closeDeleteConfirmation}>{localization.get("common.cancel")}</Button>)
	];

	return (
		<div style={{ paddingTop: 16 }}>
			<div style={{ position: "sticky", top: 8, display: "flex", zIndex: 100, gap: 8 }}>
				<div style={{ flex: 2 }}>
					<Button fullWidth variant="contained" onClick={() => {
						component.state.userMetrics.trackEvent("org-forms: open add prepopulate form popup");
						setAddNewOpen(true);
					}}>
						{localization.get("common.addnew")}
					</Button>
				</div>
				<div style={{ flex: 1 }}>
					<Button
						fullWidth
						variant="contained"
						onClick={expandAllPanels}
						disabled={currentPrepopulate.length === 0}
					>
						{localization.get("expansion_panel.expand_all")}
					</Button>
				</div>
				<div style={{ flex: 1 }}>
					<Button
						fullWidth
						variant="contained"
						onClick={collapseAllPanels}
						disabled={currentPrepopulate.length === 0}
					>
						{localization.get("expansion_panel.collapse_all")}
					</Button>
				</div>
			</div>
			<div style={{ marginTop: 8, marginBottom: 8 }}>
				{currentPrepopulate.map(external => {
					const formId = external.formId;
					const hasError = errors[formId];
					return (
						<ExpansionPanel
							key={formId}
							expanded={!!panelOpenMap.get(formId)}
							onChange={togglePanelOpen(formId)}
						>
							<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
								<Typography color={hasError ? "error" : undefined}>
									{formNameMap.get(formId)}
								</Typography>
							</ExpansionPanelSummary>
							<ExpansionPanelDetails>
								<TextField
									error={hasError}
									helperText={hasError && localization.get("org_form_schemaerror")}
									rows={12}
									rowsMax={12}
									multiline
									onChange={onMapChangeFactory(formId)}
									value={external.formMapText}
									fullWidth
								/>
							</ExpansionPanelDetails>
							<Divider />
							<ExpansionPanelActions>
								<Button size="small" onClick={() => {
									component.state.userMetrics.trackEvent("org-forms: open remove prepopulate form confirmation popup", {
										"form": formId
									});
									setPendingDelete(formId);
								}}>
									{localization.get("common.delete")}
								</Button>
								<Button size="small" onClick={togglePanelOpen(formId)}>
									{localization.get("expansion_panel.collapse")}
								</Button>
							</ExpansionPanelActions>
						</ExpansionPanel>
					);
				})}
			</div>
			<Dialog actions={addNewActions} open={!!addNewOpen} title={localization.get("org_form_prepopulate_addNewTitle")} onClose={() => setAddNewOpen(false)}>
				<TextField
					value={newSearchText}
					onChange={e => {
						setNewSearchText(e.target.value);
					}}
					fullWidth
					placeholder={localization.get("search.start_typing")}
				/>
				<LazyList loadmore={() => {}} items={filteredForms} renderItem={renderFormListItem}></LazyList>
				{filteredForms.length === 0 && (
					<ListItem>
						<ListItemText style={{ textAlign: "center" }}>
							{localization.get("search.no_result")}
						</ListItemText>
					</ListItem>
				)}
			</Dialog>
			<Dialog actions={deleteActions} open={!!pendingDelete} title={localization.get("org_form_prepopulate_deleteTitle")} onClose={closeDeleteConfirmation}>
				{localization.get("org_form_prepopulate_deleteText")}
				{formNameMap.get(pendingDelete)}
				?
			</Dialog>
		</div>
	);
};

export default Prepopulate;
