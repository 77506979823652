import {
	Avatar,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	TextField
} from "@material-ui/1.5.1";
import React, { useMemo, useState } from "react";
import { useLocalize } from "../shared/contexts/LocalizeContext";
import RemoveIcon from "@material-ui/icons/Remove";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { useResources } from "../shared/contexts/ResourceContext";
import Utility from "../shared/Utility";
import ReactList from "react-list";
import { useUserMetrics } from "@aetonix/user-metrics";

const PeopleSelector = ({
	personsList = [], //[{id:string, title:string}]
	selectedList = [], //list
	onPersonSelect = () => {},
	onPersonRemove = () => {},
	onPersonSearch = () => {},
	idToUser = () => {},
	onSelectAll,
	onDeselectAll,
	people,
}) => {
	const [personSelectorOpen, setPersonSelector] = useState(false);
	const [personSearchText, setPersonSearchText] = useState("");
	const localize = useLocalize();
	const { userMetrics } = useUserMetrics();

	// Main

	// Person Selector
	const openPersonSelector = () => {
		setPersonSelector(true);
		userMetrics.trackEvent("user-management: open person selector in bulk edit");
	};

	const closePersonSelector = () => {
		setPersonSelector(false);
		userMetrics.trackEvent("user-management: close person selector popup in bulk edit");
	};

	const selectedSet = useMemo(() => {
		return new Set(selectedList.map(userId => userId));
	}, [selectedList]);
	const renderPersonsListItem = person => {
		let id = personsList[person];
		return (
			<PeopleListItem
				user={idToUser(id)}
				disabled={selectedSet.has(id)}
				onClick={() => {
					userMetrics.trackEvent("user-management: select person in bulk edit", {
						person: id
					});
					return onPersonSelect(id);
				}}
				key={id}
			></PeopleListItem>
		);
	};

	const sortedSelectedList = selectedList.map(people.get).sort(by_name).map(({_id}) => _id);

	function by_name(x, y) {
		var X = x?.lname?.toLowerCase();
		var Y = y?.lname?.toLowerCase();
		if (X === Y) {
			X = x.fname.toLowerCase();
			Y = y.fname.toLowerCase();
		}
		return order(X, Y);
	}

	function order(X, Y) {
		if (X < Y)
			return -1;
		if (X > Y)
			return 1;
		return 0;
	}

	return (
		<List>
			<ListItem button aria-label={localize("common.addnew")}  onClick={openPersonSelector} style={{ justifyContent: "center" }}>
				<AddCircleOutlineIcon></AddCircleOutlineIcon>
			</ListItem>
			{sortedSelectedList.map(id => {
				return (
					<PeopleListItem
						user={idToUser(id)}
						onRemove={() => {
							onPersonRemove(id);
							userMetrics.trackEvent("user-management: remove person in bulk edit", {
								person: id
							});
						}}
						key={id}
					></PeopleListItem>
				);
			})}

			<Dialog
				fullWidth
				maxWidth="sm"
				open={personSelectorOpen}
				onClose={closePersonSelector}
				PaperProps={{ style: { height: "80%" } }}
			>
				<DialogContent>
					<TextField
						value={personSearchText}
						onChange={e => {
							const text = e.target.value;
							setPersonSearchText(text);
							onPersonSearch(text);
						}}
						fullWidth
						placeholder={localize("search.start_typing")}
					></TextField>

					{onSelectAll && (
						<Button variant="raised" style={{ margin: "8px 0", color: "white" }} onClick={onSelectAll}>
							{localize("common.selectall")}
						</Button>
					)}
					{onDeselectAll && (
						<Button variant="raised" style={{ margin: "8px", color: "white"}} onClick={onDeselectAll}>
							{localize("common.unselectall")}
						</Button>
					)}
					<List>
						<ReactList itemRenderer={renderPersonsListItem} length={personsList.length} type="uniform" />
					</List>
				</DialogContent>
				<DialogActions>
					<Button onClick={closePersonSelector}>{localize("common.back")}</Button>
				</DialogActions>
			</Dialog>
		</List>
	);
};

const PeopleListItem = ({ user, onRemove, onClick, disabled }) => {
	const userId = user._id;
	const resources = useResources();
	const config = resources.config;

	const avatar = <Avatar alt='User Avatar Image' src={config.image_cdn + user.image} />;
	const text = Utility.format_name(user);

	var button = (
		<ListItemSecondaryAction>
			{onRemove && (
				<IconButton onClick={onRemove}>
					<RemoveIcon></RemoveIcon>
				</IconButton>
			)}
		</ListItemSecondaryAction>
	);
	return (
		<ListItem key={userId} onClick={onClick} disabled={disabled} button={!!onClick}>
			{avatar}
			<ListItemText primary={text} />
			{button}
		</ListItem>
	);
};
export default PeopleSelector;
