/* © 2022 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, April 20, 2022
 * For information or permission request, email info@aetonixsystems.com
 */

import par from "par";

import config from "../../configs/config.json";
import React from "react";
import Dialog from "../shared/Dialog";
import List from "@material-ui/1.5.1/List";
import ListItem from "@material-ui/1.5.1/ListItem";
import ListItemText from "@material-ui/1.5.1/ListItemText";
import TextField from "@material-ui/1.5.1/TextField";
import Avatar from "@material-ui/1.5.1/Avatar";
import Button from "@material-ui/1.5.1/Button";
import Select from "@material-ui/1.5.1/Select";
import FormControl from "@material-ui/1.5.1/FormControl";
import InputLabel from "@material-ui/1.5.1/InputLabel";
import MenuItem from "@material-ui/1.5.1/MenuItem";
import CircularProgress from "@material-ui/1.5.1/CircularProgress";
import Utility from "../shared/Utility.js";
import  AetonixTheme_mui_1_5_1 from "../shared/AetonixTheme_mui_1.5.1";
const Colors = AetonixTheme_mui_1_5_1.palette;

import { useUserMetrics } from "@aetonix/user-metrics";
import * as Sentry from "@sentry/react";
import { useEffect, useState } from "react";

const styles = {
	loaderContainer: {
		display: "flex",
		justifyContent: "center"
	}
};

export const AddManagerModal = ({
	addManagers,
	search,
	getPresets,
	localization,
	dismissModal,
	existingManagerIDs
}) => {
	const [stillLoadingPresets, setLoadingPresets] = useState(true);
	const [chosenManagers, setChosenManagers] = useState([]);
	const [searchResults, setSearchResults] = useState([]);
	const [presetsList, setPresetsList] = useState([]);
	const [chosenPresetID, setChosenPresetID] = useState(undefined);
	const { userMetrics } = useUserMetrics();

	// useEffect with [] so that we only grab the presets once
	useEffect(() => {
		async function fetchPresets() {
			const presets = await getPresets();
			setPresetsList(presets);
			setLoadingPresets(false);
		}
		fetchPresets();
	}, []);

	const chosenManagerIDs = chosenManagers.map(manager => manager._id);
	const unchoosableManagers = chosenManagerIDs.concat(existingManagerIDs || []); // These will be greyed out in the list

	const submit = () => {
		if (chosenManagerIDs.length) {
			const chosenPresetPermissions = presetsList.reduce(
				(acc, preset) => preset._id === chosenPresetID ? preset.permissions : acc,
				undefined);
			addManagers(chosenManagerIDs, chosenPresetPermissions);
		}
		dismissModal();
		userMetrics.trackEvent("user-management: add manager");
	};

	const doSearch = async e => {
		try {
			const query = e.target.value;
			const results = await search(query);
			setSearchResults(results);
		} catch (error) {
			Sentry.captureException(error);
			console.error.bind(console, error);
		}
	};

	const selectManager = manager => {
		userMetrics.trackEvent("user-management: select manager", {
			manager: manager._id
		});
		return setChosenManagers(chosenManagers.concat(manager));
	};

	const deselectManager = manager => {
		userMetrics.trackEvent("user-management: deselect manager", {
			manager: manager._id
		});
		setChosenManagers(chosenManagers.filter(chosenManager => chosenManager._id !== manager._id));
	};

	var actions = [
		<Button key={"add_manager_modal_invite"} onClick={submit}>
			{localization.get("add_manager_modal_invite")}
		</Button>,
		<Button key={"search_close"} onClick={dismissModal}>
			{localization.get("search_close")}
		</Button>
	];

	return (
		<Dialog actions={actions} open title={localization.get("search_displayname")}>
			<TextField InputProps={{"aria-label": localization.get("search_name")}} placeholder={localization.get("search_name")} onChange={doSearch} />
			<List>{searchResults.sort(by_name).map(par(renderItem, selectManager, unchoosableManagers))}</List>
			{chosenManagers.length ? (
				<List subheader={localization.get("add_manager_modal_chosen_managers")}>
					{chosenManagers.map(par(renderItem, deselectManager, []))}
				</List>
			) : (
				undefined
			)}
			<FormControl fullWidth>
				<InputLabel shrink={chosenPresetID}>{localization.get("add_manager_modal_choose_preset")}</InputLabel>
				<Select onChange={e => setChosenPresetID(e.target.value)} inputProps={{"aria-label": localization.get("add_manager_modal_choose_preset")}} value={chosenPresetID}>
					{stillLoadingPresets ? (
						<div style={styles.loaderContainer}>
							<CircularProgress mode="indeterminate" size={40} />
						</div>
					) : (
						renderPresets(presetsList)
					)}
				</Select>
			</FormControl>
		</Dialog>
	);
};

function renderItem(onSelect, unchoosableManagers, manager) {
	var disabled = false;
	var avatar = <Avatar src={config.image_cdn + manager.image} />;
	var text = Utility.format_name(manager);

	var personInfo = {
		fname: manager.fname,
		lname: manager.lname
	};
	if (manager.patientNumber) {
		personInfo.patientNumber = manager.patientNumber;
	}

	var style = {
		backgroundColor: "transparent"
	};

	if (unchoosableManagers.length && unchoosableManagers.indexOf(manager._id) >= 0) {
		disabled = true;
		style.backgroundColor = Colors.primary.light;
	}
	return (
		<ListItem key={manager._id} button disabled={disabled} onClick={par(onSelect, manager)} style={style}>
			{avatar}
			<ListItemText>{text}</ListItemText>
		</ListItem>
	);
}

function renderPresets(presetsList) {
	return presetsList.map(preset => (
		<MenuItem key={preset._id} value={preset._id}>
			{preset.name}
		</MenuItem>
	));
}

function by_name(x, y) {
	var X = x.lname?.toLowerCase();
	var Y = y.lname?.toLowerCase();
	if (X === Y && x.fname && y.fname) {
		X = x.fname.toLowerCase();
		Y = y.fname.toLowerCase();
	}
	if (X === Y && x._id && y._id) {
		X = x._id.toLowerCase();
		Y = y._id.toLowerCase();
	}

	if (X < Y) return -1;
	if (X > Y) return 1;
	return 0;
}
