/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, August 1, 2021
 * For information or permission request, email info@aetonixsystems.com
 */


import React from "react";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";


export default function VerticalTabs(props) {
	const [value, setValue] = React.useState(0);

	const { tabsDetails, onChangeHealthIndicator } = props;

	const handleChange = (event, newValue) => {
		setValue(newValue);
		onChangeHealthIndicator(newValue);
	};

	const tabLabels = tabsDetails ? (
		tabsDetails.map((tab) => {
			const tabLabel = tab.label;
			const tabIndex = tab.index;
			return <Tab
				className={value === tabIndex ? "ae-hover-color" : "ae-plain"}
				label={tabLabel}
				id={`health-indicator-vertical-tab-${tabIndex}`}
				test-id={`${tabLabel}_tab`}
				key={`health-indicator-vertical-tab-${tabIndex}`}
				aria-controls={`health-indicator-vertical-tabpanel-${tabIndex}`}
			/>;
		})
	) : null;

	const tabPanels = tabsDetails ? (
		tabsDetails.map((tab) => {
			const tabIndex = tab.index;
			return (
				<TabPanel key={`health-indicator-vertical-tabpanel-${tabIndex}`} value={value} index={tabIndex}>
					{tab.panel}
				</TabPanel>
			);
		})
	) : null;

	return (
		<div className="flex-horizontal flex-1 ae-scrollable">
			<div className="flex-0-auto table-margin">
				<Tabs
					orientation="vertical"
					variant="scrollable"
					value={value}
					onChange={handleChange}
					aria-label="Health indicator vertical tabs"
				>
					{tabLabels}
				</Tabs>
			</div>
			<div className="flex-4 min-width-0">
				{tabPanels}
			</div>
		</div>
	);
}


function TabPanel(props) {
	const { children, value, index, ...rest } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`health-indicator-vertical-tabpanel-${index}`}
			aria-labelledby={`health-indicator-vertical-tab-${index}`}
			{...rest}
		>
			{value === index && (
				<Box p={3}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired
};