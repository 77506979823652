

import Store from "./store";

import * as Sentry from "@sentry/react";

export default OrgAutoLogout;

function OrgAutoLogout(api, events, config) {
	var store = new Store;

	api.org.managers.autologout.get()
		.then(handle_get_autologout)
		.then(listen)
		.catch(error => {
			Sentry.captureException(error);
			console.error(error);
		});

	return store;

	function listen(){
		var organization = config.organization;
		if (!organization) return;
		events.on("mqtt:org/" + organization + "/autologout", handle_set_autologout);
	}

	function handle_get_autologout(autologout){
		store.set("autologout", autologout);
	}

	function handle_set_autologout(autologout){
		store.set("autologout", autologout);
	}
}
