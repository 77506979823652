/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

import par from "par";

import defineUI from "../shared/define";
import render from "./render.jsx";

/**
 * Stores required for dashboard state
 */
import GroupCallStore from "../stores/org_group_call_store";

import PersonStore from "../stores/person_store";
import LocalizationStore from "../stores/localization_store";
import CurrentPersonInfoStore from "../stores/current_person_info_store";
import CareplanNotificationStore from "../stores/careplan_notice_store";
import ConnectionStore from "../stores/connection_store";
import makeStoreFactory from "../shared/stores";

const makeStores = makeStoreFactory({
	people: PersonStore,
	localization: LocalizationStore,
	currentPerson: CurrentPersonInfoStore,
	calls: GroupCallStore,
	careplanChangeList: CareplanNotificationStore,
	connection: ConnectionStore
});

function make_ui(api, events, config, userMetrics) {

	var storeStore = makeStores(api, events, config);

	window.stores = storeStore.store;

	userMetrics.trackEvent("page view", {
		"page": "group-calls",
		"category": "history",
		"permission level": "staff",
	});

	return defineUI({
		getInitialState: getInitialState,
		componentDidMount: listen_for_changes,
		render: render,
		componentWillUnmount: removeListeners
	});

	function removeListeners() {
		events.off();
	}

	function listen_for_changes() {
		storeStore.listen(par(handle_change, this));
	}

	function handle_change(component, stores) {
		component.setState({
			calls: stores.calls.sorted(),
			people: stores.people,
			currentPerson: stores.currentPerson,
			localization: stores.localization,
			careplanChangeList: stores.careplanChangeList,
			connection: stores.connection
		});
	}

	function getInitialState() {
		return {
			calls: storeStore.stores.calls.sorted(),
			people: storeStore.stores.people,
			currentPerson: storeStore.stores.currentPerson,
			localization: storeStore.stores.localization,
			more: storeStore.stores.calls.more,
			filterCallers: filterCallers,
			filterRecipients: filterRecipients,
			filterClear: filterClear,
			filterDate: filterDate,
			closeCallerSearch: par(closeCallerSearch, this),
			closeRecipientSearch: par(closeRecipientSearch, this),
			searchCaller: searchCaller,
			searchRecipient: searchRecipient,
			report: false,
			callersFilter: false,
			recipientsFilter: false,
			statusFilter: false,
			dateFilter: false,
			callers: [],
			recipients: [],
			callstatus: "",
			startDate: "",
			endDate: new Date(),
			fileName: "",
			careplanChangeList: storeStore.stores.careplanChangeList,
			connection: storeStore.stores.connection,
			userMetrics: userMetrics
		};
	}

	/**
	 * The first function returns a list of the group's users while the second
	 * is a list of _any_ person in the system matching the criteria
	 * @param  {objectId} query User_id
	 * @return {array}       User _id's
	 */
	function searchCaller(query) {
		return api.people.search(query);
	}
	function searchRecipient(query) {
		return api.people.search(query);
	}

	function filterCallers(component, data) {
		var callers = component.state.callers;
		component.setState({
			callers: callers.concat(data)
		});
		storeStore.stores.calls.searchCallers(data);
		userMetrics.trackEvent("group-calls: filter callers", {
			callers
		});
	}

	function filterRecipients(component, data) {
		var recipients = component.state.recipients;
		component.setState({
			recipients: recipients.concat(data)
		});
		storeStore.stores.calls.searchRecipients(data);
		userMetrics.trackEvent("group-calls: filter recipients", {
			recipients
		});
	}

	function filterDate(start, end) {
		storeStore.stores.calls.searchDate(start, end);
	}

	function filterClear(name) {
		storeStore.stores.calls.clearSearch(name);
		const eventText = {
			callers: "group-calls: clear callers filter popup",
			recipients: "group-calls: clear recipients filter popup",
			date: "group-calls: clear date filter popup",
		}[name] || "group-calls: clear filter popup";
		userMetrics.trackEvent(eventText, {
			"filter": name
		});
	}

	function closeCallerSearch(component) {
		component.setState({
			callersFilter: false
		});
		userMetrics.trackEvent("group-calls: close caller filter popup");
	}

	function closeRecipientSearch(component) {
		component.setState({
			recipientsFilter: false
		});
		userMetrics.trackEvent("group-calls: close recipient filter popup");
	}

}

export default make_ui;
