import React, { useState } from "react";
import ConfirmationDialog from "../ConfirmationDialog";

export const GlobalAlertContext = React.createContext({
	/**
	 * @param {AlertConfigInitialState} config
	 */
	show: () => {},
	close: () => {}
});

export const useGlobalAlert = () => React.useContext(GlobalAlertContext);

const AlertConfigInitialState = {
	affirmativeAction: () => {},
	affirmativeText: undefined,
	negativeAction: () => {},
	negativeText: undefined,

	title: undefined,
	description: undefined,

	onClose: () => {},
	maxWidth: undefined,
	fullWidth: undefined
};
const GlobalAlert = props => {
	const [alertConfig, setAlertConfig] = useState(AlertConfigInitialState);
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);

	const handleClose = () => {
		setOpen(false);
		setAlertConfig(AlertConfigInitialState);
		setLoading(false);
	};

	const startLoading = () => setLoading(true);
	const endLoading = () => setLoading(false);

	/**
	 * @param {AlertConfigInitialState} config
	 */
	const handleShow = config => {
		const wrappedAffirmAction = runIfShouldClose(config.affirmativeAction, handleClose, startLoading, endLoading);
		const wrappedNegativeAction = runIfShouldClose(config.negativeAction, handleClose, startLoading, endLoading);

		const finalConfig = {
			...AlertConfigInitialState,
			...config,
			affirmativeAction: wrappedAffirmAction,
			negativeAction: wrappedNegativeAction
		};
		setAlertConfig(finalConfig);
		setOpen(true);
	};

	return (
		<GlobalAlertContext.Provider
			value={{
				show: handleShow,
				close: handleClose
			}}
		>
			<ConfirmationDialog
				{...alertConfig}
				open={open}
				onClose={handleClose}
				affirmativeDisabled={loading}
				negativeDisabled={loading}
			/>
			{props.children}
		</GlobalAlertContext.Provider>
	);
};

const runIfShouldClose = (fn, onClose, startLoading, endLoading) => {
	return () => {
		let val;
		if (typeof fn === "function") {
			startLoading();
			val = fn();
		}
		return Promise.resolve(val)
			.then(res => {
				if (res !== false) return onClose();
			})
			.finally(() => {
				endLoading();
			});
	};
};

export default GlobalAlert;
