import ErrorBar from "./ErrorBar";
import GlobalAlert from "./GlobalAlert";
import "./shared.css";
import "./polyfills.js";
import * as Sentry from "@sentry/react";

import { localizationMap } from "../localization";

import {
	RoutesContext,
	ResourcesContext,
	ConfigContext,
	CurrentPersonProvider,
	LocalizeProvider,
	ConnectionProvider,
	PeopleProvider,
	useCurrentPerson
} from "@aetonix/hooks";

import React from "react";
var Component = React.Component;

import * as ReactRouterDom from "react-router-dom";
var BrowserRouter = ReactRouterDom.BrowserRouter;
var Route = ReactRouterDom.Route;
import AetonixTheme from "./AetonixTheme.js";
import routesFactory from "../dashboards/routes";
const routes = routesFactory();
import CircularProgress from "@material-ui/1.5.1/CircularProgress";
import { StylesProvider } from "@material-ui/styles";
import { createGenerateClassName } from "@material-ui/1.5.1";
import Button from "@material-ui/1.5.1/Button";
import { ThemeProvider } from "@material-ui/core/styles";
import { scrubBreadcrumbs, scrubEvents } from "./sentry";
import { useUserMetrics } from "@aetonix/user-metrics";

// eslint-disable-next-line node/no-unpublished-import
import config from "../../configs/config.json";

let environment = "local";
if(config.type === "dev")
	environment = "development";
else if (config.type === "prod")
	environment = "production";

Sentry.init({
	dsn: "https://0c4cca7d1ce249cba36db5a6ce21fa97@o951867.ingest.sentry.io/5966183",
	tracesSampleRate: 1.0,
	/* integrations: [new TracingIntegrations.BrowserTracing()], */ // @todo removed due to the inability to scrum the user's token from transactions
	beforeBreadcrumb: scrubBreadcrumbs,
	beforeSend: scrubEvents,
	environment: environment,
});

var styles = {
	loader: {
		minWidth: "100%",
		minHeight: "100%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		marginTop: "30%"
	},
	container: {
		display: "flex",
		height: "100%"
	}
};

class RootErrorBoundary extends Component {
	constructor(props) {
		super(props);

		this.state = { error: null, errorInfo: null };
	}

	componentDidCatch(error, errorInfo) {
		this.setState({ error, errorInfo }, () => {
			Sentry.captureException(error);
		});
	}

	render() {
		const { error, errorInfo } = this.state;
		const { children } = this.props;

		if (errorInfo) {
			return (
				<div style={{ margin: "20px" }}>
					<h2>Something went wrong.</h2>
					<details style={{ whiteSpace: "pre-wrap" }}>
						{error && error.toString()}
						<br />
						{errorInfo.componentStack}
					</details>
					<Button
						style={{ marginTop: "10px" }}
						variant="raised"
						onClick={() => window.location.reload()}
						color="secondary"
					>
						Refresh
					</Button>
				</div>
			);
		}

		return children;
	}
}

const Landing = () => {
	const [loading, setLoading] = React.useState(true);
	const [resources, setResources] = React.useState(null);
	const { userMetrics, Identify } = useUserMetrics();

	React.useEffect(() => {
		const loadResources = async () => {
			const importedResources = await (await import("./resources")).default;
			setResources(importedResources);
			setLoading(false);
		};
		loadResources();
	}, []);

	React.useEffect(() => {
		if (resources && resources.config) {
			try {
				const identity = new Identify();
				userMetrics.setUserId(resources.config.auth._id);
				identity
					.set("_id", resources.config.auth._id)
					.set("user id", resources.config.auth._id)
					.set("type", resources.config.auth.type)
					.set("country", resources.config.country)
					.set("province", resources.config.province)
					.set("token", resources.config.token)
					.set("org group", resources.config.orgGroup)
					.set("organization", resources.config.organization)
					.set("build", resources.config.build)
					.set("stage", resources.config.type)
					.set("version", resources.config.version)
					.set("region", resources.config.type);
        
				resources.config.orgGroup && userMetrics.assignUserToGroup("orgGroup", resources.config.orgGroup);
				resources.config.organization && userMetrics.assignUserToGroup("organization", resources.config.organization);
				userMetrics.identifyUser(identity);
			} catch (err) {
				Sentry.captureException(err);
				console.error(err);
			}
		}
	}, [resources]);

	if (loading) {
		return (
			<div style={styles.loader}>
				<CircularProgress mode="indeterminate" size={40} />
			</div>
		);
	}

	return (
		<BrowserRouter>
			<ResourcesContext.Provider value={resources}>
				<ConfigContext.Provider value={config}>
					<RoutesContext.Provider value={routes}>
						<ConnectionProvider>
							<CurrentPersonProvider>
								<LocalizeProvider map={localizationMap}>
									<PeopleProvider>
										<div key={"topcontainer"} style={styles.container}>
											<Routes />
										</div>
									</PeopleProvider>
								</LocalizeProvider>
							</CurrentPersonProvider>
						</ConnectionProvider>
					</RoutesContext.Provider>
				</ConfigContext.Provider>
			</ResourcesContext.Provider>
		</BrowserRouter>
	);
};

const LandingWithProfiler = Sentry.withProfiler(Landing);

export default function makeLanding() {
	var loginPage = (
		<StylesProvider generateClassName={generateClassName}>
			<ThemeProvider theme={AetonixTheme}>
				<RootErrorBoundary>
					<ErrorBar>
						<GlobalAlert>
							<LandingWithProfiler />
						</GlobalAlert>
					</ErrorBar>
				</RootErrorBoundary>
			</ThemeProvider>
		</StylesProvider>
	);

	return loginPage;
};

const generateClassName = createGenerateClassName({
	productionPrefix: "m4jss"
});


function Routes(){
	const currentPerson = useCurrentPerson();

	if(!currentPerson || !currentPerson.personal || !currentPerson.personal.type) return null;

	const type = currentPerson?.personal?.type;

	let useableRoutes = [...routes.notifications, ...routes.dashboard];

	if(type.includes("staff")) useableRoutes = useableRoutes.concat(routes.staff);
	if(type.includes("org:manager")) useableRoutes = useableRoutes.concat(routes["org:manager"]);
	if(type.includes("admin")) useableRoutes = useableRoutes.concat(routes.admin);
	if(type.includes("logistics")) useableRoutes = useableRoutes.concat(routes.logistics);

	const renderedRoutes = useableRoutes.map(function(routeData) {
		var RouteComponent = routeData.component;
		var route = routeData.url;
		if (routeData.type == "dashboard") {
			route = "/";
			return <Route key={"root path"} exact path={route} component={RouteComponent} />;
		}
		return <Route key={route} path={route} component={RouteComponent} />;
	});

	return renderedRoutes;
}