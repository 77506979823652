
/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, May 3, 2018
 * For information or permission request, email info@aetonixsystems.com
 */
// eslint-disable-next-line no-redeclare
/* global Blob */

import * as Sentry from "@sentry/react";

import React from "react";
import par from "par";
import xtend from "xtend";
import escape_regex from "escape-regexp";
import Header from "../shared/Header.js";
import SubHeader from "@material-ui/1.5.1/ListSubheader";
import IconButton from "@material-ui/1.5.1/IconButton";
import TextField from "@material-ui/1.5.1/TextField";
import ListItem from "@material-ui/1.5.1/ListItem";
import ListItemText from "@material-ui/1.5.1/ListItemText";
import Dialog from "../shared/Dialog";
import Button from "@material-ui/1.5.1/Button";
import Tabs from "@material-ui/1.5.1/Tabs";
import Tab from "@material-ui/1.5.1/Tab";
import FontIcon from "@material-ui/1.5.1/Icon";
import Select from "@material-ui/1.5.1/Select";
import MenuItem from "@material-ui/1.5.1/MenuItem";
import InputLabel from "@material-ui/1.5.1/InputLabel";
import FormControl from "@material-ui/1.5.1/FormControl";
import FormHelperText from "@material-ui/1.5.1/FormHelperText";
import Snackbar from "@material-ui/1.5.1/Snackbar";
import LazyList from "../shared/LazyList.jsx";
import  AetonixTheme_mui_1_5_1 from "../shared/AetonixTheme_mui_1.5.1";
const Colors = AetonixTheme_mui_1_5_1.palette;

import Markdown from "ae-base/Base/Markdown";

var styles = {
	search: {
		color: Colors.primary.main,
		minWidth: "30%",
		padding: "8px"
	},
	input: {
		color: Colors.primary.main,
		paddingBottom: "8px"
	},
	icon: {
		color: Colors.primary.main
	},
	menuItemIcon: {
		color: Colors.primary.main,
		marginRight: "8px"
	},
	inputlabel: {
		color: "black"
	},
	formControlStyle: {
		marginTop: "8px",
		marginBottom: "8px"
	},
	inputProp: {
		style: {
			color: Colors.primary.main,
		}
	},
	noMatch: {
		padding: "1em",
		display: "inherit",
		justifyContent: "center",
		color: Colors.primary.main
	},
	toast: {
		margin: "100px",
		padding: "10px",
		backgroundColor: "rgb(0, 172, 193)", //rgb(0, 199, 26)
		borderRadius: "10px"
	},
	inProgressToast: {
		margin: "100px",
		padding: "10px",
		backgroundColor: "white",
		borderRadius: "10px"
	},
	inProgressToastText: {
		color: "black",
		margin: "5px"
	},
	toastText: {
		color: Colors.canvas,
		margin: "5px"
	},
	snackbar: {style: {backgroundColor: "red"}}
};

var iconButtonClasses = {
	label: "ae-widthauto"
};

var listItemClasses = {
	default: "ae-padding",
	gutters: "ae-padding",
	root: "ae-padding"
};

export default render;

function render() {
	var component = this;
	var state = component.state;
	var toRemove = state.toRemove;

	var orgs = state.orgs;
	var allOrgs = state.allOrgs;
	var updating = state.updating;
	var creating = state.creating;
	var localization = state.localization;
	var careplanNoticeList = state.careplanChangeList.all();
	var careplanChanges = !!careplanNoticeList.length;
	var offline = state.connection.get("offline");
	var currentPerson = state.currentPerson;
	var tabValue = state.tabValue;

	var sortedOrgs = orgs.sort(by_name);
	var loadMore = state.loadMore;
	var searchOrg = state.searchOrg;
	const { dialogErrors } = state;

	var currentLanguage = state.language;

	var resources = [];
	var viewing = state.viewing;
	var isDuplicating = state.isDuplicating;
	var isMissingDestination = state.isMissingDestination;

	var creatingName = creating.name || {};
	var creatingData = creating.data || {};

	var updatingName = updating.name || {};
	var updatingData = updating.data || {};

	var currentName = updating ? updatingName[currentLanguage] || "" : creatingName[currentLanguage] || "";
	var currentData = updating ? updatingData[currentLanguage] || "" : creatingData[currentLanguage] || "";

	var dialogTitle = updating ? currentName : localization.get("org_resource_newresource");

	if (viewing)
		resources = state.resources.get(viewing);
	var userLanguage = currentPerson.get("personal").language;
	var sortedResources = resources.sort(par(by_title, userLanguage));

	var cancel = updating ? par(cancelUpdating, component) : par(cancelAdding, component);

	var addActions = [
		(<Button key={"buttonok"} color="primary" onClick={par(submitResource, component)}>{localization.get("org_create_submit")}</Button>),
		(<Button key={"buttoncancel"} color="primary" onClick={cancel}>{localization.get("org_create_cancel")}</Button>)
	];

	var remove_actions = [(
		<Button key={"buttonok"} onClick={par(confirmRemove, component)}>{localization.get("users_ok")}</Button >
	), (
		<Button key={"buttoncancel"} onClick={par(hideRemove, component)}>{localization.get("common.cancel")}</Button >
	)];

	var duplicate_actions = [
		(<Button onClick={par(cancelDuplicating, component)}>{localization.get("common.cancel")}</Button>),
		(<Button onClick={par(finishDuplicating, component)}>{localization.get("common.finish")}</Button>)
	];

	var currentDuplicateOrg = state.duplicateOrg;

	var titleKey = "admin_resources_upload";

	var orgSelectors = allOrgs.sort(by_name).map(e => (
		<MenuItem key={e._id} value={e._id} >
			{e.name}
		</MenuItem>
	));

	const renderOpenToast = (
		<Snackbar open={state.openToast} autoHideDuration={3500} onClose={par(hideToastAndFinishDuplicating, component)} onClick={par(hideToastAndFinishDuplicating, component)}>
			<div className="flex-horizontal ae-dropshadow" style={styles.toast}>
				<FontIcon className="fa fa-check fa-2x" style={styles.icon} />
				<div style={styles.toastText}>
					{localization.get(state.toastMessage) || ""}
				</div>
			</div>
		</Snackbar>
	);

	const renderInProgressToast = (
		<Snackbar open={state.inProgressToast} onClose={par(hideToast, component)}>
			<div className="flex-horizontal ae-dropshadow" style={styles.inProgressToast}>
				<FontIcon className="fa fa-cloud-upload fa-2x" style={styles.icon} />
				<div style={styles.inProgressToastText}>
					{localization.get(state.toastMessage) || ""}
				</div>
			</div>
		</Snackbar>
	);

	const renderErrorToast = (
		<Snackbar
			ContentProps={styles.snackbar}
			open={state.errorToast}
			autoHideDuration={3500}
			onClose={par(hideToast, component)}
			message={state.errorToast}
			action={<IconButton className="fa fa-times" onClick={par(hideToast, component)}></IconButton>}
		/>
	);

	return (
		<div className="flex-vertical flex-1">
			<Header
				careplanChanges={careplanChanges}
				offline={offline}
				currentPerson={currentPerson}
				localization={localization}
				titleKey={titleKey}
			/>
			<Dialog actions={duplicate_actions} open={!!isDuplicating} title={localization.get("org_resources_duplicating")}>
				<FormControl fullWidth error={isMissingDestination}>
					<InputLabel>{localization.get("org_resources_duplicating")}</InputLabel>
					<Select onChange={par(updateOrgDropdown, component)} value={currentDuplicateOrg}>
						{orgSelectors}
					</Select>
					{isMissingDestination ? (<FormHelperText>{localization.get("admin_org_duplicating_missing_destination")}</FormHelperText>) : null}
				</FormControl>
			</Dialog>
			<Dialog actions={remove_actions} open={!!toRemove} title={localization.get("org_workflow_removeTitle")} >
				{getRemove(component, toRemove)}
			</Dialog>
			<div className="flex-horizontal flex-1 ae-scrollable">
				<div className="flex-vertical flex-1 ae-left-margin ">
					<SubHeader>{localization.get("admin_form_org")}</SubHeader>
					<TextField placeholder={localization.get("admin_workflow_search")} inputProps={{"aria-label": localization.get("admin_workflow_search")}} onChange={searchOrg} style={styles.search} InputProps={styles.inputProp} />
					<LazyList loadMore={loadMore} renderItem={par(renderOrg, component, localization)} items={sortedOrgs} />
				</div>
				<div className="flex-vertical flex-1">
					<div className="flex-vertical flex-1 ae-scrollable">
						<SubHeader>{localization.get("user_resources_resources")}</SubHeader>
						{showFilter(component, sortedResources, currentPerson)}
						{renderLazyList(component, currentPerson, par(showResourceRemoval, component), par(startDuplicating, component), resources)}
					</div>
					{renderAdd(component)}
				</div>
			</div>
			<div>
				<Dialog actions={addActions} open={!!creating || !!updating} title={dialogTitle}>
					<FormControl fullWidth={true} style={styles.formControlStyle}>
						<InputLabel style={styles.inputlabel}>{localization.get("org_resources_language")}</InputLabel>
						<Select aria-label= {localization.get("org_resources_language")} aria-labelledby={localization.get("org_resources_language")} inputProps={{"aria-label": localization.get("org_resources_language"), "role": "listbox", "aria-labelledby": localization.get("org_resources_language")}} fullWidth onChange={par(updateDropdown, component, "language")} value={currentLanguage}>
							<MenuItem value={"en"}>{localization.get("en")}</MenuItem>
							<MenuItem value={"fr"}>{localization.get("fr")}</MenuItem>
							<MenuItem value={"pt"}>{localization.get("pt")}</MenuItem>
							<MenuItem value={"es"}>{localization.get("es")}</MenuItem>
							<MenuItem value={"cn_s"}>{localization.get("cn_s")}</MenuItem>
							<MenuItem value={"cn_t"}>{localization.get("cn_t")}</MenuItem>
							<MenuItem value={"ta"}>{localization.get("ta")}</MenuItem>
						</Select>
					</FormControl>
					<Tabs fullWidth value={tabValue} indicatorColor="primary" onChange={par(handleTabValueChanged, component)}>
						<Tab label={localization.get("org_resources_info")} />
						<Tab label={localization.get("org_resources_preview")} />
					</Tabs>
					{tabValue === 0 && (
						<div>
							<TextField
								fullWidth
								value={currentName}
								onChange={par(updateText, "name", component)}
								placeholder={localization.get("org_resource_name")}
								label={localization.get("org_resource_name")}
								style={styles.input}
								InputProps={styles.inputProp}
								error={dialogErrors?.["name"]?.[currentLanguage]}
								required/>
							<TextField
								fullWidth
								multiline
								value={currentData}
								rows={19}
								rowsMax={19}
								onChange={par(updateText, "data", component)}
								placeholder={localization.get("org_resource_data")}
								label={localization.get("org_resource_data")}
								style={styles.input}
								InputProps={styles.inputProp}
								error={dialogErrors?.["data"]?.[currentLanguage]}
								required/>
						</div>
					)}
					{tabValue === 1 && (
						<div className="ae-scrollable">
							<Markdown>
							{currentData}
							</Markdown>
						</div>
					)}
				</Dialog>
			</div>
			{renderInProgressToast}
			{renderOpenToast}
			{renderErrorToast}
		</div>
	);
}


function startDuplicating(component, resource) {
	component.state.userMetrics.trackEvent("admin-resources: open duplicate resource popup", {
		"resource": resource._id
	});
	component.setState({
		isDuplicating: resource
	});
}

function showResourceRemoval(component, resource) {
	component.state.userMetrics.trackEvent("admin-resources: open remove resource popup", {
		"resource": resource._id
	});
	component.setState({
		toRemove: resource
	});
}

function updateDropdown(component, type, e) {
	e.persist();
	var payload = e.target.value;
	var update = {};
	update[type] = payload;

	component.setState(update);
}

function handleTabValueChanged(component, event, value) {
	component.setState({
		tabValue: value
	});
	const eventText = [
		"admin-resources: navigate to info tab in create resource popup",
		"admin-resources: navigate to preview tab in create resource popup"
	];
	const eventProperties = component.state?.updating?._id
		? { resource: component.state.updating?._id }
		: { creating: true };
	component.state.userMetrics.trackEvent(eventText[value], eventProperties);
}

function startAdding(component){
	component.state.userMetrics.trackEvent("admin-resources: open create resource popup");
	component.setState({
		creating: {},
		tabValue: 0
	});
}

function cancelAdding(component) {
	component.state.userMetrics.trackEvent("admin-resources: close create resource popup");
	component.setState({
		creating: false,
		language: "en"
	});
}

function by_name(x, y) {
	var X = x.name?.toLowerCase() || "";
	var Y = y.name?.toLowerCase() || "";
	if (X < Y) return -1;
	if (X > Y) return 1;
	return 0;
}

function by_title(currentLanguage, x, y) {
	let X = x.name[currentLanguage] || x.name.en || x.name || "";
	let Y = y.name[currentLanguage] || y.name.en || y.name || "";

	if (currentLanguage === "cn_s" || currentLanguage === "cn_t")
		currentLanguage = (currentLanguage === "cn_s") ? "zh-CN" : "zh-HK";

	if(typeof X !== "string")
		X = "";

	if(typeof Y !== "string")
		Y = "";

	return X.toLowerCase().localeCompare(Y.toLowerCase(), currentLanguage);
}

function renderOrg(component, localization, org, index) {
	var name = org.name;
	var orgIndex = index + 1;
	var description = org.description;
	var orgId = org._id;

	var background_colour = "ae-plain";
	var viewing = component.state.viewing;
	if (viewing && orgId === viewing) background_colour = "ae-hover-color";

	return (
		<ListItem button key={orgId} className={background_colour} onClick={par(viewResourcesFor, component, orgId, orgIndex)}>
			<ListItemText primary={name} secondary={description} />
		</ListItem>
	);
}

function viewResourcesFor(component, org, orgIndex) {
	component.state.userMetrics.trackEvent("admin-resources: view resources for org", {
		"org": org
	});
	component.setState({
		viewing: org,
		selectedIndex: orgIndex,
		filteredResources: []
	});
}

function submitResource(component) {
	var viewing = component.state.viewing;
	var updating = component.state.updating;
	var resource = component.state.creating || updating;
	var submit = updating ? component.state.updateResource : component.state.createResource;
	var hide = updating ? cancelUpdating : cancelAdding;
	var name = resource.name;
	var data = resource.data;
	var resourceId = resource._id;
	const { language } = component.state;
	const nameValue = name?.[language] ?? "";
	const dataValue = data?.[language] ?? "";

	if(nameValue.length && dataValue && viewing) {
		submit(name, data, viewing, resourceId);
		hide(component);
		return;
	}

	component.state.userMetrics.trackEvent("admin-resources: submit resource", {
		"org": viewing,
		"resource": resourceId,
	});
	const dialogErrors = Object.assign({}, getDialogErrors(component, "name", nameValue), getDialogErrors(component, "data", dataValue));
	component.setState({ dialogErrors });
}

function updateText(type, component, e){
	e.persist();
	var text = e.target.value;
	var language = component.state.language;
	var updating = component.state.updating;
	var update = component.state.creating || updating;
	if(!update[type]) update[type] = {};
	update[type][language] = text;
	const dialogErrors = getDialogErrors(component, type, text);

	if(updating)
		updateUpdatingState(component, update, dialogErrors);
	else
	updateCreatingState(component, update, dialogErrors);
}

function updateUpdatingState(component, update, dialogErrors) {
	var updating = component.state.updating;
	component.setState({
		updating: xtend(updating, update),
		dialogErrors,
	});
}

function updateCreatingState(component, update, dialogErrors){
	component.setState({
		creating: update,
		dialogErrors,
	});
}

function getDialogErrors(component, field, value) {
	const { language } = component.state;
	const dialogErrors = component.state.dialogErrors ?? {};
	if(!dialogErrors[field]) dialogErrors[field] = {};
	if (!value.length) {
		dialogErrors[field][language] = true;
	} else {
		dialogErrors[field][language] = false;
	}
	return dialogErrors;
}

function renderResource(component, currentPerson, remove, duplicateStart, resource) {
	if (!resource || Object.keys(resource.name).length === 0) return null;
	var language = currentPerson.get("personal").language;
	var localization = component.state.localization;
	var name = getLang(resource.name, language);
	var id = resource._id;
	var button = (
		<div>
			<IconButton className="fa fa-pencil" aria-label={localization.get("edit_button")}  title= {localization.get("edit_button")} role="button" style={styles.icon} onClick={par(startUpdating, component, resource)} />
			<IconButton className="fa fa-times-circle" aria-label={localization.get("remove_button")} title={localization.get("remove_button")}  role="button" style={styles.icon} onClick={par(remove, resource)} />
			{downloadFile(component, resource)}
			<IconButton className="fa fa-copy"  aria-label={localization.get("duplicate_button")} title={localization.get("duplicate_button")} role="button" style={styles.icon} onClick={par(duplicateStart, resource)} />
		</div>
	);

	return (
		<ListItem classes={listItemClasses} key={id} className={"ae-plain"}>
			<ListItemText primary={name} />
			{button}
		</ListItem>
	);
}

function startUpdating(component, resource) {
	var newResource = {
		data: prepareUpdate(resource.data),
		name: prepareUpdate(resource.name),
		_id: resource._id
	};
	component.setState({
		updating: newResource,
		tabValue: 0
	});
	component.state.userMetrics.trackEvent("admin-resources: open edit resource popup", {
		"resource": resource._id
	});
}

function cancelUpdating(component) {
	component.state.userMetrics.trackEvent("admin-resources: close edit resource popup");
	component.setState({
		updating: false,
		language: "en"
	});
}

function downloadFile(component, resource) {
	var currentPerson = component.state.currentPerson;
	var language = currentPerson.get("personal").language;
	var name = getLang(resource.name, language);
	var data = getLang(resource.data, language);
	var localization = component.state.localization;

	var filename = name.replace(/ /g, "_") + ".md";
	var mdBlob = new Blob([data], {
		type: "text/markdown"
	});

	var url = window.URL.createObjectURL(mdBlob);

	return (
		<IconButton
			classes={iconButtonClasses}
			aria-label={localization.get("download_file_button")}
			title={localization.get("download_file_button")}
			role="button"
			color="primary"
			className="fa fa-download"
			href={url}
			download={filename}
			onClick={() =>
				component.state.userMetrics.trackEvent("admin-resources: download resource", {
					resource: resource._id
				})
			}
		/>
	);
}

function confirmRemove(component) {
	var id = component.state.toRemove._id;
	var viewing = component.state.viewing;
	component.state.removeResource(id, viewing);
	component.state.userMetrics.trackEvent("admin-resources: remove resource", {
		"resource": id
	});
	component.setState({
		toRemove: null
	});
}


function hideRemove(component) {
	component.state.userMetrics.trackEvent("admin-resources: close remove resource popup");
	component.setState({
		toRemove: false
	});
}

function searchResource(component, array, currentPerson, key, event) {
	event.persist();
	var query = event.target.value;
	var terms = escape_regex(query).split(" ").join("");
	var regex = new RegExp(terms, "i");
	var language = currentPerson.get("personal").language;

	var filtered = array.filter(function (item) {
		var matches = getLang(item.name, language).split(" ").join("").match(regex);
		if (matches) return item;
	});

	var update = {};
	update["filtered" + key] = filtered;

	component.setState(update);
}

function getRemove(component, resource){
	var currentPerson = component.state.currentPerson;
	var language = currentPerson.get("personal").language;
	if(!resource) return "";
	var name = getLang(resource.name, language);

	var localization = component.state.localization;
	return localization.get("org_resource_remove") + name + "?";
}

function prepareUpdate(key){
	if(typeof key === "object")
		return Object.assign({}, key);
	else return {
		en: key
	};
}
function getLang(map, language){
	if(typeof map === "string") return map;
	map = map || {};
	var returnValue = map[language];
	if(!returnValue)
		returnValue = map[Object.keys(map)[0]];
	return returnValue;
}

function showFilter(component, sortedResources, currentPerson) {
	var state = component.state;
	var localization = state.localization;
	var viewing = state.viewing;
	if (!viewing) return;
	var resources = state.resources.get(viewing);
	if (!resources || !resources.length) return;

	return (
		<TextField key={viewing} placeholder={localization.get("search_resources")} inputProps={{"aria-label": localization.get("admin_workflow_search")}} onChange={par(searchResource, component, sortedResources, currentPerson, "Resources")} style={styles.search} InputProps={styles.inputProp} />
	);
}

function renderLazyList(component, currentPerson, resourceRemoval, duplicateStart, resources) {
	var localization = component.state.localization;
	var show = [];
	if (!resources.length) {
		return null;
	}
	var renderResourceListItem = par(renderResource, component, currentPerson, resourceRemoval, duplicateStart);
	var filtered = component.state.filteredResources;
	if (filtered === null) return (
		<div style={styles.noMatch}>{localization.get("search_noMatch")}</div>
	);
	if (!filtered.length) {
		show = resources;
	} else {
		show = filtered;
	}

	return (<LazyList loadMore={noop} renderItem={renderResourceListItem} items={show} />);
}

function noop() { }

function updateOrgDropdown(component, e){
	e.persist();
	var payload = e.target.value;
	var duplicateOrg = payload;
	component.setState({
		duplicateOrg,
		isMissingDestination: false
	});
}

function hideToast(component) {
	component.state.userMetrics.trackEvent("admin-resources: hide toast");
	component.setState({
		errorToast: false,
		openToast: false,
		inProgressToast: false,
		toastMessage: ""
	});
}

function hideToastAndFinishDuplicating(component) {
	component.state.userMetrics.trackEvent("admin-resources: close duplicate resource popup");
	component.setState({
		errorToast: false,
		openToast: false,
		inProgressToast: false,
		toastMessage: "",
		isDuplicating: null,
		duplicateOrg: ""
	});
}

function cancelDuplicating(component) {
	component.state.userMetrics.trackEvent("admin-resources: close duplicate resource popup");
	component.setState({
		isDuplicating: null,
		duplicateOrg: "",
		isMissingDestination: false
	});
}

function finishDuplicating(component) {
	const state = component.state;
	const resource = state.isDuplicating;

	if(!resource)
		return;

	const resourceId = resource._id;
	const organization = resource.owner;
	const destination = state.duplicateOrg;

	component.state.userMetrics.trackEvent("admin-resources: duplicate resource", {
		"resource": resourceId,
		"org": organization,
		"destination": destination
	});
	if (!destination) {
		component.setState({isMissingDestination: true});
		return;
	} else {
		component.setState({
			inProgressToast: true,
			toastMessage: "org_resource_duplicating_inProgress"
		});
	}

	state.duplicateResource(resourceId, organization, destination)
		.then(() => {
			component.setState({
				inProgressToast: false,
				openToast: true,
				toastMessage: "org_resource_duplicating_success"
			});
		}).catch((err) => {
			Sentry.captureException(err);

			component.setState({
				inProgressToast: false,
				toastMessage: "",
				errorToast: err.response.message
			});
		});
}

function renderAdd(component) {
	if (component.state.viewing)
		return (
			<Button
				variant="raised"
				onClick={par(startAdding, component)}
				color="secondary"
			>
				{component.state.localization.get("user_resources_addresource")}
			</Button>
		);
}