
import React from "react";
import Table from "@material-ui/1.5.1/Table";
import TableHead from "@material-ui/1.5.1/TableHead";
import TableCell from "@material-ui/1.5.1/TableCell";
import TableBody from "@material-ui/1.5.1/TableBody";
import TableRow from "@material-ui/1.5.1/TableRow";
import CircularProgress from "@material-ui/1.5.1/CircularProgress";
import { fetchHeaders } from "./helpers";
import { parseTableBody } from "./helpers";

var tableCellTypes = {
	0: {
		root: "status-green"
	},
	1: {
		root: "status-yellow"
	},
	2: {
		root: "status-red"
	}
};

var styles = {
	padding: {
		padding: 10
	}
};

export default renderTable;

function renderTable(schema, data, language, externals, localization) {

	var headers = [localization.get("user_overview_date")].concat(fetchHeaders(schema, language));
	var renderHeaders = headers.map((header) => {
		return (<TableCell style={styles.padding}>{header}</TableCell>);
	});


	var renderBody = null;

	if (data.loading) {
		renderBody = (
			<SpanBody colSpan={headers.length}>
				<CircularProgress />
			</SpanBody>
		);
	} else if (!Object.keys(data || {}).length) {
		renderBody = (
			<SpanBody colSpan={headers.length}>
				{localization.get("user_overview_no_table_data")}
			</SpanBody>
		);
	} else {
		renderBody = renderTableBody(schema, data, externals, language);
	}

	return (
		<Table>
			<TableHead>
				<TableRow>
					{renderHeaders}
				</TableRow>
			</TableHead>
			{renderBody}
		</Table>
	);
}

function renderTableBody(schema, data, externals, language) {
	var days = Object.keys(data);
	if (!days) return (
		<TableBody>
			<TableRow>
			</TableRow>
		</TableBody>
	);

	return (
		<TableBody>
			{parseTableBody(schema, data, language, externals).map((row) => {
				return <TableRow>
					{row.map((cell) => {
						if (cell.type === "THRESHOLD") {
							return renderThreshold(cell.data, cell.thresholds);
						}
						else {
							return (<TableCell style={styles.padding}>{cell.data}</TableCell>);
						}
					}
					)}
				</TableRow>;
			})}
		</TableBody>
	);
}

function renderThreshold(number, thresholds) {


	var status = thresholds.reduce((acc, e) => {
		var condition = false;
		if (e.gt) {
			var gt = parseInt(e.gt, 10);
			condition = number > gt;
		}

		if (e.gte) {
			var gte = parseInt(e.gte, 10);
			condition = number >= gte;
		}

		if (e.lt) {
			var lt = parseInt(e.lt, 10);
			condition = number < lt;
		}

		if (e.lte) {
			var lte = parseInt(e.lte, 10);
			condition = number <= lte;
		}

		if (condition && (e.status || e.status === 0))
			acc = parseInt(e.status, 10);

		return acc;
	}, -1);

	return (
		<TableCell style={styles.padding} classes={tableCellTypes[status]}>
			{number}
		</TableCell>
	);
}

function SpanBody(props) {
	var colSpan = props.colSpan;

	return (
		<TableBody>
			<TableRow>
				<TableCell colSpan={colSpan}>
					<div className="flex-horizontal justify-center ae-margin-medium">
						{props.children}
					</div>
				</TableCell>
			</TableRow>
		</TableBody>
	);
}
