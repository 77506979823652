/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";

import FontIcon from "@material-ui/1.5.1/Icon";

import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import MomentUtils from "@date-io/moment";
import { useLocalize } from "@aetonix/hooks";

import * as AetonixTheme from "./AetonixTheme";
const Colors = AetonixTheme.default.palette;
var styles = {
	date: {
		display: "flex",
		flexDirection: "column",
		color: Colors.primary.main
	},
	dateEntry: {
		marginBottom: "30px"
	}
};

export default function AppDatePicker(props) {
	const { labelStart, labelEnd, updateStart, updateEnd, allowFuture } = props;
	const startDate = props.startDate || null;
	const endDate = props.endDate || null;
	const invalid = props.invalid || "Please choose a date";

	const { localize } = useLocalize();

	const arrowLeft = <FontIcon className={"fa fa-angle-left"} />;
	const arrowRight = <FontIcon className={"fa fa-angle-right"} />;

	return (
		<MuiPickersUtilsProvider utils={MomentUtils}>
			<div style={styles.date}>
				<DatePicker
					rightArrowIcon={arrowRight}
					emptyLabel={invalid}
					leftArrowIcon={arrowLeft}
					leftArrowButtonProps={{ "aria-label": localize("previous_month") }}
					rightArrowButtonProps={{ "aria-label": localize("next_month") }}
					label={labelStart}
					value={startDate}
					onChange={updateStart}
					inputProps={{ "aria-label": labelStart || localize("pick_a_date"), role: "none" }}
					style={styles.dateEntry}
					disableFuture={allowFuture}
					autoOk
				/>
				<DatePicker
					rightArrowIcon={arrowRight}
					emptyLabel={invalid}
					leftArrowIcon={arrowLeft}
					leftArrowButtonProps={{ "aria-label": localize("previous_month") }}
					rightArrowButtonProps={{ "aria-label": localize("next_month") }}
					label={labelEnd}
					value={endDate}
					onChange={updateEnd}
					style={styles.dateEntry}
					inputProps={{ "aria-label": labelEnd || localize("pick_a_date"), role: "none" }}
					disableFuture={allowFuture}
					autoOk
				/>
			</div>
		</MuiPickersUtilsProvider>
	);
};
