/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, May 3, 2018
 * For information or permission request, email info@aetonixsystems.com
 */


import par from "par";

import defineUI from "../shared/define";
import render from "./render.jsx";
import PersonStore from "../stores/admin_people_store";
import LocalizationStore from "../stores/localization_store";
import CurrentPersonInfoStore from "../stores/current_person_info_store";
import CareplanNotificationStore from "../stores/careplan_notice_store";
import ConnectionStore from "../stores/connection_store";
import OrgGroup from "../stores/admin_group_data_store";
import Org from "../stores/admin_org_data_store";
import Connectivity from "../stores/connectivity_store";
import CustomProperties from "../stores/admin_group_custom_properties";

import * as Sentry from "@sentry/react";
import makeStoreFactory from "../shared/stores";

const makeStores = makeStoreFactory({
	people: PersonStore,
	currentPerson: CurrentPersonInfoStore,
	localization: LocalizationStore,
	careplanChangeList: CareplanNotificationStore,
	connection: ConnectionStore,
	orgGroup: OrgGroup,
	org: Org,
	connectivity: Connectivity,
	customProperties: CustomProperties
});

export default make_ui;

function make_ui(api, events, config, userMetrics) {
	var storeStore = makeStores(api, events, config);

	window.stores = storeStore;

	userMetrics.trackEvent("page view", {
		"page": "admin-user-management",
		"category": "admin",
		"permission level": "admin",
	});

	return defineUI({
		getInitialState: getInitialState,
		componentDidMount: listen_for_changes,
		render: render,
		componentWillUnmount: removeListeners
	});

	function removeListeners() {
		events.off();
	}

	function listen_for_changes() {
		storeStore.listen(par(handle_change, this));
	}

	function getInitialState() {
		return {
			people: storeStore.stores.people,
			currentPerson: storeStore.stores.currentPerson,
			localization: storeStore.stores.localization,
			careplanChangeList: storeStore.stores.careplanChangeList,
			connection: storeStore.stores.connection,
			orgGroup: storeStore.stores.orgGroup,
			org: storeStore.stores.org,
			connectivity: storeStore.stores.connectivity,
			customProperties: storeStore.stores.customProperties,
			lockApp: lockApp,
			unlockApp: unlockApp,
			enableTestMode: enableTestMode,
			disableTestMode: disableTestMode,
			updateUserInfo: updateUserInfo,
			handleAdminLoginAndUserDelete: handleAdminLoginAndUserDelete,
			handleAdminLoginAndPasswordReset: handleAdminLoginAndPasswordReset,
			openSettings,
			getControlToken,
			isEditingPersonalInfo: null,
			new_fname: "",
			new_lname: "",
			new_type: "",
			new_language: "",
			new_measureunits: "",
			new_glucometerUnits: "",
			new_weightUnits: "",
			new_temperatureUnits: "",
			new_searchHidden: null,
			errorToast: false,
			openToast: false,
			toastMessage: "",
			isDeletingUser: null,
			isResetingPassword: false,
			gotEmailConfirm: false,
			gotEmailConfirm_PWR: false,
			doubleCheck_fname: "",
			doubleCheck_lname: "",
			doubleCheck_email: "",
			tokenModel: null,
			token: null,
			isWarningDeleteUser: null,
			isWarningPasswordReset: null,
			adminLoginEmail: "",
			adminLoginPassword: "",
			isEditingCustomProperties: null,
			new_customProperties: null,
			newPassword: null,
			updateUserCustomProperties: updateUserCustomProperties,
			sendToast,
			userMetrics: userMetrics,
		};
	}

	function handle_change(component, stores) {
		component.setState({
			people: stores.people,
			usersPrivate: stores.usersPrivate,
			currentPerson: stores.currentPerson,
			localization: stores.localization,
			careplanChangeList: stores.careplanChangeList,
			connection: stores.connection,
			orgGroup: stores.orgGroup,
			org: stores.org,
			connectivity: stores.connectivity,
			customProperties: stores.customProperties,
			search: doSearch
		});
	}

	function doSearch(query) {
		return api.admin.search.users(query);
	}

	function lockApp(component, who) {
		return api.admin.user.app.lock(who)
			.then(par(sendToast, component, "Lock Patient's App Successfully"))
			.catch(error => {
				Sentry.captureException(error);
				showError(component, error);
			});
	}

	function unlockApp(component, who) {
		return api.admin.user.app.unlock(who)
			.then(par(sendToast, component, "Unlock Patient's App Successfully"))
			.catch(error => {
				Sentry.captureException(error);
				showError(component, error);
			});
	}

	function openSettings(component, who){
		userMetrics.trackEvent("admin-user-management: open android settings for user", {
			"who": who,
		});
		return api.admin.user.app.settings(who)
			.then(par(sendToast, component, "Opened android settings successfully. Patient should become offline shortly."))
			.catch(error => {
				Sentry.captureException(error);
				showError(component, error);
			});
	}

	function getControlToken(component, who) {
		return api.admin.controlToken(who)
			.then((response) => { component.setState({ token: response.token }); })
			.catch(error => {
				Sentry.captureException(error);
				showError(component, error);
			});
	}

	function enableTestMode(who) {
		return api.admin.user.testmode.enable(who);
	}

	function disableTestMode(who) {
		return api.admin.user.testmode.disable(who);
	}

	function updateUserInfo(component, userId, newUserData, updateUserData, updateUserType) {
		var updateUserDataPromise = Promise.resolve();
		if (updateUserData) updateUserDataPromise = api.admin.user.update.data(userId, newUserData);
		var updateUserTypePromise = Promise.resolve();
		var newUserType = newUserData["type"];
		newUserType = newUserType === "user:mobile" ? "mobile" : newUserType;
		if (newUserType && updateUserType) updateUserTypePromise = api.admin.user.update.userType(userId, newUserType);
		return Promise.all([updateUserDataPromise, updateUserTypePromise])
			.then(par(sendToast, component, "Update Personal Info Successfully"))
			.catch(error => {
				Sentry.captureException(error);
				showError(component, error);
			});
	}

	function sendToast(component, message) {
		component.setState({
			openToast: true,
			toastMessage: message
		});
	}

	function showError(component, err) {
		component.setState({errorToast: err.response.message});
	}

	function handleAdminLoginAndUserDelete(component, userId) {
		let message = "";
		var password = component.state.adminLoginPassword;
		var email = component.state.adminLoginEmail;

		if (!email)
			message = "Missing email";
		else if (email !== component.state.currentPerson._data.personal.email)
			message = "Must use the current admin patient's email";
		else if (!password)
			message = "Missing password";

		component.setState({loginErrors: message});

		if (!message) {
			api.admin.user.destory(email, password, userId)
				.then(() => {
					sendToast(component, "Delete user successfully");
					setTimeout(() => {
						window.location.reload();
					}, 3000);
				}).catch(error => {
					Sentry.captureException(error);
					showError(component, error);
				});
		}
		userMetrics.trackEvent("admin-user-management: remove user", {
			"who": userId,
		});
	}
	function handleAdminLoginAndPasswordReset(component, userId) {
		let message = "";
		var password = component.state.adminLoginPassword;
		var email = component.state.adminLoginEmail;

		if (!email)
			message = "Missing email";
		else if (email !== component.state.currentPerson._data.personal.email)
			message = "Must use the current admin patient's email";
		else if (!password)
			message = "Missing password";

		component.setState({loginErrors: message});

		if (!message) {
			api.admin.user.resetPassword(email, password, userId)
				.then((response) => {
					component.setState({newPassword: response.newPassword});
					sendToast(component, "Patient's password reset successfully");
				}).catch(error => {
					Sentry.captureException(error);
					showError(component, error);
				});
		}
		userMetrics.trackEvent("admin-user-management: reset password", {
			"who": userId,
		});
	}

	function updateUserCustomProperties(component, userId, newCustomProperties) {
		return api.admin.user.update.customProperties(userId, newCustomProperties)
			.then(par(sendToast, component, "Update patient's custom properties successfully"))
			.catch((err) => {
				Sentry.captureException(err);
				component.setState({errorToast: err.response.message});
			});
	}
}
