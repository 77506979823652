/* © 2023 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";

import par from "par";
import xtend from "xtend";
import Header from "../shared/Header.js";
import IconButton from "@material-ui/1.5.1/IconButton";
import TextField from "@material-ui/1.5.1/TextField";
import ListItem from "@material-ui/1.5.1/ListItem";
import ListItemText from "@material-ui/1.5.1/ListItemText";
import Dialog from "../shared/Dialog";
import Button from "@material-ui/1.5.1/Button";
import Tabs from "@material-ui/1.5.1/Tabs";
import Tab from "@material-ui/1.5.1/Tab";
import Select from "@material-ui/1.5.1/Select";
import MenuItem from "@material-ui/1.5.1/MenuItem";
import InputLabel from "@material-ui/1.5.1/InputLabel";
import FormControl from "@material-ui/1.5.1/FormControl";
import LazyList from "../shared/LazyList.jsx";
import  AetonixTheme_mui_1_5_1 from "../shared/AetonixTheme_mui_1.5.1";
const Colors = AetonixTheme_mui_1_5_1.palette;

import Markdown from "ae-base/Base/Markdown";

var styles = {
	input: {
		color: "grey",
		marginTop: "8px",
		marginBottom: "8px"
	},
	iconStyle: {
		color: "#065B61",
		paddingRight: 15
	},
	inputlabel: {
		color: "black"
	},
	formControlStyle: {
		marginTop: "8px",
		marginBottom: "8px"
	},
	inputProp: {
		style: {
			color: Colors.primary.main,
		}
	},
};

var listItemClasses = {
	default: "ae-padding",
	gutters: "ae-padding",
	root: "ae-padding"
};

var iconButtonClasses = {
	label: "ae-widthauto"
};

export default render;

function render() {
	var component = this;

	var state = component.state;
	var toRemove = state.toRemove;

	var resources = state.resources;
	var currentPerson = state.currentPerson;
	var localization = state.localization;
	var offline = state.connection.get("offline");
	var careplanList = state.careplanChangeList.all();
	var careplanChanges = !!careplanList.length;
	var updating = state.updating;
	var creating = state.creating;

	var tabValue = state.tabValue || 0;

	var currentLanguage = state.language;

	var creatingName = creating.name || {};
	var creatingData = creating.data || {};

	var updatingName = updating.name || {};
	var updatingData = updating.data || {};

	var currentName = updating ? updatingName[currentLanguage] || "" : creatingName[currentLanguage] || "";
	var currentData = updating ? updatingData[currentLanguage] || "" : creatingData[currentLanguage] || "";

	var titleKey = "dashboards_header_resources";

	try {
		const userLanguage = this.state.currentPerson.get("personal").language;
		resources.sort(par(by_title, userLanguage));
	} catch(error) {
		// not an array that can be sorted
	}

	var cancel = updating ? par(cancelUpdating, component) : par(cancelAdding, component);
	var dialogTitle = updating ? currentName : localization.get("org_resource_newresource");

	var addActions = [
		(<Button key={"buttonsubmit"} onClick={par(submitResource, component)}>{localization.get("org_create_submit")}</Button >),
		(<Button key={"buttoncancel"} onClick={cancel}>{localization.get("org_create_cancel")}</Button >)
	];

	var remove_actions = [(
		<Button key={"buttonok"} onClick={par(confirmRemove, component)}>{localization.get("users_ok")}</Button >
	), (
		<Button key={"buttoncancel"} onClick={par(hideRemove, component)}>{localization.get("users_cancel")}</Button >
	)];

	return (
		<div className="flex-vertical flex-1">
			<Header
				careplanChanges={careplanChanges}
				offline={offline}
				currentPerson={currentPerson}
				localization={localization}
				titleKey={titleKey}
			/>

    <Dialog actions={remove_actions} open={!!toRemove} title={localization.get("org_resource_removeTitle")} >
				{getRemove(component, toRemove)}
			</Dialog>

			<LazyList loadMore={noop} renderItem={par(renderResource, component)} items={resources} />
			<Button variant="raised" onClick={par(showAddResource, component)} color="secondary" >{localization.get("org_resources_addnew")}</Button>

			<Dialog actions={addActions} open={!!creating || !!updating} title={dialogTitle}>
				<FormControl fullWidth={true} style={styles.formControlStyle}>
					<InputLabel style={styles.inputlabel}>{localization.get("org_resources_language")}</InputLabel>
					<Select fullWidth onChange={par(updateDropdown, component, "language")} inputProps={{"aria-label": localization.get("org_resources_language")}} value={currentLanguage}>
						<MenuItem value={"en"}>{localization.get("en")}</MenuItem>
						<MenuItem value={"fr"}>{localization.get("fr")}</MenuItem>
						<MenuItem value={"pt"}>{localization.get("pt")}</MenuItem>
						<MenuItem value={"es"}>{localization.get("es")}</MenuItem>
						<MenuItem value={"cn_s"}>{localization.get("cn_s")}</MenuItem>
						<MenuItem value={"cn_t"}>{localization.get("cn_t")}</MenuItem>
						<MenuItem value={"ta"}>{localization.get("ta")}</MenuItem>
					</Select>
				</FormControl>
				<Tabs fullWidth value={tabValue} indicatorColor="primary" onChange={par(handleTabValueChanged, component)}>
					<Tab label={localization.get("org_resources_info")} />
					<Tab label={localization.get("org_resources_preview")} />
				</Tabs>
				{tabValue === 0 && (
					<div>
              <TextField fullWidth defaultValue={currentName} value={currentName} inputProps={{"aria-label": localization.get("org_resource_name")}} onChange={par(updateText, "name", component)} placeholder={localization.get("org_resource_name")} label={localization.get("org_resource_name")} style={styles.input} InputProps={styles.inputProp} />
              <TextField aria-label={localization.get("org_resource_data")} inputProps={{"aria-label": localization.get("org_resource_data"), "role": "tab"}} role={"tablist"} fullWidth multiline defaultValue={currentData} value={currentData} rows={19} rowsMax={19} onChange={par(updateText, "data", component)} placeholder={localization.get("org_resource_data")} label={localization.get("org_resource_data")} style={styles.input} InputProps={styles.inputProp} />
          </div>
        )}
				{tabValue === 1 && (
          <div className="ae-scrollable">
            <Markdown>
              {currentData}
            </Markdown>
          </div>
				)}
			</Dialog>
		</div>
	);
}

function updateDropdown(component, type, e) {
	e.persist();
	var payload = e.target.value;
	var update = {};
	update[type] = payload;

	component.setState(update);
}

function showAddResource(component){
	component.setState({
		creating: {},
		tabValue: 0
	});
	component.state.userMetrics.trackEvent("org-resources: open add resource popup");
}

function cancelAdding(component){
	component.setState({
		creating: false,
		language: "en"
	});
	component.state.userMetrics.trackEvent("org-resources: close add resource popup");
}

function cancelUpdating(component){
	component.setState({
		updating: false,
		language: "en"
	});
	component.state.userMetrics.trackEvent("org-resources: close edit resource popup", {
		resource: component.state.updating._id
	});
}

function renderResource(component, resource) {
	var currentPerson = component.state.currentPerson;
	var language = currentPerson.get("personal").language;

	var name = getLang(resource.name, language);
	var resourceId = resource._id;
	var localization = component.state.localization;
	var background_colour = "ae-plain";
	var viewing = component.state.viewing;
	if (viewing && resourceId === viewing._id) background_colour = "ae-hover-color";

	var editButton = (
		<div>
			<IconButton classes={iconButtonClasses} aria-label={localization.get("edit_button")}  title={localization.get("edit_button")}  role="button" color="primary" className="fa fa-pencil" onClick={par(startUpdating, component, resource)} />
			<IconButton classes={iconButtonClasses} aria-label={localization.get("remove_button")} title={localization.get("remove_button")} role="button" color="primary" className="fa fa-times-circle" onClick={par(showResourceRemoval, component, resource)} />
			{downloadFile(component, resource)}
		</div>
	);

	return (
		<ListItem classes={listItemClasses} key={resourceId} className={background_colour} onClick={par(setViewing, component, resource)} >
			<ListItemText primary={name} />
			{editButton}
		</ListItem>
	);
}

function setViewing(component, resource) {
	component.setState({
		viewing: resource
	});
}

function handleTabValueChanged(component, event, value) {
	component.setState({
		tabValue: value
	});
	const eventText = [
		"org-resources: navigate to info tab in edit resource popup",
		"org-resources: navigate to preview tab in edit resource popup",
	];
	const eventProperties = component.state.updating ? {
		resource: component.state.updating._id
	} : {
		creating: true
	};
	component.state.userMetrics.trackEvent(eventText[value], eventProperties);
}

function showResourceRemoval(component, resource) {
	component.setState({
		toRemove: resource
	});
	component.state.userMetrics.trackEvent("org-resources: open remove resource popup", {
		"resource": resource._id
	});
}

function confirmRemove(component) {
	var id = component.state.toRemove._id;
	component.state.removeResource(id);
	component.setState({
		toRemove: null
	});
	component.state.userMetrics.trackEvent("org-resources: remove resource", {
		resource: id
	});
}

function hideRemove(component) {
	component.setState({
		toRemove: null
	});
	component.state.userMetrics.trackEvent("org-resources: close remove resource popup");
}

function startUpdating(component, resource) {
	var newResource = {
		data: prepareUpdate(resource.data),
		name: prepareUpdate(resource.name),
		_id: resource._id
	};
	component.setState({
		updating: newResource,
		tabValue: 0
	});
	component.state.userMetrics.trackEvent("org-resources: open edit resource popup", {
		"resource": resource._id
	});
}

function prepareUpdate(key){
	if(typeof key === "object")
		return key;
	else return {
		en: key
	};
}

function submitResource(component) {
	var updating = component.state.updating;
	var resource = component.state.creating || updating;
	var submit = updating ? component.state.updateResource : component.state.addResource;
	var hide = updating ? cancelUpdating : cancelAdding;
	var name = resource.name;
	var data = resource.data;
	var id = resource._id;

	if(name && data) {
		submit(name, data, id);
		hide(component);
	}

	component.state.userMetrics.trackEvent("org-resources: submit resource", {
		"resource": id
	});
}

function updateText(type, component, e){
	e.persist();
	var text = e.target.value;
	var language = component.state.language;
	var updating = component.state.updating;
	var update = component.state.creating || updating;
	if(!update[type]) update[type] = {};
	update[type][language] = text;

	if(updating)
		updateUpdatingState(component, update);
	else
    updateCreatingState(component, update);
}

function updateCreatingState(component, update) {
	component.setState({
		creating: update
	});
}

function updateUpdatingState(component, update) {
	var updating = component.state.updating;
	component.setState({
		updating: xtend(updating, update)
	});
}

function downloadFile(component, resource) {
	var currentPerson = component.state.currentPerson;
	var language = currentPerson.get("personal").language;
	var name = getLang(resource.name, language) || "File";
	var data = getLang(resource.data, language);
	var localization = component.state.localization;

	if(typeof name !== "string")
		name = "File";

	var filename = name.replace(/ /g, "_") + ".md";

	if(data) {
		var mdBlob = new Blob([data], {
			type: "text/markdown"
		});

		var url = window.URL.createObjectURL(mdBlob);
	}

	return (
		<IconButton
			classes={iconButtonClasses}
			aria-label= {localization.get("download_file_button")}
			title={localization.get("download_file_button")}
			disabled={!data}
			color="primary"
			className="fa fa-download"
			href={url}
			download={filename}
			onClick={() => component.state.userMetrics.trackEvent("org-resources: download resource", {
				resource: resource._id
			})}
		/>
	);
}

function getRemove(component, resource){
	var currentPerson = component.state.currentPerson;
	var language = currentPerson.get("personal").language;
	if(!resource) return "";
	var name = getLang(resource.name, language);

	var localization = component.state.localization;
	return localization.get("org_resource_remove") + name + "?";
}

function noop() {}

function getLang(map, language){
	if(typeof map === "string") return map;
	map = map || {};
	var returnValue = map[language];
	if(!returnValue)
		returnValue = map[Object.keys(map)[0]];
	return returnValue;
}

function by_title(currentLanguage, x, y) {
	x = x.name[currentLanguage] || x.name.en || x.name;
	y = y.name[currentLanguage] || y.name.en || y.name;
	if (currentLanguage === "cn_s" || currentLanguage === "cn_t")
		currentLanguage = (currentLanguage === "cn_s") ? "zh-CN" : "zh-HK";
	return x.toLowerCase().localeCompare(y.toLowerCase(), currentLanguage);
}
