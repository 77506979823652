/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

import Store from "./store";
import map from "fj-map";
import Utility from "../shared/Utility";
const { getLocalStorageArray } = Utility;
const _intersection = require ("lodash/intersection");
import isEmpty from "lodash/isEmpty";

export default UserManagedStore;

function UserManagedStore(api, events, config) {
	var store = new Store();

	var page = 0;
	var loading = null;
	var atEnd = false;
	const pagination = {
		sortBy: "current_status",
		orderBy: "desc",
	};

	let eventsList = new Set();

	var userLocalStorageKey = localStorage.getItem("userLocalStorageKey");
	store.activity = getActivity(getLocalStorageArray(`${userLocalStorageKey}stateFilter`));
	store.manageGroups = getLocalStorageArray(`${userLocalStorageKey}manageGroups`);
	store.more = more;
	store.sort = sort;
	store.sortQuery = "";
	store.reset = reset;
	store.fetch = fetch;
	store.trackUser = trackUser;
	store.search = search;
	store.changeActivity = changeActivity;
	store.changeManageGroups = changeManageGroups;
	store.query = "";
	store.inactive = null;
	store.pageSize = 10;
	store.changePageSize = changePageSize;
	store.count = {};
	store.getCount = getCount;

	reset();

	return store;

	function reset() {
		page = 0;
		atEnd = false;
		store.clear();
		const pageSize = store.pageSize;
		const currState = getLocalStorageArray(`${userLocalStorageKey}stateFilter`);
		const hasActive = currState?.includes("all_active");
		const hasInActive = currState?.includes("inactive");
		const inactiveFilter = !isEmpty(currState) && (hasActive && !hasInActive) ? false : store.inactive;

		return api.manage
			.listManagedUsers({
				queryString: store.query,
				state: store.activity,
				sortQuery: store.sortQuery,
				manageGroups: store.manageGroups,
				inactive: inactiveFilter,
				orgGroup: config.orgGroup,
			}, { page, ...pagination }, pageSize)
			.then(handle_result);
	}

	function getCount() {
		return store.count;
	}

	function more() {
		if (atEnd)
			return Promise.resolve();
		if (loading)
			return loading;
		page += 1;
		return api.manage
			.listManagedUsers({
				queryString: store.query,
				state: store.activity,
				sortQuery: store.sortQuery,
				manageGroups: store.manageGroups,
				inactive: store.inactive,
				orgGroup: config.orgGroup,
			}, { page, ...pagination })
			.then(handle_result);
	}

	function sort(newQuery) {
		store.sortQuery = newQuery;
		return reset();
	}

	function fetch(currentPage, size) {
		store.clear();
		if (atEnd)
			return Promise.resolve();
		if (loading)
			return loading;
		page += 1;

		const currState = getLocalStorageArray(`${userLocalStorageKey}stateFilter`);
		const hasActive = currState?.includes("all_active");
		const hasInActive = currState?.includes("inactive");
		const inactiveFilter = !isEmpty(currState) && (hasActive && !hasInActive) ? false : store.inactive;

		return api.manage
			.listManagedUsers({
				queryString: store.query,
				state: store.activity,
				manageGroups: store.manageGroups,
				inactive: inactiveFilter,
				orgGroup: config.orgGroup,
			}, { currentPage, ...pagination }, size)
			.then(handle_result);
	}

function changePageSize(size) {
	store.pageSize = size;
	return reset();
}

function handle_users(users) {
	if (!users.length) {
		atEnd = true;
		return;
	}

	users.map(add_person);

	loading = null;
}

function add_person(data) {
	var id = data.person || data;
	store.set(id, id);
}

function remove_person(data){
	var id = data.person;
	store.remove(id);
}

function listen_for_changes() {
	eventListenerWrapper("mqtt:$/manage/added", reset);
	eventListenerWrapper("mqtt:$/manage/removed", reset);

	if(config.orgGroup) {
		eventListenerWrapper("mqtt:orggroup/" + config.orgGroup + "/users/state/updated", handle_state_change);

		if(store.manageGroups) {
			store.manageGroups.forEach(group => {
				eventListenerWrapper("mqtt:orggroup/" + config.orgGroup + "/managegroup/" + group + "/removed", handle_group_removed);
				eventListenerWrapper(`mqtt:managegroup/${group}/patient/removed`, handle_group_patient_change);
				eventListenerWrapper(`mqtt:managegroup/${group}/patient/added`, handle_group_patient_change);
			});
		}
	}
}

function handle_group_patient_change(data) {
	const activity = store.activity;
	const state = data.state;
	if(!state || !activity.length || activity.includes(state) || activity.includes("all_active")) reset();
}

function handle_state_change(data) {
	const activity = store.activity;
	const { from, state } = data;
	const stateScope = Array.isArray(from) ? [...from, state] : [from, state];
	const intersection = _intersection(stateScope, activity);
	if(intersection.length || activity.includes("all_active")) reset();
}

function handle_group_removed(data) {
	const group = data.manageGroup;
	store.manageGroups = store.manageGroups.filter(g => g !== group);
	return reset();
}

function trackUser(){}

function handle_result(results) {
	const counts = results.counts;
	store.count = counts;

	const users = results.users;
	if (users.length) {
		users.map(add_person);
	} else {
		store.clear();
	}
	loading = null;
	listen_for_changes();
}

function search(newQuery) {
	store.query = newQuery;
	return reset();
}

function changeActivity(newActivity) {
	store.activity = getActivity(newActivity);
	return reset();
}

function changeManageGroups(newManageGroups) {
	store.manageGroups = newManageGroups;
	return reset();
}

function getActivity(activity){
	activity = [activity].flat();
	store.inactive = null;
	const hasAllActive = activity.includes("all_active");
	const hasInActive = activity.includes("inactive");
	if (hasAllActive) {
		activity = [];
		if (!hasInActive) {
			// if "all_active" is selected but "inactive" is not, then all users with
			// inactive=false should be listed
			store.inactive = false;
		}
	}
	return activity;
}

function eventListenerWrapper(name, fn) {
	if(!eventsList.has(name)){
		eventsList.add(name);
		events.on(name, fn);
	}
}
}
