/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */



/**
 * This store is for the urgency call history.
 */

import Store from "./store";

import map from "fj-map";

import * as Sentry from "@sentry/react";

export default UrgencyStore;

function UrgencyStore(api, events, config) {
	var store = new Store();

	var loading = null;
	var page = 0;
	var	status = "";
	var callers = [];
	var recipients = [];
	var start = "";
	var end = "";

	store.sorted = sorted;
	store.more = more;
	store.searchCallers = searchCallers;
	store.searchRecipients = searchRecipients;
	store.clearSearch = clearSearch;
	store.searchDate = searchDate;
	store.searchStatus = searchStatus;

	api.org.users.list()
		.then(map(listen_on_user))
		.catch(error => {
			Sentry.captureException(error);
			console.error.bind(console, error);
		});

	reset();

	return store;

	function reset() {
		page = 0;
		store.clear();
		return api.org.urgency.groupHistory(callers, recipients, page, start, end, status)
			.then(map(handle_call_create));
	}

	function sorted() {
		return store.all().sort(by_update);
	}

	function more() {
		if(loading) return loading;
		loading = api.org.urgency.groupHistory(callers, recipients, page, start, end, status)
		.then(map(handle_call_create))
		.then(next_page)
		.catch(error => {
			Sentry.captureException(error);
			console.error.bind(console, error);
		})
		.then(finish);
	}

	function finish() {
		loading = null;
	}

	function listen_on_user(id) {
		var orgGroup = config.orgGroup;
		if(!orgGroup) return;
		events.on("mqtt:orggroup/" + orgGroup + "/u/" + id + "/urgency/history/create", handle_call_create);
		events.on("mqtt:orggroup/" + orgGroup + "/u/" + id + "/urgency/history/update", handle_call_update);
	}

	function handle_call_create(newcall) {
		var id = newcall._id;
		store.set(id, newcall);
	}

	function handle_call_update(update_data) {
		var id = update_data._id;
		var existing_data = store.get(id);
		if (!existing_data)
			return console.error(id + " was udpated but not found!");

		var history = existing_data.history.concat(update_data);

		store.update(id, {
			history: history,
			updated_at: update_data.timestamp
		});
	}

	function next_page() {
		page += 1;
	}

	function searchCallers(filterquery) {
		callers = callers.concat(filterquery);
		return reset();
	}

	function searchRecipients(filterquery) {
		recipients = recipients.concat(filterquery);
		return reset();
	}


	function searchDate(startdate, enddate) {
		start = startdate;
		end = enddate;
		if (!!start && !!end){
			return reset();
		} else {
			return;
		}
	}

	function searchStatus(filterstatus) {
		status = filterstatus;
		return reset();
	}

	function clearSearch(name) {
		if (name === "callers") callers = [];
		if (name === "recipients") recipients = [];
		if (name === "status") status = "";
		if (name === "time") {
			start = "";
			end = "";
		}
		return reset();
	}

}

function by_type(prev, next) {
	var prev_type = get_type(prev);
	var next_type = get_type(next);

	if (prev_type === next_type) return 0;
	if (prev_type === "call")
		return -1;
	if (next_type === "call")
		return 1;
	return 0;
}

function by_update(prev, next) {
	var prev_time = (new Date(prev.updated_at)).getTime();
	var next_time = (new Date(next.updated_at)).getTime();

	var type_delta = by_type(prev, next);
	if (type_delta) return type_delta;

	var time_delta = next_time - prev_time;
	return time_delta;
}

function get_type(item) {
	var type = "call";
	item.history.forEach(function(event) {
		if (event.type === "accept")
			type = "accept";
		if (event.type === "cancel")
			type = "cancel";
	});
	return type;
}
