import React, { useEffect, useMemo, useState, useCallback } from "react";
import {
	Avatar,
	Divider,
	List,
	ListItem,
	ListItemAvatar,
	ListSubheader,
	Paper,
	styled,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Modal,
	ListItemText,
	IconButton,
	ListItemSecondaryAction,
	Button,
	DialogContent,
	Dialog,
	TextField,
	Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import * as Sentry from "@sentry/react";
import { formatDate } from "../shared/Util/Date";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import GroupIcon from "@material-ui/icons/Group";
import moment from "moment";
import TouchAppIcon from "@material-ui/icons/TouchApp";
import DeleteOutlineIcon from "@material-ui/icons/Delete";
import CachedIcon from "@material-ui/icons/Cached";
import GetAppIcon from "@material-ui/icons/GetApp";
import FaceIcon from "@material-ui/icons/Face";
import ProgressBar from "./ProgressBar";
import { DatePicker } from "@material-ui/pickers";
import EventSelector from "./EventSelector";
import ListSelector from "./ListSelector";
import useLogFilter from "./hooks/useLogFilter";
import { getFilterEnd, getUrlWithFilterOptions, saveFile } from "./library";
import CircularProgress from "@material-ui/core/CircularProgress";
import MetadataModal from "./MetadataModal";
import {LocalizeConsumer, useLocalize} from "../shared/contexts/LocalizeContext";
import { useUserMetrics } from "@aetonix/user-metrics";
import Utility from "../shared/Utility";
import LazyList from "../shared/LazyList.jsx";

const FilterColumn = styled("div")({ minWidth: 320, display: "grid" });
const LogViewColumn = styled("div")({ flexGrow: 1, display: "flex" });
const Container = styled("div")({
	display: "flex",
	flexDirection: "row",
	flexGrow: 1,
	gridGap: 16,
	padding: 8
});
const useStyles = makeStyles(() => ({
	dialog: {
		height: "calc(100% - 64px)",
	},
}));

export default function AuditLogView(props) {
	const {
		groups,
		api,
		localization,
		currentPerson,
		config,
		people,
		users,
		loadMoreUsers,
		searchUsers,
	} = props;

	const [logs, setLogs] = useState([]);
	const [, setHits] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [isCsvDownloading, setIsCsvDownloading] = useState(false);
	const [metadata, setMetadata] = useState(null);
	const [hasMoreData, setHasMoreData] = useState(false);

	const { filter, setFilter, modals, setModals } = useLogFilter();
	const { userMetrics } = useUserMetrics();

	const getLogs = useCallback(
		async (isLoadingMore = false) => {
			setIsLoading(true);

			const lastTimestampForPagination = logs?.[logs.length - 1]?.["@timestamp"] ?? undefined;
			const nextEnd = !filter.end ? new Date(Date.now()).toISOString() : filter.end;

			try {
				const { found: nextLogs = [], hits: nextHits = 0 } = await api.org.audit.fetch({
					...filter,
					end: nextEnd,
					...(isLoadingMore && { last: lastTimestampForPagination })
				});

				if (isLoadingMore) {
					setLogs([...logs, ...nextLogs]);
				} else {
					setLogs(nextLogs);
				}

				const noMoreEntries = !nextLogs.length;
				const hasReachedUnderDefault = nextLogs.length < 100;
				const hasMore = !(noMoreEntries || hasReachedUnderDefault);

				setHasMoreData(hasMore);
				setHits(nextHits);
			} catch (error) {
				Sentry.captureException(error);
			} finally {
				setIsLoading(false);
			}
		},
		[setIsLoading, filter, logs, setHasMoreData]
	);

	const onRefresh = useCallback(
		async () => {
			await getLogs();
			userMetrics.trackEvent("org-audit: refresh logs");
		},
		[getLogs]
	);

	const getExportUrl = useCallback(
		() => {
			const exportFilter = { ...filter };
			const language = currentPerson?.get?.("personal")?.language ?? "en";
			const { timeZone, locale } = Intl.DateTimeFormat().resolvedOptions();

			// Ensure an end date is set on the query size
			exportFilter.end = getFilterEnd(exportFilter.end);

			const baseUrl = `${config.host}/v2/audit/export?token=${config.token}&language=${encodeURIComponent(language)}&timeZone=${timeZone}&locale=${locale}`;
			const url = getUrlWithFilterOptions(baseUrl, exportFilter);

			return url;
		},
		[filter, config, currentPerson]
	);

	const onCsvDownload = useCallback(
		async () => {
			setIsCsvDownloading(true);
			userMetrics.trackEvent("org-audit: download csv");
			try {
				const url = getExportUrl();
				await saveFile(url, "logs.csv");
			} catch (error) {
				Sentry.captureException(error);
			} finally {
				setIsCsvDownloading(false);
			}
		},
		[getExportUrl, saveFile]
	);

	useEffect(() => {
		getLogs();
	}, []);

	// Query logs if the filter changes
	useEffect(
		() => {
			getLogs();
		},
		[filter.start, filter.end, filter.group, filter.event, filter.performer, filter.owner],
	);

	const sortedLogs = useMemo(
		() => {
			return logs?.sort((a, b) => {
				return (
					moment(b?.["@timestamp"])
						.toDate()
						.getTime() -
					moment(a?.["@timestamp"])
						.toDate()
						.getTime()
				);
			});
		},
		[logs]
	);

	return (
		<Container>
			<FilterColumn>
				<Paper>
					<Filter
						groups={groups}
						onCsvDownload={onCsvDownload}
						isCsvDownloading={isCsvDownloading}
						onRefresh={onRefresh}
						filter={filter}
						setFilter={setFilter}
						modals={modals}
						setModals={setModals}
						localization={localization}
						people={people}
						users={users}
						loadMoreUsers={loadMoreUsers}
						searchUsers={searchUsers}
					/>
				</Paper>
			</FilterColumn>
			<LogViewColumn>
				<Paper style={{ width: "100%" }}>
					{sortedLogs.length || isLoading ? (
						<LocalizeConsumer>
							{() => (
								<LogList
									localization={localization}
									data={sortedLogs}
									loading={isLoading}
									loadMore={() => {
										getLogs(true);
										userMetrics.trackEvent("org-audit: load more logs");
									}}
									hasMore={hasMoreData}
									setMetadata={setMetadata}
								/>
							)}
						</LocalizeConsumer>
					) : (
						<Typography variant="h6" style={{ padding: "1rem" }}>
							{localization.get("audit_logs.noRowsFound")}
						</Typography>
					)}
				</Paper>
			</LogViewColumn>
			<MetadataModal
				isOpen={!!metadata && !!Object.keys(metadata).length}
				onClose={() => setMetadata(null)}
				metadata={metadata}
				localization={localization}
			/>
		</Container>
	);
}

const LogList = ({ data = [], loading, hasMore, localization, loadMore = () => {}, setMetadata }) => {
	const { userMetrics } = useUserMetrics();
	const localize = useLocalize();
	const rows = useMemo(
		() =>
			data.map((item, index) => {
				let itemData = item.data || {};
				return (
					<TableRow key={item.event + item["@timestamp"] + index} onClick={() => {
						setMetadata(itemData);
						userMetrics.trackEvent("org-audit: open metadata modal");
					}}>
						<TableCell>{formatDate(item["@timestamp"])}</TableCell>
						<TableCell>{localize(`audit.${item.event.replace("audit-", "")}`) || item.event}</TableCell>
						<TableCell>{itemData.performer}</TableCell>
						<TableCell>{itemData.owner}</TableCell>
					</TableRow>
				);
			}),
		[data, formatDate]
	);

	return (
		<TableContainer style={{ maxHeight: "100%" }}>
			<Table stickyHeader>
				<TableHead>
					<TableRow>
						<TableCell>{localization.get("search_time")}</TableCell>
						<TableCell>{localization.get("audit_logs.event")}</TableCell>
						<TableCell>{localization.get("audit_logs.performer")}</TableCell>
						<TableCell>{localization.get("audit_logs.target")}</TableCell>
					</TableRow>
					{loading && <ProgressBar  localization={localization} />}
				</TableHead>
				<TableBody>
					{rows}
					{hasMore && (
						<TableRow>
							<TableCell colSpan={100}>
								<div style={{ display: "flex" }}>
									<Button fullWidth onClick={loadMore}>
										{localization.get("groupforms.loadmore")}
									</Button>
								</div>
							</TableCell>
						</TableRow>
					)}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

const ClearButton = ({ onClick, localization}) => (
	<IconButton aria-label= {localization.get("delete_button")} onClick={onClick}>
		<DeleteOutlineIcon />
	</IconButton>
);

const Filter = ({
	onRefresh = () => {},
	onCsvDownload = () => {},
	isCsvDownloading = false,
	localization,
	groups = [],
	filter,
	setFilter,
	modals,
	setModals,
	people,
	users,
	loadMoreUsers,
	searchUsers,
}) => {
	const { userMetrics } = useUserMetrics();
	const onStartChange = useCallback(
		nextStart => {
			const isStartAfterEnd = filter.end && new Date(nextStart) > new Date(filter.end);
			if (isStartAfterEnd) {
				window.alert("End Date must be after Start Date");
			} else {
				setFilter.setStart(nextStart);
			}
			userMetrics.trackEvent("org-audit: filter by start date");
		},
		[filter.end, setFilter.setStart]
	);

	const onEndChange = useCallback(
		nextEnd => {
			if (filter.start && new Date(nextEnd) < new Date(filter.start)) {
				window.alert("End Date must be after Start Date");
			} else {
				setFilter.setEnd(nextEnd);
			}
			userMetrics.trackEvent("org-audit: filter by end date");
		},
		[filter.start, setFilter.setEnd]
	);

	const groupMap = useMemo(() => new Map(groups.map(item => [item._id, item])), [groups]);

	const renderGroupListSelect = useCallback(
		(item, onSelect) => {
			const listItem = (
				<ListItem button onClick={() => {
					onSelect(item);
					userMetrics.trackEvent("org-audit: filter by group", {
						group: item,
					});
				}} key={item}>
					<ListItemText primary={groupMap.get(item).name} secondary={groupMap.get(item).description} />
				</ListItem>
			);
			return listItem;
		},
		[groupMap]
	);

	const sortedUsers = useMemo(() => users.map(people.get).sort(by_name), [users]);
	const onUserSearchChange = (event) => {
		event.persist();
		const query = event.target.value;
		searchUsers(query);
	};
	const openUserSearchModal = (setOpenModal) => {
		setOpenModal(true);
		searchUsers("");
	};

	return (
		<List>
			<ListSelector
				open={modals.isGroupModalOpen}
				onSelected={setFilter.setGroup}
				list={Array.from(groupMap.keys())}
				onClose={() => setModals.setIsGroupModalOpen(false)}
				renderItem={renderGroupListSelect}
				onBackdropClick={() => {
					setModals.setIsGroupModalOpen(false);
					userMetrics.trackEvent("org-audit: close group filter popup");
				}}
			/>
			<DatePickerModal
				open={modals.isStartModalOpen}
				onClose={() => {
					setModals.setIsStartModalOpen(false);
					userMetrics.trackEvent("org-audit: close start date filter popup");
				}}
				value={filter.start}
				onSelected={onStartChange}
				localization={localization}
			/>
			<DatePickerModal
				endOfDay={true}
				open={modals.isEndModalOpen}
				onClose={() => {
					setModals.setIsEndModalOpen(false);
					userMetrics.trackEvent("org-audit: close end date filter popup");
				}}
				value={filter.end}
				onSelected={onEndChange}
				localization={localization}
			/>
			<EventSelector
				open={modals.isEventModalOpen}
				onClose={() => {
					setModals.setIsEventModalOpen(false);
					userMetrics.trackEvent("org-audit: close event filter popup");
				}}
				onSelected={setFilter.setEvent}
			/>
			<UserSelectionModal
				isOpen={modals.isPerformerModalOpen}
				onClose={() => {
					setModals.setIsPerformerModalOpen(false);
					userMetrics.trackEvent("org-audit: close performer filter popup");
				}}
				users={sortedUsers}
				loadMore={loadMoreUsers}
				onUserSearchChange={onUserSearchChange}
				localization={localization}
				onSelected={setFilter.updatePerformer}
			/>
			<UserSelectionModal
				isOpen={modals.isTargetModalOpen}
				onClose={() => {
					setModals.setIsTargetModalOpen(false);
					userMetrics.trackEvent("org-audit: close target filter popup");
				}}
				users={sortedUsers}
				loadMore={loadMoreUsers}
				onUserSearchChange={onUserSearchChange}
				localization={localization}
				onSelected={setFilter.updateTarget}
			/>

			<ListSubheader>
				{localization.get("audit_logs.filters")}
				<ListItemSecondaryAction>
					<IconButton aria-label= {localization.get("refresh_button")} role="button" title={localization.get("refresh_button")} onClick={onRefresh}>
						<CachedIcon />
					</IconButton>
				</ListItemSecondaryAction>
			</ListSubheader>
			<ListItem button onClick={() => {
				setModals.setIsStartModalOpen(true);
				userMetrics.trackEvent("org-audit: open start date filter popup");
			}}>
				<ListItemAvatar>
					<Avatar>
						<AccessTimeIcon />
					</Avatar>
				</ListItemAvatar>
				<ListItemText primary={filter.start && formatDate(filter.start)} secondary={localization.get("search_start")} />
				<ListItemSecondaryAction>
					{filter.start && <ClearButton localization={localization} onClick={() => {
						setFilter.setStart(null);
						userMetrics.trackEvent("org-audit: clear start date filter");
					}} />}
				</ListItemSecondaryAction>
			</ListItem>
			<ListItem button onClick={() => {
				setModals.setIsEndModalOpen(true);
				userMetrics.trackEvent("org-audit: open end date filter popup");
			}}>
				<ListItemAvatar>
					<Avatar>
						<AccessTimeIcon />
					</Avatar>
				</ListItemAvatar>
				<ListItemText primary={filter.end && formatDate(filter.end)} secondary={localization.get("audit_logs.end")} />
				<ListItemSecondaryAction>
					{filter.end && <ClearButton localization={localization} onClick={() => {
						setFilter.setEnd(null);
						userMetrics.trackEvent("org-audit: clear end date filter");
					}} />}
				</ListItemSecondaryAction>
			</ListItem>
			<ListItem button onClick={() => {
				setModals.setIsEventModalOpen(true);
				userMetrics.trackEvent("org-audit: open event filter popup");
			}}>
				<ListItemAvatar>
					<Avatar>
						<TouchAppIcon />
					</Avatar>
				</ListItemAvatar>
				<ListItemText primary={filter.event} secondary={localization.get("audit_logs.event")} />
				<ListItemSecondaryAction>
					{filter.event && <ClearButton localization={localization} onClick={() => {
						setFilter.setEvent(null);
						userMetrics.trackEvent("org-audit: clear event filter");
					}} />}
				</ListItemSecondaryAction>
			</ListItem>
			<ListItem button onClick={() => {
				openUserSearchModal(setModals.setIsPerformerModalOpen);
				userMetrics.trackEvent("org-audit: open performer filter popup");
			}}>
				<ListItemAvatar>
					<Avatar>
						<FaceIcon />
					</Avatar>
				</ListItemAvatar>
				<ListItemText primary={filter.performer__name} secondary={localization.get("audit_logs.performer")} />
				<ListItemSecondaryAction>
					{filter.performer && <ClearButton localization={localization} onClick={() => {
						setFilter.updatePerformer(null);
						userMetrics.trackEvent("org-audit: clear performer filter");
					}} />}
				</ListItemSecondaryAction>
			</ListItem>
			<ListItem button onClick={() => {
				openUserSearchModal(setModals.setIsTargetModalOpen);
				userMetrics.trackEvent("org-audit: open target filter popup");
			}}>
				<ListItemAvatar>
					<Avatar>
						<FaceIcon />
					</Avatar>
				</ListItemAvatar>
				<ListItemText primary={filter.owner__name} secondary={localization.get("audit_logs.target")} />
				<ListItemSecondaryAction>
					{filter.owner && <ClearButton localization={localization} onClick={() => {
						setFilter.updateTarget(null);
						userMetrics.trackEvent("org-audit: clear target filter");
					}} />}
				</ListItemSecondaryAction>
			</ListItem>
			<ListItem button onClick={() => {
				setModals.setIsGroupModalOpen(true);
				userMetrics.trackEvent("org-audit: open group filter popup");
			}}>
				<ListItemAvatar>
					<Avatar>
						<GroupIcon />
					</Avatar>
				</ListItemAvatar>
				<ListItemText
					primary={filter.group && groupMap.get(filter.group).name}
					secondary={localization.get("org_form_levels.group")}
				/>
				<ListItemSecondaryAction>
					{filter.group && <ClearButton localization={localization} onClick={() => {
						setFilter.setGroup(null);
						userMetrics.trackEvent("org-audit: clear group filter");
					}} />}
				</ListItemSecondaryAction>
			</ListItem>
			<Divider />
			<ListItem>
				<ListItemText primary={localization.get("group_users_overview_export_csv")} />
				<ListItemSecondaryAction>
					<IconButton onClick={onCsvDownload}>
						{isCsvDownloading ? <CircularProgress size="1.5rem" /> : <GetAppIcon />}
					</IconButton>
				</ListItemSecondaryAction>
			</ListItem>
		</List>
	);
};

function RenderUser({ user, onSelected }) {
	const { userMetrics } = useUserMetrics();
	if (!user._id) return;
	const text = Utility.format_name(user);
	return (
		<ListItem
			key={user._id}
			button
			className={"ae-plain"}
			onClick={() => {
				onSelected({
					userId: user._id,
					user__name: text,
				});
				userMetrics.trackEvent("org-audit: add user to filter", {
					user: user._id,
				});
			}}
		>
			<ListItemText primary={text} />
		</ListItem>
	);
}

const UserSelectionModal = props => {
	const classes = useStyles();
	const {
		onClose,
		isOpen,
		users,
		loadMore,
		onUserSearchChange,
		localization,
		onSelected,
	} = props;

	return (
		<Dialog
			open={isOpen}
			onClose={onClose}
			scroll={"paper"}
			fullWidth={true}
			classes={{ paper: classes.dialog }}
		>
			<DialogContent>
				<TextField
					placeholder={localization.get("search_onNameorpatientNum")}
					onChange={onUserSearchChange}
					style={{ width: "100%" }}
				/>
				<LazyList
					className="ae-scrollable"
					loadMore={loadMore}
					renderItem={(user) => <RenderUser user={user} onSelected={onSelected} />}
					items={users}
					noScroll
				/>
			</DialogContent>
		</Dialog>
	);
};

const DatePickerModal = ({ onSelected, value, open, onClose, endOfDay, localization }) => {
	const onChangeDate = useCallback(
		date => {
			onSelected(endOfDay ? date?.endOf("day")?.toISOString() : date?.startOf("day")?.toISOString());
		},
		[onSelected]
	);

	const containerStyles = {
		width: "fit-content",
		height: "100%",
		display: "flex",
		margin: "auto"
	};

	return (
		<Modal open={open} onClose={onClose} onBackdropClick={onClose}>
			<div style={containerStyles}>
				<div style={{ margin: "auto" }}>
					<DatePicker
						autoOk
						orientation="landscape"
						variant="static"
						openTo="date"
						leftArrowButtonProps={{ "aria-label": localization.get("previous_month") }}
						rightArrowButtonProps={{ "aria-label": localization.get("next_month") }}
						inputProps={{ color: "red" }}
						value={value}
						onChange={onChangeDate}
					/>
				</div>
			</div>
		</Modal>
	);
};

function by_name(x, y){
	return order(x.lname?.toLowerCase() + x.fname?.toLowerCase(), y.lname?.toLowerCase() + y.fname?.toLowerCase());
}

function order(x, y){
	if(x < y)
		return -1;
	if(x > y)
		return 1;
	return 0;
}
