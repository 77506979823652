/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */



import Store from "./store";
import par from "par";

import * as Sentry from "@sentry/react";

export default CarePlanStore;

function CarePlanStore(api, events, config) {
	var store = new Store();

	store.raw_get = store.get;
	store.get = get;

	var tracking = [];

	return store;

	function get(id) {
		var existing = store.raw_get(id);
		if (existing) return existing;

		if (!isTracking(id))
			track(id);

		return {
			data: {}
		};
	}

	function isTracking(id) {
		return tracking.indexOf(id) !== -1;
	}

	function track(id) {
		tracking.push(id);
		retrieveCareplan(id);

		// Tracking proposals
		var orgGroup = config.orgGroup;
		if(!orgGroup) return;
		events.on("mqtt:orggoup:/" + orgGroup + "/u/" + id + "/careplan/Client/updated", par(retrieveCareplan, id));
		events.on("mqtt:orggoup:/" + orgGroup + "/u/" + id + "/careplan/Appointments/updated", par(retrieveCareplan, id));
		events.on("mqtt:orggoup:/" + orgGroup + "/u/" + id + "/careplan/CareTeam/updated", par(retrieveCareplan, id));
		events.on("mqtt:orggoup:/" + orgGroup + "/u/" + id + "/careplan/DocumentControl/updated", par(retrieveCareplan, id));
		events.on("mqtt:orggoup:/" + orgGroup + "/u/" + id + "/careplan/Goals/updated", par(retrieveCareplan, id));
		events.on("mqtt:orggoup:/" + orgGroup + "/u/" + id + "/careplan/HealthAssessments/updated", par(retrieveCareplan, id));
		events.on("mqtt:orggoup:/" + orgGroup + "/u/" + id + "/careplan/HealthConditions/updated", par(retrieveCareplan, id));
		events.on("mqtt:orggoup:/" + orgGroup + "/u/" + id + "/careplan/HospitalVisits/updated", par(retrieveCareplan, id));
		events.on("mqtt:orggoup:/" + orgGroup + "/u/" + id + "/careplan/OtherTreatments/updated", par(retrieveCareplan, id));
		events.on("mqtt:orggoup:/" + orgGroup + "/u/" + id + "/careplan/Situation/updated", par(retrieveCareplan, id));
		events.on("mqtt:orggoup:/" + orgGroup + "/u/" + id + "/careplan/Supports/updated", par(retrieveCareplan, id));
		events.on("mqtt:orggoup:/" + orgGroup + "/u/" + id + "/careplan/Treatments/updated", par(retrieveCareplan, id));

	}

	function retrieveCareplan(id) {
		var existing = store.get(id);
		if (!existing) return;

		api.org.users.careplan.data(id)
			.then(par(setChanges, id))
			.catch(error => {
				Sentry.captureException(error);
				console.error.bind(console, error);
			});

	}

	function setChanges(id, data) {
		var plan = data.userCareplan;
		store.set(id, plan);
	}
}
