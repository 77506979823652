/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, May 3, 2018
 * For information or permission request, email info@aetonixsystems.com
 */

import MakeAppComponent from "../shared/AppComponent";

import { LogisticsPage, CustomReportsPage, PatientsPage, AdminOrganizationsPage, LogisticsNotificationsPage, PatientNotificationsPage } from "@aetonix/web-ui";

import GroupCareplanUI from "../group-careplans/ui.jsx";
import OverviewUI from "../overview/ui.jsx";
import UserFallLiveUI from "../user-fall-live/ui.jsx";
import UrgencyLiveUI from "../urgency-live/ui.jsx";
import RemindersLiveUI from "../reminders-live/ui.jsx";
import UserWanterLiveUI from "../user-wander-live/ui.jsx";
import GroupUsersUI from "../group-users/ui.jsx";
import UserManagementUI from "../user-management/ui.jsx";
import UserUrgencyUI from "../user-urgency/ui.jsx";
import UserBraceletsUI from "../user-bracelets/ui.jsx";
import OrgStaffUI from "../org-staff/ui.jsx";
import UserFallHistoryUI from "../user-fall-history/ui.jsx";
import GroupCallsUI from "../group-calls/ui.jsx";
import RemindersHistoryUI from "../reminders-history/ui.jsx";
import UserWanderHistoryUI from "../user-wander-history/ui.jsx";
import UserUrgencyHistoryUI from "../user-urgency-history/ui.jsx";
import OrgFormsSubmissionHistoryUI from "../org-forms-submission-history/ui.jsx";
import OrgFormsChangesHistoryUI from "../org-forms-changes-history/ui.jsx";
import UserStepsHistoryUI from "../user-steps-history/ui.jsx";
import UserGlucoseHistoryUI from "../user-glucose-history/ui.jsx";
import UserWeightHistoryUI from "../user-weight-history/ui.jsx";
import UserBloodPressureHistoryUI from "../user-bloodpressure-history/ui.jsx";
import UserBloodOxygenHistoryUI from "../user-bloodoxygen-history/ui.jsx";
import UserBodyTemperatureHistoryUI from "../user-bodytemperature-history/ui.jsx";
import OrgGroupsUI from "../org-groups/ui.jsx";
import OrgFormsUI from "../org-forms/ui.jsx";
import OrgWorkflowUI from "../org-workflow/ui.jsx";
import OrgManagersUI from "../org-managers/ui.jsx";
import AdminFormsUI from "../admin-forms/ui.jsx";
import AdminWorkflowUI from "../admin-workflow/ui.jsx";
import OrgAuditUI from "../org-audit/ui.jsx";
import AdminCallsUI from "../admin-calls/ui.jsx";
import AdminBraceletLiveUI from "../admin-bracelets-live/ui.jsx";
import DashboardUI from "../dashboards/ui.jsx";
import CallgroupsCreationUI from "../group-callgroups/ui.jsx";
import ResourcesCreationUI from "../org-resources/ui.jsx";
import UserResourcesUI from "../user-resources/ui.jsx";
import AdminResourcesUI from "../admin-resources/ui.jsx";
import AvailabilityUI from "../availability/ui.jsx";
import LivechatCreationUI from "../group-livechats/ui.jsx";
import GroupUsersOverviewUI from "../group-users-overview/ui.jsx";
import UserThresholdsUI from "../user-thresholds/ui.jsx";
import GroupWorkflowAutostartUI from "../group-workflow-autostart/ui.jsx";
import AdminPromoCodeUI from "../admin-promo-code/ui.jsx";
import AdminGroupSettingsUI from "../admin-group-settings/ui.jsx";
import GroupManageGroupsUI from "../group-manage-groups/ui.jsx";
import AdminStatsUI from "../admin-stats/ui.jsx";
import OrgStatusUI from "../org-stats/ui.jsx";
import AdminTablesUI from "../admin-tables/ui.jsx";
import WorkflowHistoryUI from "../workflow-history/ui.jsx";
import AdminUserManagementUI from "../admin-user-management/ui.jsx";
import AdminTasksUI from "../admin-tasks/ui.jsx";
import AdminOptionSchemasUI from "../admin-options-schemas/ui.jsx";
import GroupOptionsUI from "../group-options/ui.jsx";
import OrgDataReportsUI from "../org-reports/ui.jsx";
import CustomIndicatorsUI from "../org-custom-indicators/ui.jsx";
import CustomIndicatorHistoryUI from "../user-custom-indicator-history/ui.jsx";

import { RootComponent as UserOverview } from "../user-overview";

const GroupCareplans = MakeAppComponent(GroupCareplanUI);
const Overview = MakeAppComponent(OverviewUI);
const UserFallLive = MakeAppComponent(UserFallLiveUI);
const UrgencyLive = MakeAppComponent(UrgencyLiveUI);
const RemindersLive = MakeAppComponent(RemindersLiveUI);
const UserWanderLive = MakeAppComponent(UserWanterLiveUI);
const GroupUsers = MakeAppComponent(GroupUsersUI);
const UserManagement = MakeAppComponent(UserManagementUI);
const UserUrgency = MakeAppComponent(UserUrgencyUI);
const UserBracelets = MakeAppComponent(UserBraceletsUI);
const OrgStaff = MakeAppComponent(OrgStaffUI);
const UserFallHistory = MakeAppComponent(UserFallHistoryUI);
const GroupCalls = MakeAppComponent(GroupCallsUI);
const RemindersHistory = MakeAppComponent(RemindersHistoryUI);
const UserWanderHistory = MakeAppComponent(UserWanderHistoryUI);
const UserUrgencyHistory = MakeAppComponent(UserUrgencyHistoryUI);
const OrgFormsSubmissionHistory = MakeAppComponent(OrgFormsSubmissionHistoryUI);
const OrgFormsChangesHistory = MakeAppComponent(OrgFormsChangesHistoryUI);
const UserStepsHistory = MakeAppComponent(UserStepsHistoryUI);
const UserGlucoseHistory = MakeAppComponent(UserGlucoseHistoryUI);
const UserWeightHistory = MakeAppComponent(UserWeightHistoryUI);
const UserBloodPressureHistory = MakeAppComponent(UserBloodPressureHistoryUI);
const UserBloodOxygenHistory = MakeAppComponent(UserBloodOxygenHistoryUI);
const UserBodyTemperatureHistory = MakeAppComponent(UserBodyTemperatureHistoryUI);
const OrgGroups = MakeAppComponent(OrgGroupsUI);
const OrgForms = MakeAppComponent(OrgFormsUI);
const OrgWorkflow = MakeAppComponent(OrgWorkflowUI);
const OrgManagers = MakeAppComponent(OrgManagersUI);
const AdminForms = MakeAppComponent(AdminFormsUI);
const AdminWorkflow = MakeAppComponent(AdminWorkflowUI);
const OrgAudit = MakeAppComponent(OrgAuditUI);
const AdminCalls = MakeAppComponent(AdminCallsUI);
const AdminBraceletsLive = MakeAppComponent(AdminBraceletLiveUI);
const Dashboard = MakeAppComponent(DashboardUI);
const CallgroupsCreation = MakeAppComponent(CallgroupsCreationUI);
const ResourcesCreation = MakeAppComponent(ResourcesCreationUI);
const UserResources = MakeAppComponent(UserResourcesUI);
const AdminResources = MakeAppComponent(AdminResourcesUI);
const Availability = MakeAppComponent(AvailabilityUI);
const LivechatCreation = MakeAppComponent(LivechatCreationUI);
const GroupUsersOverview = MakeAppComponent(GroupUsersOverviewUI);
const UserThresholds = MakeAppComponent(UserThresholdsUI);
const GroupWorkflowAutostart = MakeAppComponent(GroupWorkflowAutostartUI);
const AdminPromoCode = MakeAppComponent(AdminPromoCodeUI);
const AdminGroupSettings = MakeAppComponent(AdminGroupSettingsUI);
const GroupManageGroups = MakeAppComponent(GroupManageGroupsUI);
const AdminStats = MakeAppComponent(AdminStatsUI);
const OrgStats = MakeAppComponent(OrgStatusUI);
const AdminTables = MakeAppComponent(AdminTablesUI);
const WorkflowHistory = MakeAppComponent(WorkflowHistoryUI);
const AdminUserManagement = MakeAppComponent(AdminUserManagementUI);
const AdminTasks = MakeAppComponent(AdminTasksUI);
const AdminOptionSchemas = MakeAppComponent(AdminOptionSchemasUI);
const GroupOptions = MakeAppComponent(GroupOptionsUI);
const OrgDataReports = MakeAppComponent(OrgDataReportsUI);
const CustomIndicators = MakeAppComponent(CustomIndicatorsUI);
const CustomIndicatorHistory = MakeAppComponent(CustomIndicatorHistoryUI);

/* Routes object
* key: Used for feature flag access, admin/general/logistics pages don't need this flag
* name: Localization key
* url: Route url
* type: Dashboards category on all dashboards hierarchy page
* component: Component for page
*/

export default function() {
	return {
		notifications: [
			{
				name: "dashboards_logistics_notifications",
				url: "/logisticNotifications/",
				type: "logistic_notifications",
				component: () => LogisticsNotificationsPage()
			},
			{
				name: "dashboards_patients_notifications",
				url: "/patientNotifications/",
				type: "patient_notifications",
				component: () => PatientNotificationsPage()
			}
		],
		logistics: [
			{
				name: "dashboards_logistics",
				url: "/logistics/",
				type: "logistics",
				component: () => LogisticsPage()
			}
		],
		staff: [
			{
				key: "dashboards_patients",
				name: "dashboards_patients",
				url: "/patients/",
				type: "user",
				component: PatientsPage
			},
			{
				key: "dashboards_availability_label",
				name: "dashboards_availability_label",
				url: "/availability/",
				type: "monitor",
				component: Availability
			},
			{
				key: "",
				url: "/user-overview/:id",
				type: "",
				component: UserOverview
			},
			{
				key: "dashboards_group_careplans",
				name: "dashboards_group_careplans",
				url: "/group-careplans/",
				type: "group",
				component: GroupCareplans
			},
			{
				key: "dashboards_overview",
				name: "dashboards_overview",
				url: "/overview/",
				type: "monitor",
				component: Overview
			},
			{
				key: "dashboards_group_user_overview",
				name: "dashboards_group_user_overview",
				url: "/group-users-overview",
				type: "monitor",
				component: GroupUsersOverview
			},
			{
				key: "dashboards_userfalllive",
				name: "dashboards_userfalllive",
				url: "/user-fall-live/",
				type: "monitor",
				component: UserFallLive
			},
			{
				key: "dashboards_urgency",
				name: "dashboards_urgency",
				url: "/urgency-live/",
				type: "monitor",
				component: UrgencyLive
			},
			{
				key: "dashboards_reminders",
				name: "dashboards_reminders",
				url: "/reminders-live/",
				type: "monitor",
				component: RemindersLive
			},
			{
				key: "dashboards_userwanderlive",
				name: "dashboards_userwanderlive",
				url: "/user-wander-live/",
				type: "monitor",
				component: UserWanderLive
			},
			{
				key: "dashboards_user",
				name: "dashboards_user",
				url: "/group-users/",
				type: "user",
				component: GroupUsers
			},
			{
				key: "dashboards_usermanage",
				name: "dashboards_usermanage",
				url: "/user-management/",
				type: "user",
				component: UserManagement
			},
			{
				key: "dashboards_userresources",
				name: "dashboards_userresources",
				url: "/user-resources/",
				type: "user",
				component: UserResources
			},
			{
				key: "dashboards_user_thresholds",
				name: "dashboards_user_thresholds",
				url: "/user-thresholds/",
				type: "user",
				component: UserThresholds
			},
			{
				key: "dashboards_userurgency",
				name: "dashboards_userurgency",
				url: "/user-urgency/",
				type: "user",
				component: UserUrgency
			},
			{
				key: "dashboards_userbracelets",
				name: "dashboards_userbracelets",
				url: "/user-bracelets/",
				type: "user",
				component: UserBracelets
			},
			{
				key: "dashboards_staff",
				name: "dashboards_staff",
				url: "/org-staff/",
				type: "group",
				component: OrgStaff
			},
			{
				key: "dashboards_userfallhistory",
				name: "dashboards_userfallhistory",
				url: "/user-fall-history/",
				type: "history",
				component: UserFallHistory
			},
			{
				key: "",
				name: "dashboards_customindicatorhistory",
				url: "/user-custom-indicator-history/:indicatorId/",
				type: "customindicator",
				component: CustomIndicatorHistory
			},
			{
				key: "dashboards_groupcalls",
				name: "dashboards_groupcalls",
				url: "/group-calls/",
				type: "history",
				component: GroupCalls
			},
			{
				key: "dashboards_reminderhistory",
				name: "dashboards_reminderhistory",
				url: "/reminders-history/",
				type: "history",
				component: RemindersHistory
			},
			{
				key: "dashboards_userwanderhistory",
				name: "dashboards_userwanderhistory",
				url: "/user-wander-history/",
				type: "history",
				component: UserWanderHistory
			},
			{
				key: "dashboards_urgencycallhistory",
				name: "dashboards_urgencycallhistory",
				url: "/user-urgency-history/",
				type: "history",
				component: UserUrgencyHistory
			},
			{
				key: "dashboards_formSubmissionsHistory",
				name: "dashboards_formSubmissionsHistory",
				url: "/org-forms-submission-history/",
				type: "history",
				component: OrgFormsSubmissionHistory
			},
			{
				key: "dashboards_formSubmissionsChangeHistory",
				name: "dashboards_formSubmissionsChangeHistory",
				url: "/org-forms-changes-history/",
				type: "group",
				component: OrgFormsChangesHistory
			},
			{
				key: "dashboards_stepsHistory",
				name: "dashboards_stepsHistory",
				url: "/user-steps-history/",
				type: "myhealth",
				component: UserStepsHistory
			},
			{
				key: "dashboards_glucoseHistory",
				name: "dashboards_glucoseHistory",
				url: "/user-glucose-history/",
				type: "myhealth",
				component: UserGlucoseHistory
			},
			{
				key: "dashboards_weightHistory",
				name: "dashboards_weightHistory",
				url: "/user-weight-history/",
				type: "myhealth",
				component: UserWeightHistory
			},
			{
				key: "dashboards_bloodpressureHistory",
				name: "dashboards_bloodpressureHistory",
				url: "/user-bloodpressure-history/",
				type: "myhealth",
				component: UserBloodPressureHistory
			},
			{
				key: "dashboards_bloodoxygenHistory",
				name: "dashboards_bloodoxygenHistory",
				url: "/user-bloodoxygen-history/",
				type: "myhealth",
				component: UserBloodOxygenHistory
			},
			{
				key: "dashboards_bodytemperatureHistory",
				name: "dashboards_bodytemperatureHistory",
				url: "/user-bodytemperature-history/",
				type: "myhealth",
				component: UserBodyTemperatureHistory
			},
			{
				key: "dashboards_group_livechats",
				name: "dashboards_group_livechats",
				url: "/group-livechats/",
				type: "group",
				component: LivechatCreation
			},
			{
				key: "dashboards_callgroups",
				name: "dashboards_callgroups",
				url: "/group-callgroups",
				type: "group",
				component: CallgroupsCreation
			},
			{
				key: "dashboards_group_workflowAutostart",
				name: "dashboards_group_workflowAutostart",
				url: "/group-pathway-autostart/",
				type: "group",
				component: GroupWorkflowAutostart
			},
			{
				key: "dashboards_manager_groups",
				name: "dashboards_manager_groups",
				url: "/group-manage-groups/",
				type: "group",
				component: GroupManageGroups
			},
			{
				key: "dashboards_options",
				name: "dashboards_options",
				url: "/group-options/",
				type: "group",
				component: GroupOptions
			}
		],
		"org:manager": [
			{
				key: "dashboards_group",
				name: "dashboards_group",
				url: "/org-groups/",
				type: "group",
				component: OrgGroups
			},
			{
				key: "dashboards_forms",
				name: "dashboards_forms",
				url: "/org-forms/",
				type: "org",
				component: OrgForms
			},
			{
				key: "dashboards_workflow",
				name: "dashboards_workflow",
				url: "/org-pathway/",
				type: "org",
				component: OrgWorkflow
			},
			{
				key: "dashboards_resources",
				name: "dashboards_resources",
				url: "/org-resources/",
				type: "org",
				component: ResourcesCreation
			},
			{
				key: "dashboards_manager",
				name: "dashboards_manager",
				url: "/org-managers/",
				type: "org",
				component: OrgManagers
			},
			{
				key: "dashboards_org_stats",
				name: "dashboards_org_stats",
				url: "/org-stats/",
				type: "org",
				component: OrgStats
			},
			{
				key: "dashboards_audit",
				name: "dashboards_audit",
				url: "/org-audit/",
				type: "org",
				component: OrgAudit
			},
			{
				key: "dashboards_org_custom_indicators",
				name: "dashboards_org_custom_indicators",
				url: "/org-custom-indicators",
				type: "org",
				component: CustomIndicators
			},
			{
				key: "dashboards_org_reports",
				name: "dashboards_org_reports",
				url: "/org-reports",
				type: "org",
				component: OrgDataReports
			},
			{
				key: "dashboards_custom_reports",
				name: "dashboards_custom_reports",
				url: "/custom-reports/",
				type: "org",
				component: CustomReportsPage
			}
		],
		admin: [
			{
				name: "dashboards_forms",
				url: "/admin-forms/",
				type: "admin",
				component: AdminForms
			},
			{
				name: "dashboards_workflow",
				url: "/admin-pathway/",
				type: "admin",
				component: AdminWorkflow
			},
			{
				name: "dashboards_resources",
				url: "/admin-resources/",
				type: "admin",
				component: AdminResources
			},
			{
				name: "dashboards_tables",
				url: "/admin-tables/",
				type: "admin",
				component: AdminTables
			},
			{
				name: "dashboards_admin_options",
				url: "/admin-options-schemas/",
				type: "admin",
				component: AdminOptionSchemas
			},
			{
				name: "dashboards_create",
				url: "/org-creation/",
				type: "org",
				component: AdminOrganizationsPage
			},
			{
				name: "dashboards_admincalls",
				url: "/admin-calls/",
				type: "monitor",
				component: AdminCalls
			},
			{
				name: "dashboards_workflowHistory",
				url: "/pathway-history/",
				type: "history",
				component: WorkflowHistory
			},
			{
				name: "dashboards_adminbracelets",
				url: "/admin-bracelets-live/",
				type: "monitor",
				component: AdminBraceletsLive
			},
			{
				name: "admin_promo_code_title",
				url: "/admin-promo-code/",
				type: "admin",
				component: AdminPromoCode
			},
			{
				name: "admin_group_settings_title",
				url: "/admin-group-settings/",
				type: "admin",
				component: AdminGroupSettings
			},
			{
				name: "dashboards_stats",
				url: "/admin-stats/",
				type: "admin",
				component: AdminStats
			},
			{
				name: "dashboards_user_management",
				url: "/admin-user-management/",
				type: "admin",
				component: AdminUserManagement
			},
			{
				name: "dashboards_tasks",
				url: "/admin-tasks/",
				type: "admin",
				component: AdminTasks
			}
		],
		dashboard: [
			{
				name: "dashboard_dashboard",
				url: "/",
				type: "dashboard",
				component: Dashboard
			}
		]
	};
};
