
/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import par from "par";

import debounce from "debounce";
import defineUI from "../shared/define";
import render from "./render.jsx";
import PersonStore from "../stores/person_store";
import OrgManagerStore from "../stores/org_manager_store";
import LocalizationStore from "../stores/localization_store";
import CurrentPersonInfoStore from "../stores/current_person_info_store";
import CareplanNotificationStore from "../stores/careplan_notice_store";
import ConnectionStore from "../stores/connection_store";
import OrgAutoLogout from "../stores/org_autologout_store.js";
import OrgMultifactor from "../stores/org_multifactor_store";
import makeStoreFactory from "../shared/stores";

const makeStores = makeStoreFactory({
	people: PersonStore,
	managers: OrgManagerStore,
	currentPerson: CurrentPersonInfoStore,
	localization: LocalizationStore,
	careplanChangeList: CareplanNotificationStore,
	connection: ConnectionStore,
	logout: OrgAutoLogout,
	multifactor: OrgMultifactor
});

function make_ui(api, events, config, userMetrics) {
	var storeStore = makeStores(api, events, config);

	userMetrics.trackEvent("page view", {
		"page": "org-managers",
		"category": "org",
		"permission level": "org:manager",
	});

	return defineUI({
		getInitialState: getInitialState,
		componentDidMount: listen_for_changes,
		render: render,
		componentWillUnmount: removeListeners
	});

	function removeListeners() {
		events.off();
	}

	function listen_for_changes() {
		storeStore.listen(par(handle_change, this));
	}

	function handle_change(component, stores) {
		component.setState({
			people: stores.people,
			managers: stores.managers.all(),
			currentPerson: stores.currentPerson,
			localization: stores.localization,
			careplanChangeList: stores.careplanChangeList,
			connection: stores.connection,
			logout: stores.logout,
			multifactor: stores.multifactor
		});
	}

	function getInitialState() {
		return {
			people: storeStore.stores.people,
			currentPerson: storeStore.stores.currentPerson,
			managers: [],
			toRemove: {},
			searching: false,
			removing: false,
			removeManager: api.org.managers.remove,
			addManager: api.org.managers.add,
			searchNew: debounce(searchNew.bind(this), 200),
			searchExist: par(searchExist, storeStore.stores.managers),
			searchResults: [],
			loadMore: par(loadMore, storeStore.stores.managers),
			localization: storeStore.stores.localization,
			careplanChangeList: storeStore.stores.careplanChangeList,
			connection: storeStore.stores.connection,
			logout: storeStore.stores.logout,
			setAutoLogout: setAutoLogout,
			multifactor: storeStore.stores.multifactor,
			setMultifactor: setMultifactor,
			updateRetention,
			showToast: false,
			userMetrics,
		};
	}

	function searchNew(query) {

		var filter = {
			type: {
				exclude: ["atouchaway", "user:mobile", "simplified"]
			}
		};

		api.people.search(query, filter).then(set_search_results.bind(this));
	}

	function loadMore(managers) {
		return managers.more();
	}

	function set_search_results(results) {
		var managers = storeStore.stores.managers.all();
		var filtered = results.filter(function (item) {
			return (managers.indexOf(item._id) === -1);
		});
		this.setState({
			searchResults: filtered
		});
	}

	function searchExist(managers, event) {
		event.persist();
		var query = event.target.value;
		return managers.search(query);
	}

	function setAutoLogout(data) {
		return api.org.managers.autologout.set(data);
	}

	function setMultifactor(data) {
		return api.org.managers.multifactor.set(data);
	}

	function updateRetention(component, value){
		return api.org.dataRetention.update(value)
			.then(() => {
				component.setState({
					showToast: true
				});
			});
	}
}

export default make_ui;
