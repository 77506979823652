import React, { useContext } from "react";
import PropTypes from "prop-types";
export const LocalizeFromContext = React.createContext(() => {});

export const LocalizeFromProvider = (props, context) => {
	return (
		<LocalizeFromContext.Provider {...props} value={context.localizeFrom} />
	);
};

export const LocalizeFromConsumer = LocalizeFromContext.Consumer;

export const useLocalizeFrom = () => {
	return useContext(LocalizeFromContext);
};

LocalizeFromProvider.contextTypes = {
	formatDate: PropTypes.func,
	localize: PropTypes.func,
	localizeFrom: PropTypes.func,
	dispatch: PropTypes.func
};
