/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */



import Store from "./store";
import par from "par";
import clone from "clone";

import * as Sentry from "@sentry/react";

export default CarePlanChangeStore;

function CarePlanChangeStore(api, events, config) {

	var store = new Store();

	store.raw_get = store.get;
	store.get = get;

	var tracking = [];

	return store;

	function get(id) {
		var existing = store.raw_get(id);
		if (existing) return existing;

		if (!isTracking(id))
			track(id);

		return [];
	}

	function isTracking(id) {
		return tracking.indexOf(id) !== -1;
	}

	function track(id) {
		tracking.push(id);
		update_changes(id);

		var orgGroup = config.orgGroup;
		if(!orgGroup) return;
		// Tracking confirmed changes
		events.on("mqtt:orggoup:/" + orgGroup + "/u/" + id + "/careplan/Client/changes/confirmed", par(addChange, id));
		events.on("mqtt:orggoup:/" + orgGroup + "/u/" + id + "/careplan/Appointments/changes/confirmed", par(addChange, id));
		events.on("mqtt:orggoup:/" + orgGroup + "/u/" + id + "/careplan/CareTeam/changes/confirmed", par(addChange, id));
		events.on("mqtt:orggoup:/" + orgGroup + "/u/" + id + "/careplan/DocumentControl/changes/confirmed", par(addChange, id));
		events.on("mqtt:orggoup:/" + orgGroup + "/u/" + id + "/careplan/Goals/changes/confirmed", par(addChange, id));
		events.on("mqtt:orggoup:/" + orgGroup + "/u/" + id + "/careplan/HealthAssessments/changes/confirmed", par(addChange, id));
		events.on("mqtt:orggoup:/" + orgGroup + "/u/" + id + "/careplan/HealthConditions/changes/confirmed", par(addChange, id));
		events.on("mqtt:orggoup:/" + orgGroup + "/u/" + id + "/careplan/HospitalVisits/changes/confirmed", par(addChange, id));
		events.on("mqtt:orggoup:/" + orgGroup + "/u/" + id + "/careplan/OtherTreatments/changes/confirmed", par(addChange, id));
		events.on("mqtt:orggoup:/" + orgGroup + "/u/" + id + "/careplan/Situation/changes/confirmed", par(addChange, id));
		events.on("mqtt:orggoup:/" + orgGroup + "/u/" + id + "/careplan/Supports/changes/confirmed", par(addChange, id));
		events.on("mqtt:orggoup:/" + orgGroup + "/u/" + id + "/careplan/Treatments/changes/confirmed", par(addChange, id));
		// Tracking change commits
		events.on("mqtt:orggoup:/" + orgGroup + "/u/" + id + "/careplan/Client/changes/committed", par(removeSection, id, "Client"));
		events.on("mqtt:orggoup:/" + orgGroup + "/u/" + id + "/careplan/Appointments/changes/committed", par(removeSection, id, "Appointments"));
		events.on("mqtt:orggoup:/" + orgGroup + "/u/" + id + "/careplan/CareTeam/changes/committed", par(removeSection, id, "CareTeam"));
		events.on("mqtt:orggoup:/" + orgGroup + "/u/" + id + "/careplan/DocumentControl/changes/committed", par(removeSection, id, "DocumentControlent"));
		events.on("mqtt:orggoup:/" + orgGroup + "/u/" + id + "/careplan/Goals/changes/committed", par(removeSection, id, "Goals"));
		events.on("mqtt:orggoup:/" + orgGroup + "/u/" + id + "/careplan/HealthAssessments/changes/committed", par(removeSection, id, "HealthAssessments"));
		events.on("mqtt:orggoup:/" + orgGroup + "/u/" + id + "/careplan/HealthConditions/changes/committed", par(removeSection, id, "HealthConditions"));
		events.on("mqtt:orggoup:/" + orgGroup + "/u/" + id + "/careplan/HospitalVisits/changes/committed", par(removeSection, id, "HospitalVisits"));
		events.on("mqtt:orggoup:/" + orgGroup + "/u/" + id + "/careplan/OtherTreatments/changes/committed", par(removeSection, id, "OtherTreatments"));
		events.on("mqtt:orggoup:/" + orgGroup + "/u/" + id + "/careplan/Situation/changes/committed", par(removeSection, id, "Situation"));
		events.on("mqtt:orggoup:/" + orgGroup + "/u/" + id + "/careplan/Supports/changes/committed", par(removeSection, id, "Supports"));
		events.on("mqtt:orggoup:/" + orgGroup + "/u/" + id + "/careplan/Treatments/changes/committed", par(removeSection, id, "Treatments"));
	}

	function update_changes(id) {
		api.org.users.careplan.changes.pending(id)
			.then(par(setChanges, id))
			.catch(error => {
				Sentry.captureException(error);
				console.error.bind(console, error);
			});
	}

	function addChange(id) {
		var existing = store.get(id);
		if (!existing) return;

		update_changes(id);
	}

	// This function will remove a Section
	function removeSection(id, section) {
		var existing = store.get(id);
		if (!existing) return;

		var new_changes = clone(existing);
		delete new_changes[section];

		store.set(id, new_changes);
	}

	function setChanges(id, changes) {
		var added = changes.reduce(function(acc, curr) {
			if (acc[curr.section]) {
				acc[curr.section] = acc[curr.section].concat(curr);
			} else {
				acc[curr.section] = [curr];
			}
			return acc;
		}, {});

		store.set(id, added);
	}
}
