import * as yup from "yup";

const formData = {
	gender: {
		male: "gender.male",
		female: "gender.female",
		other: "gender.other"
	},
	country: {
		canada: "country.canada",
		"united states": "country.united states",
		"united kingdom": "country.united kingdom"
	},
	regions: {
		CAC: {
			optGroup: "regions.CAC"
		},
		AB: "regions.AB",
		BC: "regions.BC",
		MB: "regions.MB",
		NB: "regions.NB",
		NL: "regions.NL",
		NS: "regions.NS",
		NT: "regions.NT",
		NU: "regions.NU",
		ON: "regions.ON",
		PE: "regions.PE",
		QC: "regions.QC",
		SK: "regions.SK",
		YT: "regions.YT",
		USC: {
			optGroup: "regions.USC"
		},
		AL: "regions.AL",
		AK: "regions.AK",
		AZ: "regions.AZ",
		AR: "regions.AR",
		CA: "regions.CA",
		CO: "regions.CO",
		CT: "regions.CT",
		DE: "regions.DE",
		FL: "regions.FL",
		GA: "regions.GA",
		HI: "regions.HI",
		ID: "regions.ID",
		IL: "regions.IL",
		IN: "regions.IN",
		IA: "regions.IA",
		KS: "regions.KS",
		KY: "regions.KY",
		LA: "regions.LA",
		ME: "regions.ME",
		MD: "regions.MD",
		MA: "regions.MA",
		MI: "regions.MI",
		MN: "regions.MN",
		MS: "regions.MS",
		MO: "regions.MO",
		MT: "regions.MT",
		NE: "regions.NE",
		NV: "regions.NV",
		NH: "regions.NH",
		NJ: "regions.NJ",
		NM: "regions.NM",
		NY: "regions.NY",
		NC: "regions.NC",
		ND: "regions.ND",
		OH: "regions.OH",
		OK: "regions.OK",
		OR: "regions.OR",
		PA: "regions.PA",
		RI: "regions.RI",
		SC: "regions.SC",
		SD: "regions.SD",
		TN: "regions.TN",
		TX: "regions.TX",
		UT: "regions.UT",
		VT: "regions.VT",
		VA: "regions.VA",
		WA: "regions.WA",
		WV: "regions.WV",
		WI: "regions.WI",
		WY: "regions.WY",
		UKC: {
			optGroup: "regions.UKC"
		},
		ENG: "regions.ENG",
		NIR: "regions.NIR",
		SCT: "regions.SCT",
		WLS: "regions.WLS"
	},
	languages: {
		en: "languages.en",
		fr: "languages.fr",
		cn_s: "languages.cn_s",
		cn_t: "languages.cn_t",
		es: "languages.es",
		pt: "languages.pt",
		ta: "languages.ta"
	},
	types: {
		aTouchAway: "types.aTouchAway",
		Mobile: "types.Mobile",
		Simplified: "types.Simplified"
	},
	timezones: {
		"America/Los_Angeles": "timezones.America/Los_Angeles",
		"America/Denver": "timezones.America/Denver",
		"America/Chicago": "timezones.America/Chicago",
		"America/Toronto": "timezones.America/Toronto",
		"America/New_York": "timezones.America/New_York",
		"America/Halifax": "timezones.America/Halifax",
		"Europe/London": "timezones.Europe/London",
		"Atlantic/St_Helena": "timezones.Atlantic/St_Helena"
	}
};

const createFormData = (dataKey, localization) => {
	var keys = Object.keys(formData[dataKey]);
	var arr = keys.reduce(
		(result, key) =>
			result.concat({ value: key, label: localization.localize(formData[dataKey][key]) || formData[dataKey][key] }),
		[]
	);
	return arr;
};

const validationSchema = yup.object({
	fname: yup
		.string()
		.required("Required")
		.max(50),
	lname: yup
		.string()
		.required("Required")
		.max(50),
	gender: yup.string(),
	dob: yup.date(),
	language: yup.string().required("Required"),
	user_type: yup.string().required("Required"),
	street: yup.string().max(30, "Maximum of 30 characters allowed"),
	apartment: yup.string().max(30, "Maximum of 30 characters allowed"),
	country: yup.string(),
	city: yup.string().max(25),
	province: yup.string(),
	postal: yup
		.string()
		.when("country", {
			is: "canada",
			then: yup
				.string()
				.matches(/^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/, "Invalid Postal Code")
		})
		.when("country", {
			is: "united states",
			then: yup.string().matches(/^([0-9]{5}(?:-[0-9]{4})?)$/, "Invalid Postal Code")
		}),
	email: yup
		.string()
		.email("Enter a valid email")
		.max(40),
	phone: yup.string().matches(/^\d{3}[- ]\d{3}[- ]\d{4}$/, "ex. 613-131-1291"),
	phone2: yup.string().matches(/^\d{3}[- ]\d{3}[- ]\d{4}$/, "ex. 613-131-1291"),
	timezone: yup.string(),
	healthCardNumber: yup.string().matches(/^\d{4}[ -]\d{3}[ -]\d{3}[- ][A-Z]{2}$/, "ex. 2432-454-758-LF"),
	patientNumber: yup.string(),
	manageGroup: yup.array(),
	user_note: yup.string().max(30, "Pinned note must be less than 30 characters")
});

export { validationSchema, createFormData, formData };
