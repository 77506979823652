/* © 2023 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import defineUI from "../shared/define";

import render from "./render.jsx";
import par from "par";

import { getDefaultIndicator } from "./library";

import PersonStore from "../stores/person_store";
import LocalizationStore from "../stores/localization_store";
import CurrentPersonInfoStore from "../stores/current_person_info_store";
import ConnectionStore from "../stores/connection_store";
import OrgAutoLogout from "../stores/org_autologout_store.js";
import OrgCustomIndicatorStore from "../stores/org_custom_indicators_store.js";
import makeStoreFactory from "../shared/stores";

const makeStores = makeStoreFactory({
	people: PersonStore,
	currentPerson: CurrentPersonInfoStore,
	localization: LocalizationStore,
	connection: ConnectionStore,
	logout: OrgAutoLogout,
	indicators: OrgCustomIndicatorStore
});

function make_ui(api, events, config, userMetrics) {
	var storeStore = makeStores(api, events, config);

	userMetrics.trackEvent("page view", {
		"page": "org-custom-indicators",
		"category": "org",
		"permission level": "org:manager",
	});

	return defineUI({
		getInitialState: getInitialState,
		componentDidMount: listen_for_changes,
		render,
		componentWillUnmount: removeListeners
	});

	function removeListeners() {
		events.off();
	}

	function handle_change(component, stores) {
		component.setState({
			people: stores.people,
			currentPerson: stores.currentPerson,
			localization: stores.localization,
			connection: stores.connection,
			logout: stores.logout,
			customIndicators: stores.indicators
		});
	}

	function listen_for_changes() {
		storeStore.listen(par(handle_change, this));
	}

	function getInitialState() {
		const customIndicators = storeStore.stores.indicators;
		const currentPerson = storeStore.stores.currentPerson;

		return {
			people: storeStore.stores.people,
			currentPerson,
			localization: storeStore.stores.localization,
			isCreating: false,
			indicatorToUpdate: null,
			indicatorToRemove: null,
			isUnitError: false,
			isNameError: false,
			searchTerm: "",
			editedIndicator: getDefaultIndicator(),
			customIndicators,
			userMetrics: userMetrics,
		};
	}
}

export default make_ui;
