import React from "react";

import { getNameForLanguage } from "../../org-custom-indicators/library";
import { DailyTab } from "./DailyTab/DailyTab";
import { HealthIndicatorTabPanel } from "./HealthIndicatorTabPanel/HealthIndicatorTabPanel";
import { CustomIndicatorTabPanel } from "./CustomIndicatorTabPanel";

import VerticalTabs from "../VerticalTabs";

export function HealthTab({ component }){
	const state = component.state;
	const localization = state.localization;
	const indicators = component.state?.indicators?.all?.() ?? [];
	const language = component.state?.currentPerson?.get("personal")?.language ?? "en";

	const tabsDetails = [
		{
			index: 0,
			label: localization.get("remindermaker.day_values.all"),
			panel: <DailyTab component={component} />
		},
		{
			index: 1,
			label: localization.get("dashboards_bloodoxygenHistory"),
			panel: <HealthIndicatorTabPanel component={component} indicator="bloodOxygen" />
		},
		{
			index: 2,
			label: localization.get("dashboards_bodytemperatureHistory"),
			panel: <HealthIndicatorTabPanel component={component} indicator="bodyTemperature" />
		},
		{
			index: 3,
			label: localization.get("dashboards_glucoseHistory"),
			panel: <HealthIndicatorTabPanel component={component} indicator="bloodSugar" />
		},
		{
			index: 4,
			label: localization.get("dashboards_weightHistory"),
			panel: <HealthIndicatorTabPanel component={component} indicator="weight" />
		},
		{
			index: 5,
			label: localization.get("dashboards_bloodpressureHistory"),
			panel: <HealthIndicatorTabPanel component={component} indicator="bloodPressure" />
		},
		{
			index: 6,
			label: localization.get("dashboards_stepsHistory"),
			panel: <HealthIndicatorTabPanel component={component} indicator="activity" />
		},
		...indicators?.map?.((indicator, index) => ({
			index: index + 7,
			label: getNameForLanguage(indicator, language),
			panel: <CustomIndicatorTabPanel component={component} indicator={indicator} />
		}))
	];

	const onChangeHealthIndicator = newValue => {
		component.setState({
			currentHealthIndicatorIndex: newValue
		});
		const label = tabsDetails[newValue].label;
		component.state.userMetrics.trackEvent(`user-overview: navigate to ${label} table in health tab`);
	};

	return <VerticalTabs tabsDetails={tabsDetails} onChangeHealthIndicator={onChangeHealthIndicator} />;
}