import React from "react";

import {TableBody, TableCell, TableRow} from "@material-ui/1.5.1";

import { getHeaderPropertyText } from "./helpers";

const styles = {
	noResults: {
		fontSize: "0.75rem",
		fontWeight: "500",
		color: "rgba(0, 0, 0, 0.54)",
		padding: "10px",
	},
};

const tableCellTypes = {
	0: { root: "status-green" },
	1: { root: "status-yellow" },
	2: { root: "status-red" },
};

function isValidReading(reading) {
	if (typeof reading === "string" || reading instanceof String) {
		return reading?.length > 0;
	}

	return reading !== null && reading !== undefined;
}

function createTableCell(key, content, cellStatusColor) {
	return (
		<TableCell key={`${key}`} classes={tableCellTypes[cellStatusColor]}>
			{content}
		</TableCell>
	);
}

/**
 * Renders the row data for a user.
 * @param {Object} options - required options object
 * @param {Object} options.value - the value object
 * @param {String} options.units - units string
 * @param {String} options.rowKey - the row key
 * @param {Array} options.headers - the table headers
 * @param {Map} props.hiddenColumns - Map of hidden columns.
 * @param {Object} options.dataTransformer - The data transformer
 * @param {Function} props.columnsFilterFn - Optional function to filter columns from the table.
 */
function renderRow(options) {
	const {
		rowData,
		measureUnits,
		rowKey,
		headers,
		hiddenColumns,
		dataTransformer,
		columnsFilterFn,
	} = options;
	const filteredHeaders = columnsFilterFn
		? headers.filter(columnsFilterFn)
		: headers;

	return filteredHeaders
		.map((column, index) => {
			const propertyText = getHeaderPropertyText(column);
			if (hiddenColumns.get(propertyText)) return;

			if (column.indicator) {
				if (dataTransformer?.indicatorTransformer) {
					const data = dataTransformer.indicatorTransformer({
						rowData,
						column,
						units: measureUnits.measureUnits,
						weightUnits: measureUnits.weightUnits,
						temperatureUnits: measureUnits.temperatureUnits,
						glucometerUnits: measureUnits.glucometerUnits,
						propertyText,
						key: rowKey,
					});
					if (data.isCustomCell) return data.value;
					return createTableCell(`${rowKey}_${index}`, data.value, data?.cellStatusColor);
				}

				const content = isValidReading(rowData?.[column.indicator._id])
					? rowData?.[column.indicator._id]
					: "--";
				return createTableCell(`${rowKey}_${index}`, content);
			}

			if (dataTransformer?.[propertyText]) {
				const data = dataTransformer[propertyText]({
					rowData,
					column,
					units: measureUnits.measureUnits,
					weightUnits: measureUnits.weightUnits,
					temperatureUnits: measureUnits.temperatureUnits,
					glucometerUnits: measureUnits.glucometerUnits,
					propertyText,
					key: rowKey,
				});

				if (data.isCustomCell) return data.value;

				return createTableCell(`${rowKey}_${index}`, data.value, data.cellStatusColor);
			}

			return createTableCell(`${rowKey}_${index}`, rowData[propertyText] ?? "");
		}
	);
}

/**
 * Generates the content of a row.
 * @param {Object} props - required props object.
 * @param {Map} props.pageOfRecords - The records to displays
 * @param {String} props.units - The units to display
 * @param {Object} props.localization - The localization object
 * @param {Array} props.headers - the table headers
 * @param {Object} props.dataTransformer - The data transformer
 * @param {Function} props.columnsFilterFn - Optional function to filter columns from the table.
 * @param {Map} props.hiddenColumns - Map of hidden columns.
 */
function renderRows(props) {
	const { pageOfRecords } = props;

	const rows = [];
	pageOfRecords.forEach((rowData = {}, key) => {
		rows.push(
			<TableRow key={key}>
				{renderRow({
					...props,
					rowKey: key,
					rowData,
				})}
			</TableRow>
		);
	});
	return rows;
}

/**
 * Generates the contents of the table body.
 * @param {Object} props - required props object
 * @param {Array} props.pageOfRecords - Array of record on the page
 * @param {Object} props.localization - The localization object
 * @param {String} props.units - The units
 * @param {String} props.headers - The table headers
 * @param {Object} props.dataTransformer - The data transformer
 * @param {Function} props.columnsFilterFn - Optional function to filter columns from the table.
 * @param {Map} props.hiddenColumns - Map of hidden columns.
 */
function getContent(props) {
	const { localization, records } = props;
	let { pageOfRecords } = props;

	if (pageOfRecords.length === 0) {
		return (
			<tr>
				<td style={styles.noResults}>
					<i>{localization.get("table_no_records_to_display")}</i>
				</td>
			</tr>
		);
	}

	if (records instanceof Map) {
		pageOfRecords = new Map(pageOfRecords);
	}
	return renderRows({
		...props,
		pageOfRecords,
	});
}

/**
 * Generates the table body.
 * @param {Object} props - required props object
 * @param {Map | Array} props.records - the records to display
 * @param {Number} props.numRows - the num of rows
 * @param {Object} props.localization - the localization object
 * @param {Integer} props.rowsPerPage - the number of rows per page
 * @param {Number} props.page - the current page
 * @param {String} props.units - The units to display
 * @param {String} props.headers - The table headers
 * @param {Object} props.dataTransformer - The data transformer
 * @param {Function} props.columnsFilterFn - Optional function to filter columns from the table.
 * @param {Map} props.hiddenColumns - Map of hidden columns.
 */
function BodyComponent (props) {
	let { numRows } = props;
	const {
		records,
		rowsPerPage,
		page,
	} = props;

	numRows = numRows === -1 ? Number.MAX_SAFE_INTEGER : numRows;
	const startIndex = page * rowsPerPage;
	const endIndex = Math.min((page + 1) * rowsPerPage, numRows);
	const pageOfRecords = Array.from(records).slice(startIndex, endIndex);

	const content = getContent({
		...props,
		pageOfRecords,
	});

	return (
		<TableBody>
			{content}
		</TableBody>
	);
}

export { BodyComponent };
