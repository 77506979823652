/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";

import par from "par";
import xtend from "xtend";
import config from "../../configs/config.json";
import Avatar from "@material-ui/1.5.1/Avatar";
import GridList from "@material-ui/1.5.1/GridList";
import GridListTile from "@material-ui/1.5.1/GridListTile";
import Chip from "@material-ui/1.5.1/Chip";
import FontIcon from "@material-ui/1.5.1/Icon";
import Utility from "../shared/Utility.js";
import Header from "../shared/Header.js";

import  AetonixTheme_mui_1_5_1 from "../shared/AetonixTheme_mui_1.5.1";
const Colors = AetonixTheme_mui_1_5_1.palette;

export default render;

var styles = {
	grid: {
		margin: 5,
		justifyContent: "center"
	},
	tile: {
		display: "flex",
		padding: 5,
		margin: 3,
		alignItems: "center",
		borderRadius: 3
	},
	picture: {
		marginRight: 3
	},
	online: {
		backgroundColor: "#0080005e"
	},
	offline: {
		backgroundColor: "#ff00005e"
	},
	icon: {
		fontSize: 20,
		color: Colors.canvas
	},
	avatar: {
		backgroundColor: Colors.primary.dark
	},
	back: {
		backgroundColor: Colors.primary.light,
		color: Colors.primary.main
	},
	backPrimary: {
		backgroundColor: "#E0E0E0"
	},
	avatarPrimary: {
		backgroundColor: "#BCBCBC"
	},
	inputProp: {
		style: {
			color: Colors.primary.main,
		}
	},
};

function render() {
	var component = this;
	var state = component.state;
	var users = state.users;
	var people = state.people;
	var localization = state.localization;
	var careplanNoticeList = state.careplanChangeList.all();
	var careplanChanges = !!careplanNoticeList.length;
	var offline = state.connection.get("offline");
	var currentPerson = state.currentPerson;
	var userData = users.map(people.get).sort(by_name);

	var titleKey = "live_monitoring_availability";

	if (dataHasChanged(userData, state.oldUserData)) {
		const userIDs = userData
			.map(x => x._id)
			.filter(x => !!x);
		userIDs.length && component.state.userMetrics.trackEvent("availability: viewed availability of patients", {
			"patients": userIDs,
		});
		component.setState({
			oldUserData: userData,
		});
	}

	return (
		<div className="flex-vertical flex-1">
			<Header
				careplanChanges={careplanChanges}
				offline={offline}
				currentPerson={currentPerson}
				localization={localization}
				titleKey={titleKey}
			/>
			<div className="flex-horizontal ae-empty">
				{renderNofilter(component)}
				{renderOnline(component)}
				{renderOffline(component)}
			</div>
			<div className="ae-LazyList">
				<GridList tabIndex='0' cellHeight={"auto"}
					cols={4}
					style={styles.grid}>
					{userData.map(par(renderUser, component))}
				</GridList>
			</div>
		</div>
	);
}

function dataHasChanged(userData, oldUserData) {
	userData = userData || [];
	oldUserData = oldUserData || [];
	if (userData.length !== oldUserData.length) return true;
	for (var i = 0; i < userData.length; i++) {
		if (userData[i]._id !== oldUserData[i]._id) return true;
	}
	return false;
}

function renderNofilter(component) {
	var state = component.state;
	var deleteOn = noop();
	var styleavatar = styles.avatarPrimary;
	var styleback = styles.backPrimary;
	var users = state.users;

	if (state.filtering === "none") {
		styleavatar = styles.avatar;
		styleback = styles.back;
	}

	return (
		<Chip className="ae-icon ae-fonticon"
			onDelete={deleteOn}
			style={styleback}
			onClick={par(refresh, component, users, "none")}
			avatar={<Avatar style={styleavatar}>
				<FontIcon className="fa fa-filter fa-2x" style={styles.icon} />
			</Avatar>}
			label={renderFilterLabel(component, "none")}
		/>
	);
}

function renderFilterLabel(component, name) {
	if (name === "none") return "All";
	if (name === "online") return "Online";
	if (name === "offline") return "Offline";
}

function renderOnline(component) {
	var state = component.state;
	var styleavatar = styles.avatarPrimary;
	var styleback = styles.backPrimary;
	var users = state.users;

	if (state.filtering === "online") {
		styleavatar = styles.avatar;
		styleback = styles.back;
	}

	return (
		<Chip className="ae-icon ae-fonticon"
			style={styleback}
			onClick={par(refresh, component, users, "online")}
			avatar={<Avatar style={styleavatar}>
				<FontIcon className="fa fa-filter fa-2x" style={styles.icon} />
			</Avatar>}
			label={renderFilterLabel(component, "online")}
		/>
	);
}

function renderOffline(component) {
	var state = component.state;
	var styleavatar = styles.avatarPrimary;
	var styleback = styles.backPrimary;
	var users = state.users;

	if (state.filtering === "offline") {
		styleavatar = styles.avatar;
		styleback = styles.back;
	}

	return (
		<Chip className="ae-icon ae-fonticon"
			style={styleback}
			onClick={par(refresh, component, users, "offline")}
			avatar={<Avatar style={styleavatar}>
				<FontIcon className="fa fa-filter fa-2x" style={styles.icon} />
			</Avatar>}
			label={renderFilterLabel(component, "offline")}
		/>
	);
}

function refresh(component, users, filter) {
	component.state.userMetrics.trackEvent(`availability: filter by ${filter}`);
	component.setState({
		filtering: filter
	});
	component.state.refreshAll(component, users, filter);
}

function by_name(x, y) {
	var X = "";
	var Y = "";
	if(x.lname)
		X += x.lname.toLowerCase();
	if(x.fname)
		X += x.fname.toLowerCase();
	if(y.lname)
		Y += y.lname.toLowerCase();
	if(y.fname)
		Y += y.fname.toLowerCase();

	if (X < Y) return -1;
	if (X > Y) return 1;
	return 0;
}

function renderUser(component, user, index) {
	// if the stores are not populated correctly return
	if (!user._id) return;
	var state = component.state;
	var avatar = <Avatar alt="User Avatar Image" src={config.image_cdn + user.image}/>;
	var text = Utility.format_name(user);
	var connectivity = state.connectivity.get(user._id);
	var filtering = state.filtering;

	if (connectivity) {
		if (filtering === "none" || filtering === "online") {
			var boxStyle = xtend(styles.tile, styles.online);
		} else { return; }
	} else {
		if (filtering === "none" || filtering === "offline") {
			boxStyle = xtend(styles.tile, styles.offline);
		} else { return; }
	}

	return (
		<div key={text + index} style={boxStyle}>
			<GridListTile key={avatar}
				cols={1}
				style={styles.picture}>
				{avatar}
			</GridListTile>
				{text}
		</div>
	);
}

function noop() { }
