import en from "./localization-en.json";
import fr from "./localization-fr.json";
import cn_t from "./localization-cn-t.json";
import cn_s from "./localization-cn-s.json";
import es from "./localization-es.json";
import pt from "./localization-pt.json";
import ta from "./localization-ta.json";

export const localizationMap = {
	en,
	fr,
	cn_t,
	cn_s,
	es,
	pt,
	ta,
};