import React from "react";
import {
	useLocalize
} from "@aetonix/hooks";

import { Button } from "@material-ui/core";
import { formatTime } from "./WorkflowHelpers";
import StyledText from "ae-base/Base/StyledText";
import OutputForm from "ae-forms/src/Outputs/OutputForm";
import { useUserMetrics } from "@aetonix/user-metrics";

const styles = {
	action: {
		padding: 5,
		borderBottom: 1,
		borderBottomStyle: "solid",
		borderColor: "lightgray"
	}
};

const WorkflowAction = ({ action, workflow }) => {
	const { localize, localizeFrom } = useLocalize();
	const workflowSchema = workflow?.schema;
	if (!workflowSchema) return null;

	const workflowActions = workflowSchema?.actions;
	const actionSchema = workflowActions[action.action] || {};

	const readableTime = formatTime(action.updated_at);
	const createdTime = formatTime(action.created_at);

	const actionOwner = action.owner[0] || {};
	const workflowActors = workflowSchema?.actors;
	const actorSchema = workflowActors?.[action?.actor];

	const actorLocalized = localizeFrom(actorSchema, ".") || actorSchema?.en || action?.actor;
	const hasName = actionOwner.fname || actionOwner.lname;
	const name = `${actionOwner.fname || ""}${hasName ? " " : ""}${actionOwner.lname || ""}${
		hasName ? " " : ""
	}(${actorLocalized}) `;

	return (
		<div key={action._id} style={styles.action}>
			<div style={{ marginTop: 5 }}>
				{localize("workflow.createdAt")} {createdTime}
			</div>
			{actionSchema && <OutputForm key={action._id} schema={actionSchema} data={action.data || {}} />}
			{action.responded ? null : <StyledText>{localize("workflow.actionpending")}</StyledText>}
			<div style={{ paddingTop: 16 }}>
				<StyledText bold>{name}</StyledText>
				<StyledText bold>{readableTime}</StyledText>
			</div>
		</div>
	);
};

export const WorkflowActions = ({ actions, workflow, nextPage }) => {
	if(!actions?.length) return null;
	const { userMetrics } = useUserMetrics();
	const { localize } = useLocalize();
	const workflowActions = actions.map(action => (
		<WorkflowAction key={action._id} action={action} workflow={workflow} />
	));

	return (
		<>
			{workflowActions}
			<Button variant="raised" fullWidth color="secondary" onClick={() => {
				nextPage();
				userMetrics.trackEvent("user-overview: load more workflows");
			}}>
				{localize("user_overview_loadmore")}
			</Button>
		</>
	);
};