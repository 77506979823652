import {
	Button,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormLabel,
	IconButton,
	ListItem,
	ListItemText,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
	TextField,
	Tooltip
} from "@material-ui/1.5.1";
import React, { useEffect, useState } from "react";
import xtend from "xtend";

import config from "../../../configs/config.json";
import { getNameForLanguage } from "../../org-custom-indicators/library";
import DatePicker from "../../shared/DatePicker";
import Dialog from "../../shared/Dialog";
import { useApi } from "../../shared/contexts/ResourceContext";
import { KEYS, popperProps } from "../constants";
import { styles } from "../styles";

export function PdfExportModal(props) {
	const { component } = props;
	const [groupSettings, setGroupSettings] = useState({});
	const api = useApi();

	const state = component.state;
	const { localization, pdfSetup } = state;

	useEffect(() => {
		async function getGroupSettings() {
			const settings = await api.org.group.settings();
			setGroupSettings(settings);
		}

		getGroupSettings();
	}, []);

	const pages = [
		PresetPage,
		...(groupSettings?.isEnrolmentFormEnabled ? [EnrolmentPage] : []),
		VitalsPage,
		DateRangePage,
		AdditionalCustomizationPage,
		FormsSelectPage,
		WorkflowsSelectPage
	];

	let exportPdfActions;
	if (pdfSetup === 0)
		exportPdfActions = [
			<Button key={"buttoncancel"} onClick={() => cancelExportPdf(component)}>
				{localization.get("users_cancel")}
			</Button>,
			<Button key={"buttongonext"} onClick={() => goNextPdf(component)}>
				{localization.get("users_gonext")}
			</Button>
		];
	else if (pdfSetup > 0 && pdfSetup < pages.length - 1)
		exportPdfActions = [
			<Button key={"buttoncancel"} onClick={() => cancelExportPdf(component)}>
				{localization.get("users_cancel")}
			</Button>,
			<Button key={"buttongoback"} onClick={() => goBackPdf(component)}>
				{localization.get("users_goback")}
			</Button>,
			<Button key={"buttongonext"} onClick={() => goNextPdf(component)}>
				{localization.get("users_gonext")}
			</Button>
		];
	else if (pdfSetup === pages.length - 1)
		exportPdfActions = [
			<Button key="buttonsavepreset" onClick={() => startSavePreset(component)}>
				{localization.get("user_overview_save_preset")}
			</Button>,
			<Button key={"buttoncancel"} onClick={() => cancelExportPdf(component)}>
				{localization.get("users_cancel")}
			</Button>,
			<Button key={"buttongoback"} onClick={() => goBackPdf(component)}>
				{localization.get("users_goback")}
			</Button>,
			pdfReport(component, localization)
		];

	return (
		<Dialog
			actions={exportPdfActions}
			open={!!state.exportingPdf}
			title={localization.get("group_users_overview_export_pdf")}
		>
			<RenderPDFSetup
				component={component}
				pages={pages}
				isEnrolmentFormEnabled={groupSettings?.isEnrolmentFormEnabled}
			/>
		</Dialog>
	);
}

function RenderPDFSetup(props) {
	const { component, pages, isEnrolmentFormEnabled } = props;

	const pdfSetup = component.state.pdfSetup;
	const PdfPage = pages[pdfSetup];
	return <PdfPage component={component} isEnrolmentFormEnabled={isEnrolmentFormEnabled} />;
}

function PresetPage({ component }) {
	const localization = component.state.localization;
	const exportPresets = component.state.pdfExportPresets || [];
	let renderPresets;

	if (exportPresets.length) {
		renderPresets = exportPresets.map((e, index) => {
			return (
				<ListItem key={`PresetPage-${index}`} button>
					<ListItemText primary={e.name} secondary={e.description} />
					<div>
						<Tooltip title={localization.get("tooltip_use_preset")} PopperProps={popperProps}>
							<IconButton color="primary" className="fa fa-pencil" onClick={() => selectPreset(component, e)} />
						</Tooltip>
						<Tooltip title={localization.get("tooltip_download_preset")} PopperProps={popperProps}>
							<IconButton color="primary" className="fa fa-download" onClick={() => downloadPdf(component, e)} />
						</Tooltip>
						<Tooltip title={localization.get("tooltip_remove_preset")} PopperProps={popperProps}>
							<IconButton
								color="primary"
								className="fa fa-times-circle"
								onClick={() => removePreset(component, e._id)}
							/>
						</Tooltip>
					</div>
				</ListItem>
			);
		});
	} else {
		renderPresets = localization.get("user_overview_no_presets");
	}

	return (
		<div>
			<div className="ae-title">{localization.get("user_overview_export_presets")}</div>
			{renderPresets}
		</div>
	);
}

function EnrolmentPage({ component }) {
	const state = component.state;
	const { localization } = state;
	return (
		<div>
			<FormControl>
				<FormLabel id="enrolment-info-radio-label">{localization.get("generate_enrolment_discharge_report")}</FormLabel>
				<RadioGroup
					aria-labelledby="enrolment-info-radio-label"
					value={state.enrolmentInfo}
					name="enrolment-info-radio-group"
					onChange={event => updateEnrolmentInfo(component, event)}
				>
					<FormControlLabel
						value="notApplicable"
						control={
							<Radio
								inputProps={{
									"aria-label": localization.get("common.not_applicable")
								}}
							/>
						}
						label={localization.get("common.not_applicable")}
					/>
					<FormControlLabel
						value="consentForm"
						control={
							<Radio
								inputProps={{
									"aria-label": localization.get("generate_enrolment_report")
								}}
							/>
						}
						label={localization.get("generate_enrolment_report")}
					/>
					<FormControlLabel
						value="dischargeForm"
						control={
							<Radio
								inputProps={{
									"aria-label": localization.get("generate_discharge_report")
								}}
							/>
						}
						label={localization.get("generate_discharge_report")}
					/>
				</RadioGroup>
			</FormControl>
			<TextField
				fullWidth
				multiline
				rows={5}
				inputProps={{ "aria-label": localization.get("report_comment") }}
				rowsMax={5}
				label={localization.get("report_comment")}
				placeholder={localization.get("report_comment")}
				value={component.state.enrolmentComments}
				onChange={event => component.setState({ enrolmentComments: event.target.value })}
				InputProps={styles.inputProp}
			/>
		</div>
	);
}

function VitalsPage({ component }) {
	const state = component.state;
	const localization = state.localization;
	const language = state.language;
	const indicators = state.indicators;
	const allIndicators = indicators?.all?.() ?? [];

	let allSelected = true;
	const renderCheckboxes = [...KEYS, "displayRisk"].map(function(key, index) {
		const checked = state[key];

		if (!checked) {
			allSelected = false;
		}

		return (
			<FormControlLabel
				key={`VitalsPage-${index}`}
				control={
					<Checkbox
						checked={checked}
						inputProps={{
							"aria-label":
								localization.get("permissions_checkbox_for") + localization.get("user_overview_vitals." + key)
						}}
						onChange={event => updateActionChanged(component, key, event)}
					/>
				}
				label={localization.get("user_overview_vitals." + key)}
			/>
		);
	});

	const indicatorCheckboxes = allIndicators.map((indicator, index) => {
		const key = indicator._id;
		const checked = state[key] ?? false;

		if (!checked) {
			allSelected = false;
		}

		return (
			<FormControlLabel
				control={
					<Checkbox
						inputProps={{
							"aria-label": localization.get("permissions_checkbox_for") + getNameForLanguage(indicator, language)
						}}
						checked={checked}
						onChange={event => updateActionChanged(component, key, event)}
					/>
				}
				label={getNameForLanguage(indicator, language)}
				key={`${key}-$${index}`}
			/>
		);
	});

	const allCheckboxes = [...renderCheckboxes, ...indicatorCheckboxes];

	const setAllCheckboxes = evt => {
		[...KEYS, "displayRisk"].forEach(key => {
			updateActionChanged(component, key, evt);
		});

		allIndicators.forEach(indicator => {
			const key = indicator._id;

			updateActionChanged(component, key, evt);
		});
	};

	const selectAllLabel = allSelected
		? localization.get("user_overview_deselect_all")
		: localization.get("user_overview_select_all");

	const selectAll = (
		<FormControlLabel
			control={
				<Checkbox checked={allSelected} inputProps={{ "aria-label": selectAllLabel }} onChange={setAllCheckboxes} />
			}
			label={selectAllLabel}
		/>
	);
	return (
		<div>
			{selectAll}
			<br />
			{allCheckboxes}
			<TextField
				fullWidth
				multiline
				rows={5}
				inputProps={{ "aria-label": localization.get("report_comment") }}
				rowsMax={5}
				label={localization.get("report_comment")}
				placeholder={localization.get("report_comment")}
				value={component.state.comment}
				onChange={event => updateComment(component, event)}
				InputProps={styles.inputProp}
			/>
		</div>
	);
}

function DateRangePage({ component, isEnrolmentFormEnabled }) {
	const localization = component.state.localization;
	return (
		<div>
			<div>
				<p className="ae-title">{localization.get("user_overview_select_date")}</p>
				{isEnrolmentFormEnabled && (
					<p className="ae-warning">{localization.get("user_overview_select_date_subheading")}</p>
				)}
			</div>
			<DatePicker
				invalid={localization.get("choose_date")}
				labelStart={localization.get("report_start")}
				labelEnd={localization.get("report_end")}
				startDate={component.state.start}
				endDate={component.state.end}
				updateStart={event => updateChanged(component, "start", event)}
				updateEnd={event => updateChanged(component, "end", event)}
				allowFuture={false}
			/>
			<TextField
				fullWidth
				label={localization.get("report_filename")}
				inputProps={{ "aria-label": localization.get("report_filename") }}
				placeholder={localization.get("report_filename")}
				value={component.state.filename}
				onChange={event => updateFileName(component, event)}
				InputProps={styles.inputProp}
			/>
		</div>
	);
}

function AdditionalCustomizationPage({ component }) {
	const localization = component.state.localization;
	return (
		<div>
			<div className="ae-title">{localization.get("user_overview_select_additional")}</div>
			<FormControlLabel
				control={
					<Checkbox
						checked={component.state.groupName}
						inputProps={{ "aria-label": localization.get("user_overview_groupName") }}
						onChange={event => updateActionChanged(component, "groupName", event)}
					/>
				}
				label={localization.get("user_overview_groupName")}
			/>
			<FormControlLabel
				control={
					<Checkbox
						checked={component.state.exportNotesInPDF}
						inputProps={{ "aria-label": localization.get("user_overview_exportNotesInPDF") }}
						onChange={event => updateActionChanged(component, "exportNotesInPDF", event)}
					/>
				}
				label={localization.get("user_overview_exportNotesInPDF")}
			/>
			<FormControlLabel
				control={
					<Checkbox
						checked={component.state.exportPinnedNoteInPDF}
						inputProps={{ "aria-label": localization.get("user_overview_exportPatientNoteInPDF") }}
						onChange={event => updateActionChanged(component, "exportPinnedNoteInPDF", event)}
					/>
				}
				label={localization.get("user_overview_exportPatientNoteInPDF")}
			/>
			<TextField
				fullWidth
				label={localization.get("user_overview_title")}
				value={component.state.pdfTitle}
				inputProps={{ "aria-label": localization.get("user_overview_title") }}
				onChange={event => updateText(component, "pdfTitle", event)}
				InputProps={styles.inputProp}
			/>
			<TextField
				fullWidth
				label={localization.get("user_overview_formNumber")}
				value={component.state.formNumber}
				inputProps={{ "aria-label": localization.get("user_overview_formNumber") }}
				onChange={event => updateText(component, "formNumber", event)}
				InputProps={styles.inputProp}
			/>
			<TextField
				fullWidth
				label={localization.get("user_overview_fileNumber")}
				value={component.state.fileNumber}
				inputProps={{ "aria-label": localization.get("user_overview_fileNumber") }}
				onChange={event => updateText(component, "fileNumber", event)}
				InputProps={styles.inputProp}
			/>
		</div>
	);
}

function FormsSelectPage({ component }) {
	const currentPerson = component.state.currentPerson.get("personal");
	const language = currentPerson.language;
	const localization = component.state.localization;
	const userForms = component.state.userForms;
	const selectAllForm = component.state.selectAllForm;

	const rows = buildRows(userForms.sort(sortForms), 2);

	const renderFormCheckboxes = rows.map((row, index) => {
		const renderRowItem = row.map(form => renderFormCheckbox(component, language, form));
		return (
			<div key={`FormsSelectPage-${index}`} className="flex-horizontal ae-justify">
				{renderRowItem}
			</div>
		);
	});

	const boldedLabel = {
		label: "ae-bold"
	};

	const selectAllChange = selectAllForm ? () => deselectAllForms(component) : () => selectAllForms(component);

	const sortFormsHandler = e => {
		component.setState({
			sortFormsBy: e.target.value
		});
	};

	return (
		<div>
			<div className="ae-title">
				{localization.get("user_overview_sortby")}:{" "}
				<Select
					value={component.state.sortFormsBy}
					inputProps={{ "aria-label": localization.get("user_overview_sortby") }}
					onChange={sortFormsHandler}
				>
					{component.state.sortByLabels.map(sortByLabel => (
						<MenuItem value={sortByLabel} key={sortByLabel}>
							{sortByLabel}
						</MenuItem>
					))}
				</Select>
			</div>
			<div className="ae-title">{localization.get("user_overview_select_forms")}</div>
			<FormControlLabel
				classes={boldedLabel}
				control={
					<Checkbox
						key={"selectAllForm" + selectAllForm}
						inputProps={{ "aria-label": localization.get("user_overview_select_all") }}
						defaultChecked={selectAllForm}
						onChange={selectAllChange}
					/>
				}
				label={localization.get("user_overview_select_all")}
			/>
			{renderFormCheckboxes}
		</div>
	);
}

function WorkflowsSelectPage({ component }) {
	const currentPerson = component.state.currentPerson.get("personal");
	const language = currentPerson.language;
	const localization = component.state.localization;
	const workflowDefinitions = component.state.definitions;

	const selectAllWorkflow = component.state.selectAllWorkflow;

	const rows = buildRows(workflowDefinitions, 2);

	const renderDefinitionCheckboxes = rows.map((row, index) => {
		const renderRowItem = row.map(wf => renderWorkflowCheckbox(component, language, wf));
		return (
			<div key={`WorkflowsSelectPage=${index}`} className="flex-horizontal ae-justify">
				{renderRowItem}
			</div>
		);
	});

	const boldedLabel = {
		label: "ae-bold"
	};

	const selectAllChange = selectAllWorkflow
		? () => deselectAllWorkflows(component)
		: () => selectAllWorkflows(component);

	const sortWorkflowsHandler = e => {
		component.setState({
			sortWorkflowsBy: e.target.value
		});
	};

	return (
		<div>
			<div className="ae-title">
				{localization.get("user_overview_sortby")}:{" "}
				<Select
					value={component.state.sortWorkflowsBy}
					inputProps={{ "aria-label": localization.get("user_overview_sortby") }}
					onChange={sortWorkflowsHandler}
				>
					{component.state.sortByLabels.map(sortByLabel => (
						<MenuItem key={sortByLabel} value={sortByLabel}>
							{sortByLabel}
						</MenuItem>
					))}
				</Select>
			</div>
			<div className="ae-title">{localization.get("user_overview_select_workflow")}</div>
			<FormControlLabel
				classes={boldedLabel}
				control={
					<Checkbox
						key={"selectAllWorkflow" + selectAllWorkflow}
						defaultChecked={selectAllWorkflow}
						inputProps={{ "aria-label": localization.get("user_overview_select_all") }}
						onChange={selectAllChange}
					/>
				}
				label={localization.get("user_overview_select_all")}
			/>
			{renderDefinitionCheckboxes}
		</div>
	);
}

function renderWorkflowCheckbox(component, language, workflow) {
	const id = workflow._id;
	const schema = workflow.schema || {};
	const description = schema.description || {};
	const displayText = description[language] || description[Object.keys(description)[0]] || "Unknown";
	const isWorkflowSelected = component.state.workflows.indexOf(id) !== -1;
	return (
		<div className="flex flex-1" key={id}>
			<FormControlLabel
				control={
					<Checkbox
						key={id + isWorkflowSelected}
						inputProps={{ "aria-label": displayText }}
						defaultChecked={isWorkflowSelected}
						onChange={event => updateListChanged(component, id, "workflows", event)}
					/>
				}
				label={displayText}
			/>
		</div>
	);
}

function deselectAllWorkflows(component) {
	const workflows = [];
	const selectAllWorkflow = false;
	component.setState({ workflows, selectAllWorkflow });
}

function selectAllWorkflows(component) {
	const definitions = component.state.definitions;
	const workflows = definitions.map(e => e._id);
	const selectAllWorkflow = true;
	component.setState({ workflows, selectAllWorkflow });
}

function updateChanged(component, name, event, date) {
	let value = date;
	if (event) {
		value = event._d;
	}
	const update = {};
	update[name] = value;
	component.setState(update);
}

function updateFileName(component, event) {
	event.persist();
	const value = event.target.value;
	component.setState({
		filename: value
	});
}

function removePreset(component, preset) {
	const remove = component.state.removePreset;
	remove(preset);
	component.state.userMetrics.trackEvent("user-overview: remove preset", {
		name: preset.name,
		description: preset.description,
		options: preset.options
	});
}

function selectPreset(component, preset) {
	preset = preset || {};
	const options = xtend({}, preset.options);
	options.pdfSetup = 1;
	component.setState(options);
	component.state.userMetrics.trackEvent("user-overview: start editing preset", {
		name: preset.name,
		description: preset.description,
		options: options
	});
}

function downloadPdf(component, preset) {
	const localization = component.state.localization;
	preset = preset || {};
	const options = preset.options;
	component.setState(options, () => pdfReport(component, localization, false, true));
	component.state.userMetrics.trackEvent("user-overview: download pdf using preset", {
		name: preset.name,
		description: preset.description,
		options: options
	});
}

function updateEnrolmentInfo(component, event) {
	component.setState({
		enrolmentInfo: event.target.value
	});
}

function updateActionChanged(component, key, event) {
	component.setState({ [key]: event.target.checked });
}

function updateComment(component, event) {
	const value = event.target.value;
	component.setState({
		comment: value
	});
}

function updateText(component, stateName, e) {
	e.persist();
	const update = {};
	update[stateName] = e.target.value;
	component.setState(update);
}

function pdfReport(component, localization, iconButton, noButton) {
	let filename = component.state.filename;
	const currentPerson = component.state.currentPerson.get("personal");
	const language = currentPerson.language;
	const whoPerson = component.state.person;
	const who = whoPerson._id;
	const timeoffset = new Date().getTimezoneOffset();
	const indicators = component.state?.indicators;

	const start = component.state.start;
	const end = component.state.end;
	const comment = component.state.comment;
	const title = component.state.pdfTitle;
	const groupName = component.state.groupName;
	const exportNotesInPDF = component.state.exportNotesInPDF;
	const exportPinnedNoteInPDF = component.state.exportPinnedNoteInPDF;
	const formNumber = component.state.formNumber;
	const fileNumber = component.state.fileNumber;
	const forms = component.state.forms;
	const workflows = component.state.workflows;
	const sortFormsBy = component.state.sortFormsBy === localization.get("user_overview_sortby_oldest") ? "old" : "new";
	const sortWorkflowsBy =
		component.state.sortWorkflowsBy === localization.get("user_overview_sortby_oldest") ? "old" : "new";
	const enrolmentInfo = component.state.enrolmentInfo;
	const enrolmentComments = component.state.enrolmentComments;

	const allIndicators = indicators?.all?.() ?? [];

	if (!filename) filename = whoPerson.fname + "_" + whoPerson.lname + "_" + localization.get("user_overview_overview");

	let url =
		config.host +
		"/v2/org/users/report?token=" +
		config.token +
		"&who=" +
		encodeURIComponent(who) +
		"&filename=" +
		encodeURIComponent(filename) +
		"&language=" +
		encodeURIComponent(language) +
		"&timeoffset=" +
		encodeURIComponent(timeoffset) +
		"&sortformsby=" +
		encodeURIComponent(sortFormsBy) +
		"&sortworkflowsby=" +
		encodeURIComponent(sortWorkflowsBy);

	if (comment) url += "&comment=" + encodeURIComponent(comment);
	if (start) url += "&startDate=" + encodeURIComponent(start);
	if (end) url += "&endDate=" + encodeURIComponent(end);
	if (title) url += "&title=" + encodeURIComponent(title);
	if (groupName) url += "&groupPage=" + encodeURIComponent(groupName);
	if (exportNotesInPDF) url += "&exportNotesInPDF=" + encodeURIComponent(exportNotesInPDF);
	if (exportPinnedNoteInPDF) url += "&exportPinnedNoteInPDF=" + encodeURIComponent(exportPinnedNoteInPDF);
	if (formNumber) url += "&formNumber=" + encodeURIComponent(formNumber);
	if (fileNumber) url += "&fileNumber=" + encodeURIComponent(fileNumber);
	if (forms && forms.length) url = forms.reduce(reducer("&forms="), url);
	if (workflows && workflows.length) url = workflows.reduce(reducer("&workflows="), url);
	if (enrolmentInfo !== "notApplicable") url += "&enrolmentInfo=" + encodeURIComponent(enrolmentInfo);
	if (enrolmentComments) url += "&enrolmentComments=" + encodeURIComponent(enrolmentComments);

	url = [...KEYS, "displayRisk"].reduce(function(acc, key) {
		acc += "&" + key + "=" + encodeURIComponent(component.state[key]);
		return acc;
	}, url);

	url =
		allIndicators?.reduce?.(function(acc, indicator) {
			const key = indicator._id;
			const isChecked = component.state[key];

			if (!isChecked) {
				return acc;
			}

			acc += "&indicator=" + encodeURIComponent(key);
			return acc;
		}, url) ?? url;

	if (noButton) {
		var a = document.createElement("a");
		a.style.display = "none";
		document.body.appendChild(a);
		a.href = url;
		a.setAttribute("download", filename);
		a.click();
		return;
	}

	if (iconButton)
		return <IconButton style={styles.icon} key={url} href={url} download={filename} className="fa fa-download" />;

	return (
		<Button key={url} href={url} download={filename}>
			{localization.get("report_save")}
		</Button>
	);
}

function reducer(key) {
	return function(result, e) {
		result += key + e;
		return result;
	};
}

function renderFormCheckbox(component, language, form) {
	const id = form._id;
	const formLocalization = form.localization || {};
	const displayText = formLocalization[language] || formLocalization[Object.keys(formLocalization)[0]] || "Unknown";
	const isFormSelected = component.state.forms.indexOf(id) !== -1;
	return (
		<div className="flex flex-1" key={id}>
			<FormControlLabel
				control={
					<Checkbox
						key={id + isFormSelected}
						defaultChecked={isFormSelected}
						inputProps={{ "aria-label": displayText }}
						onChange={event => updateListChanged(component, id, "forms", event)}
					/>
				}
				label={displayText}
			/>
		</div>
	);
}

function buildRows(arr, maxRow) {
	return arr.reduce(
		(rows, item, index) => {
			if (!(index % maxRow) && index > 0) rows.push([]);
			rows[rows.length - 1].push(item);
			return rows;
		},
		[[]]
	);
}

function sortForms(x, y) {
	const xx = x.schema.order;
	const yy = y.schema.order;
	if (xx > yy || xx === undefined) return 1;
	if (xx < yy || yy === undefined) return -1;
	return 0;
}

function updateListChanged(component, id, updater, event) {
	const current = component.state[updater];
	let newList = [];
	if (event.target.checked) newList = current.concat(id);
	else newList = current.filter(e => removeId(id, e));

	const update = {};
	update[updater] = newList;
	component.setState(update);
}

function removeId(id, e) {
	return id !== e;
}

function deselectAllForms(component) {
	const forms = [];
	const selectAllForm = false;
	component.setState({ forms, selectAllForm });
}

function selectAllForms(component) {
	const userForms = component.state.userForms;
	const forms = userForms.map(e => e._id);
	const selectAllForm = true;
	component.setState({ forms, selectAllForm });
}

function goNextPdf(component) {
	let pdfSetup = component.state.pdfSetup;
	component.setState({
		pdfSetup: ++pdfSetup
	});
	const pageName = {
		0: "export presets",
		1: "indicators and comments",
		2: "dates and filename",
		3: "additional options",
		4: "forms",
		5: "workflows"
	}[pdfSetup];
	component.state.userMetrics.trackEvent(`user-overview: go to pdf page ${pdfSetup} (${pageName})`);
}

function goBackPdf(component) {
	let pdfSetup = component.state.pdfSetup;
	component.setState({
		pdfSetup: --pdfSetup
	});
	const pageName = {
		0: "export presets",
		1: "indicators and comments",
		2: "dates and filename",
		3: "additional options",
		4: "forms",
		5: "workflows"
	}[pdfSetup];
	component.state.userMetrics.trackEvent(`user-overview: go to pdf page ${pdfSetup} (${pageName})`);
}

function cancelExportPdf(component) {
	const vitals = [...KEYS, "displayRisk"].reduce((acc, key) => {
		acc[key] = false;
		return acc;
	}, {});

	const allIndicators = component.state.indicators?.all?.() ?? [];
	allIndicators.forEach(indicator => {
		vitals[indicator._id] = false;
	});

	component.setState({
		exportingPdf: false,
		pdfSetup: 0,
		start: null,
		end: null,
		pdfTitle: "",
		groupName: false,
		exportNotesInPDF: false,
		exportPinnedNoteInPDF: false,
		formNumber: "",
		fileNumber: "",
		forms: [],
		workflows: [],
		comment: "",
		filename: "",
		enrolmentInfo: "notApplicable",
		enrolmentComments: "",
		...vitals
	});
	component.state.userMetrics.trackEvent("user-overview: close export pdf popup");
}

function startSavePreset(component) {
	component.setState({
		savingPreset: true
	});
	component.state.userMetrics.trackEvent("user-overview: open save preset popup");
}
