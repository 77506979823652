import React from "react";
import par from "par";
import xtend from "xtend";
import Header from "../shared/Header.js";
import IconButton from "@material-ui/1.5.1/IconButton";
import TextField from "@material-ui/1.5.1/TextField";
import Select from "@material-ui/1.5.1/Select";
import MenuItem from "@material-ui/1.5.1/MenuItem";
import ListItem from "@material-ui/1.5.1/ListItem";
import ListItemText from "@material-ui/1.5.1/ListItemText";
import Dialog from "../shared/Dialog";
import Button from "@material-ui/1.5.1/Button";
import FontIcon from "@material-ui/1.5.1/Icon";
import Avatar from "@material-ui/1.5.1/Avatar";
import InputLabel from "@material-ui/1.5.1/InputLabel";
import FormControl from "@material-ui/1.5.1/FormControl";
import Tabs from "@material-ui/1.5.1/Tabs";
import Tab from "@material-ui/1.5.1/Tab";
import LazyList from "../shared/LazyList.jsx";

import { getNameForLanguage } from "../org-custom-indicators/library";

export default render;

var listItemClasses = {
	default: "ae-padding",
	gutters: "ae-padding",
	root: "ae-padding"
};

function render() {
	var component = this;
	var state = component.state;
	var toRemove = state.toRemove;
	var workflow = state.workflow;
	var currentPerson = state.currentPerson;
	var localization = state.localization;
	var offline = state.connection.get("offline");
	var careplanList = state.careplanChangeList.all();
	var careplanChanges = !!careplanList.length;
	var updating = state.updating;
	var creating = state.creating;
	var schemaError = state.schemaError;
	var iconError = state.iconError;
	var tabValue = state.tabValue;
	var errorText = schemaError ? localization.get("org_workflow_schemaerror") : null;
	var titleKey = "dashboards_header_workflow";
	var icons = localization.get("org_form_icons");
	var language = currentPerson.get("personal").language;

	const indicators = state?.indicators?.all?.() ?? [];


	var currentIcon = (updating ? updating.icon : creating.icon) || "";
	var currentExternals = (updating ? updating.externals : creating.externals) || {};
	var externals = findExternals(component);

	var defaultSchema = updating ? updating.schemaText : creating ? creating.schemaText : null;
	var iconStyle = {
		color: "#065B61",
		marginRight: "8px"
	};
	var iconsList = Object.keys(icons).map(function(icon) {
		var iconName = "fa fa-" + icon;
		return (
			<MenuItem key={icon} value={icon}>
				<FontIcon className={iconName} style={iconStyle} />
				{icons[icon]}
			</MenuItem>
		);
	});

	var cancel = updating ? par(cancelUpdating, component) : par(cancelAdding, component);
	var dialogTitle = getDialogTitle(component, currentPerson, updating);

	var addActions = [
		<Button key={"buttonsubmit"} onClick={par(submitWorkflow, component)}>
			{localization.get("org_create_submit")}
		</Button>,
		<Button key={"buttoncancel"} onClick={cancel}>
			{localization.get("org_create_cancel")}
		</Button>
	];

	var remove_actions = [
		<Button key={"buttonok"} onClick={par(confirmRemove, component)}>
			{localization.get("users_ok")}
		</Button>,
		<Button key={"buttoncancel"} onClick={par(hideRemove, component)}>
			{localization.get("users_cancel")}
		</Button>
	];

	var resources = state.resources;
	var resourcesMenu = resources.map(function(resource) {
		return (
			<MenuItem key={resource._id} value={resource._id}>
				{getLang(resource.name, language)}
			</MenuItem>
		);
	});

	var forms = state.forms;
	var formsMenu = forms.map(function(form) {
		return (
			<MenuItem key={form._id} value={form._id}>
				{getFormName(currentPerson, form)}
			</MenuItem>
		);
	});

	var options = state.options;
	var optionMenu = options.map(option => {
		return (
			<MenuItem key={option._id} value={option._id}>
				{getOptionLang(currentPerson, option, localization)}
			</MenuItem>
		);
	});

	const indicatorsMenu = indicators.map(indicator => (
		<MenuItem key={indicator._id} value={indicator._id}>
			{getNameForLanguage(indicator, language)}
		</MenuItem>
	));

	var externalTab = externals ? (
		<Tabs
			fullWidth
			value={tabValue}
			indicatorColor="primary"
			onChange={par(handleTabValueChanged, component)}
		>
			<Tab label={localization.get("org_workflow_schema")} />
			<Tab label={localization.get("org_workflow_externals")} />
		</Tabs>
	) : null;

	var externalForm = externals
		? Object.keys(externals).map(function(key, index) {
				var menu = null;
				if (externals[key] === "form") menu = formsMenu;
				else if (externals[key] === "resource") menu = resourcesMenu;
				else if (externals[key] === "option") menu = optionMenu;
				else if (externals[key] === "indicator") {
					menu = indicatorsMenu;
				} else return null;

				return (
					<FormControl fullWidth key={`${key}-${index}`}>
						<InputLabel>{key}</InputLabel>
						<Select fullWidth value={currentExternals[key] || ""} onClick={par(updateExternal, component, key)}>
							{menu}
						</Select>
					</FormControl>
				);
		  })
		: null;

	return (
		<div className="flex-vertical flex-1">
			<Header
				careplanChanges={careplanChanges}
				offline={offline}
				currentPerson={currentPerson}
				localization={localization}
				titleKey={titleKey}
			/>

			<Dialog actions={remove_actions} open={!!toRemove} title={localization.get("org_workflow_removeTitle")}>
				{localization.get("org_workflow_removeText")}
			</Dialog>

			<LazyList loadMore={noop} renderItem={par(renderWorkflow, component, currentPerson)} items={workflow} />
			<Button variant="raised" onClick={par(showAddWorkflow, component)} color="secondary" >
				{localization.get("org_workflow_addnew")}
			</Button>

			<Dialog actions={addActions} open={!!creating || !!updating} title={dialogTitle}>
				{externalTab}
				{tabValue === 0 && (
					<div>
						<FormControl fullWidth={true}>
							<InputLabel>{localization.get("org_form_icon")}</InputLabel>
							<Select fullWidth error={iconError} inputProps={{"aria-label": localization.get("org_form_icon")}} onChange={par(updateDropdown, component, "icon")} value={currentIcon}>
								{iconsList}
							</Select>
						</FormControl>
						<TextField
							fullWidth
							multiline
							error={schemaError}
							defaultValue={defaultSchema}
							helperText={errorText}
							aria-label= {localization.get("audit.workflowSchema")}
							inputProps={{"aria-label": localization.get("audit.workflowSchema"), "role": "tab"}}
							role={"tablist"}
							rows={19}
							rowsMax={19}
							onChange={par(updateSchema, component)}
							placeholder={localization.get("org_workflow_schema")}
						/>
					</div>
				)}{" "}
				{tabValue === 1 && externals && externalForm}
			</Dialog>
		</div>
	);
}

function handleTabValueChanged(component, event, value) {
	component.setState({
		tabValue: value
	});
	const eventText = [
		"org-pathway: navigate to schema tab in add workflow popup",
		"org-pathway: navigate to externals tab in add workflow popup",
	];
	const eventProperties = component.state.updating ? {
		workflow: component.state.updating._id,
	} : {
		creating: true,
	};
	component.state.userMetrics.trackEvent(eventText[value], eventProperties);
}

function showAddWorkflow(component) {
	component.setState({
		creating: {},
		schemaError: false,
		iconError: false,
		tabValue: 0
	});
	component.state.userMetrics.trackEvent("org-pathway: open add workflow popup", {
		workflow: component.state.creating,
	});
}

function cancelAdding(component) {
	component.state.userMetrics.trackEvent("org-pathway: close add workflow popup");
	component.setState({
		creating: false
	});
}

function renderWorkflow(component, currentPerson, workflow) {
	var state = component.state;
	var localization = state.localization;
	var person = currentPerson.get("personal");
	var language = person.language;
	var workflowId = workflow._id;
	var schema = workflow.schema || {};
	var workflowDescription = schema.description || {};
	var keys = Object.keys(workflowDescription);
	var description =
		workflowDescription[language] || workflowDescription[keys[0]] || localization.get("org_workflow_untitled");
	var name = workflow.name || description;
	var editButton = (
		<div>
			<IconButton color="primary" aria-label={localization.get("workflow.edit")}  title={localization.get("workflow.edit")} role="button" className="fa fa-pencil" onClick={par(startUpdating, component, workflow)} />
			<IconButton color="primary" aria-label={localization.get("org_workflow_removeTitle")} title={localization.get("org_workflow_removeTitle")} role="button" className="fa fa-times-circle" onClick={par(startRemove, component, workflow)} />
			{downloadFile(component, currentPerson, workflow, name)}
		</div>
	);

	var iconName = "fa fa-" + workflow.icon;
	var icon = <FontIcon className={iconName} />;
	var avatar = <Avatar>{icon}</Avatar>;
	return (
		<ListItem classes={listItemClasses} key={workflowId} onClick={par(set_viewing, component, workflow)}>
			{avatar}
			<ListItemText primary={name} />
			{editButton}
		</ListItem>
	);
}

function set_viewing(component, workflow) {
	component.setState({
		viewing: workflow
	});
}

function startRemove(component, workflow) {
	component.setState({
		toRemove: workflow
	});
	component.state.userMetrics.trackEvent("org-pathway: open remove workflow popup", {
		workflow: component.state?.toRemove?._id,
	});
}

function hideRemove(component) {
	component.setState({
		toRemove: false
	});
	component.state.userMetrics.trackEvent("org-pathway: close remove workflow popup", {
		workflow: component.state?.toRemove?._id,
	});
}

function confirmRemove(component) {
	var workflow = component.state.toRemove;
	var remove = component.state.removeWorkflow;
	remove(workflow);
	component.setState({
		toRemove: false
	});
	component.state.userMetrics.trackEvent("org-pathway: remove workflow", {
		workflow: workflow?._id,
	});
}

function startUpdating(component, workflow) {
	var updating = xtend({}, workflow);
	var schema = workflow.schema;
	component.state.userMetrics.trackEvent("org-pathway: open update workflow popup", {
		workflow: updating._id,
	});
	if (typeof schema !== "string") updating.schemaText = JSON.stringify(schema, null, 4);
	component.setState({
		updating: updating,
		schemaError: false,
		iconError: false,
		tabValue: 0
	});
}

function cancelUpdating(component) {
	component.state.userMetrics.trackEvent("org-pathway: cancel updating workflow", {
		workflow: component.state?.updating?._id,
	});
	component.setState({
		updating: false
	});
}

function updateDropdown(component, type, e) {
	e.persist();
	var payload = e.target.value;
	var updating = component.state.updating;
	var update = component.state.creating || updating;
	update[type] = payload;

	if (updating) updateUpdatingState(component, update);
	else updateCreatingState(component, update);
}

function updateExternal(component, key, e) {
	e.persist();
	var payload = e.target.value;
	var updating = component.state.updating;
	var update = component.state.creating || updating;
	if (!update.externals) update.externals = {};
	update.externals[key] = payload;

	component.state.userMetrics.trackEvent("org-pathway: update external", {
		workflow: update?._id,
	});

	if (updating) updateUpdatingState(component, update);
	else updateCreatingState(component, update);
}

function submitWorkflow(component) {
	var updating = component.state.updating;
	var workflow = component.state.creating || updating;
	var submit = updating ? component.state.updateWorkflow : component.state.addWorkflow;
	var hide = updating ? cancelUpdating : cancelAdding;
	var iconError = workflow.icon ? false : true;
	component.setState({
		iconError: iconError
	});

	try {
		workflow.schema = JSON.parse(workflow.schemaText);
		var icon = workflow.icon;
		if (icon) {
			submit(workflow);
			hide(component);
		}
		const eventString = updating ? "org-pathway: update workflow" : "org-pathway: add workflow";
		component.state.userMetrics.trackEvent(eventString, {
			"id": workflow._id,
			"workflow json": workflow.schemaText,
		});
	} catch (err) {
		component.setState({
			schemaError: true
		});
	}
}

function updateSchema(component, e) {
	e.persist();
	var text = e.target.value;
	var updating = component.state.updating;
	var update = component.state.creating || updating;
	update.schemaText = text;

	if (updating) updateUpdatingState(component, update);
	else updateCreatingState(component, update);
}

function updateCreatingState(component, update) {
	component.setState({
		creating: update
	});
}

function updateUpdatingState(component, update) {
	var updating = component.state.updating;
	component.setState({
		updating: xtend(updating, update)
	});
}

function downloadFile(component, currentPerson, workflow, name) {
	var schemaText = JSON.stringify(workflow.schema, null, 4);
	var filename = name.replace(/ /g, "_") + ".schema.json";
	var jsonBlob = new Blob([schemaText], {
		type: "application/json"
	});
	var localization = component.state.localization;
	var url = window.URL.createObjectURL(jsonBlob);

	var overrideStyle = {
		verticalAlign: "top"
	};
	return (
		<IconButton
			color="primary"
			aria-label={localization.get("download_file_button")}
			title={localization.get("download_file_button")}
			role="button"
			className="fa fa-download"
			style={overrideStyle}
			href={url}
			download={filename}
			onClick={() => component.state.userMetrics.trackEvent("org-pathway: download workflow", {
				workflow: workflow._id,
			})}
		/>
	);
}

function noop() {}

function getDialogTitle(component, currentPerson, workflow) {
	var state = component.state;
	var localization = state.localization;
	var person = currentPerson.get("personal");
	var language = person.language;
	var description = localization.get("org_workflow_new");
	var schemaDescription = workflow.schema ? workflow.schema.description : null;
	if (schemaDescription) {
		var keys = Object.keys(schemaDescription);
		description =
			keys.length !== 0
				? schemaDescription[language] || schemaDescription[keys[0]]
				: localization.get("org_workflow_untitled");
	}
	return workflow.name || description;
}

function getFormName(currentPerson, form) {
	var person = currentPerson.get("personal");
	var language = person.language;
	var localization = form.localization;
	var name = localization[language] || localization[Object.keys(localization)[0]];
	return name;
}

function findExternals(component) {
	var workflow = component.state.updating || component.state.creating;
	var schemaText = workflow.schemaText;
	var schema = null;

	try {
		schema = JSON.parse(schemaText);
		if (schema.externals) return schema.externals;
		return null;
	} catch (err) {
		return null;
	}
}

function getLang(map, language) {
	if (typeof map === "string") return map;
	map = map || {};
	var returnValue = map[language];
	if (!returnValue) return map[Object.keys(map)[0]];
	return returnValue;
}

function getOptionLang(currentPerson, option, localization) {
	var schema = option.schema || {};
	var person = currentPerson.get("personal");
	var language = person.language;
	var schemaLocalization = schema.localization || {};
	return (
		schemaLocalization[language] ||
		schemaLocalization[Object.keys(schemaLocalization)[0]] ||
		localization.get("group_options_unknown")
	);
}
