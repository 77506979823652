/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */



export default {
	SettingsPermissions: [
		"personal",
		"urgencycontacts",
		"contacts",
		"device",
		"atouchaway",
		"reminders",
		"files",
		"forms",
		"observations",
		"workflow",
		"manage",
		"notes",
		"resources",
		"devices"
	],
	CareplanPermissions: [
		"careplan_Appointments",
		"careplan_CareTeam",
		"careplan_Client",
		"careplan_Goals",
		"careplan_HealthAssessments",
		"careplan_HealthConditions",
		"careplan_HospitalVisits",
		"careplan_OtherTreatments",
		"careplan_Situation",
		"careplan_Supports",
		"careplan_Treatments"
	]
};
