/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

import par from "par";

import defineUI from "../shared/define";
import render from "./render.jsx";
import LocalizationStore from "../stores/localization_store";
import CurrentPersonInfoStore from "../stores/current_person_info_store";
import CareplanNotificationStore from "../stores/careplan_notice_store";
import ConnectionStore from "../stores/connection_store";

import storeFactory from "../shared/stores";

export default make_ui;

const makeStores = storeFactory({
	currentPerson: CurrentPersonInfoStore,
	localization: LocalizationStore,
	connection: ConnectionStore,
	careplanChangeList: CareplanNotificationStore
});

function make_ui(api, events, config, userMetrics) {
	const storeStore = makeStores(api, events, config);

	userMetrics.trackEvent("page view", {
		"page": "dashboard",
		"category": "dashboard",
		"permission level": "dashboard"
	});

	return defineUI({
		getInitialState: initialState,
		componentDidMount: listen_for_changes,
		render: render,
		componentWillUnmount: removeListeners
	});

	function removeListeners() {
		events.off();
	}

	function listen_for_changes() {
		storeStore.listen(par(handle_change, this));
	}

	function handle_change(component, stores) {
		component.setState({
			currentPerson: stores.currentPerson,
			localization: stores.localization,
			careplanChangeList: stores.careplanChangeList,
			connection: stores.connection,
		});
	}

	function initialState() {
		return {
			currentPerson: storeStore.stores.currentPerson,
			localization: storeStore.stores.localization,
			careplanChangeList: storeStore.stores.careplanChangeList,
			connection: storeStore.stores.connection,
		};
	}
}
