import React from "react";
import { HealthTable } from "./HealthTable";
import { styles } from "../../styles";

export function DailyTab({component}) {
	const state = component.state;
	const days = state.days || [];
	const localization = state.localization;
	const allIndicators = state.indicators?.all?.() ?? [];

	return (
		<div>
			<div className="flex-2 table-margin">
				<div style={styles.tableListContainer}>
					<HealthTable days={days} allIndicators={allIndicators} localization={localization} state={state} component={component} />
				</div>
			</div>
		</div>
	);
}