import { IconButton, Snackbar } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import React from "react";

export function ErrorToast({ open, handleClose, message }) {
	return (
		<Snackbar
			anchorOrigin={{ vertical: "top", horizontal: "center" }}
			open={open}
			autoHideDuration={6000}
			onClose={handleClose}
		>
			<Alert
				severity="error"
				variant="filled"
				action={
					<IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
						<Close fontSize="small" />
					</IconButton>
				}
			>
				{message}
			</Alert>
		</Snackbar>
	);
}
