export const indicatorUnits = {
	bloodoxygen: {
		metric: "(%)",
		imperial: "(%)",
	},
	bloodpressure: {
		metric: "(mmHg)",
		imperial: "(mmHg)",
	},
	bppulserate: {
		metric: "(bpm)",
		imperial: "(bpm)",
	},
	bloodsugar: {
		mmol: "(mmol)",
		mg: "(mg)",
	},
	bodytemperature: {
		celsius: "(°C)",
		fahrenheit: "(°F)",
	},
	heartrate: {
		metric: "(bpm)",
		imperial: "(bpm)",
	},
	oxygensaturation: {
		metric: "(%)",
		imperial: "(%)",
	},
	weight: {
		kg: "(kg)",
		lbs: "(lbs)",
	},
};
