
/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */




import Store from "./store";
import par from "par";

export default UserManagerStore;

function UserManagerStore(api, events, config) {
	var store = new Store();

	store.raw_get = store.get;
	store.get = get;

	var tracking = [];

	return store;

	function get(id) {
		var existing = store.raw_get(id);
		if (existing) return existing;

		if (!isTracking(id))
			track(id);

		return [];
	}

	function isTracking(id) {
		return tracking.indexOf(id) !== -1;
	}

	function track(id) {
		tracking.push(id);
		api.org.manage.managers(id).then(par(setManagers, id));
		var orgGroup = config.orgGroup;
		if(!orgGroup) return;
		events.on("mqtt:orggroup/" + orgGroup + "/u/" + id + "/manage/manager/removed", par(removeManager, id));
		events.on("mqtt:orggroup/" + orgGroup + "/u/" + id + "/manage/manager/added", par(addManager, id));
	}

	function removeManager(id, data) {
		var manager = data.manager;
		var existing = store.get(id);
		if (!existing) return;

		var filtered = existing.filter(function (existingManager) {
			return existingManager !== manager;
		});

		store.set(id, filtered);
	}

	function addManager(id, data) {
		var manager = data.manager;
		var existing = store.get(id);
		if (!existing) return;
		if(existing.indexOf(manager) !== -1) return;

		var added = existing.concat(manager);

		store.set(id, added);
	}

	function setManagers(id, managers) {
		store.set(id, managers);
	}

}
