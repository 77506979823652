import dotty from "dotty";

const VALID_CHART_TYPES = [
	"line",
	"bar"
];
const DEFAULT_RED = "#ff6384";
const DEFAULT_GREY = "rgba(0, 0, 0, 0.1)";

// Converts workflow data into a chartjs compatible data object
export function convertData(chartToRender, dateRange, data, schema, externals, language) {
	dateRange = dateRange || {};
	dateRange.start = Date.parse(dateRange.start);
	dateRange.end = Date.parse(dateRange.end);

	var chartData = {
		datasets: [],
		labels: Object.keys(data).filter((date) => (
			!dateRange.start || Date.parse(date) > dateRange.start) && (!dateRange.end || Date.parse(date) < dateRange.end)
		),
	};

	chartToRender?.propertiesToRender?.forEach((propertyToRender) => {
		chartData.datasets = chartData.datasets.concat(
			createDatasetEntry(propertyToRender, dateRange, data, schema, externals, language)
		);
	});

	return chartData;
}

// Creates a dataset entry for a single field
function createDatasetEntry(propertyToRender, dateRange, data, schema, externals, language) {
	var propertyKey = propertyToRender.property;
	if (!propertyKey) return null;

	var chartType = propertyToRender?.chartType?.toLowerCase();
	var dataset = {
		type: VALID_CHART_TYPES.includes(chartType) ? chartType : "line",
		label: propertyToRender.label || schema?.properties[propertyKey]?.localization[language] || propertyKey,
		...propertyToRender.datasetRenderOptions
	};

	var accessor = schema?.properties[propertyKey]?.data;
	var dataInDateRange = Object.entries(data).filter((entry) => (!dateRange.start || Date.parse(entry[0]) > dateRange.start) && (!dateRange.end || Date.parse(entry[0]) < dateRange.end));
	dataset["data"] = dataInDateRange.map((entry) => {
		var [, dateData] = entry;

		var fieldProperties = schema.properties[propertyKey] || {};
		var isWorkflow = externals[fieldProperties?.workflow];
		var isForm = externals[fieldProperties?.form];

		var propertyData = null;
		if (isWorkflow) {
			let workflowData = dateData[isWorkflow] || {};
			propertyData = workflowData[fieldProperties.action] || {};
		} else if (isForm) {
			let formData = dateData[isForm] || {};
			propertyData = formData;
		}
		return dotty.get(propertyData, accessor);
	});

	var thresholds = propertyToRender?.thresholds;
	if (thresholds) {
		dataset.backgroundColor = (context) => {
			if (!context || !context.parsed || !context.parsed.y) return null;
			var yValue = Number(context.parsed.y);
			if (yValue > parseInt(thresholds?.max?.value || schema?.thresholds?.max)) {
				return thresholds?.max?.color || DEFAULT_RED;
			} else if (yValue < parseInt(thresholds?.min?.value || schema?.thresholds?.min)) {
				return thresholds?.min?.color || DEFAULT_RED;
			}
			return thresholds?.defaultColor || propertyToRender?.datasetRenderOptions?.pointBackgroundColor || DEFAULT_GREY;
		};
	}
	return dataset;
}
