
/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */
import Store from "./store";

import par from "par";

export default AdminWorkflowDefinitionStore;

function AdminWorkflowDefinitionStore(api) {
	var store = new Store();

	store.raw_get = store.get;
	store.get = get;

	var tracking = [];

	return store;

	function get(id) {
		var existing = store.raw_get(id);
		if (existing) return existing;

		if (!isTracking(id))
			track(id);

		return {};
	}

	function isTracking(id) {
		return tracking.indexOf(id) !== -1;
	}

	function track(id) {
		if (id === undefined) return;
		tracking.push(id);
		api.admin.workflow.definition(id)
			.then(par(setDefinition, id));
	}

	function setDefinition(id, definition) {
		store.set(id, definition);
		return definition;
	}
}