/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";

import par from "par";
import isEmpty from "is-empty";
import Changes from "ae-forms/src/Changes/OutputChange";

/**
 * User Interface elements material-ui
 */
import Button from "@material-ui/1.5.1/Button";

import Chip from "@material-ui/1.5.1/Chip";
import Avatar from "@material-ui/1.5.1/Avatar";
import FontIcon from "@material-ui/1.5.1/Icon";
import Table from "@material-ui/1.5.1/Table";
import TableHeader from "@material-ui/1.5.1/TableHead";
import TableHeaderColumn from "@material-ui/1.5.1/TableCell";
import TableBody from "@material-ui/1.5.1/TableBody";
import TableRow from "@material-ui/1.5.1/TableRow";
import TableRowColumn from "@material-ui/1.5.1/TableCell";

/**
 * User Interface elements Aetonix
 */

import Dialog from "../shared/Dialog";

import Header from "../shared/Header.js";
import Utility from "../shared/Utility.js";
import Scroll from "../shared/InfiniScroll.jsx";
import SearchTable from "../shared/SearchTable.jsx";
import DatePicker from "../shared/DatePicker";
import  AetonixTheme_mui_1_5_1 from "../shared/AetonixTheme_mui_1.5.1";
const Colors = AetonixTheme_mui_1_5_1.palette;

var styles = {
	icon: {
		fontSize: 20,
		color: Colors.canvas
	},
	avatar: {
		backgroundColor: Colors.primary.dark
	},
	back: {
		backgroundColor: Colors.primary.light,
		color: Colors.primary.main
	},
	backPrimary: {
		backgroundColor: "#E0E0E0"
	},
	avatarPrimary: {
		backgroundColor: "#BCBCBC"
	},
	modalSelects: {
		display: "flex",
		flexDirection: "column",
		marginBottom: "15px"
	},
	inputProp: {
		style: {
			color: Colors.primary.main,
		}
	},
};

export default render;

function render() {

	//Boiler plate standard for every history page
	var component = this;
	var state = component.state;
	var localization = state.localization;
	var careplanNoticeList = state.careplanChangeList.all();
	var careplanChanges = !!careplanNoticeList.length;
	var offline = state.connection.get("offline");

	// States relevant to _this_ history page
	var people = state.people;
	var currentPerson = state.currentPerson;
	var definitions = state.definitions;
	var changes = state.changes;

	var rowData = changes ? changes.map(par(convertData, component, people, definitions)) : [];

	var startDate = state.startDate;
	var endDate = state.endDate;
	var openingDataView = state.openingDataView;

	// Search related variables
	var choose_date = localization.get("choose_date");
	var userFilter = state.userFilter;
	var dateFilter = state.dateFilter;
	var filterUser = state.filterUser;
	var closeSearch = state.closeSearch;
	var titleKey = "form_change_history";

	var headers = [
		{
			name: "forms",
			content: localization.get("form_change_history_form")
		}, {
			name: "user",
			content: localization.get("form_change_history_user")
		}, {
			name: "time",
			content: localization.get("form_change_history_time")
		}, {
			name: "data",
			content: localization.get("form_change_history_data")
		}
	];

	var date_actions = [
		<Button key={"buttontime"}
			onClick={par(handle_date, component, "time")}>
			{localization.get("search_apply")}
		</Button >,
		<Button key={"buttoncancel"}
			onClick={par(hideSearch, component, "time")}>
			{localization.get("form_submissions_history_cancel")}
		</Button >
	];

	var data_view_actions = [
		<Button key={"buttonclose"}
			onClick={par(handle_close_dataView, component)}>
			{localization.get("search_close")}
		</Button >,
		<Button key={"buttonconfirm"}
			onClick={par(confirmSingleChange, component)}>
			{localization.get("search_confirm")}
		</Button >
	];

	if (listHasChanged(rowData, state.previousRowData)) {
		component.state.userMetrics.trackEvent("org-forms-changes-history: viewed org forms changes history", {
			changes: rowData.map((row) => row._id),
		});
		component.setState({
			previousRowData: rowData,
		});
	}

	return (
		<div className="flex-vertical flex-1">

			<Header
				careplanChanges={careplanChanges}
				offline={offline}
				currentPerson={currentPerson}
				localization={localization}
				titleKey={titleKey}
			/>

			<div className="flex-horizontal ae-empty">
				{renderUserFilterChip(component)}
				{renderDateFilterChip(component)}
			</div>

			<Scroll>
				<Table>
					{renderHeader(component, headers)}
					{renderBody(component, headers, rowData)}
				</Table>
			</Scroll>

			<SearchTable action={par(filterUser, component)} ref="searchDialog" search={state.search}
				localization={localization}
				people={people}
				showing={userFilter}
				onClose={par(closeSearch, component, "user")}
				title={localization.get("search_panel_title_patient")} />

			<Dialog actions={date_actions} open={dateFilter} title={localization.get("search_date_panel_title")}>
				<DatePicker invalid={choose_date}
					labelStart={localization.get("report_start")}
					labelEnd={localization.get("report_end")}
					startDate={startDate}
					endDate={endDate}
					updateStart={par(updateChanged, component, "startDate")}
					updateEnd={par(updateChanged, component, "endDate")}
				/>
			</Dialog>

			<Dialog actions={data_view_actions}
				open={openingDataView}
				title={localization.get("form_change_history_data_view")}>
				{renderDataModal(component)}
			</Dialog>
		</div>
	);
}

function listHasChanged(newList, oldList) {
	newList = newList || [];
	oldList = oldList || [];
	if (newList.length !== oldList.length) return true;
	newList = newList.map((row) => row._id);
	oldList = oldList.map((row) => row._id);
	return newList.some((row, index) => row !== oldList[index]);
}

function handle_close_dataView(component) {
	component.setState({
		openingDataView: false,
		dataToView: null
	});
	component.state.userMetrics.trackEvent("org-forms-changes-history: close form submission popup");
}

function renderDataModal(component) {
	var state = component.state;
	var data = state.dataToView;
	var localization = state.localization;
	var schemaChanges = data ? data.changes : null;
	if (!data) return;
	var definitions = state.definitions;
	var chosen_form = state.chosen_form;
	var cannot_render = localization.get("cannot_render");
	if (!definitions) return;

	var form_types = state.definitions.all();
	var chosen_data = form_types.find(function (item) {
		return item._id === chosen_form;
	});
	var schema = chosen_data.schema;
	var viewData = schema ? <Changes schema={schema} changes={schemaChanges} />
	: <div>{cannot_render}</div>;

	return viewData;
}

function renderDateFilterChip(component) {
	var state = component.state;
	var deleteOn = noop();
	var styleavatar = styles.avatarPrimary;
	var styleback = styles.backPrimary;
	if (!!state.startDate && !!state.endDate) {
		deleteOn = par(clearSearch, component, "time");
		styleavatar = styles.avatar;
		styleback = styles.back;
	}
	return (<Chip className="ae-icon ae-fonticon"
		onDelete={deleteOn}
		style={styleback}
		onClick={par(showSearch, component, "time")}
		avatar={<Avatar alt="Date Filter" style={styleavatar}>
			<FontIcon className="fa fa-filter fa-2x" />
		</Avatar>}
		label={renderFilterLabel(component, "time")}
	/>);
}

function renderUserFilterChip(component) {
	var state = component.state;
	var deleteOn = noop();
	var styleavatar = styles.avatarPrimary;
	var styleback = styles.backPrimary;
	if (state.user.length) {
		deleteOn = par(clearSearch, component, "user");
		styleavatar = styles.avatar;
		styleback = styles.back;
	}
	return (
		<Chip className="ae-icon ae-fonticon"
			onDelete={deleteOn}
			style={styleback}
			onClick={par(showSearch, component, "user")}
			avatar={<Avatar alt="User Filter" style={styleavatar}>
				<FontIcon className="fa fa-filter fa-2x" />
			</Avatar>}
			label={renderFilterLabel(component, "user")}
		/>
	);
}

function confirmSingleChange(component) {
	var state = component.state;
	state.handleConfirmSingleChange(component);
	handle_close_dataView(component);
	component.state.userMetrics.trackEvent("org-forms-changes-history: confirm view", {
		form: state.chosen_form
	});
}

function renderHeader(component, headers) {
	return (
		<TableHeader>
			<TableRow>
				{headers.map(renderHeaderColumn)}
			</TableRow>
		</TableHeader>
	);
}

function renderHeaderColumn(column, index) {
	return (
		<TableHeaderColumn key={index} className="flex-spread">
			{column.content}
		</TableHeaderColumn>
	);
}

function renderBody(component, headers, rows) {
	return (
		<TableBody>
			{rows.map(par(renderRow, headers))}
		</TableBody>
	);
}

function renderRow(headers, row, index) {
	return (
		<TableRow key={index}>
			{headers.map(par(renderRowColumn, row))}
		</TableRow>
	);
}

function renderRowColumn(row, column, index) {
	return (
		<TableRowColumn key={index}>
			{row[column.name]}
		</TableRowColumn>
	);
}

function convertData(component, people, definitions, record) {
	if (!record || !definitions) return;
	var user = Utility.format_name(people.get(record.owner));
	var updated_at = Utility.timestamp(record.created_at);
	var form = definitions.get(record.schema) ? definitions.get(record.schema).localization["en"] : "---";
	var data = renderData(component, record);

	return {
		"forms": form,
		"user": user,
		"time": updated_at,
		"data": data
	};
}

function renderData(component, data) {
	var localization = component.state.localization;
	var showData = function () {
		component.setState({
			openingDataView: true,
			dataToView: data,
			chosen_form: data.schema
		});
		component.state.userMetrics.trackEvent("org-forms-changes-history: open form submission popup", {
			submission: data._id
		});
	};

	return (
		<Chip className="ae-icon ae-fonticon"
			style={styles.backPrimary}
			onClick={showData}
			label={localization.get("submissions_view_data")}
		/>
	);
}

function updateChanged(component, name, event, date) {
	var value = date;
	if (event) {
		value = event._d;
	}
	var update = {};
	update[name] = value;
	component.setState(update);
}

/**
 * A function to reset a date by 1 day ahead.	Used to adjust the end date
 * on the date selector function because the default value is midnight of the
 * day selected instead of say, 23:59 of the day chosen or midnight of the next
 * day
 * @param	{String} date Date string
 * @return {String}			Date string
 */
function adjust_date(date) {
	if (isEmpty(date)) return "";
	var end = new Date(date);
	end.setDate(end.getDate() + 1);
	return end;
}

function showSearch(component, name) {
	if (name === "user") component.setState({
		userFilter: true
	});
	if (name === "time") component.setState({
		dateFilter: true
	});
	const eventName = {
		"time": "date",
		"user": "user",
	}[name];
	component.state.userMetrics.trackEvent(`org-forms-changes-history: open ${eventName} filter popup`);
}

function hideSearch(component, name) {
	if (name === "user") component.setState({
		userFilter: false
	});
	if (name === "time") component.setState({
		dateFilter: false
	});
	const eventName = {
		"time": "date",
		"user": "user",
	}[name];
	component.state.userMetrics.trackEvent(`org-forms-changes-history: close ${eventName} filter popup`);
}

function clearSearch(component, name) {
	var clear = component.state.filterClear;
	if (name === "user") {
		component.setState({
			user: []
		});
		return clear("user");
	}
	if (name === "time") {
		component.setState({
			startDate: "",
			endDate: ""
		});
		return clear("time");
	}
	if (name === "form") {
		component.setState({
			forms: [],
			chosen_form: ""
		});
		return clear("form");
	}
}

function renderFilterLabel(component, name) {
	var state = component.state;
	var localization = state.localization;
	var label;
	if (name === "time") {
		var start = state.startDate;
		var end = state.endDate;
		if (!start || !end) return (localization.get("search_time"));
		start = shortDate(start);
		end = shortDate(end);
		label = start + " - " + end;
		return label;
	}
	if (name === "user") {
		var lengthUser = state.user.length;
		if (!lengthUser) return(localization.get("search_patient"));
		label = localization.get("search_patient") + " (" + lengthUser + ")";
		return label;
	}
	if (name === "form") {
		var lengthForms = state.forms.length;
		if (!lengthForms) return (localization.get("search_forms"));
		label = localization.get("search_forms") + " (" + lengthForms + ")";
		return label;
	}
}

function shortDate(date) {
	var newdate = new Date(date);
	var day = newdate.getDate();
	var month = newdate.getMonth() + 1;
	var year = newdate.getFullYear();
	return (day + "/" + month + "/" + year);

}

function handle_date(component, value) {
	var state = component.state;
	hideSearch(component, value);
	var start = state.startDate;
	var end = adjust_date(state.endDate);
	component.state.userMetrics.trackEvent("org-forms-changes-history: filter by date", {
		start,
		end
	});
	return state.filterDate(start, end);
}

function noop() { }