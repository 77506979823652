/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, May 3, 2018
 * For information or permission request, email info@aetonixsystems.com
 */


import React, { useState } from "react";
import Immutable from "immutable";

import {
	Button,
	Divider,
	ExpansionPanel,
	ExpansionPanelActions,
	ExpansionPanelDetails,
	ExpansionPanelSummary,
	TextField,
	Typography
} from "@material-ui/1.5.1";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import Dialog from "../shared/Dialog";
import AetonixTheme from "../shared/AetonixTheme";
import SETTINGS from "../shared/SettingList";
const Colors = AetonixTheme.palette;

var styles = {
	input: {
		color: "grey",
		marginTop: "8px",
		marginBottom: "8px"
	},
	inputProp: {
		style: {
			color: Colors.primary.main,
		}
	},
};


// eslint-disable-next-line no-unused-vars
const CustomPropertiesExpansionPanel = ({ component, onChange, updateCustomProp, ...props }) => {
	const [panelOpenMap, setPanelOpenMap] = useState(Immutable.Map());
	const [addNewOpen, setAddNewOpen] = useState(false);
	const [pendingDelete, setPendingDelete] = useState("");
	const [newCustomProperty, setNewCustomProperty] = useState({});
	const [newCustomPropertyErrors, setNewCustomPropertyErrors] = useState({});
	const [customPropertiesToUpdateErrors, setCustomPropertiesToUpdateErrors] = useState({});

	const state = component.state;
	const localization = state.localization;
	const groupCustomProperties = state.groupCustomProperties;
	const uniqueKeyErrorText = newCustomPropertyErrors.uniqueKeyError && "Key is required or there is already an existing custom property with the same key" || null;
	const localizationErrorText = newCustomPropertyErrors.localizationError && "Please make sure to enter English localization for this custom property" || null;

	// State methods
	const setPanelOpen = (key, val) => {
		setPanelOpenMap(panelOpenMap.set(key, val));
	};

	const collapseAllPanels = () => {
		component.state.userMetrics.trackEvent("admin-group-settings: collapse all panels");
		setPanelOpenMap(panelOpenMap.clear());
	};

	const expandAllPanels = () => {
		component.state.userMetrics.trackEvent("admin-group-settings: expand all panels");
		setPanelOpenMap(Immutable.Map(groupCustomProperties.map(customProperty => [customProperty.key, true])));
	};

	const togglePanelOpen = key => {
		return () => {
			component.state.userMetrics.trackEvent("admin-group-settings: toggle panel open", {
				"key": key,
			});
			setPanelOpenMap(panelOpenMap.set(key, !panelOpenMap.get(key)));
		};
	};

	const closeDeleteConfirmation = () => {
		return setPendingDelete("");
	};

	const updateNewCustomPropertyKeyField = (event) => {
		event.persist();
		const text = event.target.value;
		const toUpdate = newCustomProperty;
		toUpdate.key = text;
		setNewCustomProperty(toUpdate);
	};

	const updateNewCustomPropertyLocalizationField = (lang, event) => {
		event.persist();
		const text = event.target.value;
		const toUpdate = newCustomProperty;
		toUpdate["localization"] = newCustomProperty["localization"] || {};
		toUpdate["localization"][lang] = text;
		setNewCustomProperty(toUpdate);
	};

	const removeCustomProperty = customPropertyKey => {
		let newState = [...groupCustomProperties];
		let index = newState.findIndex(customProperty => customProperty.key === customPropertyKey);
		newState.splice(index, 1);

		onChange({newState});
		setPanelOpen(customPropertyKey, false);
	};

	const updateComponentTextField = (keyToUpdate, lang, event) => {
		event.persist();
		const text = event.target.value;
		let newState = [...groupCustomProperties];
		let allowUpdate = true;
		let errorsToUpdate = {...customPropertiesToUpdateErrors};
		let updateHasError = false;
		for (let index = 0; index < groupCustomProperties.length; ++index) {
			if (groupCustomProperties[index].key === keyToUpdate) {
				if (!text && lang === "en") {
					errorsToUpdate[keyToUpdate] = "Please make sure to enter English localization for this custom property";
					allowUpdate = false;
				} else {
					let customPropertyToChange = {key: keyToUpdate};
					customPropertyToChange["localization"] = {...groupCustomProperties[index]["localization"]};
					customPropertyToChange["localization"][lang] = text;
					newState[index] = customPropertyToChange;
					errorsToUpdate[keyToUpdate] = null;
				}
			}
		}
		const errorsKeys = Object.keys(errorsToUpdate);
		for (let index = 0; index < errorsKeys.length; ++index) {
			const objectKey =  errorsKeys[index];
			if (errorsToUpdate[objectKey]) {
				updateHasError = true;
				allowUpdate = false;
				break;
			}
		}

		if (allowUpdate) {
			onChange({newState, updateHasError});
			setCustomPropertiesToUpdateErrors({});
		} else {
			onChange({updateHasError});
			setCustomPropertiesToUpdateErrors(errorsToUpdate);
		}
	};

	const customPropertyTitleMap = new Map(
		groupCustomProperties.map(customProperty => {
			return [customProperty.key, `${customProperty.key} - ${customProperty["localization"]["en"]}`];
		})
	);

	const handleAddNewActionAddNew = () => {
		let updatedError = {};
		let allowAddNew = true;
		const setting = SETTINGS[7];
		if (!newCustomProperty.key) {
			updatedError.uniqueKeyError = true;
			allowAddNew = false;
		}
		for (let index = 0; index < groupCustomProperties.length; ++index) {
			if (groupCustomProperties[index].key === newCustomProperty.key) {
				allowAddNew = false;
				updatedError.uniqueKeyError = true;
				break;
			}
		}
		if (!newCustomProperty["localization"] || !newCustomProperty["localization"]["en"]) {
			allowAddNew = false;
			updatedError.localizationError = true;
		}
		if (allowAddNew) {
			let newState = [...groupCustomProperties];
			newState.push(newCustomProperty);
			onChange({newState});
			setNewCustomProperty({});
			updateCustomProp(setting, newCustomProperty);
			setNewCustomPropertyErrors({});
			setAddNewOpen(false);
		} else {
			setNewCustomPropertyErrors(updatedError);
		}
		component.state.userMetrics.trackEvent("admin-group-settings: create new custom property", {
			"key": newCustomProperty?.key,
			"localization": newCustomProperty?.["localization"] && newCustomProperty?.["localization"]?.["en"] || null,
		});
	};

	const handleAddNewActionBack = () => {
		component.state.userMetrics.trackEvent("admin-group-settings: close create new custom property popup");
		setNewCustomProperty({});
		setNewCustomPropertyErrors({});
		setAddNewOpen(false);
	};

	const addNewActions = [
		(<Button key={"button_addNew"} onClick={handleAddNewActionAddNew}>{localization.get("common.addnew")}</Button>),
		(<Button key={"button_back"} onClick={handleAddNewActionBack}>{localization.get("common.back")}</Button>)
	];

	const deleteActions = [
		(<Button
			key={"button_confirm"}
			onClick={() => {
				component.state.userMetrics.trackEvent("admin-group-settings: remove custom property and close popup", {
					"key": pendingDelete,
				});
				removeCustomProperty(pendingDelete);
				closeDeleteConfirmation();
			}}
		>
			{localization.get("common.confirm")}
		</Button>),
		(<Button key={"button_cancel"} onClick={() => {
			closeDeleteConfirmation();
			component.state.userMetrics.trackEvent("admin-group-settings: close remove custom property confirmation popup");
		}}>{localization.get("common.cancel")}</Button>)
	];

	const renderCustomPropertyFields = (isAddingNew, defaultKeyValue, defaultLocalizationObject) => {
		const additionalLocalizationLanguages = ["fr", "cn_s", "cn_t", "es", "pt", "ta"];
		const renderKeyField = isAddingNew ? (
			<TextField
				fullWidth
				style={styles.input}
				InputProps={styles.inputProp}
				label={localization.get("admin_group_settings_customProperties.enterKeyTitle")}
				inputProps={{"aria-label": localization.get("admin_group_settings_customProperties.enterKeyTitle")}}
				placeholder={localization.get("admin_group_settings_customProperties.enterKeyTitle")}
				error={newCustomPropertyErrors.uniqueKeyError}
				helperText={uniqueKeyErrorText}
				onChange={updateNewCustomPropertyKeyField}
			/>
		) : null;
		const onChangeFunc = (addingNew, lang) => {
			return (event) => {
				addingNew ? updateNewCustomPropertyLocalizationField(lang, event) : updateComponentTextField(defaultKeyValue, lang, event);
			};
		};
		const updateErrorsText = customPropertiesToUpdateErrors[defaultKeyValue];
		const renderEnglishField = (
			<TextField
				fullWidth
				style={styles.input}
				InputProps={styles.inputProp}
				key={`${defaultKeyValue}_localization_field_en`}
				label={`${localization.get("admin_group_settings_customProperties.localizationForTitle")} - ${localization.get("en")}`}
				placeholder={`${localization.get("admin_group_settings_customProperties.localizationForTitle")} - ${localization.get("en")}`}
				inputProps={{"aria-label": `${localization.get("admin_group_settings_customProperties.localizationForTitle")} - ${localization.get("en")}` }}
				error={isAddingNew ? newCustomPropertyErrors.localizationError : !!updateErrorsText}
				helperText={isAddingNew ? localizationErrorText : updateErrorsText}
				defaultValue={!isAddingNew ? defaultLocalizationObject["en"] : ""}
				onChange={onChangeFunc(isAddingNew, "en")}
			/>
		);
		const renderAdditionalLocalizationFields = additionalLocalizationLanguages.map(localizationLanguage => {
			return (
				<TextField
					fullWidth
					style={styles.input}
					InputProps={styles.inputProp}
					key={`${defaultKeyValue}_localization_field_${localizationLanguage}`}
					label={`${localization.get("admin_group_settings_customProperties.localizationForTitle")} - ${localization.get(localizationLanguage)}`}
					placeholder={`${localization.get("admin_group_settings_customProperties.localizationForTitle")} - ${localization.get(localizationLanguage)}`}
					inputProps={{"aria-label": "Localization " + localization.get(localizationLanguage) }}
					defaultValue={!isAddingNew ? defaultLocalizationObject[localizationLanguage] : ""}
					onChange={onChangeFunc(isAddingNew, localizationLanguage)}
				/>
			);
		});
		return (
			<div>
				{renderKeyField}
				{renderEnglishField}
				{renderAdditionalLocalizationFields}
			</div>
		);
	};

	return (
		<div style={{ paddingTop: 16 }}>
			<div style={{ position: "sticky", top: 8, display: "flex", zIndex: 100, gap: 8 }}>
				<div style={{ flex: 2 }}>
					<Button fullWidth variant="contained" onClick={() => {
							component.state.userMetrics.trackEvent("admin-group-settings: open create new custom property popup");
							return setAddNewOpen(true);
						}}>
						{localization.get("common.addnew")}
					</Button>
				</div>
				<div style={{ flex: 1 }}>
					<Button
						fullWidth
						variant="contained"
						onClick={expandAllPanels}
						disabled={groupCustomProperties.length === 0}
					>
						{localization.get("expansion_panel.expand_all")}
					</Button>
				</div>
				<div style={{ flex: 1 }}>
					<Button
						fullWidth
						variant="contained"
						onClick={collapseAllPanels}
						disabled={groupCustomProperties.length === 0}
					>
						{localization.get("expansion_panel.collapse_all")}
					</Button>
				</div>
			</div>
			<div style={{ marginTop: 8, marginBottom: 8 }}>
				{groupCustomProperties.map(customProperty => {
					const customPropertyKey = customProperty.key;
					const customPropertyLocalization = customProperty["localization"];
					const hasError = customPropertiesToUpdateErrors[customPropertyKey];
					return (
						<ExpansionPanel
							key={customPropertyKey}
							expanded={!!panelOpenMap.get(customPropertyKey)}
							onChange={togglePanelOpen(customPropertyKey)}
						>
							<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
								<Typography color={hasError ? "error" : undefined}>
									{customPropertyTitleMap.get(customPropertyKey)}
								</Typography>
							</ExpansionPanelSummary>
							<ExpansionPanelDetails>
								{renderCustomPropertyFields(false, customPropertyKey, customPropertyLocalization)}
							</ExpansionPanelDetails>
							<Divider />
							<ExpansionPanelActions>
								<Button size="small" onClick={() => {
										component.state.userMetrics.trackEvent("admin-group-settings: open remove custom property confirmation popup", {
											"key": customPropertyKey,
										});
										return setPendingDelete(customPropertyKey);
									}}>
									{localization.get("common.delete")}
								</Button>
								<Button size="small" onClick={togglePanelOpen(customPropertyKey)}>
									{localization.get("expansion_panel.collapse")}
								</Button>
							</ExpansionPanelActions>
						</ExpansionPanel>
					);
				})}
			</div>
			<Dialog actions={addNewActions} open={!!addNewOpen} title={localization.get("admin_group_settings_customProperties.addNewTitle")} onClose={() => setAddNewOpen(false)}>
				{renderCustomPropertyFields(true)}
			</Dialog>
			<Dialog actions={deleteActions} open={!!pendingDelete} title={localization.get("admin_group_settings_customProperties.deleteTitle")} onClose={closeDeleteConfirmation}>
				{localization.get("admin_group_settings_customProperties.deleteText")}
				{customPropertyTitleMap.get(pendingDelete)}
				?
			</Dialog>
		</div>
	);
};

export default CustomPropertiesExpansionPanel;
