


import Store from "./store";

export default ManagerDefaultPermissions;

function ManagerDefaultPermissions(api, events, config){
	var store = new Store();

	api.org.thresholds.default.list()
		.then(setThresholds)
		.then(trackChanges);

	return store;

	function setThresholds(threshold){
		store.set("thresholds", threshold);
	}

	function trackChanges(){
		var orgGroup = config.orgGroup;
		if(!orgGroup) return;
		events.on("mqtt:orggroup/" + orgGroup + "/thresholds/updated", handle_change);
		events.on("mqtt:orggroup/" + orgGroup + "/thresholds/group/removed", refresh);
	}

	function handle_change(thresholds){
		setThresholds(thresholds);
	}

	function refresh() {
		api.org.thresholds.default.list()
			.then(setThresholds);
	}
}