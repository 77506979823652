/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */


import Store from "./store";
import par from "par";

export default AdminGroupSettingsStore;

function AdminGroupSettingsStore(api, events) {
	var store = new Store();

	var tracking = [];

	store.raw_get = store.get;
	store.get = get;
	return store;

	function get(id) {
		var existing = store.raw_get(id);
		if (existing) return existing;
		if(!isTracking(id)) track(id);
		return {};
	}

	function track(id){
		tracking.push(id);
		api.admin.orgGroup.settings.get(id).then(par(setSettings, id));
		events.on("mqtt:orggroup/" + id + "/settings/updated", par(setSettings, id));
	}

	function setSettings(id, settings){
		store.set(id, settings);
	}

	function isTracking(id){
		return tracking.indexOf(id) !== -1;
	}
}
