/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */



import Store from "./store";
import par from "par";

export default UserUrgencyStore;

function UserUrgencyStore(api, events, config) {
	var store = new Store();

	store.raw_get = store.get;
	store.get = get;

	var tracking = [];

	return store;

	function get(id) {
		var existing = store.raw_get(id);
		if (existing) return existing;

		if (!isTracking(id))
			track(id);

		return [];
	}

	function isTracking(id) {
		return tracking.indexOf(id) !== -1;
	}

	function track(id) {
		tracking.push(id);
		api.org.urgency.list(id).then(par(setContacts, id));
		var orgGroup = config.orgGroup;
		if(!orgGroup) return;
		events.on("mqtt:orggroup/" + orgGroup + "/u/" + id + "/urgency/remove", par(removeContact, id));
		events.on("mqtt:orggroup/" + orgGroup + "/u/" + id + "/urgency/add", par(addContact, id));
	}

	function removeContact(id, data) {
		var who = data.who;
		var existing = store.get(id);
		if (!existing) return;

		var filtered = existing.filter(function (existingManager) {
			return existingManager !== who;
		});

		store.set(id, filtered);
	}

	function addContact(id, data) {
		var who = data.who;
		var existing = store.get(id);
		if (!existing) return;
		if(existing.indexOf(who) !== -1) return;

		var added = existing.concat(who);

		store.set(id, added);
	}

	function setContacts(id, contacts) {
		store.set(id, contacts);
	}
}
