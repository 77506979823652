/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

import Store from "./store";
import map from "fj-map";
import compose from "fj-compose";
import pluck from "fj-pluck";
var getWho = pluck("who");
import Utility from "../shared/Utility";
const { getLocalStorageArray } = Utility;
import isEmpty from "lodash/isEmpty";
const _intersection = require("lodash/intersection");
var debounce = require("debounce");

export default UserStore;

function UserStore(api, events, config) {
	var store = new Store();

	var page = 0;
	// var pageSize = 10;
	var loading = null;
	var atEnd = false;
	let eventsList = new Set();

	var userLocalStorageKey = localStorage.getItem("userLocalStorageKey");
	store.activity = getActivity(getLocalStorageArray(`${userLocalStorageKey}stateFilter`));
	store.manageGroups = getLocalStorageArray(`${userLocalStorageKey}manageGroups`);
	store.more = more;
	store.fetch = fetch;
	store.reset = reset;
	store.trackUser = trackUser;
	store.search = search;
	store.sort = sort;
	store.changeActivity = changeActivity;
	store.changeManageGroups = changeManageGroups;
	store.changePageSize = changePageSize;
	store.query = "";
	store.sortQuery = "";
	store.status = null;
	store.pageSize = 10;
	store.count = {};
	store.getCount = getCount;

	reset();

	return store;

	function trackUser(user){
		add_person(user);
	}

	function reset() {
		page = 0;
		atEnd = false;
		store.clear();
		const pageSize = store.pageSize;

		const currState = getLocalStorageArray(`${userLocalStorageKey}stateFilter`);
		const hasActive = currState?.includes("all_active");
		const hasInActive = currState?.includes("inactive");
		const inactiveFilter = !isEmpty(currState) && (hasActive && !hasInActive) ? false : store.status;

		const filters = {
			query: store.query,
			sortQuery: store.sortQuery,
			state: store.activity,
			manageGroups: store.manageGroups,
			status: inactiveFilter,
		};

		return api.org.users
			.listByCurrentStatus(filters, page, pageSize)
			.then(handle_result);
	}

	function fetch(currentPage, size) {
		store.clear();
		if (atEnd)
			return Promise.resolve();
		if (loading)
			return loading;
		page += 1;
		const currState = getLocalStorageArray(`${userLocalStorageKey}stateFilter`);
		const hasActive = currState?.includes("all_active");
		const hasInActive = currState?.includes("inactive");
		const inactiveFilter = !isEmpty(currState) && (hasActive && !hasInActive) ? false : store.status;

		const filters = {
			query: store.query,
			sortQuery: store.sortQuery,
			state: store.activity,
			manageGroups: store.manageGroups,
			status: inactiveFilter,
		};

		return api.org.users
			.listByCurrentStatus(filters, currentPage, size)
			.then(handle_result);
}

	function more(currentPage, size) {
		if (atEnd)
			return Promise.resolve();
		if (loading)
			return loading;
		page += 1;
		const currState = getLocalStorageArray(`${userLocalStorageKey}stateFilter`);
		const hasActive = getLocalStorageArray(`${userLocalStorageKey}stateFilter`).includes("all_active");
		const hasInActive = getLocalStorageArray(`${userLocalStorageKey}stateFilter`).includes("inactive");
		let filters;
		if(!isEmpty(currState) && (hasActive && !hasInActive)){
		 filters = {
				query: store.query,
				sortQuery: store.sortQuery,
				state: store.activity,
				manageGroups: store.manageGroups,
				status: false,
			};
		} else {
			filters = {
				query: store.query,
				sortQuery: store.sortQuery,
				state: store.activity,
				manageGroups: store.manageGroups,
				status: store.status,
			};
		}
		return api.org.users
			.listByCurrentStatus(filters, currentPage, size)
			.then(handle_result)
	}

	function handle_result(results) {
		store.clear();
		const counts = results.counts;
		store.count = counts;

		const users = results.users;
		if (users.length) {
			users.map(add_person);
		} else {
			store.clear();
		}

		loading = null;
		listen_for_changes();
	}

	function getCount() {
		return store.count;
	}

	function handle_users(users) {
		store.clear();
		if (!users.length) {
			atEnd = true;
			return;
		}

		users.map(add_person);

		loading = null;
	}

	function search(newQuery) {
		store.query = newQuery;
		return reset();
	}

	function sort(newQuery) {
		store.sortQuery = newQuery;
		return reset();
	}

	function changeActivity(newActivity) {
		store.activity = getActivity(newActivity);
		return reset();
	}

	function changeManageGroups(newManageGroups) {
		store.manageGroups = newManageGroups;
		return reset();
	}

	function changePageSize(size) {
		store.pageSize = size;
		return reset();
	}

	function getActivity(activity){
		activity = [activity].flat();
		store.status = null;
		const hasAllActive = activity.includes("all_active");
		const hasInActive = activity.includes("inactive");
		if (hasAllActive) {
			activity = [];
			if (!hasInActive) {
				// if "all_active" is selected but "inactive" is not, then all users with
				// inactive=false should be listed
				store.status = false;
			}
		}
		return activity;
	}

	function add_person(id) {
		store.set(id, id);
	}

	function remove_person(id) {
		store.remove(id);
	}

	function listen_for_changes() {
		var orgGroup = config.orgGroup;
		if(!orgGroup) return;

		eventListenerWrapper("mqtt:orggroup/" + orgGroup + "/users/state/updated", handle_state_change);
		eventListenerWrapper("mqtt:orggroup/" + orgGroup + "/users/added", reset);
		eventListenerWrapper("mqtt:orggroup/" + orgGroup + "/users/removed", debounce(reset, 500));

		if(store.manageGroups) {
			store.manageGroups.forEach(group => {
				eventListenerWrapper(`mqtt:managegroup/${group}/patient/removed`, handle_group_patient_change);
				eventListenerWrapper(`mqtt:managegroup/${group}/patient/added`, handle_group_patient_change);
				eventListenerWrapper("mqtt:orggroup/" + orgGroup + "/managegroup/" + group + "/removed", handle_group_removed);
			});
		}
	}

	function handle_state_change(data) {
		const activity = store.activity;
		const { from, state } = data;
		const stateScope = Array.isArray(from) ? [...from, state] : [from, state];
		const intersection = _intersection(stateScope, activity);
		if(intersection.length || activity.includes("all_active")) reset();
	}

	function handle_group_patient_change(data) {
		const activity = store.activity;
		const state = data.state;
		if(!state || !activity.length || activity.includes(state) || activity.includes("all_active")) reset();
	}

	function handle_group_removed(data) {
		const group = data.manageGroup;
		store.manageGroups = store.manageGroups.filter(g => g !== group);
		return reset();
	}

	function eventListenerWrapper(name, fn) {
		if(!eventsList.has(name)){
			eventsList.add(name);
			events.on(name, fn);
		}
	}
}
