/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */



/**
 * This store derives its data from an api call to /org/reminders.  It contains
 * data concerning the history of pending reminders of the members of a group.
 */

import Store from "./store";

import map from "fj-map";
import xtend from "xtend";

import * as Sentry from "@sentry/react";

export default ReminderHistoryStore;

function ReminderHistoryStore(api, events, config) {
	var store = new Store();

	var loading = null;
	var page = 0;
	var startShown = "";
	var endShown = "";
	var startRespond = "";
	var endRespond = "";
	var users = [];
	var state = [];
	var content = null;
	var responseType = null;

	api.org.users.list()
		.then(map(listen_on_user))
		.catch(error => {
			Sentry.captureException(error);
			store.error(error);
		});

	store.sorted = sorted;
	store.more = more;
	store.searchShown = searchShown;
	store.searchRespond = searchRespond;
	store.searchUsers = searchUsers;
	store.searchState = searchState;
	store.clearSearch = clearSearch;

	reset();

	return store;

	function reset() {

		page = 0;
		store.clear();
		return api.org.reminders.groupPendingHistory(users, page, startShown, endShown, startRespond, endRespond, content, state, responseType)
			.then(map(handle_create))
			.catch(error => {
				Sentry.captureException(error);
				store.error(error);
			})
			.then(next_page);
	}

	function more() {
		if(loading) return loading;
		loading = api.org.reminders.groupPendingHistory(users, page, startShown, endShown, startRespond, endRespond, content, state, responseType)
		.then(map(handle_create))
		.then(next_page)
		.catch(error => {
			Sentry.captureException(error);
			store.error(error);
		})
		.then(finish);
	}

	function finish() {
		loading = null;
	}

	function sorted() {
		var list = store.all().sort(by_time);
		var pending = list.filter(is_pending);
		var others = list.filter(isnt_pending);
		return pending.concat(others);
	}

	function listen_on_user(user) {
		var orgGroup = config.orgGroup;
		if(!orgGroup) return;
		events.on("mqtt:orggroup/" + orgGroup + "/u/" + user + "/reminders/history/create", handle_create);
		events.on("mqtt:orggoup/" + orgGroup + "/u/" + user + "/reminders/history/update", handle_update);
	}

	function handle_create(data) {
		var id = data._id;
		store.set(id, data);
	}

	function handle_update(data) {
		var id = data._id;
		var event = data.event;
		/**
		 * if the event is 'responded' or 'cleared' then
		 * the reminder has been responded to and so
		 * DO NOT include it in the pending history
		 */
		if (event === "responded" || event === "cleared") return;

		var timestamp = data.timestamp;

		var history = store.get(id).history;

		var update = {};
		update[event] = timestamp;

		store.update(id, {
			history: xtend(history, update),
			updated_at: timestamp
		});
	}

	function next_page() {
		page += 1;
	}

	function searchState(filterstate) {
		state = filterstate;
		return reset();
	}

	function searchUsers(filterquery) {
		users = users.concat(filterquery);
		return reset();
	}

	function searchShown(startdate, enddate) {
		startShown = startdate;
		endShown = enddate;
		if (!!startShown && !!endShown){
			return reset();
		} else {
			return;
		}
	}

	function searchRespond(startdate, enddate) {
		startRespond = startdate;
		endRespond = enddate;
		if (!!startRespond && !!endRespond){
			return reset();
		} else {
			return;
		}
	}

	function clearSearch(name) {
		if (name === "shown") {
			startShown = "";
			endShown = "";
		}
		if (name === "respond") {
			startRespond = "";
			endRespond = "";
		}
		if (name === "users") users = [];
		if (name === "status") state = [];
		if (name === "content") content = null;
		if (name === "responseType") responseType = null;
		return reset();
	}
}

/**
 * Used in sort of the list of reminders by the 'created_at' time. \This is different
 * from other histories because reminder updates are not important to track, only
 * the time they are shown (created).
 * @param  {Obj}   prev Previous reminder object
 * @param  {obj} next Next reminder object
 * @return {Number}        Time delta between previous and next reminders
 */
function by_time(prev, next) {
	var prev_time = (new Date(prev.updated_at)).getTime();
	var next_time = (new Date(next.updated_at)).getTime();

	var time_delta = next_time - prev_time;
	return time_delta;
}

function get_type(item) {
	if (item.history.accepted) return "accept";
	if (item.history.denied) return "deny";
	if (item.history.cleared) return "cleared";
	if (item.history.responded) return "responded";
	return "pending";
}

function is_pending(item){
	return get_type(item) === "pending";
}

function isnt_pending(item) {
	return !is_pending(item);
}
