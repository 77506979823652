/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

import par from "par";

import defineUI from "../shared/define";
import render from "./render.jsx";
import PersonStore from "../stores/person_store";
import LocalizationStore from "../stores/localization_store";
import CurrentPersonInfoStore from "../stores/current_person_info_store";
import CallGroupsStore from "../stores/org_group_callgroups_store";
import CareplanNotificationStore from "../stores/careplan_notice_store";
import ConnectionStore from "../stores/connection_store";
import StaffStore from "../stores/staff_store";
import makeStoreFactory from "../shared/stores";

const makeStores = makeStoreFactory({
	people: PersonStore,
	currentPerson: CurrentPersonInfoStore,
	localization: LocalizationStore,
	connection: ConnectionStore,
	callgroups: CallGroupsStore,
	careplanChangeList: CareplanNotificationStore,
	staff: StaffStore
});

function make_ui(api, events, config, userMetrics) {
	var storeStore = makeStores(api, events, config);

	window.stores = storeStore.store;

	userMetrics.trackEvent("page view", {
		"page": "group-callgroups",
		"category": "group",
		"permission level": "staff",
	});

	return defineUI({
		getInitialState: getInitialState,
		componentDidMount: listen_for_changes,
		render: render
	});

	function listen_for_changes() {
		storeStore.listen(par(handle_change, this));
	}

	function handle_change(component, stores) {
		component.setState({
			currentPerson: stores.currentPerson,
			localization: stores.localization,
			people: stores.people,
			callgroups: stores.callgroups,
			careplanChangeList: stores.careplanChangeList,
			connection: stores.connection,
			staff: stores.staff
		});
	}

	function getInitialState() {
		return {
			currentPerson: storeStore.stores.currentPerson,
			localization: storeStore.stores.localization,
			people: storeStore.stores.people,
			connection: storeStore.stores.connection,
			careplanChangeList: storeStore.stores.careplanChangeList,
			callgroups: storeStore.stores.callgroups,
			staff: storeStore.stores.staff,
			viewing: null,
			creating: false,
			updating: false,
			adding: false,
			removing: false,
			removingMember: false,
			newName: null,
			newDescription: null,
			updateName: null,
			updateDescription: null,
			filteredMembers: null,
			file: null,
			fileView: null,
			create: createCallGroup,
			remove: removeCallGroup,
			update: updateCallGroup,
			removeMember: removeMember,
			addMember: addMember,
			search: doSearch,
			userMetrics: userMetrics,
		};
	}

	function createCallGroup(name, description, file){
		return api.org.callgroups.create(name, description, null, file);
	}

	function removeCallGroup(group){
		return api.org.callgroups.remove(group);
	}

	function updateCallGroup(group, name, description, file){
		return api.org.callgroups.update(group, name, description, null, file);
	}

	function removeMember(group, member){
		return api.org.callgroups.members.remove(group, member);
	}

	function addMember(group, member){
		return api.org.callgroups.members.add(group, member);
	}

	function doSearch(query) {
		var filter = {
			type: {
				exclude: ["atouchaway", "user:mobile", "simplified"]
			}
		};

		return api.people.search(query, filter);
	}
}


export default make_ui;
