/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

import par from "par";

import React from "react";
import Table from "@material-ui/1.5.1/Table";
import TableHeader from "@material-ui/1.5.1/TableHead";
import TableHeaderColumn from "@material-ui/1.5.1/TableCell";
import TableBody from "@material-ui/1.5.1/TableBody";
import TableRow from "@material-ui/1.5.1/TableRow";
import TableRowColumn from "@material-ui/1.5.1/TableCell";
import Header from "../shared/Header.js";
import Utility from "../shared/Utility.js";
import Scroll from "../shared/InfiniScroll.jsx";
import  AetonixTheme_mui_1_5_1 from "../shared/AetonixTheme_mui_1.5.1";
const Colors = AetonixTheme_mui_1_5_1.palette;

var styles = {
	header: {
		color: Colors.primary.dark,
	}
};

export default render;

function render() {
	var component = this;
	var state = component.state;
	var localization = state.localization;
	var careplanNoticeList = state.careplanChangeList.all();
	var careplanChanges = !!careplanNoticeList.length;
	var offline = state.connection.get("offline");
	var currentPerson = state.currentPerson;
	var loadMore = state.more || noop;
	var titleKey = "reminders_title";

	var headers = [
		{
			name: "time",
			content: localization.get("reminders_header_time")
		}, {
			name: "time_pending",
			content: localization.get("reminders_header_time_pending")
		}, {
			name: "name",
			content: localization.get("reminders_header_name")
		}, {
			name: "state",
			content: localization.get("reminders_header_state")
		}, {
			name: "text",
			content: localization.get("reminders_header_content")
		}
	];

	var rowData = generate_data(state.people, state.reminders, state.history, localization);

	if (dataHasChanged(state.history, state.previousHistory)) {
		state.userMetrics.trackEvent("reminders-live: view reminders", {
			reminders: state.history.map((reminder) => reminder._id),
		});
		component.setState({
			previousHistory: state.history,
		});
	}


	return (
		<div className="flex-vertical flex-1">

			<Header
				careplanChanges={careplanChanges}
				offline={offline}
				currentPerson={currentPerson}
				localization={localization}
				titleKey={titleKey}
			/>
			<Scroll loadMore={loadMore}>
				<Table>
					{renderHeader(headers)}
					{renderBody(rowData, headers)}
				</Table>
			</Scroll>
		</div>
	);
}

function dataHasChanged(history, previousHistory) {
	history = history || [];
	previousHistory = previousHistory || [];
	if (history.length !== previousHistory.length) {
		return true;
	}
	for (var i = 0; i < history.length; i++) {
		if (history[i]._id !== previousHistory[i]._id) {
			return true;
		}
	}
	return false;
}

function renderHeader(headers) {
	return (
		<TableHeader>
			<TableRow style={styles.header}>
				{headers.map(renderHeaderColumn)}
			</TableRow>
		</TableHeader>
	);
}

function renderHeaderColumn(column, index) {
	return (
		<TableHeaderColumn key={index}>{column.content}</TableHeaderColumn>
	);
}

function renderBody(rows, headers) {
	return (
		<TableBody>
			{rows.map(par(renderRow, headers))}
		</TableBody>
	);
}

function renderRow(headers, row, index) {
	return (
		<TableRow key={index}>
			{headers.map(par(renderRowColumn, row))}
		</TableRow>
	);
}

function renderRowColumn(row, column, index) {
	return (
		<TableRowColumn key={index}>
			{row[column.name]}
		</TableRowColumn>
	);
}

function generate_data(people, reminders, history, localization) {
	return history.map(function function_name(item) {
		var state = get_type(item);

		return {
			time: timestamp_text(item),
			time_pending: timestamp_delta(item),
			name: Utility.format_name(people.get(item.from)),
			state: state,
			text: reminders.get(item.reminder).content
		};

	}).filter(par(filter_for, localization));  // filter to show 'pending' only
}

function filter_for(localization, value) {
	if (value.state === "pending")
		return localization.get("reminders_pending");
}

function get_type(item) {
	if (!!item.history.accepted || !!item.history.denied
		|| !!item.history.responded || !!item.history.cleared) {
		return "not pending";
	}
	return "pending";

}

function timestamp_text(item) {
	return Utility.timestamp(item.created_at);
}

function timestamp_delta(item) {
	var delta = time_delta(item.created_at, Date.now());
	delta = delta / 1000;
	var seconds = Math.floor(delta % 60);
	delta = delta / 60;
	var minutes = Math.floor(delta % 60);
	delta = delta / 60;
	var hours = Math.floor(delta % 24);

	function fill(number) {
		if (number.toString().length < 2) {
			return "0" + number;
		}
		return number;
	}

	return fill(hours) + ":" + fill(minutes) + ":" + fill(seconds);
}

function time_delta(start, end) {
	var start_time = (new Date(start)).getTime();
	var end_time = (new Date(end)).getTime();
	var delta = end_time - start_time;
	return delta;
}

function noop() { }
