
/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";

import par from "par";
import config from "../../configs/config.json";
import Header from "../shared/Header.js";
import IconButton from "@material-ui/1.5.1/IconButton";
import TextField from "@material-ui/1.5.1/TextField";
import List from "@material-ui/1.5.1/List";
import ListItem from "@material-ui/1.5.1/ListItem";
import ListItemText from "@material-ui/1.5.1/ListItemText";
import Dialog from "../shared/Dialog";
import Button from "@material-ui/1.5.1/Button";
import Avatar from "@material-ui/1.5.1/Avatar";
import LazyList from "../shared/LazyList.jsx";
import Utility from "../shared/Utility.js";
import  AetonixTheme_mui_1_5_1 from "../shared/AetonixTheme_mui_1.5.1";
const Colors = AetonixTheme_mui_1_5_1.palette;
var styles = {
	search: {
		color: Colors.primary.main,
		minWidth: "30%",
		padding: 8
	},
	input: {
		color: Colors.primary.main,
		padding: 8
	},
	inputProp: {
		style: {
			color: Colors.primary.main,
		}
	},
};

export default render;

function render() {
	var component = this;
	var state = component.state;

	var editing = state.editing;
	var creating = state.creating;
	var searching = state.searching;
	var confirming = state.confirming;
	var groups = state.groupList;
	var searchNew = state.searchNew;
	var setAdministrator = state.setAdministrator;
	var localization = state.localization;
	var careplanNoticeList = state.careplanChangeList.all();
	var careplanChanges = !!careplanNoticeList.length;
	var offline = state.connection.get("offline");
	var currentPerson = state.currentPerson;
	var sortedGroups = groups.sort(by_name);
	var loadMore = state.loadMore;
	var searchExist = state.searchExist;
	var titleKey = "org_groups_manage";

	var standardActions_add = [
		(<Button onClick={par(addGroup, component)}>{localization.get("org_groups_submit")}</Button >),
		(<Button onClick={par(dismissAddModal, component)}>{localization.get("org_groups_cancel")}</Button >),
	];

	var standardActions_edit = [
		(<Button onClick={par(editGroup, component)}>{localization.get("org_groups_submit")}</Button >),
		(<Button onClick={par(dismissEditModal, component)}>{localization.get("org_groups_cancel")}</Button >),
	];

	var standardActions_administrator = [
		(<Button onClick={par(hideSearch, component)}>{localization.get("org_groups_cancel")}</Button >),
	];

	var standardActions_confirmRemove = [
		(<Button onClick={par(newAdministrator, component)}>{localization.get("org_groups_yes")}</Button >),
		(<Button onClick={par(hideConfirm, component)}>{localization.get("org_groups_no")}</Button >),
	];

	var renderGroupListItem = par(renderGroup, component);

	return (
		<div className="flex-vertical flex-1 ">

			<Header
				careplanChanges={careplanChanges}
				offline={offline}
				currentPerson={currentPerson}
				localization={localization}
				titleKey={titleKey}
			/>

			<div className="ae-left-margin">
				<TextField placeholder={localization.get("search_onName")} inputProps={{"aria-label": localization.get("search_onName")}} onChange={searchExist} style={styles.search} InputProps={styles.inputProp} />
			</div>

			<LazyList loadMore={loadMore} renderItem={renderGroupListItem} items={sortedGroups} />

			<Button variant="raised" onClick={par(showAddGroup, component)} color="secondary" >{localization.get("org_groups_newgroupadd")}</Button>

			<Dialog actions={standardActions_add} open={!!creating} title={localization.get("org_groups_entergroup")} >
				<TextField fullWidth placeholder={localization.get("org_groups_newgroupname")} inputProps={{"aria-label": localization.get("search_onName")}} onChange={par(newGroupInput, component)} />
			</Dialog>
			<Dialog actions={standardActions_edit} open={!!editing} title={localization.get("org_groups_edit")}>
				<div className="flex-vertical flex-1">
					<TextField fullWidth placeholder={localization.get("org_groups_newname")} inputProps={{"aria-label": localization.get("org_groups_newname")}} defaultValue={component.state.editgroupname} onChange={par(newGroupName, component)} style={styles.input} InputProps={styles.inputProp} />
					<TextField fullWidth placeholder={localization.get("org_groups_newdescription")} inputProps={{"aria-label": localization.get("org_groups_newdescription")}} defaultValue={component.state.editdescription} multiLine onChange={par(newGroupDescription, component)} style={styles.input} InputProps={styles.inputProp} />
				</div>
			</Dialog>
			<Dialog actions={standardActions_administrator} open={searching} title={localization.get("org_groups_addadmin")}>
				<div className="flex-vertical flex-1">
					<TextField fullWidth placeholder={localization.get("org_groups_newadmin")} onChange={par(doSearch, searchNew)} InputProps={styles.inputProp} />
					{renderSearchResults(component, setAdministrator)}
				</div>
			</Dialog>
			<Dialog actions={standardActions_confirmRemove} open={!!confirming} title={localization.get("org_groups_removeadmin")}>
				{localization.get("org_groups_removewarn")}
			</Dialog>

		</div>
	);
}

function by_name(x, y) {
	var X = x.name.toLowerCase();
	var Y = y.name.toLowerCase();

	if (X < Y) return -1;
	if (X > Y) return 1;
	return 0;
}

function newGroupInput(component, event) {
	component.setState({
		newGroupInput: event.target.value
	});
}

function newGroupName(component, event) {
	component.setState({
		newGroupName: event.target.value
	});
}

function newGroupDescription(component, event) {
	component.setState({
		newGroupDescription: event.target.value
	});
}

function renderSearchResults(component, setAdministrator) {
	var searchResults = component.state.searchResults;
	if (!searchResults) return;

	return (
		<List>
			{searchResults.map(par(renderSearchItem, component, setAdministrator, component.state.toAdd))}
		</List>
	);
}

function showSearch(component) {
	component.setState({ searching: true });
}

function hideSearch(component) {
	component.setState({
		searching: false,
		searchResults: null
	});
	component.state.userMetrics.trackEvent("org-groups: close search popup");
}

function doSearch(search, e) {
	var query = e.target.value;
	search(query);
}

function renderGroup(component, group) {

	var name = group.name;
	var description = group.description;
	var ownertext = (
		<span />
	);

	var administrator = group.administrator;

	if (administrator) {
		var person = component.state.people.get(administrator);
		ownertext =
			component.state.localization.get("org_groups_whoadmin") + " " + Utility.format_name(person);
	}

	var edit = par(showEditGroup, component, group);
	var buttons = renderEditGroup(edit, component, group);

	var groupId = group._id;
	var background_colour = "ae-plain";
	var viewing = component.state.viewing;
	if (viewing && groupId === viewing) background_colour = "ae-hover-color";

	var secondaryText = (
		<div className={background_colour}>
			{description}
			<div className={background_colour}>
				{ownertext}
			</div>
		</div>
	);

	return (
		<ListItem key={groupId} className={background_colour} onClick={par(set_viewing, component, groupId)}>
			<ListItemText primary={name} secondary={secondaryText} />
			{buttons}
		</ListItem>
	);
}

function set_viewing(component, group) {
	component.setState({
		viewing: group
	});
}

function showAddGroup(component) {
	component.setState({ creating: true });
	component.state.userMetrics.trackEvent("org-groups: open add group popup");
}

function dismissAddModal(component) {
	component.setState({ creating: false });
	component.setState({ newGroupInput: null });
	component.state.userMetrics.trackEvent("org-groups: close add group popup");
}

function addGroup(component) {
	var name = component.state.newGroupInput;
	var add = component.state.addGroup;
	add(name);
	dismissAddModal(component);
	component.state.userMetrics.trackEvent("org-groups: add group", {
		name,
	});
}

function renderEditGroup(edit, component, group) {
	var localization = component.state.localization;
	return (
		<div>
			<IconButton color="primary" aria-label={localization.get("edit_button")}   title={localization.get("edit_button")}  role="button" className="fa fa-pencil" onClick={edit} />
			{renderAdminAddOrRemove(component, group)}
		</div>
	);
}

function renderAdminAddOrRemove(component, group) {
	var localization = component.state.localization;
	if (group.administrator) return (
		<IconButton color="primary" aria-label={localization.get("remove_admin_button")} title={localization.get("remove_admin_button")} role="button" className="fa fa-user-times" label="Primary" onClick={par(removeAdministrator, component, group)} />
	);

	return (
		<IconButton color="primary" aria-label={localization.get("add_admin_button")}  title={localization.get("add_admin_button")} role="button" className="fa fa-user-plus" label="Primary" onClick={par(startAdding, component, group._id)} />
	);
}

function renderSearchItem(component, setAdministrator, group, person) {
	var avatar = <Avatar alt="Group Administrator Avatar Image" src={config.image_cdn + person.image} />;
	var text = Utility.format_name(person);
	var pers_id = person._id;
	var clickAction = par(confirmAdministrator, component, pers_id);
	return (
		<ListItem key={pers_id} onClick={clickAction} >
			{avatar}
			<ListItemText primary={text} />
		</ListItem>
	);
}

function dismissEditModal(component) {
	component.setState({ editing: false });
	component.setState({ newGroupName: null });
	component.setState({ newGroupDescription: null });
	component.state.userMetrics.trackEvent("org-groups: close edit group popup");
}

function showEditGroup(component, group) {
	component.setState({
		editing: group._id,
		editgroupname: group.name,
		editdescription: group.description
	});
	component.state.userMetrics.trackEvent("org-groups: open edit group popup");
}

function editGroup(component) {
	var enteredName = component.state.newGroupName;
	var enteredDescription = component.state.newGroupDescription;
	var group = component.state.editing;
	component.state.updateGroup(group, {
		name: enteredName,
		description: enteredDescription
	});
	dismissEditModal(component);
	component.state.userMetrics.trackEvent("org-groups: edit group", {
		name: enteredName,
		description: enteredDescription
	});
}

function removeAdministrator(component, group) {
	component.setState({
		toRemove: group._id
	});
	showConfirm(component);
	component.state.userMetrics.trackEvent("org-groups: open remove admin popup");
}

function newAdministrator(component) {
	component.state.removeAdministrator(component.state.toRemove);
	hideConfirm(component);
	showSearch(component);
	component.state.userMetrics.trackEvent("org-groups: remove admin", {
		who: component.state.toRemove,
	});
}

function confirmAdministrator(component, person) {
	var toAdd = component.state.toAdd;
	var setAdministrator = component.state.setAdministrator;
	setAdministrator(toAdd, person);
	hideSearch(component);
	component.state.userMetrics.trackEvent("org-groups: select admin", {
		who: person,
	});
}

function startAdding(component, group) {
	showSearch(component);
	component.setState({
		toAdd: group
	});
	component.state.userMetrics.trackEvent("org-groups: open select admin popup");
}

function hideConfirm(component) {
	component.setState({ confirming: false });
	component.state.userMetrics.trackEvent("org-groups: close remove admin popup");
}

function showConfirm(component) {
	component.setState({
		confirming: true
	});
}
