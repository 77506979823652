/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";

import par from "par";
import config from "../../configs/config.json";
import escape_regex from "escape-regexp";
import Header from "../shared/Header.js";
import SubHeader from "@material-ui/1.5.1/ListSubheader";
import IconButton from "@material-ui/1.5.1/IconButton";
import Avatar from "@material-ui/1.5.1/Avatar";
import ListItem from "@material-ui/1.5.1/ListItem";
import ListItemText from "@material-ui/1.5.1/ListItemText";
import Dialog from "../shared/Dialog";
import Button from "@material-ui/1.5.1/Button";
import TextField from "@material-ui/1.5.1/TextField";
import Toggle from "@material-ui/1.5.1/Switch";
import FormGroup from "@material-ui/1.5.1/FormGroup";
import FormControlLabel from "@material-ui/1.5.1/FormControlLabel";
import Checkbox from "@material-ui/1.5.1/Checkbox";
import Utility from "../shared/Utility";
import LazyList from "../shared/LazyList.jsx";
import Search from "../shared/Search.jsx";
import  AetonixTheme_mui_1_5_1 from "../shared/AetonixTheme_mui_1.5.1";
const Colors = AetonixTheme_mui_1_5_1.palette;

import moment from "moment-timezone";

var iconButtonClasses = {
	root: "ae-inline"
};

var styles = {
	dropUnderline: {
		borderTop: 0,
	},
	dropDown: {
		alignItems: "baseline",
		marginLeft: "30px",
		padding: "0 5px",
		color: Colors.primary.main
	},
	search: {
		color: Colors.primary.main,
		minWidth: "30%",
		padding: 8
	},
	tabsRoot: {
		backgroundColor: Colors.primary.main,
		color: "white"
	},
	subHeader: {
		display: "flex",
		flexDirection: "row",
		paddingLeft: "1em",
		alignItems: "baseline"
	},
	input: {
		color: "grey",
		marginTop: "8px",
		marginBottom: "8px"
	},
	timeInput: {
		marginTop: "8px",
		marginBottom: "8px",
		width: 200
	},
	inputProp: {
		style: {
			color: Colors.primary.main,
		}
	},
	formControlStyle: {
		marginTop: "8px",
		marginBottom: "8px"
	},
	userInactive: {
		color: "red"
	},
	snackbar: {
		style: {
			backgroundColor: "red"
		}
	}
};

export default render;

var DAYS = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

var timeInputProps = {
	step: 300
};

function render() {
	var component = this;
	var state = component.state;
	var people = state.people;
	var localization = state.localization;
	var careplanNoticeList = state.careplanChangeList.all();
	var careplanChanges = !!careplanNoticeList.length;
	var offline = state.connection.get("offline");
	var creating = state.creating;
	var updating = state.updating;
	var currentPerson = state.currentPerson;
	var livechat = state.livechat;
	var viewing = state.viewing;
	var removingMember = state.removingMember;
	var removing = state.removing;
	var filteredMembers = state.filteredMembers;
	var filteredLiveChats = state.filteredLiveChats;

	var titleKey = "livechat_title";
	var current = null;
	var membersRaw = null;
	var members = [];
	var shownMembers = [];
	var allLiveChats = livechat.all();
	var shownLiveChats = filteredLiveChats && filteredLiveChats.length ? filteredLiveChats : allLiveChats;

	if(viewing){
		current = livechat.get(viewing);
		membersRaw = current.members;
		members = membersRaw.map(people.get).sort(by_name);
		shownMembers = filteredMembers && filteredMembers.length ? filteredMembers : members;
	}

	var currentName = null;
	if(updating){
		var currentGroup = livechat.get(updating);
		currentName = currentGroup.name;
	}

	var create_actions = [(
		<Button onClick={par(doCreate, component)}>{localization.get("users_create")}</Button >
	), (
		<Button onClick={par(hideCreate, component)}>{localization.get("users_cancel")}</Button >
	)];

	var update_actions = [(
		<Button onClick={par(doUpdate, component)}>{localization.get("users_update")}</Button >
	), (
		<Button onClick={par(hideUpdate, component)}>{localization.get("users_cancel")}</Button >
	)];

	var remove_actions_member = [(
		<Button key={"user_ok"} onClick={par(confirmRemoveMember, component)}>{localization.get("users_ok")}</Button >
	), (
		<Button key={"users_cancel"} onClick={par(hideRemovingMember, component)}>{localization.get("users_cancel")}</Button >
	)];

	var remove_actions = [(
		<Button key={"user_ok"} onClick={par(confirmRemove, component)}>{localization.get("users_ok")}</Button >
	), (
		<Button key={"users_cancel"} onClick={par(hideRemovingLiveChat, component)}>{localization.get("users_cancel")}</Button >
	)];


	var renderMembersLazyList = component.state.viewing ? (
		<LazyList className="ae-scrollable" renderItem={par(renderMember, component)} items={shownMembers} />
	) : null;

	var renderAddNewMember = component.state.viewing ? (
		<Button variant="raised" onClick={par(startAdding, component)} color="secondary" >{localization.get("livechat_addmember")}</Button>
	) : null;

	var renderSearchMember = component.state.viewing ? (
		<TextField placeholder={localization.get("livechat_searchMember")} onChange={par(search, component, members, "Members")} style={styles.search} InputProps={styles.inputProp} />
	) : null;

	var renderDaysCheckboxes = function(change){
		return DAYS.map(function(day){
			return (
				<FormControlLabel
					control={<Checkbox defaultChecked={state[change + day]} onChange={par(updateActionChanged, component, change + day)}/>}
					label={localization.get("livechat_days_" + day)}
				/>
			);
		});
	};

	var renderCreateSchedule = component.state.newHasSchedule ? (
		<div className="flex-vertical flex-1">
			<FormGroup row>
				{renderDaysCheckboxes("new")}
			</FormGroup>
			{localization.get("livechat_schedule_starttime")}
			<TextField type="time" defaultValue={state.newStartTime} value={state.newStartTime} onChange={par(updateActionChanged, component, "newStartTime")} style={styles.timeInput} inputProps={timeInputProps} />
			{localization.get("livechat_schedule_endtime")}
			<TextField type="time" defaultValue={state.newEndTime} value={state.newEndTime} onChange={par(updateActionChanged, component, "newEndTime")} style={styles.timeInput} inputProps={timeInputProps} />
		</div>
	) : null;

	var renderUpdateSchedule = component.state.updateHasSchedule ? (
		<div className="flex-vertical flex-1">
			<FormGroup row>
				{renderDaysCheckboxes("update")}
			</FormGroup>
			{localization.get("livechat_schedule_starttime")}
			<TextField aria-label={localization.get("livechat_schedule_starttime")} role='textbox' InputProps={{"aria-label": localization.get("livechat_schedule_starttime"), "role": "textbox"}} type="time" defaultValue={state.updateStartTime} value={state.updateStartTime} onChange={par(updateActionChanged, component, "updateStartTime")} style={styles.timeInput} inputProps={timeInputProps} />
			{localization.get("livechat_schedule_endtime")}
			<TextField aria-label={localization.get("livechat_schedule_endtime")} role='textbox' InputProps={{"aria-label": localization.get("livechat_schedule_endtime"), "role": "textbox"}} type="time" defaultValue={state.updateEndTime} value={state.updateEndTime} onChange={par(updateActionChanged, component, "updateEndTime")} style={styles.timeInput} inputProps={timeInputProps} />
		</div>
	) : null;

	return (
		<div className="flex-vertical flex-1">
			<Header
				careplanChanges={careplanChanges}
				offline={offline}
				currentPerson={currentPerson}
				localization={localization}
				titleKey={titleKey}
			/>

			<div className="flex-horizontal flex-1 ae-scrollable">
				<div className="flex-vertical flex-1 ae-left-margin ">
					<SubHeader>{localization.get("livechat_subtitle")}</SubHeader>
					<TextField placeholder={localization.get("livechat_search")} onChange={par(search, component, allLiveChats, "LiveChats")} style={styles.search} InputProps={styles.inputProp} />
					<LazyList className=" ae-scrollable" renderItem={par(renderLiveChat, component)} items={shownLiveChats} />
					<Button variant="raised" onClick={par(startCreating, component)} color="secondary" >{localization.get("livechat_create")}</Button>
				</div>
				<div className="flex-vertical flex-1">
					<div className="flex-vertical flex-1 ae-scrollable">
						<SubHeader>{localization.get("livechat_members")}</SubHeader>
						{renderSearchMember}
						{renderMembersLazyList}
						{renderAddNewMember}
					</div>
				</div>
			</div>

			<Search selection={membersRaw} action={par(addMember, component)} ref="searchDialog" search={state.search} localization={localization} />

			<Dialog title={localization.get("livechat_create")} actions={create_actions} modal={true} open={!!creating}>
				<div className="flex-vertical">
					<TextField placeholder={localization.get("livechat_name")} value={state.newName} onChange={par(updateActionChanged, component, "newName")} label={localization.get("livechat_name")} style={styles.input}  InputProps={styles.inputProp} />
					<div className="flex-horizontal ae-createSubtitles">
						<div className="ae-padright">{localization.get("livechat_hasschedule")} : </div>
						<div className="ae-padright">{localization.get("search_exist_no")}</div>
						<Toggle inputProps={{ "aria-label": localization.get("switch_button")}} checked={state.newHasSchedule} onChange={par(updateToggleChanged, component, "newHasSchedule")} />
						<div className="ae-padleft">{localization.get("search_exist_yes")}</div>
					</div>
					{renderCreateSchedule}
			</div>
			</Dialog>

			<Dialog title={currentName} actions={update_actions} modal={true} open={!!updating}>
				<div className="flex-vertical">
					<TextField defaultValue={currentName} placeholder={localization.get("livechat_name")} value={state.updateName} onChange={par(updateActionChanged, component, "updateName")} label={localization.get("livechat_name")} style={styles.input}  InputProps={styles.inputProp} />
					<div className="flex-horizontal ae-createSubtitles">
						<div className="ae-padright">{localization.get("livechat_hasschedule")} : </div>
						<div className="ae-padright">{localization.get("search_exist_no")}</div>
						<Toggle inputProps={{ "aria-label": localization.get("switch_button")}} checked={state.updateHasSchedule} onChange={par(updateToggleChanged, component, "updateHasSchedule")} />
						<div className="ae-padleft">{localization.get("search_exist_yes")}</div>
					</div>
					{renderUpdateSchedule}
				</div>
			</Dialog>

			<Dialog actions={remove_actions_member} open={!!removingMember} title={localization.get("livechat_removeMember")}>
				{localization.get("livechat_warning") + " " + Utility.format_name(people.get(removingMember)) + "?"}
			</Dialog>

			<Dialog actions={remove_actions} open={!!removing} title={localization.get("livechat_remove")}>
				{localization.get("livechat_warning") + " " + (removing ? livechat.get(removing).name : "") + "?"}
			</Dialog>
		</div>
	);
}

function renderLiveChat(component, group){
	var text = group.name;
	var groupId = group._id;
	var background_colour = "ae-plain";
	var viewing = component.state.viewing;
	var localization = component.state.localization;
	if (groupId === viewing)
		background_colour = "ae-hover-color";

	return (
		<ListItem key={groupId} className={background_colour} onClick={par(viewGroup, component, groupId)}>
			<ListItemText primary={text} />
			<IconButton classes={iconButtonClasses} disableRipple aria-label={localization.get("edit_button")}  title={localization.get("edit_button")}  role="button"  color="primary" className="fa fa-pencil" onClick={par(startUpdate, component, groupId)} />
			<IconButton classes={iconButtonClasses} disableRipple aria-label={localization.get("remove_button")} title={localization.get("remove_button")} role="button" color="primary" className="fa fa fa-times-circle" onClick={par(startRemoveLiveChat, component, groupId)} />
		</ListItem>
	);
}

function viewGroup(component, group){
	component.setState({
		viewing: group
	});
	component.state.userMetrics.trackEvent("group-livechats: view livechat", {
		livechat: group,
	});
}

function startCreating(component){
	component.setState({
		creating: true
	});
	component.state.userMetrics.trackEvent("group-livechats: open create livechat popup");
}

function by_name(x, y) {
	return order(x.lname.toLowerCase() + x.fname.toLowerCase(), y.lname.toLowerCase() + y.fname.toLowerCase());
}

function order(x, y){
	if(x < y)
		return -1;
	if(x > y)
		return 1;
	return 0;
}

function hideCreate(component){
	component.state.userMetrics.trackEvent("group-livechats: close create livechat popup");
	component.setState({
		creating: false,
		newName: null,
		newDescription: null,
		newHasSchedule: null,
		newSunday: true,
		newMonday: true,
		newTuesday: true,
		newWednesday: true,
		newThursday: true,
		newFriday: true,
		newSaturday: true,
		newStartTime: "08:00",
		newEndTime: "17:00"
	});
}

function doCreate(component){
	var state = component.state;
	var name = state.newName;
	var description = state.newDescription;
	var hasSchedule = state.newHasSchedule;

	var daysSelected = DAYS.reduce(function(acc, day, index){
		if(state["new" + day]) return acc.concat(index);
		return acc;
	}, []);

	var daysOfWeekCron = daysSelected.join(",");

	var startTime = state.newStartTime;
	var endTime = state.newEndTime;

	var schedule = {
		startTime: createCron(startTime, daysOfWeekCron),
		endTime: createCron(endTime, daysOfWeekCron),
		timezone: moment.tz.guess()
	};

	var create = component.state.create;

	create(name, description, hasSchedule, schedule);
	hideCreate(component);
	component.state.userMetrics.trackEvent("group-livechats: create livechat", {
		name,
		schedule,
	});
	component.state.userMetrics.trackEvent("group-livechats: create livechat", {
		name,
		schedule,
	});
}

function createCron(time, days){
	var timeSplit = time.split(":");
	var hour = timeSplit[0];
	var minute = timeSplit[1];

	return [minute, hour, "*", "*", days].join(" ");
}

function doUpdate(component){
	var state = component.state;
	var name = state.updateName;
	var description = state.updateDescription;
	var group = state.updating;
	var update = state.update;
	var hasSchedule = state.updateHasSchedule;

	var daysSelected = DAYS.reduce(function(acc, day, index){
		if(state["update" + day]) return acc.concat(index);
		return acc;
	}, []);

	var daysOfWeekCron = daysSelected.join(",");

	var startTime = state.updateStartTime;
	var endTime = state.updateEndTime;

	var schedule = {
		startTime: createCron(startTime, daysOfWeekCron),
		endTime: createCron(endTime, daysOfWeekCron),
		timezone: moment.tz.guess()
	};

	update(name, description, hasSchedule, schedule, group);
	hideUpdate(component);
	component.state.userMetrics.trackEvent("group-livechats: edit livechat", {
		name,
		schedule,
	});
}

function updateActionChanged(component, name, event) {
	event.persist();
	var value = event.target.value;
	updateState(component, name, value);
}

function updateToggleChanged(component, name, event) {
	event.persist();
	var value = event.target.checked;
	updateState(component, name, value);
}

function updateState(component, name, value){
	var update = {};
	update[name] = value;
	component.setState(update);
}


function renderMember(component, member){
	var avatar = <Avatar alt="Member Avatar Image" src={config.image_cdn + member.image} />;
	var text = Utility.format_name(member);
	var memberId = member._id;
	var localization = component.state.localization;
	var background_colour = "ae-plain";

	return (
		<ListItem key={memberId} className={background_colour}>
			{avatar}
			<ListItemText primary={text} />
			<IconButton classes={iconButtonClasses} disableRipple aria-label={localization.get("remove")} title={localization.get("remove")} role="button" color="primary" className="fa fa-user-times" onClick={par(removeMember, component, memberId)} />
		</ListItem>
	);
}

function startAdding(component){
	component.refs.searchDialog.show();
	component.state.userMetrics.trackEvent("group-livechats: open add member popup", {
		livechat: component.state.viewing,
	});
}

function removeMember(component, member){
	component.state.userMetrics.trackEvent("group-livechats: open remove member popup", {
		user: member,
		livechat: component.state.viewing,
	});
	component.setState({
		removingMember: member
	});
}

function hideRemovingMember(component){
	component.state.userMetrics.trackEvent("group-livechats: close remove member popup");
	component.setState({
		removingMember: false
	});
}

function confirmRemoveMember(component){
	var remove = component.state.removeMember;
	var group = component.state.viewing;
	var member = component.state.removingMember;
	remove(group, member);
	hideRemovingMember(component);
	component.state.userMetrics.trackEvent("group-livechats: remove member", {
		user: member,
		livechat: group,
	});
}

function addMember(component, member){
	var group = component.state.viewing;
	var add = component.state.addMember;

	add(group, member);

	component.state.userMetrics.trackEvent("group-livechats: add member", {
		user: member,
		livechat: group,
	});
}

function hideUpdate(component){
	component.state.userMetrics.trackEvent("group-livechats: close edit livechat popup");
	component.setState({
		updateName: null,
		updateDescription: null,
		updateHasSchedule: null,
		updateSunday: true,
		updateMonday: true,
		updateTuesday: true,
		updateWednesday: true,
		updateThursday: true,
		updateFriday: true,
		updateSaturday: true,
		updateStartTime: "08:00",
		updateEndTime: "17:00",
		updating: false
	});
}

function hideRemovingLiveChat(component){
	component.setState({
		removing: false
	});
	component.state.userMetrics.trackEvent("group-livechats: close remove livechat popup");
}

function startUpdate(component, group){
	var state = component.state;
	var livechat = state.livechat;
	var currentGroup = livechat.get(group);

	var update = currentGroup.hasSchedule ? parseSchedule(currentGroup) : {};
	update["updating"] = group;
	update["updateName"] = currentGroup.name;
	update["updateDescription"] = currentGroup.description;
	update["updateHasSchedule"] = currentGroup.hasSchedule;

	component.setState(update);
	component.state.userMetrics.trackEvent("group-livechats: open edit livechat popup", {
		livechat: group,
	});
}

function startRemoveLiveChat(component, group){
	component.setState({
		removing: group
	});
	component.state.userMetrics.trackEvent("group-livechats: open remove livechat popup", {
		livechat: group,
	});
}

function confirmRemove(component){
	var group = component.state.removing;
	var remove = component.state.remove;
	remove(group);

	component.setState({
		removing: false,
		viewing: false
	});
	component.state.userMetrics.trackEvent("group-livechats: remove livechat", {
		livechat: group,
	});
}

function search(component, array, key, event) {
	event.persist();
	var query = event.target.value;
	var terms = escape_regex(query).split(" ").join("");
	var regex = new RegExp(terms, "i");

	var filtered = array.filter(function (item) {
		var matches = getName(item).split(" ").join("").match(regex);
		if (matches) return item;
	});

	var update = {};
	update["filtered" + key] = filtered;

	component.setState(update);
}

function getName(item){
	var name = item.name || item.fname + item.lname;
	return name;
}

function parseSchedule(group){
	var schedule = group.schedule;
	var startTime = schedule.startTime;
	var endTime = schedule.endTime;

	var startTimeConverted = convertCron(startTime);
	var endTimeConverted = convertCron(endTime);

	var update = {};
	update["updateStartTime"] = startTimeConverted.time;
	update["updateEndTime"] = endTimeConverted.time;

	var timeDays = startTimeConverted.days;
	DAYS.forEach(function(day, index){
		if(timeDays.indexOf("" + index) !== -1)
			update["update" + day] = true;
		else
			update["update" + day] = false;
	});

	return update;
}

function convertCron(cron){
	var split = cron.split(" ");
	var minutes = split[0];
	var hours = split[1];
	var days = split[4];

	return {
		time: hours + ":" + minutes,
		days: days.split(",")
	};
}
