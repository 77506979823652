
/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";

import par from "par";
import config from "../../configs/config.json";
import Header from "../shared/Header.js";
import Avatar from "@material-ui/1.5.1/Avatar";
import List from "@material-ui/1.5.1/List";
import ListItem from "@material-ui/1.5.1/ListItem";
import ListItemText from "@material-ui/1.5.1/ListItemText";
import Button from "@material-ui/1.5.1/Button";
import TextField from "@material-ui/1.5.1/TextField";
import Dialog from "../shared/Dialog";
import Paper from "@material-ui/1.5.1/Paper";
import Select from "@material-ui/1.5.1/Select/Select";
import MenuItem from "@material-ui/1.5.1/MenuItem/MenuItem";
import IconButton from "@material-ui/1.5.1/IconButton";
import SubHeader from "@material-ui/1.5.1/ListSubheader";
import Utility from "../shared/Utility.js";
import LazyList from "../shared/LazyList.jsx";
import  AetonixTheme_mui_1_5_1 from "../shared/AetonixTheme_mui_1.5.1";
const Colors = AetonixTheme_mui_1_5_1.palette;
var styles = {
	search: {
		color: Colors.primary.main,
		minWidth: "30%",
		padding: 8
	},
	input: {
		color: Colors.primary.main,
		padding: 8
	},
	inputProp: {
		style: {
			color: Colors.primary.main,
		}
	},
};

export default render;

function render() {
	var component = this;
	var state = component.state;
	var localization = state.localization;
	var currentPerson = state.currentPerson;
	var careplanNoticeList = state.careplanChangeList.all();
	var careplanChanges = !!careplanNoticeList.length;
	var offline = state.connection.get("offline");
	var titleKey = "user_bracelets_title";

	var actions = [(
		<Button key={"buttonok"}
			onClick={par(hideSaved, component)}>
			OK
		</Button >
		)];


	return (
		<div className="flex-vertical flex-1">

			<Header
				careplanChanges={careplanChanges}
				offline={offline}
				currentPerson={currentPerson}
				localization={localization}
				titleKey={titleKey}
			/>
			<div className="flex-horizontal flex-1 ae-left-margin">
				{renderUsers(component)}
				{renderSettings(component)}
			</div>
			<Dialog actions={actions} open={component.state.saved} title={localization.get("user_bracelets_saved")}>
				{localization.get("user_bracelets_saved_description")}
			</Dialog>
		</div>
	);
}

function renderSettings(component) {
	var viewing = component.state.viewing;
	if (!viewing) {
		return;
	}
	var preferences = component.state.braceletPreferences.get(viewing);
	var loading = preferences.loading;

	return (
		<div className="flex-vertical flex-1 ae-padded" key={viewing + loading}>
			<Paper zdepth={1} className="ae-padded">
				{renderMac(component)}
				{renderModel(component)}
				{renderRelay(component)}
				{renderDisconnect(component)}
				{renderFall(component)}
				{renderPress(component)}
				<div className="ae-contact-set">
					{renderContact(component)}
				</div>
			</Paper>
		</div>
	);
}

function renderDisconnect(component) {
	var viewing = component.state.viewing;
	var preferences = component.state.braceletPreferences.get(viewing);
	var localization = component.state.localization;

	return (

		<div className="ae-form-input">
			<label>{localization.get("user_bracelets_disconnectaction_label")}</label>
			<Select value={preferences.disconnect || "default"} inputProps={{"aria-label": localization.get("user_bracelets_disconnectaction_label")}} onChange={par(updateDisconnect, component)}>
				<MenuItem value="default">{localization.get("user_bracelets_disconnectaction_default")}</MenuItem>
				<MenuItem value="disconnected">{localization.get("user_bracelets_disconnectaction_disconnect")}</MenuItem>
				<MenuItem value="wandering">{localization.get("user_bracelets_disconnectaction_wander")}</MenuItem>
				<MenuItem value="none">{localization.get("user_bracelets_disconnectaction_none")}</MenuItem>
			</Select>
		</div>

	);
}

function renderRelay(component) {
	var viewing = component.state.viewing;
	var preferences = component.state.braceletPreferences.get(viewing);
	var localization = component.state.localization;
	var relay = !!preferences.relay;

	return (

		<div className="ae-form-input">
			<label>{localization.get("user_bracelets_relay")}</label>
			<Select value={relay} inputProps={{"aria-label": localization.get("user_bracelets_relay")}} onChange={par(updateRelay, component)}>
				<MenuItem value={true}>{localization.get("user_bracelets_relay_yes")}</MenuItem>
				<MenuItem value={false}>{localization.get("user_bracelets_relay_no")}</MenuItem>
			</Select>
		</div>

	);
}

function renderModel(component) {
	var viewing = component.state.viewing;
	var preferences = component.state.braceletPreferences.get(viewing);
	var localization = component.state.localization;
	var model = preferences.model;

	return (

		<div className="ae-form-input">
			<label>{localization.get("user_bracelets_model")}</label>
			<Select value={model} inputProps={{"aria-label": localization.get("user_bracelets_model")}} onChange={par(updateModel, component)}>
				<MenuItem value={"vlrt"}>{localization.get("user_bracelets_vlrt")}</MenuItem>
				<MenuItem value={"meta"}>{localization.get("user_bracelets_meta")}</MenuItem>
				<MenuItem value={"meta_manual"}>{localization.get("user_bracelets_meta_manual")}</MenuItem>
			</Select>
		</div>

	);
}

function renderFall(component) {
	var viewing = component.state.viewing;
	var preferences = component.state.braceletPreferences.get(viewing);
	var localization = component.state.localization;

	return (
		<div className="ae-form-input">
			<label>{localization.get("user_bracelets_fallaction")}</label>
			<Select value={preferences.fall || "default"} inputProps={{"aria-label": localization.get("user_bracelets_fallaction")}} onChange={par(updateFall, component)}>
				<MenuItem value="default">{localization.get("user_bracelets_default")}</MenuItem>
				<MenuItem value="urgency">{localization.get("user_bracelets_urgencycall")}</MenuItem>
				<MenuItem value="none">{localization.get("user_bracelets_none")}</MenuItem>
			</Select>
		</div>
	);
}

function renderPress(component) {
	var viewing = component.state.viewing;
	var preferences = component.state.braceletPreferences.get(viewing);
	var localization = component.state.localization;

	return (
		<div className="ae-form-input">
			<label>{localization.get("user_bracelets_pressaction")}</label>
			<Select value={preferences.press || "default"} inputProps={{"aria-label": localization.get("user_bracelets_pressaction")}} onChange={par(updatePress, component)}>
				<MenuItem value="default">{localization.get("user_bracelets_default")}</MenuItem>
				<MenuItem value="urgency">{localization.get("user_bracelets_urgencycall")}</MenuItem>
				<MenuItem value="contact">{localization.get("user_bracelets_callcontact")}</MenuItem>
				<MenuItem value="none">{localization.get("user_bracelets_none")}</MenuItem>
			</Select>
		</div>
	);
}

function renderMac(component) {
	var viewing = component.state.viewing;
	var preferences = component.state.braceletPreferences.get(viewing);
	var localization = component.state.localization;

	var mac = preferences.mac;

	return (
		<div className="flex-horizontal">
			<div className="flex-1" key={mac}>
				<TextField fullWidth defaultValue={mac} placeholder={localization.get("user_bracelets_mac")} inputProps={{"aria-label": localization.get("user_bracelets_mac")}} onChange={par(macField, component)} name="mac" style={styles.search} InputProps={styles.inputProp} />
			</div>
			<IconButton color="primary" aria-label={localization.get("common.save")}  title={localization.get("common.save")}  role="button"  className="fa fa-floppy-o" onClick={par(updateMac, component)} />
			<IconButton color="primary" aria-label={localization.get("common.delete")} title={localization.get("common.delete")}  role="button"  className="fa fa-trash-o" onClick={par(clearMac, component)} />
		</div>
	);
}

function macField(component, event) {
	component.setState({
		macField: event.target.value
	});
}
function renderContact(component) {
	var viewing = component.state.viewing;
	var preferences = component.state.braceletPreferences.get(viewing);

	var press = preferences.press;
	if (press !== "contact")
		return;

	var contact = preferences.contact;
	var contactElement;
	if (contact)
		contactElement = renderCurrentContact(component);

	var adding = component.state.addingContact;

	var additionElement;
	if (adding)
		additionElement = renderContactList(component);

	return [contactElement, renderAddContact(component), additionElement];
}

function renderCurrentContact(component) {
	var localization = component.state.localization;
	var people = component.state.people;
	var viewing = component.state.viewing;
	var preferences = component.state.braceletPreferences.get(viewing);
	var contact = preferences.contact;
	var person = people.get(contact);
	var avatar = <Avatar src={config.image_cdn + person.image} />;
	var text = Utility.format_name(person);

	if (person.fname === " ... ") {
		avatar = <Avatar>X</Avatar>;
		text = localization.get("user_bracelets_nocontact");
	}

	return (
		<div className="ae-contact-set">
			<ListItem >
				{avatar}
				<ListItemText primary={text} secondary={localization.get("user_bracelets_currentcontact")} />
			</ListItem>
		</div>
	);
}

function renderAddContact(component) {
	var localization = component.state.localization;
	if (component.state.addingContact)
		return (
			<div className="ae-center-text">
				<Button variant="raised" onClick={par(stopAdding, component)}>{localization.get("user_bracelets_cancel")}</Button>
			</div>
		);

	return (
		<div className="ae-center-text flex-horizontal">
			<Button variant="raised" onClick={par(startAdding, component)} >{localization.get("user_bracelets_setcontact")}</Button>
		</div>
	);
}

function renderContactList(component) {
	var viewing = component.state.viewing;
	var contacts = component.state.contacts.get(viewing);
	var localization = component.state.localization;

	return (
		<List>
			<SubHeader>{localization.get("user_bracelets_available")}</SubHeader>
			{contacts.map(par(renderContactListContact, component))}
		</List>
	);
}

function renderContactListContact(component, contact) {
	var people = component.state.people;
	var person = people.get(contact);
	var avatar;
	if (person.image) {
		avatar = <Avatar src={config.image_cdn + person.image} />;
	} else {
		avatar = <Avatar>None</Avatar>;
	}
	var text = Utility.format_name(person);
	var action = par(updateContact, component, contact);

	return (
		<ListItem key={contact} onClick={action}>
			{avatar}
			<ListItemText primary={text} />
		</ListItem>
	);
}

function startAdding(component) {
	component.setState({
		addingContact: true
	});
	component.state.userMetrics.trackEvent("user-bracelets: start add contact", {
		patient: component.state.viewing,
	});
}

function stopAdding(component) {
	component.setState({
		addingContact: false
	});
	component.state.userMetrics.trackEvent("user-bracelets: cancel add contact", {
		patient: component.state.viewing,
	});
}

function updateContact(component, contact) {
	var user = component.state.viewing;
	component.state.updateContact(user, contact).then(par(stopAdding, component)).then(par(showSaved, component));
	component.state.userMetrics.trackEvent("user-bracelets: edit contact", {
		patient: component.state.viewing,
	});
}

function updateFall(component, event) {
	event.persist();
	var value = event.target.value;
	var user = component.state.viewing;
	component.state.updateFall(user, value).then(par(showSaved, component));
	component.state.userMetrics.trackEvent("user-bracelets: edit fall action", {
		patient: component.state.viewing,
		value,
	});
}

function updateDisconnect(component, event) {
	event.persist();
	var value = event.target.value;
	var user = component.state.viewing;
	component.state.updateDisconnect(user, value).then(par(showSaved, component));
	component.state.userMetrics.trackEvent("user-bracelets: edit disconnect action", {
		patient: component.state.viewing,
		value,
	});
}

function updatePress(component, event) {
	event.persist();
	var value = event.target.value;
	var user = component.state.viewing;
	component.state.updatePress(user, value).then(par(showSaved, component));
	component.state.userMetrics.trackEvent("user-bracelets: edit press action", {
		patient: component.state.viewing,
		value,
	});
}

function updateRelay(component, event) {
	event.persist();
	var value = event.target.value;
	var user = component.state.viewing;
	component.state.updateRelay(user, value).then(par(showSaved, component));
	component.state.userMetrics.trackEvent("user-bracelets: edit relay action", {
		patient: component.state.viewing,
		value,
	});
}

function updateModel(component, event) {
	event.persist();
	var value = event.target.value;
	var user = component.state.viewing;
	component.state.updateModel(user, value).then(par(showSaved, component));
	component.state.userMetrics.trackEvent("user-bracelets: edit model", {
		patient: component.state.viewing,
		value,
	});
}

function updateMac(component) {
	var newMac = component.state.macField;
	var user = component.state.viewing;
	component.state.updateMac(user, newMac).then(par(showSaved, component));
	component.state.userMetrics.trackEvent("user-bracelets: edit mac", {
		patient: component.state.viewing,
	});
}

function clearMac(component) {
	var user = component.state.viewing;
	component.state.unassociateMac(user);
	component.state.userMetrics.trackEvent("user-bracelets: clear mac", {
		patient: component.state.viewing,
	});
}

function renderUsers(component) {
	var localization = component.state.localization;
	var state = component.state;

	var users = state.users;
	var people = state.people;
	var sortedUsers = users.map(people.get).sort(by_name);
	var loadMore = state.loadMore;
	var renderUserListItem = par(renderUser, component);
	var searchExist = state.searchExist;

	return (
		<div className="flex-vertical flex-1">
			<SubHeader>{localization.get("user_manage_users")}</SubHeader>
			<TextField placeholder={localization.get("search_onNameorpatientNum")} inputProps={{"aria-label": localization.get("search_onNameorpatientNum")}} onChange={searchExist} style={styles.search} InputProps={styles.inputProp} />
			<LazyList className="ae-scrollable" loadMore={loadMore} renderItem={renderUserListItem} items={sortedUsers} />
		</div>
	);
}


function by_name(x, y) {
	var X = x.lname.toLowerCase();
	var Y = y.lname.toLowerCase();
	if (X === Y) {
		X = x.fname.toLowerCase();
		Y = y.fname.toLowerCase();
	}
	return order(X, Y);
}

function order(X, Y) {
	if (X < Y)
		return -1;
	if (X > Y)
		return 1;
	return 0;
}


function renderUser(component, user, index) {
	if (!user?._id) return;
	var avatar = <Avatar alt="User Avatar Image" src={config.image_cdn + user.image} />;
	var text = Utility.format_name(user);
	var userIndex = index + 1;
	var userId = user?._id;
	var background_colour = "ae-plain";
	var viewing = component.state.viewing;
	if (userId === viewing) background_colour = "ae-hover-color";

	return (
		<ListItem key={userId} className={background_colour} onClick={par(setViewing, component, userId, userIndex)} >
			{avatar}
			<ListItemText primary={text} />
		</ListItem>
	);
}

function setViewing(component, user, userIndex) {
	component.setState({
		viewing: user,
		selectedIndex: userIndex
	});
	component.state.userMetrics.trackEvent("user-bracelets: view patient's bracelets", {
		patient: user
	});
}

function showSaved(component) {
	component.setState({
		saved: true
	});
}

function hideSaved(component) {
	component.setState({
		saved: false
	});
	component.state.userMetrics.trackEvent("user-bracelets: close successful save popup", {
		patient: component.state.viewing
	});
}
