import * as AetonixTheme from "../shared/AetonixTheme";
const Colors = AetonixTheme.default.palette;

export const styles = {
	avatarContainer: {
		display: "flex",
		flex: "1 1 20%",
		maxWidth: 120,
		maxHeight: 120
	},
	date: {
		justifyContent: "center",
		marginTop: "5px",
		alignItems: "center"
	},
	flex1: {
		display: "flex"
	},
	flex2: {
		display: "flex",
		flex: 2
	},
	flex4: {
		display: "flex",
		flex: 4
	},
	info: {
		flex: 2,
		flexDirection: "column",
		justifyContent: "center",
		padding: "0 1em"
	},
	latest: {
		display: "flex",
		flex: 4,
		padding: "0 1em",
		jusifyContent: "space-between",
		flexDirection: "column"
	},
	infoBoxContainer: {
		margin: "0px 10px"
	},
	infoBox: {
		minWidth: "130px"
	},
	infoBoxInfo: {
		border: "0.5px solid lightgrey",
		padding: "5px"
	},
	infoBoxValues: {
		border: "0.5px solid lightgrey",
		padding: "5px",
		justifyContent: "center",
		alignItems: "center",
		display: "flex",
		flexDirection: "column"
	},
	infoBoxValue: {
		margin: "5px 0",
		fontSize: "1.3em"
	},
	infoBoxDate: {
		fontSize: "0.8em"
	},
	text: {
		marginTop: "5px"
	},
	name: {
		fontSize: "1.2em",
	},
	emr: {
		marginTop: "10px"
	},
	navigation: {
		backgroundColor: "lightgrey",
		margin: "10px 0"
	},
	tableContainer: {
		alignItems: "center",
		maxHeight: "89.6vh",
		overflowY: "auto",
		overflowX: "hidden"
	},
	tableListContainer: {
		alignItems: "center",
		maxHeight: "89.6vh"
	},
	notesListContainer: {
		height: "89.6vh",
		alignItems: "center"
	},
	latestRow: {
		display: "flex",
		flexDirection: "row",
		gap: 12,
		flexWrap: "wrap",
		flex: 1,
		marginBottom: "10px"
	},
	RED: {
		backgroundColor: "#faa0a0"
	},
	YELLOW: {
		backgroundColor: "#ffe694"
	},
	GREEN: {
		backgroundColor: "#c5e09e"
	},
	inputProp: {
		style: {
			color: Colors.primary.main
		}
	},
	noteDate: {
		fontSize: "12px"
	},
	noteName: {
		fontWeight: "bold"
	},
	noteBody: {
		wordBreak: "break-all",
		padding: "5px 15px",
		fontSize: "14px"
	},
	noteTitle: {
		padding: "5px"
	},
	noteContainer: {
		backgroundColor: "white",
		padding: "10px 10px",
		maxHeight: "87vh",
		overflowY: "auto",
		minHeight: "34px"
	},
	selectAllButtons: {
		padding: "1px"
	},
	icon: {
		color: Colors.primary.main
	},
	chipIcon: {
		fontSize: 20,
		color: Colors.canvas
	},
	chipAvatar: {
		backgroundColor: Colors.primary.dark
	},
	chipBack: {
		backgroundColor: Colors.primary.light,
		color: Colors.primary.main
	},
	chipBackPrimary: {
		backgroundColor: "#E0E0E0"
	},
	chipAvatarPrimary: {
		backgroundColor: "#BCBCBC"
	},
	infoText: {
		fontSize: "20px",
		color: Colors.primary.main,
		fontWeight: 800,
		padding: "5px 0px"
	}
};
