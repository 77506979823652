/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */



/**
 * This store derives its data from an api call to /org/reminders.  It contains
 * data concerning the reminders history of members of a group.
 */

import Store from "./store";

import map from "fj-map";

import * as Sentry from "@sentry/react";

export default WorkflowActionHistoryStore;

function WorkflowActionHistoryStore(api) {
	var store = new Store();

	var loading = null;
	var page = 0;
	var actor = [];
	var owner = [];
	var start = "";
	var end = "";
	var workflow = [];
	var action = [];

	store.sorted = sorted;
	store.more = more;
	store.searchInitial = searchInitial;
	store.searchDate = searchDate;
	store.searchWorkflow = searchWorkflow;
	store.searchOwners = searchOwners;
	store.searchActor = searchActor;
	store.searchAction = searchAction;
	store.clearSearch = clearSearch;

	reset();

	return store;

	function reset() {
		page = 0;
		store.clear();
		return api.org.workflow.actions(owner, page, start, end, workflow, action, actor)
			.then(function (result) {
				if (result.length) {
					map(handle_create, result).bind(this);
				} else {
					store.clear();
				}
			})
			.catch(error => {
				Sentry.captureException(error);
				store.error(error);
			})
			.then(finish);
	}

	function more() {
		if (loading) return loading;
		loading = api.org.workflow.actions(owner, page, start, end, workflow, action, actor)
			.then(map(handle_create))
			.then(next_page)
			.catch(error => {
				Sentry.captureException(error);
				store.error(error);
			})
			.then(finish);
	}

	function finish() {
		loading = null;
	}

	function sorted() {
		return store.all().sort(by_time);
	}

	function handle_create(data) {
		if (!data) return;
		var id = data._id;
		store.set(id, data);
	}

	function next_page() {
		page += 1;
	}

	function searchOwners(filterowners) {
		owner = owner.concat(filterowners);
		return reset();
	}

	function searchInitial(flows, act) {
		action = act;
		return reset();
	}

	function searchWorkflow(flows) {
		workflow = flows;
		return reset();
	}

	function searchActor(actors) {
		actor = actors;
		return reset();
	}

	function searchAction(act) {
		action = act;
		return reset();
	}

	function searchDate(startdate, enddate) {
		start = startdate;
		end = enddate;
		if (!!start && !!end) {
			return reset();
		}
		return;
	}

	function clearSearch(name) {
		if (name === "owners") owner = [];
		if (name === "respond") {
			start = "";
			end = "";
		}
		if (name === "workflow") workflow = [];
		if (name === "action") action = [];
		if (name === "actors") actor = [];
		return reset();
	}
}

function by_time(prev, next) {
	var prev_time = (new Date(prev.updated_at)).getTime();
	var next_time = (new Date(next.updated_at)).getTime();

	var time_delta = next_time - prev_time;
	return time_delta;
}
