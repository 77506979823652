/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, May 3, 2018
 * For information or permission request, email info@aetonixsystems.com
 */

import par from "par";

import defineUI from "../shared/define";
import render from "./render.jsx";
import PersonStore from "../stores/person_store";
import OrgStore from "../stores/org_store";
import WorkflowStore from "../stores/admin_workflow_store";
import LocalizationStore from "../stores/localization_store";
import CurrentPersonInfoStore from "../stores/current_person_info_store";
import CareplanNotificationStore from "../stores/careplan_notice_store";
import ConnectionStore from "../stores/connection_store";
import FormsStore from "../stores/admin_forms_store";
import AdminTableStore from "../stores/admin_tables_store";
import makeStoreFactory from "../shared/stores";

const makeStores = makeStoreFactory({
	orgs: OrgStore,
	people: PersonStore,
	workflow: WorkflowStore,
	currentPerson: CurrentPersonInfoStore,
	localization: LocalizationStore,
	careplanChangeList: CareplanNotificationStore,
	connection: ConnectionStore,
	forms: FormsStore,
	tables: AdminTableStore
});

export default make_ui;

function make_ui(api, events, config, userMetrics) {
	var storeStore = makeStores(api, events, config);

	window.stores = storeStore.store;

	userMetrics.trackEvent("page view", {
		"page": "admin-tables",
		"category": "admin",
		"permission level": "admin",
	});

	return defineUI({
		getInitialState: getInitialState,
		componentDidMount: listen_for_changes,
		render: render,
		componentWillUnmount: removeListeners
	});

	function removeListeners() {
		events.off();
	}

	function listen_for_changes() {
		storeStore.listen(par(handle_change, this));
	}

	function handle_change(component, stores) {
		component.setState({
			orgs: stores.orgs.all(),
			people: stores.people,
			workflow: stores.workflow,
			currentPerson: stores.currentPerson,
			localization: stores.localization,
			careplanChangeList: stores.careplanChangeList,
			connection: stores.connection,
			forms: stores.forms,
			resources: stores.resources,
			tables: stores.tables
		});
	}

	function getInitialState() {
		return {
			orgs: [],
			loadMore: par(loadMore, storeStore.stores.orgs),
			people: storeStore.stores.people,
			workflow: storeStore.stores.workflow,
			currentPerson: storeStore.stores.currentPerson,
			localization: storeStore.stores.localization,
			searchOrg: par(searchOrg, storeStore.stores.orgs),
			careplanChangeList: storeStore.stores.careplanChangeList,
			connection: storeStore.stores.connection,
			tables: storeStore.stores.tables,
			create: createTable,
			update: updateTable,
			remove: removeTable,
			forms: [],
			updating: false,
			creating: false,
			schemaError: false,
			userMetrics: userMetrics,
		};
	}

	function searchOrg(orgs, event) {
		event.persist();
		var query = event.target.value;
		return orgs.search(query);
	}

	function loadMore(orgs) {
		return orgs.more();
	}

	function createTable(table, org){
		var actions = table.actions;
		var externals = table.externals;
		var schema = table.schema;

		if(!schema) return;

		return api.admin.tables.create(actions, externals, schema, org);
	}

	function updateTable(table, org){
		var actions = table.actions;
		var externals = table.externals;
		var schema = table.schema;
		var tableId = table._id;

		if(!schema || !table) return;

		return api.admin.tables.update(actions, externals, schema, tableId, org);
	}

	function removeTable(table, org){
		return api.admin.tables.remove(table, org);
	}

}
