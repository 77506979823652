export function getDefaultIndicator() {
	return {
		name: "",
		nameCnS: "",
		nameCnT: "",
		namePt: "",
		nameFr: "",
		nameEs: "",
		unit: ""
	};
}

export function hasNameError(nameFields) {
	return Object.values(nameFields).every(nameField => !nameField && !nameField.length);
}

export function addCustomIndicator(component, save) {

	// eslint-disable-next-line no-unused-vars
	const { editedIndicator: { unit, _id, ...nameFields } } = component.state;

	const isUnitError = !unit || !unit?.length;
	const isNameError = hasNameError(nameFields);

	if (isUnitError || isNameError) {
		return component.setState({ isUnitError, isNameError });
	}

	component.setState({
		isCreating: false,
		editedIndicator: getDefaultIndicator()
	});

	save(component.state.editedIndicator);
}

export function isIncludedInSearch(searchTerm, indicator, language) {
	const hasIndicator = indicator;
	const searchIsEmpty = !searchTerm;
	const localizedName = getNameForLanguage(indicator, language);

	const isNameContainedInTerm = localizedName?.toLowerCase?.()?.includes?.(searchTerm?.toLowerCase?.()) ?? false;
	const isUnitContainedInTerm = indicator?.unit?.toLowerCase?.()?.includes?.(searchTerm?.toLowerCase?.()) ?? false;

	return searchIsEmpty || (hasIndicator && (isNameContainedInTerm || isUnitContainedInTerm));
}

export function removeCustomIndicator(component, indicatorToRemove, deleteIndicator) {
	component.setState({
		indicatorToRemove: null,
		editedIndicator: getDefaultIndicator()
	});

	deleteIndicator(indicatorToRemove._id);
}

export function updateCustomIndicator(component, indicatorToUpdate, update) {

	const { editedIndicator: { unit, ...nameFields } } = component.state;

	const isUnitError = !unit || !unit?.length;
	const isNameError = hasNameError(nameFields);

	if (isUnitError || isNameError) {
		return component.setState({ isUnitError, isNameError });
	}

	update(indicatorToUpdate._id, component.state.editedIndicator);

	component.setState({
		indicatorToUpdate: null,
		editedIndicator: getDefaultIndicator()
	});
}

export function getNameForLanguage(indicator, language) {
	// eslint-disable-next-line no-unused-vars
	const { unit, _id, ...nameFields } = indicator ?? {};
	const firstAvailableName = Object.values(nameFields || {}).find(name => !!name && name?.length);
	const defaultName = indicator?.name || firstAvailableName || "";

	if(typeof indicator?.name === "string"){
		switch (language) {
			case "en":
				return indicator?.name || defaultName;
			case "pt":
				return indicator?.namePt || defaultName;
			case "es":
				return indicator?.nameEs || defaultName;
			case "fr":
				return indicator?.nameFr || defaultName;
			case "cn-s":
				return indicator?.nameCnS || defaultName;
			case "cn-t":
				return indicator?.nameCnT || defaultName;
			default:
				return defaultName;
		}
	} else if (typeof indicator?.name === "object") {
		return indicator?.name && indicator.name[language] || indicator.name[Object.keys(indicator.name)[0]] || "";
	} else {
		return defaultName;
	}

}
