/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */



import Store from "./store";
import map from "fj-map";
import compose from "fj-compose";
import pluck from "fj-pluck";
var getWho = pluck("who");
import Promise from "promise";

export default OrgManagerStore;

function OrgManagerStore(api, events) {
	var store = new Store();

	api.org.id().then(listen_for_changes);

	var query = "";
	var page = 0;
	var loading = null;
	var atEnd = false;

	store.more = more;
	store.search = search;

	reset();

	return store;

	function add_person(id) {
		store.set(id, id);
	}

	function remove_person(id) {
		store.remove(id);
	}

	function listen_for_changes(org) {
		events.on("mqtt:org/" + org + "/managers/added", compose(add_person, getWho));
		events.on("mqtt:org/" + org + "/managers/removed", compose(remove_person, getWho));
	}

	function reset() {
		page = 0;
		atEnd = false;
		store.clear();
		return api.org.managers
			.list(query, page)
			.then(map(add_person));
	}

	function more(){
		if (atEnd)
			return Promise.resolve();
		if (loading)
			return loading;
		page += 1;
		return api.org.managers.list(query, page).then(handle_managers);
	}

	function handle_managers(managers) {
		if (!managers.length) {
			atEnd = true;
			return;
		}

		managers.map(add_person);

		loading = null;
	}

	function search(newQuery) {
		query = newQuery;
		return reset();
	}

}
