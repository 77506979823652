/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

import par from "par";

import defineUI from "../shared/define";
import render from "./render.jsx";
import PersonStore from "../stores/person_store";
import UserStore from "../stores/user_all_store";
import LocalizationStore from "../stores/localization_store";
import CurrentPersonInfoStore from "../stores/current_person_info_store";
import ConnectivityStore from "../stores/connectivity_store";
import ConnectionStore from "../stores/connection_store";
import CareplanNotificationStore from "../stores/careplan_notice_store";
import makeStoreFactory from "../shared/stores";

const makeStores = makeStoreFactory({
	people: PersonStore,
	users: UserStore,
	currentPerson: CurrentPersonInfoStore,
	localization: LocalizationStore,

	connectivity: ConnectivityStore,

	connection: ConnectionStore,
	careplanChangeList: CareplanNotificationStore,
});

function make_ui(api, events, config, userMetrics) {
	var storeStore = makeStores(api, events, config);

	window.stores = storeStore.store;

	userMetrics.trackEvent("page view", {
		"page": "availability",
		"category": "monitor",
		"permission level": "staff",
	});

	return defineUI({
		getInitialState: getInitialState,
		componentDidMount: listen_for_changes,
		render: render,
		componentWillUnmount: removeListeners
	});

	function removeListeners() {
		events.off();
	}

	function listen_for_changes() {
		storeStore.listen(par(handle_change, this));
	}

	function handle_change(component, stores) {
		component.setState({
			people: stores.people,
			users: stores.users.all(),
			currentPerson: stores.currentPerson,
			localization: stores.localization,

			connectivity: stores.connectivity,

			connection: stores.connection,
			careplanChangeList: stores.careplanChangeList,
		});
	}

	function getInitialState() {
		return {
			people: storeStore.stores.people,
			users: [],
			currentPerson: storeStore.stores.currentPerson,
			tokens: storeStore.stores.tokens,
			localization: storeStore.stores.localization,

			connectivity: storeStore.stores.connectivity,

			connection: storeStore.stores.connection,
			careplanChangeList: storeStore.stores.careplanChangeList,

			filtering: "none",
			refreshAll: refreshAll,

			userMetrics: userMetrics,
		};
	}
}

function refreshAll(component, ids, filter) {
	return component.state.connectivity.filtering(ids, filter);
}

export default make_ui;
