import React from "react";
import Tooltip from "@material-ui/1.5.1/Tooltip";
import {  Typography } from "@material-ui/1.5.1";
import Grid from "@material-ui/core/Grid";

import { withStyles } from "@material-ui/core/styles";

import { useLocalize } from "@aetonix/hooks";

const GridCell = withStyles({
	root: {
		textAlign: "center",
		whiteSpace: "noWrap",
		"&:hover": {
			cursor: "pointer",
		}
	}
})(Grid);

const styles = {
	textNormal: {
		fontSize: "14px",
	},
	textSmall: {
		fontSize: "12px",
	}
};



export function HealthTableCell({value, onClick, strikethrough, status}){
	const { localize } = useLocalize();

	const parseStatus = () => {
		if (status === 2) {
			return localize("group_user_overview_high");
		}
		if (status === 1) {
			return localize("group_user_overview_low");
		}
		if (status === 0) {
			return localize("group_user_overview_normal");
		}
	};


	return (
		<Tooltip title={localize("health.noteinvalid")}>
			<GridCell onClick={onClick}>
				<Typography style={{ ...styles.textNormal, textDecoration: strikethrough ? "line-through" : "" }}>
					{value}
				</Typography>
				<Typography style={styles.textSmall}>{parseStatus()}</Typography>
			</GridCell>
		</Tooltip>
	);
}