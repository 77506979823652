import { SpinningIcon } from "@aetonix/web-ui";
import {
	Dialog,
	DialogContent,
	DialogTitle,
	Divider,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select
} from "@material-ui/core";
import { Sync } from "@material-ui/icons";
import * as Sentry from "@sentry/react";
import Form from "ae-forms";
import React, { useState, useRef } from "react";
import { useLocalize } from "../../../shared/contexts/LocalizeContext";
import { useLocalizeFrom } from "../../../shared/contexts/LocalizeFromContext";
import StyledIconButton from "ae-base/Base/StyledIconButton";
import AetonixTheme from "../../../shared/AetonixTheme";
const Colors = AetonixTheme.palette;

const styles = {
	centerContent: {
		display: "flex",
		justifyContent: "center",
		padding: "1rem"
	},
	formContainer: {
		maxHeight: "calc(100vh - 200px)",
		overflowY: "auto",
	  },
	scrollToTopButton: {
		position: "absolute",
		bottom: "1rem",
		right: "1rem",
		zIndex: 2,
		backgroundColor: Colors.primary.main,
		width: 60,
		height: 60,
		justifyContent: "center",
		color: "white",
		alignItems: "center",
		borderRadius: 30,
		borderWidth: 3,
		borderColor: "white"
	  },
	customTitle: {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-end",
		paddingRight: "1rem",
		marginTop: "-1rem",
	},
};


function CustomDialogTitle({ patient, emrId, localize }) {
	const name = patient?.fname + " " + patient?.lname;

	return (
		<div style={styles.customTitle}>
			<div> {localize("groupforms.patient")} : {name} </div>
			{  emrId ? (<div>{localize("groupforms.emrId")} : {emrId} </div>) : null  }
		</div>
	);
};


export function CreateFormDialog({
	forms,
	formsById,
	isOpen,
	onClose,
	submitForm,
	getFormPrepopulate,
	retrieveSubmissions,
	person
}) {
	const localize = useLocalize();
	const [selectedFormId, setSelectedFormId] = React.useState("");
	const [selectedForm, setSelectedForm] = React.useState(null);
	const [formSubmitting, setFormSubmitting] = useState(false);
	const [prepopulateData, setPrepopulate] = useState({});
	const [existingSubmissions, setExistingSubmissions] = useState({});
	const [isLoadingFormData, setIsLoadingFormData] = useState(false);
	const [showScrollToTop, setShowScrollToTop] = useState(false);
  	const formContainerRef = useRef(null);
	const localizeFrom = useLocalizeFrom();

	const handleScroll = () => {
		const scrollTop = formContainerRef?.current?.scrollTop;
		setShowScrollToTop(scrollTop > 100);
	  };

	  const scrollToTop = () => {
		formContainerRef?.current?.scrollTo({
		  top: 0,
		  behavior: "smooth",
		});
	  };

	function handleClose() {
		setSelectedForm(null);
		setSelectedFormId("");
		onClose();
	}

	async function handleFormChange(event) {
		setIsLoadingFormData(true);
		const newFormId = event.target.value;
		const { data: prepopulateRes } = await getFormPrepopulate(newFormId);

		const newForm = formsById[newFormId];
		if (newForm.formType === "single") {
			const { data: prevSubs } = await retrieveSubmissions(newFormId);
			if (prevSubs.length) {
				setExistingSubmissions({ ...existingSubmissions, [newFormId]: prevSubs[0] });
			}
		}
		setSelectedForm(newForm);
		setSelectedFormId(newFormId);
		setPrepopulate(prepopulateRes);
		setIsLoadingFormData(false);
	}

	async function handleSubmit(data) {
		setFormSubmitting(true);
		try {
			const submissionId = selectedForm.formType === "single" ? existingSubmissions[selectedFormId]?._id : undefined;
			const response = await submitForm({ formId: selectedForm._id, data, submissionId });
			if (response.status === "success") {
				handleClose();
				return;
			}
		} catch (error) {
			Sentry.captureException(error);
		}
		setFormSubmitting(false);
	}

	const formData = existingSubmissions[selectedFormId]?.data;
	const emrId = person?.patientNumber || "";

	return (
		<>
			<Dialog aria-labelledby="create-form-title" fullWidth maxWidth="md" onClose={handleClose} open={isOpen}>
				<DialogTitle id="create-form-title">{localize("groupforms.addnew")}</DialogTitle>
				<CustomDialogTitle emrId={emrId} patient={person} localize={localize} />
				<DialogContent>
					<div ref={formContainerRef} onScroll={handleScroll} style={styles.formContainer}>
						<FormControl fullWidth>
							<InputLabel id="create-form-label">{localize("groupforms.select_form")}</InputLabel>
							<Select
								labelId="create-form-label"
								id="create-form-select"
								value={selectedFormId}
								onChange={handleFormChange}
							>
								{forms.map(form => (
									<MenuItem key={form._id} value={form._id}>
										{localizeFrom(form.localization, ".") || ""}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<Divider />
						{selectedFormId &&
							(isLoadingFormData ? (
								<Grid item xs={12} style={styles.centerContent}>
									<SpinningIcon icon={Sync} spinning={true} />
								</Grid>
							) : (
								<Form
									schema={selectedForm?.schema}
									defaultData={formData || prepopulateData}
									onCancel={handleClose}
									cancelText={localize("groupforms.discardchanges")}
									submitText={localize("common.save")}
									onSubmit={handleSubmit}
									submitting={formSubmitting}
								/>
							))}
					</div>
					{showScrollToTop && (
						<StyledIconButton icon="arrow-up" contrast onPress={scrollToTop} style={styles.scrollToTopButton}></StyledIconButton>
					)}
				</DialogContent>
			</Dialog>
		</>
	);
}