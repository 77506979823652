import Store from "./store";
import map from "fj-map";
import compose from "fj-compose";
import pluck from "fj-pluck";
const getWho = pluck("who");

import * as Sentry from "@sentry/react";

export default UserAllStore;

function UserAllStore(api, events) {
	const store = new Store();

	api.org.id()
		.then(listen_for_changes)
		.catch(error => {
			Sentry.captureException(error);
			console.error.bind(console, error);
		});

	let page = 0;
	let query = "";
	let status = "";
	let loading = null;

	store.more = more;
	store.search = search;
	store.searchStatus = searchStatus;

	reset();

	return store;

	function reset() {
		page = 0;
		store.clear();
		return api.org.users
			.listAllUsers(query, null, status)
			.then(map(addPerson));
	}

	function handleUsers(users) {
		if (!users.length) return;

		users.map(addPerson);
		loading = null;
	}

	function more() {
		if (loading) return loading;

		page += 1;
		return api.org.users.list(query, page, status).then(handleUsers);
	}

	function search(newQuery) {
		query = newQuery;
		return reset();
	}

	function searchStatus(newStatus) {
		status = newStatus;
		return reset();
	}

	function addPerson(id) {
		store.set(id, id);
	}

	function removePerson(id) {
		store.remove(id);
	}

	function listen_for_changes(orgId) {
		events.on(`mqtt:org/${orgId}/users/added`, compose(addPerson, getWho));
		events.on(`mqtt:org/${orgId}/users/removed`, compose(removePerson, getWho));

		events.on(`mqtt:org/${orgId}/managers/added`, compose(addPerson, getWho));
		events.on(`mqtt:org/${orgId}/managers/removed`, compose(removePerson, getWho));
	}
}
