import { Dialog, DialogContent, List} from "@material-ui/core";
import React from "react";

const ListSelector = ({ open, onSelected = () => {}, onClose, onBackdropClick, list, renderItem = () => {} }) => {
	return (
		<Dialog open={open} onClose={onClose} onBackdropClick={onBackdropClick}>
			<DialogContent>
				<List>
					{list.map(item => {
						return renderItem(item, onSelected);
					})}
				</List>
			</DialogContent>
		</Dialog>
	);
};

export default ListSelector;
