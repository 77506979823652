
/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */



import Store from "./store";
import map from "fj-map";

export default StaffForms;

function StaffForms(api, events){
	var store = new Store();

	api.org.forms.listGroup()
		.then(map(add_forms));

	api.org.id()
		.then(listen_for_changes);

	return store;

	function add_forms(form){
		var id = form._id;
		store.set(id, form);
	}

	function remove_forms(form){
		var id = form._id;
		store.remove(id);
	}

	function listen_for_changes(org){
		events.on("mqtt:org/" + org + "/forms/definitions/created", add_forms);
		events.on("mqtt:org/" + org + "/forms/definitions/removed", remove_forms);
		events.on("mqtt:org/" + org + "/forms/definitions/updated", add_forms);
	}
}
