/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */


import Store from "./store";

export default GroupCustomProperties;

function GroupCustomProperties(api, events, config) {
	var store = new Store();

	reset();

	return store;

	function reset(){
		return api.org.group.customProperties()
			.then((properties) => {
				store.set("customProperties", properties || []);
				trackChanges();
			});
	}

	function trackChanges(){
		var orgGroup = config.orgGroup;
		if(!orgGroup) return;
		events.on(`mqtt:orggroup/${orgGroup}/custom/property/added`, handle_added);
		events.on(`mqtt:orggroup/${orgGroup}/custom/property/removed`, handle_remove);
		events.on(`mqtt:orggroup/${orgGroup}/custom/properties/updated`, handleUpdated);
	}

	function handle_added(property){
		var current = store.get("customProperties");
		var newList = current.concat(property);
		store.set("customProperties", newList);
	}

	function handle_remove(property){
		var current = store.get("customProperties");
		var newList = current.filter((e) => e.key !== property);
		store.set("customProperties", newList);
	}

	function handleUpdated(properties) {
		store.set("customProperties", properties || []);
	}
}
