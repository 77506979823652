/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */


import Store from "./store";

export default OrgGroupSettingsStore;

function OrgGroupSettingsStore(api, events, config) {
	var store = new Store();

	api.org.group.settings()
		.then(setSettings)
		.then(listenChanges);

	return store;

	function listenChanges(){
		events.on(`mqtt:orggroup/${config.orgGroup}/settings/updated`, setSettings);
	}

	function setSettings(settings){
		store.set("settings", settings);
	}
}
