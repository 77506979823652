/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

import Store from "./store";

import map from "fj-map";
import par from "par";

import * as Sentry from "@sentry/react";

export default ReconnectingMachineStore;

var stateOrder = ["reconnecting", "taking", "connected", "offline", "unknown"];

function ReconnectingMachineStore(api, events, config) {
	var store = new Store();

	api.admin.machine.list()
		.then(map(addMachine))
		.then(map(addListeners))
		.catch(function(e){
			Sentry.captureException(e);
			return;
		});

	store.sorted = sorted;

	return store;

	function sorted() {
		return store.all().sort(byState);
	}

	function addMachine(machine) {
		var serial = machine.serial;
		var who = machine.who;

		store.set(serial, {
			serial: serial,
			who: who,
			state: "unknown",
			found: [],
			take: null,
			taken: null,
		});
		return machine;
	}

	function addListeners(machine) {
		var serial = machine.serial;
		var who = machine.who;

		events.on("mqtt:machine/" + serial + "/state/reconnecting", par(handle_reconnecting, serial));
		events.on("mqtt:machine/" + serial + "/state/take", par(handle_take, serial));
		events.on("mqtt:machine/" + serial + "/found", par(handle_found, serial));
		events.on("mqtt:machine/" + serial + "/taken", par(handle_taken, serial));

		var orgGroup = config.orgGroup;
		if(orgGroup)
			events.on("mqtt:orggroup/" + orgGroup + "/u/" + who + "/status/available", par(handle_availability, serial));
	}

	function handle_reconnecting(serial, data) {
		var reconnecting = data.reconnecting;
		var updateData;
		if (reconnecting) {
			updateData = {
				state: "reconnecting",
				taken: null
			};
		} else {
			updateData = {
				found: [],
				take: null
			};
		}

		store.update(serial, updateData);
	}

	function handle_take(serial, data) {
		// Data will contain `machine` and `bracelet`
		store.update(serial, {
			state: "taking",
			take: data.machine
		});
	}

	function handle_taken(serial, data) {
		var taker = data.from;
		store.update(serial, {
			state: "connected",
			taken: taker,
			take: null
		});
	}

	function handle_found(serial, data) {
		var finder = data.from;

		var alreadyFound = store.get(serial).found;

		var hasFound = false;
		var newFinderData = {
			finder: finder,
			timestamp: new Date()
		};

		var newFound = alreadyFound.map(function (already) {
			if (already.finder !== finder) return already;
			hasFound = true;
			return newFinderData;
		});

		if (!hasFound)
			newFound = alreadyFound.concat(newFinderData);

		store.update(serial, {
			found: newFound
		});

	}

	function handle_availability(serial, data) {
		if (data.status) return;

		store.update(serial, {
			state: "offline",
			take: null,
			taken: null,
			found: []
		});
	}
}

function byState(m1, m2) {
	var order1 = stateOrder.indexOf(m1.state);
	var order2 = stateOrder.indexOf(m2.state);
	if (order2 === order1) return 0;
	if (order1 > order2) return 1;
	return -1;
}
