
/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import par from "par";

import defineUI from "../shared/define";
import render from "./render.jsx";
import ReconnectingMachineStore from "../stores/reconnecting_machine_store";
import PersonStore from "../stores/person_store";
import CurrentPersonInfoStore from "../stores/current_person_info_store";
import LocalizationStore from "../stores/localization_store";
import ConnectionStore from "../stores/connection_store";
import makeStoreFactory from "../shared/stores";

const makeStores = makeStoreFactory({
	people: PersonStore,
	localization: LocalizationStore,
	currentPerson: CurrentPersonInfoStore,
	machines: ReconnectingMachineStore,
	connection: ConnectionStore
});

function make_ui(api, events, config, userMetrics) {
	var storeStore = makeStores(api, events, config);

	window.stores = storeStore.store;

	userMetrics.trackEvent("page view", {
		"page": "admin-bracelets-live",
		"category": "monitor",
		"permission level": "admin",
	});

	return defineUI({
		getInitialState: getInitialState,
		componentDidMount: listen_for_changes,
		render: render,
		componentWillUnmount: removeListeners
	});

	function removeListeners() {
		events.off();
	}

	function listen_for_changes() {
		storeStore.listen(par(handle_change, this));
	}

	function handle_change(component, stores) {
		component.setState({
			people: stores.people,
			localization: stores.localization,
			currentPerson: stores.currentPerson,
			machines: stores.machines,
			connection: stores.connection,
			userMetrics: userMetrics,
		});
	}

	function getInitialState() {
		return storeStore.stores;
	}
}

export default make_ui;
