import React, { useEffect, useState } from "react";
import { PropTypes } from "prop-types";

import { Paper, Table } from "@material-ui/1.5.1";
import { TableContainer } from "@material-ui/core";

import { HeaderComponent } from "./HeaderComponent";
import { BodyComponent } from "./BodyComponent";
import { EditMenuComponent } from "./EditMenuComponent";
import { DndWrapper } from "./DnDWrapper";
import { Pagination } from "./Pagination/Pagination";

import { useUserMetrics } from "@aetonix/user-metrics";

import { getHeaderPropertyText } from "./helpers";

const DEFAULT_ROWS_PER_PAGE = 10;

const styles = {
	actionButtonsContainer: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "flex-end",
	},
	rightActionButtons: {
		display: "flex",
	},
	leftActionButtons: {
		display: "flex",
	},
};

function getColumnHiddenFromLocalStorage(propertyText, userLocalStorageKey) {
	const key = `${userLocalStorageKey}_hide_${propertyText}`;
	return localStorage.getItem(key) === "true";
}

function mapHeaderByPropertyText(headers) {
	const map = new Map();
	headers.forEach((header) => {
		const propertyText = getHeaderPropertyText(header);
		map.set(propertyText, header);
	});
	return map;
}

function getHiddenColumns(headers, userLocalStorageKey) {
	const hiddenHeaders = new Map();
	headers.forEach((header) => {
		const propertyText = getHeaderPropertyText(header);
		const isHidden = getColumnHiddenFromLocalStorage(propertyText, userLocalStorageKey);
		hiddenHeaders.set(propertyText, isHidden);
	});
	return hiddenHeaders;
}

function getHeaders(propsColumns, userLocalStorageKey) {
	const previousColumnOrder = localStorage.getItem(`${userLocalStorageKey}_columns`);
	if (!previousColumnOrder) return propsColumns;

	const parsedColumns = JSON.parse(previousColumnOrder);
	const parsedColumnsByPropertyText = mapHeaderByPropertyText(parsedColumns);

	// check for columns in propsColumns missing in previously save columns
	propsColumns.forEach((propColumn) => {
		const propertyText = getHeaderPropertyText(propColumn);
		if (!parsedColumnsByPropertyText.has(propertyText)) {
			parsedColumns.push(propColumn);
		}
	});
	return parsedColumns;
}

/**
 * @param {Object} props - required props object.
 * @param {Array} props.headerColumns - Array of header columns. Defaults to an empty array.
 * @param {Array} [props.headerGroups] - optional array of header groups, used to group headers
 * 	in the edit table menu.
 * @param {Object} props.localization - the localization object
 * @param {String} props.localizationPrefix - localizationPrefix prefix string
 * @param {String} props.tableName - The name to identify this table by
 * @param {Array | Map} props.records - The records to display
 * @param {Number} props.recordCount - Total number of records.
 * @param {Boolean} props.isFiltered - Boolean to indicate if the records to be displayed are
 * 	currently being filtered.
 * @param {Object} props.state - The state
 * @param {Object} [props.dataTransformer] - Optional data transformer.
 * @param {Function} [props.columnsFilterFn] - Optional function to filter columns from the table.
 * 	If provided, columns will only appear on the table if the function returns true for that column.
 * @param {Array} [props.rightActionButtons]  - Optional array of buttons to put on the right
 * 	side of the top of the table, right before "Edit Table" button.
 * @param {Array}[props.leftActionButtons]  - Optional array of buttons to put on the left
 * 	side of the top of the table.
 */
function TableComponent(props) {
	const {
		headerColumns = [],
		localization,
		localizationPrefix,
		tableName,
		records,
		recordCount = -1,
		state,
		dataTransformer = {},
		columnsFilterFn,
		headerGroups,
		rightActionButtons,
		leftActionButtons,
		isFiltered,
		measureUnits,
		userMetricsLabel = tableName,
		pageName = tableName,
		ignorePageLocalStorage,
		sortingFn,
		pageFn
	} = props;

	const { currentPerson } = state;
	const userLocalStorageKey = `${currentPerson.get("personal")._id}_${tableName}`;

	const rowsPerPageKey = `${userLocalStorageKey}_rowsPerPage`;
	const tablePage = `${userLocalStorageKey}_tablepage`;
	const storedRowsPerPage = localStorage.getItem(rowsPerPageKey)
		? Number(localStorage.getItem(rowsPerPageKey))
		: DEFAULT_ROWS_PER_PAGE;

	const storedPage = (localStorage.getItem(tablePage)) ? Number(localStorage.getItem(tablePage)) : null;

	const [page, setPage] = useState(storedPage || 0);
	const [headers, setHeaders] = useState(() => getHeaders(headerColumns, userLocalStorageKey));
	const [rowsPerPage, setRowsPerPage] = useState(storedRowsPerPage);
	const [headersLocked, setHeadersLocked] = useState(true);
	const [hiddenColumns, setHiddenColumns] = useState(() =>
		getHiddenColumns(headers, userLocalStorageKey));
	const { userMetrics } = useUserMetrics();

	const currentRecordsSize = records instanceof Map
		? records.size
		: records.length;

	const [previousRecordIds, setPreviousRecordIds] = useState([]);
	const displayedRecordIds = React.useMemo(() => {
		return getIdsOfDisplayedRecords(records, page, rowsPerPage);
	}, [records, page, rowsPerPage]);
	useEffect(() => {
		const hasChanged = displayedRecordIds.some(id => !previousRecordIds.includes(id));
		if (hasChanged) {
			displayedRecordIds.length && userMetrics.trackEvent(`${pageName}: View ${userMetricsLabel}`, {
				records: displayedRecordIds
			});
			setPreviousRecordIds(displayedRecordIds);
		}
	}, [displayedRecordIds, page, rowsPerPage]);

	useEffect(()=>{
		pageFn(rowsPerPage);
	}, [rowsPerPage]);

	// Update the headers when the headerColumns prop changes
	useEffect(() => {
		const updatedHeaders = getHeaders(headerColumns, userLocalStorageKey);
		setHeaders(updatedHeaders);
	}, [headerColumns]);

	// Update the hidden columns when userLocalStorageKey changes
	useEffect(() => {
		const updatedHiddenColumns = getHiddenColumns(headers, userLocalStorageKey);
		setHiddenColumns(updatedHiddenColumns);
	}, [userLocalStorageKey]);

	useEffect(() => {
		localStorage.setItem(tablePage, 0);
		setPage(0);
	}, [recordCount]);

	const updateHeadersOrder = newHeaders => {
		localStorage.setItem(`${userLocalStorageKey}_columns`, JSON.stringify(newHeaders));
		setHeaders(newHeaders);
	};

	const resetHeadersOrder = () => {
		localStorage.setItem(`${userLocalStorageKey}_columns`, JSON.stringify(headerColumns));
		setHeaders(headerColumns);
	};

	const updateHiddenColumns = (propertyText, isHidden) => {
		const key = `${userLocalStorageKey}_hide_${propertyText}`;
		localStorage.setItem(key, isHidden);
		const newHiddenColumns = new Map(hiddenColumns.set(propertyText, isHidden));
		setHiddenColumns(newHiddenColumns);
	};


	const personal = currentPerson.get("personal") || {};
	const {
		language = "en"
	} = personal;

	const displayPage = tableName === "group_users_overview" ? 0 : page;

	return (
		<div id={`${tableName}-table-container`}>
			<DndWrapper id={`${tableName}-table-container`}>
				<div style={styles.actionButtonsContainer}>
					<div style={styles.leftActionButtons}>
						{leftActionButtons}
					</div>
					<div style={styles.rightActionButtons}>
						{rightActionButtons}
						<EditMenuComponent
							headers={headers}
							language={language}
							localization={localization}
							localizationPrefix={localizationPrefix}
							hiddenColumns={hiddenColumns}
							updateHiddenColumns={updateHiddenColumns}
							headerGroups={headerGroups}
							headersLocked={headersLocked}
							setHeadersLocked={setHeadersLocked}
							resetHeadersOrder={resetHeadersOrder}
							pageName={pageName}
							userMetricsLabel={userMetricsLabel}
						/>
					</div>
				</div>
				<Paper>
					<Pagination
						ignorePageLocalStorage={ignorePageLocalStorage}
						isFiltered={isFiltered}
						recordCount={recordCount}
						records={records}
						state={state}
						tableName={tableName}
						localization={localization}
						page={page}
						setPage={setPage}
						rowsPerPage={rowsPerPage}
						setRowsPerPage={(numRows) => {
							userMetrics.trackEvent(`${pageName}: Change rows per page on ${userMetricsLabel}`, {
								rowsPerPage: numRows
							});
							return setRowsPerPage(numRows);
						}}
						currentRecordsSize={currentRecordsSize}
						storedPage={storedPage}
					/>
					<TableContainer tabIndex="0" >
						<Table>
							<HeaderComponent
								headers={headers}
								localization={localization}
								localizationPrefix={localizationPrefix}
								updateHeadersOrder={updateHeadersOrder}
								measureUnits={measureUnits}
								currentPerson={currentPerson}
								headersLocked={headersLocked}
								columnsFilterFn={columnsFilterFn}
								hiddenColumns={hiddenColumns}
								sortingFn={sortingFn}
								pageName={pageName}
							/>
							<BodyComponent
								records={records}
								numRows={currentRecordsSize}
								localization={localization}
								rowsPerPage={rowsPerPage}
								page={displayPage}
								measureUnits={measureUnits}
								headers={headers}
								dataTransformer={dataTransformer}
								columnsFilterFn={columnsFilterFn}
								hiddenColumns={hiddenColumns}
							/>
						</Table>
					</TableContainer>
					<Pagination
						ignorePageLocalStorage={ignorePageLocalStorage}
						isFiltered={isFiltered}
						recordCount={recordCount}
						records={records}
						state={state}
						tableName={tableName}
						localization={localization}
						page={page}
						setPage={setPage}
						rowsPerPage={rowsPerPage}
						setRowsPerPage={setRowsPerPage}
						currentRecordsSize={currentRecordsSize}
						storedPage={storedPage}
					/>
				</Paper>
			</DndWrapper>
		</div>
	);
};

// Terrible code below! Don't look!
function getIdsOfDisplayedRecords(records, page, rowsPerPage) {
	let ids = [];
	if (records instanceof Map) {
		const zerothKey = records.keys().next().value;
		if (!zerothKey) return [];
		if (zerothKey.length === 24) { // It's an ID so we're on the group-users-overview page
			ids = Array.from(records.keys()).slice(page * rowsPerPage, (page + 1) * rowsPerPage);
		} else if (zerothKey.includes("/") || zerothKey === "today") { // It's a date so we're on the user-overview page
			const displayedRecords = Array.from(records.values()).slice(page * rowsPerPage, (page + 1) * rowsPerPage);
			ids = displayedRecords.reduce((acc, record) => {
				const values = Object.values(record);
				acc = acc.concat(values.map(value => value._id));
				return acc;
			}, []);
		} else {
			throw new Error("Unknown record type");
		}
	} else {
		const displayedRecords = records.slice(page * rowsPerPage, (page + 1) * rowsPerPage);
		ids = displayedRecords.map(record => record?._id);
		ids.filter(id => id);
	}
	return ids;
}

Table.propTypes = {
	headerColumns: PropTypes.array,
	localization: PropTypes.object,
	localizationPrefix: PropTypes.string,
	tableName: PropTypes.string,
	records: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.object),
		PropTypes.instanceOf(Map)
	]),
	recordCount: PropTypes.number,
	isFiltered: PropTypes.bool,
	state: PropTypes.object,
	dataTransformer: PropTypes.object,
	columnsFilterFn: PropTypes.func,
	rightActionButtons: PropTypes.array,
	leftActionButtons: PropTypes.array,
};

export { TableComponent };
