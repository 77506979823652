/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";

import par from "par";
import config from "../../configs/config.json";
import isEmpty from "is-empty";
import Forms from "ae-forms/src/Outputs/OutputForm";
import HTMLOutput from "ae-forms/src/TextOutput/HTMLOutput";
import base64_js from "base64-js";
import utf8ToBytes from "../shared/utf8ToBytes";

/**
 * For react-router links
 */
import * as ReactRouterDom from "react-router-dom";

var Link = ReactRouterDom.Link;

/**
 * User Interface elements material-ui
 */
import Button from "@material-ui/1.5.1/Button";

import TextField from "@material-ui/1.5.1/TextField";
import MenuItem from "@material-ui/1.5.1/MenuItem";
import Chip from "@material-ui/1.5.1/Chip";
import Avatar from "@material-ui/1.5.1/Avatar";
import FontIcon from "@material-ui/1.5.1/Icon";
import Select from "@material-ui/1.5.1/Select";
import Table from "@material-ui/1.5.1/Table";
import TableHeader from "@material-ui/1.5.1/TableHead";
import TableHeaderColumn from "@material-ui/1.5.1/TableCell";
import TableBody from "@material-ui/1.5.1/TableBody";
import TableRow from "@material-ui/1.5.1/TableRow";
import TableRowColumn from "@material-ui/1.5.1/TableCell";
import InputLabel from "@material-ui/1.5.1/InputLabel";
import FormControl from "@material-ui/1.5.1/FormControl";

/**
 * User Interface elements Aetonix
 */

import Dialog from "../shared/Dialog";

import Header from "../shared/Header.js";
import Utility from "../shared/Utility.js";
import Scroll from "../shared/InfiniScroll.jsx";
import SearchTable from "../shared/SearchTable.jsx";
import DatePicker from "../shared/DatePicker";
import  AetonixTheme_mui_1_5_1 from "../shared/AetonixTheme_mui_1.5.1";
const Colors = AetonixTheme_mui_1_5_1.palette;

var styles = {
	icon: {
		fontSize: 20,
		color: Colors.canvas
	},
	avatar: {
		backgroundColor: Colors.primary.dark
	},
	back: {
		backgroundColor: Colors.primary.light,
		color: Colors.primary.main
	},
	backPrimary: {
		backgroundColor: "#E0E0E0"
	},
	avatarPrimary: {
		backgroundColor: "#BCBCBC"
	},
	modalSelects: {
		display: "flex",
		flexDirection: "column",
		marginBottom: "15px"
	},
	inputProp: {
		style: {
			color: Colors.primary.main,
		}
	},
	selectProp: {
		PaperProps: {
			style: {
				transform: "translate3d(0, 0, 0)"
			}
		}
	}
};

export default render;

function render() {

	//Boiler plate standard for every history page
	var component = this;
	var state = component.state;
	var localization = state.localization;
	var careplanNoticeList = state.careplanChangeList.all();
	var careplanChanges = !!careplanNoticeList.length;
	var offline = state.connection.get("offline");

	// States relevant to _this_ history page
	var people = state.people;
	var currentPerson = state.currentPerson;
	var definitions = state.definitions;
	var rowData = state.submissions.map(par(convertData, component, localization, people, definitions));
	var startDate = state.startDate;
	var endDate = state.endDate;
	var report = state.report;
	var fileName = state.fileName;
	var loadMore = state.more;
	var openingModal = state.openingModal;
	var openingDataView = state.openingDataView;

	// Search related variables
	var choose_date = localization.get("choose_date");
	var userFilter = state.userFilter;
	var dateFilter = state.dateFilter;
	var editorFilter = state.editorFilter;
	var filterUser = state.filterUser;
	var filterEditor = state.filterEditor;
	var closeSearch = state.closeSearch;
	var titleKey = "form_submissions_history";

	var headers = [
		{
			name: "forms",
			content: localization.get("form_submissions_history_form")
		}, {
			name: "user",
			content: localization.get("form_submissions_history_user")
		}, {
			name: "editor",
			content: localization.get("form_submissions_history_editor")
		}, {
			name: "time",
			content: localization.get("form_submissions_history_time")
		}, {
			name: "data",
			content: localization.get("form_submissions_history_data")
		}
	];

	var report_actions = [
		csvReport(component, localization),
		(<Button key={"buttoncancel"}
			onClick={par(hideReport, component)}>
			{localization.get("report_cancel")}
		</Button >)
	];

	var date_actions = [
		<Button key={"buttonapply"}
			onClick={par(handle_date, component, "time")}>
			{localization.get("search_apply")}
		</Button >,
		<Button key={"buttoncancel"}
			onClick={par(hideSearch, component, "time")}>
			{localization.get("form_submissions_history_cancel")}
		</Button >
	];

	var opening_modal_actions = [
		<Button key={"buttonapply"}
			onClick={par(handle_close_opening, component)}>
			{localization.get("form_submissions_history_apply")}
		</Button >,
		<Link key={"dashboard"} to={"/"} style={{
			textDecoration: "none"
		}}>
			<Button key={"buttonapply"}>
				{localization.get("form_submissions_history_cancel")}
			</Button >
		</Link>
	];

	var submissionDownload = "";
	var submissionName = "";

	try {
		submissionDownload = getSubmissionURL(component);
		submissionName = getSubmissionName(component);
	} catch(e) {
		// Whatever
	}

	var data_view_actions = [
		<Button
			key={"buttondownload"}
			onClick={() => component.state.userMetrics.trackEvent("org-forms-submission-history: download report", {
				url: submissionDownload,
				name: submissionName,
			})}
			href={submissionDownload}
			download={submissionName}
		>
			{localization.get("download")}
		</Button>,
		<Button key={"buttonclose"} onClick={par(handle_close_dataView, component)}>
			{localization.get("search_close")}
		</Button>
	];

	return (
		<div className="flex-vertical flex-1">

			<Header
				careplanChanges={careplanChanges}
				offline={offline}
				currentPerson={currentPerson}
				localization={localization}
				titleKey={titleKey}
			/>

			<div className="flex-horizontal ae-empty">
				{renderUserFilterChip(component)}
				{renderEditorilterChip(component)}
				{renderDateFilterChip(component)}
				{renderResetChip(component)}
			</div>

			<Scroll loadMore={loadMore}>
				<Table>
					{renderHeader(component, headers)}
					{renderBody(component, headers, rowData)}
				</Table>
			</Scroll>

			<Dialog actions={report_actions} open={report} title={localization.get("dashboards_formSubmissionsHistory_title")}>
				<div>
					<DatePicker invalid={choose_date}
						labelStart={localization.get("report_start")}
						labelEnd={localization.get("report_end")}
						startDate={startDate}
						endDate={endDate}
						updateStart={par(updateChanged, component, "startDate")}
						updateEnd={par(updateChanged, component, "endDate")}
					/>
					<TextField helperText={localization.get("report_filename")} errorText={localization.get("report_file_warning")} value={fileName} onChange={par(updateFileNameChanged, component)} InputProps={styles.inputProp} />
				</div>
			</Dialog>

			<SearchTable action={par(filterUser, component)} ref="searchDialog" search={state.search}
				localization={localization}
				people={people}
				showing={userFilter}
				onClose={par(closeSearch, component, "user")}
				title={localization.get("search_panel_title_patient")} />

			<SearchTable action={par(filterEditor, component)} ref="searchDialog" search={state.search}
				localization={localization}
				people={people}
				showing={editorFilter}
				onClose={par(closeSearch, component, "editor")}
				title={localization.get("search_editor_panel_title")} />

			<Dialog actions={date_actions} open={dateFilter} title={localization.get("dashboards_weightHistory_title")}>
				<DatePicker invalid={choose_date}
					labelStart={localization.get("report_start")}
					labelEnd={localization.get("report_end")}
					startDate={startDate}
					endDate={endDate}
					updateStart={par(updateChanged, component, "startDate")}
					updateEnd={par(updateChanged, component, "endDate")}
				/>
			</Dialog>

			<Dialog actions={opening_modal_actions} open={openingModal} title={localization.get("submissions_history_form_type_search")}>
				{renderOpeningModal(component)}
			</Dialog>

			<Dialog actions={data_view_actions}
				open={openingDataView}>
				{renderDataModal(component)}
			</Dialog>

		</div>
	);
}

function handle_close_dataView(component) {
	component.setState({
		openingDataView: false,
		dataToView: null
	});
	component.state.userMetrics.trackEvent("org-forms-submission-history: close form submission popup");
}

function renderDataModal(component) {
	var state = component.state;
	var data = state.dataToView;
	if (!data) return;
	var definitions = state.definitions;
	var chosen_form = state.chosen_form;
	if (!definitions) return;
	var form_types = state.definitions.all();
	var chosen_data = form_types.find(function (item) {
		return item._id === chosen_form;
	});
	var schema = chosen_data.schema;

	return (
		<Forms schema={schema} data={data} />
	);
}

function renderOpeningModal(component) {
	var state = component.state;
	var localization = state.localization;
	var definitions = state.definitions;
	var current_language = state.currentPerson.get("personal").language;
	var chosen_form = state.chosen_form;
	if (!definitions) return;
	var form_types = state.definitions.all();

	var choices = form_types.map(function (item, index) {
		var text = item.localization[current_language] || item.localization["en"];
		var value = item._id;
		return (
			<MenuItem key={index} value={value}>{text}</MenuItem>
		);
	});

	return (
		<FormControl fullWidth={true} style={styles.modalSelects}>
			<InputLabel>{localization.get("form_submissions_history_choose")}</InputLabel>
			<Select onChange={par(choose_definition, component)} inputProps={{"aria-label": localization.get("form_submissions_history_choose")}} value={chosen_form} MenuProps={styles.selectProp}>
				{choices}
			</Select>
		</FormControl>
	);
}

function choose_definition(component, event) {
	var value = event.target.value;
	return component.setState({
		chosen_form: value
	});
}

function handle_close_opening(component) {

	/**
	 * First find all the submitted forms that are using the chosen
	 * form definition (schema)
	 */
	var state = component.state;
	var form = state.chosen_form;
	if (!form) return;

	state.filterForms(component, form);

	component.state.userMetrics.trackEvent("org-forms-submission-history: select form", {
		form,
	});

	return component.setState({
		openingModal: false
	});
}

function renderResetChip(component) {
	var state = component.state;
	var localization = state.localization;
	return (
		<Chip className="ae-icon ae-fonticon"
			style={styles.back}
			onClick={par(showOpeningModal, component)}
			avatar={<Avatar style={styles.avatar}>
				<FontIcon className="fa fa-sliders fa-2x" style={styles.icon} />
			</Avatar>}
			label={localization.get("search_reset")}
		/>
	);
}

function showOpeningModal(component) {
	clearSearch(component, "user");
	clearSearch(component, "time");
	clearSearch(component, "editor");
	component.setState({
		openingModal: true,
		chosen_form: "",
		headers: [],
		forms: []
	});
	component.state.userMetrics.trackEvent("org-forms-submission-history: deselect form");
}

function renderDateFilterChip(component) {
	var state = component.state;
	var deleteOn = noop();
	var styleavatar = styles.avatarPrimary;
	var styleback = styles.backPrimary;
	if (!!state.startDate && !!state.endDate) {
		deleteOn = par(clearSearch, component, "time");
		styleavatar = styles.avatar;
		styleback = styles.back;
	}
	return (<Chip className="ae-icon ae-fonticon"
		onDelete={deleteOn}
		style={styleback}
		onClick={par(showSearch, component, "time")}
		avatar={<Avatar style={styleavatar}>
			<FontIcon className="fa fa-filter fa-2x" style={styles.icon} />
		</Avatar>}
		label={renderFilterLabel(component, "time")}
	/>);
}

function renderUserFilterChip(component) {
	var state = component.state;
	var deleteOn = noop();
	var styleavatar = styles.avatarPrimary;
	var styleback = styles.backPrimary;
	if (state.user.length) {
		deleteOn = par(clearSearch, component, "user");
		styleavatar = styles.avatar;
		styleback = styles.back;
	}
	return (
		<Chip className="ae-icon ae-fonticon"
			onDelete={deleteOn}
			style={styleback}
			onClick={par(showSearch, component, "user")}
			avatar={<Avatar style={styleavatar}>
				<FontIcon className="fa fa-filter fa-2x" style={styles.icon} />
			</Avatar>}
			label={renderFilterLabel(component, "user")}
		/>
	);
}

function renderEditorilterChip(component) {
	var state = component.state;
	var deleteOn = noop();
	var styleavatar = styles.avatarPrimary;
	var styleback = styles.backPrimary;
	if (state.editor.length) {
		deleteOn = par(clearSearch, component, "editor");
		styleavatar = styles.avatar;
		styleback = styles.back;
	}
	return (
		<Chip className="ae-icon ae-fonticon"
			onDelete={deleteOn}
			style={styleback}
			onClick={par(showSearch, component, "editor")}
			avatar={<Avatar style={styleavatar}>
				<FontIcon className="fa fa-filter fa-2x" style={styles.icon} />
			</Avatar>}
			label={renderFilterLabel(component, "editor")}
		/>
	);
}

function renderHeader(component, headers) {
	if (component.state.openingModal) return; // no table render if opening modal
	return (
		<TableHeader>
			<TableRow>
				{headers.map(renderHeaderColumn)}
			</TableRow>
		</TableHeader>
	);
}

function renderHeaderColumn(column, index) {
	return (
		<TableHeaderColumn key={index} className="flex-spread">
			{column.content}
		</TableHeaderColumn>
	);
}

function renderBody(component, headers, rows) {
	if (component.state.openingModal) return; // no table render if opening modal
	return (
		<TableBody>
			{rows.map(par(renderRow, headers))}
		</TableBody>
	);
}

function renderRow(headers, row, index) {
	return (
		<TableRow key={index}>
			{headers.map(par(renderRowColumn, row))}
		</TableRow>
	);
}

function renderRowColumn(row, column, index) {
	return (
		<TableRowColumn key={index}>
			{row[column.name]}
		</TableRowColumn>
	);
}

function convertData(component, localization, people, definitions, record) {
	if (!record || !definitions) return;
	var user = Utility.format_name(people.get(record.owner));
	var updated_at = Utility.timestamp(record.updated_at);
	var editor = Utility.format_name(people.get(record.edited[0]));
	var form = definitions.get(record.schema) ? definitions.get(record.schema).localization["en"] : "---";
	var data = renderData(component, record.data);

	return {
		"forms": form,
		"user": user,
		"editor": editor,
		"time": updated_at,
		"data": data
	};
}

function renderData(component, data) {
	var localization = component.state.localization;
	var showData = function () {
		component.setState({
			openingDataView: true,
			dataToView: data
		});
		component.state.userMetrics.trackEvent("org-forms-submission-history: open form submission popup", {
			data,
		});
	};

	return (
		<Chip className="ae-icon ae-fonticon"
			style={styles.backPrimary}
			onClick={showData}
			label={localization.get("submissions_view_data")}
		/>
	);
}

function csvReport(component, localization) {

	var comp = component.state;
	var start = comp.startDate;
	var end = adjust_date(comp.endDate);
	var filename = comp.fileName;

	if (!filename) filename = localization.get("default_file_form");

	var language = comp.currentPerson.get("personal").language;
	var timezone = new Date().getTimezoneOffset();

	var url = config.host +
		"/v2/org/observations/weight/history/report?token=" +
		config.token +
		"&start=" +
		encodeURIComponent(start) +
		"&end=" + encodeURIComponent(end) +
		"&filename=" +
		encodeURIComponent(filename) +
		"&language=" +
		encodeURIComponent(language) +
		"&timezone=" +
		encodeURIComponent(timezone);

	return (
		<a
			key={url}
			className="ae-report-save"
			href={url}
			download={filename}
			onClick={() => component.state.userMetrics.trackEvent("org-forms-submission-history: download submission data", {
				start,
				end,
				filename,
				language,
				timezone,
			})}
		>
			{localization.get("report_save")}
		</a>
	);
}

function hideReport(component) {
	component.state.userMetrics.trackEvent("org-forms-submission-history: close report popup");
	return (
		component.setState({
			report: false,
		})
	);
}

function updateFileNameChanged(component, event) {
	event.persist();
	var value = event.target.value;
	component.setState({
		fileName: value
	});
}

function updateChanged(component, name, event, date) {
	var value = date;
	if (event) {
		value = event._d;
	}
	var update = {};
	update[name] = value;
	component.setState(update);
}

/**
 * A function to reset a date by 1 day ahead.	Used to adjust the end date
 * on the date selector function because the default value is midnight of the
 * day selected instead of say, 23:59 of the day chosen or midnight of the next
 * day
 * @param	{String} date Date string
 * @return {String}			Date string
 */
function adjust_date(date) {
	if (isEmpty(date)) return "";
	var end = new Date(date);
	end.setDate(end.getDate() + 1);
	return end;
}

function showSearch(component, name) {
	if (name === "user") component.setState({
		userFilter: true
	});
	if (name === "editor") component.setState({
		editorFilter: true
	});
	if (name === "time") component.setState({
		dateFilter: true
	});
	const eventText = {
		"time": "org-forms-submission-history: open date filter popup",
		"editor": "org-forms-submission-history: open editor filter popup",
		"user": "org-forms-submission-history: open user filter popup",
	}[name] || "org-forms-submission-history: open filter popup";
	component.state.userMetrics.trackEvent(eventText);
}

function hideSearch(component, name) {
	if (name === "user") component.setState({
		userFilter: false
	});
	if (name === "editor") component.setState({
		editorFilter: false
	});
	if (name === "time") component.setState({
		dateFilter: false
	});
	const eventText = {
		"time": "org-forms-submission-history: close date filter popup",
		"editor": "org-forms-submission-history: close editor filter popup",
		"user": "org-forms-submission-history: close user filter popup",
	}[name] || "org-forms-submission-history: close filter popup";
	component.state.userMetrics.trackEvent(eventText);
}

function clearSearch(component, name) {
	const eventText = {
		"time": "org-forms-submission-history: clear date filter",
		"editor": "org-forms-submission-history: clear editor filter",
		"user": "org-forms-submission-history: clear user filter",
	}[name] || "org-forms-submission-history: clear filter popup";
	component.state.userMetrics.trackEvent(eventText);

	var clear = component.state.filterClear;
	if (name === "user") {
		component.setState({
			user: []
		});
		return clear("user");
	}
	if (name === "time") {
		component.setState({
			startDate: "",
			endDate: new Date()
		});
		return clear("time");
	}
	if (name === "editor") {
		component.setState({
			editor: []
		});
		return clear("editor");
	}
}

function renderFilterLabel(component, name) {
	var state = component.state;
	var localization = state.localization;
	var label;
	if (name === "time") {
		var start = state.startDate;
		var end = state.endDate;
		if (!start || !end) return (localization.get("search_time"));
		start = shortDate(start);
		end = shortDate(end);
		label = start + " - " + end;
		return label;
	}
	if (name === "user") {
		var lengthUser = state.user.length;
		if (!lengthUser) return (localization.get("search_patient"));
		label = localization.get("search_patient") + " (" + lengthUser + ")";
		return label;
	}
	if (name === "editor") {
		var lengthEditor = state.editor.length;
		if (!lengthEditor) return (localization.get("search_editor"));
		label = localization.get("search_editor") + " (" + lengthEditor + ")";
		return label;
	}
}

function shortDate(date) {
	var newdate = new Date(date);
	var day = newdate.getDate();
	var month = newdate.getMonth() + 1;
	var year = newdate.getFullYear();
	return (day + "/" + month + "/" + year);

}

function handle_date(component, value) {
	var state = component.state;
	hideSearch(component, value);
	var start = state.startDate;
	var end = adjust_date(state.endDate);
	component.state.userMetrics.trackEvent("org-forms-submission-history: filter by date", {
		start,
		end,
	});
	return state.filterDate(start, end);
}

function noop() { }

function getSubmissionURL(component) {
	var state = component.state;
	var data = state.dataToView;
	if (!data) return "javascript:void(0)";
	var definitions = state.definitions;
	var chosen_form = state.chosen_form;
	if (!definitions) return "javascript:void(0)";
	var form_types = state.definitions.all();
	var chosen_data = form_types.find(function (item) {
		return item._id === chosen_form;
	});
	var schema = chosen_data.schema;

	var htmlData = HTMLOutput({
		schema,
		data,
	}, component.getChildContext());

	try {
		var utf8 = utf8ToBytes(htmlData);
		var b64 = base64_js.fromByteArray(utf8);
		var url = "data:text/html;base64," + b64;
	}
	catch (err) { console.error(err); }

	return url;
}

function getSubmissionName() {
	return new Date() + ".html";
}
