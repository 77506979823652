/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";
import CreateReactClass from "create-react-class";

export default CreateReactClass({
	displayName: "InfiniScroll",
	componentDidMount: componentDidMount,
	render: render
});

function componentDidMount() {
	this.handleScroll = handleScroll.bind(this);
}

function render() {
	return (
		<div style={this.props.style} tabIndex="0" className="ae-LazyList" onScroll={this.handleScroll}>
			{this.props.children}
		</div>
	);
}

function handleScroll(e) {
	var isAtBottom = (e.target.scrollHeight - Math.ceil(e.target.scrollTop) === e.target.clientHeight);

	if (isAtBottom && this.props.loadMore) {
		this.props.loadMore();
	}
}
