/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */



/**
 * This store derives its data from an api call to /org/people creating a list
 * of users<->status.  It contains data concerning the current machine
 * connectivity of members of a group.
 */

import Store from "./store";
import later from "call-later";
import flatten from "array-flatten";

import * as Sentry from "@sentry/react";

export default ConnectivityStore;

function ConnectivityStore(api, events, config) {

	var store = new Store ();

	store.raw_get = store.get;
	store.get = get;

	store.track = later(track_all);
	store.tracking = is_tracking;

	store.fetch = later(fetch_all_data);
	store.filtering = filtering;

	var tracking = [];
	var filter = "none";

	return store;

	function get(id) {
		var data = store.raw_get(id);
		if (data !== undefined) return data;

		if (!store.tracking(id))
			store.track(id);

		return false;
	}

	function fetch_all_data(buffer) {
		var ids = flatten(buffer);
		api.people
			.statuses(ids, filter)
			.then(set_person)
			.catch(error => {
				Sentry.captureException(error);
				console.error.bind(console, "Couldn't fetch people's data");
			});
	}

	function set_person(person) {;
		filter = "none";
		store.setAll(person);
	}

	function track_all(buffer) {
		var ids = flatten(buffer);
		ids.map(track_person);
	}

	function track_person(id) {
		if (store.tracking(id))
			return;

		tracking.push(id);

		var orgGroup = config.orgGroup;
		events.on("mqtt:orggroup/" + orgGroup + "/u/" + id + "/status/available", fetch_person);

		fetch_person();

		function fetch_person() {
			store.fetch(id);
		}
	}

	function is_tracking(id) {
		return tracking.indexOf(id) !== -1;
	}

	function filtering(ids, filterType) {
		filter = filterType || "none";
		store.fetch(ids);
	}

}
