/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */



/**
 * This store is for the live monitoring of falls.
 */

import Store from "./store";

import map from "fj-map";
import par from "par";

import * as Sentry from "@sentry/react";

export default BatteryLiveStore;

function BatteryLiveStore(api, events) {

	var store = new Store();
	store._get = store.get;
	store.get = get;

	var tracking = {};

	begin_listening();

	return store;

	function get(id) {
		var existingData = this._get(id);

		if (existingData)
			return existingData;

		if(!tracking[id]) {
			tracking[id] = true;
			api.org.devices.data(id)
				.then(par(setPreferences, id));
		}

		return {
			level: -1
		};
	}

	function setPreferences(id, data) {
		var mac = data.mac;
		var machine = data.connected_to;
		var level = data.battery_level;
		store.set(id, {
			mac: mac,
			machine: machine,
			level: level
		});
		return {
			level: level
		};
	}

	function begin_listening() {

			//  Start listening on individual users for bracelet battery level updates
		api.org.devices.list()
			.then(map(listen_to_bracelet))
			.catch(error => {
				Sentry.captureException(error);
				console.error.bind(console, error);
			});
	}

	function listen_to_bracelet(data) {
		var id = data.owner;
		var mac = data.mac.replace(/:/g, "-"); //convert ':' to '-' for proper mqtt format

			//Payload of the following message is {machine: serial#, battery:level in %}
		events.on("mqtt:bracelet/" + mac + "/battery", par(handle_batteryLevel, id, mac));
	}

	function handle_batteryLevel(id, mac, bracelet) {
		var machine = bracelet.machine;
		var level = bracelet.battery;

		store.update(id, {
			mac: mac,
			machine: machine,
			level: level
		});
	}

}
