/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For intableation or permission request, email info@aetonixsystems.com
 */




import Store from "./store";
import par from "par";
import xtend from "xtend";

export default AdminTableStore;

function AdminTableStore(api, events) {
	var store = new Store();

	store.raw_get = store.get;
	store.get = get;

	var tracking = [];

	return store;

	function get(id) {
		var existing = store.raw_get(id);
		if (existing) return existing;

		if (!isTracking(id))
			track(id);

		return [];
	}

	function isTracking(id) {
		return tracking.indexOf(id) !== -1;
	}

	function track(id) {
		tracking.push(id);
		api.admin.tables.list(id).then(par(setTables, id));
		events.on("mqtt:org/" + id + "/table/removed", par(removeTable, id));
		events.on("mqtt:org/" + id + "/table/created", par(addTable, id));
		events.on("mqtt:org/" + id + "/table/updated", par(updateTable, id));
	}

	function removeTable(id, data) {
		var table = data.table;
		var existing = store.get(id);
		if (!existing) return;
		var filtered = existing.filter(function (existingTable) {
			return existingTable._id !== table;
		});

		store.set(id, filtered);
	}

	function addTable(id, data) {
		var table = data;
		var existing = store.get(id);
		if (!existing) return;

		var added = existing.concat(table);

		store.set(id, added);
	}

	function updateTable(id, data) {
		var table = data;
		var existing = store.get(id);
		if (!existing) return;

		var updated = existing.map(function (currentTable) {
			if (table._id === currentTable._id)
				return xtend(currentTable, table);
			return currentTable;
		});

		store.set(id, updated);
	}

	function setTables(id, tables) {
		store.set(id, tables);
	}

}
