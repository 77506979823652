/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */



import Store from "./store";
import pluck from "fj-pluck";

import * as Sentry from "@sentry/react";

export default CarePlanNoticeStore;

function CarePlanNoticeStore(api, events) {
	var store = new Store();

	var tracking = [];
	showList();
	api.org.group.current.data()
		.then(pluck("_id"))
		.then(listen_for_changes)
		.catch(error => {
			Sentry.captureException(error);
			store.error(error);
		});

	return store;

	function showList() {
		api.org.users.careplan.changes.notification()
			.then(addTo)
			.catch(error => {
				Sentry.captureException(error);
				console.error.bind(console, error);
			});
	}

	function addTo(data) {
		if (!data) return;
		tracking = data;
		store.setAll(tracking);
	}

	function addNew(data) {
		if (!data) return;
		var userId = data.who;
		if(tracking.indexOf(userId) !== -1) return;
		var changes = tracking.concat(userId);
		tracking = changes;
		store.setAll(tracking);
	}

	function removeFrom(data) {
		if (!data) return;
		store.clear();  // clear the store first
		var userId = data.who;
		var changes = tracking.filter(function(user) { //filter the user
			return user !== userId;
		});
		tracking = changes;
		store.setAll(tracking); // populate the store with the filtered list
	}

	function listen_for_changes(groupId) {
		events.on("mqtt:orggroup/" + groupId + "/careplan/changes/confirmed", addNew);
		events.on("mqtt:orggroup/" + groupId + "/careplan/changes/completed", removeFrom);
	}
}
