/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, May 08, 2018
 * For information or permission request, email info@aetonixsystems.com
 */




import Store from "./store";
import par from "par";
import xtend from "xtend";

export default AdminOptionsSchemaStore;

function AdminOptionsSchemaStore(api, events) {
	var store = new Store();

	store.raw_get = store.get;
	store.get = get;

	var tracking = [];

	return store;

	function get(id) {
		var existing = store.raw_get(id);
		if (existing) return existing;

		if (!isTracking(id))
			track(id);

		return [];
	}

	function isTracking(id) {
		return tracking.indexOf(id) !== -1;
	}

	function track(id) {
		tracking.push(id);
		api.admin.options.schema.list(null, id).then(par(setOptions, id));
		events.on("mqtt:org/" + id + "/options/schema/removed", par(removeOptions, id));
		events.on("mqtt:org/" + id + "/options/schema/created", par(addOptions, id));
		events.on("mqtt:org/" + id + "/options/schema/updated", par(updateOptions, id));
	}

	function removeOptions(id, data) {
		var existing = store.get(id);
		if (!existing) return;

		var filtered = existing.filter(item => {
			return item._id !== data._id;
		});

		store.set(id, filtered);
	}

	function addOptions(id, data) {
		var existing = store.get(id);
		if (!existing) return;

		var added = existing.concat(data);

		store.set(id, added);
	}

	function updateOptions(id, data) {
		var existing = store.get(id);
		if (!existing) return;

		var updated = existing.map(current => {
			if(data._id === current._id)
				return xtend(current, data);
			return current;
		});

		store.set(id, updated);
	}

	function setOptions(id, schemas) {
		store.set(id, schemas);
	}

}
