/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

import par from "par";

import debounce from "debounce";
import defineUI from "../shared/define";
import render from "./render.jsx";
import PersonStore from "../stores/person_store";
import UserStore from "../stores/user_store";
import UserManagerStore from "../stores/user_manager_store";
import LocalizationStore from "../stores/localization_store";
import CurrentPersonInfoStore from "../stores/current_person_info_store";
import PermissionsStore from "../stores/permissions_store";
import CarePlanChangeStore from "../stores/careplan_change_store";
import CarePlanStore from "../stores/careplan_store";
import CareplanNotificationStore from "../stores/careplan_notice_store";
import ConnectionStore from "../stores/connection_store";
import makeStoreFactory from "../shared/stores";

const makeStores = makeStoreFactory({
	people: PersonStore,
	currentPerson: CurrentPersonInfoStore,
	localization: LocalizationStore,
	users: UserStore,
	managers: UserManagerStore,
	permissions: PermissionsStore,
	changes: CarePlanChangeStore,
	careplan: CarePlanStore,
	careplanChangeList: CareplanNotificationStore,
	connection: ConnectionStore
});

function make_ui(api, events, config, userMetrics) {
	var storeStore = makeStores(api, events, config);

	window.stores = storeStore.store;

	userMetrics.trackEvent("page view", {
		"page": "group-careplans",
		"category": "group",
		"permission level": "staff",
	});

	return defineUI({
		getInitialState: getInitialState,
		componentDidMount: listen_for_changes,
		render: render,
		componentWillUnmount: removeListeners
	});

	function removeListeners() {
		events.off();
	}

	function listen_for_changes() {
		storeStore.listen(par(handle_change, this));
	}

	function handle_change(component, stores) {
		component.setState({
			people: stores.people,
			users: stores.users.all(),
			currentPerson: stores.currentPerson,
			localization: stores.localization,
			changes: stores.changes,
			careplan: stores.careplan,
			careplanChangeList: stores.careplanChangeList,
			connection: stores.connection
		});
	}

	function getInitialState() {
		return {
			people: storeStore.stores.people,
			currentPerson: storeStore.stores.currentPerson,
			loadMore: par(loadMore, storeStore.stores.users),
			localization: storeStore.stores.localization,
			changes: storeStore.stores.changes,
			careplan: storeStore.stores.careplan,
			viewing: null,
			toRemove: null,
			users: [],
			committed_sections: [],
			search: doSearch,
			sectionCommitted: sectionCommitted,
			searchExist: debounce(par(searchExist, storeStore.stores.users), 200),
			showCareplanPending: true,
			careplanChangeList: storeStore.stores.careplanChangeList,
			connection: storeStore.stores.connection,
		};
	}

	function loadMore(user){
		return user.more();
	}

	function searchExist(users, query){
		return users.search(query);
	}

	function doSearch(query) {
		return api.people.search(query);
	}

	function sectionCommitted(user, section, change) {
		if (!user) return;
		return api.org.users.careplan.changes.committed(user, section, change);
	}
}

export default make_ui;
