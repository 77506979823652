import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const CrossedTrashIcon = (props) => (
  <SvgIcon {...props}>
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 198.74 159.85">
      <path style={{strokeWidth: "0px"}} d="M48.39,29.8c7,6,12.36,10.72,21.58,10.49,27.97-.72,55.98-.26,83.97-.26h5.71c-.75,12.48-1.44,24.41-2.2,36.34-.73,11.47-1.66,22.93-2.16,34.4-.07,1.71,1.31,4.03,2.74,5.18,12.07,9.7,24.33,19.18,36.48,28.79,1.54,1.22,3.72,2.65,3.98,4.25.43,2.66.44,6.23-1.04,8.09-1.3,1.64-4.93,2.09-7.45,1.91-1.89-.14-3.77-1.96-5.45-3.28C124.88,108.98,65.24,62.21,5.59,15.45c-1.05-.82-2.14-1.62-3.05-2.57C-.38,9.82-.95,6.35,1.72,2.98,4.38-.39,7.98-.87,11.51,1.5c3.45,2.31,6.61,5.05,9.89,7.62,2.88,2.25,5.73,4.53,9.15,7.23,3.12-6.32,8.72-6.48,14.55-6.35,6.99.15,14-.07,20.99.09,2.62.06,4.36-.48,5.47-3.12C73.68,1.92,77.76-.05,83.15,0c11.16.1,22.33.08,33.49,0,5.13-.03,9.24,1.68,11.29,6.58,1.27,3.03,3.3,3.56,6.27,3.5,8.33-.17,16.67-.24,24.99.01,7.95.24,12.45,6.79,9.67,13.63-1.82,4.47-5.55,5.98-10.01,6.04-6.16.09-12.33.03-18.49.03-28.49,0-56.98,0-85.47,0-1.74,0-3.49,0-6.48,0Z"/>
      <path style={{strokeWidth: "0px"}} d="M41.65,62.79c37.54,29.73,73.93,58.53,110.3,87.32-2.81,7.07-8.24,9.59-14.79,9.62-25.16.15-50.32.14-75.48,0-8.38-.04-14.24-5.32-14.88-13.82-1.51-20.26-2.61-40.56-3.85-60.84-.43-6.97-.81-13.94-1.29-22.3Z"/>
    </svg>
  </SvgIcon>
);

export default CrossedTrashIcon;