/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import par from "par";

import defineUI from "../shared/define";
import render from "./render.jsx";
import PersonStore from "../stores/person_store";
import LocalizationStore from "../stores/localization_store";
import BloodOxygenHistoryStore from "../stores/health_bloodoxygen_store";
import CareplanNotificationStore from "../stores/careplan_notice_store";
import ConnectionStore from "../stores/connection_store";
import CurrentPersonInfoStore from "../stores/current_person_info_store";
import makeStoreFactory from "../shared/stores";

const makeStores = makeStoreFactory({
	people: PersonStore,
	localization: LocalizationStore,
	bloodoxygenhistoryStore: BloodOxygenHistoryStore,
	currentPerson: CurrentPersonInfoStore,
	careplanChangeList: CareplanNotificationStore,
	connection: ConnectionStore
});

function make_ui(api, events, config, userMetrics) {

	var storeStore = makeStores(api, events, config);

	window.stores = storeStore.stores;

	userMetrics.trackEvent("page view", {
		"page": "user-bloodoxygen-history",
		"category": "myhealth",
		"permission level": "staff",
	});

	return defineUI({
		getInitialState: getInitialState,
		componentDidMount: listen_for_changes,
		render: render,
		componentWillUnmount: removeListeners
	});

	function removeListeners() {
		events.off();
	}

	function listen_for_changes() {
		storeStore.listen(par(handle_change, this));
	}

	function handle_change(component, stores) {
		component.setState({
			people: stores.people,
			localization: stores.localization,
			currentPerson: stores.currentPerson,
			bloodoxygen: stores.bloodoxygenhistoryStore.sorted(),
			careplanChangeList: stores.careplanChangeList,
			connection: stores.connection
		});
	}

	function getInitialState() {
		storeStore.stores.bloodoxygenhistoryStore.reset();
		return {
			people: storeStore.stores.people,
			localization: storeStore.stores.localization,
			currentPerson: storeStore.stores.currentPerson,
			bloodoxygen: storeStore.stores.bloodoxygenhistoryStore.sorted(),
			more: more,
			filterUser: filterUser,
			filterDate: filterDate,
			filterClear: filterClear,
			closeSearch: par(closeSearch, this),
			search: searchName,
			report: false,
			userFilter: false,
			dateFilter: false,
			query: [],
			startDate: "",
			endDate: new Date(),
			fileName: "",
			careplanChangeList: storeStore.stores.careplanChangeList,
			connection: storeStore.stores.connection,
			userMetrics: userMetrics,
		};
	}

	function more() {
		storeStore.stores.bloodoxygenhistoryStore.more();
	}

	/**
	 * Returns a list of the groups users
	 * @param  {objectId} query User_id
	 * @return {array}       User _id's
	 */
	function searchName(query) {
		return api.org.users.list(query);
	}

	function filterUser(component, data) {
		var query = component.state.query;
		component.setState({
			query: query.concat(data)
		});
		storeStore.stores.bloodoxygenhistoryStore.searchQuery(data);
	}

	function filterDate(start, end) {
		storeStore.stores.bloodoxygenhistoryStore.searchDate(start, end);
	}

	function filterClear(name) {
		storeStore.stores.bloodoxygenhistoryStore.clearSearch(name);
	}

	function closeSearch(component) {
		component.setState({
			userFilter: false
		});
		component.state.userMetrics.trackEvent("user-bloodoxygen-history: close user filter");
	}
}

export default make_ui;