/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import Store from "./store";

import par from "par";

export default AdminCustomProperties;

function AdminCustomProperties(api, events) {
	var store = new Store();

	var tracking = [];

	store.raw_get = store.get;
	store.get = get;
	return store;

	function get(id) {
		var existing = store.raw_get(id);
		if (existing) return existing;
		if (!isTracking(id)) track(id);
		return [];
	}

	function track(id) {
		tracking.push(id);
		api.admin.orgGroup.customProperties(id)
			.then((data) => {
				set_group(id, data);
				events.on(`mqtt:orggroup/${id}/custom/properties/updated`, par(set_group, id));
			});
	}

	function set_group(id, data) {
		store.set(id, data);
	}

	function isTracking(id) {
		return tracking.indexOf(id) !== -1;
	}
}
