/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

import par from "par";

import prettyMs from "pretty-ms";
import React from "react";
import isEmpty from "is-empty";
import Table from "@material-ui/1.5.1/Table";
import TableHeader from "@material-ui/1.5.1/TableHead";
import TableHeaderColumn from "@material-ui/1.5.1/TableCell";
import TableBody from "@material-ui/1.5.1/TableBody";
import TableRow from "@material-ui/1.5.1/TableRow";
import TableRowColumn from "@material-ui/1.5.1/TableCell";
import Header from "../shared/Header.js";
import List from "@material-ui/1.5.1/List";
import ListItem from "@material-ui/1.5.1/ListItem";
import ListItemText from "@material-ui/1.5.1/ListItemText";
import Button from "@material-ui/1.5.1/Button";
import Dialog from "../shared/Dialog";
import Chip from "@material-ui/1.5.1/Chip";
import Avatar from "@material-ui/1.5.1/Avatar";
import FontIcon from "@material-ui/1.5.1/Icon";
import Utility from "../shared/Utility.js";
import Scroll from "../shared/InfiniScroll.jsx";
import SearchTable from "../shared/SearchTable.jsx";
import DatePicker from "../shared/DatePicker";
import  AetonixTheme_mui_1_5_1 from "../shared/AetonixTheme_mui_1.5.1";
const Colors = AetonixTheme_mui_1_5_1.palette;

var styles = {
	date: {
		display: "flex",
		flexDirection: "column",
		color: Colors.primary.main
	},
	dateEntry: {
		marginBottom: "30px"
	},
	icon: {
		fontSize: 20,
		color: Colors.canvas
	},
	avatar: {
		backgroundColor: Colors.primary.dark
	},
	back: {
		backgroundColor: Colors.primary.light,
		color: Colors.primary.main
	},
	backPrimary: {
		backgroundColor: "#E0E0E0"
	},
	avatarPrimary: {
		backgroundColor: "#BCBCBC"
	}
};

export default render;

function render() {
	var component = this;
	var state = component.state;
	var localization = state.localization;
	var people = state.people;
	var rowData = state.calls.map(par(convertData, localization, people));
	var currentPerson = state.currentPerson;
	var startDate = state.startDate;
	var endDate = state.endDate;
	var loadMore = state.more;
	// Search related variables
	var choose_date = localization.get("choose_date");
	var callersFilter = state.callersFilter;
	var recipientsFilter = state.recipientsFilter;
	var dateFilter = state.dateFilter;
	var searchCaller = state.searchCaller;
	var searchRecipient = state.searchRecipient;
	var filterCallers = state.filterCallers;
	var filterRecipients = state.filterRecipients;
	var closeCallerSearch = state.closeCallerSearch;
	var closeRecipientSearch = state.closeRecipientSearch;
	var offline = state.connection.get("offline");
	var careplanChanges = null;
	var titleKey = "call_title";

	var modal_actions = [<Button key={"buttonok"} onClick={
		par(hide_details, component)
	} > OK </Button >
	];

	var headers = [
		{
			name: "from",
			content: localization.get("call_headers_from")
		}, {
			name: "to",
			content: localization.get("call_headers_to")
		}, {
			name: "state",
			content: localization.get("call_headers_state")
		}, {
			name: "start",
			content: localization.get("call_headers_start")
		}, {
			name: "duration",
			content: localization.get("call_headers_duration")
		}
	];

	var details = [];
	if (state.details)
		details = state.logs.get(state.details);

	var date_actions = [
		<Button key={"buttondate"}
			onClick={par(handle_date, component, "time")}>
			{localization.get("search_apply")}
		</Button >,
		<Button key={"buttoncancel"}
			onClick={par(hideSearch, component, "time")}>
			{localization.get("form_submissions_history_cancel")}
		</Button >
	];

	if (!callsAreSame(state.calls, state.previousCalls)) {
		state.calls.length && this.state.userMetrics.trackEvent("admin-calls: viewed calls", {
			"calls": state.calls.map((call) => ({
				"from": call.from,
				"to": call.to,
			})),
			"filters": {
				"callers": state.callers,
				"recipients": state.recipients,
				"start": state.startDate,
				"end": state.endDate
			}
		});
		this.setState({
			previousCalls: state.calls
		});
	}


	return (
		<div className="flex-vertical flex-1">
			<Header
				careplanChanges={careplanChanges}
				offline={offline}
				currentPerson={currentPerson}
				localization={localization}
				titleKey={titleKey}
			/>

			<div className="flex-horizontal ae-empty">
				{renderCallerFilterChip(component)}
				{renderRecipientFilterChip(component)}
				{renderDateFilterChip(component)}
			</div>


			<Scroll loadMore={loadMore}>
				<Table>
					{renderHeader(headers)}
					{renderBody(component, headers, rowData)}
				</Table>
			</Scroll>

			<SearchTable action={par(filterCallers, component)} ref="searchDialog" search={searchCaller}
				localization={localization}
				people={people}
				showing={callersFilter}
				onClose={closeCallerSearch}
				title={localization.get("search_panel_title")} />

			<SearchTable action={par(filterRecipients, component)} ref="searchDialog" search={searchRecipient}
				localization={localization}
				people={people}
				showing={recipientsFilter}
				onClose={closeRecipientSearch}
				title={localization.get("search_panel_title")} />

			<Dialog actions={date_actions} open={dateFilter} title={localization.get("search_time")}>
				<DatePicker invalid={choose_date}
					labelStart={localization.get("report_start")}
					labelEnd={localization.get("report_end")}
					startDate={startDate}
					endDate={endDate}
					updateStart={par(updateChanged, component, "startDate")}
					updateEnd={par(updateChanged, component, "endDate")}
				/>
			</Dialog>

			<Dialog actions={modal_actions} open={!!state.details} title={localization.get("call_details")}>
				{render_details(state.people, details, localization)}
			</Dialog>
		</div>
	);
}

function callsAreSame(calls, previousCalls) {
	calls = calls || [];
	previousCalls = previousCalls || [];
	if (calls.length !== previousCalls.length) return false;
	for (var i = 0; i < calls.length; i++) {
		if (calls[i]._id !== previousCalls[i]._id) return false;
	}
	return true;
}

function renderDateFilterChip(component) {
	var state = component.state;
	var deleteOn = noop();

	var styleavatar = styles.avatarPrimary;
	var styleback = styles.backPrimary;

	if (!isEmpty(state.startDate) && !isEmpty(state.endDate)) {
		deleteOn = par(clearSearch, component, "time");
		styleavatar = styles.avatar;
		styleback = styles.back;
	}

	return (
		<Chip className="ae-icon ae-fonticon"
			onDelete={deleteOn}
			style={styleback}
			onClick={par(showSearch, component, "time")}
			avatar={<Avatar style={styleavatar}>
				<FontIcon className="fa fa-filter fa-2x" style={styles.icon} />
			</Avatar>}
			label={renderFilterLabel(component, "time")}
		/>);
}

function renderCallerFilterChip(component) {
	var state = component.state;
	var deleteOn = noop();
	var styleavatar = styles.avatarPrimary;
	var styleback = styles.backPrimary;

	if (state.callers.length) {
		deleteOn = par(clearSearch, component, "callers");
		styleavatar = styles.avatar;
		styleback = styles.back;
	}

	return (
		<Chip className="ae-icon ae-fonticon"
			onDelete={deleteOn}
			style={styleback}
			onClick={par(showSearch, component, "callers")}
			avatar={<Avatar style={styleavatar}>
				<FontIcon className="fa fa-filter fa-2x" style={styles.icon} />
			</Avatar>}
			label={renderFilterLabel(component, "callers")}
		/>
	);
}

function renderRecipientFilterChip(component) {
	var state = component.state;
	var deleteOn = noop();

	var styleavatar = styles.avatarPrimary;
	var styleback = styles.backPrimary;

	if (state.recipients.length) {
		deleteOn = par(clearSearch, component, "recipients");
		styleavatar = styles.avatar;
		styleback = styles.back;
	}

	return (
		<Chip className="ae-icon ae-fonticon"
			onDelete={deleteOn}
			style={styleback}
			onClick={par(showSearch, component, "recipients")}
			avatar={<Avatar style={styleavatar}>
				<FontIcon className="fa fa-filter fa-2x" style={styles.icon} />
			</Avatar>}
			label={renderFilterLabel(component, "recipients")}
		/>
	);
}

function convertData(localization, people, record) {
	return {
		"from": Utility.format_name(people.get(record.from)),
		"to": Utility.format_name(people.get(record.to)),
		"state": translate(localization, what_state(record)),
		"start": Utility.timestamp(record.created_at),
		"duration": duration(record)
	};
}

function duration(call) {
	var state = what_state(call);
	var start,
		end;

	if (state === "call_invalid")
		return "0ms";

	if (state === "Finished") {
		start = call.content.accept;
		end = call.content.hangup;
	} else if (state === "Denied") {
		start = call.content.send;
		end = call.content.deny;
	} else if (state === "Cancelled") {
		start = call.content.send;
		end = call.content.hangup;
	} else if (state === "Disconnected") {
		start = call.content.accept || call.content.send;
		end = call.content.disconnected;
	} else {
		start = call.content.send;
		end = call.content.disconnected || call.updated_at;
	}

	start = new Date(start).getTime();
	end = new Date(end).getTime();
	var delta = end - start;
	if (delta <= 0) delta = 0;
	return prettyMs(delta);
}

function translate(localization, state) {
	var translated = localization.get(state);
	return translated;
}

/**
* Determines the state of the call to post to dashboard
* Below is truth table used; order of ops is important.
* @param	{[type]} record [description]
* @return {[type]}				[description]
*/
function what_state(record) {
	var states = record.content;

	var send = "send" in states;
	var accept = "accept" in states;
	var deny = "deny" in states;
	var hangup = "hangup" in states;
	var disconnected = "disconnected" in states;

	if (!send)
		return "call_invalid";
	if (accept && !hangup)
		return "call_accepted";
	if (deny)
		return "call_denied";
	if (!deny && !hangup && !disconnected)
		return "call_calling";
	if (accept && hangup)		// removed test for disconnected temporarily
		return "call_finished";
	if (!deny && hangup)
		return "call_cancelled";
	if (!deny && !hangup && disconnected)
		return "call_interrupted";
	if (disconnected)
		return "call_disconnected";

	return "call_invalid";
}

function render_details(people, details, localization) {
	return (
		<List>
			{details.map(function (detail, index) {
				return (
					<ListItem key={index} >
						<ListItemText primary={detail_title(people, detail, localization)} secondary={detail_details(detail)} />
					</ListItem>);
			})}
		</List>
	);
}

function renderHeader(headers) {
	return (
		<TableHeader>
			<TableRow>
				{headers.map(renderHeaderColumn)}
			</TableRow>
		</TableHeader>
	);
}

function renderHeaderColumn(column, index) {
	return (
		<TableHeaderColumn key={index}>{column.content}</TableHeaderColumn>
	);
}

function renderBody(component, headers, rows) {
	return (
		<TableBody>
			{rows.map(par(renderRow, component, headers))}
		</TableBody>
	);
}

function renderRow(component, headers, row, index) {
	return (
		<TableRow key={index} onClick={par(display_details, component, index)}>
			{headers.map(par(renderRowColumn, row))}
		</TableRow>
	);
}

function renderRowColumn(row, column, index) {
	return (
		<TableRowColumn key={index}>
			{row[column.name]}
		</TableRowColumn>
	);
}

function detail_title(people, detail) {
	var person = people.get(detail.logUserID);
	var name = Utility.format_name(person);
	var time = Utility.timestamp(detail.logDate);
	return name + " @ " + time + "";
}

function detail_details(detail) {
	return detail.logMsg;
}

function hide_details(component) {
	component.setState({
		details: null
	});
	component.state.userMetrics.trackEvent("admin-calls: close call details popup");
}

function display_details(component, index) {
	var data = component.state.calls[index];
	var id = data._id;
	component.state.userMetrics.trackEvent("admin-calls: viewed call details", {
		"call": id
	});
	component.setState({
		details: id
	});
}

/**
 * A function to reset a date by 1 day ahead.  Used to adjust the end date
 * on the date selector function because the default value is midnight of the
 * day selected instead of say, 23:59 of the day chosen or midnight of the next
 * day
 * @param  {String} date Date string
 * @return {String}      Date string
 */
function adjust_date(date) {
	if (isEmpty(date)) return "";
	var end = new Date(date);
	end.setDate(end.getDate() + 1);
	return end;
}

function updateChanged(component, name, event, date) {
	var value = date;
	if (event) {
		value = event._d;
	}
	var update = {};
	update[name] = value;
	component.setState(update);
}

function showSearch(component, name) {
	if (name === "callers") component.setState({
		callersFilter: true
	});
	if (name === "recipients") component.setState({
		recipientsFilter: true
	});
	if (name === "status") component.setState({
		statusFilter: true
	});
	if (name === "time") component.setState({
		dateFilter: true
	});
	component.state.userMetrics.trackEvent(`admin-calls: open ${name} filter popup`);
}

function hideSearch(component, name) {
	if (name === "callers") component.setState({
		callersFilter: false
	});
	if (name === "recipients") component.setState({
		recipientsFilter: false
	});
	if (name === "status") component.setState({
		statusFilter: false
	});
	if (name === "time") component.setState({
		dateFilter: false
	});
	component.state.userMetrics.trackEvent(`admin-calls: close ${name} filter popup`);
}

function clearSearch(component, name) {
	var clear = component.state.filterClear;
	if (name === "callers") {
		component.setState({
			callers: []
		});
		return clear("callers");
	}
	if (name === "recipients") {
		component.setState({
			recipients: []
		});
		return clear("recipients");
	}
	if (name === "status") {
		component.setState({
			callstatus: ""
		});
		return clear("status");
	}
	if (name === "time") {
		component.setState({
			startDate: "",
			endDate: new Date()
		});
		return clear("time");
	}
}

function renderFilterLabel(component, name) {
	var state = component.state;
	var localization = state.localization;
	if (name === "time") {
		var start = state.startDate;
		var end = state.endDate;
		if (isEmpty(start) || isEmpty(end)) {
			return localization.get("search_start");
		} else {
			start = shortDate(start);
			end = shortDate(end);
			const label = start + " - " + end;
			return label;
		}
	}
	if (name === "callers") {
		const length = state.callers.length;
		if (!length) return (localization.get("search_from"));
		const label = localization.get("search_from") + " (" + length + ")";
		return label;
	}
	if (name === "recipients") {
		const length = state.recipients.length;
		if (!length) return (localization.get("search_to"));
		const label = localization.get("search_to") + " (" + length + ")";
		return label;
	}
	if (name === "status") {
		const status = state.callstatus;
		if (!status) return localization.get("search_status");
		const label = localization.get("search_status_value")[status];
		return label;
	}
}

function shortDate(date) {
	var day = date.getDate();
	var month = date.getMonth() + 1;
	var year = date.getFullYear();
	return (day + "/" + month + "/" + year);

}

function handle_date(component, value) {
	var state = component.state;
	hideSearch(component, value);
	var start = state.startDate;
	var end = adjust_date(state.endDate);
	component.state.userMetrics.trackEvent(`admin-calls: applied date filter for ${value}`, {
		start,
		end,
	});
	return state.filterDate(start, end);
}

function noop() { }
