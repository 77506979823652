/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import CreateReactClass from "create-react-class";
import xtend from "xtend";
import PropTypes from "prop-types";
import { Platform } from "react-native";
import localizer from "ae-localizer";


var componentTypes = {
	formatDate: PropTypes.func,
	localize: PropTypes.func,
	localizeFrom: PropTypes.func,
	dispatch: PropTypes.func,
	loadImage: PropTypes.func
};

var defaults = {
	displayName: "UIX",
	getChildContext: childContext
};

module.exports = defineUI;
module.exports.defaults = defaults;
module.exports.componentTypes = componentTypes;

function defineUI(element) {
	var reactClass = CreateReactClass(xtend(defaults, element));
	reactClass.childContextTypes = element.componentTypes || componentTypes;
	return reactClass;
}

function childContext() {
	return {
		formatDate: formatDate.bind(this),
		localize: localize.bind(this),
		localizeFrom: localizeFrom.bind(this),
		dispatch: dispatch.bind(this),
		loadImage: loadImage.bind(this)
	};
}

function localize(key, args){
	var map = this.state.localization._data;

	return localizer(map, key, args);
}

function localizeFrom(map, key, args) {
	var language = this.state.currentPerson.get("personal").language || "en";
	if(typeof map === "object") {
		var localizedMap = map[language];
		if(!localizedMap) {
			localizedMap = map;
		}
	}

	return localizer(localizedMap, key, args);
}

function formatDate(date, options){
	var language = this.state.currentPerson.get("personal").language || "en";
	if(language === "cn_s")
		language = "zh-CN";
	else if(language === "cn_t")
		language = "zh-HK";
	return new Date(date).toLocaleDateString(language, options);
}

function dispatch(event, data){
	return function(newData){
		var finalData = (typeof data === "undefined") ? newData : data;
		if(event === "fileviewer:trigger:view"){
			var file = finalData.file;
			var url = file.url || file.uri;
			if(!file && !url) return;
			var newUrl = url + "?view";
			window.open(newUrl, "_blank");
		} else if (event === "linkopener:trigger:open"){
			var uri = finalData;
			if(typeof finalData === "object" && finalData.uri){
				uri = finalData.uri;
			}
			if(!uri) return;
			window.open(uri, "_blank");
		}
	};
}

function loadImage(file){
	return new Promise((resolve, reject) => {
		if(!isWeb()) return resolve(file.uri);
		// eslint-disable-next-line no-undef
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = function() {
			resolve(reader.result);
		};
		reader.onerror = function(error){
			reject(error);
		};
	});
}

function isWeb(){
	return Platform.OS === "web";
}
