/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

import par from "par";

import defineUI from "../shared/define";
import render from "./render.jsx";
import WorkflowStore from "../stores/org_workflow";
import CurrentPersonInfoStore from "../stores/current_person_info_store";
import LocalizationStore from "../stores/localization_store";
import ConnectionStore from "../stores/connection_store";
import CareplanNotificationStore from "../stores/careplan_notice_store";
import ResourcesStore from "../stores/org_resources_store";
import FormsStore from "../stores/org_forms_store";
import OptionStore from "../stores/org_option_schema_store";
import OrgCustomIndicator from "../stores/org_custom_indicators_store";
import makeStoreFactory from "../shared/stores";

const makeStores = makeStoreFactory({
	workflow: WorkflowStore,
	currentPerson: CurrentPersonInfoStore,
	localization: LocalizationStore,
	connection: ConnectionStore,
	careplanChangeList: CareplanNotificationStore,
	forms: FormsStore,
	resources: ResourcesStore,
	options: OptionStore,
	indicators: OrgCustomIndicator
});

export default make_ui;

function make_ui(api, events, config, userMetrics) {
	var storeStore = makeStores(api, events, config);

	window.stores = storeStore.store;

	userMetrics.trackEvent("page view", {
		"page": "org-pathway",
		"category": "org",
		"permission level": "org:manager",
	});

	return defineUI({
		getInitialState: getInitialState,
		componentDidMount: listen_for_changes,
		render: render,
		componentWillUnmount: removeListeners
	});

	function removeListeners() {
		events.off();
	}

	function listen_for_changes() {
		storeStore.listen(par(handle_change, this));
	}

	function getInitialState() {
		return {
			workflow: storeStore.stores.workflow,
			currentPerson: storeStore.stores.currentPerson,
			localization: storeStore.stores.localization,
			connection: storeStore.stores.connection,
			careplanChangeList: storeStore.stores.careplanChangeList,
			forms: [],
			resources: [],
			updating: false,
			creating: false,
			schemaError: false,
			iconError: false,
			addWorkflow: addWorkflow,
			updateWorkflow: updateWorkflow,
			removeWorkflow: removeWorkflow,
			indicators: storeStore.stores.indicators,
			options: [],
			userMetrics: userMetrics,
		};
	}

	function handle_change(component, stores) {
		component.setState({
			workflow: stores.workflow.all(),
			forms: stores.forms.all(),
			resources: stores.resources.all(),
			options: stores.options.all(),
			currentPerson: stores.currentPerson,
			localization: stores.localization,
			connection: stores.connection,
			careplanChangeList: stores.careplanChangeList,
			indicators: storeStore.stores.indicators
		});
	}

	function addWorkflow(workflow) {
		var icon = workflow.icon;
		var schema = workflow.schema;

		if (!icon || !schema) {
			return;
		}

		return api.org.workflow.create(workflow);
	}

	function updateWorkflow(workflow) {
		var workflowId = workflow._id;
		var icon = workflow.icon;
		var schema = workflow.schema;

		if (!icon || !schema) {
			return;
		}

		return api.org.workflow.update(workflowId, workflow);
	}

	function removeWorkflow(workflow) {
		var workflowId = workflow._id;

		return api.org.workflow.remove(workflowId);
	}
}
