
/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";

import par from "par";
import xtend from "xtend";
import config from "../../configs/config.json";
import Header from "../shared/Header.js";
import IconButton from "@material-ui/1.5.1/IconButton";
import Avatar from "@material-ui/1.5.1/Avatar";
import ListItem from "@material-ui/1.5.1/ListItem";
import ListItemText from "@material-ui/1.5.1/ListItemText";
import Select from "@material-ui/1.5.1/Select";
import FormControl from "@material-ui/1.5.1/FormControl";
import InputLabel from "@material-ui/1.5.1/InputLabel";
import MenuItem from "@material-ui/1.5.1/MenuItem";
import Button from "@material-ui/1.5.1/Button";
import Snackbar from "@material-ui/1.5.1/Snackbar";
import Utility from "../shared/Utility.js";
import LazyList from "../shared/LazyList.jsx";
import  AetonixTheme_mui_1_5_1 from "../shared/AetonixTheme_mui_1.5.1";
const Colors = AetonixTheme_mui_1_5_1.palette;

import Search from "../shared/Search.jsx";
import FontIcon from "@material-ui/1.5.1/Icon";

var styles = {
	search: {
		color: Colors.primary.main,
		minWidth: "30%",
		padding: 8
	},
	input: {
		color: Colors.primary.main,
		padding: 8
	},
	inputlabel: {
		color: "black"
	},
	inputProp: {
		style: {
			color: Colors.primary.main,
		}
	},
	formControlStyle: {
		margin: "18px",
		minWidth: "400px"
	},
	margin: {
		margin: "18px"
	},
	subHeader: {
		display: "flex",
		flexDirection: "row",
		paddingLeft: "1em",
		alignItems: "baseline"
	},
	actorButton: {
		paddingTop: "10px",
		paddingBottom: "10px"
	},
	icon: {
		fontSize: 20,
		color: Colors.canvas,
		margin: "5px"
	},
	toast: {
		margin: "100px",
		padding: "10px",
		backgroundColor: "rgb(0, 172, 193)",
		borderRadius: "10px"
	},
	toastText: {
		color: Colors.canvas,
		margin: "5px"
	}
};

var iconButtonClasses = {
	root: "ae-inline"
};

export default render;

function render() {
	var component = this;
	var state = component.state;

	var localization = state.localization;
	var careplanNoticeList = state.careplanChangeList.all();
	var careplanChanges = !!careplanNoticeList.length;
	var offline = state.connection.get("offline");
	var currentPerson = state.currentPerson;
	var workflows = state.workflow.all();
	var titleKey = "dashboards_group_workflowAutostart";
	var language = currentPerson.get("personal").language;
	var currentSettings = state.settings || state.autostart || {};

	var workflowList = workflows.map(function (workflow){
		var schema = workflow.schema;
		var title = getWorkflowTitle(schema, language);
		return (
			<MenuItem key={title} value={workflow._id}>{title}</MenuItem>
		);
	});

	workflowList = [(<MenuItem key={"none"} value={"none"}>{localization.get("workflow_autostart_none")}</MenuItem>)].concat(workflowList);

	var currentWorkflow = currentSettings._id || "none";
	var currentViewing = state.viewing;
	var actorsSelected = (currentSettings.actors || []).filter(function(actor){
		return actor.actor === currentViewing;
	}).map(function(actor){
		return actor.who;
	});

	return (
		<div className="flex-vertical flex-1">
			<Header
				careplanChanges={careplanChanges}
				offline={offline}
				currentPerson={currentPerson}
				localization={localization}
				titleKey={titleKey}
			/>
			<FormControl style={styles.formControlStyle}>
				<InputLabel style={styles.inputlabel}>{localization.get("workflow_autostart_workflow")}</InputLabel>
				<Select fullWidth onChange={par(updateDropdown, component, "_id")} value={currentWorkflow}>
					{workflowList}
				</Select>
			</FormControl>
			{renderActorForms(component, currentWorkflow)}
			<Search
				selection={actorsSelected}
				action={par(addActor, component)}
				ref="searchDialog"
				search={state.search}
				localization={localization}
				onDismiss={() => component.state.userMetrics.trackEvent("group-pathway-autostart: close search popup")}
			/>
			<Button variant="raised" color="primary" onClick={par(saveSettings, this)}>
				{localization.get("workflow_autostart_save")}
			</Button>
			<Snackbar open={state.toast} autoHideDuration={6000} onClose={par(handleCloseToast, this)}>
				<div className="flex-horizontal ae-dropshadow" style={styles.toast}>
					<FontIcon className="fa fa-check fa-2x" style={styles.icon} />
					<div style={styles.toastText}>{localization.get("workflow_autostart_settings_saved")}</div>
				</div>
			</Snackbar>
		</div>
	);
}

function renderUser(component, actor, user){
	if (!user._id) return;
	var avatar = <Avatar alt="Actor Avatar Image" src={config.image_cdn + user.image} />;
	var text = Utility.format_name(user);
	var userId = user._id;
	var background_colour = "ae-plain";

	return (
		<ListItem key={userId} className={background_colour}>
			{avatar}
			<ListItemText primary={text} />
			<IconButton classes={iconButtonClasses} disableRipple color="primary" className="fa fa-user-times" onClick={par(removeActor, component, actor, userId)} />
		</ListItem>
	);
}

function addActor(component, person){
	var current = component.state.settings || component.state.autostart || {};
	var currentActors = current.actors || [];
	var currentViewing = component.state.viewing;

	var newActors = currentActors.concat({
		actor: currentViewing,
		who: person
	});

	var update = xtend(current, {
		actors: newActors
	});

	component.setState({
		settings: update
	});

	component.state.userMetrics.trackEvent("group-pathway-autostart: add actor", {
		"actor": currentViewing,
	});
}

function updateDropdown(component, type, e) {
	e.persist();
	var payload = e.target.value;
	var current = {};
	current[type] = payload;

	component.setState({
		settings: current
	});
	component.state.userMetrics.trackEvent("group-pathway-autostart: select workflow", {
		"workflow": payload,
	});
}

function showSearch(component, actor) {
	component.setState({
		viewing: actor
	});
	component.refs.searchDialog.show();
	component.state.userMetrics.trackEvent("group-pathway-autostart: open show search popup", {
		"actor": actor,
	});
}

function saveSettings(component){
	var settings = component.state.settings || component.state.autostart;
	var save = component.state.save;
	var remove = component.state.remove;
	var promise = null;
	if(settings._id === "none"){
		component.state.userMetrics.trackEvent("group-pathway-autostart: click save without a chosen workflow", {
			"workflow": settings._id,
		});
		promise = remove();
		component.setState({
			settings: null
		});
	} else {
		component.state.userMetrics.trackEvent("group-pathway-autostart: save settings", {
			"workflow": settings._id,
		});
		promise = save(component);
	}
	return promise.then(() => {
		component.setState({
			toast: true
		});
	});
}

function handleCloseToast(component){
	component.setState({
		toast: false
	});
}

function getWorkflowTitle(schema, language){
	var description = schema.description || {};
	return description[language] || description["en"] || description[Object.keys(description)[0]];
}

function renderActorForms(component, workflow){
	var language = component.state.currentPerson.get("personal").language;
	var people = component.state.people;
	if(!workflow) return;
	var definition = component.state.workflow.get(workflow);
	if(!definition) return;
	var schema = definition.schema || {};
	var actorSchema = schema.actors;

	var currentSettings = component.state.settings || component.state.autostart || {};
	var currentActors = currentSettings.actors || [];

	var actors = Object.keys(actorSchema).filter(function(e){
		return e !== "Patient";
	});

	var renderActors = actors.map(function(actor){
		var currentActor = actorSchema[actor];
		var actorLocale = currentActor[language] || currentActor["en"] || currentActor[Object.keys(currentActor)[0]];

		var currentFiltered = currentActors.reduce(function(a, e){
			if(e.actor === actor)
				a = a.concat(people.get(e.who));
			return a;
		}, []);

		return (
			<div className="flex-1 flex-vertical ae-scrollable">
				{renderActorForm(component, actorLocale, actor)}
				<LazyList className="ae-scrollable" renderItem={par(renderUser, component, actor)} items={currentFiltered} />
			</div>
		);
	});

	return (
			<div className="flex-horizontal flex-1 ae-scrollable">
				{renderActors}
			</div>
	);
}

function renderActorForm(component, actorLocale, actor){
	var localization = component.state.localization;
	return (
		<div style={styles.margin}>
			<div className="ae-title">{actorLocale}</div>
			<div style={styles.actorButton}>
				<Button color="primary" variant="raised"  onClick={par(showSearch, component, actor)}>{localization.get("workflow_autostart_addactor")}</Button>
			</div>
		</div>
	);
}

function removeActor(component, actor, userId){
	var currentSettings = component.state.settings || component.state.autostart || {};
	var currentActors = currentSettings.actors;
	var newActors = currentActors.filter(function(e){
		return !(e.actor === actor && e.who === userId);
	});
	var newSettings = xtend(currentSettings, {
		actors: newActors
	});
	component.setState({
		settings: newSettings
	});
	component.state.userMetrics.trackEvent("group-pathway-autostart: remove actor", {
		"actor": actor,
	});
}
