import React, { useContext } from "react";
import PropTypes from "prop-types";
export const FormatDateContext = React.createContext(()=>{});

export const FormatDateProvider = (props, context) => {
	return <FormatDateContext.Provider {...props} value={context.formatDate} />;
};

export const FormatDateConsumer = FormatDateContext.Consumer;

export const useFormatDate = () => {
	return useContext(FormatDateContext);
};

FormatDateProvider.contextTypes = {
	formatDate: PropTypes.func
};
