/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */
import Store from "./store";

import map from "fj-map";

export default AdminTasksStore;

function AdminTasksStore(api, events) {
	var store = new Store();

	var page = 0;
	var filter = [];
	var atEnd = false;
	reset();

	store.loadMore = loadMore;
	store.search = search;
	store.clearSearch = clearSearch;
	store.atEnd = atEnd;

	return store;

	function reset(){
		return api.admin.tasks.list(filter, page)
			.then(checkEnd)
			.then(map(setTask))
			.then(listenChanges);
	}

	function listenChanges(){
		events.on("mqtt:tasks/removed", removeTaskFromStore);
	}

	function setTask(task){
		store.set(task._id, task);
	}

	function removeTaskFromStore(data){
		var id = data._id;
		store.remove(id);
	}

	function checkEnd(tasks){
		if(tasks.length < 32)
			atEnd = true;
		return tasks;
	}

	function loadMore(){
		if(atEnd) return;
		++page;
		reset();
	}

	function search(who){
		filter = filter.concat(who);
		store.clear();
		atEnd = false;
		page = 0;
		reset();
	}

	function clearSearch(){
		filter = [];
		store.clear();
		atEnd = false;
		page = 0;
		reset();
	}
}
