import format from "string-template";
import xtend from "xtend";
import par from "par";

import { DndProvider } from "react-dnd";
import React, { useState, useEffect } from "react";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useApi } from "../shared/contexts/ResourceContext";
import Utility from "../shared/Utility";
const { getLocalStorageArray } = Utility;

import config from "../../configs/config.json";
import Header from "../shared/Header.js";
import Dialog from "../shared/Dialog";
import TableCell from "@material-ui/1.5.1/TableCell";
import Button from "@material-ui/1.5.1/Button";
import IconButton from "@material-ui/1.5.1/IconButton";
import FormControlLabel from "@material-ui/1.5.1/FormControlLabel";
import Checkbox from "@material-ui/1.5.1/Checkbox";
import MenuItem from "@material-ui/1.5.1/MenuItem";
import ListItemText from "@material-ui/1.5.1/ListItemText";
import Select from "@material-ui/1.5.1/Select";
import InputLabel from "@material-ui/1.5.1/InputLabel";
import TextField from "@material-ui/1.5.1/TextField";
import FontIcon from "@material-ui/1.5.1/Icon";

import  AetonixTheme_mui_1_5_1 from "../shared/AetonixTheme_mui_1.5.1";
const Colors = AetonixTheme_mui_1_5_1.palette;

import { Typography } from "@material-ui/1.5.1";
import { getNameForLanguage } from "../org-custom-indicators/library";
import { TableComponent } from "../shared/TableComponent";
import { getHeaderPropertyText } from "../shared/TableComponent/helpers";
import { ACTIVE_STATES, USER_STATES } from "../shared/UserStates";

import { MeasureUnitsProvider, useMeasureUnits } from "@aetonix/hooks";

var styles = {
	totalBoxContainer: {
		display: "flex",
		padding: "20px 0px"
	},
	totalContainer: {
		display: "flex",
		justifyContent: "space-between"
	},
	infoBoxInfo: {
		border: "0.5px solid lightgrey",
		padding: "1px"
	},
	infoBoxValue: {
		padding: "25px 50px",
		fontSize: "1.8em"
	},
	infoBoxTitle: { padding: "5px 0px 0px 5px" },
	tableContainer: {
		display: "flex",
		flexDirection: "column",
		overflowX: "hidden",
		overflowY: "scroll"
	},
	tableRows: {
		padding: "inherit"
	},
	iconStyle: {
		color: "#065B61",
		paddingRight: "4px",
		fontSize: "19px"
	},
	nameContainer: {
		display: "flex",
		alignItems: "center"
	},
	nameId: {
		display: "flex",
		flexDirection: "column"
	},
	red: {
		backgroundColor: "#faa0a0"
	},
	yellow: {
		backgroundColor: "#ffe694"
	},
	green: {
		backgroundColor: "#c5e09e"
	},
	columnsMenuPaper: {
		position: "absolute",
		right: 10,
		padding: "10px",
		zIndex: 1,
		overflowY: "scroll",
		display: "grid",
		maxHeight: "50%",
		color: "rgba(0, 0, 0, 0.54)"
	},
	columnsMenuFormGroup: {},
	search: {
		color: Colors.primary.main,
		minWidth: "30%"
	},
	inputProp: { style: { color: Colors.primary.main } },
	selectInput: { width: "200px" },
	compliantContainer: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between"
	},
	columnPresetAccordion: {
		display: "grid"
	},
	columnPresetButton: {
		justifyContent: "flex-start"
	},
	headerCellUser: {
		paddingRight: "120px"
	},
	innerDraggableHeaderCell: {
		alignSelf: "center"
	},
	draggableColumnHeaderDisabled: {
		opacity: 1,
		textAlign: "left",
		fontSize: "0.75rem",
		fontWeight: "500",
		color: "rgba(0, 0, 0, 0.54)",
		borderBottom: "1px solid rgba(224, 224, 224, 1)",
		verticalAlign: "center",
		padding: "4px 56px 4px 24px"
	},
	draggableColumnsHeaderContainer: {
		cursor: "move",
		fontSize: "0.75rem",
		fontWeight: "500",
		color: "rgba(0, 0, 0, 0.54)",
		borderBottom: "1px solid rgba(224, 224, 224, 1)",
		verticalAlign: "center",
		padding: "4px 20px 4px 12px"
	},
	draggableColumnsHeaderContents: {
		display: "flex"
	},
	noResults: {
		fontSize: "0.75rem",
		fontWeight: "500",
		color: "rgba(0, 0, 0, 0.54)",
		padding: "10px"
	},
	selectFilters: {
		minWidth: "150",
		maxWidth: "300px",
	},
	noteDate: {
		fontSize: "12px"
	},
	noteName: {
		fontWeight: "bold"
	},
	noteBody: {
		wordBreak: "break-all",
		padding: "5px 15px",
		fontSize: "14px"
	},
	noteTitle: {
		paddingTop: "5px"
	},
	infoButton: {
		width: "2rem",
		height: "2rem",
		padding: "0.25rem",
		fontSize: "1rem"
	},
	notePreview: {
		display: "flex",
		flexDirection: "row"
	},
	indicatorText: {
		fontWeight: "bold",
		fontSize: "14px"
	},
	indicatorTextSmall: {
		fontSize: "12px"
	}
};

var MAX_TEXT_SIZE = 27;

var tableCellTypes = {
	0: { root: "status-green" },
	1: { root: "status-yellow" },
	2: { root: "status-red" }
};

var COLUMNS = [
	"WorkflowStatus",
	"OxygenSaturation",
	"HeartRate",
	"BodyTemperature",
	"BloodSugar",
	"Weight",
	"BloodPressure",
	"BloodPressurePulserate",
	"Steps",
	"Notes"
];

const VITAL_COLUMNS_MAP = {
	"OxygenSaturation": "bloodoxygen",
	"HeartRate": "bloodoxygen",
	"BodyTemperature": "bodytemperature",
	"BloodSugar": "bloodsugar",
	"Weight": "weight",
	"BloodPressure": "bloodpressure",
	"BloodPressurePulserate": "bloodpressure",
	"Steps": "activity",
	"Notes": "note",
};

var EXPORTCOLUMNS = [
	"OverallStatus",
	"WorkflowStatus",
	"OxygenSaturation",
	"HeartRate",
	"BodyTemperature",
	"BloodSugar",
	"Weight",
	"BloodPressure",
	"BPPulserate",
	"Steps",
	"Notes",
	"PatientNumber",
	"JoinDate"
];

var INDICATOR_MAP = {
	bloodsugar: {
		mmol: "{mmol}",
		mg: "{mg}"
	},
	bodytemperature: {
		celsius: "{celsius}",
		fahrenheit: "{fahrenheit}"
	},
	weight: {
		kg: "{kg}",
		lbs: "{lbs}"
	},
	bloodoxygen: {
		pulserate: "{pulserate}",
		oxygensaturation: "{oxygensaturation}"
	},
	activity: {
		cumulative: "{cumulative}"
	},
	bloodpressure: {
		systolic: "{systolic}/{diastolic}",
		bpPulserate: "{bpPulserate}"
	}
};

var ONE_DAY = 1000 * 3600 * 24;

var EXPORT_PAGES = 1;

const GroupUsersOverview = ({ component }) => {
	const { state } = component;

	var localization = state.localization;
	var careplanNoticeList = state.careplanChangeList.all();
	var careplanChanges = !!careplanNoticeList.length;
	var offline = state.connection.get("offline");
	var currentPerson = state.currentPerson;
	var users = state.users || new Map();
	var people = state.people;
	const { userLocalStorageKey, manageGroups } = state;
	var totals = state.totals || {};
	const viewing = state.viewing;
	var compliantSchedule = state.compliancy.get("schedule") || {};
	var exportPage = state.exportPage;
	const indicators = state.customIndicators?.all?.() ?? [];
	const patientGroupsFilterKey = `${state.userLocalStorageKey}manageGroups`;

	const filterColumns = column => {
		if (column.indicator) {
			const indicatorFromList = indicators?.find?.(indicator => indicator._id == column.key);
			return !!indicatorFromList;
		}

		return true;
	};

	useEffect(() => {
		const alreadyCheckedGroups = state[`${userLocalStorageKey}manageGroups`] ?? [];
		const filteredGroups = alreadyCheckedGroups.filter((element) => manageGroups.some((group) => group._id === element));

		if (alreadyCheckedGroups.length !== filteredGroups.length) {
			var newState = {
				page: 0,
				[patientGroupsFilterKey]: filteredGroups,
			};

			localStorage.setItem(patientGroupsFilterKey, JSON.stringify(filteredGroups));
			state.userFunc.updateManageGroups(filteredGroups);
			component.setState(newState);
		}

	}, [manageGroups.length]);


	const headers = [
		{
			key: "group_user_overview_user",
			localizationPrefix: "",
			isNotDraggable: true,
		},
		{
			key: "group_users_overview_overall_status",
			localizationPrefix: "",
		},
		{
			key: "followedUp",
			localization: {en: "Followed Up"},
		},
		...state.columns,
	];

	const { customIndicatorColumns = [] } = state;
	const healthColumns = [...COLUMNS, ...customIndicatorColumns];
	const headerGroups = [{
		title: "group_users_overview_health_columns",
		columns: healthColumns,
		presetTitle: "group_users_overview_show_health_columns",
	}, {
		title: "group_users_overview_group_columns",
		columns: state.customProperties ?? [],
		presetTitle: "group_users_overview_show_group_columns",
	}];

	const userStateFilter = state[`${userLocalStorageKey}stateFilter`] ?? [];
	const dataTransformer = getDataTransformer({
		localization,
		state,
		userStore: people,
		component
	});
	let isFiltered = false;
	if (state.query || userStateFilter.length > 0) {
		isFiltered = true;
	}

	var compliant_actions = [
		<Button key={"compliantsubmit"} onClick={par(submitCompliancy, component)}>
			{localization.get("search_done")}
		</Button>,
		<Button key={"compliantcancel"} onClick={par(cancelCompliancy, component)}>
			{localization.get("users_cancel")}
		</Button>
	];

	const note_view_actions = [
		<Button key="close" onClick={par(handleCloseView, component)}>
			Close
		</Button>
	];

	var titleKey = "dashboards_group_user_overview";

	var export_actions;
	if (exportPage === 0)
		export_actions = [
			<Button key={"buttoncancel"} onClick={par(cancelExport, component)}>
				{localization.get("users_cancel")}
			</Button>,
			<Button key={"buttongonext"} onClick={par(goNextExport, component)}>
				{localization.get("users_gonext")}
			</Button>
		];
	else if (exportPage > 0 && exportPage < EXPORT_PAGES)
		export_actions = [
			<Button key={"buttoncancel"} onClick={par(cancelExport, component)}>
				{localization.get("users_cancel")}
			</Button>,
			<Button key={"buttongoback"} onClick={par(goBackExport, component)}>
				{localization.get("users_goback")}
			</Button>,
			<Button key={"buttongonext"} onClick={par(goNextExport, component)}>
				{localization.get("users_gonext")}
			</Button>
		];
	else if (exportPage === EXPORT_PAGES)
		export_actions = [
			<Button key={"buttoncancel"} onClick={par(cancelExport, component)}>
				{localization.get("users_cancel")}
			</Button>,
			<Button key={"buttongoback"} onClick={par(goBackExport, component)}>
				{localization.get("users_goback")}
			</Button>,
			csvReport(component, indicators, localization)
		];

	const renderUserStatesList = () => {
		const isActiveState = userState => ACTIVE_STATES.includes(userState);
		const isChecked = (userState) => {
			const shouldCheckActiveStates = userStateFilter.includes("all_active");
			if (shouldCheckActiveStates && isActiveState(userState)) return true;

			return userStateFilter.includes(userState);
		};

		return USER_STATES.map((userState) => {
			const dataAttrs = {};
			if (isActiveState(userState)) dataAttrs["data-active-state"] = true;
			if (userState === "all_active") dataAttrs["data-all_active"] = true;

			return (
				<MenuItem key={userState} value={userState} {...dataAttrs}>
					<Checkbox checked={isChecked(userState)} {...dataAttrs}/>
					<ListItemText primary={localization.get(`group_user_overview_${userState}_patients`)} />
				</MenuItem>
			);
		});
	};

	const activityRenderValue = (states = []) => {
		if (states.length === 0) {
			return localization.get("group_user_overview_unfiltered_patients");
		}
		return states.map(userState =>
			localization.get(`group_user_overview_${userState}_patients`)).join(", ");
	};

	const manageGroupsRenderValue = (groups = []) => {
		if (groups?.length === 0) {
			return localization.get("group_user_overview_all_groups");
		}

		if(manageGroups?.length === 0){
			return localization.get("personstore_fname"); //loading...
		}

		return groups
			.filter(groupId => manageGroups.some(({ _id }) => groupId === _id))
			.map((groupId) => {
				const group = manageGroups.find(({ _id}) => groupId === _id);
				return group?.name;
			})
			.join(", ");
	};

	const renderUserManageGroupsList = () => {
		return [].concat(manageGroups.map((group) => (
			<MenuItem key={group._id} value={group._id}>
				<Checkbox checked={checkedManageGroups.includes(group._id)}/>
				<ListItemText primary={group.name} />
			</MenuItem>
		)));
	};

	const checkedManageGroups = state[`${userLocalStorageKey}manageGroups`];

	const leftActionButtons = [
		<div className="ae-vertical-end flex-horizontal" key="left-buttons-search">
			<TextField
				placeholder={localization.get("search_displayname")}
				inputProps={{"aria-label": localization.get("search_displayname")}}
				onChange={par(handleUserSearch, component)}
				style={styles.search}
				InputProps={styles.inputProp}
			/>
		</div>,
		<div key="left-buttons-view">
			<InputLabel htmlFor="list-type">{localization.get("submissions_view_data")}</InputLabel>
			<UserFilters state={state} component={component} />
		</div>,
		<div key="left-buttons-user-activity">
			<InputLabel htmlFor="list-type">{localization.get("users_activity")}</InputLabel>
			<div>
				<Select
					style={styles.selectFilters}
					inputProps={{ id: "list-activity", "aria-label": localization.get("users_activity") }}
					value={userStateFilter}
					onChange={par(handleActivityChange, component)}
					renderValue={activityRenderValue}
					displayEmpty
					multiple
				>
					{renderUserStatesList()}
				</Select>
			</div>
		</div>,
		<div key="left-buttons-patient-groups">
			<InputLabel htmlFor="list-type">{localization.get("patient_groups")}</InputLabel>
			<div>
				<Select
					style={styles.selectFilters}
					inputProps={{ id: "patient-groups" }}
					value={checkedManageGroups}
					onChange={par(handleChangeGroup, component)}
					renderValue={manageGroupsRenderValue}
					displayEmpty
					multiple
				>
					{renderUserManageGroupsList(component, checkedManageGroups)}
				</Select>
			</div>
		</div>,
	];
	const rightActionButtons = [
		<Button onClick={par(startCompliancy, component)} key="right-buttons-compliancy">
			{localization.get("group_users_overview_compliancy_edit")}
		</Button>,
		<Button onClick={par(handleOpenExport, component)} key="right-buttons-csv">
			{localization.get("group_users_overview_export_csv")}
		</Button>,
	];

	const handlePageSizeChange = (value) => {
		state.userFunc.updatePageSize(value);
	};

	const HandleSorting = (id, sort) => {
		var sortServer = false;
		var sortQuery = "";
		if(id === "group_users_overview_overall_status") {
			sortQuery = "current_status";
			sortServer = true;
		} else if(id === "WorkflowStatus") {
			sortQuery = "workflow_status";
		} else if (Object.keys(VITAL_COLUMNS_MAP).includes(id)) {
			sortQuery = VITAL_COLUMNS_MAP[id];
		} else if (id.match(/^[0-9a-fA-F]{24}$/)) {
			sortQuery = id;
		} else if (id === "group_user_overview_user") {
			sortQuery = "searchr";
			sortServer = true;
		} else if (id === "followedUp") {
			sortQuery = "followedUp";
			sortServer = true;
		} else {
			sortQuery = "customProperties." + id;
			sortServer = true;
		}

		if(sort === "up") {
			sortQuery = "+" + sortQuery;
		} else {
			sortQuery = "-" + sortQuery;
		}
		var userSortExist = state.userSortExist;
		userSortExist(sortQuery, sortServer);
	};

	return (
		<DndProvider backend={HTML5Backend}>
			<div style={{ width: "100%" }}>
				<div className="flex-vertical flex-1">
					<Header
						careplanChanges={careplanChanges}
						offline={offline}
						currentPerson={currentPerson}
						localization={localization}
						titleKey={titleKey}
					/>
					<div className="flex-1 ae-padded">
						<div style={styles.totalContainer}>
							<div>
								{renderTotals(totals, localization)}
							</div>
						</div>
						<div style={styles.tableContainer} id="group-users-overview-tablecontainer">
							<TableComponent
								headerColumns={headers}
								localization={localization}
								localizationPrefix={"group_users_overview_"}
								tableName={"group_users_overview"}
								userMetricsLabel={"health overview table"}
								pageName={"group-users-overview"}
								records={users}
								recordCount={totals.total}
								isFiltered={isFiltered}
								state={state}
								dataTransformer={dataTransformer}
								columnsFilterFn={filterColumns}
								rightActionButtons={rightActionButtons}
								leftActionButtons={leftActionButtons}
								headerGroups={headerGroups}
								measureUnits={useMeasureUnits()}
								sortingFn={HandleSorting}
								pageFn={handlePageSizeChange}
							/>
						</div>
					</div>
				</div>

				<Dialog
					actions={export_actions}
					open={!!state.exporting}
					title={localization.get("group_users_overview_export_csv")}
				>
					{renderExportSetup(component, indicators)}
				</Dialog>

				<Dialog actions={compliant_actions} open={!!state.compliant} title={localization.get("group_users_compliancy")}>
					{renderCompliancy(compliantSchedule, component)}
				</Dialog>

				<Dialog actions={note_view_actions} open={!!state.viewing} title={localization.get("ae_careplan_notes")}>
					<div className="flex-vertical">{renderNote(component, viewing)}</div>
				</Dialog>
			</div>
		</DndProvider>
	);
};

export default render;

function render() {
	var component = this;
	const state = component.state;
	const personal = state.currentPerson.get("personal");
	return (
		<MeasureUnitsProvider value={personal}>
			<GroupUsersOverview component={component} />
		</MeasureUnitsProvider>
	);
}

function renderNote(component, item) {
	if (!item) return;
	const note = item.note;
	const editedBy = item.editedBy;
	if (!note) return;

	const date = note.updated_at;
	const data = note.data || {};
	const title = data.title;
	const body = data.body;

	return (
		<div className="flex-horizontal">
			<div className="flex-vertical flex-1 itemPadding">
				<div className="flex-horizontal ae-justify">
					<span style={styles.noteName}>{editedBy}</span>
					<span style={styles.noteDate}>{formatDate(component.state, date)}</span>
				</div>
				<ListItemText primary={title} style={styles.noteTitle} className="MuiTypography-subheading-258" />
				<span style={styles.noteBody}>{body}</span>
			</div>
		</div>
	);
}


function UserFilters(props) {
	var { state, component } = props;
	return (
		<div>
			<Select
				inputProps={{ id: "list-type", "aria-label": state.localization.get("groupforms.view") }}
				value={state[component.state.userLocalStorageKey + "listType"]}
				onChange={par(handleListChange, component)}
			>
				<MenuItem value="all">{state.localization.get("group_user_overview_all_patients")}</MenuItem>
				<MenuItem value="managed">{state.localization.get("group_user_overview_my_managed")}</MenuItem>
			</Select>
		</div>
	);
}

function handleCloseView(component) {
	component.setState({
		viewing: null
	});
	component.state.userMetrics.trackEvent("group-users-overview: close note preview");
}

function exportPageToName(page) {
	if (page === 0) return "field selection";
	else if (page === 1) return "choose file name";
}

function goBackExport(component) {
	var exportPage = component.state.exportPage;
	component.setState({ exportPage: --exportPage });
	component.state.userMetrics.trackEvent(`group-users-overview: move to page ${exportPage + 1} of csv export (${exportPageToName(exportPage)})`);
}

function goNextExport(component) {
	var exportPage = component.state.exportPage;
	component.setState({ exportPage: ++exportPage });
	component.state.userMetrics.trackEvent(`group-users-overview: move to page ${exportPage + 1} of csv export (${exportPageToName(exportPage)})`);
}

function renderExportSetup(component, indicators) {
	var exportPage = component.state.exportPage;
	if (exportPage === 0) return renderExportPage1(component, indicators);
	else if (exportPage === 1) return renderExportPage2(component);
}

function renderExportPage1(component, indicators) {
	const state = component.state;
	const localization = state.localization;
	const language = state.currentPerson.get("personal").language || "en";
	const customProperties = state.customProperties || [];
	const checkBoxes = customProperties.map(property => (
		<FormControlLabel
			control={
				<Checkbox
					checked={state["export" + property.key]}
					inputProps={{"aria-label": property.localization[language] }}
					onChange={par(handleOptionChange, component, property.key)}
					value={property.key}
				/>
			}
			label={property.localization[language]}
			key={property.key + "_export_page_1"}
		/>
	));

	const healthCheckBoxes = EXPORTCOLUMNS.map(property => (
		<FormControlLabel
			control={
				<Checkbox
					checked={state["export" + property]}
					onChange={par(handleOptionChange, component, property)}
					value={property}
				/>
			}
			label={localization.get("group_users_overview_" + property)}
			key={property + "_checkbox"}
		/>
	));

	const indicatorCheckBoxes = indicators.map(indicator => (
		<FormControlLabel
			control={
				<Checkbox
					checked={state["export" + indicator._id]}
					onChange={par(handleOptionChange, component, indicator._id)}
					value={indicator._id}
				/>
			}
			label={getNameForLanguage(indicator, language)}
			key={indicator._id + "_checkbox"}
		/>
	));

	const renderCheckboxes = [...checkBoxes, ...healthCheckBoxes, ...indicatorCheckBoxes];

	return (
		<div>
			<div className="ae-title">{localization.get("group_users_overview_select_fields")}</div>
			{renderCheckboxes}
		</div>
	);
}

function renderExportPage2(component) {
	var state = component.state;
	var localization = state.localization;
	return (
		<div className="flex-1 flex-vertical">
			<FormControlLabel
				control={
					<Checkbox checked={state.exportAnon} onChange={par(handleOptionChange, component, "Anon")} value={"Anon"} />
				}
				label={localization.get("group_users_overview_" + "Anon")}
			/>
			<TextField
				label={localization.get("report_filename")}
				placeholder={localization.get("report_filename")}
				inputProps={{"aria-label": localization.get("report_filename")}}
				value={state.filename}
				onChange={par(updateFileName, component)}
				InputProps={styles.inputProp}
			/>
		</div>
	);
}

function handleOptionChange(component, col, event) {
	var checked = event.target.checked;
	var newState = {};
	newState["export" + col] = checked;
	component.setState(newState);
}

function renderCompliancy(defaults, component) {
	var state = component.state;
	var localization = state.localization;
	return Object.keys(INDICATOR_MAP).map(function(key) {
		var defaultValue = defaults[key] || 0;
		var stateValue = state[key + "Schedule"];
		if (stateValue || stateValue === 0) defaultValue = stateValue;
		return (
			<div style={styles.compliantContainer} key={key + "_compliancy"}>
				{localization.get("users_" + key)}
				<Select style={styles.selectInput} value={defaultValue} inputProps={{"aria-label": localization.get("users_" + key)}} onChange={par(handleDropUpdate, component, key)}>
					<MenuItem value={0}>{localization.get("group_user_overview_schedule_0")}</MenuItem>
					<MenuItem value={1}>{localization.get("group_user_overview_schedule_1")}</MenuItem>
					<MenuItem value={2}>{localization.get("group_user_overview_schedule_2")}</MenuItem>
					<MenuItem value={3}>{localization.get("group_user_overview_schedule_3")}</MenuItem>
					<MenuItem value={4}>{localization.get("group_user_overview_schedule_4")}</MenuItem>
					<MenuItem value={5}>{localization.get("group_user_overview_schedule_5")}</MenuItem>
					<MenuItem value={6}>{localization.get("group_user_overview_schedule_6")}</MenuItem>
					<MenuItem value={7}>{localization.get("group_user_overview_schedule_7")}</MenuItem>
					<MenuItem value={14}>{localization.get("group_user_overview_schedule_14")}</MenuItem>
					<MenuItem value={28}>{localization.get("group_user_overview_schedule_28")}</MenuItem>
				</Select>
			</div>
		);
	});
}

function handleDropUpdate(component, key, events) {
	var value = events.target.value;
	var newState = { [key + "Schedule"]: value };
	component.setState(newState);
}

function submitCompliancy(component) {
	var state = component.state;
	var submit = state.submitCompliancy;
	var currentSchedules = state.compliancy.get("schedule") || {};
	var newSchedules = Object.keys(INDICATOR_MAP).reduce(function(acc, key) {
		var value = state[key + "Schedule"];
		if (value || value === 0) acc[key] = value;
		return acc;
	}, {});

	var combinedSchedules = xtend(currentSchedules, newSchedules);
	submit(combinedSchedules);
	cancelCompliancy(component);

	component.state.userMetrics.trackEvent("group-users-overview: edit compliancy", {
		"compliancy": combinedSchedules,
	});
}

function cancelCompliancy(component) {
	component.setState({
		compliant: false,
		bloodpressureSchedule: null,
		bloodoxygenSchedule: null,
		stepsSchedule: null,
		bodytemperatureSchedule: null,
		weightSchedule: null,
		bloodsugarSchedule: null
	});

	component.state.userMetrics.trackEvent("group-users-overview: close compliancy popup");
}

function startCompliancy(component) {
	component.state.userMetrics.trackEvent("group-users-overview: open compliancy popup");
	component.setState({ compliant: true });
}

function updateFileName(component, event) {
	event.persist();
	var value = event.target.value;
	component.setState({ filename: value });
}

function csvReport(component, indicators, localization) {
	var filename = component.state.filename;

	var currentPerson = component.state.currentPerson.get("personal");
	var language = currentPerson.language;
	var managed = component.state[component.state.userLocalStorageKey + "listType"] === "managed";
	var userLocalStorageKey = localStorage.getItem("userLocalStorageKey");
	const currState = getLocalStorageArray(`${userLocalStorageKey}stateFilter`);
	const currManageGroups = getLocalStorageArray(`${userLocalStorageKey}manageGroups`);

	const manageGroupsFilter = currManageGroups.map(id => `manageGroups=${id}`).join("&");
	const stateFilter = currState.map(id => `state=${id}`).join("&");

	if (!filename) filename = localization.get("group_users_overview_filename_default");

	var url =
		config.host +
		"/v2/org/observations/overview/report?token=" +
		config.token +
		"&filename=" +
		encodeURIComponent(filename) +
		"&language=" +
		encodeURIComponent(language) +
		"&managed=" +
		encodeURIComponent(managed) +
		"&" +
		manageGroupsFilter +
		"&" +
		stateFilter;

	const customProperties = component.state.customProperties || [];

	const propertiesToExport = customProperties.concat(EXPORTCOLUMNS).filter((property) => {
		property = property ? property.key || property : "";
		return component.state["export" + property];
	});

	url = propertiesToExport.reduce((acc, property) => {
		const value = component.state["export" + property];
		acc += `&${property}=${encodeURIComponent(value)}`;
		return acc;
	}, url);

	url = indicators.reduce((acc, indicator) => {
		var value = component.state["export" + indicator._id];
		if (value) acc += `&indicator=${encodeURIComponent(indicator._id)}`;
		return acc;
	}, url);

	if (component.state.exportAnon) url += `&anonymize=${encodeURIComponent(component.state.exportAnon)}`;

	return (
		<Button
			key={url}
			href={url}
			download={filename}
			onClick={() => {
				component.state.userMetrics.trackEvent("group-users-overview: download CSV report");
			}}
		>
			{localization.get("report_save")}
		</Button>
	);
}

function cancelExport(component) {
	const customProperties = component.state.customProperties || [];
	const resetProperties = customProperties.reduce(
		(acc, property) => {
			property = property ? property.key || property : "";
			acc["export" + property] = false;
			return acc;
		},
		{
			filename: "",
			exporting: false,
			exportPage: 0
		}
	);
	const healthReset = EXPORTCOLUMNS.reduce((acc, property) => {
		acc["export" + property] = false;
		return acc;
	}, resetProperties);
	component.state.userMetrics.trackEvent("group-users-overview: close generate csv popup");
	component.setState(healthReset);
}

function handleOpenExport(component) {
	component.state.userMetrics.trackEvent("group-users-overview: open generate csv popup");
	component.setState({ exporting: true });
}

function handleListChange(component, e) {
	const { state } = component;
	const { value } = e.target;
	const currentType = localStorage.getItem(state.userLocalStorageKey + "listType");
	if (value !== currentType) {
		localStorage.setItem(state.userLocalStorageKey + "listType", value);
		var newState = {
			page: 0
		};
		newState[state.userLocalStorageKey + "listType"] = value;
		state.userFunc.toggleType();
		component.setState(newState);
	}
	component.state.userMetrics.trackEvent(`group-users-overview: toggle to view ${value}`);
}

function getUserStateUpdate(currentTarget, prevStates, currStates) {
	const currHasAllActive = currStates.includes("all_active");
	const prevHadAllActive = prevStates.includes("all_active");
	const currHasInactive = currStates.includes("inactive");

	const checkedActive = !prevHadAllActive && currHasAllActive;
	const uncheckedActive = prevHadAllActive && !currHasAllActive;

	let result = currHasInactive ? ["inactive"] : [];

	if (uncheckedActive) return result;
	if (checkedActive) {
		result.push("all_active");
		return result;
	}

	// if "all_active" is currently checked and an active state is clicked, then the "all_active"
	// checkbox should be unchecked, as well as the active state that was clicked.
	if (currHasAllActive && currentTarget.attributes["data-active-state"]) {
		const selectedState = currentTarget.getAttribute("data-value");
		return ACTIVE_STATES.filter(userState => userState !== selectedState).concat(result);
	}

	return currStates;
}

function handleActivityChange(component, e) {
	const { state } = component;
	const { value } = e.target;
	const userStateFilterKey = `${state.userLocalStorageKey}stateFilter`;
	const userLocalStorageKey = `${state.currentPerson.get("personal")._id}_group_users_overview`;
	const tablepage = `${userLocalStorageKey}_tablepage`;
	const storedTablepage = localStorage.getItem(tablepage);
	const prevValue = state[userStateFilterKey];
	const newUserState = getUserStateUpdate(e.currentTarget, prevValue, value);

	if(storedTablepage){
		localStorage.setItem(tablepage, 0);
	}

	component.state.userMetrics.trackEvent("group-users-overview: filter by user state", {
		"selected states": newUserState,
	});
	localStorage.setItem(userStateFilterKey, JSON.stringify(newUserState));
	var newState = {
		page: 0
	};
	newState[userStateFilterKey] = newUserState;
	state.userFunc.updateActivity(newUserState);
	component.setState(newState);
}

function handleChangeGroup(component, e) {
	const { state } = component;
	const { value } = e.target;
	const userLocalStorageKey = `${state.currentPerson.get("personal")._id}_group_users_overview`;
	const tablepage = `${userLocalStorageKey}_tablepage`;
	const storedTablepage = localStorage.getItem(tablepage);
	const patientGroupsFilterKey = `${state.userLocalStorageKey}manageGroups`;

	if(storedTablepage){
		localStorage.setItem(tablepage, 0);
	}

	component.state.userMetrics.trackEvent("group-users-overview: filter by patient group", {
		"selected groups": value,
	});
	localStorage.setItem(patientGroupsFilterKey, JSON.stringify(value));
	var newState = {
		page: 0,
		[patientGroupsFilterKey]: value,
	};
	state.userFunc.updateManageGroups(value);
	component.setState(newState);
}

function renderTotals(totals, localization) {
	const total = totals;

	return <TotalsBox {...total} localization={localization}/>;
}

function TotalsBox({total, red, yellow, green, localization}){
	return(
		<div style={styles.totalBoxContainer}>
			<InfoBox title={localization.get("group_user_overview_total")} value={total} />
			<InfoBox title={localization.get("group_user_overview_high")} value={red} color="red" />
			<InfoBox title={localization.get("group_user_overview_low")} value={yellow} color="yellow" />
			<InfoBox title={localization.get("group_user_overview_normal")} value={green} color="green" />
		</div>
	);
}

function InfoBox(props) {
	var title = props.title;
	var value = props.value;
	var color = props.color;

	var containerStyle = xtend(styles.infoBoxInfo, styles[color] || {});
	return (
		<div style={containerStyle}>
			<div style={styles.infoBoxTitle}>{title}</div>
			<div style={styles.infoBoxValue}>{value}</div>
		</div>
	);
}

/**
 * Transforms the data into the values to be displayed in cells.
 * @param {Object} options - the options object
 * @param {Object} options.localization - the localization object
 * @param {Object} options.state - the component state
 * @param {Object} options.userStore - the user store.
 */
function getDataTransformer(options) {
	const {
		localization,
		state,
		userStore,
		component
	} = options;

	const customProperties = state.customProperties || [];
	const customPropertiesTransformer = {};
	const customTransmformFn = ({ key, propertyText }) => {
		const userData = userStore.get(key);
		const cell = <TableCell key={userData._id + propertyText}>
			{(userData.customProperties || {})[propertyText]}
		</TableCell>;

		return {
			value: cell,
			isCustomCell: true,
		};
	};
	customProperties.forEach((column) => {
		const propertyText = getHeaderPropertyText(column);
		customPropertiesTransformer[propertyText] = customTransmformFn;
	});

	return {
		...customPropertiesTransformer,
		group_user_overview_user: ({ rowData, key }) => {
			const userData = userStore.get(key);
			const compliantSchedule = state.compliancy.get("schedule") || {};
			let currentCompliance = calculateCompliance(rowData, compliantSchedule);

			if(!Object.keys(compliantSchedule).length)
				currentCompliance = userData?.current_compliance;

			let name = "--";
			let patientNumber = "";
			let joinDate = "";
			if (userData) {
				patientNumber = userData.patientNumber;
				joinDate = userData.created_at
					? new Date(userData.created_at).toLocaleString(undefined, { dateStyle: "short" })
					: "";
				name = userData.lname + ", " + userData.fname;
				if (name.length > 25) name = name.slice(0, 25) + "...";
			}

			const cell = (
				<TableCell key={"user_cell_" + key} onClick={() => state.goToUser(key)}>
					<div style={styles.nameContainer}>
						{currentCompliance ? (
							<FontIcon className="fa fa-exclamation-circle fa-sm" style={styles.iconStyle} />
						) : null}
						<div style={styles.nameId}>
							<div>{name}</div>
							{patientNumber ? <div className="TableCell-date">{patientNumber}</div> : null}
							{joinDate ? (
								<div className="TableCell-date">
									{localization.get("group_users_overview_JoinDate") + " " + joinDate}
								</div>
							) : null}
						</div>
					</div>
					</TableCell>
			);
			return {
				value: cell,
				isCustomCell: true,
			};
		},
		group_users_overview_overall_status: ({ rowData, key }) => {
			const userData = userStore.get(key);
			const cell = (
				<TableCell key={"overview_cell_" + key} classes={tableCellTypes[rowData.current_status]}>
					{renderStatusWithDate({
						status: rowData.current_status,
						localization,
						state,
						updatedAt: userData.updated_at,
					})}
				</TableCell>
			);
			return {
				value: cell,
				isCustomCell: true,
			};
		},
		WorkflowStatus: function WorkflowStatus({ rowData, key }) {
			const userData = userStore.get(key);
			const cell = (
				<TableCell key={"WorkflowStatus_Cell_" + userData._id} classes={tableCellTypes[rowData.workflow_status]}>
					{renderStatusWithDate({
						status: rowData.workflow_status,
						localization,
						state,
						updatedAt: rowData.workflow_last_updated_at,
					})}
				</TableCell>
			);
			return {
				value: cell,
				isCustomCell: true,
			};
		},
		OxygenSaturation: ({ rowData, key }) => {
			const userData = userStore.get(key);
			const cell = createTableCell(
				userData._id,
				state,
				rowData.bloodoxygen,
				"bloodoxygen",
				null,
				"oxygensaturation",
				localization
			);
			return {
				value: cell,
				isCustomCell: true,
			};
		},
		HeartRate: ({ rowData, key }) => {
			const userData = userStore.get(key);
			const cell = createTableCell(userData._id, state, rowData.bloodoxygen, "bloodoxygen", null, "pulserate", localization);
			return {
				value: cell,
				isCustomCell: true,
			};
		},
		BodyTemperature: ({ rowData, temperatureUnits, key }) => {
			const userData = userStore.get(key);
			const cell = createTableCell(userData._id, state, rowData.bodytemperature, "bodytemperature", null, temperatureUnits, localization);
			return {
				value: cell,
				isCustomCell: true,
			};
		},
		BloodSugar: ({ rowData, glucometerUnits, key }) => {
			const userData = userStore.get(key);
			const cell = createTableCell(userData._id, state, rowData.bloodsugar, "bloodsugar", null, glucometerUnits, localization);
			return {
				value: cell,
				isCustomCell: true,
			};
		},
		Weight: ({ rowData, weightUnits, key }) => {
			const userData = userStore.get(key);
			const cell = createTableCell(userData._id, state, rowData.weight, "weight", null, weightUnits, localization);
			return {
				value: cell,
				isCustomCell: true,
			};
		},
		BloodPressure: ({ rowData, key }) => {
			const userData = userStore.get(key);
			const cell = createTableCell(userData._id, state, rowData.bloodpressure, "bloodpressure", null, "systolic", localization);
			return {
				value: cell,
				isCustomCell: true,
			};
		},
		BloodPressurePulserate: ({ rowData, key }) => {
			const userData = userStore.get(key);
			const cell = createTableCell(
				userData._id,
				state,
				rowData.bloodpressure,
				"bloodpressure",
				null,
				"bpPulserate",
				localization
			);
			return {
				value: cell,
				isCustomCell: true,
			};
		},
		Steps: ({ rowData, key }) => {
			const userData = userStore.get(key);
			const cell = createTableCell(userData._id, state, rowData.activity, "activity", null, "cumulative", localization);

			return {
				value: cell,
				isCustomCell: true,
			};
		},
		Notes: function Notes({ rowData, key }) {
			const userData = userStore.get(key);
			const latestNote = Array.isArray(rowData.note) ? rowData.note[0] : rowData.note;

			const cell = (
				<TableCell key={"Note_Cell_" + userData._id} onClick={() => state.goToUser(userData._id)} style={{whiteSpace: "nowrap"}}>
					<NotePreview note={latestNote} component={component} />
				</TableCell>
			);
			return {
				value: cell,
				isCustomCell: true,
			};
		},
		indicatorTransformer: ({ rowData, propertyText, key }) => {
			const userData = userStore.get(key);
			const customIndicators = rowData?.customIndicators ?? {};
			const indicatorValue = customIndicators?.[propertyText];

			const cell = (
				<TableCell key={userData._id + propertyText}>
					<Typography style={styles.indicatorText}>{indicatorValue?.history?.value ?? "-"}</Typography>
					<div className="TableCell-date">{indicatorValue ? formatDate(state, (indicatorValue || {}).updated_at) : ""}</div>
				</TableCell>
			);
			return {
				value: cell,
				isCustomCell: true,
			};
		},
		followedUp: ({ propertyText, key }) => {
			const userData = userStore.get(key);

			if(userData.followedUp){
				state.followedUp.push(userData._id);
			}

			const cell = (
				<TableCell key={userData._id + propertyText}>
					<div className="TableCell-date">
					<Checkbox
						checked={state.followedUp.includes(userData._id)}
						disabled={state.followedUp.includes(userData._id)}
						onChange={(e) => {
							state.setCheck(userData._id, e.target.checked);

							const followedUp = [...state.followedUp, userData._id];
							component.setState({followedUp: followedUp});
						}}
					/>
					</div>
				</TableCell>
			);
			return {
				value: cell,
				isCustomCell: true,
			};
		}
	};
}

function calculateCompliance(readings, compliantSchedule) {
	return Object.keys(INDICATOR_MAP).reduce(function(acc, key) {
		var schedule = compliantSchedule[key];
		if (!schedule) return acc;

		var reading = readings[key];
		if (!reading) return true;

		var updated_at = reading.updated_at;
		var daysSince = calculateDays(updated_at);

		acc = daysSince > schedule;
		return acc;
	}, false);
}

function renderBloodPressure(bp) {
	if (bp === "/") return "";
	return bp;
}

function parseStatus(status, localization) {
	if (status === 2) {
		return localization.get("group_user_overview_high");
	}
	if (status === 1) {
		return localization.get("group_user_overview_low");
	}
	if (status === 0) {
		return localization.get("group_user_overview_normal");
	}

	return "--";
}

function renderStatusWithDate({ status, localization, state, updatedAt }) {
	status = parseStatus(status, localization);
	return (
		<>
			<Typography style={styles.indicatorTextSmall}>{status}</Typography>
			<div className="TableCell-date">{formatDate(state, updatedAt)}</div>
		</>
	);
}

function formatDate(state, date) {
	if (!date) return "";
	const options = {
		year: "numeric",
		month: "short",
		day: "numeric",
		hour: "numeric",
		minute: "numeric",
		second: "numeric",
		hour12: false
	};
	var language = state.currentPerson.get("personal").language || "en";
	if (language === "cn_s") language = "zh-CN";
	else if (language === "cn_t") language = "zh-HK";
	return new Date(date).toLocaleDateString(language, options);
}

function getStatus(reading, type, units) {
	reading = reading || {};
	if (type === "bloodoxygen") {
		if (units === "oxygensaturation") {
			return tableCellTypes[reading.oxygenSaturationStatus];
		} else {
			return tableCellTypes[reading.heartRateStatus];
		}
	} else if (type === "bloodpressure" && units === "bpPulserate") {
		return tableCellTypes[reading["bpPulserate"] ? reading.status : -1];
	} else {
		return tableCellTypes[reading.status];
	}
}

function getStatusReading (reading = {}, type, units, localization){

	if(reading){
		if (type === "bloodoxygen") {
			if (units === "oxygensaturation") {
				return parseStatus(reading?.oxygenSaturationStatus, localization);
			} else {
				return parseStatus(reading?.heartRateStatus, localization);
			}
		} else if (type === "bloodpressure" && units === "bpPulserate") {
			return parseStatus(reading["bpPulserate"] ? reading.status : -1, localization);
		} else {
			return parseStatus(reading?.status, localization);
		}
	} else return "--";

}

function createTableCell(userID, state, reading, type, compliant, units, localization) {
	var compliantSchedule = state.compliancy.get("schedule") || {};
	var indicatorSchedule = compliantSchedule[type];

	var checked = checkReading(type, reading, units);

	var updatedAt = (reading || {}).updated_at;
	var daysSinceRead = calculateDays(updatedAt);
	var showDays = daysSinceRead > indicatorSchedule;
	const invalid = reading ? reading.invalid : false;

	return (
		<TableCell
			key={type + units + "_cell_" + userID}
			classes={invalid ? "" : getStatus(reading, type, units)}
			className="MuiTableCell-root-237"
		>
			<Typography style={{...styles.indicatorText, textDecoration: invalid ? "line-through" : ""}}>{checked || "--"}</Typography>
			<Typography style={styles.indicatorTextSmall}>{getStatusReading(reading, type, units, localization)}</Typography>
			<div className="TableCell-date">{checked ? formatDate(state, (reading || {}).updated_at) : ""}</div>
			{showDays && checked ? <div className="TableCell-date">{daysSinceRead + " days ago"}</div> : null}
		</TableCell>
	);
}

function checkReading(type, reading, units) {
	var formatted = INDICATOR_MAP[type][units];
	if (type === "bloodpressure" && units === "systolic") return renderBloodPressure(format(formatted, reading));
	return format(formatted, reading);
}

function calculateDays(date) {
	if (!date) return;
	var last = new Date(date);
	var today = new Date();

	var days = Math.round((today.getTime() - last.getTime()) / ONE_DAY);
	return days;
}

function NotePreview({note, component}) {
	const [peopleMap, setPeopleMap] = useState({});
	const api = useApi();
	useEffect(() => {
		let peopleSet = new Set();
		note && note.edited.forEach(id => {
			peopleSet.add(id);
		});
		let peopleToFetch = Array.from(peopleSet);

		peopleToFetch.length && api.people.public(peopleToFetch).then(res => {
			let peopleList = res;
			//convert arr to map
			let peopleObj = peopleList.reduce(
				(obj, person) => ((obj[person._id] = person), obj),
				{}
			);
			setPeopleMap(peopleObj);
		});
	}, [note]);
	var state = component.state;

	if(!note){
		return "--";
	}

	var title = null;
	var body = null;

	let lastPersonToEdit = peopleMap[note.edited[note.edited.length - 1]];

	var editedBy =
		(lastPersonToEdit && state.localization.localize("groupforms.edit_by", {
			fname: lastPersonToEdit.fname,
			lname: lastPersonToEdit.lname
		})) || "";

	if(Array.isArray(note)) {
		if(note.length > 0){
			let titleText = sizeNote(note[0].data?.title);
			title = <div>{titleText}</div>;
			let bodyText = sizeNote(note[0].data?.body);
			body = <div>{bodyText}</div>;
		} else if(note.length === 0){
			return "--";
		}
	}

	if (note.data && note.data.title) {
		let titleText = sizeNote(note.data.title);
		title = <div>{titleText}</div>;
	}
	if (note.data && note.data.body) {
		let bodyText = sizeNote(note.data.body);
		body = <div>{bodyText}</div>;
	}

	function onTooltipClick(e) {
		e.stopPropagation();
		e.preventDefault();
		component.setState({
			viewing: {
				note,
				editedBy
			}
		});
		component.state.userMetrics.trackEvent("group-users-overview: open note preview", {
			note: note._id,
		});
	}
	return (
		<div style={styles.notePreview}>
			<div>
				{title}
				{body}
			</div>
			<IconButton style={styles.infoButton} color="primay" size="small" aria-label={"tooltip_edit"} role="button" className="fa fa-info" onClick={onTooltipClick} />
		</div>
	);
}

function sizeNote(text) {
	if (text?.length > MAX_TEXT_SIZE) return text.slice(0, MAX_TEXT_SIZE) + "...";
	return text;
}

function handleUserSearch(component, e) {
	e.preventDefault();

	var state = component.state;
	var userSearchExist = state.userSearchExist;
	var query = e.target.value;
	const userLocalStorageKey = `${state.currentPerson.get("personal")._id}_group_users_overview`;
	const tablepage = `${userLocalStorageKey}_tablepage`;
	const storedTablepage = localStorage.getItem(tablepage);

	if(storedTablepage){
		localStorage.setItem(tablepage, 0);
	}
	userSearchExist(query);
	component.setState({
		query: query,
		page: 0
	});
}
