/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

import par from "par";

import defineUI from "../shared/define";
import render from "./render.jsx";
import ResourceStore from "../stores/org_resources_store";
import CurrentPersonInfoStore from "../stores/current_person_info_store";
import LocalizationStore from "../stores/localization_store";
import ConnectionStore from "../stores/connection_store";
import CareplanNotificationStore from "../stores/careplan_notice_store";
import makeStoreFactory from "../shared/stores";

const makeStores = makeStoreFactory({
	resources: ResourceStore,
	currentPerson: CurrentPersonInfoStore,
	localization: LocalizationStore,
	connection: ConnectionStore,
	careplanChangeList: CareplanNotificationStore
});

export default make_ui;

function make_ui(api, events, config, userMetrics){
	var storeStore = makeStores(api, events, config);

	window.stores =  storeStore.store;

	userMetrics.trackEvent("page view", {
		"page": "org-resources",
		"category": "org",
		"permission level": "org:manager",
	});

	return defineUI({
		getInitialState: getInitialState,
		componentDidMount: listen_for_changes,
		render: render,
		componentWillUnmount: removeListeners
	});

	function removeListeners() {
		events.off();
	}

	function listen_for_changes(){
		storeStore.listen(par(handle_change, this));
	}

	function getInitialState(){
		return {
			resources: storeStore.stores.resources,
			currentPerson: storeStore.stores.currentPerson,
			localization: storeStore.stores.localization,
			connection: storeStore.stores.connection,
			careplanChangeList: storeStore.stores.careplanChangeList,
			updating: false,
			creating: false,
			addResource: addResource,
			updateResource: updateResource,
			removeResource: removeResource,
			language: "en",
			userMetrics: userMetrics,
		};
	}

	function handle_change(component, stores){
		component.setState({
			resources: stores.resources.all(),
			currentPerson: stores.currentPerson,
			localization: stores.localization,
			connection: stores.connection,
			careplanChangeList: stores.careplanChangeList
		});
	}

	function addResource(name, data){
		if(!name || !data) return;
		return api.org.resources.create(name, data);
	}

	function updateResource(name, data, resource){
		if(!resource || !name || !data) return;
		return api.org.resources.update(resource, name, data);
	}

	function removeResource(resource){
		if(!resource) return;
		return api.org.resources.remove(resource);
	}
}