/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */



import Store from "./store";
import map from "fj-map";
import par from "par";

export default OrgStore;

function OrgStore(api, events) {
	var store = new Store();

	api.admin.org.list().then(map(add_org));
	events.on("mqtt:orgs/created", add_org);

	var query = "";
	var page = 0;
	var loading = null;
	var atEnd = false;

	store.more = more;
	store.search = searchOrg;

	reset();

	return store;

	function reset() {
		page = 0;
		atEnd = false;
		store.clear();
		return api.admin.org.list(query, page)
			.then(handle_results);
	}

	function handle_results(orgs) {
		if (!orgs.length) {
			atEnd = true;
			return;
		}
		orgs.map(add_org);
		loading = null;
	}

	function more() {
		if (atEnd)
			return Promise.resolve();
		if (loading)
			return loading;
		page += 1;
		api.admin.org.list(query, page).then(map(add_org));
	}

	function searchOrg(newQuery) {
		query = newQuery;
		return reset();
	}

	function add_org(org) {
		var id = org._id;
		store.set(id, org);

		events.on("mqtt:org/" + id + "/updated", par(update_org, id));
		events.on("mqtt:org/" + id + "/administrator/added", par(setAdministrator, id));
		events.on("mqtt:org/" + id + "/administrator/removed", par(removeAdministrator, id));
	}

	function setAdministrator(org, data) {
		const current = store.get(org);
		if(!current) return;
		var who = data.who;
		store.update(org, {
			administrator: who
		});
	}

	function removeAdministrator(org) {
		const organization = store.get(org);
		if(organization === undefined) {
			return;
		}
		store.update(org, {
			administrator: null
		});
	}

	function update_org(org, data) {
		const current = store.get(org);
		if(!current) return;
		store.update(org, data);
	}
}
