/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, May 3, 2018
 * For information or permission request, email info@aetonixsystems.com
 */

import par from "par";

import debounce from "debounce";
import defineUI from "../shared/define";
import render from "./render.jsx";
import PersonStore from "../stores/person_store";
import LocalizationStore from "../stores/localization_store";
import CurrentPersonInfoStore from "../stores/current_person_info_store";
import CareplanNotificationStore from "../stores/careplan_notice_store";
import ConnectionStore from "../stores/connection_store";
import GroupOptionStore from "../stores/group_options_store";
import GroupOptionSchemaStore from "../stores/group_option_schema_store";
import makeStoreFactory from "../shared/stores";

const makeStores = makeStoreFactory({
	people: PersonStore,
	currentPerson: CurrentPersonInfoStore,
	localization: LocalizationStore,
	careplanChangeList: CareplanNotificationStore,
	connection: ConnectionStore,
	options: GroupOptionStore,
	optionSchemas: GroupOptionSchemaStore
});

export default make_ui;

function make_ui(api, events, config, userMetrics) {
	var storeStore = makeStores(api, events, config);

	window.stores = storeStore.store;

	userMetrics.trackEvent("page view", {
		"page": "group-options",
		"category": "group",
		"permission level": "staff",
	});

	return defineUI({
		getInitialState: getInitialState,
		componentDidMount: listen_for_changes,
		render: render,
		componentWillUnmount: removeListeners
	});

	function removeListeners() {
		events.off();
	}

	function listen_for_changes() {
		storeStore.listen(par(handle_change, this));
	}

	function getInitialState() {
		return {
			people: storeStore.stores.people,
			currentPerson: storeStore.stores.currentPerson,
			localization: storeStore.stores.localization,
			careplanChangeList: storeStore.stores.careplanChangeList,
			connection: storeStore.stores.connection,
			options: storeStore.stores.options,
			optionSchemas: storeStore.stores.optionSchemas,
			viewing: "",
			filteredOptions: [],
			newOption: {},
			addOption: addOption,
			updateOption: updateOption,
			removeOption: removeOption,
			searchOptions: debounce(searchOptions.bind(this), 600),
			userMetrics: userMetrics,
		};
	}

	function handle_change(component, stores) {
		component.setState({
			people: stores.people,
			currentPerson: stores.currentPerson,
			localization: stores.localization,
			careplanChangeList: stores.careplanChangeList,
			connection: stores.connection,
			options: stores.options,
			optionSchemas: stores.optionSchemas
		});
	}

	function addOption(schema, data) {
		if (!schema || !data) return console.error("Missing required fields");
		return api.org.options.addOption(schema, data);
	}

	function updateOption(option, data) {
		if (!option || !data) return console.error("Missing required fields");
		return api.org.options.updateOption(option, data);
	}

	function removeOption(option) {
		if (!option) return console.error("Missing required fields");
		return api.org.options.removeOption(option);
	}

	async function searchOptions(schema, fields, search, page) {
		const filteredOptions = search ? await api.org.options.searchOption(schema, fields, search, page) : this.state.options.all();

		this.setState({
			filteredOptions: filteredOptions,
			loading: false
		});
	}
}
