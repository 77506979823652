import React, { Fragment, useState } from "react";
import { PermissionsForm } from "./PermissionForm";
import { TextField } from "@material-ui/1.5.1";
import { useLocalize } from "../shared/contexts/LocalizeContext";
const PresetPermissionForm = ({ onSubmit, name = "", ...props }) => {
	const [presetName, setPresetName] = useState(name);
	const [nameError, setNameError] = useState("");
	const localize = useLocalize();
	const handleSubmit = permissions => {
		if (presetName)
			onSubmit &&
				onSubmit({
					name: presetName,
					permissions
				});
		else {
			setNameError(localize("preset_permission.no_name_error"));
		}
	};

	const handleNameChange = e => {
		setPresetName(e.target.value);
		setNameError("");
	};
	return (
		<Fragment>
			<TextField
				error={nameError}
				helperText={nameError}
				value={presetName}
				InputProps={{"aria-label": localize("preset_permission.preset_name"), "role": "textbox"}}
				onChange={handleNameChange}
				fullWidth
				label={localize("preset_permission.preset_name")}
				inputProps={{maxlength: 50}}
			/>
			<PermissionsForm {...props} onSubmit={handleSubmit} />
		</Fragment>
	);
};

export default PresetPermissionForm;
