/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */


import Store from "./store";
import map from "fj-map";

export default AdminPromoCodeStore;

function AdminPromoCodeStore(api, events) {
	var store = new Store();
	reset();
	return store;

	function reset(){
		api.admin.code.list()
			.then(map(handle_code_created))
			.then(track);
	}

	function track(){
		events.on("mqtt:admin/code/promo/created", handle_code_created.bind(this));
		events.on("mqtt:admin/code/promo/removed", handle_code_removed.bind(this));
	}

	function handle_code_created(code){
		store.set(code._id, code);
	}

	function handle_code_removed(id){
		store.remove(id);
	}
}
