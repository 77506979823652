import React from "react";
import TableCell from "@material-ui/1.5.1/TableCell";
import TableHead from "@material-ui/1.5.1/TableHead";
import TableRow from "@material-ui/1.5.1/TableRow";

export function HealthTableHeader({ headers }) {
	return (
		<TableHead>
			<TableRow>
				{headers.map(column => (
					<TableCell key={column.content} className="flex-spread">
						{column.content}
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}