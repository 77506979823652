
/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";

import par from "par";
import config from "../../configs/config.json";
import Header from "../shared/Header.js";
import IconButton from "@material-ui/1.5.1/IconButton";
import Avatar from "@material-ui/1.5.1/Avatar";
import List from "@material-ui/1.5.1/List";
import ListItem from "@material-ui/1.5.1/ListItem";
import ListItemText from "@material-ui/1.5.1/ListItemText";
import Toggle from "@material-ui/1.5.1/Switch";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Snackbar from "@material-ui/core/Snackbar";
import Dialog from "../shared/Dialog.js";
import Button from "@material-ui/1.5.1/Button";
import TextField from "@material-ui/1.5.1/TextField";
import Utility from "../shared/Utility.js";
import LazyList from "../shared/LazyList.jsx";
import  AetonixTheme_mui_1_5_1 from "../shared/AetonixTheme_mui_1.5.1";
const Colors = AetonixTheme_mui_1_5_1.palette;
var styles = {
	search: {
		color: Colors.primary.main,
		minWidth: "30%",
		padding: 8
	},

	input: {
		color: Colors.primary.main,
		padding: 8
	},
	inputProp: {style: {color: Colors.primary.main, }},
	toast: {
		margin: "80px",
		padding: "10px",
		backgroundColor: "rgb(0, 172, 193)",
		borderRadius: "10px"
	},
	toastText: {
		color: Colors.canvas,
		margin: "5px"
	},
};

export default render;

function render() {
	var component = this;
	var state = component.state;

	var searching = state.searching;
	var removing = state.removing;
	var managers = state.managers;
	var people = state.people;
	var toRemove = state.toRemove;
	var removeManager = state.removeManager;
	var autologout = state.logout.get("autologout");
	var multifactor = state.multifactor.get("multifactor");
	var managerToAdd = state.addManager;
	var searchNew = state.searchNew;
	var searchResults = state.searchResults;
	var localization = state.localization;
	var careplanNoticeList = state.careplanChangeList.all();
	var careplanChanges = !!careplanNoticeList.length;
	var offline = state.connection.get("offline");
	var currentPerson = state.currentPerson;
	var searchExist = state.searchExist;
	var sortedManagers = managers.map(people.get).sort(by_name);
	const org = currentPerson.get("org");
	const dataRetention = org?.dataRetention || -1;

	var loadMore = state.loadMore;
	var titleKey = "org_managers_title";

	var removal_actions = [
		(<Button key={"buttonok"} onClick={par(confirmRemove, component, removeManager)}>{localization.get("generic_ok")}</Button >),
		(<Button key={"buttoncancel"} onClick={par(hideRemove, component)}>{localization.get("org_managers_cancel")}</Button >),
	];

	var search_actions = [
		(<Button key={"buttoncancel"} onClick={par(hideSearch, component)}>{localization.get("org_managers_cancel")}</Button >)
	];

	var renderManagerListItem = par(renderManager, component, par(showUserRemoval, component));

	return (
		<div className="flex-vertical flex-1">

			<Header
				careplanChanges={careplanChanges}
				offline={offline}
				currentPerson={currentPerson}
				localization={localization}
				titleKey={titleKey}
			/>
			<div className="ae-left-margin">
				<TextField placeholder={localization.get("search_onName")} inputProps={{"aria-label": localization.get("search_onName")}} onChange={searchExist} style={styles.search} InputProps={styles.inputProp} />
			</div>
			<div className="flex-horizontal ae-left-margin ae-createSubtitles">
				<div className="ae-padright">{localization.get("org_data_retention")}</div>
				<Select key={"data_retention_setting"} value={dataRetention} onChange={par(updateDataRetention, this)} inputProps={{
					"aria-label": localization.get("org_data_retention"),
				}}>
					{[(
						<MenuItem value={-1}>None</MenuItem>
					)].concat([...Array(31).keys()].map(key => (
						<MenuItem value={key}>{key == 1 ? localization.localize("org_data_year", {year: key}) : localization.localize("org_data_years", {year: key})}</MenuItem>
					)))}
				</Select>
			</div>
			<div className="flex-horizontal ae-left-margin ae-createSubtitles">
				<div className="ae-padright">{localization.get("org_staff_autologout")} : </div>
				<div className="ae-padright">{localization.get("org_manager_off")}</div>
				<Toggle color="primary" defaultChecked={autologout} key={`autologout_${autologout}`} onChange={par(onToggle, this, "autologout")} inputProps={{
					"aria-label": localization.get("org_staff_autologout"),
					"role": "button"
				}} />
				<div className="ae-padleft">{localization.get("org_manager_on")}</div>
			</div>
			<div className="flex-horizontal ae-left-margin ae-createSubtitles">
				<div className="ae-padright">{localization.get("org_staff_multifactor")} : </div>
				<div className="ae-padright">{localization.get("org_manager_off")}</div>
				<Toggle color="primary" defaultChecked={multifactor} key={`multifactor_${multifactor}`} onChange={par(onToggle, this, "multifactor")} inputProps={{
					"aria-label": localization.get("org_staff_multifactor"),
					"role": "button"
				}} />
				<div className="ae-padleft">{localization.get("org_manager_on")}</div>
			</div>
			<div className="flex-vertical flex-1 ae-scrollable">
				<LazyList loadMore={loadMore} renderItem={renderManagerListItem} items={sortedManagers} />
			</div>
			<Button variant="raised" onClick={par(showSearch, component)} color="secondary" >{localization.get("org_managers_add")}</Button>

			<Dialog actions={removal_actions} open={removing} title={localization.get("org_managers_remove")}>
				{localization.get("org_managers_warning")}
				{Utility.format_name(toRemove)}?
			</Dialog>

			<Dialog actions={search_actions} open={searching} title={localization.get("org_managers_add")}>
				<TextField placeholder={localization.get("generic_name")} inputProps={{"aria-label": localization.get("generic_name")}}  onChange={par(doSearch, searchNew)} InputProps={styles.inputProp} />
				<List>
					{searchResults.sort(by_name).map(par(renderSearchItem, managerToAdd, component))}
				</List>
			</Dialog>

			<Snackbar open={state.showToast} autoHideDuration={3500} onClose={par(hideToast, component)} onClick={par(hideToast, component)}>
				<div className="flex-horizontal ae-dropshadow" style={styles.toast}>
					<div style={styles.toastText}>
						{localization.get("org_settings_saved") || ""}
					</div>
				</div>
			</Snackbar>

		</div>
	);
}



function by_name(x, y) {
	var X = x.lname.toLowerCase();
	var Y = y.lname.toLowerCase();
	if (X === Y) {
		X = x.fname.toLowerCase();
		Y = y.fname.toLowerCase();
	}

	if (X < Y) return -1;
	if (X > Y) return 1;
	return 0;
}

function showSearch(component) {
	component.setState({searching: true});
	component.state.userMetrics.trackEvent("org-managers: open add manager popup");
}

function hideSearch(component) {
	component.setState({
		searchResults: [],
		searching: false
	});
	component.state.userMetrics.trackEvent("org-managers: close add manager popup");
}

function doSearch(search, e) {
	var query = e.target.value;
	search(query);
}

function renderSearchItem(add, component, person) {
	var avatar = <Avatar alt="Manager Avatar Icon" src={config.image_cdn + person.image} />;
	var text = Utility.format_name(person);
	var clickAction = par(addManager, par(add, person._id), component);
	return (
		<ListItem onClick={clickAction}>
			{avatar}
			<ListItemText primary={text} />
		</ListItem>
	);
}

function addManager(add, component) {
	add();
	hideSearch(component);
	component.state.userMetrics.trackEvent("org-managers: add manager", {
		manager: add._id
	});
}

function renderManager(component, remove, manager) {
	if (!manager._id) return;
	var avatar = <Avatar alt="Managers Avatar Image" src={config.image_cdn + manager.image} />;
	var text = Utility.format_name(manager);
	var removeButton = renderRemove(par(remove, manager), component);
	var managerId = manager._id;
	var background_colour = "ae-plain";
	var viewing = component.state.viewing;
	if (viewing && managerId === viewing._id) background_colour = "ae-hover-color";

	return (
		<ListItem key={managerId} className={background_colour} onClick={par(set_viewing, component, manager)}>
			{avatar}
			<ListItemText primary={text} />
			{removeButton}
		</ListItem>
	);
}

function set_viewing(component, manager) {
	component.setState({viewing: manager});
	component.state.userMetrics.trackEvent("org-managers: view manager", {
		manager: manager._id
	});
}

function renderRemove(remove, component) {
	var localization = component.state.localization;
	return (
		<IconButton key={"buttonremove"} aria-label={localization.get("managers_remove")} title={localization.get("managers_remove")} role="button" color="primary" className="fa fa-user-times" onClick={remove} />
	);
}

function showUserRemoval(component, person) {
	component.setState({
		toRemove: person,
		removing: true
	});
	component.state.userMetrics.trackEvent("org-managers: open remove manager popup", {
		manager: person._id,
	});
}

function confirmRemove(component) {
	var id = component.state.toRemove._id;
	component.state.removeManager(id);
	hideRemove(component);
	component.state.userMetrics.trackEvent("org-managers: remove manager", {
		manager: id
	});
}

function hideRemove(component) {
	component.setState({removing: false});
	component.state.userMetrics.trackEvent("org-managers: close remove manager popup");
}

function onToggle(component, eventName, event, toggle){
	let eventHandler = "";
	if (eventName === "autologout") {
		eventHandler = "setAutoLogout";
	} else if (eventName === "multifactor") {
		eventHandler = "setMultifactor";
	}
	component.state[eventHandler](toggle);

	const userMetricsEventName = {
		"autoLogout": "auto logout",
		"multifactor": "multifactor"
	}[eventName] || eventName;
	component.state.userMetrics.trackEvent(`org-managers: toggle ${userMetricsEventName} policy`, {
		"value": toggle ? "on" : "off",
	});
}

function updateDataRetention(component, event){
	const newValue = event.target.value;
	component.state.updateRetention(component, newValue);
	component.state.userMetrics.trackEvent("org-managers: edit data retention policy", {
		dataRetention: newValue
	});
}

function hideToast(component){
	component.setState({
		showToast: false
	});
	component.state.userMetrics.trackEvent("org-managers: close toast");
}