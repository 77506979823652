/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */



import Store from "./store";
import later from "call-later";
import map from "fj-map";
import flatten from "array-flatten";
import Promise from "promise";

import * as Sentry from "@sentry/react";

var MAX_AT_ONCE = 300;

export default PersonStore;

function PersonStore(api, events) {
	var store = new Store();

	store.raw_get = store.get;
	store.get = get;

	store.track = later(track_all);
	store.tracking = is_tracking;

	store.fetch = later(fetch_all_data);

	var tracking = [];

	return store;

	function get(id) {
		var data = store.raw_get(id);
		if (data) return data;

		if (!store.tracking(id))
			store.track(id);

		return {
			fname: " ... ",
			lname: "",
			type: ["loading"]
		};
	}

	function fetch_all_data(buffer) {
		if (!buffer || buffer.length === 0 || buffer[0][0] === null) return;
		var ids = flatten(buffer);

		var chunks = [];
		var total = ids.length;
		var group = 0;
		while (group < total) {
			chunks.push(ids.slice(group, group + MAX_AT_ONCE));
			group += MAX_AT_ONCE;
		}

		Promise
			.all(chunks.map(function(chunk) {
				return api.admin.user.data(chunk);
			}))
			.then(flatten)
			.then(map(set_person))
			.catch(error => {
				Sentry.captureException(error);
				console.error.bind(console, "Couldn't fetch people's data");
			});
	}

	function set_person(person) {
		var id = person._id;
		store.set(id, person);
	}

	function track_all(buffer) {
		var ids = flatten(buffer);
		ids.map(track_person);
	}

	function track_person(id) {
		if (store.tracking(id))
			return;

		tracking.push(id);
		events.on(`mqtt:u/${id}/settings`, (data) => {
			fetch_person;
			updatePreferences(data);
		});
		events.on(`mqtt:u/${id}/type/changed`, updatePreferences);
		events.on(`mqtt:u/${id}/app/unlocked`, handle_unlocked);
		events.on(`mqtt:u/${id}/app/locked`, handle_locked);

		fetch_person();

		function fetch_person() {
			store.fetch(id);
		}

		function handle_unlocked(){
			var current = store.raw_get(id);
			current.applocked = false;
			store.set(id, current);
		}

		function handle_locked(){
			var current = store.raw_get(id);
			current.applocked = true;
			store.set(id, current);
		}

		function updatePreferences(data) {
			store.update(id, data);
		}
	}

	function is_tracking(id) {
		return tracking.indexOf(id) !== -1;
	}
}
