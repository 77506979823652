import React from "react";
import { HealthTableCell } from "./HealthTableCell";
import { useUserMetrics } from "@aetonix/user-metrics";

export function getDataTransformer(localization, component) {
	const { userMetrics } = useUserMetrics();
	return {
		date: ({ key }) => ({
			value: localization.get(key) || key
		}),
		oxygensat: ({ rowData }) => {
			const value = rowData?.oxygensaturation?.history?.oxygensaturation;
			const isInvalid = rowData?.oxygensaturation?.invalid;

			const element = value ? (
				<HealthTableCell value={value} strikethrough={isInvalid} onClick={() => {
					component.setState({
						invalidRecord: rowData?.oxygensaturation,
						recordInvalid: rowData?.oxygensaturation?.invalid,
						recordNote: rowData?.oxygensaturation?.notes
					});
					userMetrics.trackEvent("user-overview: open record notes popup");
				}}
				status= {isInvalid ? "" : rowData?.oxygensaturation?.history?.oxygenSaturationStatus}
				/>
			) : "--";

			return {
				value: element,
				cellStatusColor: isInvalid ? "" : rowData?.oxygensaturation?.history?.oxygenSaturationStatus
			};
		},
		pulserate: ({ rowData }) => {
			const value = rowData?.pulserate?.history?.pulserate;
			const isInvalid = rowData?.pulserate?.invalid;

			const element = value ? (
				<HealthTableCell value={value} strikethrough={isInvalid} onClick={() => {
					component.setState({
						invalidRecord: rowData?.pulserate,
						recordInvalid: rowData?.pulserate?.invalid,
						recordNote: rowData?.pulserate?.notes
					});
					userMetrics.trackEvent("user-overview: open record notes popup");
				}}
				status={isInvalid ? "" : rowData?.pulserate?.history?.heartRateStatus}
				/>
			) : "--";

			return {
				value: element,
				cellStatusColor: isInvalid ? "" : rowData?.pulserate?.history?.heartRateStatus
			};
		},
		bodytemperature: ({ rowData, temperatureUnits }) => {
			const value = rowData?.bodytemperature?.history?.[temperatureUnits];
			const isInvalid = rowData?.bodytemperature?.invalid;

			const element = value ? (
				<HealthTableCell value={value} strikethrough={isInvalid} onClick={() => {
					component.setState({
						invalidRecord: rowData?.bodytemperature,
						recordInvalid: rowData?.bodytemperature?.invalid,
						recordNote: rowData?.bodytemperature?.notes
					});
					userMetrics.trackEvent("user-overview: open record notes popup");
				}}
				status={isInvalid ? "" : rowData?.bodytemperature?.history?.status}
				/>
			) : "--";

			return {
				value: element,
				cellStatusColor: isInvalid ? "" : rowData?.bodytemperature?.history?.status
			};
		},
		bloodsugar: ({ rowData, glucometerUnits }) => {
			const value = rowData?.bloodsugar?.history?.[glucometerUnits];
			const isInvalid = rowData?.bloodsugar?.invalid;

			const element = value ? (
				<HealthTableCell value={value} strikethrough={isInvalid} onClick={() => {
					component.setState({
						invalidRecord: rowData?.bloodsugar,
						recordInvalid: rowData?.bloodsugar?.invalid,
						recordNote: rowData?.bloodsugar?.notes
					});
					userMetrics.trackEvent("user-overview: open record notes popup");
				}}
				status={isInvalid ? "" : rowData?.bloodsugar?.history?.status}
				/>
			) : "--";

			return {
				value: element,
				cellStatusColor: isInvalid ? "" : rowData?.bloodsugar?.history?.status
			};
		},
		weight: ({ rowData, weightUnits }) => {
			const value = rowData?.weight?.history?.[weightUnits];
			const isInvalid = rowData?.weight?.invalid;

			const element = value ? (
				<HealthTableCell value={value} strikethrough={isInvalid} onClick={() => {
					component.setState({
						invalidRecord: rowData?.weight,
						recordInvalid: rowData?.weight?.invalid,
						recordNote: rowData?.weight?.notes
					});
					userMetrics.trackEvent("user-overview: open record notes popup");
				}}
				status={isInvalid ? "" : rowData?.weight?.history?.status}
				/>
			) : "--";

			return {
				value: element,
				cellStatusColor: isInvalid ? "" : rowData?.weight?.history?.status
			};
		},
		bloodpressure: ({ rowData }) => {
			const systolic = rowData?.bloodpressure?.history?.systolic;
			const diastolic = rowData?.bloodpressure?.history?.diastolic;
			const isInvalid = rowData?.bloodpressure?.invalid;

			const element = systolic && diastolic ? (
				<HealthTableCell value={`${systolic}/${diastolic}`} strikethrough={isInvalid} onClick={() => {
					component.setState({
						invalidRecord: rowData?.bloodpressure,
						recordInvalid: rowData?.bloodpressure?.invalid,
						recordNote: rowData?.bloodpressure?.notes
					});
					userMetrics.trackEvent("user-overview: open record notes popup");
				}}
				status={isInvalid ? "" : rowData?.bloodpressure?.history?.status}
				/>
			) : "--";

			return {
				value: element,
				cellStatusColor: isInvalid ? "" : rowData?.bloodpressure?.history?.status
			};
		},
		bloodpressure_pulserate: ({ rowData }) => {
			const value = rowData?.bpPulserate?.history?.bpPulserate;
			const isInvalid = rowData?.bpPulserate?.invalid;

			const element = value ? (
				<HealthTableCell value={value} strikethrough={isInvalid} onClick={() => {
					component.setState({
						invalidRecord: rowData?.bpPulserate,
						recordInvalid: rowData?.bpPulserate?.invalid,
						recordNote: rowData?.bpPulserate?.notes
					});
					userMetrics.trackEvent("user-overview: open record notes popup");
				}}
				status={isInvalid ? "" : rowData?.bpPulserate?.history?.bpPulserateStatus}
				/>
			) : "--";

			return {
				value: element,
				cellStatusColor: isInvalid ? "" : rowData?.bpPulserate?.history?.bpPulserateStatus
			};
		},
		steps: ({ rowData }) => {
			const value = rowData?.activity?.cumulative;
			const isInvalid = rowData?.activity?.invalid;

			const element = value ? (
				<HealthTableCell value={value} strikethrough={isInvalid} onClick={() => {
					component.setState({
						invalidRecord: rowData?.activity,
						recordInvalid: rowData?.activity?.invalid,
						recordNote: rowData?.activity?.notes
					});
					userMetrics.trackEvent("user-overview: open record notes popup");
				}}
				status={isInvalid ? "" : rowData?.activity?.status}
				/>
			) : "--";

			return {
				value: element,
				cellStatusColor: isInvalid ? "" : rowData?.activity?.status
			};
		},
		indicatorTransformer: ({ rowData, column }) => {
			const indicator = column?.indicator?._id;
			const value = rowData?.[indicator]?.history?.value;
			const isInvalid = rowData?.[indicator]?.invalid;


			const element = value ? (
				<HealthTableCell value={value} strikethrough={isInvalid} onClick={() => {
					component.setState({
						invalidRecord: rowData?.[indicator],
						recordInvalid: rowData?.[indicator]?.invalid,
						recordNote: rowData?.[indicator]?.notes
					});
					userMetrics.trackEvent("user-overview: open record notes popup");
				}}
				status={isInvalid ? "" : rowData?.[indicator]?.history?.status}
				/>
			) : "--";

			return {
				value: element,
				cellStatusColor: isInvalid ? "" : rowData?.[indicator]?.history?.status
			};
		}
	};
}