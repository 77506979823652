/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

import Store from "./store";

import map from "fj-map";

import * as Sentry from "@sentry/react";

export default CallHistoryStore;

function CallHistoryStore(api) {
	var store = new Store();

	var loading = null;
	var page = 0;
	var status = "";
	var callers = [];
	var recipients = [];
	var start = "";
	var end = "";

	store.sorted = sorted;
	store.more = more;
	store.searchCallers = searchCallers;
	store.searchRecipients = searchRecipients;
	store.clearSearch = clearSearch;
	store.searchDate = searchDate;
	store.searchStatus = searchStatus;

	reset();

	return store;

	function reset() {
		page = 0;
		store.clear();
		return api.admin.calls(callers, recipients, page, start, end, status)
			.then(map(handle_create));
	}

	function more() {
		if(loading) return loading;
		loading = api.admin.calls(callers, recipients, page, start, end, status)
			.then(map(handle_create))
			.then(next_page)
			.catch(error => {
				Sentry.captureException(error);
				store.error(error);
			})
			.then(finished);
	}

	function finished() {
		loading = null;
	}

	function sorted() {
		return store.all().sort(by_time);
	}

	function handle_create(data) {
		var id = data._id;
		store.set(id, data);
	}

	function next_page() {
		page += 1;
	}

	function searchCallers(filterquery) {
		callers = callers.concat(filterquery);
		return reset();
	}

	function searchRecipients(filterquery) {
		recipients = recipients.concat(filterquery);
		return reset();
	}

	function searchDate(startdate, enddate) {
		start = startdate;
		end = enddate;
		if (!!start && !!end) {
			return reset();
		} else {
			return;
		}
	}

	function searchStatus(filterstatus) {
		status = filterstatus;
		return reset();
	}

	function clearSearch(name) {
		if (name === "callers") callers = [];
		if (name === "recipients") recipients = [];
		if (name === "status") status = "";
		if (name === "time") {
			start = "";
			end = "";
		}
		return reset();
	}

}

function by_time(prev, next) {
	var prev_time = (new Date(prev.created_at)).getTime();
	var next_time = (new Date(next.created_at)).getTime();

	var time_delta = next_time - prev_time;
	return time_delta;
}
