


import Store from "./store";

export default OrgGroupCompliancy;

function OrgGroupCompliancy(api, events, config){
	var store = new Store();

	api.org.compliant.list()
		.then(setSchedule)
		.then(trackChanges);

	return store;

	function setSchedule(schedule){
		store.set("schedule", schedule);
	}

	function trackChanges(){
		var orgGroup = config.orgGroup;
		if(!orgGroup) return;
		events.on("mqtt:orggroup/" + orgGroup + "/compliancy/updated", handle_change);
	}

	function handle_change(data){
		var schedule = data.schedules;
		setSchedule(schedule);
	}
}