import React, { useContext, useEffect, useMemo } from "react";

import {
	useResources as aeUseResources,
} from "@aetonix/hooks";

export const ResourceContext = React.createContext({
  api: null,
  events: null,
  config: null,
  dispatcher: null
});

// Converts old context to new context for cleaner code
export const ResourceProvider = (props) => {
  const { api, events, config } = aeUseResources();
  const val = useMemo(
    () => ({
      api: api,
      events: events.createTracker(),
      config: config,
      dispatcher: events
    }),
    []
  );

  useEffect(() => {
    return () => {
      val.events.off();
    };
  });
  return <ResourceContext.Provider {...props} value={val} />;
};

export const ResourceConsumer = ResourceContext.Consumer;

export const useResources = () => {
  return useContext(ResourceContext);
};

export const useApi = () => {
  return useContext(ResourceContext).api;
};

export const useEvents = () => {
  return useContext(ResourceContext).events;
};

export const useConfig = () => {
  return useContext(ResourceContext).config;
};

export const useDispatcher = () => {
  return useContext(ResourceContext).dispatcher;
};
