import React from "react";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ResourceConsumer } from "../shared/contexts/ResourceContext";
import { default as LogViewer } from "./LogViewer.jsx";
import Header from "../shared/Header.js";
import {LocalizeProvider} from "../shared/contexts/LocalizeContext";

export default render;

function render() {
	var component = this;
	var state = component.state;

	var localization = state.localization;
	var careplanNoticeList = state.careplanChangeList.all();
	var careplanChanges = !!careplanNoticeList.length;
	var offline = state.connection.get("offline");
	var currentPerson = state.currentPerson;
	var titleKey = "org_audit_log";

	var groups = state.groups;

	return (
		<MuiPickersUtilsProvider utils={MomentUtils}>
			<div className="flex-vertical flex-1">
				<Header
					careplanChanges={careplanChanges}
					offline={offline}
					currentPerson={currentPerson}
					localization={localization}
					titleKey={titleKey}
				/>
				<div style={{ display: "flex", height: "calc(100% - 64px)" }}>
					<ResourceConsumer>
						{({ api, config }) => {
							return (
							<LocalizeProvider>
								<LogViewer
									api={api}
									groups={groups}
									config={config}
									localization={localization}
									currentPerson={currentPerson}
									people={state.people}
									users={state.users}
									loadMoreUsers={state.loadMoreUsers}
									searchUsers={state.searchUsers}
									sortedUsers={state.sortedUsers}
									usersAtEnd={state.usersAtEnd}
								/>
							</LocalizeProvider>
							);
						}}
					</ResourceConsumer>
				</div>
			</div>
		</MuiPickersUtilsProvider>
	);
}
