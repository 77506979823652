
/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, May 3, 2018
 * For information or permission request, email info@aetonixsystems.com
 */

import * as Sentry from "@sentry/react";

import React from "react";
import par from "par";
import xtend from "xtend";
import escape_regex from "escape-regexp";
import Header from "../shared/Header.js";
import SubHeader from "@material-ui/1.5.1/ListSubheader";
import IconButton from "@material-ui/1.5.1/IconButton";
import TextField from "@material-ui/1.5.1/TextField";
import Select from "@material-ui/1.5.1/Select";
import MenuItem from "@material-ui/1.5.1/MenuItem";
import ListItem from "@material-ui/1.5.1/ListItem";
import ListItemText from "@material-ui/1.5.1/ListItemText";
import Dialog from "../shared/Dialog";
import Button from "@material-ui/1.5.1/Button";
import InputLabel from "@material-ui/1.5.1/InputLabel";
import FormControl from "@material-ui/1.5.1/FormControl";
import FormHelperText from "@material-ui/1.5.1/FormHelperText";
import Snackbar from "@material-ui/1.5.1/Snackbar";
import FontIcon from "@material-ui/1.5.1/Icon";
import LazyList from "../shared/LazyList.jsx";
import  AetonixTheme_mui_1_5_1 from "../shared/AetonixTheme_mui_1.5.1";
const Colors = AetonixTheme_mui_1_5_1.palette;

var styles = {
	search: {
		color: Colors.primary.main,
		minWidth: "30%",
		padding: "8px"
	},
	input: {
		color: Colors.primary.main,
		padding: "8px"
	},
	icon: {color: Colors.primary.main},
	menuItemIcon: {
		color: Colors.primary.main,
		marginRight: "8px"
	},
	inputProp: {style: {color: Colors.primary.main, }},
	noMatch: {
		padding: "1em",
		display: "inherit",
		justifyContent: "center",
		color: Colors.primary.main
	},
	duplicationHeader: {textAlign: "center"},
	toast: {
		margin: "100px",
		padding: "10px",
		backgroundColor: "rgb(0, 172, 193)", //rgb(0, 199, 26)
		borderRadius: "10px"
	},
	inProgressToast: {
		margin: "100px",
		padding: "10px",
		backgroundColor: "white",
		borderRadius: "10px"
	},
	inProgressToastText: {
		color: "black",
		margin: "5px"
	},
	toastText: {
		color: Colors.canvas,
		margin: "5px"
	},
	snackbar: {style: {backgroundColor: "red"}}
};

export default render;

function render() {
	var component = this;
	var state = component.state;
	var toRemove = state.toRemove;

	var orgs = state.orgs;
	var allOrgs = state.allOrgs;
	var updating = state.updating;
	var creating = state.creating;
	var schemaError = state.schemaError;
	var localization = state.localization;
	var careplanNoticeList = state.careplanChangeList.all();
	var careplanChanges = !!careplanNoticeList.length;
	var offline = state.connection.get("offline");
	var currentPerson = state.currentPerson;
	var options = [];
	var isDuplicating = state.isDuplicating;
	var isMissingDestination = state.isMissingDestination;

	var sortedOrgs = orgs.sort(by_name);
	var loadMore = state.loadMore;
	var searchOrg = state.searchOrg;
	var viewing = state.viewing;

	var defaultSchema = updating ? updating.schemaText : (creating ? creating.schemaText : null);
	var errorText = schemaError ? localization.get("org_form_schemaerror") : null;

	if (viewing){
		options = state.options.get(viewing);
	}

	var sortedOptions = options.sort(by_title);

	var renderOrgListItem = par(renderOrg, component, localization);

	var cancel = updating ? par(cancelUpdating, component) : par(cancelAdding, component);
	var dialogTitle = getDialogTitle(component, currentPerson, updating);

	var addActions = [
		(<Button key={"buttonok"} color="primary" onClick={par(submitOptions, component)}>{localization.get("org_create_submit")}</Button>),
		(<Button key={"buttoncancel"} color="primary" onClick={cancel}>{localization.get("org_create_cancel")}</Button>)
	];

	var remove_actions = [(
		<Button key={"buttonok"} onClick={par(confirmRemove, component)}>{localization.get("users_ok")}</Button >
	), (
		<Button key={"buttoncancel"} onClick={par(hideRemove, component)}>{localization.get("users_cancel")}</Button >
	)];

	var duplicate_actions = [
		(<Button onClick={par(cancelDuplicating, component)}>{localization.get("common.cancel")}</Button>),
		(<Button onClick={par(finishDuplicating, component)}>{localization.get("common.finish")}</Button>)
	];

	var currentDuplicateOrg = state.duplicateOrg;

	var titleKey = "admin_options_upload";

	var orgSelectors = allOrgs.sort(by_name).map(e => (
		<MenuItem key={e._id} value={e._id} >
			{e.name}
		</MenuItem>
	));

	const renderOpenToast = (
		<Snackbar open={state.openToast} autoHideDuration={3500} onClose={par(hideToastAndFinishDuplicating, component)} onClick={par(hideToastAndFinishDuplicating, component)}>
			<div className="flex-horizontal ae-dropshadow" style={styles.toast}>
				<FontIcon className="fa fa-check fa-2x" style={styles.icon} />
				<div style={styles.toastText}>
					{localization.get(state.toastMessage) || ""}
				</div>
			</div>
		</Snackbar>
	);

	const renderInProgressToast = (
		<Snackbar open={state.inProgressToast} onClose={par(hideToast, component)}>
			<div className="flex-horizontal ae-dropshadow" style={styles.inProgressToast}>
				<FontIcon className="fa fa-cloud-upload fa-2x" style={styles.icon} />
				<div style={styles.inProgressToastText}>
					{localization.get(state.toastMessage) || ""}
				</div>
			</div>
		</Snackbar>
	);

	const renderErrorToast = (
		<Snackbar
			ContentProps={styles.snackbar}
			open={state.errorToast}
			autoHideDuration={3500}
			onClose={par(hideToast, component)}
			message={state.errorToast}
			action={<IconButton className="fa fa-times" onClick={par(hideToast, component)}></IconButton>}
		/>
	);

	return (
		<div className="flex-vertical flex-1">
			<Header
				careplanChanges={careplanChanges}
				offline={offline}
				currentPerson={currentPerson}
				localization={localization}
				titleKey={titleKey}
			/>
			<Dialog actions={duplicate_actions} open={!!isDuplicating} title={localization.get("org_options_duplicating")}>
				<FormControl fullWidth error={isMissingDestination}>
					<InputLabel>{localization.get("org_options_duplicating")}</InputLabel>
					<Select onChange={par(updateOrgDropdown, component)} value={currentDuplicateOrg}>
						{orgSelectors}
					</Select>
					{isMissingDestination ? (<FormHelperText>{localization.get("admin_org_duplicating_missing_destination")}</FormHelperText>) : null}
				</FormControl>
			</Dialog>
			<Dialog actions={remove_actions} open={!!toRemove} title={localization.get("org_options_removeTitle")} >
				{localization.get("org_options_removeText")}
			</Dialog>
			<div className="flex-horizontal flex-1 ae-scrollable">
				<div className="flex-vertical flex-1 ae-left-margin ">
					<SubHeader>{localization.get("admin_form_org")}</SubHeader>
					<TextField placeholder={localization.get("admin_options_search")} inputProps={{"aria-label": localization.get("admin_options_search")}} onChange={searchOrg} style={styles.search} InputProps={styles.inputProp} />
					<LazyList loadMore={loadMore} renderItem={renderOrgListItem} items={sortedOrgs} />
				</div>
				<div className="flex-vertical flex-1">
					<div className="flex-vertical flex-1 ae-scrollable">
						<SubHeader>{localization.get("admin_options_text")}</SubHeader>
						{showFilter(component, sortedOptions, currentPerson)}
						{renderLazyList(component, currentPerson, par(showFormRemoval, component), par(startDuplicating, component), options)}
					</div>
					{renderAdd(component)}
				</div>
			</div>
			<div>
				<Dialog actions={addActions} open={!!creating || !!updating} title={dialogTitle}>
						<div>
							<TextField
								fullWidth
								defaultValue={defaultSchema}
								errorText={errorText}
								multiline
								rows="18"
								aria-label= {localization.get("org_options_schema")}
								inputProps={{"aria-label": localization.get("org_options_schema"), "role": "tab"}}
								role={"tablist"}
								onChange={par(updateSchema, component)}
								label={localization.get("org_options_schema")}
								InputProps={styles.inputProp} />
						</div>
				</Dialog>
			</div>
			{renderInProgressToast}
			{renderOpenToast}
			{renderErrorToast}
		</div>
	);
}


function hideToast(component) {
	component.state.userMetrics.trackEvent("admin-options-schema: hide toast");
	component.setState({
		errorToast: false,
		openToast: false,
		inProgressToast: false,
		toastMessage: ""
	});
}

function hideToastAndFinishDuplicating(component) {
	component.state.userMetrics.trackEvent("admin-options-schema: close duplicate options popup");
	component.setState({
		errorToast: false,
		openToast: false,
		inProgressToast: false,
		toastMessage: "",
		isDuplicating: null,
		duplicateOrg: ""
	});
}

function showFilter(component, sortedOptions, currentPerson) {
	var state = component.state;
	var localization = state.localization;
	var viewing = state.viewing;
	if (!viewing) return;
	var options = state.options.get(viewing);
	if (!options || !options.length) return;

	return (
		<TextField key={viewing} placeholder={localization.get("search_onName")} inputProps={{"aria-label": localization.get("search_onName")}} onChange={par(searchOptions, sortedOptions, component, currentPerson)} InputProps={styles.inputProp} />
	);
}

function searchOptions(options, component, currentPerson, event) {
	event.persist();
	var query = event.target.value;
	var terms = escape_regex(query);
	var regex = new RegExp(terms, "gi");
	var person = currentPerson.get("personal");
	var language = person.language;

	var filtered = options.filter(function (item) {
		var description = item.schema.localization;
		var name = description[language] || description[Object.keys(description)[0]];
		var matches = (name).match(regex);
		if (matches) return item;
	});

	if (!filtered.length && terms.length) filtered = ["none"];

	component.setState({filteredOptions: filtered});
}


function showAddOptions(component) {
	component.state.userMetrics.trackEvent("admin-options-schema: open create options popup");
	component.setState({
		creating: {},
		schemaError: false,
		tabValue: 0
	});
}

function cancelAdding(component) {
	component.state.userMetrics.trackEvent("admin-options-schema: close create options popup");
	component.setState({creating: false});
}

function renderAdd(component) {
	if (component.state.viewing)
		return (<Button variant="raised"
			onClick={par(showAddOptions, component)}
			color="secondary"
			>
			{component.state.localization.get("admin_form_add")}
		</Button>);
}

function by_name(x, y) {
	var X = x.name.toLowerCase();
	var Y = y.name.toLowerCase();
	if (X < Y) return -1;
	if (X > Y) return 1;
	return 0;
}

function by_title(x, y) {
	var titleX = x.schema.title || "Untitled";
	var titleY = y.schema.title || "Untitled";
	var X = titleX.toLowerCase();
	var Y = titleY.toLowerCase();
	if (X < Y) return -1;
	if (X > Y) return 1;
	return 0;
}

function renderOrg(component, localization, org, index) {
	var name = org.name;
	var orgIndex = index + 1;
	var description = org.description;
	var orgId = org._id;

	var background_colour = "ae-plain";
	var viewing = component.state.viewing;
	if (viewing && orgId === viewing) background_colour = "ae-hover-color";

	const isDuplicating = component.state.isDuplicating;

	var onClick = isDuplicating ? par(finishDuplicating, component, isDuplicating, orgId) : par(viewOptionssFor, component, orgId, orgIndex);

	return (
		<ListItem button key={orgId} className={background_colour} onClick={onClick}>
			<ListItemText primary={name} secondary={description} />
		</ListItem>
	);
}

function viewOptionssFor(component, org, orgIndex) {
	component.state.userMetrics.trackEvent("admin-options-schema: view options for org", {
		org,
	});
	component.setState({
		viewing: org,
		selectedIndex: orgIndex,
		filteredOptions: []
	});
}

function renderLazyList(component, currentPerson, formRemoval, duplicateStart, options) {
	var localization = component.state.localization;
	var show = [];
	if (!options.length) {
		return null;
	}
	var renderOptionsListItem = par(renderOptions, component, currentPerson, formRemoval, duplicateStart);
	var filtered = component.state.filteredOptions;
	if (filtered[0] === "none") return (
		<div style={styles.noMatch}>{localization.get("search_noMatch")}</div>
	);
	if (!filtered.length) {
		show = options;
	} else {
		show = filtered;
	}

	return (<LazyList loadMore={noop} renderItem={renderOptionsListItem} items={show} />);
}

function submitOptions(component) {
	var updating = component.state.updating;
	var options = component.state.creating || updating;
	var viewing = component.state.viewing;
	var submit = updating ? component.state.updateOptionSchema : component.state.addOptionSchema;
	var hide = updating ? cancelUpdating : cancelAdding;

	const eventString = updating ? "admin-options-schema: update options" : "admin-options-schema: create options";
	component.state.userMetrics.trackEvent(eventString, {
		"id": options?._id,
		"organization": options?.organization,
		"english name": options?.schema?.localization?.en
	});

	try {
		options.schema = JSON.parse(options.schemaText);
		submit(options, viewing);
		hide(component);
	} catch (err) {
		component.setState({schemaError: true});
	}
}

function updateOrgDropdown(component, e){
	e.persist();
	var payload = e.target.value;
	var duplicateOrg = payload;
	component.setState({
		duplicateOrg,
		isMissingDestination: false
	});
}

function updateCreatingState(component, update) {
	component.setState({creating: update});
}

function updateUpdatingState(component, update) {
	var updating = component.state.updating;
	component.setState({updating: xtend(updating, update)});
}

function updateSchema(component, e) {
	e.persist();
	var text = e.target.value;
	var updating = component.state.updating;
	var update = component.state.creating || updating;
	update.schemaText = text;

	if (updating)
		updateUpdatingState(component, update);
	else
		updateCreatingState(component, update);
}

function renderOptions(component, currentPerson, remove, duplicateStart, options) {
	var state = component.state;
	var localization = state.localization;
	var person = currentPerson.get("personal");
	var language = person.language;
	var optionsId = options._id;
	var schema = options.schema || {};
	var optionLocalization = schema.localization || {};
	var keys = Object.keys(optionLocalization);
	var schemaLocalization = optionLocalization[language] || optionLocalization[keys[0]] || localization.get("org_options_untitled");
	var name = options.name || schemaLocalization;
	var localizationLabel = component.state.localization;
	var button = (
		<div className="flex-horizontal">
			<IconButton className="fa fa-pencil" aria-label= {localizationLabel.get("edit_button")} title= {localizationLabel.get("edit_button")} role="button"  style={styles.icon} onClick={par(startUpdating, component, options)} />
			<IconButton className="fa fa-times-circle" aria-label= {localizationLabel.get("remove_button")} title= {localizationLabel.get("remove_button")} role="button" style={styles.icon} onClick={par(remove, options)} />
			{downloadFile(options, name, localizationLabel, component)}
			<IconButton className="fa fa-copy" aria-label={localizationLabel.get("duplicate_button")} title= {localizationLabel.get("duplicate_button")} role="button" style={styles.icon} onClick={par(duplicateStart, options)} />
		</div>
	);
	var background_colour = "ae-plain";

	return (
		<ListItem key={optionsId} className={background_colour} >
			<ListItemText primary={name} />
			{button}
		</ListItem>
	);
}

function startUpdating(component, options) {
	var schema = options.schema;
	if (typeof schema !== "string")
		options.schemaText = JSON.stringify(schema, null, 4);
	var changes = xtend({}, options);
	component.state.userMetrics.trackEvent("admin-options-schema: open edit options popup");
	component.setState({
		updating: changes,
		schemaError: false,
		tabValue: 0
	});
}

function cancelUpdating(component) {
	component.state.userMetrics.trackEvent("admin-options-schema: close edit options popup");
	component.setState({updating: false});
}

function downloadFile(options, name, localizationLabel, component) {
	var schemaText = JSON.stringify(options.schema, null, 4);
	var filename = name.replace(/ /g, "_") + ".schema.json";
	var jsonBlob = new Blob([schemaText], {type: "application/json"});

	var url = window.URL.createObjectURL(jsonBlob);

	return (
		<IconButton className="fa fa-download" aria-label= {localizationLabel.get("download_file_button")}  title={localizationLabel.get("download_file_button")} role="button" style={styles.icon} href={url} download={filename}
			onClick={() => component.state.userMetrics.trackEvent("admin-options-schemas: download file", {
				filename,
				url,
			})}
		/>
	);
}

function startDuplicating(component, options) {
	component.state.userMetrics.trackEvent("admin-options-schema: open duplicate options popup");
	component.setState({isDuplicating: options});
}

function cancelDuplicating(component) {
	component.state.userMetrics.trackEvent("admin-options-schema: close duplicate options popup");
	component.setState({
		isDuplicating: null,
		duplicateOrg: "",
		isMissingDestination: false
	});
}

function finishDuplicating(component) {
	const state = component.state;
	const options = state.isDuplicating;

	if(!options)
		return;

	const optionsId = options._id;
	const organization = options.organization;
	const destination = state.duplicateOrg;

	if (!destination) {
		component.setState({isMissingDestination: true});
		return;
	} else {
		component.setState({
			inProgressToast: true,
			toastMessage: "org_options_duplicating_inProgress"
		});
	}

	component.state.userMetrics.trackEvent("admin-options-schema: duplicate options", {
		"id": optionsId,
		destination
	});
	state.duplicate(optionsId, organization, destination)
		.then(() => {
			component.setState({
				inProgressToast: false,
				openToast: true,
				toastMessage: "org_options_duplicating_success"
			});
		}).catch((err) => {

			Sentry.captureException(err);

			component.setState({
				inProgressToast: false,
				toastMessage: "",
				errorToast: err.response.message
			});
		});
}

function showFormRemoval(component, form) {
	component.state.userMetrics.trackEvent("admin-options-schema: open remove options popup");
	component.setState({toRemove: form});
}

function confirmRemove(component) {
	var id = component.state.toRemove;
	var viewing = component.state.viewing;
	component.state.removeOptionSchema(id, viewing);
	component.state.userMetrics.trackEvent("admin-options-schema: remove options", {
		"id": id._id,
	});
	component.setState({toRemove: null});
}


function hideRemove(component) {
	component.state.userMetrics.trackEvent("admin-options-schema: close remove options popup");
	component.setState({toRemove: null});
}

function noop() { }

function getDialogTitle(component, currentPerson, options) {
	var state = component.state;
	var localization = state.localization;
	var person = currentPerson.get("personal");
	var language = person.language;
	var description = localization.get("org_options_new");
	var schemaDescription = options.schema ? options.schema.localization : null;
	if (schemaDescription) {
		var keys = Object.keys(schemaDescription);
		description = keys.length !== 0 ? schemaDescription[language] || schemaDescription[keys[0]] : localization.get("org_options_untitled");
	}
	return options.name || description;
}
