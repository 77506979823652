

import Store from "./store";
import map from "fj-map";
import xtend from "xtend";

import * as Sentry from "@sentry/react";

export default OrgOptionSchemaStore;

function OrgOptionSchemaStore(api, events, config) {
	var store = new Store();
	refresh();

	return store;

	function refresh(){
		return api.org.options.listOrg()
			.then(map(setOptions))
			.then(listenChanges)
			.catch(error => {
				Sentry.captureException(error);
				console.error(error);
			});
	}

	function setOptions(optionSchema){
		store.set(optionSchema._id, optionSchema);
	}

	function listenChanges(){
		const org = config.organization;
		if(!org) return;
		events.on(`mqtt:org/${org}/options/schema/updated`, handle_updated);
		events.on(`mqtt:org/${org}/options/schema/removed`, handle_removed);
		events.on(`mqtt:org/${org}/options/schema/created`, handle_created);
	}

	function handle_updated(data){
		var id = data._id;
		var current = store.get(id);

		if(!current) return;

		var newItem = xtend(current, data);
		store.set(id, newItem);
	}

	function handle_created(data){
		var id = data._id;
		store.set(id, data);
	}

	function handle_removed(data){
		var id = data._id;
		store.remove(id);
	}
}
