/* © 2022 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, February 11, 2022
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";

import {
	IconButton,
	ListItem,
	ListItemText,
	CircularProgress,
	Modal,
	Popover,
	Typography,
	Box,
	Grid,
	TextField,
	Button,
	MenuItem,
	Select,
	InputAdornment
} from "@material-ui/core";

import { Search } from "@material-ui/icons";
import Header from "../shared/Header.js";
import LazyList from "../shared/LazyList";

const listItemClasses = {
	default: "ae-padding",
	gutters: "ae-padding",
	root: "ae-padding"
};

const iconButtonClasses = {
	label: "ae-widthauto"
};

const styles = {
	gridItemStyle: { display: "flex", justifyContent: "center", maxWidth: "100%", marginBottom: "20px" },
	orgFilterContainer: { display: "flex", width: "100%" },
	dateFilter: { display: "flex", alignItems: "center" },
	dateTypography: { marginRight: "20px", color: "#065B61" },
	sortSelect: { color: "#065B61", backgroundColor: "transparent", border: "1px solid #065B61", height: "40px" },
	flexVertical: { display: "flex", flexDirection: "column", flex: 1 },
	orgContainer: { display: "flex", flexDirection: "column", alignItems: "center" },
	orgTitleItem: { display: "flex", justifyContent: "center", maxWidth: "100%" },
	boxContainer: { flex: 1, flexDirection: "column", alignItems: "center" },
};


export default render;

function render() {
	var component = this;
	const reports = component.state.reports.get("reports") || [];
	var state = {
		...component.state,
		startDate: null,
		endDate: null,
		searchTerm: null,
		sortByName: false,
		sortedReports: reports,
	};

	var localization = state.localization;
	var offline = state.connection.get("offline");
	var careplanChanges = !!state.careplanChangeList.all().length;
	var currentPerson = state.currentPerson;

	const loading = !!state.reports.get("loading");

	let reportsToRender;
	if (this.state.sortedReports === undefined) {
		reportsToRender = reports;
	}
	else {
		reportsToRender = this.state.sortedReports;
	}

	const orgName = currentPerson.get("org")?.name || "";

	const filteredReportsDate = reportsToRender.filter(report => {
		if (!this.state.startDate === null || !this.state.endDate) {
			return true;
		} else {
			const lastModified = new Date(report.LastModified);
			return lastModified >= this.state.startDate && lastModified <= this.state.endDate;
		}
	}).filter(report => {

		if (!this.state.searchTerm) {

			return true;
		} else {
			const filename = report?.Key?.split("/")[1];
			return filename?.toLowerCase().includes(this.state?.searchTerm?.toLowerCase());
		}
	});

	const renderReport = (report) => {
		const filename = report?.Key?.split("/")[1];
		const lastModifiedString = new Date(report.LastModified).toLocaleDateString();
		const downloadLink = this.state.makeDownloadLink(report.Key);
		const filesizeString = this.state.bitsToString(report.Size);
		const tooLarge = report.tooLarge;
		const open = this.state[`popover_${report.Key}_open`];

		const handlePopoverOpen = () => {
			const newState = {};
			newState[`popover_${report.Key}_open`] = true;
			this.setState(newState);
		};

		const handlePopoverClose = () => {
			const newState = {};
			newState[`popover_${report.Key}_open`] = false;
			this.setState(newState);
		};

		return (
			<ListItem classes={listItemClasses} key={`${Math.random().toString(36).replace(/[^a-z]+/g, "")}`} className={"ae-plain" || "ae-hover-color"}>
				<ListItemText primary={filename || report.ETag} secondary={`${localization.get("dashboards_org_reports_last_modified")} ${lastModifiedString}. ${filesizeString}`} />
				<IconButton
					id={`button_${report.Key}`}
					color={"primary"}
					className={tooLarge ? "fa fa-question-circle" : "fa fa-download"}
					aria-label={localization.get("download_file_button")}
					title={localization.get("download_file_button")}
					role="button"
					classes={iconButtonClasses}
					download={tooLarge ? null : filename}
					href={tooLarge ? null : downloadLink}
					aria-owns={open ? "mouse-over-popover" : undefined}
					aria-haspopup="true"
					onMouseEnter={tooLarge ? handlePopoverOpen : undefined}
					onMouseLeave={tooLarge ? handlePopoverClose : undefined}
					selected={false}
					onClick={() => component.state.userMetrics.trackEvent("org-reports: download report", {
						report: report?.Key
					})}
				/>
				<Popover
					id={`popover_${report.Key}`}
					open={open}
					anchorReference="anchorEl"
					anchorEl={document.getElementById(`button_${report.Key}`)}
					anchorOrigin={{ horizontal: "left", vertical: "center" }}
					transformOrigin={{ vertical: "center", horizontal: "right" }}
				>
					<Box m={1}>
						<Typography> {localization.get("")} </Typography>
					</Box>
				</Popover>
			</ListItem>
		);
	};


	const handleSortByName = () => {
		const newSortByName = !this.state.sortByName;
		const sortedReports = [...reports].sort((a, b) => {
			const nameA = a.Key.split("/")[1].toLowerCase();
			const nameB = b.Key.split("/")[1].toLowerCase();
			if (newSortByName) {
				return nameA.localeCompare(nameB);
			} else {
				return nameB.localeCompare(nameA);
			}
		});
		component.setState({ sortedReports: sortedReports, sortByName: newSortByName });
	};

	const table = (<LazyList loadMore={() => { }} renderItem={renderReport} items={filteredReportsDate} />);

	if (dataHasChanged(reports, state.previousReports)) {
		reports.length && component.state.userMetrics.trackEvent("org-reports: view reports", {
			reports: reports.map((report) => report?.Key).filter((report) => report)
		});
		component.setState({
			previousReports: reports
		});
	}
	return (
		<Grid className="flex-vertical flex-1" >
			<Header
				localization={localization}
				offline={offline}
				careplanChanges={careplanChanges}
				currentPerson={currentPerson}
				titleKey={"dashboards_org_reports_title"}
			/>
			<Grid container className="org-container ae-align-centre">
				<Grid item xs={12} style={styles.orgTitleItem}>
					<Typography variant="h6" className="ae-title org-title-item">{`${localization.get("dashboards_org_reports_h1")} ${orgName}`}</Typography>
				</Grid>
				<Grid item style={styles.orgFilterContainer} className="org-filter">
					<Grid container style={styles.gridItemStyle} xs={12} spacing={2}>
						<Grid item xs={12}>
							<TextField
								fullWidth
								id="searchTerm"
								variant="outlined"
								label={localization.get("dashboards_org_reports_search_by_name")}
								onChange={(event) => this.setState({ searchTerm: event.target.value })}
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<Search />
										</InputAdornment>
									),
								}}
							/>
						</Grid>

						<Grid item xs={6} sm={3} md={2} lg={2} className="date-filter" style={styles.dateFilter}>
							<Typography variant="subtitle1" style={styles.dateTypography} >
								{localization.get("dashboards_org_reports_start_date")}
							</Typography>
							<TextField
								type="date"
								id="startDate"
								variant="outlined"
								size="small"
								onChange={(event) => this.setState({ startDate: new Date(event.target.value) })}
							/>
						</Grid>

						<Grid item xs={6} sm={3} md={2} lg={2} className="date-filter" style={styles.dateFilter}>
							<Typography variant="subtitle1" style={styles.dateTypography}>
								{localization.get("dashboards_org_reports_end_date")}
							</Typography>
							<TextField
								type="date"
								id="endDate"
								size="small"
								variant="outlined"
								onChange={(event) => this.setState({ endDate: new Date(event.target.value) })}
							/>
						</Grid>
						<Grid item xs={12} sm={2} md={2} lg={1} style={styles.dateFilter}>
							<Button
								size="medium"
								variant="outlined"
								style={{ ...styles.sortSelect}}
								aria-label={localization.get("dashboards_org_reports_clear_dates")}
								onClick={() => {
									const endDateElem = document.getElementById("endDate");
									const startDateElem = document.getElementById("startDate");
									if (endDateElem || startDateElem) {
										endDateElem.value = null;
										startDateElem.value = null;
										this.setState({ endDate: null, startDate: null });
									}
								}}
							>
								{localization.get("dashboards_org_reports_clear_dates")}
							</Button>
						</Grid>
						<Grid item xs={12} sm={2} md={3} lg={2} style={styles.dateFilter}>
							<Typography variant="subtitle1" style={styles.dateTypography}>
								{localization.get("dashboards_org_reports_sort")}
							</Typography>
							<Select
								value={this.state.sortByName ? "AZ" : "ZA"}
								onChange={handleSortByName}
								displayEmpty
								aria-label={localization.get("dashboards_org_reports_sort")}
							>
								<MenuItem value="AZ">A-Z</MenuItem>
								<MenuItem value="ZA">Z-A</MenuItem>
							</Select>
						</Grid>
					</Grid>
				</Grid>

				{!filteredReportsDate?.length && (
					<div className="flex-1 flex-vertical ae-align-centre box-container">
						{!loading ?
							(<div className="ae-title box-title"><i>{localization.get("dashboards_org_reports_no_reports")}</i></div>) :
							(<CircularProgress mode="indeterminate" size={40} />)
						}
					</div>
				)}

			</Grid>
			<Modal></Modal>
			{!!filteredReportsDate?.length && table}
		</Grid>
	);
}

function dataHasChanged(newData, oldData) {
	newData = newData || [];
	oldData = oldData || [];
	if (newData.length !== oldData.length) {
		return true;
	}
	for (var i = 0; i < newData.length; i++) {
		if (newData[i].Key !== oldData[i].Key) {
			return true;
		}
	}
	return false;
}


