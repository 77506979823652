
/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import par from "par";

import defineUI from "../shared/define";
import render from "./render.jsx";
import PersonStore from "../stores/person_store";
import UserStore from "../stores/user_active_store";
import UserBraceletPreferencesStore from "../stores/user_bracelet_preference_store";
import LocalizationStore from "../stores/localization_store";
import CurrentPersonInfoStore from "../stores/current_person_info_store";
import UserContactStore from "../stores/user_contact_store";
import CareplanNotificationStore from "../stores/careplan_notice_store";
import ConnectionStore from "../stores/connection_store";
import makeStoreFactory from "../shared/stores";

const makeStores = makeStoreFactory({
	people: PersonStore,
	currentPerson: CurrentPersonInfoStore,
	localization: LocalizationStore,
	users: UserStore,
	contacts: UserContactStore,
	braceletPreferences: UserBraceletPreferencesStore,
	careplanChangeList: CareplanNotificationStore,
	connection: ConnectionStore
});

function make_ui(api, events, config, userMetrics) {
	var storeStore = makeStores(api, events, config);

	window.stores = storeStore.store;

	userMetrics.trackEvent("page view", {
		"page": "user-bracelets",
		"category": "user",
		"permission level": "staff",
	});

	return defineUI({
		getInitialState: getInitialState,
		componentDidMount: listen_for_changes,
		render: render,
		componentWillUnmount: removeListeners
	});

	function removeListeners() {
		events.off();
	}

	function listen_for_changes() {
		storeStore.listen(par(handle_change, this));
	}

	function handle_change(component, stores) {
		component.setState({
			people: stores.people,
			users: stores.users.all(),
			contacts: stores.contacts,
			braceletPreferences: stores.braceletPreferences,
			currentPerson: stores.currentPerson,
			localization: stores.localization,
			careplanChangeList: stores.careplanChangeList,
			connection: stores.connection
		});
	}

	function getInitialState() {
		return {
			people: storeStore.stores.people,
			braceletPreferences: storeStore.stores.braceletPreferences,
			currentPerson: storeStore.stores.currentPerson,
			localization: storeStore.stores.localization,
			contacts: storeStore.stores.contacts,
			viewing: null,
			saved: false,
			addingContact: false,
			macerror: false,
			users: [],
			updateMac: updateMac,
			unassociateMac: unassociateMac,
			updateRelay: updateRelay,
			updateContact: updateContact,
			updateFall: updateFall,
			updateDisconnect: updateDisconnect,
			updateModel: updateModel,
			updatePress: updatePress,
			selectedIndex: 0,
			loadMore: par(loadMore, storeStore.stores.users),
			searchExist: par(searchExist, storeStore.stores.users),
			careplanChangeList: storeStore.stores.careplanChangeList,
			connection: storeStore.stores.connection,
			userMetrics: userMetrics,
		};
	}

	function loadMore(users) {
		return users.more();
	}

	function searchExist(users, event){
		event.persist();
		var query = event.target.value;
		return users.search(query);
	}

	function updateMac(user, mac) {
		return api.org.devices.associate(mac, user);
	}

	function unassociateMac(user) {
		return api.org.devices.unassociate(user);
	}

	function updateFall(user, fall) {
		return api.org.devices.update({
			fall: fall
		}, user);
	}

	function updateDisconnect(user, disconnect) {
		return api.org.devices.update({
			disconnect: disconnect
		}, user);
	}

	function updatePress(user, press) {
		return api.org.devices.update({
			press: press
		}, user);
	}

	function updateContact(user, contact) {
		return api.org.devices.contact(contact, user);
	}

	function updateRelay(user, relay) {
		return api.org.devices.update({
			relay: relay
		}, user);
	}

	function updateModel(user, model) {
		return api.org.devices.update({
			model: model
		}, user);
	}
}

export default make_ui;
