/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, May 08, 2018
 * For information or permission request, email info@aetonixsystems.com
 */




import Store from "./store";
import par from "par";
import xtend from "xtend";

export default AdminResourceStore;

function AdminResourceStore(api, events) {
	var store = new Store();

	store.raw_get = store.get;
	store.get = get;

	var tracking = [];

	return store;

	function get(id) {
		var existing = store.raw_get(id);
		if (existing) return existing;

		if (!isTracking(id))
			track(id);

		return [];
	}

	function isTracking(id) {
		return tracking.indexOf(id) !== -1;
	}

	function track(id) {
		tracking.push(id);
		api.admin.resources.list(id).then(par(setResource, id));
		events.on("mqtt:org/" + id + "/resource/removed", par(removeResource, id));
		events.on("mqtt:org/" + id + "/resource/created", par(addResource, id));
		events.on("mqtt:org/" + id + "/resource/updated", par(updateResource, id));
	}

	function removeResource(id, data) {
		var resources = data;
		var newId = resources.resource;
		var existing = store.get(id);
		if (!existing) return;

		var filtered = existing.filter(function (existingResource) {
			return existingResource._id !== newId;
		});

		store.set(id, filtered);
	}

	function addResource(id, data) {
		var resources = data;
		var existing = store.get(id);
		if (!existing) return;

		var added = existing.concat(resources);

		store.set(id, added);
	}

	function updateResource(id, data) {
		var resources = data;
		var newId = resources.resource;
		var existing = store.get(id);
		if (!existing) return;

		var updated = existing.map(function (current) {
			if (newId === current._id)
				return xtend(current, resources);
			return current;
		});

		store.set(id, updated);
	}

	function setResource(id, resources) {
		store.set(id, resources);
	}

}
