/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */
import React from "react";

import TextField from "@material-ui/1.5.1/TextField";
import Paper from "@material-ui/1.5.1/Paper";
import MuiThemeProvider from "@material-ui/1.5.1/styles/MuiThemeProvider";
import AetonixTheme_mui_151 from "../shared/AetonixTheme_mui_1.5.1";
import AetonixTheme from "../shared/AetonixTheme";
import Button from "@material-ui/1.5.1/Button";
import  AetonixTheme_mui_1_5_1 from "../shared/AetonixTheme_mui_1.5.1";
const Colors = AetonixTheme_mui_1_5_1.palette;

import Select from "@material-ui/1.5.1/Select";
import MenuItem from "@material-ui/1.5.1/MenuItem";
import InputLabel from "@material-ui/1.5.1/InputLabel";
import FormControl from "@material-ui/1.5.1/FormControl";
import StyledIcon from "ae-base/Base/StyledIcon";
import config from "../../configs/config.json";
import { styled, StylesProvider } from "@material-ui/styles";
import { createGenerateClassName } from "@material-ui/1.5.1";

import {
	ThemeProvider
} from "@material-ui/core/styles";

import {
	CircularProgress
} from "@material-ui/core";

var styles = {
	input: {
		color: Colors.primary.main,
		marginTop: 8,
		marginBottom: 8
	},
	inputlabel: {
		color: "grey"
	},
	inputProp: {
		style: {
			color: Colors.primary.main
		}
		// autoComplete: "new-password",
	},
	buttons: {
		justifyContent: "space-between",
		margin: "0px 0px 10px 0px",
		display: "flex",
		flexDirection: "row",
		alignItems: "flex-start"
	},
	button: {
		margin: 10
	},
	showedEmail: {
		marginTop: 0
	},
	requestBtn: {
		display: "flex",
		flexDirection: "column"
	},
	centered: {
		flex: 1,
		display: "flex",
		justifyContent: "center",
		alignItems: "center"
	}
};

const PageContainer = styled("div")({
	height: "100%",
	width: "100%",
	display: "flex"
});

const LoginContainer = styled(Paper)({
	margin: "auto",
	padding: "24px 48px",
	maxWidth: 800,
	minHeight: 300,
	display: "flex",
	height: "auto"
});

const Logo = styled("img")({
	width: "100%"
});

const VerticalDivider = styled("div")({
	borderStyle: "solid",
	borderWidth: "0 0 0 1.5px",
	borderColor: "lightgray",
	margin: "0 48px",
	boxShadow: "none !important"
});

const RegionSelectRow = styled("div")({
	display: "flex",
	flexDirection: "row"
});

export default render;

function render() {
	const component = this;
	const state = component.state;
	const localization = state.localization;
	const hasTwoFactorAuth = state.hasTwoFactorAuth;
	const btnText = state.btnText;
	const isBtnDisabled = state.isBtnDisabled;
	const currentRegion = state.region;

	const handlelogin = component.handlelogin;
	const handleChangeEmail = component.handleChangeEmail;
	const handleChangePassword = component.handleChangePassword;
	const handleChangeCode = component.handleChangeCode;
	const handleTwoFactorRequest = component.handleTwoFactorRequest;
	const handleTwoFactorCancel = component.handleTwoFactorCancel;
	const handleTwoFactorAuth = component.handleTwoFactorAuth;
	const handleUpdateRegion = component.handleUpdateRegion;

	const message = state.message !== "" ? <p>{state.message}</p> : null;

	const showedEmail = state.email ? state.email : "";

	var hosts = config.hosts || [];
	var regionList = hosts.map(host => (
		<MenuItem key={host.name} value={host.name}>
			{host.name}
		</MenuItem>
	));

	const handleloginForm = (
		<form className="ae-login-form" onSubmit={handlelogin}>
			<div style={{ display: "flex", flexGrow: 1 }}>
				<div
					style={{
						flex: 1,
						display: "flex",
						flexDirection: "column",
						justifyContent: "space-around"
					}}>
					<Logo alt='Aetonix Logo' src="./static/images/logo.png" />
					{!!hosts.length && (
						<RegionSelectRow>
							<StyledIcon
								style={{ marginTop: "auto", height: "min-content", paddingRight: 7}}
								name="fa-globe"
								size={30}
							></StyledIcon>

							<FormControl style={{ minWidth: "180px" }} fullWidth>
								<InputLabel>{localization.region}</InputLabel>
								<Select
									fullWidth
									onChange={handleUpdateRegion}
									value={currentRegion}
								>
									{localization.region}
									{regionList}
								</Select>
							</FormControl>
						</RegionSelectRow>
					)}
				</div>
				<VerticalDivider />
				<div
					style={{
						flex: 2,
						display: "flex",
						flexDirection: "column",
						justifyContent: "center"
					}}>
					{state.loading ?
						<div style={styles.centered}>
							<CircularProgress />
						</div> :
						<>
							<div style={{ flex: 1 }}>{message}</div>
							<div style={{ flex: 1 }}>
								<TextField
									autoFocus
									key="email"
									fullWidth
									aria-label={localization.login_email}
									inputProps={{"aria-label": localization.login_email}}
									role={"textbox"}
									label={localization.login_email}
									onChange={handleChangeEmail}
									type="email"
									style={styles.input}
									InputProps={styles.inputProp}
									required
								/>
								<TextField
									key="password"
									fullWidth
									role={"textbox"}
									aria-label={localization.login_password}
									label={localization.login_password}
									inputProps={{"aria-label": localization.login_password}}
									onChange={handleChangePassword}
									type="password"
									style={styles.input}
									InputProps={styles.inputProp}
									required
								/>
							</div>
							<div style={{ flex: 1, display: "flex" }}>
								<Button
									variant="raised"
									onClick={handlelogin}
									type="submit"
									style={{ marginLeft: "auto", marginTop: "auto", color: "#FFFFFF"}}
								>
									{localization.login_login}
								</Button>
							</div>
						</>
					}
				</div>
			</div>
		</form>
	);

	const handleTwoFactorForm = (
		<form
			className="ae-login-form"
			style={{ flexDirection: "column" }}
			onSubmit={handleTwoFactorAuth}
		>
			<h4>{localization.two_factor_title}</h4>
			<p style={styles.showedEmail}>{showedEmail}</p>
			{message}
			<div>
				<TextField
					key="code"
					fullWidth
					label={localization.two_factor_code}
					onChange={handleChangeCode}
					type="text"
					style={styles.input}
					InputProps={styles.inputProp}
				/>
			</div>
			<div style={styles.buttons}>
				<Button
					variant="raised"
					onClick={handleTwoFactorAuth}
					fullWidth
					type="submit"
				>
					{localization.two_factor_submit}
				</Button>
				<div style={{ width: 16 }}></div>
				<Button variant="raised" fullWidth onClick={handleTwoFactorCancel}>
					{localization.two_factor_cancel}
				</Button>
			</div>
			<div style={styles.requestBtn}>
				<Button
					variant="raised"
					onClick={handleTwoFactorRequest}
					disabled={isBtnDisabled}
				>
					{btnText}
				</Button>
			</div>
		</form>
	);

	const formToRender = hasTwoFactorAuth ? handleTwoFactorForm : handleloginForm;

	return (
		<StylesProvider generateClassName={generateClassName}>
			<PageContainer>
				<ThemeProvider theme={AetonixTheme}>
					<MuiThemeProvider theme={AetonixTheme_mui_151}>
						<LoginContainer zdepth={1}>
							{formToRender}
						</LoginContainer>
					</MuiThemeProvider>
				</ThemeProvider>
			</PageContainer>
		</StylesProvider>
	);
}

const generateClassName = createGenerateClassName({
	productionPrefix: "m4jss"
});
