
/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, May 3, 2018
 * For information or permission request, email info@aetonixsystems.com
 */

import * as Sentry from "@sentry/react";

import React from "react";
import par from "par";
import xtend from "xtend";
import escape_regex from "escape-regexp";
import clone from "clone";
import Header from "../shared/Header.js";
import SubHeader from "@material-ui/1.5.1/ListSubheader";
import IconButton from "@material-ui/1.5.1/IconButton";
import TextField from "@material-ui/1.5.1/TextField";
import Select from "@material-ui/1.5.1/Select";
import MenuItem from "@material-ui/1.5.1/MenuItem";
import ListItem from "@material-ui/1.5.1/ListItem";
import ListItemText from "@material-ui/1.5.1/ListItemText";
import Dialog from "../shared/Dialog";
import Button from "@material-ui/1.5.1/Button";
import Tabs from "@material-ui/1.5.1/Tabs";
import Tab from "@material-ui/1.5.1/Tab";
import FontIcon from "@material-ui/1.5.1/Icon";
import InputLabel from "@material-ui/1.5.1/InputLabel";
import FormControl from "@material-ui/1.5.1/FormControl";
import FormHelperText from "@material-ui/1.5.1/FormHelperText";
import Drawer from "@material-ui/1.5.1/Drawer";
import { Checkbox, FormControlLabel } from "@material-ui/1.5.1";
import Snackbar from "@material-ui/1.5.1/Snackbar";
import LazyList from "../shared/LazyList.jsx";
import  AetonixTheme_mui_1_5_1 from "../shared/AetonixTheme_mui_1.5.1";
const Colors = AetonixTheme_mui_1_5_1.palette;
import { default as Prepopulate } from "../org-forms/Prepopulate.jsx";

var styles = {
	search: {
		color: Colors.primary.main,
		minWidth: "30%",
		padding: "8px"
	},
	input: {
		color: "grey",
		marginTop: "8px",
		marginBottom: "8px"
	},
	icon: {color: Colors.primary.main},
	iconStyle: {
		color: "#065B61",
		paddingRight: 15
	},
	inputlabel: {
		color: "black"
	},
	formControlStyle: {
		marginTop: "8px",
		marginBottom: "8px"
	},
	inputProp: {
		style: {
			color: Colors.primary.main,
		}
	},
	schemaError: {
		display: "flex",
		flex: 1,
		justifyContent: "center",
		padding: "1em",
		color: "white",
		backgroundColor: Colors.primary.main
	},
	noMatch: {
		padding: "1em",
		display: "inherit",
		justifyContent: "center",
		color: Colors.primary.main
	},
	toast: {
		margin: "100px",
		padding: "10px",
		backgroundColor: "rgb(0, 172, 193)", //rgb(0, 199, 26)
		borderRadius: "10px"
	},
	inProgressToast: {
		margin: "100px",
		padding: "10px",
		backgroundColor: "white",
		borderRadius: "10px"
	},
	inProgressToastText: {
		color: "black",
		margin: "5px"
	},
	toastText: {
		color: Colors.canvas,
		margin: "5px"
	},
	snackbar: {style: {backgroundColor: "red"}}
};

export default render;

function render() {
	var component = this;
	var state = component.state;
	var toRemove = state.toRemove;

	var orgs = state.orgs;
	var allOrgs = state.allOrgs;
	var updating = state.updating;
	var creating = state.creating;
	var schemaError = state.schemaError;
	var localization = state.localization;
	var careplanNoticeList = state.careplanChangeList.all();
	var careplanChanges = !!careplanNoticeList.length;
	var offline = state.connection.get("offline");
	var currentPerson = state.currentPerson;

	var sortedOrgs = orgs.sort(by_name);
	var loadMore = state.loadMore;
	var searchOrg = state.searchOrg;

	var forms = [];
	var viewing = state.viewing;
	var isDuplicating = state.isDuplicating;
	var isMissingDestination = state.isMissingDestination;

	var icons = localization.get("org_form_icons");
	var formTypes = localization.get("org_form_formtypes");
	var levels = localization.get("org_form_levels");

	var currentEmrId = (updating ? updating.emrId : creating.emrId || "");
	var currentIcon = (updating ? updating.icon : creating.icon || "");
	var currentFormType = (updating ? updating.formType : creating.formType) || "";
	var currentLevel = (updating ? updating.targetType : creating.targetType) || "";
	var currentLockOnSubmit = (updating ? updating.lockOnSubmit : creating.lockOnSubmit) || false;

	var defaultEn = (updating ? updating.localization.en : (creating.localization ? creating.localization.en : null)) || null;
	var defaultFr = (updating ? updating.localization.fr : (creating.localization ? creating.localization.fr : null)) || null;
	var defaultCns = (updating ? updating.localization.cn_s : (creating.localization ? creating.localization.cn_s : null)) || null;
	var defaultCnt = (updating ? updating.localization.cn_t : (creating.localization ? creating.localization.cn_t : null)) || null;
	var defaultTa = (updating ? updating.localization.ta : (creating.localization ? creating.localization.ta : null)) || null;
	var defaultEs = (updating ? updating.localization.es : (creating.localization ? creating.localization.es : null)) || null;
	var defaultPt = (updating ? updating.localization.pt : (creating.localization ? creating.localization.pt : null)) || null;

	var externals = findExternals(component);

	var defaultSchema = (updating ? updating.schemaText : creating.schemaText) || null;
	var errorText = schemaError ? localization.get("org_form_schemaerror") : null;
	var tabValue = state.tabValue || 0;

	if (viewing)
		forms = state.forms.get(viewing);
	var sortedForm = forms.sort(by_time);

	var renderOrgListItem = par(renderOrg, component, localization);

	var iconsList = Object.keys(icons).map(function (icon) {
		var iconName = "fa fa-" + icon;
		var renderIcon = (
			<div>
				<FontIcon className={iconName} style={styles.iconStyle} />
				{icons[icon]}
			</div>
		);
		return (
			<MenuItem key={icon} value={icon}>{renderIcon}</MenuItem>
		);
	});

	var cancel = updating ? par(cancelUpdating, component) : par(cancelAdding, component);

	var dialogTitle = updating ? getDialogTitle(currentPerson, updating) : localization.get("org_form_newform");

	var addActions = [
		(<Button key={"buttonsubmit"}
			onClick={par(submitForm, component)}>
			{localization.get("org_create_submit")}
		</Button>),
		(<Button key={"buttoncancel"}
			onClick={cancel}>
			{localization.get("org_create_cancel")}
		</Button>)
	];

	var remove_actions = [(
		<Button key={"buttonok"} onClick={par(confirmRemove, component)}>{localization.get("users_ok")}</Button >
	), (
		<Button key={"buttoncancel"} onClick={par(hideRemove, component)}>{localization.get("common.cancel")}</Button >
	)];

	var duplicate_actions = [
		(<Button onClick={par(cancelDuplicating, component)}>{localization.get("common.cancel")}</Button>),
		(<Button onClick={par(finishDuplicating, component)}>{localization.get("common.finish")}</Button>)
	];

	var currentDuplicateOrg = state.duplicateOrg;

	var titleKey = "admin_form_upload";

	var orgSelectors = allOrgs.sort(by_name).map(e => (
		<MenuItem key={e._id} value={e._id} >
			{e.name}
		</MenuItem>
	));

	const renderOpenToast = (
		<Snackbar open={state.openToast} autoHideDuration={3500} onClose={par(hideToastAndFinishDuplicating, component)} onClick={par(hideToastAndFinishDuplicating, component)}>
			<div className="flex-horizontal ae-dropshadow" style={styles.toast}>
				<FontIcon className="fa fa-check fa-2x" style={styles.icon} />
				<div style={styles.toastText}>
					{localization.get(state.toastMessage) || ""}
				</div>
			</div>
		</Snackbar>
	);

	const renderInProgressToast = (
		<Snackbar open={state.inProgressToast} onClose={par(hideToast, component)}>
			<div className="flex-horizontal ae-dropshadow" style={styles.inProgressToast}>
				<FontIcon className="fa fa-cloud-upload fa-2x" style={styles.icon} />
				<div style={styles.inProgressToastText}>
					{localization.get(state.toastMessage) || ""}
				</div>
			</div>
		</Snackbar>
	);

	const renderErrorToast = (
		<Snackbar
			ContentProps={styles.snackbar}
			open={state.errorToast}
			autoHideDuration={3500}
			onClose={par(hideToast, component)}
			message={state.errorToast}
			action={<IconButton className="fa fa-times" onClick={par(hideToast, component)}></IconButton>}
		/>
	);

	return (
		<div className="flex-vertical flex-1">
			<Header
				careplanChanges={careplanChanges}
				offline={offline}
				currentPerson={currentPerson}
				localization={localization}
				titleKey={titleKey}
			/>
			<Dialog actions={duplicate_actions} open={!!isDuplicating} title={localization.get("org_form_duplicating")}>
				<FormControl fullWidth error={isMissingDestination}>
					<InputLabel>{localization.get("org_form_duplicating")}</InputLabel>
					<Select onChange={par(updateOrgDropdown, component)} value={currentDuplicateOrg}>
						{orgSelectors}
					</Select>
					{isMissingDestination ? (<FormHelperText>{localization.get("admin_org_duplicating_missing_destination")}</FormHelperText>) : null}
				</FormControl>
			</Dialog>
			<Dialog actions={remove_actions} open={!!toRemove} title={localization.get("org_form_removeTitle")} >
				{localization.get("org_form_removeform")}
			</Dialog>
			<div className="flex-horizontal flex-1 ae-scrollable">
				<div className="flex-vertical flex-1 ae-left-margin ">
					<SubHeader>{localization.get("admin_form_org")}</SubHeader>
					<TextField placeholder={localization.get("admin_workflow_search")} inputProps={{"aria-label": localization.get("admin_workflow_search")}}  onChange={searchOrg} style={styles.search} InputProps={styles.inputProp} />
					<LazyList loadMore={loadMore} renderItem={renderOrgListItem} items={sortedOrgs} />
				</div>
				<div className="flex-vertical flex-1">
					<div className="flex-vertical flex-1 ae-scrollable">
						<SubHeader>{localization.get("admin_form_text")}</SubHeader>
						{showFilter(component, sortedForm, currentPerson)}
						{renderLazyList(component, currentPerson, par(showFormRemoval, component), par(startDuplicating, component), forms)}
					</div>
					{renderAdd(component)}
				</div>
			</div>
			<div>
				<Dialog actions={addActions} open={!!creating || !!updating} title={dialogTitle}>
					<Tabs fullWidth value={tabValue} indicatorColor="primary" onChange={par(handleTabValueChanged, component)}>
						<Tab label={localization.get("org_form_forminfo")} />
						<Tab label={localization.get("org_form_schema")} />
						<Tab  label={localization.get("org_form_prepopulate")} style={{ display: currentLevel === "user" ? undefined : "none" }} />
						{externals  && <Tab label={localization.get("org_workflow_externals")} />}
					</Tabs>
					{tabValue === 0 && (
						<div>
							<FormControl fullWidth={true} style={styles.formControlStyle}>
								<InputLabel style={styles.inputlabel}>{localization.get("org_form_icon")}</InputLabel>
								<Select fullWidth onChange={par(updateDropdown, component, "icon")} value={currentIcon}>
									{iconsList}
								</Select>
							</FormControl>
							<TextField fullWidth defaultValue={currentEmrId} onChange={par(updateEmrId, component)} placeholder={localization.get("org_form_emrId")} label={localization.get("org_form_emrId")} style={styles.input} InputProps={styles.inputProp} />
							<FormControl fullWidth={true} style={styles.formControlStyle}>
								<InputLabel style={styles.inputlabel}>{localization.get("org_form_formtype")}</InputLabel>
								<Select fullWidth onChange={par(updateDropdown, component, "formType")} value={currentFormType}>
									<MenuItem value={"single"}>{formTypes.single}</MenuItem>
									<MenuItem value={"multi"}>{formTypes.multi}</MenuItem>
								</Select>
							</FormControl>
							<FormControl fullWidth={true} style={styles.formControlStyle}>
								<InputLabel style={styles.inputlabel}>{localization.get("org_form_level")}</InputLabel>
								<Select fullWidth onChange={par(updateDropdown, component, "targetType")} value={currentLevel}>
									<MenuItem value={"user"}>{levels.user}</MenuItem>
									<MenuItem value={"group"}>{levels.group}</MenuItem>
									<MenuItem value={"staff"}>{levels.staff}</MenuItem>
								</Select>
							</FormControl>
							<TextField inputProps={{"aria-label": localization.get("org_form_englishtitle") }} fullWidth defaultValue={defaultEn} onChange={par(updateLocalization, component, "en")} placeholde={localization.get("org_form_englishtitle")} label={localization.get("org_form_englishtitle")} style={styles.input} InputProps={styles.inputProp} />
							<TextField inputProps={{"aria-label": localization.get("org_form_frenchtitle") }} fullWidth defaultValue={defaultFr} onChange={par(updateLocalization, component, "fr")} placeholder={localization.get("org_form_frenchtitle")} label={localization.get("org_form_frenchtitle")} style={styles.input} InputProps={styles.inputProp} />
							<TextField inputProps={{"aria-label": localization.get("org_form_cnstitle")}} fullWidth defaultValue={defaultCns} onChange={par(updateLocalization, component, "cn_s")} placeholder={localization.get("org_form_cnstitle")} label={localization.get("org_form_cnstitle")} style={styles.input} InputProps={styles.inputProp} />
							<TextField inputProps={{"aria-label": localization.get("org_form_cnttitle")}} fullWidth defaultValue={defaultCnt} onChange={par(updateLocalization, component, "cn_t")} placeholder={localization.get("org_form_cnttitle")} label={localization.get("org_form_cnttitle")} style={styles.input} InputProps={styles.inputProp} />
							<TextField inputProps={{"aria-label": localization.get("org_form_estitle")}} fullWidth defaultValue={defaultEs} onChange={par(updateLocalization, component, "es")} placeholder={localization.get("org_form_estitle")} label={localization.get("org_form_estitle")} style={styles.input} InputProps={styles.inputProp} />
							<TextField inputProps={{"aria-label": localization.get("org_form_pttitle")}} fullWidth defaultValue={defaultPt} onChange={par(updateLocalization, component, "pt")} placeholder={localization.get("org_form_pttitle")} label={localization.get("org_form_pttitle")} style={styles.input} InputProps={styles.inputProp} />
							<TextField inputProps={{"aria-label": localization.get("org_form_tatitle")}} fullWidth defaultValue={defaultTa} onChange={par(updateLocalization, component, "ta")} placeholder={localization.get("org_form_tatitle")} label={localization.get("org_form_tatitle")} style={styles.input} InputProps={styles.inputProp} />
							<FormControl style={styles.formControlStyle}>
								<FormControlLabel
									checked={currentLockOnSubmit}
									value={"lockOnSubmit"}
									control={<Checkbox></Checkbox>}
									label={localization.get("org_form_lockonsubmit")}
									labelPlacement="end"
									onChange={par(updateCheckbox, component, "lockOnSubmit")}
								/>
							</FormControl>
						</div>
					)}
					{tabValue === 1 && (
						<TextField aria-label={localization.get("org_form_schema")} inputProps={{"aria-label": localization.get("org_form_schema"), "role": "tab"}} role={"tablist"} fullWidth multiline error={errorText} defaultValue={defaultSchema} helperText={errorText} rows={19} rowsMax={19} onChange={par(updateSchema, component)} floatingLabelText={localization.get("org_form_schema")} InputProps={styles.inputProp} />
					)}
					{tabValue === 2 && (
						<Prepopulate  forms={forms} component={component} onChange={par(updatePrepopulate, component)}></Prepopulate>
					)}
					{tabValue === 3 && externals && renderExternals(component)}
					<Drawer
						anchor="bottom"
						open={schemaError}
						onClose={par(toggleDrawer, component, false)}
					>
						<div
							tabIndex={0}
							role="button"
							onClick={par(toggleDrawer, component, false)}
							onKeyDown={par(toggleDrawer, component, false)}
							style={styles.schemaError}
						>
							{errorText}
						</div>
					</Drawer>
				</Dialog>
			</div>
			{renderInProgressToast}
			{renderOpenToast}
			{renderErrorToast}
		</div>
	);
}

function updateEmrId(component, e) {
	e.persist();
	var text = e.target.value;
	var updating = component.state.updating;
	var update = component.state.creating || updating;
	update.emrId = text;

	if (updating)
		updateUpdatingState(component, update);
	else
		updateCreatingState(component, update);
}

function toggleDrawer(component, state) {
	component.state.userMetrics.trackEvent("admin-forms: toggled error toast", {
		"set open to": !!state,
		error: state.errorText
	});
	component.setState({
		schemaError: state
	});
}

function hideToast(component) {
	component.state.userMetrics.trackEvent("admin-forms: hide toast");
	component.setState({
		errorToast: false,
		openToast: false,
		inProgressToast: false,
		toastMessage: ""
	});
}

function hideToastAndFinishDuplicating(component) {
	component.state.userMetrics.trackEvent("admin-forms: hide toast");
	component.setState({
		errorToast: false,
		openToast: false,
		inProgressToast: false,
		toastMessage: "",
		isDuplicating: null,
		duplicateOrg: ""
	});
}

function startDuplicating(component, form) {
	component.state.userMetrics.trackEvent("admin-forms: open duplicate form popup");
	component.setState({
		isDuplicating: form
	});
}

function cancelDuplicating(component) {
	component.state.userMetrics.trackEvent("admin-forms: close duplicate form popup");
	component.setState({
		isDuplicating: null,
		duplicateOrg: "",
		isMissingDestination: false
	});
}

function finishDuplicating(component) {
	const state = component.state;
	const form = state.isDuplicating;

	if(!form)
		return;

	const formId = form._id;
	const organization = form.organization;
	const destination = state.duplicateOrg;

	if (!destination) {
		component.setState({isMissingDestination: true});
		return;
	} else {
		component.setState({
			inProgressToast: true,
			toastMessage: "org_form_duplicating_inProgress"
		});
	}

	component.state.userMetrics.trackEvent("admin-forms: duplicate form", {
		"form": formId,
	});
	state.duplicateForm(formId, organization, destination)
		.then(() => {
			component.setState({
				inProgressToast: false,
				openToast: true,
				toastMessage: "org_form_duplicating_success"
			});
		}).catch((err) => {
			Sentry.captureException(err);

			component.setState({
				inProgressToast: false,
				toastMessage: "",
				errorToast: err.response.message
			});
		});
}

function showFilter(component, sortedForm, currentPerson) {
	var state = component.state;
	var localization = state.localization;
	var viewing = state.viewing;
	if (!viewing) return;
	var forms = state.forms.get(viewing);
	if (!forms || !forms.length) return;

	return (
		<TextField key={viewing} placeholder={localization.get("search_onName")} inputProps={{"aria-label": localization.get("search_onName")}} onChange={par(searchForm, sortedForm, component, currentPerson)} InputProps={styles.inputProp} />
	);
}

function searchForm(forms, component, currentPerson, event) {
	event.persist();
	var query = event.target.value;
	var terms = escape_regex(query);
	var regex = new RegExp(terms, "gi");
	var person = currentPerson.get("personal");
	var language = person.language;

	var filtered = forms.filter(function (item) {
		var localization = item.localization;
		var name = localization[language] || localization[Object.keys(localization)[0]];
		var matches = (name).match(regex);
		if (matches) return item;
	});

	if (!filtered.length && terms.length) filtered = null;

	component.setState({
		filteredForms: filtered
	});
}


function showAddForm(component) {
	component.state.userMetrics.trackEvent("admin-forms: open create form popup");
	component.setState({
		creating: {},
		schemaError: false,
		tabValue: 0,
		prepopulateError: {}
	});
}

function cancelAdding(component) {
	component.state.userMetrics.trackEvent("admin-forms: close create form popup");
	component.setState({
		creating: false
	});
}

function renderAdd(component) {
	if (component.state.viewing)
		return (<Button variant="raised"
			onClick={par(showAddForm, component)}
			color="secondary"
			>
			{component.state.localization.get("admin_form_add")}
		</Button>);
}

function by_name(x, y) {
	if(typeof x.name !== "string")
		x.name = "";

	if(typeof y.name !== "string")
		y.name = "";

	var X = x.name.toLowerCase();
	var Y = y.name.toLowerCase();
	if (X < Y) return -1;
	if (X > Y) return 1;
	return 0;
}

function by_time(x, y) {
	return (new Date(x.created_at)).getTime() - (new Date(y.created_at)).getTime();
}

function renderOrg(component, localization, org, index) {
	var name = org.name;
	var orgIndex = index + 1;
	var description = org.description;
	var orgId = org._id;

	var background_colour = "ae-plain";
	var viewing = component.state.viewing;
	if (viewing && orgId === viewing) background_colour = "ae-hover-color";

	return (
		<ListItem key={orgId} className={background_colour} onClick={par(viewFormsFor, component, orgId, orgIndex)}>
			<ListItemText primary={name} secondary={description} />
		</ListItem>
	);
}

function viewFormsFor(component, org, orgIndex) {
	component.state.userMetrics.trackEvent("admin-forms: view forms for org", {
		org
	});
	component.setState({
		viewing: org,
		selectedIndex: orgIndex,
		filteredForms: []
	});
}

function renderLazyList(component, currentPerson, formRemoval, duplicateStart, forms) {
	var localization = component.state.localization;
	var show = [];
	if (!forms.length) {
		return null;
	}
	var renderFormListItem = par(renderForm, component, currentPerson, formRemoval, duplicateStart);
	var filtered = component.state.filteredForms;
	if (filtered === null) return (
		<div style={styles.noMatch}>{localization.get("search_noMatch")}</div>
	);
	if (!filtered.length) {
		show = forms;
	} else {
		show = filtered;
	}
	return (<LazyList loadMore={noop} renderItem={renderFormListItem} items={show} />);
}

function submitForm(component) {
	var updating = component.state.updating;
	var formInState = component.state.creating || updating;
	var form = clone(formInState);
	var viewing = component.state.viewing;
	var submit = updating ? component.state.updateForm : component.state.addForm;
	var hide = updating ? cancelUpdating : cancelAdding;

	var prepopulateError = {};
	if (form.prepopulate) {
		for (let entry of form.prepopulate) {
			try {
				entry.formMap = JSON.parse(entry.formMapText);
				delete entry.formMapText;
			} catch (err) {
				prepopulateError[entry.formId] = true;
			}
		}
	}
	component.setState({
		prepopulateError: prepopulateError
	});

	component.state.userMetrics.trackEvent("admin-forms: submit form", {
		"form": form._id,
	});
	try {
		form.schema = JSON.parse(form.schemaText);
		if(Object.keys(prepopulateError).length === 0){
			submit(form, viewing);
			hide(component);
		}
	} catch (err) {
		component.setState({
			schemaError: true
		});
	}
}

function updateOrgDropdown(component, e){
	e.persist();
	var payload = e.target.value;
	var duplicateOrg = payload;
	component.setState({
		duplicateOrg,
		isMissingDestination: false
	});
	component.state.userMetrics.trackEvent("admin-forms: update duplicate org dropdown", {
		"org": payload
	});
}

function updateDropdown(component, type, e) {
	e.persist();
	var payload = e.target.value;
	var updating = component.state.updating;
	var update = updating || component.state.creating;
	update[type] = payload;

	if (updating)
		updateUpdatingState(component, update);
	else
		updateCreatingState(component, update);
}

function updateCreatingState(component, update) {
	component.setState({
		creating: update
	});
}

function updateUpdatingState(component, update) {
	var updating = component.state.updating;
	component.setState({
		updating: xtend(updating, update)
	});
}


function updateLocalization(component, type, e) {
	e.persist();
	var text = e.target.value;
	var updating = component.state.updating;
	var update = component.state.creating || updating;
	var localization = {};
	localization[type] = text;
	update.localization = xtend(update.localization, localization);

	if (updating)
		updateUpdatingState(component, update);
	else
		updateCreatingState(component, update);
}

function updateCheckbox(component, type, e) {
	e.persist();
	var payload = e.target.checked;
	var updating = component.state.updating;
	var update = updating || component.state.creating;
	update[type] = payload;

	if (updating)
		updateUpdatingState(component, update);
	else
		updateCreatingState(component, update);
}

function updatePrepopulate(component, payload) {
	var updating = component.state.updating;
	var update = updating || component.state.creating;
	update["prepopulate"] = payload;

	if (updating)
		updateUpdatingState(component, update);
	else
		updateCreatingState(component, update);
}

function updateSchema(component, e) {
	e.persist();
	var text = e.target.value;
	var updating = component.state.updating;
	var update = component.state.creating || updating;
	update.schemaText = text;

	if (updating)
		updateUpdatingState(component, update);
	else
		updateCreatingState(component, update);
}

function renderForm(component, currentPerson, remove, duplicateStart, form) {
	if (!form) return null;
	var person = currentPerson.get("personal");
	var language = person.language;
	var localization = form.localization;
	var localizationLabel = component.state.localization;
	var name = localization[language] || localization[Object.keys(localization)[0]];
	var formId = form._id;

	var button = (
		<div className="flex-horizontal">
			<IconButton className="fa fa-pencil" aria-label= {localizationLabel.get("edit_button")} title={localizationLabel.get("edit_button")} role="button" style={styles.icon} onClick={par(startUpdating, component, form)} />
			<IconButton className="fa fa-times-circle" aria-label={localizationLabel.get("remove_button")} title={localizationLabel.get("remove_button")}  role="button" style={styles.icon} onClick={par(remove, form)} />
			{downloadFile(component, currentPerson, form, name)}
			<IconButton className="fa fa-copy" aria-label={localizationLabel.get("duplicate_button")} title={localizationLabel.get("duplicate_button")}  role="button" style={styles.icon} onClick={par(duplicateStart, form)} />
		</div>
	);
	var type = form.targetType;

	var icon = (type === "user") ? (
		<FontIcon className="fa fa-user" style={styles.iconStyle} />
	) : (type === "group") ? (
		<FontIcon className="fa fa-sitemap" style={styles.iconStyle} />
	) : (
		<FontIcon className="fa fa-cog" style={styles.iconStyle} />
	);

	var background_colour = "ae-plain";

	return (
		<ListItem key={formId} className={background_colour}>
			{icon}
			<ListItemText primary={name} />
			{button}
		</ListItem>
	);
}

function startUpdating(component, form) {
	var schema = form.schema;

	var changes = clone(form);
	if (typeof schema !== "string")
		changes.schemaText = JSON.stringify(schema, null, 4);
	changes.prepopulate = (changes.prepopulate || []).map((entry) => { return { ...entry, formMapText: JSON.stringify(entry.formMap) }; });

	component.setState({
		updating: changes,
		schemaError: false
	});

	component.state.userMetrics.trackEvent("admin-forms: open edit form popup", {
		"form": form._id,
	});
}

function cancelUpdating(component) {
	component.state.userMetrics.trackEvent("admin-forms: close edit form popup");
	component.setState({
		updating: false
	});
}

function downloadFile(component, currentPerson, form, name) {
	var schemaText = JSON.stringify(form.schema, null, 4);
	var filename = name.replace(/ /g, "_") + ".schema.json";
	var jsonBlob = new Blob([schemaText], {
		type: "application/json"
	});
	var localization = component.state.localization;
	var url = window.URL.createObjectURL(jsonBlob);

	return (
		<IconButton
			className="fa fa-download"
			aria-label={localization.get("download_file_button")}
			title={localization.get("download_file_button")}
			role="button"
			style={styles.icon}
			href={url}
			download={filename}
			onClick={() => component.state.userMetrics.trackEvent("admin-forms: download form schema", {
				"form": form._id,
				})}
		/>
	);
}

function handleTabValueChanged(component, event, value) {
	component.setState({
		tabValue: value
	});
	const eventText = [
		"admin-forms: navigate to edit form info tab in edit form popup",
		"admin-forms: navigate to edit form schema tab in edit form popup",
		"admin-forms: navigate to edit form prepopulate tab in edit form popup",
		"admin-forms: navigate to edit form externals tab in edit form popup"
	];
	const eventProperties = component.state?.updating?._id ? { "form": component.state.updating._id } : { creating: true };
	component.state.userMetrics.trackEvent(eventText[value], eventProperties);
}

function showFormRemoval(component, form) {
	component.state.userMetrics.trackEvent("admin-forms: open remove form popup", {
		"form": form._id
	});
	component.setState({
		toRemove: form
	});
}

function confirmRemove(component) {
	var id = component.state.toRemove;
	var viewing = component.state.viewing;
	component.state.removeForm(id, viewing);
	component.state.userMetrics.trackEvent("admin-forms: remove form", {
		"form": id._id
	});
	component.setState({
		toRemove: null
	});
}


function hideRemove(component) {
	component.state.userMetrics.trackEvent("admin-forms: close remove form popup");
	component.setState({
		toRemove: null
	});
}

function noop() { }

function getDialogTitle(currentPerson, form) {
	var person = currentPerson.get("personal");
	var language = person.language;
	var localization = form.localization;
	var name = localization[language] || localization[Object.keys(localization)[0]];
	return name;
}

function renderExternals(component){
	var state = component.state;
	var currentPerson = state.currentPerson.get("personal");
	var language = currentPerson.language;
	var localization = state.localization;
	var updating = state.updating;
	var creating = state.creating;
	var externals = findExternals(component);

	var currentExternals = (updating ? updating.externals : creating.externals) || {};

	var options = state.options.get(state.viewing) || [];
	var optionsMenu = options.map(option => {
		var schema = option.schema || {};
		var schemaLocalization = schema.localization || {};
		var displayText = schemaLocalization[language] || schemaLocalization[Object.keys(schemaLocalization)[0]] || localization.get("group_options_unknown");
		return (<MenuItem key={option._id} value={option._id}>{displayText}</MenuItem>);
	});

	var externalForm = externals ? Object.keys(externals).map(function(key){
		var menu = null;
		if(externals[key] === "option")
			menu = optionsMenu;
		else return null;

		return (
		<FormControl fullWidth>
			<InputLabel>{key}</InputLabel>
			<Select fullWidth value={currentExternals[key] || ""} onClick={par(updateExternal, component, key)}>
				{menu}
			</Select>
		</FormControl>
		);
	}) : null;

	return externalForm;
}

function findExternals(component){
	var form = component.state.updating || component.state.creating;
	var schemaText = form.schemaText;
	var schema = null;

	try {
		schema = JSON.parse(schemaText);
		if(schema.externals)
			return schema.externals;
		return null;
	} catch (err) {
		return null;
	}
}

function updateExternal(component, key, e){
	e.persist();
	var payload = e.target.value;
	var updating = component.state.updating;
	var update = component.state.creating || updating;
	if(!update.externals)
		update.externals = {};
	update.externals[key] = payload;

	component.state.userMetrics.trackEvent("admin-forms: edit external", {
		"form": update._id,
	});

	if(updating)
		updateUpdatingState(component, update);
	else
		updateCreatingState(component, update);
}