/* © 2016 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 2016
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";

import par from "par";
import * as Sentry from "@sentry/react";

import config from "../../configs/config.json";
import Header from "../shared/Header.js";
import IconButton from "@material-ui/1.5.1/IconButton";
import Avatar from "@material-ui/1.5.1/Avatar";
import List from "@material-ui/1.5.1/List";
import ListItem from "@material-ui/1.5.1/ListItem";
import ListItemText from "@material-ui/1.5.1/ListItemText";
import Button from "@material-ui/1.5.1/Button";
import Dialog from "../shared/Dialog";
import Toggle from "@material-ui/1.5.1/Switch";
import SubHeader from "@material-ui/1.5.1/ListSubheader";
import TextField from "@material-ui/1.5.1/TextField";
import Utility from "../shared/Utility";
import LazyList from "../shared/LazyList.jsx";
import  AetonixTheme_mui_1_5_1 from "../shared/AetonixTheme_mui_1.5.1";
const Colors = AetonixTheme_mui_1_5_1.palette;
var styles = {
	search: {
		color: Colors.primary.main,
		minWidth: "30%",
		padding: 8
	},
	input: {
		color: Colors.primary.main,
		padding: 8
	},
	inputProp: {
		style: {
			color: Colors.primary.main,
		}
	},
};

var toggleListClasses = {
	root: "ae-no-padding"
};

export default render;

function render() {
	var component = this;
	var state = component.state;

	var users = state.users;
	var people = state.people;
	var staff = state.staff;

	var localization = state.localization;
	var careplanNoticeList = state.careplanChangeList.all();
	var careplanChanges = !!careplanNoticeList.length;
	var offline = state.connection.get("offline");
	var currentPerson = state.currentPerson;
	var sortedUsers = users.map(people.get).sort(by_name_id);
	var sortedStaff = staff.map(people.get).sort(by_name_id);
	var filteredStaffs = state.filteredStaffs;

	var loadMore = state.loadMore;
	var searchExist = state.searchExist;
	var titleKey = "user_urgency_title";

	var urgency = [];

	var toRemove = state.toRemove;
	var viewing = state.viewing;
	if (viewing)
		urgency = state.urgency
			.get(viewing);

	var actions = [
		(<Button key={"buttonclose"} onClick={par(hideSearch, component)}>{localization.get("search_close")}</Button >)
	];

	var remove_actions = [(
		<Button key={"buttonok"} onClick={par(confirmRemove, component, viewing, toRemove)}>{localization.get("users_ok")}</Button >
	), (
		<Button key={"buttoncancel"} onClick={par(hideRemove, component)}>{localization.get("users_cancel")}</Button >
	)];


	var renderUserListItem = par(renderUser, component);


	return (
		<div className="flex-vertical flex-1">
			<Header
				careplanChanges={careplanChanges}
				offline={offline}
				currentPerson={currentPerson}
				localization={localization}
				titleKey={titleKey}
			/>
			<div className="flex-horizontal flex-1 ae-scrollable">
				<div className="flex-vertical flex-1 ae-left-margin">
					<SubHeader>{localization.get("user_manage_users")}</SubHeader>
					<TextField placeholder={localization.get("search_onNameorpatientNum")} inputProps={{"aria-label": localization.get("search_onNameorpatientNum")}} onChange={searchExist} style={styles.search} InputProps={styles.inputProp} />
					<LazyList loadMore={loadMore} renderItem={renderUserListItem} items={sortedUsers} />
				</div>
				<div className="flex-vertical flex-1">
					<SubHeader> {localization.get("user_urgency_respondants")}</SubHeader>
					{showFilter(component)}
					<List className="flex-1 ae-no-padding">
						{urgency.map(par(renderManager, component))}
					</List>
					{[renderOrdered(component, localization)]}
					{renderAdd(component)}
				</div>
			</div>

			<Dialog actions={actions} autoDetectWindowHeight={true} open={state.searching} title={localization.get("user_urgency_staff")}>
				<TextField placeholder={localization.get("search_onName")} inputProps={{"aria-label": localization.get("search_onName")}}  onChange={par(searchStaff, component)} style={styles.search} InputProps={styles.inputProp} />
				{sortedStaff.filter(par(filterPotentialStaff, filteredStaffs)).map(par(renderStaff, component, urgency))}
			</Dialog>

			<Dialog actions={remove_actions} open={!!toRemove} title={localization.get("urgency_remove")} >
				{localization.get("urgency_warning")}
				{Utility.format_name(people.get(toRemove))}
				?
			</Dialog>

		</div>
	);
}

function filterPotentialStaff(filteredStaffs, staff) {
	if (!filteredStaffs) return true;

	var regex = new RegExp(filteredStaffs, "i");

	return (staff.fname + staff.lname).match(regex);
}

function by_name_id(x, y) {
	var X = x.lname.toLowerCase();
	var Y = y.lname.toLowerCase();
	if (X === Y) {
		X = x.fname.toLowerCase();
		Y = y.fname.toLowerCase();
	}
	return order(X, Y);
}

function order(X, Y) {
	if (X < Y)
		return -1;
	if (X > Y)
		return 1;
	return 0;
}

function showFilter(component) {
	var state = component.state;
	var localization = state.localization;
	var viewing = state.viewing;
	if (!viewing) return;
	var urgency = state.urgency.get(viewing);
	if (!urgency || !urgency.length) return;

	return (
		<TextField placeholder={localization.get("search_onName")} inputProps={{"aria-label": localization.get("search_onName")}}  onChange={par(filter_name, component)} style={styles.search} InputProps={styles.inputProp} />
	);
}

function filter_name(component, e) {
	var query = e.target.value;
	var filtering = component.state.filtering;
	// if query is empty just return else split the string apart by "<space>"
	// and match any words beginning with the query string
	if (!query) {
		toggleFilterOff(component);
		return;
	} else {
		if (!filtering) toggleFilterOn(component);
		var regx = e.target.value.toLowerCase().split(" ").join("");
		var regxQuery = new RegExp(regx);
		component.setState({
			query: regxQuery
		});
	}
}

function do_filter(component, name) {
	var query = component.state.query;
	return name.toLowerCase().split(" ").join("").match(query);
}

function renderStaff(component, current, staff) {
	if (!staff._id) return;
	var state = component.state;
	var staffId = staff._id;
	var viewing = state.viewing;
	var avatar = <Avatar alt='Staff Avatar Image' src={config.image_cdn + staff.image} />;
	var text = Utility.format_name(staff);
	var add = par(state.addContact, viewing, staffId);
	var disabled = current.indexOf(staff._id) !== -1;

	return (
		<ListItem button disabled={disabled} key={staffId} onClick={add}>
			{avatar}
			<ListItemText primary={text} />
		</ListItem>
	);
}

function renderAdd(component) {
	var label = component.state.localization.get("user_manage_label");

	if (component.state.viewing)
		return (
			<Button variant="raised" onClick={par(showSearch, component)} color="secondary"  >{label}</Button>
		);
}

function renderOrdered(component, localization) {
	var viewing = component.state.viewing;
	if (!viewing)
		return;

	var preferences = component.state
		.urgencyPreferences
		.get(viewing);

	var onToggle = par(changeOrdered, component);
	var ordered = !!preferences.ordered;

	return (
		<ListItem key={"buttonordered"} classes={toggleListClasses}>
			<ListItemText primary={localization.get("call_in_order")} />
			<Toggle color="primary" inputProps={{ "aria-label": localization.get("call_in_order_toggle"), "role": "switch"}} checked={ordered} onChange={onToggle} />
		</ListItem>
	);
}

function changeOrdered(component, event, ordered) {
	component.state
		.setOrdered(component.state.viewing, ordered)
		.catch(error => {
			Sentry.captureException(error);
			console.error.bind(console, error);
		});
	component.state.userMetrics.trackEvent("user-urgency: toggle contacts to be called in order", {
		"patient": component.state.viewing,
		"ordered": ordered ? "yes" : "no",
	});
}

function renderManager(component, manager) {
	if (!manager) return;
	var people = component.state.people;
	var person = people.get(manager);
	var filtering = component.state.filtering;

	var avatar = <Avatar alt='Urgency Contact Person Avatar Image' src={config.image_cdn + person.image} />;
	var text = Utility.format_name(person);
	var localization = component.state.localization;
	var viewing = component.state.viewing;
	var remove = par(proposeRemove, component, viewing, manager);

	var button = (
		<IconButton color="primary" aria-label={localization.get("remove_button")} title={localization.get("remove_button")}  role='button' className="fa fa-user-times" onClick={remove} />
	);

	if (filtering && !do_filter(component, text)) {
		return;
	}

	var background_colour = "ae-plain";
	var manager_viewed = component.state.manager_viewed;
	if (manager_viewed && manager === manager_viewed) background_colour = "ae-hover-color";

	return (
		<ListItem key={manager} className={background_colour} onClick={par(set_manager_viewing, component, manager)}>
			{avatar}
			<ListItemText primary={text} />
			{button}
		</ListItem>
	);
}

function set_manager_viewing(component, manager) {
	component.setState({
		manager_viewed: manager
	});
	component.state.userMetrics.trackEvent("user-urgency: view urgency contact", {
		"urgency contact": manager,
		"patient": component.state.viewing
	});
}

function renderUser(component, user, index) {
	if (!user._id) return;
	var avatar = <Avatar alt='User Avatar Image' src={config.image_cdn + user.image} />;
	var text = Utility.format_name(user);
	var userIndex = (index + 1).toString();
	var userId = user._id;
	var background_colour = "ae-plain";
	var viewing = component.state.viewing;
	if (userId === viewing) background_colour = "ae-hover-color";

	return (
		<ListItem key={userId} className={background_colour} value={userIndex} onClick={par(viewManagersFor, component, userId, userIndex)} >
			{avatar}
			<ListItemText primary={text} />
		</ListItem>
	);
}

function showSearch(component) {
	component.setState({
		searching: true
	});
	component.state.userMetrics.trackEvent("user-urgency: open search popup");
}

function hideSearch(component) {
	component.setState({
		searching: false
	});
	component.state.userMetrics.trackEvent("user-urgency: close search popup");
}

function viewManagersFor(component, user, userIndex) {
	component.setState({
		viewing: user,
		selectedIndex: userIndex
	});
	component.state.userMetrics.trackEvent("user-urgency: view urgency contacts", {
		"patient": user
	});
}

function toggleFilterOn(component) {
	component.setState({
		filtering: true
	});
}

function toggleFilterOff(component) {
	component.setState({
		filtering: false
	});
}

function searchStaff(component, event) {
	var query = event.target.value;
	component.setState({
		filteredStaffs: query
	});
}

function proposeRemove(component, user, manager, event) {
	event.stopPropagation();
	component.setState({
		toRemove: manager
	});
	component.state.userMetrics.trackEvent("user-urgency: open remove urgency contact popup");
}

function confirmRemove(component, user, manager) {
	component.state.removeContact(user, manager);
	hideRemove(component);
	component.state.userMetrics.trackEvent("user-urgency: remove urgency contact");
}

function hideRemove(component) {
	component.setState({
		toRemove: null
	});
	component.state.userMetrics.trackEvent("user-urgency: close remove urgency contact popup");
}
