import { Avatar, Checkbox, List, ListItem, ListItemSecondaryAction, ListItemText } from "@material-ui/1.5.1";
import React, { useMemo } from "react";
import { useResources } from "../shared/contexts/ResourceContext";
import Utility from "../shared/Utility";
import { useUserMetrics } from "@aetonix/user-metrics";

const PeopleSelectorCheckList = ({
	personsList = [],
	selectedList = [],
	onPersonSelect = () => {},
	onPersonRemove = () => {},
	idToUser = () => {}
}) => {
	const selectedSet = useMemo(
		() => {
			return new Set(selectedList.map(userId => userId));
		},
		[selectedList]
	);

	const { userMetrics } = useUserMetrics();

	return (
		<List>
			{personsList.map(id => {
				const selected = selectedSet.has(id);
				return (
					<PeopleListItem
						user={idToUser(id)}
						onToggle={() => {
							if (selected) {
								onPersonRemove(id);
							} else {
								onPersonSelect(id);
							}
							userMetrics.trackEvent(`user-management: ${selected ? "check" : "uncheck"} person in bulk edit`, {
								person: id
							});
						}}
						checked={selected}
						key={id}
					/>
				);
			})}
		</List>
	);
};

const PeopleListItem = ({ user, onToggle, onClick, disabled, checked }) => {
	const userId = user._id;
	const resources = useResources();
	const config = resources.config;

	const avatar = <Avatar src={config.image_cdn + user.image} />;
	const text = Utility.format_name(user);

	var button = (
		<ListItemSecondaryAction>
			<Checkbox onChange={onToggle} checked={checked} />
		</ListItemSecondaryAction>
	);
	return (
		<ListItem key={userId} onClick={onClick} disabled={disabled} button={!!onClick}>
			{avatar}
			<ListItemText primary={text} />
			{button}
		</ListItem>
	);
};
export default PeopleSelectorCheckList;
