import React, { Fragment, useMemo, useState } from "react";
import { useLocalize } from "../shared/contexts/LocalizeContext";
import PermissionsList from "../shared/PermissionsList";
import { Button, Checkbox, MenuItem, Select } from "@material-ui/1.5.1";
import { ImportantButton, PrimaryButton } from "../shared/Buttons";
import { styled } from "@material-ui/styles";
const SETTINGS_PERMISSIONS = PermissionsList.SettingsPermissions;
const SETTINGS_SELECT_PERMISSIONS = {
	manage: [
		{
			value: 0,
			text: "permissions_noaccess"
		},
		{
			value: 1,
			text: "permissions_viewonly"
		},
		{
			value: 3,
			text: "permissions_viewmodify"
		}
	],
	resources: [
		{
			value: 0,
			text: "permissions_noaccess"
		},
		{
			value: 1,
			text: "permissions_view"
		},
		{
			value: 2,
			text: "permissions_readadjustaccess"
		}
	],
	devices: [
		{
			value: 0,
			text: "permissions_noaccess"
		},
		{
			value: 1,
			text: "permissions_view"
		},
		{
			value: 2,
			text: "permissions_viewedit"
		}
	]
};

const DEFAULT_SETTINGS_VALUE = SETTINGS_PERMISSIONS.reduce((prev, curr) => {
	prev[curr] = 0;
	return prev;
}, {});

const permissionFieldFactory = fieldKey => {
	const selectData = SETTINGS_SELECT_PERMISSIONS[fieldKey];

	if (selectData) {
		const PermissionSelectComp = props => {
			const localize = useLocalize();
			return (
				<PermissionSelect  classes={{ root: "ae-width" }} {...props}>
					{selectData.map(menuItem => {
						return (
							<MenuItem key={menuItem.value} value={menuItem.value}>
								{localize(menuItem.text)}
							</MenuItem>
						);
					})}
				</PermissionSelect>
			);
		};
		return PermissionSelectComp;
	} else {
		const PermissionCheckBoxComp = props => {
			return <PermissionCheckBox className='ae-widthauto' {...props} />;
		};
		return PermissionCheckBoxComp;
	}
};

const PermissionSelect = props => {
	const localize = useLocalize();
	const onChange = event => {
		props.onChange(event.target.value);
	};
	return <Select inputProps={{"aria-label": localize("customoptions.select") }} classes={{ root: "ae-width" }} {...props} onChange={props.onChange && onChange} />;
};

const PermissionCheckBox = props => {
	const localize = useLocalize();
	const onChange = event => {
		props.onChange(event.target.checked ? 1 : 0);
	};
	return <Checkbox {...props} onChange={props.onChange && onChange} inputProps={{ "aria-label": localize("checkbox_button"), "role": "checkbox"}} checked={!!props.value} readOnly />;
};

const ActionAreaContainer = styled("div")({ position: "sticky", bottom: 0, display: "flex", flexDirection: "row" });

const Spacer = styled("div")({ height: 8 });
export const PermissionsForm = ({
	forms,
	formsPermissions = {},
	permissions = {},
	onSubmit,
	onCancel,
	language = "en"
}) => {
	const defaultAetonixPermissionState = {
		...DEFAULT_SETTINGS_VALUE,
		...permissions
	};
	const defaultFormsPermissionsState = formsPermissions;
	const defaultFormsPermissions = useMemo(
		() =>
			forms.reduce((acc, form) => {
				acc[form._id] = 0;
				return acc;
			}, {}),
		[forms]
	);

	const defaultPermissionObject = {
		...defaultAetonixPermissionState,
		groupforms: Object.assign(
			{},
			defaultFormsPermissions,
			defaultFormsPermissionsState,
			defaultAetonixPermissionState.groupforms || {}
		)
	};
	const [formState, setFormState] = useState(defaultPermissionObject);
	const localize = useLocalize();

	const aePermSection = SETTINGS_PERMISSIONS.map(fieldKey => {
		if (fieldKey === "files" || fieldKey === "notes") return null;

		let onChange = val => {
			let newState = { ...formState };
			newState[fieldKey] = val;
			setFormState(newState);
		};
		if (fieldKey === "forms") {
			fieldKey = "notes";

			onChange = val => {
				const newState = { ...formState };
				newState["notes"] = val;
				newState["files"] = val;
				newState["forms"] = val;
				setFormState(newState);
			};
		}
		const value = formState[fieldKey];
		const Field = useMemo(() => permissionFieldFactory(fieldKey), [fieldKey]);
		return (
			<div className='flex-horizontal flex-1 ae-justify ae-align-centre' key={fieldKey}>
				{localize(composeKey(fieldKey))}
				<Field value={value} onChange={onChange} />
			</div>
		);
	});

	const formsPermSection = useMemo(
		() =>
			forms.map(form => {
				const formKey = form._id;
				const value = formState.groupforms[formKey];

				const label = form.localization[language] || form.localization["en"];

				const isMulti = form.formType === "multi";
				const multiOnlyPermission = [<MenuItem value={-2}>{localize("permissions_readwrite_family")}</MenuItem>];
				return (
					<div key={formKey} className='flex-horizontal ae-justify ae-align-centre ae-bottom-margin'>
						{label}
						<PermissionSelect
							classes={{
								root: "ae-width"
							}}
							value={value}
							onChange={val => {
								let newState = { ...formState };
								let newGroupForms = { ...newState.groupforms };
								newGroupForms[formKey] = val;
								newState.groupforms = newGroupForms;
								setFormState(newState);
							}}
						>
							<MenuItem value={0}>{localize("permissions_noaccess")}</MenuItem>
							<MenuItem value={1}>{localize("permissions_readonly")}</MenuItem>
							<MenuItem value={2}>{localize("permissions_readwrite")}</MenuItem>
							{isMulti && multiOnlyPermission}
						</PermissionSelect>
					</div>
				);
			}),
		[formState, forms]
	);

	const selectAllAePermissions = () => {
		let newState = { ...formState };
		for (let key in newState) {
			if (key !== "groupforms") {
				newState[key] = 1;
			}
		}
		setFormState(newState);
	};

	const selectAllFormPermissions = () => {
		let newState = { ...formState };
		let newGroupForms = { ...newState.groupforms };
		for (let key in newGroupForms) {
			newGroupForms[key] = 2;
		}

		newState.groupforms = newGroupForms;
		setFormState(newState);
	};

	return (
		<Fragment>
			<Spacer />
			<PermissionTitle title='aetonix' setAllButtonOnPress={selectAllAePermissions}></PermissionTitle>
			{aePermSection}
			<Spacer />
			<PermissionTitle title='forms' setAllButtonOnPress={selectAllFormPermissions}></PermissionTitle>
			{formsPermSection}
			<ActionAreaContainer>
				{onCancel && (
					<div style={{ flex: 1, marginRight: 30 }}>
						<ImportantButton variant='contained' fullWidth onClick={onCancel}>
							{localize("common.cancel")}
						</ImportantButton>
					</div>
				)}
				{onSubmit && (
					<div style={{ flex: 3 }}>
						<PrimaryButton
							variant='contained'
							fullWidth
							onClick={() => {
								onSubmit(formState);
							}}
						>
							{localize("common.save")}
						</PrimaryButton>
					</div>
				)}
			</ActionAreaContainer>
		</Fragment>
	);
};

const PermissionTitle = ({ title, setAllButtonOnPress }) => {
	let localize = useLocalize();
	var section_title = localize("permissions_" + title + "_section");
	var select = localize("permissions_select_all");
	return (
		<div className='flex-vertical flex-1'>
			<div className='ae-permission-section'>{section_title}</div>
			<div className='ae-selectall'>
				{setAllButtonOnPress && (
					<span>
						<Button className='ae-widthauto' color='primary' onClick={setAllButtonOnPress}>
							{select}
						</Button>
					</span>
				)}
			</div>
		</div>
	);
};
function composeKey(key) {
	return `permissions_${key}`;
}
