import * as Sentry from "@sentry/react";
import React, { Component } from "react";

export class ListErrorBoundary extends Component {
	constructor(props) {
		super(props);

		this.state = { error: null, errorInfo: null };
	}

	componentDidCatch(error, errorInfo) {
		this.setState({ error, errorInfo }, () => {
			Sentry.captureException(error);
		});
	}

	render() {
		const { error, errorInfo } = this.state;
		const { children } = this.props;

		if (errorInfo) {
			return (
				<div style={{ margin: "20px" }}>
					<h2>Something went wrong.</h2>
					<details style={{ whiteSpace: "pre-wrap" }}>
						{error && error.toString()}
						<br />
						{errorInfo.componentStack}
					</details>
				</div>
			);
		}

		return children;
	}
}
