/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

/**
 * Staff info store will be an array of objects identified by the staff _id and containing
 * an a list of key:value pairs eg. autologout: true
 */




import Store from "./store";
import par from "par";
import xtend from "xtend";

import * as Sentry from "@sentry/react";

export default StaffPreferencesStore;

function StaffPreferencesStore(api, events, config) {

	var store = new Store();

	store.raw_get = store.get;
	store.get = get;
	store.getPreference  = retrieveStaffInfo;

	var tracking = [];

	return store;

	function get(id) {
		var existing = store.raw_get(id);
		if (existing) return existing;

		if (!isTracking(id))
			track(id);

		return {};
	}

	function isTracking(id) {
		return tracking.indexOf(id) !== -1;
	}

	function track(id) {
		tracking.push(id);
		retrieveStaffInfo(id);
		listen_for_changes(id);
	}

	function retrieveStaffInfo(id) {
		var existing = store.get(id);
		if (!existing) return;

		api.org.staff.preferences.get(id)
			.then(par(setPreferences, id))
			.catch(error => {
				Sentry.captureException(error);
				console.error.bind(console, error);
			});
	}

	function setPreferences(id, data) {
		var objData = data[0];
		if (!objData){
			objData = data;
		}
		var preferences = objData;
		store.set(id, preferences);
	}

	function updatePreferences(id, data){
		var current = store.get(id);
		if(!current) return;

		var newData = xtend(current, data);
		store.set(id, newData);
	}

	function listen_for_changes(id) {
		var orgGroup = config.orgGroup;
		if(!orgGroup) return;
		events.on("mqtt:orggroup/" + orgGroup + "/u/" + id + "/settings", par(updatePreferences, id));
	}
}
