/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import * as Sentry from "@sentry/react";

import Store from "./store";

export default OrgCustomIndicators;

function OrgCustomIndicators(api, events, config) {
	var store = new Store();
	reset();

	store.all = all;
	store.createIndicator = createIndicator;
	store.updateIndicator = updateIndicator;
	store.deleteIndicator = deleteIndicator;

	return store;

	function reset() {
		return api.org
			.indicators()
			.then(indicators => {
				set_store(indicators);
				trackChanges();
			})
			.catch(error => {
				Sentry.captureException(error);
				store.error(error);
			});
	}

	function set_store(indicators) {
		if (!indicators) {
			return;
		}

		indicators?.forEach?.(indicator => store.set(indicator._id, indicator));
	}

	function all() {
		let indicators = [];

		if (!store._data) {
			return indicators;
		}

		Object.keys(store._data).forEach(key => {
			indicators = [
				...indicators,
				{
					...store?.get?.(key),
					_id: key
				}
			];
		});

		return indicators;
	}

	function trackChanges() {
		const org = config.organization;

		if (!org) {
			return;
		}

		events.on(`mqtt:org/${org}/indicators/added`, handleCreate);
		events.on(`mqtt:org/${org}/indicators/removed`, handleDelete);
		events.on(`mqtt:org/${org}/indicators/updated`, handleUpdate);
	}

	function handleCreate(indicator) {
		store.set(indicator._id, indicator);
	}

	function handleDelete(indicatorId) {
		store.remove(indicatorId);
	}

	function handleUpdate(indicator) {
		store.set(indicator._id, indicator);
	}

	async function updateIndicator(indicatorId, indicator) {
		try {
			return await api.indicators.update(indicatorId, indicator);
		} catch (error) {
			Sentry.captureException(error);
			store.error(error);

			return null;
		}
	}

	async function createIndicator(indicator) {
		try {
			return await api.indicators.create(indicator);
		} catch (error) {
			Sentry.captureException(error);
			store.error(error);

			return null;
		}
	}

	async function deleteIndicator(indicatorId) {
		try {
			return await api.indicators.remove(indicatorId);
		} catch (error) {
			Sentry.captureException(error);
			store.error(error);

			return null;
		}
	}
}
