import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import React from "react";

export function ConfirmationDialog({ onConfirm, onCancel, open, title, message, confirmText, cancelText }) {
	return (
		<Dialog
			open={open}
			onClose={onCancel}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			{title && <DialogTitle>{title}</DialogTitle>}
			<DialogContent>
				<DialogContentText id="alert-dialog-description">{message}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={onConfirm} color="primary">
					{confirmText}
				</Button>
				<Button onClick={onCancel} color="primary" autoFocus>
					{cancelText}
				</Button>
			</DialogActions>
		</Dialog>
	);
}
