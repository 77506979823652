
/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import par from "par";

import debounce from "debounce";
import defineUI from "../shared/define";
import render from "./render.jsx";
import PersonStore from "../stores/person_store";
import OrgGroupStore from "../stores/org_group_store";
import LocalizationStore from "../stores/localization_store";
import CurrentPersonInfoStore from "../stores/current_person_info_store";
import CareplanNotificationStore from "../stores/careplan_notice_store";
import ConnectionStore from "../stores/connection_store";
import makeStoreFactory from "../shared/stores";

const makeStores = makeStoreFactory({
	people: PersonStore,
	groups: OrgGroupStore,
	currentPerson: CurrentPersonInfoStore,
	localization: LocalizationStore,
	careplanChangeList: CareplanNotificationStore,
	connection: ConnectionStore
});

export default make_ui;

function make_ui(api, events, config, userMetrics) {
	var storeStore = makeStores(api, events, config);

	window.stores = storeStore.store;

	userMetrics.trackEvent("page view", {
		"page": "org-groups",
		"category": "group",
		"permission level": "org:manager",
	});

	return defineUI({
		getInitialState: getInitialState,
		componentDidMount: listen_for_changes,
		render: render,
		componentWillUnmount: removeListeners
	});

	function removeListeners() {
		events.off();
	}

	function listen_for_changes() {
		storeStore.listen(par(handle_change, this));
	}

	function getInitialState() {
		return {
			addGroup: addGroup,
			confirming: false,
			creating: false,
			currentPerson: storeStore.stores.currentPerson,
			editdescription: "",
			editgroupname: "",
			editing: null,
			groups: storeStore.stores.groups,
			groupList: [],
			loadMore: par(loadMore, storeStore.stores.groups),
			localization: storeStore.stores.localization,
			members: [],
			people: storeStore.stores.people,
			removeAdministrator: removeAdministrator,
			searchExist: par(searchExist, storeStore.stores.groups),
			searchNew: debounce(searchNew.bind(this), 200),
			searchResults: [],
			setAdministrator: setAdministrator,
			toAdd: null,
			toRemove: null,
			updateGroup: updateGroup,
			careplanChangeList: storeStore.stores.careplanChangeList,
			connection: storeStore.stores.connection,
			userMetrics: userMetrics,
		};
	}

	function handle_change(component, stores) {
		component.setState({
			groupList: stores.groups.all(),
			people: stores.people,
			currentPerson: stores.currentPerson,
			localization: stores.localization,
			careplanChangeList: stores.careplanChangeList,
			connection: stores.connection
		});
	}

	function addGroup(name) {
		api.org.group.create(name);
	}

	function updateGroup(group, data) {
		api.org.group.update(group, data);
	}

	function setAdministrator(group, who) {
		api.org.administrator.set(group, who);
	}

	function removeAdministrator(group) {
		api.org.administrator.remove(group);
	}

	function searchNew(query) {

		var filter = {
			type: {
				exclude: ["atouchaway", "user:mobile", "simplified"]
			}
		};

		api.people.search(query, filter)
			.then(set_search_results.bind(this));
	}

	function set_search_results(results) {
		this.setState({
			searchResults: results
		});
	}

	function loadMore(groups){
		return groups.more();
	}

	function searchExist(groups, event){
    event.persist();
    var query = event.target.value;
		return groups.search(query);
	}

}
