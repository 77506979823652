
/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, May 3, 2018
 * For information or permission request, email info@aetonixsystems.com
 */

import "./org-stats.css";

import React from "react";
import par from "par";
import Header from "../shared/Header.js";
import Table from "@material-ui/1.5.1/Table";
import TableHeader from "@material-ui/1.5.1/TableHead";
import TableHeaderColumn from "@material-ui/1.5.1/TableCell";
import TableBody from "@material-ui/1.5.1/TableBody";
import TableRow from "@material-ui/1.5.1/TableRow";
import TableRowColumn from "@material-ui/1.5.1/TableCell";
import TableSortLabel from "@material-ui/1.5.1/TableSortLabel";
import Paper from "@material-ui/1.5.1/Paper";

export default render;

function render() {
	var component = this;
	var state = component.state;
	var localization = state.localization;
	var careplanNoticeList = state.careplanChangeList.all();
	var careplanChanges = !!careplanNoticeList.length;
	var offline = state.connection.get("offline");
	var currentPerson = state.currentPerson;

	return (
		<div className="flex-vertical flex-1">
			<Header
				careplanChanges={careplanChanges}
				offline={offline}
				currentPerson={currentPerson}
				localization={localization}
				titleKey={"dashboards_org_stats_title"}
			/>
			<div className="flex-vertical flex-1 ae-scrollable">
				{renderStats(component)}
			</div>
		</div>
	);
}

function renderStats(component){
	return (
		<div className="flex-vertical">
			{renderOrgStats(component)}
			{renderGroupStats(component)}
		</div>
	);
}

function renderOrgStats(component){
	var orgStats = component.state.orgCount;
	var localization = component.state.localization;
	var currentPerson = component.state.currentPerson;
	var org = currentPerson.get("org") || {};
	var orgName = org.name || "";

	return (
		<Paper className="flex-vertical org-container ae-align-centre">
			<div className="flex-horizontal org-title-container">
				<div className="ae-title org-title-item">{`${orgName} ${localization.get("org_stats_org_overview")}`}</div>
			</div>
			<div className="flex-horizontal">
				{renderBox(localization.get("admin_stats_groups"), orgStats.groups)}
				{renderBox(localization.get("admin_stats_managers"), orgStats.managers)}
				{renderBox(localization.get("org_stats_users"), orgStats.users)}
				{renderBox(localization.get("admin_stats_staff"), orgStats.staff)}
				{renderBox(localization.get("admin_stats_patients"), orgStats.patients)}
				{renderBox(localization.get("admin_stats_active"), orgStats.active)}
				{renderBox(localization.get("admin_stats_inactive"), orgStats.inactive)}
			</div>
		</Paper>
	);
}

function renderGroupStats(component){
	var localization = component.state.localization;
	var sorting = component.state.sorting;
	var sortDirection = component.state.sortDirection;
	var groups = component.state.groups;

	var sortedGroups = sortGroups(component, groups);

	if(!groups || !groups.length) return;

	var renderGroupRow = sortedGroups.map(group => renderGroupStat(component, group));

	return (
		<div className="group-container flex-vertical ae-align-centre">
			<div className="ae-title">{localization.get("admin_stats_groups_overview")}</div>
		<Table>
			<TableHeader>
				<TableRow>
					<TableHeaderColumn>
						<TableSortLabel active={sorting === "name"} direction={sortDirection} onClick={par(changeSort, component, "name")}>
							{localization.get("admin_stats_name")}
						</TableSortLabel>
					</TableHeaderColumn>
					<TableHeaderColumn align="right">
						<TableSortLabel active={sorting === "users"} direction={sortDirection} onClick={par(changeSort, component, "users")}>
							{localization.get("org_stats_users")}
						</TableSortLabel>
					</TableHeaderColumn>
					<TableHeaderColumn align="right">
						<TableSortLabel active={sorting === "staff"} direction={sortDirection} onClick={par(changeSort, component, "staff")}>
							{localization.get("admin_stats_staff")}
						</TableSortLabel>
					</TableHeaderColumn>
					<TableHeaderColumn align="right">
						<TableSortLabel active={sorting === "patients"} direction={sortDirection} onClick={par(changeSort, component, "patients")}>
							{localization.get("admin_stats_patients")}
						</TableSortLabel>
					</TableHeaderColumn>
					<TableHeaderColumn align="right">
						<TableSortLabel active={sorting === "active"} direction={sortDirection} onClick={par(changeSort, component, "active")}>
							{localization.get("admin_stats_active")}
						</TableSortLabel>
					</TableHeaderColumn>
					<TableHeaderColumn align="right">
						<TableSortLabel active={sorting === "inactive"} direction={sortDirection} onClick={par(changeSort, component, "inactive")}>
							{localization.get("admin_stats_inactive")}
						</TableSortLabel>
					</TableHeaderColumn>
				</TableRow>
			</TableHeader>
			<TableBody>
				{renderGroupRow}
			</TableBody>
		</Table>
	</div>
	);
}

function changeSort(component, name){
	var current = component.state.sorting;
	var sortDirection = component.state.sortDirection;

	if(current !== name)
		sortDirection = "asc";
	else
		sortDirection = sortDirection === "asc" ? "desc" : "asc";

	component.setState({
		sorting: name,
		sortDirection: sortDirection
	});

	component.state.userMetrics.trackEvent("org-stats: change sort", {
		sort: name,
		direction: sortDirection
	});
}

function renderGroupStat(component, group){
	var groupId = group._id;
	var name = group.name;

	var stats = component.state.groupCount.get(groupId);

	return (
		<TableRow>
			<TableRowColumn component="th" scope="row">
				{name}
			</TableRowColumn>
			<TableRowColumn>{stats.users}</TableRowColumn>
			<TableRowColumn>{stats.staff}</TableRowColumn>
			<TableRowColumn>{stats.patients}</TableRowColumn>
			<TableRowColumn>{stats.active}</TableRowColumn>
			<TableRowColumn>{stats.inactive}</TableRowColumn>
		</TableRow>
	);
}

function renderBox(title, count){
	return (
		<div className="flex-1 flex-vertical ae-align-centre box-container" style={{paddingRight: 40}}>
			<div className="ae-title box-title" style={{whiteSpace: 'nowrap'}}>{title}</div>
			<div className="box-value">{count}</div>
		</div>
	);
}

function sortGroups(component, groups){
	var sorting = component.state.sorting;
	var sortDirection = component.state.sortDirection;

	var sortFunction = par(by_name_group, sortDirection);
	if(sorting !== "name")
		sortFunction = par(by_count, sortDirection, sorting, component);

	return groups.sort(sortFunction);
}

function by_count(direction, sorting, component, x, y){
	var countX = component.state.groupCount.get(x._id);
	var countY = component.state.groupCount.get(y._id);

	var statX = countX[sorting];
	var statY = countY[sorting];

	return direction === "asc" ? statX - statY : statY - statX;
}

function by_name_group(direction, x, y){
	var X = x.name.toLowerCase();
	var Y = y.name.toLowerCase();
	if (X < Y) return direction === "asc" ? -1 : 1;
	if (X > Y) return direction === "asc" ? 1 : -1;
	return 0;
}
