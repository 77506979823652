/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

import par from "par";

import React from "react";
import config from "../../configs/config.json";
import isEmpty from "is-empty";
import OutputForm from "ae-forms/src/Outputs/Output";
import HTMLOutput from "ae-forms/src/TextOutput/HTMLOutput";
import base64_js from "base64-js";
import utf8ToBytes from "../shared/utf8ToBytes";
import Table from "@material-ui/1.5.1/Table";
import TableHeader from "@material-ui/1.5.1/TableHead";
import TableHeaderColumn from "@material-ui/1.5.1/TableCell";
import TableBody from "@material-ui/1.5.1/TableBody";
import TableRow from "@material-ui/1.5.1/TableRow";
import TableRowColumn from "@material-ui/1.5.1/TableCell";
import Button from "@material-ui/1.5.1/Button";
import Dialog from "../shared/Dialog";
import DatePicker from "../shared/DatePicker";
import TextField from "@material-ui/1.5.1/TextField";
import MenuItem from "@material-ui/1.5.1/MenuItem";
import Chip from "@material-ui/1.5.1/Chip";
import Avatar from "@material-ui/1.5.1/Avatar";
import FontIcon from "@material-ui/1.5.1/Icon";
import Select from "@material-ui/1.5.1/Select";
import InputLabel from "@material-ui/1.5.1/InputLabel";
import FormControl from "@material-ui/1.5.1/FormControl";
import Snackbar from "@material-ui/1.5.1/Snackbar";
import IconButton from "@material-ui/1.5.1/IconButton";

/**
 * User Interface elements Aetonix
 */
import Header from "../shared/Header.js";

import Utility from "../shared/Utility.js";
import Scroll from "../shared/InfiniScroll.jsx";
import SearchTable from "../shared/SearchTable.jsx";
import  AetonixTheme_mui_1_5_1 from "../shared/AetonixTheme_mui_1.5.1";
const Colors = AetonixTheme_mui_1_5_1.palette;

var styles = {
	date: {
		display: "flex",
		flexDirection: "column",
		color: Colors.primary.main
	},
	dateEntry: {
		marginBottom: "30px"
	},
	icon: {
		fontSize: 20,
		color: Colors.canvas
	},
	avatar: {
		"backgroundColor": Colors.primary.dark
	},
	back: {
		"backgroundColor": Colors.primary.light,
		color: Colors.primary.main
	},
	backPrimary: {
		"backgroundColor": "#E0E0E0"
	},
	avatarPrimary: {
		"backgroundColor": "#BCBCBC"
	},
	modalSelects: {
		display: "flex",
		flexDirection: "column",
		marginBottom: "15px"
	},
	inputProp: {
		style: {
			color: Colors.primary.main,
		}
	},
	selectProp: {
		PaperProps: {
			style: {
				transform: "translate3d(0, 0, 0)"
			}
		}
	},
	snackbar: {
		style: {
			backgroundColor: "red"
		}
	}
};

export default render;

function render() {
	var component = this;
	var state = component.state;
	var localization = state.localization;
	var careplanNoticeList = state.careplanChangeList.all();
	var careplanChanges = !!careplanNoticeList.length;
	var offline = state.connection.get("offline");
	var currentPerson = state.currentPerson;
	var people = state.people;

	var rowData = generate_data(component);

	var report = state.report;
	var fileName = state.fileName;
	var loadMore = state.more;

	// Search and filter related variables
	var choose_date = localization.get("choose_date");
	var filterOwners = state.filterOwners;
	var searchActorName = state.searchActorName;
	var closeOwnerSearch = state.closeOwnerSearch;
	var respondFilter = state.respondFilter; // state of the date search modal
	var actorFilter = state.actorFilter; // state of the actor search modal
	var ownerFilter = state.ownerFilter; // state of the owner search modal
	var workflowFilter = state.workflowFilter; // state of the workflow search modal
	var actionFilter = state.actionFilter; // state of the action search modal
	var errorToast = state.errorToast;
	var errorMessage = localization.get("no_ongoing_workflow");

	var startRespond = state.startRespond;
	var endRespond = state.endRespond;
	var titleKey = "workflow_history";

	var openingDataView = state.openingDataView; // state of the data modal

	var report_actions = [csvReport(component, localization), (
		<Button key={"buttoncancel"} onClick={par(hideReport, component)}>
			{localization.get("report_cancel")}
		</Button >
	)];

	var respond_actions = [
		<Button key={"buttonapply"} onClick={par(handle_date, component, "respond")}>
			{localization.get("workflow_history_apply")}
		</Button >,
		<Button key={"buttoncancel"} onClick={par(hideSearch, component, "respond")}>
			{localization.get("workflow_history_cancel")}
		</Button >
	];

	var workflow_actions = [
		<Button key={"buttonapply"}
			onClick={par(handle_named_workflow, component)}>
			{localization.get("workflow_history_apply")}
		</Button >,
		<Button key={"buttoncancel"}
			onClick={par(hideSearch, component, "workflow")}>
			{localization.get("workflow_history_cancel")}
		</Button >
	];

	var action_actions = [
		<Button key={"buttonapply"}

			onClick={par(handle_actions, component)}>
			{localization.get("workflow_history_apply")}
		</Button >,
		<Button key={"buttoncancel"}
			onClick={par(hideSearch, component, "action")}>
			{localization.get("workflow_history_cancel")}
		</Button >
	];

	var actor_actions = [
		<Button key={"buttonapply"}
			onClick={par(handle_actor, component)}>
			{localization.get("workflow_history_apply")}
		</Button >,
		<Button key={"buttoncancel"}
			onClick={par(hideSearch, component, "actors")}>
			{localization.get("workflow_history_cancel")}
		</Button >
	];

	// Functionality for executing a download: create an html file name (urlName)
	// and a properly encoded (base64) file (urlDownload)
	var urlContent = "";
	var urlName = "";

	try {
		urlContent = getUrlContent(component);
		urlName = getUrlName(component);
	} catch(e) {
		console.error(e);
	}

	var data_view_actions = [
		<a
			key={"buttondownload"}
			href={urlContent}
			download={urlName}
			onClick={() =>
				component.state.userMetrics.trackEvent("workflow-history: download report", {
					workflow: state.chosen_workflow,
					url: urlContent,
					name: urlName,
				})
			}
		>
			{localization.get("download")}
		</a>,
		<Button key={"buttonclose"} onClick={par(handle_close_dataView, component)}>
			{localization.get("search_close")}
		</Button>
	];

	var headers = [
		{
			name: "label",
			content: localization.get("workflow_history_label")
		},
		{
			name: "responded",
			content: localization.get("workflow_history_responded")
		},
		{
			name: "owner",
			content: localization.get("workflow_history_owner")
		},
		{
			name: "actor",
			content: localization.get("workflow_history_actor")
		},
		{
			name: "action",
			content: localization.get("workflow_history_action")
		},
		{
			name: "data",
			content: localization.get("workflow_history_data")
		},
	];

	return (
		<div className="flex-vertical flex-1">

			<Header
				careplanChanges={careplanChanges}
				offline={offline}
				currentPerson={currentPerson}
				localization={localization}
				titleKey={titleKey}
			/>
			<div className="flex-horizontal ae-empty">
				{renderWorkflowFilterChip(component)}
				{renderRespondFilterChip(component)}
				{renderOwnerFilterChip(component)}
				{renderActorFilterChip(component)}
				{renderActionFilterChip(component)}
			</div>

			<Scroll loadMore={loadMore}>
				<Table>
					{renderHeader(component, headers)}
					{renderBody(component, rowData, headers)}
				</Table>
			</Scroll>

			<Dialog actions={report_actions} open={report} title={localization.get("groupreminder_report_title")}>
				<div>
					<DatePicker invalid={choose_date}
						labelStart={localization.get("report_start")}
						labelEnd={localization.get("report_end")}
						startDate={startRespond}
						endDate={endRespond}
						updateStart={par(updateChanged, component, "startRespond")}
						updateEnd={par(updateChanged, component, "endRespond")}
					/>
					<TextField fullWidth placeholder={localization.get("report_filename")} inputProps={{"aria-label": localization.get("report_filename")}} value={fileName} onChange={par(updateFileNameChanged, component)} InputProps={styles.inputProp} />
				</div>
			</Dialog>

			<Dialog actions={respond_actions} open={respondFilter} title={localization.get("search_dateRespond")}>
				<DatePicker invalid={choose_date}
					labelStart={localization.get("report_start")}
					labelEnd={localization.get("report_end")}
					startDate={startRespond}
					endDate={endRespond}
					updateStart={par(updateChanged, component, "startRespond")}
					updateEnd={par(updateChanged, component, "endRespond")}
				/>
			</Dialog>

			<Dialog actions={workflow_actions} open={workflowFilter} title={localization.get("search_workflow")}>
				{renderWorkflowFilter(component)}
			</Dialog>

			<Dialog multiple={true} actions={action_actions} open={actionFilter} title={localization.get("search_action")}>
				{renderActionFilter(component)}
			</Dialog>

			<Dialog multiple={true} actions={actor_actions} open={actorFilter} title={localization.get("search_actors")}>
				{renderActorFilter(component)}
			</Dialog>

			<Dialog actions={data_view_actions}
				open={openingDataView}>
				{renderDataModal(component)}
			</Dialog>

			<SearchTable action={par(filterOwners, component)}
				ref="searchDialog"
				search={searchActorName}
				localization={localization}
				people={people}
				showing={ownerFilter}
				onClose={closeOwnerSearch}
				title={localization.get("search_workflow_owner")}
			/>

			<Snackbar
				ContentProps={styles.snackbar}
				open={errorToast}
				autoHideDuration={6000}
				onClose={par(hideToast, component)}
				message={errorMessage}
				action={<IconButton className="fa fa-times" onClick={par(hideToast, component)}></IconButton>}
			/>

		</div>

	);
}

function hideToast(component) {
	component.setState({
		errorToast: false
	});
}

function renderDataModal(component) {
	var content = contentDataModal(component);
	if (!content) return;
	var data = content.data;
	var schema = content.schema;
	return (
		<OutputForm schema={schema} data={data} />
	);
}

function contentDataModal(component) {
	var state = component.state;
	var data = state.dataToView;
	var action = state.actionToView;
	var workflow = state.workflowToView;

	if (!data || !Object.keys(data).length) return null;
	var definitions = state.workflowdefinitions;
	if (!definitions || !Object.keys(definitions).length) return null;

	// Identify the workflow to filter by
	var chosen_workflow = state.chosen_workflow || workflow;
	// Find the definition associated with the chosen workflow
	var chosen_form = state.workflowongoing.get(chosen_workflow).workflow;
	var form_types = definitions.all();
	var chosen_data = form_types.find(function (item) {
		return item._id === chosen_form;
	});
	if (!chosen_data) return null;
	var schema = chosen_data.schema.actions[action];
	return {
		data: data,
		schema: schema
	};
}

function handle_actions(component) {
	var state = component.state;
	var action = state.chosen_action;
	component.state.userMetrics.trackEvent("workflow-history: filter by action", {
		action,
		workflow: state.chosen_workflow,
	});
	if (!action) return;
	hideSearch(component, "action");
	return state.filterActions(component, action);
}
function handle_actor(component) {
	var state = component.state;
	var actor = state.chosen_actor;
	component.state.userMetrics.trackEvent("workflow-history: filter by actor", {
		actor,
		workflow: state.chosen_workflow,
	});
	if (!actor) return;
	hideSearch(component, "actors");
	return state.filterActors(component, actor);
}

function handle_named_workflow(component) {
	var state = component.state;
	var workflows = state.workflow;
	var chosen_workflow = state.chosen_workflow;
	hideSearch(component, "workflow");
	component.state.userMetrics.trackEvent("workflow-history: filter by workflow", {
		"workflow": chosen_workflow
	});
	if (!chosen_workflow.length) chosen_workflow = workflows;
	return state.filterWorkflow(chosen_workflow);
}

function renderWorkflowFilter(component) {
	var state = component.state;
	var current_language = state.currentPerson.get("personal").language;
	var localization = state.localization;
	var chosen_definition = state.chosen_definition;
	var named_workflows = state.workflowdefinitions.all();
	var workflows = named_workflows.map(function (item) {
		if (!item) return;
		var description = item.schema.description;
		var text = description[current_language] ?
			description[current_language]
			: description[Object.keys(description)[0]];
		var value = item._id;
		return (
			<MenuItem key={value} value={value}>
				{text}
			</MenuItem>
		);
	});

	return (
		<FormControl fullWidth>
			<InputLabel>{localization.get("workflow_history_filter_workflow")}</InputLabel>
			<Select multiple={false} onChange={par(choose_workflow, component)} value={chosen_definition} MenuProps={styles.selectProp}>
				{workflows}
			</Select>
		</FormControl>
	);
}

function choose_workflow(component, e) {
	e.persist();
	var state = component.state;
	var value = e.target.value;
	var chosenId = "";
	var workflowOngoing = state.workflowongoing.findOngoingWorkflow(value);
	if(workflowOngoing) {
		chosenId = workflowOngoing._id;
	}else {
		component.setState({
			errorToast: true
		});
	}

	component.setState({
		chosen_definition: value,
		chosen_workflow: chosenId
	});

	component.state.userMetrics.trackEvent("workflow-history: select workflow in dropdown", {
		"workflow": chosenId
	});
}

function renderActionFilter(component) {
	var state = component.state;
	var localization = state.localization;
	var chosen_workflow = state.chosen_workflow;
	var actionFilter = state.actionFilter;
	if (!chosen_workflow || !actionFilter) return null;

	var chosen_action = state.chosen_action;

	var possible_actions = compute_possible_action(component, chosen_workflow);
	if (!possible_actions.length) return null;
	var actions = possible_actions.map(function (item) {
		var name = item.name;
		return (
			<MenuItem key={name} value={name}>{name}</MenuItem>
		);
	});

	return (
		<FormControl style={styles.modalSelects}>
			<InputLabel>{localization.get("workflow_history_choose_action")}</InputLabel>
			<Select onChange={par(choose_action, component)} value={chosen_action} MenuProps={styles.selectProp}>
				{actions}
			</Select>
		</FormControl>
	);
}

function choose_action(component, event) {
	event.persist();
	// The 'value' indicates chosen action and is used to prepare the
	// necessary headers.
	var value = event.target.value;
	return component.setState({
		chosen_action: value
	});
}

function compute_possible_action(component, value) {
	var state = component.state;
	var current_language = state.currentPerson.get("personal").language;
	// Identify the appropriate definition for the chosen workflow
	var definition = state.workflowongoing.get(value).workflow;
	// Get the definition
	var chosen = state.workflowdefinitions.get(definition);
	// No actions for the definition or no definition?  Return empty array.
	if (!chosen) return [];

	return Object.keys(chosen.schema["actions"]).map(function (item) {
		return {
			name: item,
			content: chosen.schema["actions"][item].localization[current_language]
		};
	});
}

function renderActorFilter(component) {
	var state = component.state;
	var localization = state.localization;
	var chosen_workflow = state.chosen_workflow;
	var actorFilter = state.actorFilter;
	if (!chosen_workflow || !actorFilter) return null;

	var chosen_actor = state.chosen_actor;

	var possible_actors = compute_possible_actors(component, chosen_workflow);
	if (!possible_actors.length) return null;
	var actors = possible_actors.map(function (item) {
		var name = item.name;
		var actor_text = item.content;
		return (
			<MenuItem key={name} value={name}>{actor_text}</MenuItem>
		);
	});

	return (
		<FormControl style={styles.modalSelects}>
			<InputLabel>{localization.get("workflow_history_choose_actor")}</InputLabel>
			<Select onChange={par(choose_actor, component)} value={chosen_actor} MenuProps={styles.selectProp}>
				{actors}
			</Select>
		</FormControl>
	);
}

function choose_actor(component, event) {
	event.persist();
	// The 'value' indicates chosen action and is used to prepare the
	// necessary headers.
	var value = event.target.value;
	return component.setState({
		chosen_actor: value
	});
}

function compute_possible_actors(component, value) {
	var state = component.state;
	var current_language = state.currentPerson.get("personal").language;
	// Identify the appropriate definition for the chosen workflow
	var definition = state.workflowongoing.get(value).workflow;
	// Get the definition
	var chosen = state.workflowdefinitions.get(definition);
	// No actions for the definition or no definition?  Return empty array.
	if (!chosen) return [];

	return Object.keys(chosen.schema["actors"]).map(function (item) {
		return {
			name: item,
			content: chosen.schema["actors"][item][current_language]
		};
	});
}

function renderOwnerFilterChip(component) {
	var state = component.state;
	var deleteOn = noop();
	var styleavatar = styles.avatarPrimary;
	var styleback = styles.backPrimary;

	if (state.owners.length) {
		deleteOn = par(clearSearch, component, "owners");
		styleavatar = styles.avatar;
		styleback = styles.back;
	}
	return (
		<Chip className="ae-icon ae-fonticon"
			onDelete={deleteOn}
			style={styleback}
			onClick={par(showSearch, component, "owners")}
			avatar={<Avatar style={styleavatar}>
				<FontIcon className="fa fa-filter fa-2x" style={styles.icon} />
			</Avatar>}
			label={renderFilterLabel(component, "owners")}
		/>
	);
}

function renderActorFilterChip(component) {
	var state = component.state;
	var deleteOn = noop();
	var styleavatar = styles.avatarPrimary;
	var styleback = styles.backPrimary;

	//This chip is only visible once a workflow has been chosen
	if (!state.chosen_workflow) return null;

	if (state.actors.length) {
		deleteOn = par(clearSearch, component, "chosen_actor");
		styleavatar = styles.avatar;
		styleback = styles.back;
	}
	return (
		<Chip className="ae-icon ae-fonticon"
			onDelete={deleteOn}
			style={styleback}
			onClick={par(showSearch, component, "actors")}
			avatar={<Avatar style={styleavatar}>
				<FontIcon className="fa fa-filter fa-2x" style={styles.icon} />
			</Avatar>}
			label={renderFilterLabel(component, "actors")}
		/>
	);
}

function renderWorkflowFilterChip(component) {
	var state = component.state;
	var deleteOn = noop();
	var styleavatar = styles.avatarPrimary;
	var styleback = styles.backPrimary;

	if (state.chosen_workflow.length) {
		deleteOn = par(clearSearch, component, "workflow");
		styleavatar = styles.avatar;
		styleback = styles.back;
	}
	return (
		<Chip className="ae-icon ae-fonticon"
			onDelete={deleteOn}
			style={styleback}
			onClick={par(showSearch, component, "workflow")}
			avatar={<Avatar style={styleavatar}>
				<FontIcon className="fa fa-filter fa-2x" style={styles.icon} />
			</Avatar>}
			label={renderFilterLabel(component, "workflow")}
		/>
	);
}

function renderActionFilterChip(component) {
	var state = component.state;
	var deleteOn = noop();
	var styleavatar = styles.avatarPrimary;
	var styleback = styles.backPrimary;

	//This chip is only visible once a workflow has been chosen
	if (!state.chosen_workflow) return null;

	if (state.chosen_action.length) {
		deleteOn = par(clearSearch, component, "chosen_action");
		styleavatar = styles.avatar;
		styleback = styles.back;
	}

	return (<Chip className="ae-icon ae-fonticon"
		onDelete={deleteOn}
		style={styleback}
		onClick={par(showSearch, component, "action")}
		avatar={<Avatar style={styleavatar}>
			<FontIcon className="fa fa-filter fa-2x" style={styles.icon}/>
		</Avatar>}
		label={renderFilterLabel(component, "action")}
	/>);
}

function renderRespondFilterChip(component) {
	var state = component.state;
	var deleteOn = noop();
	var styleavatar = styles.avatarPrimary;
	var styleback = styles.backPrimary;
	if (!!state.startRespond && !!state.endRespond) {
		deleteOn = par(clearSearch, component, "respond");
		styleavatar = styles.avatar;
		styleback = styles.back;
	}
	return (<Chip className="ae-icon ae-fonticon"
		onDelete={deleteOn}
		style={styleback}
		onClick={par(showSearch, component, "respond")}
		avatar={<Avatar style={styleavatar}>
			<FontIcon className="fa fa-filter fa-2x" style={styles.icon}/>
		</Avatar>}
		label={renderFilterLabel(component, "respond")}
	/>);
}
function renderHeader(component, headers) {
	if (component.state.openingModal) return; // no table render if opening modal

	return (
		<TableHeader>
			<TableRow >
				{headers.map(renderHeaderColumn)}
			</TableRow>
		</TableHeader>
	);
}

function renderHeaderColumn(column, index) {
	return (
		<TableHeaderColumn key={index} className="flex-spread ae-headers-wrap">
			{column.content}
		</TableHeaderColumn>
	);
}

function renderBody(component, rows, headers) {
	return (
		<TableBody>
			{rows.map(par(renderRow, headers))}
		</TableBody>
	);
}

function renderRow(headers, row, index) {
	return (
		<TableRow key={index} >
			{headers.map(par(renderRowColumn, row))}
		</TableRow>
	);
}

function renderRowColumn(row, column, index) {
	var chunk = row[column.name];
	return (
		<TableRowColumn key={index}>
			{chunk}
		</TableRowColumn>
	);
}

/**
 * This function generates the data that populates the table.
 * @param  {[type]} people	[description]
 * @param  {[type]} reminders [description]
 * @param  {[type]} history   [description]
 * @return {[type]}		   [description]
 */
function generate_data(component) {
	var state = component.state;

	var localization = state.localization;
	var people = state.people;
	var actions = state.workflowactions.all();
	var workflowongoing = state.workflowongoing;

	if (!actions || !workflowongoing) return;

	return actions.map(function (item) {

		var owner = Utility.format_name(people.get(item.owner[0]));
		var actor = item.actor;

		var workflow = workflowongoing.get(item.ongoingWorkflow);
		var label = workflow ? workflow.label : "---";
		var responded = item.responded ? timestamp_text(item.updated_at) : localization.get("workflow_history_pending");
		var action = item.action;
		var data = renderData(component, item);

		return {
			label: label,
			responded: responded,
			owner: owner,
			actor: actor,
			action: action,
			data: data
		};

	}).sort(by_responded);
}

function renderData(component, item) {
	var localization = component.state.localization;
	var showData = function () {
		component.setState({
			openingDataView: true,
			actionToView: item.action,
			dataToView: item.data,
			workflowToView: item.ongoingWorkflow
		});
		component.state.userMetrics.trackEvent("workflow-history: open response data popup");
	};

	return item.responded ?
		(
		<Chip className="ae-icon ae-fonticon"
			style={styles.backPrimary}
			onClick={showData}
			label={localization.get("submissions_view_data")}
		/>)
		: null;
}

function handle_close_dataView(component) {
	component.setState({
		openingDataView: false,
		dataToView: null
	});
	component.state.userMetrics.trackEvent("workflow-history: close workflow action data popup");
}

/**
 * Sorts by time in descending order
 * @param  {[type]} x [description]
 * @param  {[type]} y [description]
 * @return {[type]}   [description]
 */
function by_responded(x, y) {
	var X = x.responded;
	var Y = y.responded;
	if (X < Y) return 1;
	if (X > Y) return -1;
	return 0;
}

function timestamp_text(item) {
	return Utility.timestamp(item);
}

function csvReport(component, localization) {
	var comp = component.state;
	var start = comp.startRespond;
	var end = adjust_date(comp.endRespond);
	var filename = comp.fileName;
	var chosen_action = comp.chosen_action;
	var chosen_workflow = comp.chosen_workflow;

	if (!filename) filename = localization.get("default_file_workflowHistory");

	var language = comp.currentPerson.get("personal").language;
	var timezone = new Date().getTimezoneOffset();

	var url = config.host +
		"/v2/org/workflow/actions/history/report?token=" +
		config.token +
		"&start=" +
		encodeURIComponent(start) +
		"&end=" +
		encodeURIComponent(end) +
		"&filename=" +
		encodeURIComponent(filename) +
		"&language=" +
		encodeURIComponent(language) +
		"&timezone=" +
		encodeURIComponent(timezone) +
		"&workflow=" +
		encodeURIComponent(chosen_workflow) +
		"&action=" +
		encodeURIComponent(chosen_action)
		;

	return (
		<a
			key={url}
			className="ae-report-save"
			href={url}
			download={filename}
			onClick={() => component.state.userMetrics.trackEvent("workflow-history: download report", {
				"workflow": chosen_workflow,
			})}
		>
			{localization.get("report_save")}
		</a>
	);
}

function hideReport(component) {
	component.state.userMetrics.trackEvent("workflow-history: close report popup");
	return (
		component.setState({
			report: false
		})
	);
}

function updateFileNameChanged(component, event) {
	event.persist();
	var value = event.target.value;
	component.setState({
		fileName: value
	});
}

function updateChanged(component, name, event, date) {
	var value = date;
	if (event) {
		value = event._d;
	}
	var update = {};
	update[name] = value;
	component.setState(update);
}

/**
 * A function to reset a date by 1 day ahead.  Used to adjust the end date
 * on the date selector function because the default value is midnight of the
 * day selected instead of say, 23:59 of the day chosen or midnight of the next
 * day
 * @param  {String} date Date string
 * @return {String}	  Date string
 */
function adjust_date(date) {
	if (isEmpty(date)) return "";
	var end = new Date(date);
	end.setDate(end.getDate() + 1);
	return end;
}

function showSearch(component, name) {
	if (name === "actors") component.setState({
		actorFilter: true
	});
	if (name === "owners") component.setState({
		ownerFilter: true
	});
	if (name === "action") component.setState({
		actionFilter: true
	});
	if (name === "workflow") component.setState({
		workflowFilter: true
	});
	if (name === "respond") component.setState({
		respondFilter: true
	});
	const event = {
		"owners": "workflow-history: open actor filter popup",
		"workflow": "workflow-history: open workflow filter popup",
		"respond": "workflow-history: open date filter popup",
	}[name] || "workflow-history: open workflow action filter popup";
	component.state.userMetrics.trackEvent(event);
}

function hideSearch(component, name) {
	if (name === "actors") component.setState({
		actorFilter: false
	});
	if (name === "owners") component.setState({
		ownerFilter: false
	});
	if (name === "action") component.setState({
		actionFilter: false
	});
	if (name === "workflow") component.setState({
		workflowFilter: false
	});
	if (name === "respond") component.setState({
		respondFilter: false
	});
	const event = {
		"owners": "workflow-history: close actor filter popup",
		"workflow": "workflow-history: close workflow filter popup",
		"respond": "workflow-history: close date filter popup",
	}[name] || "workflow-history: close workflow action filter popup";
	component.state.userMetrics.trackEvent(event);
}

function clearSearch(component, name) {
	const eventText = {
		"owners": "workflow-history: clear actor filter",
		"workflow": "workflow-history: clear workflow filter",
		"respond": "workflow-history: clear date filter",
	}[name] || "workflow-history: clear workflow action filter";
	component.state.userMetrics.trackEvent(eventText);

	var clear = component.state.filterClear;
	if(name === "chosen_actor") {
		component.setState({
			chosen_actor: "",
			actors: []
		});
		return clear("actors");
	}

	if(name === "chosen_action") {
		component.setState({
			chosen_action: "",
			action: []
		});
		return clear("action");
	}

	if (name === "owners") {
		component.setState({
			owners: []
		});
		return clear("owners");
	}
	if (name === "workflow") {
		component.setState({
			chosen_definition: "",
			chosen_workflow: ""
		});
		return clear("workflow");
	}
	if (name === "respond") {
		component.setState({
			startRespond: "",
			endRespond: new Date()
		});
		return clear("respond");
	}
}

function renderFilterLabel(component, name) {
	var state = component.state;
	var localization = state.localization;

	if (name === "respond") {
		var start = state.startRespond;
		var end = state.endRespond;
		if (isEmpty(start) || isEmpty(end)) {
			return localization.get("search_responded");
		} else {
			start = shortDate(start);
			end = shortDate(end);
			var label = start + " - " + end;
			return label;
		}
	}
	if (name === "owners") {
		var ownersLength = state.owners.length;
		if (!ownersLength) return localization.get("search_owner");
		label = localization.get("search_owner") + " (" + ownersLength + ")";
		return label;
	}
	if (name === "actors") {
		var actorsLength = state.actors.length;
		if (!actorsLength) return localization.get("search_actors");
		label = localization.get("search_actors") + " (" + actorsLength + ")";
		return label;
	}
	if (name === "workflow") {
		var workflowLength = (state.chosen_workflow.length) ? 1 : 0;
		if (!workflowLength) return localization.get("search_workflow");
		label = localization.get("search_workflow") + " (" + workflowLength + ")";
		return label;
	}
	if (name === "action") {
		var actionLength = state.action.length;
		if (!actionLength) return localization.get("search_action");
		label = localization.get("search_actors") + " (" + actionLength + ")";
		return label;
	}
}

function shortDate(date) {
	var day = date.getDate();
	var month = date.getMonth() + 1;
	var year = date.getFullYear();
	return (day + "/" + month + "/" + year);

}

function handle_date(component, value) {
	var state = component.state;
	hideSearch(component, value);
	var start = state.startRespond;
	var end = adjust_date(state.endRespond);
	component.state.userMetrics.trackEvent("workflow-history: filter by date", {
		start,
		end,
	});
	if (!validate_dates(component, start, end)) return;
	return state.filterDateRespond(start, end);
}

function validate_dates(component, start, end) {
	if (isNaN(Date.parse(start)) || isNaN(Date.parse(end))) {
		clearSearch(component, "respond");
		return false;
	} else {
		return true;
	}
}

function noop() { }


function getUrlContent(component) {
	var content = contentDataModal(component);
	if (!content) return "javascript:void(0)";
	var data = content.data;
	var schema = content.schema;
	var htmlData = HTMLOutput({
		schema,
		data,
	}, component.getChildContext());

	//var b64 = window.btoa(htmlData);
	try {
		var utf8 = utf8ToBytes(htmlData);
		var b64 = base64_js.fromByteArray(utf8);
		var url = "data:text/html;base64," + b64;
	}
	catch (err) { console.error(err); }

	return url;
}

function getUrlName() {
	return new Date() + ".html";
}
