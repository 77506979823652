export const healthHeaders = (localization) => ({
	bloodOxygen: [
		{
			name: "oxygensaturation",
			content: localization.get("user_bloodoxygen_history_saturation"),
			getData: (row) => row?.history?.oxygensaturation,
			getStatus: (row) => row?.history?.oxygenSaturationStatus
		},
		{
			name: "pulserate",
			content: localization.get("user_bloodoxygen_history_pulserate"),
			getData: (row) => row?.history?.pulserate,
			getStatus: (row) => row?.history?.heartRateStatus
		}
	],
	bodyTemperature: {
		fahrenheit: [
			{
				name: "degreeF",
				content: localization.get("user_bodytemperature_history_degreeF"),
				getData: (row) => row?.history?.fahrenheit,
				getStatus: (row) => row?.history?.status
			}
		],
		celsius: [
			{
				name: "degreeC",
				content: localization.get("user_bodytemperature_history_degreeC"),
				getData: (row) => row?.history?.celsius,
				getStatus: (row) => row?.history?.status
			}
		]
	},
	bloodSugar: {
		mg: [
			{
				name: "meanblood",
				content: localization.get("user_glucose_history_meanblood"),
				getData: (row) => row?.history?.mg,
				getStatus: (row) => row?.history?.status
			}
		],
		mmol: [
			{
				name: "glucose",
				content: localization.get("user_glucose_history_glucose"),
				getData: (row) => row?.history?.mmol,
				getStatus: (row) => row?.history?.status
			}
		]
	},
	weight: {
		kg: [
			{
				name: "weight_kg",
				content: localization.get("user_weight_history_weight_kg"),
				getData: (row) => row?.history?.kg,
				getStatus: (row) => row?.history?.status
			},
		],
		lbs: [
			{
				name: "weight_lbs",
				content: localization.get("user_weight_history_weight_lbs"),
				getData: (row) => row?.history?.lbs,
				getStatus: (row) => row?.history?.status
			},
		]
	},
	bloodPressure: [
		{
			name: "systolic",
			content: localization.get("user_bloodpressure_history_systolic"),
			getData: (row) => row?.history?.systolic,
			getStatus: (row) => row?.history?.status
		},
		{
			name: "diastolic",
			content: localization.get("user_bloodpressure_history_diastolic"),
			getData: (row) => row?.history?.diastolic,
			getStatus: (row) => row?.history?.status
		},
		{
			name: "bpPulserate",
			content: localization.get("user_bloodpressure_history_pulserate"),
			getData: (row) => row?.history?.bpPulserate,
			getStatus: (row) => row?.history?.bpPulserateStatus
		}
	],
	activity: [
		{
			name: "steps",
			content: localization.get("user_steps_history_steps"),
			getData: (row) => row?.cumulative,
			getStatus: (row) => row?.status
		}
	]
});