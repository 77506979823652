import React, { useMemo } from "react";
import {
	Table,
	TableCell,
	TableContainer,
	TableRow,
	Typography,
	DialogContent,
	Dialog,
} from "@material-ui/core";

const MetadataTableCell = ({ attribute, title }) => {
	return (
		<TableRow>
			<TableCell>
				<Typography style={{ fontWeight: "600" }}> {title}</Typography>
				{attribute}
			</TableCell>
		</TableRow>
	);
};

export default function MetadataModal(props) {
	const { isOpen, onClose, metadata, localization } = props;

	const removedKeys = ["performer", "ip", "userAgent", "token", "_id"];

	const filteredMetadata = useMemo(
		() => {
			return Object.keys(metadata || {})
				.filter(key => !removedKeys.includes(key))
				.reduce((obj, key) => {
					obj[key] = metadata[key];
					return obj;
				}, {});
		},
		[metadata, removedKeys]
	);

	const rows = useMemo(() => (
		<>
			{metadata?.performer && (
				<MetadataTableCell attribute={metadata.performer} title={`${localization.get("audit_logs.performer")}:`}/>
			)}
			{metadata?.ip && (
				<MetadataTableCell attribute={metadata.ip} title={`${localization.get("audit_logs.ip")}:`}/>
			)}
			{metadata?.userAgent && (
				<MetadataTableCell attribute={metadata.userAgent} title={`${localization.get("audit_logs.agent")}:`}/>
			)}
		</>
	), [metadata]);

	return (
		<Dialog open={isOpen} onClose={onClose}>
			<DialogContent>
				<TableContainer>
					<Table>
						{rows}
						<TableRow>
							<TableCell>
								<Typography>
									<pre>{JSON.stringify(filteredMetadata, null, 2)}</pre>
								</Typography>
							</TableCell>
						</TableRow>
					</Table>
				</TableContainer>
			</DialogContent>
		</Dialog>
	);
};