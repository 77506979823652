

import Store from "./store";
import par from "par";
import xtend from "xtend";

export default UserCompliantStore;

function UserCompliantStore(api, events, config){
	var store = new Store();

	store.raw_get = store.get;
	store.get = get;

	var tracking = [];
	return store;

	function get(id){
		if(!id) return;
		var existing = store.raw_get(id);
		if(existing) return existing;

		if(!isTracking(id))
			track(id);
		return [];
	}

	function isTracking(id){
		return tracking.indexOf(id) !== -1;
	}

	function track(id){
		tracking.push(id);
		api.org.users.compliant(id)
			.then(par(setCompliancy, id))
			.then(par(trackCompliant, id));
	}

	function trackCompliant(id){
		var orgGroup = config.orgGroup;
		if(!orgGroup) return;
		events.on("mqtt:orggroup/" + orgGroup + "/u/" + id + "/compliance/updated", par(updateCompliance, id));
		events.on("mqtt:orggroup/" + orgGroup + "/u/" + id + "/compliance/current/updated", par(updateCurrentCompliance, id));
	}

	function setCompliancy(id, compliancy){
		store.set(id, compliancy);
	}

	function updateCompliance(id, data){
		var current = store.raw_get(id) || {};
		var currentStatuses = current.compliant_status || {};
		var newStatuses = data.compliant_status || {};

		store.set(id, xtend(current, {
			compliant_status: xtend(currentStatuses, newStatuses)
		}));
	}

	function updateCurrentCompliance(id, data){
		var current = store.raw_get(id) || {};
		var newData = xtend(current, data);

		store.set(id, newData);
	}
}