


import Store from "./store";
import pluck from "fj-pluck";
import map from "fj-map";
import xtend from "xtend";

export default UserForms;

function UserForms(api, events){
	var store = new Store();

	api.org.forms.listType("user")
		.then(map(addForm));
	api.org.group.current.data()
		.then(pluck("_id"))
		.then(listenForChanges);

	return store;

	function listenForChanges(group){
		events.on("mqtt:orggroup/" + group + "/forms/definitions/created", addForm);
		events.on("mqtt:orggroup/" + group + "/forms/definitions/removed", removeForm);
		events.on("mqtt:orggroup/" + group + "/forms/definitions/updated", updateForm);
	}

	function addForm(form){
		var id = form._id;
		store.set(id, form);
	}

	function removeForm(form){
		var id = form._id;
		store.remove(id);
	}

	function updateForm(form){
		var id = form._id;
		var existing = store.get(id);
		store.set(id, xtend(existing, form));
	}
}