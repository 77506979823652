import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	FormControl,
	InputLabel,
	List,
	ListItem,
	ListItemText,
	MenuItem,
	Select,
	Typography
} from "@material-ui/1.5.1";
import { styled } from "@material-ui/styles";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import PeopleSelector from "./PeopleSelector";
import frameDebounce from "frame-debounce";

import { useLocalize } from "../shared/contexts/LocalizeContext";
import { useErrorBar } from "../shared/ErrorBar";
import { useResources } from "../shared/contexts/ResourceContext";

import Utility from "../shared/Utility";
import PeopleSelectorCheckList from "./PeopleSelectorCheckList";
import * as Sentry from "@sentry/react";

import Util from "../shared/Util";
import { useUserMetrics } from "@aetonix/user-metrics";
import UserStore from "../stores/user_active_store";
import UserDataStore from "../stores/user_data_store";

const useBulkList = () => {
	const [selected, setSelected] = useState([]);

	const addSelected = id => {
		setSelected([...selected, id]);
	};

	const addMultiple = list => {
		setSelected([...selected, ...list]);
	};

	const removeMultiple = list => {
		const removeSet = new Set(list);
		setSelected([...selected.filter(val => !removeSet.has(val))]);
	};

	const removeSelected = id => {
		setSelected(selected.filter(val => val !== id));
	};

	return [selected, addSelected, removeSelected, addMultiple, removeMultiple];
};

function by_name(x, y) {
	var X = x?.lname?.toLowerCase();
	var Y = y?.lname?.toLowerCase();
	if (X === Y) {
		X = x.fname.toLowerCase();
		Y = y.fname.toLowerCase();
	}
	return order(X, Y);
}

function order(X, Y) {
	if (X < Y)
		return -1;
	if (X > Y)
		return 1;
	return 0;
}

const BulkRemove = ({ people, closeDialog = () => {} }) => {
	const resources = useResources();
	const error = useErrorBar();
	const localize = useLocalize();

	const [failedUpdate, setFailedUpdate] = useState({});

	const patientSelectedState = useBulkList();
	const managerSelectedState = useBulkList();
	const [patientsSelected] = patientSelectedState;
	const [managersSelected] = managerSelectedState;

	const [executing, setExecuting] = useState(false);

	const [, setUserDataStore] = useState([]);
	const { userMetrics } = useUserMetrics();
	const [userDataStoreRef] = useState(() => {
		const ref = UserDataStore(resources.api, resources.events, resources.config);
		let debounceStoreUpdate = frameDebounce(() => {
			setUserDataStore(ref.all());
		});
		ref.on("change", debounceStoreUpdate);
		return ref;
	});

	//No dependancy because of people store rerenders the whole parent component. No way to tell unless import our own people like  userDataStore/userDataStoreRef. Can do it when needed (e.g performance issues)
	const availableManagers = useMemo(() => {
		let managersSets = patientsSelected
			.map(userDataStoreRef.get)
			.filter(patient => {
				return patient._id; //check if loaded
			})
			.map(patient => {
				const managers = patient.managers || [];
				const idArr = managers.map(manager => {
					return manager._id;
				});
				return new Set(idArr);
			});

		let intersection = [];
		managersSets.forEach((managerSet, index) => {
			if (index === 0) {
				intersection = [...managerSet];
			} else {
				intersection = intersection.filter(currId => managerSet.has(currId));
			}
		});

		intersection.sort((a, b) => Util.Sort.byName(people.get(a), people.get(b)));

		return intersection;
	});

	const managersToRemove = useMemo(() => {
		const availableManagersSet = new Set(availableManagers);
		return managersSelected.filter(id => availableManagersSet.has(id));
	}, [managersSelected, availableManagers]);

	const canSubmit = patientsSelected.length > 0 && managersToRemove.length > 0 && !executing;

	const onExecute = () => {
		userMetrics.trackEvent("user-bloodoxygen-history: execute bulk remove", {
			"patients": patientsSelected.map(patient => patient._id),
			"managers": managersToRemove.map(manager => manager._id),
		});
		setExecuting(true);
		resources.api.org.manage
			.removeMulti(patientsSelected, managersToRemove)
			.then(res => {
				setExecuting(false);

				let failed = computeFailedUpdates(res.success, patientsSelected, managersToRemove);
				if (Object.keys(failed).length > 0) {
					setFailedUpdate(failed);
				} else {
					closeDialog();
				}
			})
			.catch((err) => {
				error.showError(localize("http_error.something_went_wrong"));
				console.error(err);
				Sentry.captureException(err);
				setExecuting(false);
			});
	};

	return (
		<Fragment>
			<FailedUserUpdateDialog
				open={Object.keys(failedUpdate).length > 0}
				data={failedUpdate}
				idToUser={people.get}
				onClose={() => {
					setFailedUpdate({});
					closeDialog();
					userMetrics.trackEvent("user-management: close failed bulk remove popup");
				}}
			/>
			<BulkDialogContent>
				<SelectorContainer>
					<div style={{ flex: 1 }}>
						<HeaderText variant="title">{localize("user_manage_users")}</HeaderText>
						<Divider />
						<PatientSelector people={people} selectedState={patientSelectedState} />
					</div>
					<VerticalDivider />
					<div style={{ flex: 1 }}>
						<HeaderText variant="title">{localize("user_manage_managers")}</HeaderText>
						<Divider />
						<ManagerRemoveSelector
							availableManagers={availableManagers}
							people={people}
							selectedState={managerSelectedState}
						/>
					</div>
				</SelectorContainer>
			</BulkDialogContent>
			<DialogActions>
				<Button onClick={onExecute} color="primary" disabled={!canSubmit}>
					{localize("common.confirm")}
				</Button>
				<Button onClick={closeDialog} color="primary" disabled={executing}>
					{localize("common.cancel")}
				</Button>
			</DialogActions>
		</Fragment>
	);
};

const BulkAdd = ({ people, presetPermissionsList = [], search, closeDialog = () => {} }) => {
	const patientSelectedState = useBulkList();
	const managerSelectedState = useBulkList();
	const [patientsSelected] = patientSelectedState;
	const [managersSelected] = managerSelectedState;
	const [permissionSelected, setPermissionSelected] = useState("");
	const [failedUpdate, setFailedUpdate] = useState({});
	const [executing, setExecuting] = useState(false);
	const { userMetrics } = useUserMetrics();

	const canSubmit = patientsSelected.length > 0 && managersSelected.length > 0 && !executing;

	const resources = useResources();
	const localize = useLocalize();
	const error = useErrorBar();

	const onExecute = () => {
		setExecuting(true);

		let usePermissions;
		userMetrics.trackEvent("user-management: execute bulk add", {
			"patients": patientsSelected.map(patient => patient._id),
			"managers": managersSelected.map(manager => manager._id),
			"permission": permissionSelected,
		});
		if (permissionSelected && permissionSelected !== "default")
			presetPermissionsList.some(preset => {
				if (preset._id === permissionSelected) {
					usePermissions = preset.permissions;
					return true;
				}
			});
		//undefined permission is fine, it will just be default
		resources.api.org.manage
			.addMulti(patientsSelected, managersSelected, usePermissions)
			.then(res => {
				setExecuting(false);
				let failed = computeFailedUpdates(res.success, patientsSelected, managersSelected);
				if (Object.keys(failed).length > 0) {
					setFailedUpdate(failed);
				} else {
					closeDialog();
				}
			})
			.catch(err => {
				error.showError(localize("http_error.something_went_wrong"));
				console.error(err);
				Sentry.captureException(err);
				setExecuting(false);
			});
	};
	return (
		<Fragment>
			<FailedUserUpdateDialog
				open={Object.keys(failedUpdate).length > 0}
				data={failedUpdate}
				idToUser={people.get}
				onClose={() => {
					setFailedUpdate({});
					closeDialog();
					userMetrics.trackEvent("user-management: close failed bulk add popup");
				}}
			/>
			<BulkDialogContent>
				<FormControl fullWidth>
					<InputLabel shrink>{localize("preset_permission.tab_title")}</InputLabel>
					<Select
						value={permissionSelected}
						onChange={e => {
							setPermissionSelected(e.target.value);
						}}
						displayEmpty
					>
						<MenuItem value="" selected>
							{localize("common.default")}
						</MenuItem>
						{presetPermissionsList.map(permission => {
							const presetName = permission.name;
							const id = permission._id;
							return (
								<MenuItem value={id} key={id}>
									{presetName}
								</MenuItem>
							);
						})}
					</Select>
				</FormControl>
				<SelectorContainer>
					<div style={{ flex: 1 }}>
						<HeaderText variant="title">{localize("user_manage_users")}</HeaderText>
						<Divider />
						<PatientSelector people={people} selectedState={patientSelectedState} />
					</div>
					<VerticalDivider />
					<div style={{ flex: 1 }}>
						<HeaderText variant="title">{localize("user_manage_managers")}</HeaderText>
						<Divider />
						<ManagerAddSelector search={search} people={people} selectedState={managerSelectedState} />
					</div>
				</SelectorContainer>
			</BulkDialogContent>
			<DialogActions>
				<Button onClick={onExecute} color="primary" disabled={!canSubmit}>
					{localize("common.confirm")}
				</Button>
				<Button onClick={closeDialog} color="primary" disabled={executing}>
					{localize("common.cancel")}
				</Button>
			</DialogActions>
		</Fragment>
	);
};

const PatientSelector = ({ people, selectedState }) => {
	const [selectedPatients, addPatient, removePatient, addMultiple, removeMultiple] = selectedState;

	const resources = useResources();

	const [userStore, setUserStore] = useState([]);
	const [userStoreRef] = useState(() => {
		const ref = UserStore(resources.api, resources.events, resources.config);
		const debounceStoreUpdate = frameDebounce(() => {
			setUserStore(ref.all());
		});
		ref.on("change", debounceStoreUpdate);
		return ref;
	});
	const { userMetrics } = useUserMetrics();

	const [, setUserDataStore] = useState([]);
	const [userDataStoreRef] = useState(() => {
		const ref = UserDataStore(resources.api, resources.events, resources.config);
		let debounceStoreUpdate = frameDebounce(() => {
			setUserDataStore(ref.all());
		});
		ref.on("change", debounceStoreUpdate);
		return ref;
	});

	useEffect(() => {
		userStoreRef.loadAllUser();
	}, []);
	const onSearch = text => {
		userStoreRef.search(text);
		if (!text) userStoreRef.loadAllUser();
	};


	const selectAll = () => {
		const selectedSet = new Set(selectedPatients);
		addMultiple(userStore.filter(val => !selectedSet.has(val)));
		userMetrics.trackEvent("user-management: select all patients in bulk edit");
	};

	const deselectAll = () => {
		removeMultiple(userStore);
		userMetrics.trackEvent("user-management: deselect all patients in bulk edit");
	};

	const sorteduserList = userStore.length > 0 && userStore.map(people.get).sort(by_name).map(({_id}) => _id) || [];

	return (
		<PeopleSelector
			personsList={sorteduserList}
			selectedList={selectedPatients}
			onPersonRemove={removePatient}
			onPersonSelect={addPatient}
			onLoadMore={() => {}}
			idToUser={userDataStoreRef.get}
			onPersonSearch={onSearch}
			onSelectAll={selectAll}
			onDeselectAll={deselectAll}
			people = {people}
		/>
	);
};

const ManagerRemoveSelector = ({ people, selectedState, availableManagers }) => {
	const [selected, addSelected, removeSelected] = selectedState;

	return (
		<PeopleSelectorCheckList
			personsList={availableManagers}
			selectedList={selected}
			onPersonRemove={removeSelected}
			onPersonSelect={addSelected}
			idToUser={people.get}
		/>
	);
};

const ManagerAddSelector = ({ search, people, selectedState }) => {
	const [selected, addSelected, removeSelected] = selectedState;

	const [managersFound, setManagerFound] = useState([]);

	const onSearch = text => {
		search(text).then(res => {
			setManagerFound(res);
		});
	};
	return (
		<PeopleSelector
			personsList={managersFound.sort(by_name).map(item => item._id)}
			selectedList={selected}
			onPersonRemove={removeSelected}
			onPersonSelect={addSelected}
			onLoadMore={() => {}}
			onPersonSearch={onSearch}
			idToUser={people?.get}
			people={people}
		/>
	);
};

const computeFailedUpdates = (successfulUpdates = [], users, managers) => {
	let successful = {};
	successfulUpdates.forEach(update => {
		let user = update.user;
		let manager = update.manager;
		if (!successful[user]) successful[user] = [];

		successful[user].push(manager);
	});

	let failed = {};
	users.forEach(user => {
		let updated = successful[user];
		if (!updated) {
			failed[user] = [...managers];
		} else if (updated.length < managers) {
			let updatedSet = new Set(updated);
			failed[user] = managers.filter(manager => {
				return !updatedSet.has(manager);
			});
		}
	});

	return failed;
};
const FailedUserUpdateDialog = ({ open, data, onClose, idToUser }) => {
	const localize = useLocalize();

	return (
		<Dialog maxWidth="sm" fullWidth onclose={onClose} open={open}>
			<DialogTitle>{localize("user_manage_multi_failed")}</DialogTitle>
			<DialogContent>
				<DialogContentText>{localize("user_manage_multi_failed_content")}</DialogContentText>
				<List>
					{Object.keys(data).map(userId => {
						let managers = data[userId];
						let user = idToUser(userId);
						const text = Utility.format_name(user);

						const renderManagers = managers.map(managerId => {
							let manager = idToUser(managerId);
							const managerName = Utility.format_name(manager);
							return (
								<ListItem key={managerId}>
									<ListItemText inset secondary={localize("user_manage_manager")}>
										{managerName}
									</ListItemText>
								</ListItem>
							);
						});
						return [
							<ListItem key={userId}>
								<ListItemText secondary={localize("user_manage_user")}>{text}</ListItemText>
							</ListItem>,
							...renderManagers
						];
					})}
				</List>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} color="primary">
					{localize("common.close")}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

const VerticalDivider = styled("div")({
	borderWidth: 0,
	borderLeftWidth: 1,
	borderColor: "lightgray",
	borderStyle: "solid"
});

const HeaderText = styled(Typography)({
	margin: 8,
	textAlign: "center"
});

const BulkDialogContent = styled(DialogContent)({
	display: "flex",
	flexDirection: "column"
});

const SelectorContainer = styled("div")({ display: "flex", flexDirection: "row", width: "100%", flexGrow: 1 });
export { BulkAdd, BulkRemove };
