import React, { useMemo, useCallback, useState, useRef } from "react";
import { Dialog, DialogContent, Typography, DialogTitle, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {
	useLocalize,
	useAllPatientContacts,
	useAllPatientManagers,
	usePatientGroupManagers,
	useOwnerOngoingWorkflows,
	usePatientsPatientGroup,
	useOwnContacts,
	useCurrentPerson
} from "@aetonix/hooks";
import { createSchema, getActors, validateActors } from "./WorkflowHelpers";

import Form from "ae-forms";
import { useUserMetrics } from "@aetonix/user-metrics";

import StyledIconButton from "ae-base/Base/StyledIconButton";
import AetonixTheme from "../../shared/AetonixTheme";
const Colors = AetonixTheme.palette;
const styles = {
	formContainer: {
		maxHeight: "calc(100vh - 200px)",
		overflowY: "auto",
	  },
	scrollToTopButton: {
		position: "absolute",
		bottom: "1rem",
		right: "1rem",
		zIndex: 2,
		backgroundColor: Colors.primary.main,
		width: 60,
		height: 60,
		justifyContent: "center",
		color: "white",
		alignItems: "center",
		borderRadius: 30,
		borderWidth: 3,
		borderColor: "white"
	},
};

export const WorkflowEditModal = ({ open, onClose, schema, defaults, ongoingId }) => {
	const { localize } = useLocalize();
	const { personal: { language }} = useCurrentPerson();
	const { updateWorkflow } = useOwnerOngoingWorkflows();
	const { userMetrics } = useUserMetrics();

	const { contacts: ownContacts } = useOwnContacts();
	const { contacts: patientAllContacts } = useAllPatientContacts();
	const { managers: patientAllManagers } = useAllPatientManagers();
	const { managers: patientGroupManagers } = usePatientGroupManagers();
	const { patientGroups: patientGroups } = usePatientsPatientGroup();

	const [formError, setFormError] = useState(null);
	const [showScrollToTop, setShowScrollToTop] = useState(false);
  	const formContainerRef = useRef(null);

	const creationFormSchema = useMemo(() => createSchema(schema, "edit", localize, language), [schema]);
	const formSources = useMemo(
		() => ({
			ownContacts: createKeyValuePeople(ownContacts),
			manageGroups: createKeyValueGroup(patientGroups),
			patientAllContacts: createKeyValuePeople(patientAllContacts),
			patientAllManagers: createKeyValuePeople(patientAllManagers),
			patientGroupManagers: createKeyValuePeople(patientGroupManagers)
		}),
		[patientGroups, patientAllContacts, patientAllManagers, patientGroupManagers]
	);

	const handleScroll = () => {
		const scrollTop = formContainerRef?.current?.scrollTop;
		setShowScrollToTop(scrollTop > 100);
	  };

	  const scrollToTop = () => {
		formContainerRef?.current?.scrollTo({
		  top: 0,
		  behavior: "smooth",
		});
	  };

	const onSubmit = useCallback((data) => {
		const actors = getActors(data);
		const isValid = validateActors(data, schema);

		if (!isValid) {
			setFormError(localize("workflow.missingActors"));
			return;
		};

		setFormError(null);
		updateWorkflow(actors, data.defaults, ongoingId);
		onClose();
		userMetrics.trackEvent("user-overview: edit workflow", { workflowId: ongoingId });
	}, [ongoingId]);

	return (
		<Dialog open={open} scroll="paper" onClose={onClose} fullWidth maxWidth="md">
			<DialogTitle disableTypography>
				<Typography variant="body1" align="center">
					{localize("workflow.create")}
				</Typography>
				<IconButton onClick={onClose} style={{ padding: "0px" }}>
					<CloseIcon color="secondary" aria-label="Close Button" />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<div ref={formContainerRef} onScroll={handleScroll} style={styles.formContainer}>
				{formError && (
						<Typography variant="h6" color="error">
							{formError}
						</Typography>
					)}
					<Form
						schema={creationFormSchema}
						onSubmit={onSubmit}
						onCancel={onClose}
						defaultData={defaults}
						sources={formSources}
						cancelText={localize("common.cancel")}
					/>
				</div>
				{showScrollToTop && (
					<StyledIconButton icon="arrow-up" contrast onPress={scrollToTop} style={styles.scrollToTopButton}></StyledIconButton>
					)};
			</DialogContent>
		</Dialog>
	);
};

const createKeyValuePeople = list => {
	return list.map(person => ({ label: `${person.fname} ${person.lname}`, value: person._id }));
};

const createKeyValueGroup = list => {
	return list.map(group => ({ label: group.name, value: group._id }));
};
