import { useMemo, useState } from "react";

export default function useLogFilter() {

    const [target, setTarget] = useState(null);
    const [owner__name, setOwnerName] = useState("");
    const [group, setGroup] = useState(null);
    const [start, setStart] = useState(null);
    const [end, setEnd] = useState(null);
    const [performer, setPerformer] = useState(null);
    const [performer__name, setPerformerName] = useState("");
    const [event, setEvent] = useState(null);

    const filter = useMemo(() => {
        return { owner: target, owner__name, group, start, end, performer, performer__name, event };
    }, [target, group, start, end, performer, event]);

    const [isStartModalOpen, setIsStartModalOpen] = useState(false);
    const [isEndModalOpen, setIsEndModalOpen] = useState(false);
    const [isEventModalOpen, setIsEventModalOpen] = useState(false);
    const [isGroupModalOpen, setIsGroupModalOpen] = useState(false);
    const [isTargetModalOpen, setIsTargetModalOpen] = useState(false);
    const [isPerformerModalOpen, setIsPerformerModalOpen] = useState(false);

    const modals = useMemo(() => ({
        isStartModalOpen,
        isEndModalOpen,
        isEventModalOpen,
        isGroupModalOpen,
        isTargetModalOpen,
        isPerformerModalOpen,
    }), [
        isStartModalOpen,
        isEndModalOpen,
        isEventModalOpen,
        isGroupModalOpen,
        isTargetModalOpen,
        isPerformerModalOpen,
    ]);

    const updatePerformer = (userData) => {
        if (!userData) {
            setPerformer(null);
            setPerformerName("");
            return;
        }
        setPerformer(userData.userId);
        setPerformerName(userData.user__name);
    };

    const updateTarget = (userData) => {
        if (!userData) {
            setTarget(null);
            setOwnerName("");
            return;
        }
        setTarget(userData.userId);
        setOwnerName(userData.user__name);
    };

    return {
        filter,
        modals,
        setFilter: {
            updateTarget,
            setGroup,
            setStart,
            setEnd,
            updatePerformer,
            setEvent,
        },
        setModals: {
            setIsStartModalOpen,
            setIsEndModalOpen,
            setIsEventModalOpen,
            setIsGroupModalOpen,
            setIsTargetModalOpen,
            setIsPerformerModalOpen,
        }
    };
}

export { useLogFilter };