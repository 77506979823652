/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, August 1, 2021
 * For information or permission request, email info@aetonixsystems.com
 */


import Store from "./store";

import map from "fj-map";

import * as Sentry from "@sentry/react";

export default UserObservationsSingleIndicatorStore;

function UserObservationsSingleIndicatorStore(indicator) {
	return function(api, events, config) {
		const store = new Store();

		let loading = null;
		let page = 0;
		let user = null;
		let start = "";
		let end = "";
		const indicatorLowerCases = indicator.toLowerCase();
		let indicatorApi = null;
		switch (indicator) {
			case "activity": {
				indicatorApi = api.org.devices.activity.steps.cumulative;
				break;
			}
			case "bloodSugar": {
				indicatorApi = api.org.devices.health.glucose;
				break;
			}
			default: {
				indicatorApi = api.org.devices.health[indicatorLowerCases];
			}
		}

		store.more = more;
		store.reset = reset;
		store.sorted = sorted;

		return store;

		function reset(who) {
			user = who;
			page = 0;
			store.clear();
			return indicatorApi(user, page, start, end)
				.then(function(result) {
					if (result.length) {
						map(handle_create, result);
					} else {
						store.clear();
					}
				}).then(track).catch(error => {
					Sentry.captureException(error);
					console.error(error);
				});
		}

		function more() {
			if (loading) return loading;
			const originalPage = page;
			next_page();
			loading = indicatorApi(user, page, start, end)
				.then(map(handle_create))
				.catch((err) => {
					page = originalPage;
					store.error(err);
					Sentry.captureException(err);
				}).then(finish);
		}

		function sorted() {
			return store.all().sort(by_time);
		}

		function finish() {
			loading = null;
		}

		function handle_create(data) {
			var id = data._id;
			store.set(id, data);
		}

		function next_page() {
			page += 1;
		}

		function track() {
			const orgGroup = config.orgGroup;
			if (!orgGroup) return;
			events.on("mqtt:orggroup/" + orgGroup + "/u/" + user + "/observations/blood_oxygen", handleUpdate.bind(this, "bloodOxygen"));
			events.on("mqtt:orggroup/" + orgGroup + "/u/" + user + "/observations/blood_pressure", handleUpdate.bind(this, "bloodPressure"));
			events.on("mqtt:orggroup/" + orgGroup + "/u/" + user + "/observations/bloodsugar", handleUpdate.bind(this, "bloodSugar"));
			events.on("mqtt:orggroup/" + orgGroup + "/u/" + user + "/observations/body_temperature", handleUpdate.bind(this, "bodyTemperature"));
			events.on("mqtt:orggroup/" + orgGroup + "/u/" + user + "/observations/weight", handleUpdate.bind(this, "weight"));
			events.on("mqtt:orggroup/" + orgGroup + "/u/" + user + "/observations/steps/cumulant", handleUpdate.bind(this, "activity"));
			events.on("mqtt:orggroup/" + orgGroup + "/u/" + user + "/indicator/updated", handleIndicatorUpdate);
		}

		function handleUpdate(healthIndicator) {
			if (healthIndicator === indicator) {
				reset(user);
			}
		}

		function handleIndicatorUpdate(data){
			if(data?.type?.includes("step:history") && indicator === "activity"){
				store.set(data._id, data);
			} else if (data?.type?.includes(`${indicatorLowerCases}:history`)) {
				store.set(data._id, data);
			}
		}

		function by_time(prev, next) {
			var prev_time = (new Date(prev.created_at)).getTime();
			var next_time = (new Date(next.created_at)).getTime();
			var time_delta = next_time - prev_time;
			return time_delta;
		}
	};
}
