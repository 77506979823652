
/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import prettyMs from "pretty-ms";

import par from "par";
import React from "react";
import Table from "@material-ui/1.5.1/Table";
import TableHeader from "@material-ui/1.5.1/TableHead";
import TableHeaderColumn from "@material-ui/1.5.1/TableCell";
import TableBody from "@material-ui/1.5.1/TableBody";
import TableRow from "@material-ui/1.5.1/TableRow";
import TableRowColumn from "@material-ui/1.5.1/TableCell";
import Header from "../shared/Header.js";
import Dialog from "../shared/Dialog";
import List from "@material-ui/1.5.1/List";
import ListItem from "@material-ui/1.5.1/ListItem";
import ListItemText from "@material-ui/1.5.1/ListItemText";
import Button from "@material-ui/1.5.1/Button";
import Utility from "../shared/Utility.js";
import  AetonixTheme_mui_1_5_1 from "../shared/AetonixTheme_mui_1.5.1";
const Colors = AetonixTheme_mui_1_5_1.palette;

var styles = {
	header: {
		color: Colors.primary.dark,
	}
};
/*
Row data should be processed into a list of the following before processing:
{
	state: "calling",
	name: "Bob Smith",
	meta: "Room 101",
	time: "9:45 AM (2 minutes)",
	who: "Nurse Joy"
}
 */

var ONE_HOUR = 1000 * 60 * 60 * 1;

export default render;

function render() {
	var component = this;
	var state = component.state;
	var localization = state.localization;
	var careplanNoticeList = state.careplanChangeList.all();
	var careplanChanges = !!careplanNoticeList.length;
	var offline = state.connection.get("offline");
	var currentPerson = state.currentPerson;
	var people = state.people;
	var history = state.history;
	var group = state.group;

	var rowData = generate_data(localization, people, history, group);
	var detailsData = state.urgencies.get(state.details);
	var details = detailsData ? detailsData.history : [];
	var detailing = state.detailing;
	var titleKey = "urgencylive_title";

	var modal_actions = [
		(<Button key={"buttonok"} onClick={par(hide_details, component)}>OK</Button >)
	];

	var headers = [
		{
			name: "name",
			content: localization.get("urgency_headers_name")
		}, {
			name: "who",
			content: localization.get("urgency_headers_who")
		}, {
			name: "state",
			content: localization.get("urgency_headers_state")
		}, {
			name: "time",
			content: localization.get("urgency_headers_time")
		}, {
			name: "duration",
			content: localization.get("urgency_headers_duration")
		},
	];

	const rowIds = rowData.map((row) => row._id);
	if (dataHasChanged(rowIds, state.previousRowIds)) {
		rowIds.length && component.state.userMetrics.trackEvent("urgency-live: viewed urgency calls", {
			"calls": rowIds,
		});
		component.setState({
			previousRowIds: rowIds
		});
	}

	return (
		<div className="flex-vertical flex-1">
			<Header
				careplanChanges={careplanChanges}
				offline={offline}
				currentPerson={currentPerson}
				localization={localization}
				titleKey={titleKey}
			/>

			<Table>
				{renderHeader(headers)}
				{renderBody(component, rowData, headers)}
			</Table>
			<Dialog actions={modal_actions} open={detailing} title={localization.get("urgency_details")}>
				{render_details(state.people, details, localization)}
			</Dialog>

		</div>
	);
}

function dataHasChanged(newData, oldData) {
	newData = newData || [];
	oldData = oldData || [];
	if (newData.length !== oldData.length) {
		return true;
	}
	for (var i = 0; i < newData.length; i++) {
		if (newData[i]._id !== oldData[i]._id) {
			return true;
		}
	}
	return false;
}

function render_details(people, details, localization) {
	return (
		<List>
			{details.map(function (detail, index) {
				return (
					<ListItem key={index}>
						<ListItemText primary={detail_title(people, detail, localization)} secondary={timestamp_history(detail)} />
					</ListItem>
				);
			})}
		</List>
	);
}

function renderHeader(headers) {
	return (
		<TableHeader>
			<TableRow style={styles.header}>
				{headers.map(renderHeaderColumn)}
			</TableRow>
		</TableHeader>
	);
}

function renderHeaderColumn(column, index) {
	return (
		<TableHeaderColumn key={index}>{column.content}</TableHeaderColumn>
	);
}

function renderBody(component, rows, headers) {
	return (
		<TableBody>
			{rows.map(par(renderRow, component, headers))}
		</TableBody>
	);
}

function renderRow(component, headers, row, index) {
	return (
		<TableRow key={index}
			onClick={par(display_details, component, index, row)} >
			{headers.map(par(renderRowColumn, row))}
		</TableRow>
	);
}

function renderRowColumn(row, column, index) {
	return (
		<TableRowColumn key={index}>
			{row[column.name]}
		</TableRowColumn>
	);
}

function detail_title(people, detail, localization) {
	var type = detail.type;
	var person;
	if (type === "start") {
		return localization.get("urgency_started");
	}
	if (type === "call") {
		person = people.get(detail.who);
		return localization.get("urgency_calling") + Utility.format_name(person);
	}
	if (type === "cycle") {
		return localization.get("urgency_searching");
	}
	if (type === "cancel") {
		return localization.get("urgency_cancel");
	}
	if (type === "accept") {
		person = people.get(detail.who);
		return localization.get("urgency_accepted") + Utility.format_name(person);
	}

	return "Unknown event";
}

function hide_details(component) {
	component.setState({
		detailing: false
	});
	component.state.userMetrics.trackEvent("urgency-live: close details popup");
}

function display_details(component, index) {
	var data = component.state.history[index];
	var id = data._id;
	component.setState({
		details: id,
		detailing: true
	});
	component.state.userMetrics.trackEvent("urgency-live: open details popup", {
		"urgency": id,
	});
}

function generate_data(localization, people, data_list) {
	return data_list.map(function (data) {
		var last = last_event(data);
		var who_id = last.who;
		return {
			_id: data._id,
			time: timestamp_text(data),
			duration: duration(data),
			name: Utility.format_name(people.get(data.from)),
			state: history_item_type(localization, data),
			who: (who_id
				? Utility.format_name(people.get(who_id))
				: localization.get("urgency_notindb")) + " (" + last.online.length + ")"
		};
	}).filter(par(filter_for, localization));
}

/**
 * Filters out all calls that are not active ie. any call who's state is
 * 'cancelled' or 'accepted'
 * @param  {Objec} localization Contains key/value for translations
 * @param  {[type]} value        [description]
 * @return {[type]}              [description]
 */
function filter_for(localization, value) {
	if (value.state === localization.get("urgency_calling"))// == 'cancel' or accepted
		return value;
}

function history_item_type(localization, item) {
	var type = localization.get("urgency_calling");
	item.history.forEach(function function_name(event) {
		if (event.type === "accept")
			type = localization.get("urgency_accepted");
		if (event.type === "cancel")
			type = localization.get("urgency_cancelled");
	});
	return type;
}

function time_delta(start, end) {
	var start_time = (new Date(start)).getTime();
	var end_time = (new Date(end)).getTime();
	var delta = end_time - start_time;
	return delta;
}

function duration(item) {
	return prettyMs(time_delta(item.created_at, item.updated_at));
}

function last_event(item) {
	return item.history[item.history.length - 1];
}

function timestamp_history(item) {
	var delta = time_delta(item.timestamp, Date.now());
	if (delta <= ONE_HOUR)
		return prettyMs(delta) + " ago";
	return Utility.timestamp(item.timestamp, delta);
}

function timestamp_text(item) {
	var delta = time_delta(item.created_at, Date.now());
	if (delta <= ONE_HOUR)
		return prettyMs(delta) + " ago";
	return Utility.timestamp(item.created_at, delta);
}
