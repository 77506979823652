import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import Form from "ae-forms";
import React, { useState, useRef } from "react";
import { useLocalize } from "../../../shared/contexts/LocalizeContext";
import { useLocalizeFrom } from "../../../shared/contexts/LocalizeFromContext";
import StyledIconButton from "ae-base/Base/StyledIconButton";
import AetonixTheme from "../../../shared/AetonixTheme";
const Colors = AetonixTheme.palette;

const styles = {
	formContainer: {
		maxHeight: "calc(100vh - 200px)",
		overflowY: "auto",
	},
	scrollToTopButton: {
		position: "absolute",
		bottom: "1rem",
		right: "1rem",
		zIndex: 2,
		backgroundColor: Colors.primary.main,
		width: 60,
		height: 60,
		justifyContent: "center",
		color: "white",
		alignItems: "center",
		borderRadius: 30,
		borderWidth: 3,
		borderColor: "white"
	}
};

export function EditFormDialog({ isOpen, onClose, submission, submitForm }) {
	const localize = useLocalize();
	const localizeFrom = useLocalizeFrom();
	const [showScrollToTop, setShowScrollToTop] = useState(false);
	const formContainerRef = useRef(null);

	const handleScroll = () => {
		const scrollTop = formContainerRef?.current?.scrollTop;
		setShowScrollToTop(scrollTop > 100);
	  };

	  const scrollToTop = () => {
		formContainerRef?.current?.scrollTo({
		  top: 0,
		  behavior: "smooth",
		});
	  };

	async function handleSubmit(data) {
		const response = await submitForm({
			formId: submission.schema._id,
			submissionId: submission._id,
			data
		});
		if (response.status === "success") {
			onClose();
			return;
		}
	}

	return (
		<>
			<Dialog aria-labelledby="alert-dialog-title" fullWidth maxWidth="md" onClose={onClose} open={isOpen}>
				<DialogTitle>{localizeFrom(submission.schema.localization, ".") || ""}</DialogTitle>
				<DialogContent>
					<div ref={formContainerRef} onScroll={handleScroll} style={styles.formContainer}>
						<Form
							schema={submission.schema.schema}
							defaultData={submission.data}
							onCancel={onClose}
							cancelText={localize("groupforms.discardchanges")}
							submitText={localize("common.save")}
							onSubmit={handleSubmit}
						></Form>
						{showScrollToTop && (
							<StyledIconButton icon="arrow-up" contrast onPress={scrollToTop} style={styles.scrollToTopButton}></StyledIconButton>
						)}
					</div>
				</DialogContent>
			</Dialog>
		</>
	);
}
