/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import "../../static/css/font-awesome.min.css";
import "../../static/fonts/fontawesome-webfont.eot";
import "../../static/fonts/fontawesome-webfont.ttf";
import "../../static/fonts/fontawesome-webfont.svg";
import "../../static/fonts/fontawesome-webfont.woff";
import "../../static/fonts/fontawesome-webfont.woff2";
import "../../static/fonts/FontAwesome.otf";
import "../../static/images/icon.png";
import "../../static/images/logo.png";

import defineUI from "../shared/define";
import config from "../../configs/config.json";
import apiFactory from "ae-api";
let api = apiFactory(config);
import par from "par";

import * as Sentry from "@sentry/react";
import render from "./render.jsx";
import makeApi from "ae-api";

var validTypes = ["staff", "org:manager", "admin", "logistics"];

var Login = defineUI({
	getInitialState: getInitialState,
	componentDidMount: () => {
		var region = localStorage.getItem("region") || "Canada";

		var hosts = config.hosts || [];
		var foundHost = hosts.find(host => host.name === region) || {};
		var hostAPI = foundHost.host;

		var host = config.host || hostAPI;
		api = makeApi({host});
	},
	handleChangeEmail: function (event) {
		this.setState({
			email: event.target.value
		});
	},
	handleChangePassword: function (event) {
		this.setState({
			password: event.target.value
		});
	},
	handleChangeCode: function (event) {
		this.setState({
			code: event.target.value
		});
	},
	handlelogin: handlelogin,
	handleLoginSuccess: handleLoginSuccess,
	check_user_type: check_user_type,
	handleLoginFailure: handleLoginFailure,
	handleTwoFactor: handleTwoFactor,
	handleTwoFactorCancel: handleTwoFactorCancel,
	handleTwoFactorRequest: handleTwoFactorRequest,
	handleTwoFactorRequestSuccess: handleTwoFactorRequestSuccess,
	handleTwoFactorRequestError: handleTwoFactorRequestError,
	handleTwoFactorAuth: handleTwoFactorAuth,
	handleTwoFactorAuthSuccess: handleTwoFactorAuthSuccess,
	handleTwoFactorAuthFail: handleTwoFactorAuthFail,
	handleUpdateRegion: handleUpdateRegion,
	clock: clock,
	render,
});

export default Login;

function getInitialState() {
	return {
		email: "",
		password: "",
		message: "",
		loading: false,
		localization: {
			region: "Region",
			login_email: "Email",
			login_password: "Password",
			login_login: "Login",
			two_factor_title: "Please enter the 5-digit code sent to your email: ",
			two_factor_code: "Two-Factor Code",
			two_factor_submit: "Submit",
			two_factor_cancel: "Cancel",
			two_factor_request: "Request Two-Factor Authentication Code",
		},
		hasTwoFactorAuth: false,
		userId: null,
		code: "",
		btnText: "Request Two-Factor Authentication Code",
		isBtnDisabled: false,
		timer: 60,
		timerId: null,
		twoFactorSent: false,
		region: localStorage.getItem("region") || "Canada"
	};
}

function handlelogin(event) {
	event.preventDefault();
	let message = "";

	var email = this.state.email;
	if (!email) {
		this.props.userMetrics.trackEvent("login: failed login", {
			reason: "email missing",
		});
		message = "Please fill out all fields";
	}

	var password = this.state.password;
	if (!password) {
		this.props.userMetrics.trackEvent("login: failed login", {
			reason: "password missing",
		});
		message = "Please fill out all fields";
	}

	this.setState({message: message});
	if (email && password) {
		this.setState({loading: true});
		api.auth.login(email, password)
			.then(par(this.handleLoginSuccess, this.state.region))
			.catch(error => {
				if(error.status !== 401)
					Sentry.captureException(error);

				this.handleLoginFailure(error);
			});
	}
}

function handleLoginSuccess(region, data) {
	var multifactor = data.multifactor;
	var token = data.token;
	if (multifactor) {
		this.handleTwoFactor(data);
		this.props.userMetrics.trackEvent("login: submit correct credentials and move to 2fa", {
			region: region,
		});
	} else if (token) {
		localStorage.setItem("token", token);
		this.setState({
			loading: false,
			message: ""
		});
		this.check_user_type(token);
		this.props.userMetrics.trackEvent("login: successful login", {
			region: region,
		});
	}
	localStorage.setItem("region", region);
}

function handleTwoFactor(data) {
	this.setState({
		hasTwoFactorAuth: true,
		loading: false,
		userId: data.id
	});

	if (!this.state.twoFactorSent) this.handleTwoFactorRequest();
}

function handleTwoFactorRequest() {
	const btnTextOrigin = this.state.localization.two_factor_request;
	const timerId = setInterval(this.clock, 1000);
	const userId = this.state.userId;

	this.setState({
		isBtnDisabled: true,
		btnText: `${btnTextOrigin} (60s)`,
		timerId: timerId,
		twoFactorSent: true,
		loading: true,
		message: ""
	});

	this.props.userMetrics.trackEvent("login: request new 2fa code", {
		region: this.state.region,
	});

	api.auth.twoFactorAuthRequest(userId)
		.then(this.handleTwoFactorRequestSuccess)
		.catch(error => {
			Sentry.captureException(error);
			this.handleTwoFactorRequestError(error);
		});
}

function handleTwoFactorRequestSuccess() {
	this.setState({
		message: "",
		loading: false,
	});
}

function handleTwoFactorAuth(event) {
	event.preventDefault();
	const code = this.state.code;
	const userId = this.state.userId;
	let message = "";
	if (!code) {
		this.props.userMetrics.trackEvent("login: failed 2fa", {
			reason: "code missing",
		});
		message = "Please enter the code below";
	}
	this.setState({message: message});

	if (code) {
		this.setState({loading: true});
		api.auth.twoFactorAuth(userId, code)
			.then(this.handleTwoFactorAuthSuccess)
			.catch(error => {
				Sentry.captureException(error);
				this.handleTwoFactorAuthFail(error);
			});
	}
}

function handleTwoFactorAuthSuccess(data) {
	this.setState({
		loading: false,
		message: ""
	});
	const token = data.token || null;
	if (token) {
		localStorage.setItem("token", token);
		this.setState({
			loading: false,
			message: ""
		});
		this.check_user_type(token);
		this.props.userMetrics.trackEvent("login: successful login using 2fa", {
			region: this.state.region,
		});
	}
}

function handleTwoFactorAuthFail(err) {
	const status = err.status;
	const status_map = {
		0: "There was a network error",
		401: "Incorrect code",
		404: "Two-factor is disabled, please go back and login again"
	};
	const message = this.state.message ? this.state.message : (status ? status_map[status] : status_map[0]);
	this.setState({
		loading: false,
		message: message
	});
	this.props.userMetrics.trackEvent("login: failed 2fa", {
		reason: message,
	});
}

function handleTwoFactorRequestError(err) {
	const status = err.status;
	const status_map = {
		0: "There was a network error",
		400: "multifactor.error400",
		404: "Two-factor is disabled, please go back and login again"
	};
	const message = this.state.message ? this.state.message : (status ? status_map[status] : status_map[0]);
	const btnTextOrigin = this.state.localization.two_factor_request;

	clearInterval(this.state.timerId);

	this.setState({
		loading: false,
		message: message,
		timer: 60,
		btnText: btnTextOrigin,
		isBtnDisabled: false,
		twoFactorSent: false
	});
}

function handleTwoFactorCancel() {
	this.setState({
		hasTwoFactorAuth: false,
		email: "",
		password: "",
		code: "",
		userId: null,
		message: ""
	});
	this.props.userMetrics.trackEvent("login: cancel 2fa");
}

function handleLoginFailure(data) {
	var status = data.status;
	var status_map = {
		0: "There was a network error",
		400: "Please fill out all fields",
		401: "Your email/password combination is incorrect, please try again.",
		404: "Your email/password combination is incorrect, please try again."
	};
	var message = status ? status_map[status] : status_map[0] ;
	this.props.userMetrics.trackEvent("login: failed login", {
		reason: message,
	});
	this.setState({
		loading: false,
		message: message
	});
}

function handleUpdateRegion(event){
	var region = event.target.value;
	var hosts = config.hosts || [];
	var host = config.host || hosts.find(eachHost => eachHost.name === region).host;
	api = makeApi({host});
	this.setState({
		region: event.target.value
	});
	this.props.userMetrics.trackEvent("login: change region", {
		region: region,
	});
}

function check_user_type(token) {
	config.token = token;
	api.auth.auth(token)
		.then(function(data) {
			if (hasValidTypes(data)) {
				this.props.setAuthed(true);
			} else {
				var message = "Do not have access permission";
				this.setState({
					message: message
				});
			}
		}.bind(this));
}

function hasValidTypes(person) {
	var types = person.type;
	return validTypes.some(par(has_type, types));
}

function has_type(types, type) {
	return types.indexOf(type) !== -1;
}

function clock() {
	let ti = this.state.timer;
	const btnTextOrigin = this.state.localization.two_factor_request;
	if (ti > 0) {
		--ti;
		this.setState({
			timer: ti,
			btnText: `${btnTextOrigin} (${ti}s)`,
		});
	} else {
		clearInterval(this.state.timerId);
		this.setState({
			isBtnDisabled: false,
			timer: 60,
			btnText: btnTextOrigin,
			twoFactorSent: false
		});
	}
}
