/* © 2023 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";

import par from "par";
import escape_regex from "escape-regexp";
import config from "../../configs/config.json";
import Header from "../shared/Header.js";
import Avatar from "@material-ui/1.5.1/Avatar";
import Button from "@material-ui/1.5.1/Button";
import Dialog from "../shared/Dialog";
import IconButton from "@material-ui/1.5.1/IconButton";
import ListItem from "@material-ui/1.5.1/ListItem";
import ListItemText from "@material-ui/1.5.1/ListItemText";
import TextField from "@material-ui/1.5.1/TextField";
import SubHeader from "@material-ui/1.5.1/ListSubheader";
import Utility from "../shared/Utility";
import LazyList from "../shared/LazyList.jsx";
import  AetonixTheme_mui_1_5_1 from "../shared/AetonixTheme_mui_1.5.1";
const Colors = AetonixTheme_mui_1_5_1.palette;

var styles = {
	search: {
		color: Colors.primary.main,
		minWidth: "30%",
		padding: "8px"
	},
	input: {
		color: Colors.primary.main,
		padding: "8px"
	},
	inputProp: {
		style: {
			color: Colors.primary.main
		}
	}
};

var listItemClasses = {
	root: "ae-padding",
	gutters: "ae-padding",
	default: "ae-padding"
};

var iconButtonClasses = {
	root: "ae-inline"
};

export default render;

function render(){
	var state = this.state;

	var users = state.users;
	var localization = state.localization;
	var resources = state.resources;
	var careplanNoticeList = state.careplanChangeList.all();
	var careplanChanges = !!careplanNoticeList.length;
	var offline = state.connection.get("offline");
	var currentPerson = state.currentPerson;
	var people = state.people;
	var searchExist = state.searchExist;
	var loadMore = state.loadMore;
	var filteredResources = state.filteredResources;
	var filteredResourcesAdd = state.filteredResourcesAdd;
	var toRemove = state.toRemove;
	var adding = state.adding;
	var sortedUsers = users.map(people.get).sort(by_name);
	var titleKey = "user_resources";
	var viewing = state.viewing;
	var allResources = resources.all();

	var userResources = [];
	var shownResources = [];
	var shownAddingResources = [];
	var nonUserResources = [];
	if(viewing){
		var userResourcesMap = state.userResources.get(viewing);

		const currentLanguage = this.state.currentPerson.get("personal").language;
		userResources = userResourcesMap.map(resources.get.bind(resources)).sort(par(byResourcesName, currentLanguage));
		nonUserResources = allResources.filter(filterInvalid).filter(par(filterOwned, userResourcesMap)).sort(par(byResourcesName, currentLanguage));
		shownResources = filteredResources && filteredResources.length ? filteredResources : userResources;

		shownAddingResources = filteredResourcesAdd && filteredResourcesAdd.length ? filteredResourcesAdd : nonUserResources;
	}

	var removeResourceActions = [(
		<Button key={"user_ok"} onClick={par(confirmRemoveResource, this)}>{localization.get("users_ok")}</Button >
  ), (
		<Button key={"users_cancel"} onClick={par(hideRemovingResource, this)}>{localization.get("users_cancel")}</Button >
  )];

	var addResourceActions = [(
		<Button key={"user_done"} onClick={par(finishAddingResource, this)}>{localization.get("search_done")}</Button>
  )];

	var renderResourcesLazyList = () => {
		if (!userResources.length) return null;
		return (viewing ? (
			<LazyList className="ae-scrollable" renderItem={par(renderResource, this)} items={shownResources} />
		) : null);
	};

	var renderAddNewResource = viewing ? (
    <Button variant="raised" onClick={par(startAdding, this)} color="secondary" >{localization.get("user_resources_addresource")}</Button>
  ) : null;

	var renderSearchResource = () => {
		if (!userResources.length) return null;
		return (viewing ? (
			<TextField key={viewing} placeholder={localization.get("search_resources")} inputProps={{"aria-label": localization.get("search_resources") }} onChange={par(search, this, userResources, "Resources")} style={styles.search} InputProps={styles.inputProp} />
		) : null);
	};

	return(
    <div className="flex-vertical flex-1">
      <Header
        careplanChanges={careplanChanges}
        offline={offline}
        currentPerson={currentPerson}
        localization={localization}
        titleKey={titleKey} />

      <div className="flex-horizontal flex-1 ae-scrollable">
        <div className="flex-vertical flex-1 ae-left-margin">
          <SubHeader>{localization.get("user_resources_users")}</SubHeader>
          <TextField placeholder={localization.get("search_onNameorpatientNum")} inputProps={{"aria-label": localization.get("search_onNameorpatientNum") }} onChange={searchExist} style={styles.search} InputProps={styles.inputProp} />
          <LazyList className="ae-scrollable" loadMore={loadMore} renderItem={par(renderUser, this)} items={sortedUsers} />
        </div>
		<div className="flex-vertical flex-1">
			<div className="flex-vertical flex-1 ae-scrollable">
				<SubHeader>{localization.get("user_resources_resources")}</SubHeader>
				{renderSearchResource()}
				{renderResourcesLazyList()}
			</div>
			{renderAddNewResource}
		</div>
      </div>

      <Dialog actions={removeResourceActions} open={!!toRemove} title={localization.get("user_resources_removeTitle")}>
				{getRemovingText(this, localization, toRemove)}
			</Dialog>

      <Dialog actions={addResourceActions} open={!!adding} title={localization.get("user_resources_addingTitle")}>
        <TextField placeholder={localization.get("search_resources")} inputProps={{"aria-label": localization.get("search_resources") }} onChange={par(search, this, nonUserResources, "ResourcesAdd")} style={styles.search} InputProps={styles.inputProp} />
        <LazyList className="ae-scrollable" renderItem={par(renderResourceAdd, this)} items={shownAddingResources} />
    </Dialog>
    </div>
	);
}

function renderResource(component, resource){
	var currentPerson = component.state.currentPerson;
	var language = currentPerson.get("personal").language;
	var localization = component.state.localization;
	var viewing = component.state.viewing;
	if(!viewing || !resource) return;

	var name = getLang(resource.name, language);

	return (
    <ListItem key={resource._id} classes={listItemClasses} className={"ae-plain"}>
      <ListItemText primary={name} />
      <IconButton classes={iconButtonClasses} disableRipple color="primary" aria-label={localization.get("remove_button")} title={localization.get("remove_button")} role="button" className="fa fa-times-circle" onClick={par(startRemoveResource, component, resource)} />
    </ListItem>
	);
}

function startAdding(component){
	component.setState({
		adding: true
	});
	component.state.userMetrics.trackEvent("user-resources: open add resource popup");
}

function finishAddingResource(component){
	component.setState({
		adding: false
	});
	component.state.userMetrics.trackEvent("user-resources: close add resource popup");
}

function renderUser(component, user) {
	if (!user._id) return;
	var avatar = <Avatar alt="User Avatar Image" src={config.image_cdn + user.image} />;
	var text = Utility.format_name(user);
	var userId = user._id;
	var background_colour = "ae-plain";
	var viewing = component.state.viewing;
	if (userId === viewing)
		background_colour = "ae-hover-color";

	return (
		<ListItem key={user._id} classes={listItemClasses} className={background_colour} onClick={par(viewResourcesFor, component, userId)}>
			{avatar}
			<ListItemText primary={text} />
		</ListItem>
	);
}

function renderResourceAdd(component, resource) {
	var currentPerson = component.state.currentPerson;
	var language = currentPerson.get("personal").language;

	var viewing = component.state.viewing;
	if(!viewing) return;

	var name = getLang(resource.name, language);
	var resourceId = resource._id;
	var userId = viewing;
	return (
    <ListItem key={resource._id} button classes={listItemClasses} className={"ae-plain"} onClick={par(addResource, component, userId, resourceId)}>
      <ListItemText primary={name} />
    </ListItem>
	);
}

function addResource(component, userId, resourceId){
	var add = component.state.addResource;
	component.state.userMetrics.trackEvent("user-resources: add resource", {
		resource: resourceId,
		user: userId,
	});
	if(!userId || !resourceId) return;
	add(userId, resourceId);
}

function viewResourcesFor(component, user){
	component.setState({
		viewing: user,
		filteredResources: []
	});
	component.state.userMetrics.trackEvent("user-resources: view resources for user", {
		user,
	});
}

function search(component, array, key, event) {
	var currentPerson = component.state.currentPerson;
	var language = currentPerson.get("personal").language;
	event.persist();
	var query = event.target.value;
	var terms = escape_regex(query).split(" ").join("");
	var regex = new RegExp(terms, "i");

	var filtered = array.filter(function (item) {
		if(item === undefined) return null;
		var matches = getLang(item.name, language).split(" ").join("").match(regex);
		if (matches) return item;
	});

	var update = {};
	update["filtered" + key] = filtered;

	component.setState(update);
}

function startRemoveResource(component, resource){
	component.setState({
		toRemove: resource
	});
	component.state.userMetrics.trackEvent("user-resources: open remove resource popup", {
		resource: resource?._id,
	});
}

function confirmRemoveResource(component){
	var user = component.state.viewing;
	var removing = component.state.toRemove;
	var remove = component.state.removeResource;
	remove(user, removing._id);
	hideRemovingResource(component);
	component.state.userMetrics.trackEvent("user-resources: removed resource", {
		resource: removing._id,
		user,
	});
}

function hideRemovingResource(component){
	component.setState({
		toRemove: false
	});
	component.state.userMetrics.trackEvent("user-resources: close remove resource popup");
}

function getRemovingText(component, localization, resource){
	var currentPerson = component.state.currentPerson;
	var language = currentPerson.get("personal").language;
	if(!resource) return "";
	return localization.get("user_resources_remove") + " " + getLang(resource.name, language) + "?";
}

function by_name(x, y){
	return order(x.lname.toLowerCase() + x.fname.toLowerCase(), y.lname.toLowerCase() + y.fname.toLowerCase());
}

function byResourcesName(currentLanguage, x, y) {
	x = x.name[currentLanguage] || x.name.en || x.name;
	y = y.name[currentLanguage] || y.name.en || y.name;
	if(typeof x === "object") x = "";
	if(typeof y === "object") y = "";
	if (currentLanguage === "cn_s" || currentLanguage === "cn_t")
		currentLanguage = (currentLanguage === "cn_s") ? "zh-CN" : "zh-HK";
	return x.toLowerCase().localeCompare(y.toLowerCase(), currentLanguage);
}

function order(x, y){
	if(x < y)
		return -1;
	if(x > y)
		return 1;
	return 0;
}


function filterOwned(map, resource){
	return map.indexOf(resource._id) === -1;
}

function filterInvalid(resource) {
	return Object.keys(resource.name).length !== 0;
}

function getLang(map, language){
	if(typeof map === "string") return map;
	map = map || {};
	var returnValue = map[language];
	if(!returnValue) return map[Object.keys(map)[0]];
	return returnValue;
}
