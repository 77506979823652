export const PDF_PAGES = 5;

export const tableCellTypes = {
	0: {
		root: "status-green"
	},
	1: {
		root: "status-yellow"
	},
	2: {
		root: "status-red"
	}
};

export const KEYS = ["bloodoxygen", "bodytemperature", "bloodsugar", "weight", "bloodpressure", "activity"];

export const KEYS_TO_STORE_MAP = {
	bloodpressure: "bloodPressure",
	bloodoxygen: "bloodOxygen",
	bloodsugar: "bloodSugar",
	bodytemperature: "bodyTemperature",
	activity: "activity",
	weight: "weight"
};
export const TEMPLATES = {
	o2: {
		imperial: "{oxygensaturation}%",
		metric: "{oxygensaturation}%"
	},
	heartrate: {
		imperial: "{pulserate}bpm",
		metric: "{pulserate}bpm"
	},
	bodytemperature: {
		imperial: "{fahrenheit}°F",
		metric: "{celsius}°C",
		fahrenheit: "{fahrenheit}°F",
		celsius: "{celsius}°C"
	},
	bloodsugar: {
		imperial: "{mg}mg",
		metric: "{mmol}mmol",
		mg: "{mg}mg",
		mmol: "{mmol}mmol"
	},
	weight: {
		imperial: "{lbs}lbs",
		metric: "{kg}kg",
		lbs: "{lbs}lbs",
		kg: "{kg}kg"
	},
	bloodpressure: {
		imperial: "{systolic}/{diastolic}",
		metric: "{systolic}/{diastolic}"
	},
	bpPulserate: {
		imperial: "{bpPulserate}bpm",
		metric: "{bpPulserate}bpm"
	},
	activity: {
		imperial: "{cumulative}",
		metric: "{cumulative}"
	}
};

export const popperProps = {
	disablePortal: true
};
