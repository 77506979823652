/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */


import Store from "./store";

export default OrgCountStore;

function OrgCountStore(api) {
	var store = new Store();
	store.raw_get = store.get;
	store.get = get;

	reset();

	return store;

	function get(){
		var data = store.getAll();
		if(data && Object.keys(data).length > 0) return store.getAll();
		return {
			groups: "-",
			patients: "-",
			inactive: "-",
			active: "-",
			staff: "-",
			managers: "-",
			users: "-"
		};
	}

	function reset(){
		var prefix = api.org.stats;
		return Promise.all([
			prefix.groupCount(),
			prefix.patientCount(),
			prefix.inactiveCount(),
			prefix.activeCount(),
			prefix.staffCount(),
			prefix.managersCount(),
			prefix.userCount()
		]).then(handle_count);
	}

	function handle_count(results){
		store.setAll({
			groups: results[0],
			patients: results[1],
			inactive: results[2],
			active: results[3],
			staff: results[4],
			managers: results[5],
			users: results[6]
		});
	}
}
