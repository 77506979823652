
/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, May 3, 2018
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";

import par from "par";
import xtend from "xtend";
import escape_regex from "escape-regexp";
import Header from "../shared/Header.js";
import SubHeader from "@material-ui/1.5.1/ListSubheader";
import IconButton from "@material-ui/1.5.1/IconButton";
import TextField from "@material-ui/1.5.1/TextField";
import Select from "@material-ui/1.5.1/Select";
import MenuItem from "@material-ui/1.5.1/MenuItem";
import ListItem from "@material-ui/1.5.1/ListItem";
import ListItemText from "@material-ui/1.5.1/ListItemText";
import Dialog from "../shared/Dialog";
import Button from "@material-ui/1.5.1/Button";
import FontIcon from "@material-ui/1.5.1/Icon";
import InputLabel from "@material-ui/1.5.1/InputLabel";
import FormControl from "@material-ui/1.5.1/FormControl";
import FormHelperText from "@material-ui/1.5.1/FormHelperText";
import Tabs from "@material-ui/1.5.1/Tabs";
import Tab from "@material-ui/1.5.1/Tab";
import Snackbar from "@material-ui/1.5.1/Snackbar";
import LazyList from "../shared/LazyList.jsx";
import  AetonixTheme_mui_1_5_1 from "../shared/AetonixTheme_mui_1.5.1";
const Colors = AetonixTheme_mui_1_5_1.palette;

import * as Sentry from "@sentry/react";

var styles = {
	search: {
		color: Colors.primary.main,
		minWidth: "30%",
		padding: "8px"
	},
	input: {
		color: Colors.primary.main,
		padding: "8px"
	},
	icon: {color: Colors.primary.main},
	menuItemIcon: {
		color: Colors.primary.main,
		marginRight: "8px"
	},
	inputProp: {style: {color: Colors.primary.main}},
	noMatch: {
		padding: "1em",
		display: "inherit",
		justifyContent: "center",
		color: Colors.primary.main
	},
	duplicationHeader: {textAlign: "center"},
	toast: {
		margin: "100px",
		padding: "10px",
		backgroundColor: "rgb(0, 172, 193)",
		borderRadius: "10px"
	},
	inProgressToast: {
		margin: "100px",
		padding: "10px",
		backgroundColor: "white",
		borderRadius: "10px"
	},
	inProgressToastText: {
		color: "black",
		margin: "5px"
	},
	toastText: {
		color: Colors.canvas,
		margin: "5px"
	},
	snackbar: {style: {backgroundColor: "red"}}
};

export default render;

function render() {
	var component = this;
	var state = component.state;
	var toRemove = state.toRemove;

	var orgs = state.orgs;
	var allOrgs = state.allOrgs;
	var updating = state.updating;
	var creating = state.creating;
	var schemaError = state.schemaError;
	var localization = state.localization;
	var careplanNoticeList = state.careplanChangeList.all();
	var careplanChanges = !!careplanNoticeList.length;
	var offline = state.connection.get("offline");
	var currentPerson = state.currentPerson;
	var tabValue = state.tabValue;
	var resources = [];
	var workflow = [];
	var forms = [];
	var options = [];
	var isDuplicating = state.isDuplicating;
	var isMissingDestination = state.isMissingDestination;

	var sortedOrgs = orgs.sort(by_name);
	var loadMore = state.loadMore;
	var searchOrg = state.searchOrg;
	var viewing = state.viewing;

	var icons = localization.get("org_form_icons");
	var externals = findExternals(component);

	var currentIcon = (updating ? updating.icon : creating.icon) || "";
	var currentExternals = (updating ? updating.externals : creating.externals) || {};
	var defaultSchema = updating ? updating.schemaText : (creating ? creating.schemaText : null);
	var errorText = schemaError ? localization.get("org_form_schemaerror") : null;

	if (viewing){
		resources = state.resources.get(viewing);
		workflow = state.workflow.get(viewing);
		forms = state.forms.get(viewing);
		options = state.options.get(viewing);
	}
	var sortedWorkflow = workflow.sort(by_title);

	var renderOrgListItem = par(renderOrg, component, localization);

	var iconsList = Object.keys(icons).map(function (icon) {
		var iconName = "fa fa-" + icon;
		return (
			<MenuItem key={icon} value={icon} >
				<FontIcon className={iconName} style={styles.menuItemIcon} />
				{icons[icon]}
			</MenuItem>
		);
	});

	var language = currentPerson.get("personal").language;

	var resourcesMenu = resources.map(function(resource){
		return (<MenuItem key={resource._id} value={resource._id}>{getLang(resource.name, language)}</MenuItem>);
	});

	var formsMenu = forms.map(function(form){
		return (<MenuItem key={form._id} value={form._id}>{getFormName(currentPerson, form)}</MenuItem>);
	});

	var optionMenu = options.map(option => {
		return (<MenuItem key={option._id} value={option._id}>{getOptionLang(currentPerson, option, localization)}</MenuItem>);
	});

	var externalTab = externals ? (
		<Tabs fullWidth value={tabValue} indicatorColor="primary" onChange={par(handleTabValueChanged, component)}>
			<Tab label={localization.get("org_workflow_schema")} />
			<Tab label={localization.get("org_workflow_externals")} />
		</Tabs>
	) : null;

	var externalForm = externals ? Object.keys(externals).map(function(key){
		var menu = null;
		if(externals[key] === "form")
			menu = formsMenu;
		else if(externals[key] === "resource")
			menu = resourcesMenu;
		else if(externals[key] === "option")
			menu = optionMenu;
		else return null;

		return (
			<FormControl fullWidth>
				<InputLabel>{key}</InputLabel>
				<Select fullWidth value={currentExternals[key] || ""} onClick={par(updateExternal, component, key)}>
					{menu}
				</Select>
			</FormControl>
		);
	}) : null;

	var cancel = updating ? par(cancelUpdating, component) : par(cancelAdding, component);
	var dialogTitle = getDialogTitle(component, currentPerson, updating);

	var addActions = [
		(<Button key={"buttonok"} color="primary" onClick={par(submitWorkflow, component)}>{localization.get("org_create_submit")}</Button>),
		(<Button key={"buttoncancel"} color="primary" onClick={cancel}>{localization.get("org_create_cancel")}</Button>)
	];

	var remove_actions = [(
		<Button key={"buttonok"} onClick={par(confirmRemove, component)}>{localization.get("users_ok")}</Button >
	), (
		<Button key={"buttoncancel"} onClick={par(hideRemove, component)}>{localization.get("users_cancel")}</Button >
	)];

	var duplicate_actions = [
		(<Button onClick={par(cancelDuplicating, component)}>{localization.get("users_cancel")}</Button>),
		(<Button onClick={par(finishDuplicating, component)}>{localization.get("org_workflow_finish")}</Button>)
	];

	var currentDuplicateOrg = state.duplicateOrg;

	var titleKey = "admin_workflow_upload";

	var orgSelectors = allOrgs.sort(by_name).map(e => (
		<MenuItem key={e._id} value={e._id} >
			{e.name}
		</MenuItem>
	));

	const renderOpenToast = (
		<Snackbar open={state.openToast} autoHideDuration={3500} onClose={par(hideToastAndFinishDuplicating, component)} onClick={par(hideToastAndFinishDuplicating, component)}>
			<div className="flex-horizontal ae-dropshadow" style={styles.toast}>
				<FontIcon className="fa fa-check fa-2x" style={styles.icon} />
				<div style={styles.toastText}>
					{localization.get(state.toastMessage) || ""}
				</div>
			</div>
		</Snackbar>
	);

	const renderInProgressToast = (
		<Snackbar open={state.inProgressToast} onClose={par(hideToast, component)}>
			<div className="flex-horizontal ae-dropshadow" style={styles.inProgressToast}>
				<FontIcon className="fa fa-cloud-upload fa-2x" style={styles.icon} />
				<div style={styles.inProgressToastText}>
					{localization.get(state.toastMessage) || ""}
				</div>
			</div>
		</Snackbar>
	);

	const renderErrorToast = (
		<Snackbar
			ContentProps={styles.snackbar}
			open={state.errorToast}
			autoHideDuration={3500}
			onClose={par(hideToast, component)}
			message={state.errorToast}
			action={<IconButton className="fa fa-times" onClick={par(hideToast, component)}></IconButton>}
		/>
	);

	return (
		<div className="flex-vertical flex-1">
			<Header
				careplanChanges={careplanChanges}
				offline={offline}
				currentPerson={currentPerson}
				localization={localization}
				titleKey={titleKey}
			/>
			<Dialog actions={duplicate_actions} open={!!isDuplicating} title={localization.get("org_workflow_duplicating")}>
				<FormControl fullWidth error={isMissingDestination}>
					<InputLabel>{localization.get("org_workflow_duplicating")}</InputLabel>
					<Select onChange={par(updateOrgDropdown, component)} value={currentDuplicateOrg}>
						{orgSelectors}
					</Select>
					{isMissingDestination ? (<FormHelperText>{localization.get("admin_org_duplicating_missing_destination")}</FormHelperText>) : null}
				</FormControl>
			</Dialog>
			<Dialog actions={remove_actions} open={!!toRemove} title={localization.get("org_workflow_removeTitle")} >
				{localization.get("org_workflow_removeText")}
			</Dialog>
			<div className="flex-horizontal flex-1 ae-scrollable">
				<div className="flex-vertical flex-1 ae-left-margin ">
					<SubHeader>{localization.get("admin_form_org")}</SubHeader>
					<TextField placeholder={localization.get("admin_workflow_search")} inputProps={{"aria-label": localization.get("admin_workflow_search")}} onChange={searchOrg} style={styles.search} InputProps={styles.inputProp} />
					<LazyList loadMore={loadMore} renderItem={renderOrgListItem} items={sortedOrgs} />
				</div>
				<div className="flex-vertical flex-1">
					<div className="flex-vertical flex-1 ae-scrollable">
						<SubHeader>{localization.get("admin_workflow_text")}</SubHeader>
						{showFilter(component, sortedWorkflow, currentPerson)}
						{renderLazyList(component, currentPerson, par(showFormRemoval, component), par(startDuplicating, component), workflow)}
					</div>
					{renderAdd(component)}
				</div>
			</div>
			<div>
				<Dialog actions={addActions} open={!!creating || !!updating} title={dialogTitle}>
					{externalTab}
					{tabValue === 0 && (
						<div>
							<FormControl fullWidth>
								<InputLabel aria-label={localization.get("org_form_icon")} inputProps={{"aria-label": localization.get("org_form_icon")}} >{localization.get("org_form_icon")}</InputLabel>
								<Select onChange={par(updateDropdown, component, "icon")} role='listbox' aria-label ={localization.get("org_form_icon")} inputProps={{"aria-label": localization.get("org_form_icon"), "role": "listbox"}} value={currentIcon}>
									{iconsList}
								</Select>
							</FormControl>
							<TextField
								fullWidth
								defaultValue={defaultSchema}
								errorText={errorText}
								aria-label={localization.get("org_workflow_schema")}
								multiline
								inputProps={{"aria-label": localization.get("org_workflow_schema"), "role": "tab"}}
								role={"tablist"}
								rows="18"
								onChange={par(updateSchema, component)}
								label={localization.get("org_workflow_schema")}
								InputProps={styles.inputProp} />
						</div>
					)} {tabValue === 1 && externals && externalForm}
				</Dialog>
			</div>
			{renderInProgressToast}
			{renderOpenToast}
			{renderErrorToast}
		</div>
	);
}


function hideToast(component) {
	component.state.userMetrics.trackEvent("admin-workflow: hide error toast");
	component.setState({
		errorToast: false,
		openToast: false,
		inProgressToast: false,
		toastMessage: ""
	});
}

function hideToastAndFinishDuplicating(component) {
	component.state.userMetrics.trackEvent("admin-workflow: close duplicate workflow popup");
	component.setState({
		errorToast: false,
		openToast: false,
		inProgressToast: false,
		toastMessage: "",
		isDuplicating: null,
		duplicateOrg: ""
	});
}

function handleTabValueChanged(component, event, value) {
	component.setState({tabValue: value});
	const eventText = [
		"admin-workflow: navigate to schema tab in edit workflow popup",
		"admin-workflow: navigate to externals tab in edit workflow popup"
	];
	const eventProperties = component.state.updating._id
		? { workflow: component.state.updating._id }
		: { creating: true };
	component.state.userMetrics.trackEvent(eventText[value], eventProperties);
}

function showFilter(component, sortedWorkflow, currentPerson) {
	var state = component.state;
	var localization = state.localization;
	var viewing = state.viewing;
	if (!viewing) return;
	var workflow = state.workflow.get(viewing);
	if (!workflow || !workflow.length) return;

	return (
		<TextField key={viewing} placeholder={localization.get("search_onName")} inputProps={{"aria-label": localization.get("search_onName")}} onChange={par(searchWorkflow, sortedWorkflow, component, currentPerson)} InputProps={styles.inputProp} />
	);
}

function searchWorkflow(workflow, component, currentPerson, event) {
	event.persist();
	var query = event.target.value;
	var terms = escape_regex(query);
	var regex = new RegExp(terms, "gi");
	var person = currentPerson.get("personal");
	var language = person.language;

	var filtered = workflow.filter(function (item) {
		var description = item.schema.description;
		var name = description[language] || description[Object.keys(description)[0]];
		var matches = (name).match(regex);
		if (matches) return item;
	});

	if (!filtered.length && terms.length) filtered = ["none"];

	component.setState({filteredWorkflow: filtered});
}


function showAddWorkflow(component) {
	component.state.userMetrics.trackEvent("admin-workflow: show add workflow popup");
	component.setState({
		creating: {},
		schemaError: false,
		tabValue: 0
	});
}

function cancelAdding(component) {
	component.state.userMetrics.trackEvent("admin-workflow: close add workflow popup");
	component.setState({creating: false});
}

function renderAdd(component) {
	if (component.state.viewing)
		return (<Button variant="raised"
			onClick={par(showAddWorkflow, component)}
			color="secondary"
			>
			{component.state.localization.get("admin_form_add")}
		</Button>);
}

function by_name(x, y) {
	var X = x.name.toLowerCase();
	var Y = y.name.toLowerCase();
	if (X < Y) return -1;
	if (X > Y) return 1;
	return 0;
}

function by_title(x, y) {
	var titleX = x.schema.title || "Untitled";
	var titleY = y.schema.title || "Untitled";
	var X = titleX.toLowerCase();
	var Y = titleY.toLowerCase();
	if (X < Y) return -1;
	if (X > Y) return 1;
	return 0;
}

function renderOrg(component, localization, org, index) {
	var name = org.name;
	var orgIndex = index + 1;
	var description = org.description;
	var orgId = org._id;

	var background_colour = "ae-plain";
	var viewing = component.state.viewing;
	if (viewing && orgId === viewing) background_colour = "ae-hover-color";

	return (
		<ListItem button key={orgId} className={background_colour} onClick={par(viewWorkflowsFor, component, orgId, orgIndex)}>
			<ListItemText primary={name} secondary={description} />
		</ListItem>
	);
}

function viewWorkflowsFor(component, org, orgIndex) {
	component.state.userMetrics.trackEvent("admin-workflow: view workflows for org", {
		org: org,
	});
	component.setState({
		viewing: org,
		selectedIndex: orgIndex,
		filteredWorkflow: []
	});
}

function renderLazyList(component, currentPerson, formRemoval, duplicateStart, workflow) {
	var localization = component.state.localization;
	var show = [];
	if (!workflow.length) {
		return null;
	}
	var renderWorkflowListItem = par(renderWorkflow, component, currentPerson, formRemoval, duplicateStart);
	var filtered = component.state.filteredWorkflow;
	if (filtered[0] === "none") return (
		<div style={styles.noMatch}>{localization.get("search_noMatch")}</div>
	);
	if (!filtered.length) {
		show = workflow;
	} else {
		show = filtered;
	}

	return (<LazyList loadMore={noop} renderItem={renderWorkflowListItem} items={show} />);
}

function submitWorkflow(component) {
	var updating = component.state.updating;
	var workflow = component.state.creating || updating;
	var viewing = component.state.viewing;
	var submit = updating ? component.state.updateWorkflow : component.state.addWorkflow;
	var hide = updating ? cancelUpdating : cancelAdding;

	try {
		workflow.schema = JSON.parse(workflow.schemaText);
		submit(workflow, viewing);
		hide(component);
	} catch (err) {
		component.setState({schemaError: true});
	}

	const eventString = updating ? "admin-workflow: update workflow" : "admin-workflow: add workflow";
	component.state.userMetrics.trackEvent(eventString, {
		workflow: workflow._id,
		"schema json": workflow.schemaText,
	});
}

function updateOrgDropdown(component, e){
	e.persist();
	var payload = e.target.value;
	var duplicateOrg = payload;
	component.setState({
		duplicateOrg,
		isMissingDestination: false
	});
}

function updateDropdown(component, type, e) {
	e.persist();
	var payload = e.target.value;
	var updating = component.state.updating;
	var update = component.state.creating || updating;
	update[type] = payload;

	if (updating)
		updateUpdatingState(component, update);
	else
		updateCreatingState(component, update);
}

function updateCreatingState(component, update) {
	component.setState({creating: update});
}

function updateUpdatingState(component, update) {
	var updating = component.state.updating;
	component.setState({updating: xtend(updating, update)});
}

function updateSchema(component, e) {
	e.persist();
	var text = e.target.value;
	var updating = component.state.updating;
	var update = component.state.creating || updating;
	update.schemaText = text;

	if (updating)
		updateUpdatingState(component, update);
	else
		updateCreatingState(component, update);
}

function renderWorkflow(component, currentPerson, remove, duplicateStart, workflow) {
	if (!workflow) return null;
	var state = component.state;
	var localization = state.localization;
	var person = currentPerson.get("personal");
	var language = person.language;
	var workflowId = workflow._id;
	var schema = workflow.schema || {};
	var workflowDescription = schema.description || {};
	var keys = Object.keys(workflowDescription);
	var description = workflowDescription[language] || workflowDescription[keys[0]] || localization.get("org_workflow_untitled");
	var name = workflow.name || description;
	var button = (
		<div className="flex-horizontal">
			<IconButton className="fa fa-pencil" aria-label={localization.get("edit_button")}  title={localization.get("edit_button")} role="button" style={styles.icon} onClick={par(startUpdating, component, workflow)} />
			<IconButton className="fa fa-times-circle" aria-label={localization.get("remove_button")} title={localization.get("remove_button")} role="button" style={styles.icon} onClick={par(remove, workflow)} />
			{downloadFile(component, currentPerson, workflow, name)}
			<IconButton className="fa fa-copy" aria-label={localization.get("duplicate_button")} title={localization.get("duplicate_button")}  role="button" style={styles.icon} onClick={par(duplicateStart, workflow)} />
		</div>
	);

	var iconName = "fa fa-" + workflow.icon;
	var icon = <FontIcon className={iconName} style={styles.icon} />;
	var background_colour = "ae-plain";

	return (
		<ListItem  key={workflowId} className={background_colour} >  {/*was there a reason for <ListItem />  to be a button */}
			{icon}
			<ListItemText primary={name} />
			{button}
		</ListItem>
	);
}

function updateExternal(component, key, e){
	e.persist();
	var payload = e.target.value;
	var updating = component.state.updating;
	var update = component.state.creating || updating;
	if(!update.externals)
		update.externals = {};
	update.externals[key] = payload;

	if(updating)
		updateUpdatingState(component, update);
	else
		updateCreatingState(component, update);

	component.state.userMetrics.trackEvent("admin-workflow: edit externals", {
		workflow: update._id,
		external: key,
	});
}

function startUpdating(component, workflow) {
	var schema = workflow.schema;
	if (typeof schema !== "string")
		workflow.schemaText = JSON.stringify(schema, null, 4);
	var changes = xtend({}, workflow);
	component.setState({
		updating: changes,
		schemaError: false,
		tabValue: 0
	});
	component.state.userMetrics.trackEvent("admin-workflow: open edit workflow popup", {
		workflow: workflow._id,
	});
}

function cancelUpdating(component) {
	component.state.userMetrics.trackEvent("admin-workflow: close edit workflow popup");
	component.setState({updating: false});
}

function downloadFile(component, currentPerson, workflow, name) {
	var schemaText = JSON.stringify(workflow.schema, null, 4);
	var filename = name.replace(/ /g, "_") + ".schema.json";
	var jsonBlob = new Blob([schemaText], {type: "application/json"});
	var localization = component.state.localization;
	var url = window.URL.createObjectURL(jsonBlob);

	return (
		<IconButton
			className="fa fa-download"
			aria-label={localization.get("download_file_button")}
			title={localization.get("download_file_button")}
			role="button"
			style={styles.icon}
			href={url}
			download={filename}
			onClick={() => component.state.userMetrics.trackEvent("admin-workflow: download workflow", {
				workflow: workflow._id,
			})}
		/>
	);
}

function startDuplicating(component, workflow) {
	component.state.userMetrics.trackEvent("admin-workflow: open duplicate workflow popup", {
		workflow: workflow._id,
	});
	component.setState({isDuplicating: workflow});
}

function cancelDuplicating(component) {
	component.state.userMetrics.trackEvent("admin-workflow: close duplicate workflow popup");
	component.setState({
		isDuplicating: null,
		duplicateOrg: "",
		isMissingDestination: false
	});
}

function finishDuplicating(component) {
	const state = component.state;
	const workflow = state.isDuplicating;

	if(!workflow)
		return;

	const workflowId = workflow._id;
	const organization = workflow.organization;
	const destination = state.duplicateOrg;

	if (!destination) {
		component.setState({isMissingDestination: true});
		return;
	} else {
		component.setState({
			inProgressToast: true,
			toastMessage: "org_workflow_duplicating_inProgress"
		});
	}
	state.duplicateWorkflow(workflowId, organization, destination)
		.then(() => {
			component.setState({
				inProgressToast: false,
				openToast: true,
				toastMessage: "org_workflow_duplicating_success"
			});
		}).catch((err) => {
			Sentry.captureException(err);
			component.setState({
				inProgressToast: false,
				toastMessage: "",
				errorToast: err.response.message
			});
		});
	component.state.userMetrics.trackEvent("admin-workflow: duplicate workflow", {
		workflow: workflow._id,
		destination: destination
	});
}

function showFormRemoval(component, form) {
	component.state.userMetrics.trackEvent("admin-workflow: open remove workflow popup", {
		form: form._id,
	});
	component.setState({toRemove: form});
}

function confirmRemove(component) {
	var id = component.state.toRemove;
	var viewing = component.state.viewing;
	component.state.removeWorkflow(id, viewing);
	component.setState({toRemove: null});
	component.state.userMetrics.trackEvent("admin-workflow: remove workflow", {
		workflow: id._id,
	});
}


function hideRemove(component) {
	component.state.userMetrics.trackEvent("admin-workflow: close remove workflow popup");
	component.setState({toRemove: null});
}

function noop() { }

function getDialogTitle(component, currentPerson, workflow) {
	var state = component.state;
	var localization = state.localization;
	var person = currentPerson.get("personal");
	var language = person.language;
	var description = localization.get("org_workflow_new");
	var schemaDescription = workflow.schema ? workflow.schema.description : null;
	if (schemaDescription) {
		var keys = Object.keys(schemaDescription);
		description = keys.length !== 0 ? schemaDescription[language] || schemaDescription[keys[0]] : localization.get("org_workflow_untitled");
	}
	return workflow.name || description;
}

function getFormName(currentPerson, form) {
	var person = currentPerson.get("personal");
	var language = person.language;
	var localization = form.localization;
	var name = localization[language] || localization[Object.keys(localization)[0]];
	return name;
}

function findExternals(component){
	var workflow = component.state.updating || component.state.creating;
	var schemaText = workflow.schemaText;
	var schema = null;

	try {
		schema = JSON.parse(schemaText);
		if(schema.externals)
			return schema.externals;
		return null;
	} catch (err) {
		return null;
	}

}

function getLang(map, language){
	if(typeof map === "string") return map;
	map = map || {};
	var returnValue = map[language];
	if(!returnValue) return map[Object.keys(map)[0]];
	return returnValue;
}

function getOptionLang(currentPerson, option, localization){
	var schema = option.schema || {};
	var person = currentPerson.get("personal");
	var language = person.language;
	var schemaLocalization = schema.localization || {};
	return schemaLocalization[language] || schemaLocalization[Object.keys(schemaLocalization)[0]] || localization.get("group_options_unknown");
}
