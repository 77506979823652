import React from "react";
import { OwnerOngoingWorkflowsProvider } from "@aetonix/hooks";
import makeAppComponent from "../shared/AppComponent";
import userOverviewUi from "../user-overview/ui.jsx";

const UserOverview = makeAppComponent(userOverviewUi);

export const RootComponent = (props) => {
	const id = props.match.params.id;
	return (
		<OwnerOngoingWorkflowsProvider owner={id}>
			<UserOverview {...props} />
		</OwnerOngoingWorkflowsProvider>
	);
};