/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

import prettyMs from "pretty-ms";

import par from "par";
import React from "react";
import config from "../../configs/config.json";
import isEmpty from "is-empty";

/**
 *  material-ui components
 */
import Table from "@material-ui/1.5.1/Table";

import TableHeader from "@material-ui/1.5.1/TableHead";
import TableHeaderColumn from "@material-ui/1.5.1/TableCell";
import TableBody from "@material-ui/1.5.1/TableBody";
import TableRow from "@material-ui/1.5.1/TableRow";
import TableRowColumn from "@material-ui/1.5.1/TableCell";
import Header from "../shared/Header.js";
import Button from "@material-ui/1.5.1/Button";
import Dialog from "../shared/Dialog";
import TextField from "@material-ui/1.5.1/TextField";
import Chip from "@material-ui/1.5.1/Chip";
import Avatar from "@material-ui/1.5.1/Avatar";
import FontIcon from "@material-ui/1.5.1/Icon";

/**
 * User Interface elements Aetonix
 */

import Utility from "../shared/Utility.js";

import Scroll from "../shared/InfiniScroll.jsx";
import SearchTable from "../shared/SearchTable.jsx";
import DatePicker from "../shared/DatePicker";
import  AetonixTheme_mui_1_5_1 from "../shared/AetonixTheme_mui_1.5.1";
const Colors = AetonixTheme_mui_1_5_1.palette;

var styles = {
	icon: {
		fontSize: 20,
		color: Colors.canvas
	},
	avatar: {
		backgroundColor: Colors.primary.dark
	},
	back: {
		backgroundColor: Colors.primary.light,
		color: Colors.primary.main
	},
	backPrimary: {
		backgroundColor: "#E0E0E0"
	},
	avatarPrimary: {
		backgroundColor: "#BCBCBC"
	},

	inputProp: {
		style: {
			color: Colors.primary.main,
		}
	}
};

export default render;

function render() {
	var component = this;
	var state = component.state;
	var localization = state.localization;
	var careplanNoticeList = state.careplanChangeList.all();
	var careplanChanges = !!careplanNoticeList.length;
	var offline = state.connection.get("offline");
	var people = state.people;
	var rowData = state.calls.map(par(convertData, localization, people));
	var currentPerson = state.currentPerson;
	var startDate = state.startDate;
	var endDate = state.endDate;
	var report = state.report;
	var fileName = state.fileName;
	var loadMore = state.more;
	// Search related variables
	var choose_date = localization.get("choose_date");
	var callersFilter = state.callersFilter;
	var recipientsFilter = state.recipientsFilter;
	var dateFilter = state.dateFilter;
	var searchCaller = state.searchCaller;
	var searchRecipient = state.searchRecipient;
	var filterCallers = state.filterCallers;
	var filterRecipients = state.filterRecipients;
	var closeCallerSearch = state.closeCallerSearch;
	var closeRecipientSearch = state.closeRecipientSearch;
	var titleKey = "groupcall_title";

	var headers = [
		{
			name: "from",
			content: localization.get("call_headers_from")
		}, {
			name: "to",
			content: localization.get("call_headers_to")
		}, {
			name: "state",
			content: localization.get("call_headers_state")
		}, {
			name: "start",
			content: localization.get("call_headers_start")
		}, {
			name: "duration",
			content: localization.get("call_headers_duration")
		}
	];

	var report_actions = [
		csvReport(component, localization), (
			<Button key={"buttonreport"} onClick={par(hideReport, component)}>
				{localization.get("report_cancel")}
			</Button >
		)
	];

	if (rowsHaveChanged(state.calls, state.previousCalls)) {
		component.state.userMetrics.trackEvent("group-calls: viewed call history", {
			calls: state.calls.map((call) => call._id)
		});
		component.setState({
			previousCalls: state.calls
		});
	}

	var date_actions = [
		<Button
			key={"buttondate"}
			onClick={par(handle_date, component, "time")}>
			{localization.get("search_apply")}
		</Button >,
		<Button key={"buttoncancel"}
			onClick={par(hideSearch, component, "time")}>
			{localization.get("form_submissions_history_cancel")}
		</Button >
	];

	return (
		<div className="flex-vertical flex-1">

			<Header
				careplanChanges={careplanChanges}
				offline={offline}
				currentPerson={currentPerson}
				localization={localization}
				titleKey={titleKey}
			/>

			<div className="flex-horizontal ae-empty">
				{renderReportChip(component)}
				{renderCallerFilterChip(component)}
				{renderRecipientFilterChip(component)}
				{renderDateFilterChip(component)}
			</div>

			<Scroll loadMore={loadMore}>
				<Table>
					{renderHeader(headers)}
					{renderBody(headers, rowData)}
				</Table>
			</Scroll>

			<Dialog actions={report_actions} open={report} title={localization.get("groupcall_report_title")}>
				<div>
					<DatePicker invalid={choose_date}
						labelStart={localization.get("report_start")}
						labelEnd={localization.get("report_end")}
						startDate={startDate}
						endDate={endDate}
						updateStart={par(updateChanged, component, "startDate")}
						updateEnd={par(updateChanged, component, "endDate")}
					/>
					<TextField fullWidth placeholder={localization.get("report_filename")} inputProps={{"aria-label": localization.get("report_filename")}} value={fileName} onChange={par(updatefileNameChanged, component)} InputProps={styles.inputProp} />
				</div>
			</Dialog>

			<SearchTable action={par(filterCallers, component)} ref="searchDialog" search={searchCaller}
				localization={localization}
				people={people}
				showing={callersFilter}
				onClose={closeCallerSearch}
				title={localization.get("search_panel_title")} />

			<SearchTable action={par(filterRecipients, component)} ref="searchDialog" search={searchRecipient}
				localization={localization}
				people={people}
				showing={recipientsFilter}
				onClose={closeRecipientSearch}
				title={localization.get("search_panel_title")} />



			<Dialog actions={date_actions} open={dateFilter} title={localization.get("search_time")}>
				<DatePicker invalid={choose_date}
					labelStart={localization.get("report_start")}
					labelEnd={localization.get("report_end")}
					startDate={startDate}
					endDate={endDate}
					updateStart={par(updateChanged, component, "startDate")}
					updateEnd={par(updateChanged, component, "endDate")}
				/>
			</Dialog>

		</div>

	);
}

function rowsHaveChanged(newRows, oldRows) {
	newRows = newRows || [];
	oldRows = oldRows || [];
	if (newRows.length !== oldRows.length) {
		return true;
	}
	for (var i = 0; i < newRows.length; i++) {
		if (newRows[i].id !== oldRows[i].id) {
			return true;
		}
	}
	return false;
}


function renderReportChip(component) {
	var state = component.state;
	return (
		<Chip className="ae-icon ae-fonticon"
			style={styles.back}
			onClick={par(showReport, component)}
			avatar={<Avatar style={styles.avatar}>
				<FontIcon className="fa fa-floppy-o fa-2x" style={styles.icon} />
			</Avatar>}
			label={state.localization.get("groupcall_report_button")}
		/>
	);
}

function renderDateFilterChip(component) {
	var state = component.state;
	var deleteOn = noop();
	var styleavatar = styles.avatarPrimary;
	var styleback = styles.backPrimary;

	if (!isEmpty(state.startDate) && !isEmpty(state.endDate)) {
		deleteOn = par(clearSearch, component, "time");
		styleavatar = styles.avatar;
		styleback = styles.back;
	}
	return (
		<Chip className="ae-icon ae-fonticon"
			onDelete={deleteOn}
			style={styleback}
			onClick={par(showSearch, component, "time")}
			avatar={<Avatar style={styleavatar}>
				<FontIcon className="fa fa-filter fa-2x" style={styles.icon} />
			</Avatar>}
			label={renderFilterLabel(component, "time")}
		/>
	);
}

function renderCallerFilterChip(component) {
	var state = component.state;
	var deleteOn = noop();
	var styleavatar = styles.avatarPrimary;
	var styleback = styles.backPrimary;

	if (state.callers.length) {
		deleteOn = par(clearSearch, component, "callers");
		styleavatar = styles.avatar;
		styleback = styles.back;
	}
	return (
		<Chip className="ae-icon ae-fonticon"
			onDelete={deleteOn}
			style={styleback}
			onClick={par(showSearch, component, "callers")}
			avatar={<Avatar style={styleavatar}>
				<FontIcon className="fa fa-filter fa-2x" style={styles.icon} />
			</Avatar>}
			label={renderFilterLabel(component, "callers")}
		/>
	);
}

function renderRecipientFilterChip(component) {
	var state = component.state;
	var deleteOn = noop();

	var styleavatar = styles.avatarPrimary;
	var styleback = styles.backPrimary;

	if (state.recipients.length) {
		deleteOn = par(clearSearch, component, "recipients");
		styleavatar = styles.avatar;
		styleback = styles.back;
	}

	return (
		<Chip className="ae-icon ae-fonticon"
			onDelete={deleteOn}
			style={styleback}
			onClick={par(showSearch, component, "recipients")}
			avatar={<Avatar style={styleavatar}>
				<FontIcon className="fa fa-filter fa-2x" style={styles.icon} />
			</Avatar>}
			label={renderFilterLabel(component, "recipients")}
		/>
	);
}

function updateChanged(component, name, event, date) {
	var value = date;
	if (event) {
		value = event._d;
	}
	var update = {};
	update[name] = value;
	component.setState(update);
}

function convertData(localization, people, record) {

	return {
		"from": Utility.format_name(people.get(record.from)),
		"to": Utility.format_name(people.get(record.to)),
		"state": localization.get(what_state(record)),
		"start": Utility.timestamp(record.created_at),
		"duration": duration(record)
	};
}

function duration(call) {
	var state = what_state(call);
	var start,
		end;

	if (state === "call_invalid") //"Invalid"
		return "0ms";

	if (state === "call_finished") { //"Finished"
		start = call.content.accept;
		end = call.content.hangup;
	} else if (state === "call_denied") { //"Denied"
		start = call.content.send;
		end = call.content.deny;
	} else if (state === "call_cancelled") { //"Cancelled"
		start = call.content.send;
		end = call.content.hangup;
	} else if (state === "call_disconnected") { //"Disconnected"
		start = call.content.accept || call.content.send;
		end = call.content.disconnected;
	} else {
		start = call.content.send;
		end = call.content.disconnected || call.updated_at;
	}

	start = new Date(start).getTime();
	end = new Date(end).getTime();
	var delta = end - start;
	return prettyMs(delta);
}

/**
	* Determines the state of the call to post to dashboard
	* Below is truth table used; order of ops is important.
	* @param  {[type]} record [description]
	* @return {[type]}        [description]
	*/
function what_state(record) {
	var states = record.content;

	var send = "send" in states;
	var accept = "accept" in states;
	var deny = "deny" in states;
	var hangup = "hangup" in states;
	var disconnected = "disconnected" in states;

	if (!send)
		return "call_invalid";
	if (accept && !hangup)
		return "call_accepted";
	if (deny)
		return "call_denied";
	if (!deny && !hangup && !disconnected)
		return "call_calling";
	if (accept && hangup)		// removed test for disconnected temporarily
		return "call_finished";
	if (!deny && hangup)
		return "call_cancelled";
	if (!deny && !hangup && disconnected)
		return "call_interrupted";
	if (disconnected)
		return "call_disconnected";

	return "call_invalid";
}

function renderHeader(headers) {
	return (
		<TableHeader>
			<TableRow >
				{headers.map(renderHeaderColumn)}
			</TableRow>
		</TableHeader>
	);
}

function renderHeaderColumn(column) {
	return (
		<TableHeaderColumn key={column.content}>
			{column.content}
		</TableHeaderColumn>
	);
}

function renderBody(headers, rows) {
	return (
		<TableBody>
			{rows.map(par(renderRow, headers))}
		</TableBody>
	);
}

function renderRow(headers, row, index) {
	return (
		<TableRow key={index}>
			{headers.map(par(renderRowColumn, row))}
		</TableRow>
	);
}

function renderRowColumn(row, column) {

	return (
		<TableRowColumn key={column.name}>
			{row[column.name]}
		</TableRowColumn>
	);
}

function updatefileNameChanged(component, event) {
	event.persist();
	var value = event.target.value;
	component.setState({
		fileName: value
	});
}

function csvReport(component, localization) {

	var comp = component.state;
	var start = comp.startDate;
	var end = adjust_date(comp.endDate);
	var filename = comp.fileName;

	if (!filename) filename = localization.get("default_file_groupCalls");

	var language = comp.currentPerson.get("personal").language;
	var timezone = new Date().getTimezoneOffset();

	var url = config.host +
		"/v2/org/group/groupcalls/report?token=" +
		config.token +
		"&start=" +
		encodeURIComponent(start) +
		"&end=" +
		encodeURIComponent(end) +
		"&filename=" +
		encodeURIComponent(filename) +
		"&language=" +
		encodeURIComponent(language) +
		"&timezone=" +
		encodeURIComponent(timezone);

	return (
		<a
			key={"url"}
			className="ae-report-save"
			href={url}
			download={filename}
			onClick={() => component.state.userMetrics.trackEvent("group-calls: download report", {
				start,
				end,
				filename,
				language,
				timezone
			})}
		>
			{localization.get("report_save")}
		</a>
	);
}

function hideReport(component) {
	component.state.userMetrics.trackEvent("group-calls: close report download popup");
	return (
		component.setState({
			report: false,
		})
	);
}

function showReport(component) {
	component.state.userMetrics.trackEvent("group-calls: open report download popup");
	return (
		component.setState({
			report: true
		})
	);
}

/**
 * A function to reset a date by 1 day ahead.  Used to adjust the end date
 * on the date selector function because the default value is midnight of the
 * day selected instead of say, 23:59 of the day chosen or midnight of the next
 * day
 * @param  {String} date Date string
 * @return {String}      Date string
 */
function adjust_date(date) {
	if (isEmpty(date)) return "";
	var end = new Date(date);
	end.setDate(end.getDate() + 1);
	return end;
}


function showSearch(component, name) {
	if (name === "callers") component.setState({
		callersFilter: true
	});
	if (name === "recipients") component.setState({
		recipientsFilter: true
	});
	if (name === "status") component.setState({
		statusFilter: true
	});
	if (name === "time") component.setState({
		dateFilter: true
	});
	const eventText = {
		time: "group-calls: open date filter popup",
		callers: "group-calls: open caller filter popup",
		recipients: "group-calls: open recipient filter popup",
	}[name] || "group-calls: open filter popup";
	component.state.userMetrics.trackEvent(eventText);
}

function hideSearch(component, name) {
	if (name === "callers") component.setState({
		callersFilter: false
	});
	if (name === "recipients") component.setState({
		recipientsFilter: false
	});
	if (name === "status") component.setState({
		statusFilter: false
	});
	if (name === "time") component.setState({
		dateFilter: false
	});
	const eventText = {
		time: "group-calls: close date filter popup",
		callers: "group-calls: close caller filter popup",
		recipients: "group-calls: close recipient filter popup",
	}[name] || "group-calls: close filter popup";
	component.state.userMetrics.trackEvent(eventText);
}

function clearSearch(component, name) {
	const eventText = {
		time: "group-calls: clear date filter",
		callers: "group-calls: clear caller filter",
		recipients: "group-calls: clear recipient filter",
	}[name] || "group-calls: clear filter";
	component.state.userMetrics.trackEvent(eventText);

	var clear = component.state.filterClear;
	if (name === "callers") {
		component.setState({
			callers: []
		});
		return clear("callers");
	}
	if (name === "recipients") {
		component.setState({
			recipients: []
		});
		return clear("recipients");
	}
	if (name === "status") {
		component.setState({
			callstatus: ""
		});
		return clear("status");
	}
	if (name === "time") {
		component.setState({
			startDate: "",
			endDate: new Date()
		});
		return clear("time");
	}
}

function renderFilterLabel(component, name) {
	var state = component.state;
	var localization = state.localization;
	if (name === "time") {
		var start = state.startDate;
		var end = state.endDate;
		if (isEmpty(start) || isEmpty(end)) {
			return localization.get("search_start");
		} else {
			start = shortDate(start);
			end = shortDate(end);
			var label = start + " - " + end;
			return label;
		}
	}
	if (name === "callers") {
		var length = state.callers.length;
		if (!length) return (localization.get("search_from"));
		label = localization.get("search_from") + " (" + length + ")";
		return label;
	}
	if (name === "recipients") {
		length = state.recipients.length;
		if (!length) return (localization.get("search_to"));
		label = localization.get("search_to") + " (" + length + ")";
		return label;
	}
	if (name === "status") {
		var status = state.callstatus;
		if (!status) return localization.get("search_status");
		label = localization.get("search_status_value")[status];
		return label;
	}
}

function shortDate(date) {
	var day = date.getDate();
	var month = date.getMonth() + 1;
	var year = date.getFullYear();
	return (day + "/" + month + "/" + year);

}

function handle_date(component, value) {
	var state = component.state;
	hideSearch(component, value);
	var start = state.startDate;
	var end = adjust_date(state.endDate);
	component.state.userMetrics.trackEvent("group-calls: filter by date", {
		start: start,
		end: end
	});
	return state.filterDate(start, end);
}

function noop() { }
