export default {
	title: "WorkflowCreation",
	description: "schemas_workflow_creation",
	type: "object",
	properties: {
		definitions: {
			description: "workflow.creation.definitions",
			value_description: "workflow.creation.description",
			inputType: "dropdown",
			type: "object",
			source: "workflows"
		}
	},
};
