import React from "react";
import { TableCell, TableRow, LinearProgress } from "@material-ui/core";

// @todo this is bad practice, just porting over old version
const loaderCellStyle = {
    position: "absolute",
    top: "50%",
    left: 15,
    right: 15
};

export default function Loader({localization}) {
	return (
		<TableRow>
			<TableCell colSpan={100}>
				{"\u00A0"}
				<div style={loaderCellStyle}>
					<LinearProgress aria-label= {localization.get("progress_bar")} role="progressbar" title={localization.get("progress_bar")}/>
				</div>
			</TableCell>
		</TableRow>
	);
};