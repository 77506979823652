export const ACTIVE_STATES = [
	"new",
	"consented",
	"waiting-for-onboarding",
	"on-boarded",
	"active-care",
	"discharged",
];

export const USER_STATES = [
	"all_active",
	...ACTIVE_STATES,
	"inactive",
];