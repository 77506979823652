/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */



import Store from "./store";
import map from "fj-map";
import compose from "fj-compose";
import pluck from "fj-pluck";
var getWho = pluck("who");

import * as Sentry from "@sentry/react";

export default StaffStore;

function StaffStore(api, events) {
	var store = new Store();

	api.org.group.current.data()
		.then(pluck("_id"))
		.then(listen_for_changes)
		.catch(error => {
			Sentry.captureException(error);
			store.error(error);
		});

	var query = "";
	var page = 0;
	var loading = null;
	var atEnd = false;
	var loadingAll = true;

	store.more = more;
	store.search = search;
	store.loadAllStaff = loadAllStaff;
	store.checkStaff = checkStaff;

	reset();

	return store;

	function reset(){
		page = 0;
		atEnd = false;
		store.clear();
		return api.org.staff.list(query, page).then(map(add_person)).catch(error => {
			Sentry.captureException(error);
			store.error(error);
		});
	}

	function more() {
		if (atEnd)
			return Promise.resolve();
		if (loading)
			return loading;
		page += 1;
		return api.org.staff.list(query, page).then(map(handle_staff)).catch(error => {
			Sentry.captureException(error);
			store.error(error);
		});
	}

	function handle_staff(staff) {
		if (!staff.length) {
			atEnd = true;
			loadingAll = false;
			return;
		}

		if(Array.isArray(staff))
			staff.map(add_person);

		loading = null;
		if(loadingAll) more();
	}

	function search(newQuery) {
		query = newQuery;
		return reset();
	}

	function add_person(id) {
		store.set(id, id);
	}

	function remove_person(id) {
		store.remove(id);
	}

	function listen_for_changes(group) {
		events.on("mqtt:orggroup/" + group + "/staff/added", compose(add_person, getWho));
		events.on("mqtt:orggroup/" + group + "/staff/removed", compose(remove_person, getWho));
	}

	function loadAllStaff(){
		loadingAll = true;
		more();
	}

	function checkStaff(id){
		return !!store.get(id);
	}

}
