import "./shared/shared.css";
import ReactDOM from "react-dom";
import React from "react";
import App from "./App.js";
// eslint-disable-next-line node/no-unpublished-import
import config from "../configs/config.json";
import { UserMetricsProvider } from "@aetonix/user-metrics";

const serverUrl = config.user_metrics_url;
const options = serverUrl ? { serverUrl } : {};

const GlobalContextProviders = (props) => (
	<UserMetricsProvider config={config} options={options}>
		{props.children}
	</UserMetricsProvider>
);

ReactDOM.render(
	<GlobalContextProviders>
		<App />
	</GlobalContextProviders>,
	document.querySelector("main")
);
