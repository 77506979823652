
/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */




import Store from "./store";
import par from "par";

export default UserThresholdStore;

function UserThresholdStore(api, events, config) {
	var store = new Store();

	store.raw_get = store.get;
	store.get = get;

	var tracking = [];

	return store;

	function get(id) {
		var existing = store.raw_get(id);
		if (existing) return existing;

		if (!isTracking(id))
			track(id);

		return [];
	}

	function isTracking(id) {
		return tracking.indexOf(id) !== -1;
	}

	function track(id) {
		tracking.push(id);
		api.org.thresholds.list(id).then(par(updateThresholds, id));

		var orgGroup = config.orgGroup;
		if(!orgGroup) return;
		events.on("mqtt:orggroup/" + orgGroup + "/u/" + id + "/thresholds/updated", par(updateThresholds, id));
		events.on("mqtt:orggroup/" + orgGroup + "/u/" + id + "/thresholds/group/removed", par(reset, id));
	}

	function reset(id)
	{
		api.org.thresholds.list(id).then(par(updateThresholds, id));
	}

	function updateThresholds(id, thresholds){
		store.set(id, thresholds);
	}
}