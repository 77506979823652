export async function saveFile(url, filename) {
	const xhr = new Promise(function(resolve, reject) {
		var request = new window.XMLHttpRequest();
		request.responseType = "blob";
		request.onload = function() {
			resolve(request);
		};
		request.onerror = reject;
		request.open("GET", url);
		request.send();
	});

    const request = await xhr;

    const a = document.createElement("a");
    a.href = window.URL.createObjectURL(request.response);
    a.download = filename;
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();

    return request;
}

export function getFilterEnd(currentEnd) {
	return !currentEnd ? new Date(Date.now()).toISOString() : (currentEnd?.toISOString?.() ?? currentEnd);
}

export function getUrlWithFilterOptions(baseUrl, filter) {
	return Object.keys(filter).reduce((url, key) => {
		const value = filter[key];
		return value ? url.concat(`&${key}=${encodeURIComponent(value)}`) : url;
	}, baseUrl);
}
