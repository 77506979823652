import Utility from "../shared/Utility.js";
import isEmpty from "is-empty";

export function convertData(people, record) {
	var braceletUser = Utility.format_name(people.get(record.who));
	var timeOfEvent = Utility.timestamp(record.created_at);
	var value = record?.history?.value ?? "";

	const rowData = {
		user: braceletUser,
		value: value,
		time: timeOfEvent
	};

	return rowData;
}

/**
 * A function to reset a date by 1 day ahead.	Used to adjust the end date
 * on the date selector function because the default value is midnight of the
 * day selected instead of say, 23:59 of the day chosen or midnight of the next
 * day
 * @param {String} date Date string
 * @return {String}	Date string
 */
export function getAdjustedDate(date) {
	if (isEmpty(date)) {
		return "";
	}

	var end = new Date(date);
	end.setDate(end.getDate() + 1);
	return end;
}

export function getShortDate(date) {
	var newdate = new Date(date);
	var day = newdate.getDate();
	var month = newdate.getMonth() + 1;
	var year = newdate.getFullYear();
	return day + "/" + month + "/" + year;
}

export function getFilterLabel(name, start, end, localization, query) {
	if (name === "time") {
		if (!start || !end) {
			return localization.get("search_time");
		}

		start = getShortDate(start);
		end = getShortDate(end);

		return `${start} - ${end}`;
	} else if (name === "user") {
		var length = query.length;
		if (!length) {
			return localization.get("search_patient");
		}

		return `${localization.get("search_patient")} (${length})`;
	}

	return "";
}
