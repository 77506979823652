/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, May 3, 2018
 * For information or permission request, email info@aetonixsystems.com
 */

import par from "par";

import defineUI from "../shared/define";
import render from "./render.jsx";
import PersonStore from "../stores/person_store";
import OrgStore from "../stores/org_store";
import FormStore from "../stores/admin_forms_store";
import LocalizationStore from "../stores/localization_store";
import CurrentPersonInfoStore from "../stores/current_person_info_store";
import CareplanNotificationStore from "../stores/careplan_notice_store";
import ConnectionStore from "../stores/connection_store";
import AdminOrgStore from "../stores/admin_org_store";
import OptionStore from "../stores/admin_options_schema_store";
import makeStoreFactory from "../shared/stores";

const makeStores = makeStoreFactory({
	orgs: OrgStore,
	allOrgs: AdminOrgStore,
	people: PersonStore,
	forms: FormStore,
	currentPerson: CurrentPersonInfoStore,
	localization: LocalizationStore,
	careplanChangeList: CareplanNotificationStore,
	connection: ConnectionStore,
	options: OptionStore
});

export default make_ui;

function make_ui(api, events, config, userMetrics) {
	var storeStore = makeStores(api, events, config);

	window.stores = storeStore.store;

	userMetrics.trackEvent("page view", {
		"page": "admin-forms",
		"category": "admin",
		"permission level": "admin",
	});

	return defineUI({
		getInitialState: getInitialState,
		componentDidMount: listen_for_changes,
		render: render,
		componentWillUnmount: removeListeners
	});

	function removeListeners() {
		events.off();
	}


	function listen_for_changes() {
		storeStore.listen(par(handle_change, this));
	}

	function getInitialState() {
		return {
			orgs: [],
			allOrgs: [],
			loadMore: par(loadMore, storeStore.stores.orgs),
			people: storeStore.stores.people,
			forms: storeStore.stores.forms,
			currentPerson: storeStore.stores.currentPerson,
			localization: storeStore.stores.localization,
			searchOrg: par(searchOrg, storeStore.stores.orgs),
			careplanChangeList: storeStore.stores.careplanChangeList,
			connection: storeStore.stores.connection,
			options: storeStore.stores.options,
			updating: false,
			creating: false,
			schemaError: false,
			formError: {},
			prepopulateError: {},
			addForm: addForm,
			updateForm: updateForm,
			removeForm: removeForm,
			duplicateForm: duplicateForm,
			isDuplicating: false,
			duplicateOrg: "",
			errorToast: false,
			openToast: false,
			inProgressToast: false,
			toastMessage: "",
			isMissingDestination: false,
			userMetrics: userMetrics,
		};
	}

	function duplicateForm(form, org, destination) {
		return api.admin.forms.duplicate(form, org, destination);
	}

	function searchOrg(orgs, event) {
		event.persist();
		var query = event.target.value;
		return orgs.search(query);
	}

	function loadMore(orgs) {
		return orgs.more();
	}

	function handle_change(component, stores) {
		component.setState({
			orgs: stores.orgs.all(),
			allOrgs: stores.allOrgs.all(),
			options: stores.options,
			people: stores.people,
			forms: stores.forms,
			currentPerson: stores.currentPerson,
			localization: stores.localization,
			careplanChangeList: stores.careplanChangeList,
			connection: stores.connection
		});
	}


	function addForm(form, viewing) {
		var icon = form.icon;
		var formType = form.formType;
		var schema = form.schema;
		var localization = form.localization;
		if (!icon || !formType || !schema || Object.keys(localization) <= 0) {
			return;
		}
		return api.admin.forms.create(form, viewing);
	}

	function updateForm(form, viewing) {
		var formId = form._id;
		var icon = form.icon;
		var formType = form.formType;
		var schema = form.schema;
		var localization = form.localization;
		var organization = form.organization;

		if (organization !== viewing) return;

		if (!icon || !formType || !schema || Object.keys(localization) <= 0) {
			return;
		}

		return api.admin.forms.update(formId, form, organization);
	}

	function removeForm(form, viewing) {
		var formId = form._id;
		var organization = form.organization;
		if (organization !== viewing) return;
		return api.admin.forms.remove(formId, organization);
	}

}
