/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

import par from "par";
import config from "../../configs/config.json";
import React from "react";
import CreateReactClass from "create-react-class";
import ReactPropTypes from "prop-types";
import AetonixTheme from "./AetonixTheme.js";
import Dialog from "../shared/Dialog";
import List from "@material-ui/1.5.1/List";
import ListItem from "@material-ui/1.5.1/ListItem";
import ListItemText from "@material-ui/1.5.1/ListItemText";
import TextField from "@material-ui/1.5.1/TextField";
import Avatar from "@material-ui/1.5.1/Avatar";
import Button from "@material-ui/1.5.1/Button";
import Utility from "./Utility.js";

import * as Sentry from "@sentry/react";

export default CreateReactClass({
	displayName: "SearchTable",
	childContextTypes: {
		muiTheme: ReactPropTypes.object
	},
	getChildContext: getChildContext,
	getInitialState: getInitialState,

	render: render,
	show: show,
	dismiss: dismiss,
	doSearch: doSearch,
	setResults: setResults
});

function getChildContext() {
	return {
		muiTheme: AetonixTheme
	};
}

function getInitialState() {
	return {
		showing: false,
		results: []
	};
}

function render() {
	var searchResults = this.state.results;
	var showing = this.props.showing;
	var searchTitle = this.props.title;
	var searchName = this.props.localization.get("search_name");
	var isIds = (typeof searchResults[0]) === "string";
	var people = this.props.people;
	var onClose = this.props.onClose;

	var actions = [
		(<Button key={"buttonclose"}  onClick={onClose}>{this.props.localization.get("search_close")}</Button >)
	];

	if(isIds)
		searchResults = searchResults.map(people.get);

	return (
		<Dialog actions={actions} autoDetectWindowHeight={true} open={showing} title={searchTitle}>
			<TextField placeholder={searchName} inputProps={{"aria-label": searchName }} onChange={this.doSearch}/>
			<List>
				{searchResults.sort(by_name).map(par(renderSearchItem, this))}
			</List>
		</Dialog>
	);
}

function by_name(x, y) {
	var X = x.fname.toLowerCase();
	var Y = y.fname.toLowerCase();
	if (X === Y) {
		X = x.lname.toLowerCase();
		Y = y.lname.toLowerCase();
	}

	if (X < Y) return -1;
	if (X > Y) return 1;
	return 0;
}

function renderSearchItem(component, person) {
	var avatar = <Avatar alt="User Avatar Image" src={config.image_cdn + person.image}/>;
	var text = Utility.format_name(person);
	var clickAction = par(component.props.action, person._id);
	return (
		<ListItem key={person._id} button onClick={clickAction} >
			{avatar}
			<ListItemText primary={text} />
		</ListItem>
	);
}

function doSearch(e) {
	var query = e.target.value;

	var search = this.props.search;

	search(query).then(this.setResults).catch(error => {
		Sentry.captureException(error);
		console.error.bind(console, error);
	});
}

function setResults(results) {
	this.setState({
		results: results
	});
}

function show() {
	this.setState({
		showing: true,
		results: []
	});
}

function dismiss() {
	this.setState({
		results: [],
		showing: false
	});
}
