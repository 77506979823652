/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";
import par from "par";
import xtend from "xtend";
import clone from "clone";

import Header from "../shared/Header.js";
import IconButton from "@material-ui/1.5.1/IconButton";
import TextField from "@material-ui/1.5.1/TextField";
import Select from "@material-ui/1.5.1/Select";
import MenuItem from "@material-ui/1.5.1/MenuItem";
import ListItem from "@material-ui/1.5.1/ListItem";
import ListItemText from "@material-ui/1.5.1/ListItemText";
import Dialog from "../shared/Dialog";
import Button from "@material-ui/1.5.1/Button";
import Tabs from "@material-ui/1.5.1/Tabs";
import Tab from "@material-ui/1.5.1/Tab";
import FontIcon from "@material-ui/1.5.1/Icon";
import InputLabel from "@material-ui/1.5.1/InputLabel";
import FormControl from "@material-ui/1.5.1/FormControl";
import Avatar from "@material-ui/1.5.1/Avatar";

import LazyList from "../shared/LazyList.jsx";
import AetonixTheme from "../shared/AetonixTheme";
const Colors = AetonixTheme.palette;
import { Checkbox, FormControlLabel } from "@material-ui/1.5.1";
// eslint-disable-next-line node/no-missing-require
import Prepopulate from "./Prepopulate";

var styles = {
	input: {
		color: "grey",
		marginTop: "8px",
		marginBottom: "8px"
	},
	iconStyle: {
		color: "#065B61",
		paddingRight: 15
	},
	inputlabel: {
		color: "black"
	},
	formControlStyle: {
		marginTop: "8px",
		marginBottom: "8px"
	},
	inputProp: {
		style: {
			color: Colors.primary.main,
		}
	},
};

var listItemClasses = {
	default: "ae-padding",
	gutters: "ae-padding",
	root: "ae-padding"
};

var iconButtonClasses = {
	label: "ae-widthauto"
};

module.exports = render;

function render() {
	var component = this;

	var state = component.state;
	var toRemove = state.toRemove;

	var forms = state.forms;
	var currentPerson = state.currentPerson;
	var localization = state.localization;
	var offline = state.connection.get("offline");
	var careplanList = state.careplanChangeList.all();
	var careplanChanges = !!careplanList.length;
	var updating = state.updating;
	var creating = state.creating;
	var schemaError = state.schemaError;
	var errorText = schemaError ? localization.get("org_form_schemaerror") : null;

	var tabValue = state.tabValue || 0;
	var formError = state.formError || {};
	var errorLocalization = formError.localization ? localization.get("org_form_missed_localization") : null;

	var icons = localization.get("org_form_icons");
	var formTypes = localization.get("org_form_formtypes");
	var levels = localization.get("org_form_levels");

	var currentEmrId = (updating ? updating.emrId : creating.emrId || "");
	var currentIcon = (updating ? updating.icon : creating.icon || "");
	var currentFormType = (updating ? updating.formType : creating.formType) || "";
	var currentLevel = (updating ? updating.targetType : creating.targetType) || "";
	var currentLockOnSubmit = (updating ? updating.lockOnSubmit : creating.lockOnSubmit) || false;
	var defaultEn = (updating ? updating.localization.en : (creating.localization ? creating.localization.en : null)) || null;
	var defaultFr = (updating ? updating.localization.fr : (creating.localization ? creating.localization.fr : null)) || null;
	var defaultCns = (updating ? updating.localization.cn_s : (creating.localization ? creating.localization.cn_s : null)) || null;
	var defaultCnt = (updating ? updating.localization.cn_t : (creating.localization ? creating.localization.cn_t : null)) || null;
	var defaultEs = (updating ? updating.localization.es : (creating.localization ? creating.localization.es : null)) || null;
	var defaultPt = (updating ? updating.localization.pt : (creating.localization ? creating.localization.pt : null)) || null;
	var defaultTa = (updating ? updating.localization.ta : (creating.localization ? creating.localization.ta : null)) || null;

	var externals = findExternals(component);

	var titleKey = "dashboards_header_forms";

	var defaultSchema = (updating ? updating.schemaText : creating.schemaText) || null;

	var iconsList = Object.keys(icons).map(function (icon) {
		var iconName = "fa fa-" + icon;
		var renderIcon = (
			<div>
				<FontIcon className={iconName} style={styles.iconStyle} />
				{icons[icon]}
			</div>
		);
		return (
			<MenuItem key={icon} value={icon}>{renderIcon}</MenuItem>
		);
	});

	var cancel = updating ? par(cancelUpdating, component) : par(cancelAdding, component);
	var dialogTitle = updating ? getDialogTitle(currentPerson, updating) : localization.get("org_form_newform");

	var addActions = [
		(<Button key={"buttonsubmit"} onClick={par(submitForm, component)}>{localization.get("org_create_submit")}</Button >),
		(<Button key={"buttoncancel"} onClick={cancel}>{localization.get("org_create_cancel")}</Button >)
	];

	var remove_actions = [(
		<Button key={"buttonok"} onClick={par(confirmRemove, component)}>{localization.get("users_ok")}</Button >
	), (
		<Button key={"buttoncancel"} onClick={par(hideRemove, component)}>{localization.get("users_cancel")}</Button >
	)];

	return (
		<div className="flex-vertical flex-1">
			<Header
				careplanChanges={careplanChanges}
				offline={offline}
				currentPerson={currentPerson}
				localization={localization}
				titleKey={titleKey}
			/>

			<Dialog actions={remove_actions} open={!!toRemove} title={localization.get("org_form_removeTitle")} >
				{localization.get("org_form_removeform")}
			</Dialog>

			<LazyList loadMore={noop} renderItem={par(renderForm, component, currentPerson, par(showFormRemoval, component))} items={forms} />
			<Button variant="raised" onClick={par(showAddForm, component)} color="secondary" >{localization.get("org_form_addnewform")}</Button>

			<Dialog actions={addActions} open={!!creating || !!updating} title={dialogTitle}>
				<Tabs fullWidth value={tabValue} indicatorColor="primary"  onChange={par(handleTabValueChanged, component)}>
					<Tab label={localization.get("org_form_forminfo")} />
					<Tab label={localization.get("org_form_schema")} />
					<Tab label={localization.get("org_form_prepopulate")} style={{ display: currentLevel === "user" ? undefined : "none" }} />
					{externals  && <Tab label={localization.get("org_workflow_externals")} />}
				</Tabs>
				{tabValue === 0 && (
					<div>
						<FormControl fullWidth={true} style={styles.formControlStyle}>
							<InputLabel style={styles.inputlabel}>{localization.get("org_form_icon")}</InputLabel>
							<Select fullWidth error={formError.icon} inputProps={{"aria-label": localization.get("org_form_icon")}} onChange={par(updateDropdown, component, "icon")} value={currentIcon}>
								{iconsList}
							</Select>
						</FormControl>
						<TextField fullWidth defaultValue={currentEmrId} onChange={par(updateEmrId, component)} placeholder={localization.get("org_form_emrId")} label={localization.get("org_form_emrId")} inputProps={{"aria-label": localization.get("org_form_emrId")}} style={styles.input} InputProps={styles.inputProp} />
						<FormControl fullWidth={true} style={styles.formControlStyle}>
							<InputLabel style={styles.inputlabel}>{localization.get("org_form_formtype")}</InputLabel>
							<Select fullWidth error={formError.formType} inputProps={{"aria-label": localization.get("org_form_formtype")}} onChange={par(updateDropdown, component, "formType")} value={currentFormType}>
								<MenuItem key={"single"} value={"single"}>{formTypes.single}</MenuItem>
								<MenuItem key={"multi"} value={"multi"}>{formTypes.multi}</MenuItem>
							</Select>
						</FormControl>
						<FormControl fullWidth={true} style={styles.formControlStyle}>
							<InputLabel style={styles.inputlabel}>{localization.get("org_form_level")}</InputLabel>
							<Select fullWidth error={formError.targetType} inputProps={{"aria-label": localization.get("org_form_level")}} onChange={par(updateDropdown, component, "targetType")} value={currentLevel}>
								<MenuItem key={"user"} value={"user"}>{levels.user}</MenuItem>
								<MenuItem key={"group"} value={"group"}>{levels.group}</MenuItem>
								<MenuItem key={"staff"} value={"staff"}>{levels.staff}</MenuItem>
							</Select>
						</FormControl>
						<TextField fullWidth error={formError.localization} defaultValue={defaultEn} onChange={par(updateLocalization, component, "en")} inputProps={{"aria-label": localization.get("org_form_englishtitle")}} placeholder={localization.get("org_form_englishtitle")} label={localization.get("org_form_englishtitle")} helperText={errorLocalization} style={styles.input} InputProps={styles.inputProp} />
						<TextField fullWidth defaultValue={defaultFr} onChange={par(updateLocalization, component, "fr")} inputProps={{"aria-label": localization.get("org_form_frenchtitle")}} placeholder={localization.get("org_form_frenchtitle")} label={localization.get("org_form_frenchtitle")} style={styles.input} InputProps={styles.inputProp} />
						<TextField fullWidth defaultValue={defaultCns} onChange={par(updateLocalization, component, "cn_s")} inputProps={{"aria-label": localization.get("org_form_cnstitle")}} placeholder={localization.get("org_form_cnstitle")} label={localization.get("org_form_cnstitle")} style={styles.input} InputProps={styles.inputProp} />
						<TextField fullWidth defaultValue={defaultCnt} onChange={par(updateLocalization, component, "cn_t")} inputProps={{"aria-label": localization.get("org_form_cnttitle")}} placeholder={localization.get("org_form_cnttitle")} label={localization.get("org_form_cnttitle")} style={styles.input} InputProps={styles.inputProp} />
						<TextField fullWidth defaultValue={defaultEs} onChange={par(updateLocalization, component, "es")} inputProps={{"aria-label": localization.get("org_form_estitle")}} placeholder={localization.get("org_form_estitle")} label={localization.get("org_form_estitle")} style={styles.input} InputProps={styles.inputProp} />
						<TextField fullWidth defaultValue={defaultPt} onChange={par(updateLocalization, component, "pt")} inputProps={{"aria-label": localization.get("org_form_pttitle")}} placeholder={localization.get("org_form_pttitle")} label={localization.get("org_form_pttitle")} style={styles.input} InputProps={styles.inputProp} />
						<TextField fullWidth defaultValue={defaultTa} onChange={par(updateLocalization, component, "ta")} inputProps={{"aria-label": localization.get("org_form_tatitle")}} placeholder={localization.get("org_form_tatitle")} label={localization.get("org_form_tatitle")} style={styles.input} InputProps={styles.inputProp} />
						<FormControl style={styles.formControlStyle}>
							<FormControlLabel
								checked={currentLockOnSubmit}
								value={"lockOnSubmit"}
								control={<Checkbox inputProps={{"aria-label": localization.get("org_form_lockonsubmit") }} ></Checkbox>}
								label={localization.get("org_form_lockonsubmit")}
								labelPlacement="end"
								onChange={par(updateCheckbox, component, "lockOnSubmit")}
							/>
						</FormControl>
					</div>
				)}
				{tabValue === 1 && (
					<TextField fullWidth multiline aria-label={localization.get("org_form_schema")} inputProps={{"aria-label": localization.get("org_form_schema"), "role": "tab"}} role={"tablist"} error={schemaError} defaultValue={defaultSchema} helperText={errorText} rows={19} rowsMax={19} onChange={par(updateSchema, component)} placeholder={localization.get("org_form_schema")} InputProps={styles.inputProp} />
				)}
				{tabValue === 2 && (
					<Prepopulate forms={forms} component={component} onChange={par(updatePrepopulate, component)}></Prepopulate>
				)}
				{tabValue === 3 && externals && renderExternals(component)}
			</Dialog>
		</div>
	);
}



function updateEmrId(component, event) {
	event.persist();
	var value = event.target.value;
	var updating = component.state.updating;
	var update = updating || component.state.creating;
	update.emrId = value;

	if (updating)
		updateUpdatingState(component, update);
	else
		updateCreatingState(component, update);
}

function showAddForm(component) {
	component.setState({
		creating: {},
		schemaError: false,
		tabValue: 0,
		formError: {},
		prepopulateError: {}
	});
	component.state.userMetrics.trackEvent("org-forms: open add form popup");
}

function cancelAdding(component) {
	component.setState({
		creating: false
	});
	component.state.userMetrics.trackEvent("org-forms: close add form popup");
}

function renderForm(component, currentPerson, remove, form) {

	var person = currentPerson.get("personal");
	var language = person.language;
	var localization = form.localization;
	var name = localization[language] || localization[Object.keys(localization)[0]];
	var formId = form._id;
	var localizationLabel = component.state.localization;
	var background_colour = "ae-plain";
	var viewing = component.state.viewing;
	if (viewing && formId === viewing._id) background_colour = "ae-hover-color";

	var editButton = (
		<div>
			<IconButton classes={iconButtonClasses} aria-label= {localizationLabel.get("edit_button")}  title= {localizationLabel.get("edit_button")} role="button" color="primary" className="fa fa-pencil" onClick={par(startUpdating, component, form)} />
			<IconButton classes={iconButtonClasses} aria-label= {localizationLabel.get("remove_button")} title={localizationLabel.get("remove_button")} role="button" color="primary" className="fa fa-times-circle" onClick={par(remove, form)} />
			{downloadFile(component, currentPerson, form)}
		</div>
	);

	var type = form.targetType;
	var icon = (type === "user") ? (
		<FontIcon className="fa fa-user" />
	) : (type === "group") ? (
		<FontIcon className="fa fa-sitemap" />
	) : (
		<FontIcon className="fa fa-cog" />
	);
	var avatar = <Avatar>{icon}</Avatar>;

	return (
		<ListItem classes={listItemClasses} key={formId} className={background_colour} onClick={par(set_viewing, component, form)} >
			{avatar}
			<ListItemText primary={name} />
			{editButton}
		</ListItem>
	);
}

function set_viewing(component, form) {
	component.setState({
		viewing: form
	});
}

function handleTabValueChanged(component, event, value) {
	component.setState({
		tabValue: value
	});
	const eventText = [
		"org-forms: navigate to form info tab in edit form popup",
		"org-forms: navigate to schema tab in edit form popup",
		"org-forms: navigate to prepopulate tab in edit form popup",
		"org-forms: navigate to externals tab in edit form popup",
	];
	component.state.userMetrics.trackEvent(eventText[value], {
		form: component.state.viewing._id,
	});
}

function showFormRemoval(component, form) {
	component.setState({
		toRemove: form
	});
	component.state.userMetrics.trackEvent("org-forms: open remove form popup", {
		form: form._id,
	});
}

function confirmRemove(component) {
	var id = component.state.toRemove;
	component.state.removeForm(id);
	component.setState({
		toRemove: null
	});
	component.state.userMetrics.trackEvent("org-forms: remove form");
}

function hideRemove(component) {
	component.setState({
		toRemove: null
	});
	component.state.userMetrics.trackEvent("org-forms: close remove form popup");
}

function startUpdating(component, form) {

	var updating = xtend({}, form);

	var schema = updating.schema;
	if (typeof schema !== "string")
		updating.schemaText = JSON.stringify(schema, null, 4);

	updating.prepopulate = (updating.prepopulate || []).map((entry) => { return { ...entry, formMapText: JSON.stringify(entry.formMap) }; });
	component.setState({
		updating: updating,
		schemaError: false,
		formError: {},
		prepopulateError: {}
	});

	component.state.userMetrics.trackEvent("org-forms: open edit form popup", {
		form: form._id,
	});
}

function cancelUpdating(component) {
	component.setState({
		updating: false
	});
	component.state.userMetrics.trackEvent("org-forms: close edit form popup");
}

function updateDropdown(component, type, e) {
	e.persist();
	var payload = e.target.value;
	var updating = component.state.updating;
	var update = updating || component.state.creating;
	update[type] = payload;

	if (updating)
		updateUpdatingState(component, update);
	else
		updateCreatingState(component, update);
}

function updateCheckbox(component, type, e) {
	e.persist();
	var payload = e.target.checked;
	var updating = component.state.updating;
	var update = updating || component.state.creating;
	update[type] = payload;

	if (updating)
		updateUpdatingState(component, update);
	else
		updateCreatingState(component, update);
}

function updatePrepopulate(component, payload) {
	var updating = component.state.updating;
	var update = updating || component.state.creating;
	update["prepopulate"] = payload;

	if (updating)
		updateUpdatingState(component, update);
	else
		updateCreatingState(component, update);
}

function submitForm(component) {
	var updating = component.state.updating;
	var formInState = component.state.creating || updating;
	var form = clone(formInState);
	var submit = updating ? component.state.updateForm : component.state.addForm;
	var hide = updating ? cancelUpdating : cancelAdding;

	var formError = {};
	var localization = form.localization || {};

	if (!form.icon) formError.icon = true;
	if (!form.formType) formError.formType = true;
	if (!form.targetType) formError.targetType = true;
	if (Object.keys(localization).length <= 0) formError.localization = true;

	component.setState({
		formError: formError
	});

	var prepopulateError = {};
	if (form.prepopulate) {
		for (let entry of form.prepopulate) {
			try {
				entry.formMap = JSON.parse(entry.formMapText);
				delete entry.formMapText;
			} catch (err) {
				prepopulateError[entry.formId] = true;
			}
		}
	}
	component.setState({
		prepopulateError: prepopulateError
	});
	try {
		form.schema = JSON.parse(form.schemaText);
		var icon = form.icon;
		var formType = form.formType;
		var targetType = form.targetType;
		if (icon && formType && targetType && Object.keys(localization).length > 0 && Object.keys(prepopulateError).length == 0) {
			submit(form);
			hide(component);
		}
	} catch (err) {
		component.setState({
			schemaError: true
		});
	}

	component.state.userMetrics.trackEvent("org-forms: submit form");
}

function updateLocalization(component, type, e) {
	e.persist();
	var text = e.target.value;
	var updating = component.state.updating;
	var update = component.state.creating || updating;
	var localization = {};
	localization[type] = text;
	update.localization = xtend(update.localization, localization);

	if (updating)
		updateUpdatingState(component, update);
	else
		updateCreatingState(component, update);
}
function updateSchema(component, e) {
	e.persist();
	var text = e.target.value;
	var updating = component.state.updating;
	var update = component.state.creating || updating;
	update.schemaText = text;

	if (updating)
		updateUpdatingState(component, update);
	else
		updateCreatingState(component, update);
}

function updateCreatingState(component, update) {
	component.setState({
		creating: update
	});
}

function updateUpdatingState(component, update) {
	var updating = component.state.updating;
	component.setState({
		updating: xtend(updating, update)
	});
}

function downloadFile(component, currentPerson, form) {
	var localization = form.localization;
	var schemaText = JSON.stringify(form.schema, null, 4);
	var language = currentPerson.get("personal").language;
	var filename = (localization[language] || localization[Object.keys(localization)[0]]).replace(/ /g, "_") + ".schema.json";
	var jsonBlob = new Blob([schemaText], {
		type: "application/json"
	});
	var localizationLabel = component.state.localization;
	var url = window.URL.createObjectURL(jsonBlob);

	return (
		<IconButton
			classes={iconButtonClasses}
			aria-label={localizationLabel.get("download_file_button")}
			title={localizationLabel.get("download_file_button")}
			role="button"
			color="primary"
			className="fa fa-download"
			href={url}
			download={filename}
			onClick={() => component.state.userMetrics.trackEvent("org-forms: download form", {
				form: form._id,
			})}
		/>
	);
}

function noop() {}

function getDialogTitle(currentPerson, form) {
	var person = currentPerson.get("personal");
	var language = person.language;
	var localization = form.localization;
	var name = localization[language] || localization[Object.keys(localization)[0]];
	return name;
}

function renderExternals(component){
	var state = component.state;
	var currentPerson = state.currentPerson.get("personal");
	var language = currentPerson.language;
	var localization = state.localization;
	var updating = state.updating;
	var creating = state.creating;
	var externals = findExternals(component);

	var currentExternals = (updating ? updating.externals : creating.externals) || {};

	var options = state.options;
	var optionsMenu = options.map(option => {
		var schema = option.schema || {};
		var schemaLocalization = schema.localization || {};
		var displayText = schemaLocalization[language] || schemaLocalization[Object.keys(schemaLocalization)[0]] || localization.get("group_options_unknown");
		return (<MenuItem key={option._id} value={option._id}>{displayText}</MenuItem>);
	});

	var externalForm = externals ? Object.keys(externals).map(function(key){
		var menu = null;
		if(externals[key] === "option")
			menu = optionsMenu;
		else return null;

		return (
		<FormControl fullWidth>
			<InputLabel>{key}</InputLabel>
			<Select fullWidth value={currentExternals[key] || ""} onClick={par(updateExternal, component, key)}>
				{menu}
			</Select>
		</FormControl>
		);
	}) : null;

	return externalForm;
}

function findExternals(component){
	var form = component.state.updating || component.state.creating;
	var schemaText = form.schemaText;
	var schema = null;

	try {
		schema = JSON.parse(schemaText);
		if(schema.externals)
			return schema.externals;
		return null;
	} catch (err) {
		return null;
	}
}

function updateExternal(component, key, e){
	e.persist();
	var payload = e.target.value;
	var updating = component.state.updating;
	var update = component.state.creating || updating;
	if(!update.externals)
		update.externals = {};
	update.externals[key] = payload;

	if(updating)
		updateUpdatingState(component, update);
	else
		updateCreatingState(component, update);

	component.state.userMetrics.trackEvent("org-forms: edit externals for form", {
		form: update._id,
	});
}