


import Store from "./store";

export default ManagerDefaultPermissions;

function ManagerDefaultPermissions(api, events, config){
	var store = new Store();

	api.org.manage.defaultPermissions.get()
		.then(setPermissions)
		.then(trackChanges);

	return store;

	function setPermissions(permissions){
		store.set("default", permissions);
	}

	function trackChanges(){
		var orgGroup = config.orgGroup;
		if(!orgGroup) return;
		events.on("mqtt:orggroup/" + orgGroup + "/default/manager/permissions/change", handle_change);
	}

	function handle_change(data){
		var permissions = data.permissions;
		setPermissions(permissions);
	}
}