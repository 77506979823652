import React, { useState, useEffect } from "react";
import Login from "./login";
import Landing from "./shared/Landing";
import config from "../configs/config.json";

import { handleAuthLocalStorage } from "./App.library";
import { useUserMetrics } from "@aetonix/user-metrics";

const App = () => {
	const [initComplete, setInitComplete] = useState(false);
	const [authed, setAuthed] = useState(false);
	const { userMetrics } = useUserMetrics();

	useEffect(() => handleAuthLocalStorage(config, setAuthed, setInitComplete), [config]);

	if (!initComplete) {
		return null;
	}

	return <>{authed ? <Landing /> : <Login setAuthed={setAuthed} userMetrics={userMetrics} />}</>;
};

export default App;
