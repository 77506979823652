/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

import par from "par";

import defineUI from "../shared/define";
import render from "./render.jsx";
import timezone from "moment-timezone";
import xtend from "xtend";
import debounce from "debounce";
import Utility from "../shared/Utility";
import PersonStore from "../stores/user_data_store";
import LocalizationStore from "../stores/localization_store";
import CurrentPersonInfoStore from "../stores/current_person_info_store";
import CareplanNotificationStore from "../stores/careplan_notice_store";
import ConnectionStore from "../stores/connection_store";
import UsersStore from "../stores/users_overview_store";
import UserData from "../stores/user_data_store";
import GroupCompliantStore from "../stores/org_group_compliancy_store";
import UserCompliantStore from "../stores/user_compliant_store";
import GroupCustomProperties from "../stores/group_custom_properties";
import OrgCustomIndicators from "../stores/org_custom_indicators_store";
import ManagerGroups from "../stores/group_manager_groups_store";
import makeStoreFactory from "../shared/stores";

const { getLocalStorageArray } = Utility;

const makeStores = makeStoreFactory({
	people: PersonStore,
	currentPerson: CurrentPersonInfoStore,
	localization: LocalizationStore,
	careplanChangeList: CareplanNotificationStore,
	connection: ConnectionStore,
	users: UsersStore,
	userData: UserData,
	compliancy: GroupCompliantStore,
	userCompliancy: UserCompliantStore,
	customProperties: GroupCustomProperties,
	customIndicators: OrgCustomIndicators,
	managerGroups: ManagerGroups,
});

var COLUMNS = [
	"WorkflowStatus",
	"OxygenSaturation",
	"HeartRate",
	"BodyTemperature",
	"BloodSugar",
	"Weight",
	"BloodPressure",
	"BloodPressurePulserate",
	"Steps",
	"Notes"
];

function make_ui(api, events, config, userMetrics, props) {
	var storeStore = makeStores(api, events, config);

	window.stores = storeStore.store;

	userMetrics.trackEvent("page view", {
		"page": "group-users-overview",
		"category": "monitor",
		"permission level": "staff",
	});

	return defineUI({
		getInitialState: getInitialState,
		componentDidMount: listen_for_changes,
		render: render,
		componentWillUnmount: removeListeners
	});

	function removeListeners() {
		events.off();
	}

	function listen_for_changes() {
		storeStore.listen(par(handle_change, this));
	}

	function handle_change(component, stores) {
		const userLocalStorageKey = storeStore.stores.currentPerson.get("personal")._id + "_group_users_overview_";
		localStorage.setItem("userLocalStorageKey", userLocalStorageKey);

		var hideHealthProperties = COLUMNS.reduce((acc, property) => {
			acc[userLocalStorageKey + "hide" + property] = getColumnHide(property, userLocalStorageKey);
			return acc;
		}, {});

		const customProperties = stores.customProperties.get("customProperties") || [];

		var hideCustomProperties = customProperties.reduce((acc, property) => {
			acc[userLocalStorageKey + "hide" + property.key] = getColumnHide(property.key, userLocalStorageKey);
			return acc;
		}, {});

		const customIndicators = stores.customIndicators?.all?.() ?? [];
		const customIndicatorColumns = customIndicators.map(indicator => ({
			key: indicator._id,
			indicator
		}));

		const hideCustomIndicators = customIndicatorColumns.reduce((acc, property) => {
			acc[userLocalStorageKey + "hide" + property.key] = getColumnHide(property.key, userLocalStorageKey);
			return acc;
		}, {});

		var previousColumns = localStorage.getItem(userLocalStorageKey + "columns");
		var extendedColumns = previousColumns ? JSON.parse(previousColumns) : component.state.columns || COLUMNS;
		var extendedUnorderedColumns = component.state.unorderedColumns || [];

		var findColumnFunction = property => col => col.key === property.key || col === property;

		customProperties.forEach(property => {
			if (extendedColumns.findIndex(findColumnFunction(property)) === -1) extendedColumns.push(property);
			if (extendedUnorderedColumns.findIndex(findColumnFunction(property)) === -1)
				extendedUnorderedColumns.push(property);
		});

		customIndicatorColumns.forEach(property => {
			if (extendedColumns.findIndex(findColumnFunction(property)) === -1) extendedColumns.push(property);
			if (extendedUnorderedColumns.findIndex(findColumnFunction(property)) === -1)
				extendedUnorderedColumns.push(property);
		});

		const stateFilter = {};
		stateFilter[`${userLocalStorageKey}stateFilter`] = getLocalStorageArray(`${userLocalStorageKey}stateFilter`);

		var listType = {};
		listType[userLocalStorageKey + "listType"] = localStorage.getItem(userLocalStorageKey + "listType") || "all";

		var rowsPerPage = {};
		rowsPerPage[[userLocalStorageKey + "rowsPerPage"]] =
			Number(localStorage.getItem(component.state.userLocalStorageKey + "rowsPerPage")) || 10;
		var currentRowsPerPage = Number(localStorage.getItem(component.state.userLocalStorageKey + "rowsPerPage")) || 10;


		const listManageGroups = {};
		listManageGroups[`${userLocalStorageKey}manageGroups`] = getLocalStorageArray(`${userLocalStorageKey}manageGroups`);

		let update = {
			person: stores.persons,
			currentPerson: stores.currentPerson,
			localization: stores.localization,
			careplanChangeList: stores.careplanChangeList,
			connection: stores.connection,
			users: stores.users.get("users"),
			userData: stores.userData,
			userFunc: stores.users,
			people: stores.people,
			totals: stores.users.getCount(),
			userCompliancy: stores.userCompliancy,
			compliancy: stores.compliancy,
			customProperties: stores.customProperties.get("customProperties"),
			columns: extendedColumns,
			setCheck,
			userLocalStorageKey: userLocalStorageKey,
			unorderedColumns: extendedUnorderedColumns,
			customIndicators: storeStore.stores.customIndicators,
			manageGroups: stores.managerGroups.all(),
			goToUser: goToUser,
			loadMore: more,
			customIndicatorColumns,
			userMetrics: userMetrics,
			currentRowsPerPage: currentRowsPerPage
		};

		update = xtend(update, hideHealthProperties);
		update = xtend(update, hideCustomProperties);
		update = xtend(update, hideCustomIndicators);
		update = xtend(update, stateFilter);
		update = xtend(update, listType);
		update = xtend(update, rowsPerPage);
		update = xtend(update, listManageGroups);

		component.setState(update);
	}

	function getInitialState() {
		return {
			person: storeStore.stores.persons,
			currentPerson: storeStore.stores.currentPerson,
			localization: storeStore.stores.localization,
			careplanChangeList: storeStore.stores.careplanChangeList,
			connection: storeStore.stores.connection,
			users: storeStore.stores.users.get("users"),
			userFunc: storeStore.stores.users,
			people: storeStore.stores.people,
			totals: {
				total: 0,
				red: 0,
				yellow: 0,
				green: 0
			},
			compliancy: storeStore.stores.compliancy,
			userCompliancy: storeStore.stores.userCompliancy,
			setCheck,
			followedUp: [],
			customProperties: [],
			columns: COLUMNS,
			userLocalStorageKey: "",
			unorderedColumns: COLUMNS,
			goToUser: goToUser,
			userData: storeStore.stores.userData,
			submitCompliancy: submitCompliancy,
			filename: "",
			exporting: false,
			compliant: false,
			bloodpressureSchedule: null,
			bloodoxygenSchedule: null,
			stepsSchedule: null,
			bodytemperatureSchedule: null,
			weightSchedule: null,
			bloodsugarSchedule: null,
			exportPage: 0,
			columnsLocked: true,
			userSearchExist: debounce(par(userSearchExist, storeStore.stores.users), 200),
			userSortExist: debounce(par(userSortExist, storeStore.stores.users), 200),
			customIndicators: storeStore.stores.customIndicators,
			manageGroups: [],
			loadMore: more,
			userMetrics: userMetrics,
			rowsPerPage: 10,
		};
	}

	function more(page, size) {
		return storeStore.stores.users.fetch(page, size);
	}

	function goToUser(userId) {
		props.history.push("/user-overview/" + userId);
	}

	function getColumnHide(key, userLocalStorageKey) {
		return localStorage.getItem(userLocalStorageKey + "hide" + key) === "true";
	}

	function submitCompliancy(schedules) {
		return api.org.compliant.update(schedules, timezone.tz.guess());
	}

	function userSearchExist(users, query) {
		return users.search(query);
	}

	function setCheck(id, value) {
		return api.org.users.patientCheckState(id, value);
	}

	function userSortExist(users, sortQuery, sortServer) {
		return users.sort(sortQuery, sortServer);
	}
}

export default make_ui;
