import { useLocalStorageValue } from "@aetonix/hooks";
import { Checkbox, FormControl, Grid, Input, InputLabel, ListItemText, MenuItem, Select } from "@material-ui/core";
import React from "react";
import { useLocalize } from "../../shared/contexts/LocalizeContext";
import { useLocalizeFrom } from "../../shared/contexts/LocalizeFromContext";
import { useUserMetrics } from "@aetonix/user-metrics";

export function FormsFilter({ currentPersonId, allForms, formsById, onClose }) {
	const { userMetrics } = useUserMetrics();
	const [selectedForms, setSelectedForms] = useLocalStorageValue(`${currentPersonId}_group_forms_filter`, []);
	const localizeFrom = useLocalizeFrom();
	const localize = useLocalize();

	const handleChange = event => {
		setSelectedForms(event.target.value);
		userMetrics.trackEvent("user-overview: filter forms", {
			forms: event.target.value
		});
	};

	function renderValue(selected) {
		if (selected.length === 0) return localize("groupforms.filter_forms");
		return selected.map(id => localizeFrom(formsById[id]?.localization, ".") || "...").join(", ");
	}

	return (
		<Grid item xs={12} sm={6}>
			<FormControl fullWidth>
				<InputLabel id="forms-submissions-filter-label">{localize("groupforms.filter_forms")}</InputLabel>
				<Select
					labelId="forms-submissions-filter-label"
					id="forms-submissions-filter"
					multiple
					value={selectedForms}
					onChange={handleChange}
					onClose={() => onClose(selectedForms)}
					input={<Input />}
					renderValue={renderValue}
				>
					{allForms.map(form => (
						<MenuItem key={form._id} value={form._id}>
							<Checkbox checked={selectedForms.includes(form._id)} />
							<ListItemText primary={localizeFrom(form.localization, ".") || ""} />
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</Grid>
	);
}
