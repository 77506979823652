/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, May 3, 2018
 * For information or permission request, email info@aetonixsystems.com
 */

import par from "par";

import defineUI from "../shared/define";
import render from "./render.jsx";
import PersonStore from "../stores/person_store";
import OrgStore from "../stores/org_store";
import LocalizationStore from "../stores/localization_store";
import CurrentPersonInfoStore from "../stores/current_person_info_store";
import CareplanNotificationStore from "../stores/careplan_notice_store";
import ConnectionStore from "../stores/connection_store";
import AdminOrgCounts from "../stores/admin_org_count";
import AdminGroupCounts from "../stores/admin_group_count";
import AdminGroupStore from "../stores/admin_org_group_store";
import makeStoreFactory from "../shared/stores";

const makeStores = makeStoreFactory({
	orgs: OrgStore,
	people: PersonStore,
	currentPerson: CurrentPersonInfoStore,
	localization: LocalizationStore,
	careplanChangeList: CareplanNotificationStore,
	connection: ConnectionStore,
	groups: AdminGroupStore,
	groupCount: AdminGroupCounts,
	orgCount: AdminOrgCounts
});

export default make_ui;

function make_ui(api, events, config, userMetrics) {
	var storeStore = makeStores(api, events, config);

	window.stores = storeStore.store;

	userMetrics.trackEvent("page view", {
		"page": "admin-stats",
		"category": "admin",
		"permission level": "admin",
	});

	return defineUI({
		getInitialState: getInitialState,
		componentDidMount: listen_for_changes,
		render: render,
		componentWillUnmount: removeListeners
	});

	function removeListeners() {
		events.off();
	}

	function listen_for_changes() {
		storeStore.listen(par(handle_change, this));
	}

	function handle_change(component, stores) {
		component.setState({
			orgs: stores.orgs.all(),
			people: stores.people,
			currentPerson: stores.currentPerson,
			localization: stores.localization,
			careplanChangeList: stores.careplanChangeList,
			connection: stores.connection,
			groupCount: stores.groupCount,
			groups: stores.groups,
			orgCount: stores.orgCount
		});
	}

	function getInitialState() {
		return {
			orgs: [],
			loadMore: par(loadMore, storeStore.stores.orgs),
			people: storeStore.stores.people,
			currentPerson: storeStore.stores.currentPerson,
			localization: storeStore.stores.localization,
			searchOrg: par(searchOrg, storeStore.stores.orgs),
			careplanChangeList: storeStore.stores.careplanChangeList,
			connection: storeStore.stores.connection,
			groupCount: storeStore.stores.groupCount,
			groups: storeStore.stores.groups,
			orgCount: storeStore.stores.orgCount,
			sorting: "name",
			sortingDirection: "asc",
			fullScreen: false,
			userMetrics: userMetrics,
		};
	}

	function searchOrg(orgs, event) {
		event.persist();
		var query = event.target.value;
		return orgs.search(query);
	}

	function loadMore(orgs) {
		return orgs.more();
	}
}
