import React from "react";
import TableBody from "@material-ui/1.5.1/TableBody";
import TableCell from "@material-ui/1.5.1/TableCell";
import TableRow from "@material-ui/1.5.1/TableRow";
import Tooltip from "@material-ui/1.5.1/Tooltip";
import Typography from "@material-ui/1.5.1/Typography";

import { tableCellTypes } from "../../constants";
import { useLocalize } from "@aetonix/hooks";

const styles = {
	textNormal: {
		fontSize: "14px",
	},
	textSmall: {
		fontSize: "12px",
	}
};

export function HealthTableBody({ headers, rows, onClickRow }) {
	return (
		<TableBody>
			{rows.map((row, index) => (
				<Row key={index} headers={headers} row={row} index={index} onClickRow={onClickRow} />
			))}
		</TableBody>
	);
}

function Row({ headers, row, index, onClickRow }) {
	return (
		<TableRow key={index} >
			{headers.map((header, headerIndex) => <RowColumn row={row} column={header} index={headerIndex} onClick={onClickRow} />)}
		</TableRow>
	);
}


function RowColumn({ row, column, index, onClick }) {
	const { localize } = useLocalize();

	const data = column?.getData(row);
	const status = column?.getStatus && column?.getStatus(row);

	const className = status !== undefined && !row.invalid ? tableCellTypes[status] : "";

	const parseStatus = () => {
		if(!row.invalid){
			if (status === 2) {
				return localize("group_user_overview_high");
			}
			if (status === 1) {
				return localize("group_user_overview_low");
			}
			if (status === 0) {
				return localize("group_user_overview_normal");
			}
		}
	};

	return (
		<Tooltip title={localize("health.noteinvalid")}>
			<TableCell
				classes={className}
				className="pointer"
				key={index}
				style={{ ...styles.textNormal, textDecoration: row.invalid ? "line-through" : "" }}
				onClick={() => onClick(row)}
			>
				{data}
				<Typography style={styles.textSmall}>{parseStatus()}</Typography>
			</TableCell>
		</Tooltip>
	);
}