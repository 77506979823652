import { getNameForLanguage } from "../../org-custom-indicators/library";
import { indicatorUnits } from "../Util/IndicatorUnits";

function getHeaderPropertyText(header) {
	return header ? header.key || header : "";
}

/**
 * Returns the unit string for a header.
 * @param {Object} options - options object.
 * @param {*} options.header - The header String or Object.
 * @param {Boolean} options.isStaticHeader - Indicates if the header is a static header or not.
 * @param {String} options.measureUnits - The units system to use, metric or imperial.
 * @param {String} options.glucometerUnits - The units system to use for blood suger, mmol or mg.
 * @param {String} options.weightUnits - The units system to use for weight, kg or lbs.
 * @param {String} options.temperatureUnits - The units system to use for body temperature, C or F.
 * @returns The unit string for the header.
 */
function getHeaderUnitString(options) {
	const {
		header,
		measureUnits,
		glucometerUnits,
		weightUnits,
		temperatureUnits
	} = options;
	if (header.localization) return null;

	if (header.indicator) {
		return header.indicator?.unit;
	}

	const propertyText = getHeaderPropertyText(header)?.toLowerCase();
	if(propertyText === "bloodsugar")
		return indicatorUnits[propertyText]?.[glucometerUnits];
	if(propertyText === "weight")
		return indicatorUnits[propertyText]?.[weightUnits];
	if(propertyText === "bodytemperature")
		return indicatorUnits[propertyText]?.[temperatureUnits];
	return indicatorUnits[propertyText]?.[measureUnits];
}

/**
 * Gets the localized string for a header.
 * @param {Object} options - options object.
 * @param {*} options.header - The header String or Object.
 * @param {String} options.language - The language to localize the text to.
 * @param {String} options.localizationPrefix - The localization prefix for the table.
 * @returns the localized string for a header.
 */
function getHeaderLocalization(options) {
	const {
		header,
		localization,
		language,
		localizationPrefix,
	} = options;

	if (header.localization) {
		return header.localization[language];
	}

	if (header.localizationKey) {
		return localization.get(header.localizationKey);
	}

	const prefix = header.localizationPrefix ?? localizationPrefix;
	if (header.indicator) {
		localization.get(`${prefix}${propertyText}`);
		return getNameForLanguage(header.indicator, language);
	}

	const propertyText = getHeaderPropertyText(header);
	return localization.get(`${prefix}${propertyText}`);
}

export {
	getHeaderUnitString,
	getHeaderLocalization,
	getHeaderPropertyText,
};
