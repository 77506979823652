/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

import par from "par";

import defineUI from "../shared/define";
import render from "./render.jsx";
import PersonStore from "../stores/person_store";
import LocalizationStore from "../stores/localization_store";
import CurrentPersonInfoStore from "../stores/current_person_info_store";
import CareplanNotificationStore from "../stores/careplan_notice_store";
import ConnectionStore from "../stores/connection_store";
import AdminGroupsStore from "../stores/admin_group_store";
import AdminPromoCodeStore from "../stores/admin_promo_codes_store";
import AdminOrgStore from "../stores/admin_org_store";
import AdminOrgGroupStore from "../stores/admin_org_group_store";
import makeStoreFactory from "../shared/stores";

const makeStores = makeStoreFactory({
	people: PersonStore,
	currentPerson: CurrentPersonInfoStore,
	localization: LocalizationStore,
	careplanChangeList: CareplanNotificationStore,
	connection: ConnectionStore,
	codes: AdminPromoCodeStore,
	orgs: AdminOrgStore,
	groups: AdminGroupsStore,
	orgGroups: AdminOrgGroupStore
});

function make_ui(api, events, config, userMetrics) {
	var storeStore = makeStores(api, events, config);

	window.stores = storeStore.store;

	userMetrics.trackEvent("page view", {
		"page": "admin-promo-codes",
		"category": "admin",
		"permission level": "admin",
	});

	return defineUI({
		getInitialState: getInitialState,
		componentDidMount: listen_for_changes,
		render: render,
		componentWillUnmount: removeListeners
	});

	function removeListeners() {
		events.off();
	}

	function listen_for_changes() {
		storeStore.listen(par(handle_change, this));
	}

	function handle_change(component, stores) {
		component.setState({
			people: stores.people,
			currentPerson: stores.currentPerson,
			localization: stores.localization,
			careplanChangeList: stores.careplanChangeList,
			connection: stores.connection,
			codes: stores.codes,
			orgs: stores.orgs,
			groups: stores.groups,
			orgGroups: stores.orgGroups
		});
	}

	function getInitialState() {
		return {
			people: storeStore.stores.people,
			currentPerson: storeStore.stores.currentPerson,
			localization: storeStore.stores.localization,
			careplanChangeList: storeStore.stores.careplanChangeList,
			connection: storeStore.stores.connection,
			codes: storeStore.stores.codes,
			orgs: storeStore.stores.orgs,
			groups: storeStore.stores.groups,
			orgGroups: storeStore.stores.orgGroups,
			creating: false,
			code: "",
			org: "",
			group: "",
			add: addPromoCode,
			remove: removePromoCode,
			config: config,
			userMetrics: userMetrics
		};
	}

	function addPromoCode(code, group){
		return api.admin.code.create(code, group);
	}

	function removePromoCode(code){
		return api.admin.code.remove(code);
	}
}

export default make_ui;
