import compose from "./compose";
import { FormatDateProvider } from "./FormatDateContext";
import { LocalizeProvider } from "./LocalizeContext";
import { LocalizeFromProvider } from "./LocalizeFromContext";

export default compose(
  FormatDateProvider,
  LocalizeProvider,
  LocalizeFromProvider
);
