/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

export default {
	timestamp: timestamp,
	dategroup: dategroup,
	format_name: format_name,
	getAsArray,
	getLocalStorageArray,
};

function timestamp(date_string) {
	var date = new Date(date_string);
	var year = date.getFullYear();
	var month = date.getMonth() + 1;
	var day = date.getDate();

	var hours = date.getHours();
	var minutes = date.getMinutes();
	var seconds = date.getSeconds();

	// Check for NaN, if year or hours is NaN, so is everything else
	if (isNaN(year) || isNaN(hours)) {
		return "";
	}

	var result = "";

	result += year + "-" + fill(month) + "-" + fill(day) + " ";

	result += fill(hours) + ":" + fill(minutes) + ":" + fill(seconds);

	return result;
}

function dategroup(date_string) {
	var date = new Date(date_string);
	var year = date.getFullYear();
	var month = date.getMonth() + 1;
	var day = date.getDate();

	// Check for NaN, if year or hours is NaN, so is everything else
	if (isNaN(year)) {
		return "";
	}

	var result = "";

	result += year + "-" + fill(month) + "-" + fill(day) + " ";

	return result;
}

// Numbers should be two digits, less than '10' are preceded by '0'
function fill(number) {
	if (number.toString().length < 2) {
		return "0" + number;
	}
	return number;
}

// Format a name as a first and last name string
function format_name(person) {
	return person.lname + ", " + person.fname;
}

function getAsArray(value) {
	if (value === null || value === undefined) return [];
	if (Array.isArray(value)) return value;
	return [value];
}

// Gets the array associated with the given key from local storage.
function getLocalStorageArray(key) {
	const value = localStorage.getItem(key);
	if (value === null) return [];
	try {
		return JSON.parse(value);
	} catch (err) {
		return value.split(",");
	}
}
