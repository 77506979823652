import React from "react";
import { ResourceProvider } from "./contexts/ResourceContext";
import MuiThemeProvider from "@material-ui/1.5.1/styles/MuiThemeProvider";
import AetonixTheme_mui_151 from "./AetonixTheme_mui_1.5.1.js";
import { useUserMetrics } from "@aetonix/user-metrics";

import {
	useResources,
} from "@aetonix/hooks";

export default function makeAppComponent(make_ui) {
	const AppComponent = (props) => {
		const { api, events, config } = useResources();
		if (!api || !events || !config) return null;
		const { userMetrics } = useUserMetrics();
		const ComponentToRender = make_ui(
			api,
			events.createTracker(),
			config,
			userMetrics,
			props,
			events,
		);
		if (props.init) props.init();

		if (!ComponentToRender) return null;

		return (
			<MuiThemeProvider theme={AetonixTheme_mui_151}>
				<ResourceProvider>
					<ComponentToRender />
				</ResourceProvider>
			</MuiThemeProvider>
		);
	};

	return AppComponent;
};
