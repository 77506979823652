
/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */



import Store from "./store";
import map from "fj-map";

export default FormsHistory;

function FormsHistory(api) {
	var store = new Store();

	var loading = null;
	var user = [];
	var forms = [];
	var editor = [];
	var page = 0;
	var start = "";
	var end = "";

	store.sorted = sorted;
	store.more = more;
	store.searchUser = searchUser;
	store.searchEditor = searchEditor;
	store.searchForms = searchForms;
	store.searchDate = searchDate;
	store.clearSearch = clearSearch;

	return store;

	function reset() {
		page = 0;
		store.clear();
		api.org.forms.listGroupSubmissions(user, forms, page, start, end, editor)
			.then(handle_create)
			.then(next_page);
	}

	function more() {
		if (loading) return loading;
		loading = api.org.forms.listGroupSubmissions(user, forms, page, start, end, editor)
			.then(handle_more)
			.then(next_page)
			.then(finish);
	}

	function finish() {
		loading = null;
	}

	function sorted() {
		return store.all()
			.sort(by_time);
	}

	// The 'clear' is to make the page clear if a search returns no results
	function handle_create(result) {
		if (result.length) {
			map(add_submission, result);
		} else {
			store.clear();
		}
	}
	// handles case where 'more' returns nothing because there are no more records
	function handle_more(result) {
		if (result.length) {
			map(add_submission, result);
		}
	}

	function add_submission(form) {
		var id = form._id;
		store.set(id, form);
	}

	function next_page() {
		page += 1;
	}

	function searchUser(filterquery) {
		user = user.concat(filterquery);
		return reset();
	}

	function searchEditor(filterquery) {
		editor = editor.concat(filterquery);
		return reset();
	}

	function searchForms(filterquery) {
		forms = filterquery;
		return reset();
	}

	function searchDate(startdate, enddate) {
		start = startdate;
		end = enddate;
		if (!!start && !!end) {
			return reset();
		}
		return;
	}

	function clearSearch(name) {
		if (name === "user") user = [];
		if (name === "forms") forms = [];
		if (name === "editor") editor = [];
		if (name === "time") {
			start = "";
			end = "";
		}
		return reset();
	}
}

function by_time(prev, next) {
	var prev_time = (new Date(prev.updated_at)).getTime();
	var next_time = (new Date(next.updated_at)).getTime();

	var time_delta = next_time - prev_time;
	return time_delta;
}