import actions from "@aetonix/audit-logger/src/Audit/actions";

let exportObj = {};

for (const key in actions) {
	const action = actions[key];

	const exportedActions = Object.keys(action).reduce((accum, actionKey) => {
		const actionValue = action[actionKey];
		if (typeof actionValue === "object") {
			const innerActions = Object.keys(actionValue).reduce((innerAccum, innerActionKey) => {
				const innerActionValue = actionValue[innerActionKey];
				return [...innerAccum, innerActionValue];
			}, []);

			return [...accum, ...innerActions];
		} else {
			return [...accum, actionValue];
		}
	}, []);

	exportObj[key] = exportedActions;
}

export default exportObj;
