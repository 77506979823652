import React from "react";

import Table from "@material-ui/1.5.1/Table";
import { HealthTableHeader } from "../shared/HealthTableHeader";
import { HealthTableBody } from "../shared/HealthTableBody";
import { useMeasureUnits } from "@aetonix/hooks";
import { healthHeaders } from "./headers";
import Utility from "../../../shared/Utility";
import { useUserMetrics } from "@aetonix/user-metrics";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	messageContainer: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		height: "200px",
		fontSize: "18px",
		color: theme.palette.text.primary,
	},
}));

export function HealthIndicatorTabPanel({ component, indicator }) {
	const { weightUnits, temperatureUnits, glucometerUnits } = useMeasureUnits();
	const { userMetrics } = useUserMetrics();
	const state = component.state;
	const localization = state.localization;
	const classes = useStyles();
	const rowData = state[`user${capitalizeText(indicator)}History`].sorted();
	const headerMap = healthHeaders(localization);

	let headers = headerMap[indicator];

	if(indicator === "weight")
		headers = headerMap[indicator][weightUnits];
	else if (indicator === "bloodSugar")
		headers = headerMap[indicator][glucometerUnits];
	else if (indicator === "bodyTemperature")
		headers = headerMap[indicator][temperatureUnits];

	headers = headers.concat({
			name: "time",
			content: localization.get("user_steps_history_time"),
			getData: (row) => Utility.timestamp(row?.created_at)
	});

	const onClickRow = (row) => {
		component.setState({
			invalidRecord: row,
			recordInvalid: row?.invalid,
			recordNote: row?.notes
		});
		userMetrics.trackEvent("user-overview: click row in health indicator tab panel");
	};

	if (rowData.length === 0) {
		return <div className={classes.messageContainer}>{localization.get("user_steps_missing_history")}</div>;
	}

	return (
		<Table>
			<HealthTableHeader headers={headers} />
			<HealthTableBody component={component} headers={headers} rows={rowData} onClickRow={onClickRow} />
		</Table>
	);
}

function capitalizeText(text) {
	return text.charAt(0).toUpperCase() + text.slice(1);
}

