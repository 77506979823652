/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

import par from "par";

import React from "react";
import config from "../../configs/config.json";
import isEmpty from "is-empty";
import Table from "@material-ui/1.5.1/Table";
import TableHeader from "@material-ui/1.5.1/TableHead";
import TableHeaderColumn from "@material-ui/1.5.1/TableCell";
import TableBody from "@material-ui/1.5.1/TableBody";
import TableRow from "@material-ui/1.5.1/TableRow";
import TableRowColumn from "@material-ui/1.5.1/TableCell";
import Header from "../shared/Header.js";
import Button from "@material-ui/1.5.1/Button";
import Dialog from "../shared/Dialog";
import TextField from "@material-ui/1.5.1/TextField";
import MenuItem from "@material-ui/1.5.1/MenuItem";
import Chip from "@material-ui/1.5.1/Chip";
import Avatar from "@material-ui/1.5.1/Avatar";
import FontIcon from "@material-ui/1.5.1/Icon";
import DropDownMenu from "@material-ui/1.5.1/Select";

/**
 * User Interface elements Aetonix
 */
import Utility from "../shared/Utility.js";

import Scroll from "../shared/InfiniScroll.jsx";
import SearchTable from "../shared/SearchTable.jsx";
import DatePicker from "../shared/DatePicker";

import  AetonixTheme_mui_1_5_1 from "../shared/AetonixTheme_mui_1.5.1";
const Colors = AetonixTheme_mui_1_5_1.palette;

var styles = {
	icon: {
		fontSize: 20,
		color: Colors.canvas
	},
	avatar: {
		backgroundColor: Colors.primary.dark
	},
	back: {
		backgroundColor: Colors.primary.light,
		color: Colors.primary.main
	},
	backPrimary: {
		backgroundColor: "#E0E0E0"
	},
	avatarPrimary: {
		backgroundColor: "#BCBCBC"
	},
	inputProp: {
		style: {
			color: Colors.primary.main,
		}
	},
	marginText: {
		margin: 8
	}
};

export default render;

function render() {
	var component = this;
	var state = component.state;
	var localization = state.localization;
	var careplanNoticeList = state.careplanChangeList.all();
	var careplanChanges = !!careplanNoticeList.length;
	var offline = state.connection.get("offline");
	var currentPerson = state.currentPerson;
	var people = state.people;
	var reminders = state.reminders;
	var history = state.history;
	var rowData = generate_data(people, reminders, history, localization);

	var startDate = state.startDate;
	var endDate = state.endDate;
	var report = state.report;
	var fileName = state.fileName;
	var loadMore = state.more;
	// Search related variables
	var choose_date = localization.get("choose_date");
	var filterUsers = state.filterUsers;
	var filterStatus = state.filterStatus;
	var searchUsers = state.searchUsers;
	var closeUserSearch = state.closeUserSearch;
	var shownFilter = state.shownFilter;
	var respondFilter = state.respondFilter;
	var userFilter = state.userFilter;
	var statusFilter = state.statusFilter;
	var startShown = state.startShown;
	var endShown = state.endShown;
	var startRespond = state.startRespond;
	var endRespond = state.endRespond;
	var status = state.status;
	var titleKey = "groupreminder_history_title";

	var headers = [
		{
			name: "shown",
			content: localization.get("reminders_reminderShow")
		},
		{
			name: "responded",
			content: localization.get("reminders_timeofresponse")
		},
		{
			name: "name",
			content: localization.get("reminders_header_name")
		},
		{
			name: "text",
			content: localization.get("reminders_header_content")
		},
		{
			name: "state",
			content: localization.get("reminders_header_state")
		},
		{
			name: "type",
			content: localization.get("reminders_responseType")
		}
	];

	var report_actions = [csvReport(component, localization), (
		<Button key={"buttoncancel"} onClick={par(hideReport, component)} >
			{localization.get("report_cancel")}
		</Button >
	)
	];

	var shown_actions = [
		<Button key={"buttonapply"}
			onClick={par(handle_date, component, "shown")}>
			{localization.get("search_apply")}
		</Button >,
		<Button key={"buttoncancel"}
			onClick={par(hideSearch, component, "shown")}>
			{localization.get("form_submissions_history_cancel")}
		</Button >
	];

	var respond_actions = [
		<Button key={"buttonapply"}
			onClick={par(handle_date, component, "respond")}>
			{localization.get("search_apply")}
		</Button >,
		<Button key={"buttoncancel"}
			onClick={par(hideSearch, component, "respond")}>
			{localization.get("form_submissions_history_cancel")}
		</Button >
	];

	var status_actions = [
		<Button key={"buttondone"}
			onClick={par(hideSearch, component, "status")}>
			{localization.get("search_done")}
		</Button >
	];

	if (dataHasChanged(history, state.previousHistory)) {
		component.state.userMetrics.trackEvent("reminders-history: viewed reminders history", {
			history: history.map((r) => r._id),
		});
		component.setState({
			previousHistory: history,
		});
	}

	return (
		<div className="flex-vertical flex-1">

			<Header
				careplanChanges={careplanChanges}
				offline={offline}
				currentPerson={currentPerson}
				localization={localization}
				titleKey={titleKey}
			/>
			<div className="flex-horizontal ae-empty">
				{renderReportChip(component)}
				{renderShownFilterChip(component)}
				{renderRespondFilterChip(component)}
				{renderUserFilterChip(component)}
				{renderStatusFilterChip(component)}
			</div>

			<Scroll loadMore={loadMore}>
				<Table>
					{renderHeader(headers)}
					{renderBody(component, rowData, headers)}
				</Table>
			</Scroll>

			<Dialog actions={report_actions} open={report} title={localization.get("groupreminder_report_title")}>
				<div>
					<DatePicker invalid={choose_date}
						labelStart={localization.get("report_start")}
						labelEnd={localization.get("report_end")}
						startDate={startDate}
						endDate={endDate}
						updateStart={par(updateChanged, component, "startDate")}
						updateEnd={par(updateChanged, component, "endDate")}
					/>
					<TextField fullWidth placeholder={localization.get("report_filename")} inputProps={{"aria-label": localization.get("report_filename")}}  value={fileName} onChange={par(updateFileNameChanged, component)} InputProps={styles.inputProp} />
				</div>
			</Dialog>

			<Dialog actions={shown_actions} open={shownFilter} title={localization.get("search_dateShown")}>
				<DatePicker invalid={choose_date}
					labelStart={localization.get("report_start")}
					labelEnd={localization.get("report_end")}
					startDate={startShown}
					endDate={endShown}
					updateStart={par(updateChanged, component, "startShown")}
					updateEnd={par(updateChanged, component, "endShown")}
				/>
			</Dialog>

			<Dialog actions={respond_actions} open={respondFilter} title={localization.get("search_dateRespond")}>
				<DatePicker invalid={choose_date}
					labelStart={localization.get("report_start")}
					labelEnd={localization.get("report_end")}
					startDate={startRespond}
					endDate={endRespond}
					updateStart={par(updateChanged, component, "startRespond")}
					updateEnd={par(updateChanged, component, "endRespond")}
				/>
			</Dialog>

			<SearchTable action={par(filterUsers, component)} ref="searchDialog" search={searchUsers}
				localization={localization}
				people={people}
				showing={userFilter}
				onClose={closeUserSearch}
				title={localization.get("search_panel_title_patient")} />

			<Dialog actions={status_actions} open={statusFilter} title={localization.get("search_reminder_stateTitle")}>
				<div className="flex-horizontal ae-state-choice">
					<div style={styles.marginText}>{localization.get("filter_state_reminders")}</div>
					<DropDownMenu multiple value={handle_state(status)} inputProps={{"aria-label": localization.get("filter_state_reminders")}}  maxHeight={300} onChange={par(filterStatus, component)}>
						<MenuItem value="😄"> 😄 </MenuItem>
						<MenuItem value="😦"> 😦 </MenuItem>
						<MenuItem value="accepted">{localization.get("search_accepted")}</MenuItem>
						<MenuItem value="denied">{localization.get("groupreminder_refused")}</MenuItem>
						<MenuItem value="Yes">{localization.get("ae_careplan_yes")}</MenuItem>
						<MenuItem value="No">{localization.get("ae_careplan_no")}</MenuItem>
						<MenuItem value="cleared">{localization.get("groupreminder_cleared")}</MenuItem>
						<MenuItem value="0">{0}</MenuItem>
						<MenuItem value="1">{1}</MenuItem>
						<MenuItem value="2">{2}</MenuItem>
						<MenuItem value="3">{3}</MenuItem>
						<MenuItem value="4">{4}</MenuItem>
						<MenuItem value="5">{5}</MenuItem>
						<MenuItem value="6">{6}</MenuItem>
						<MenuItem value="7">{7}</MenuItem>
						<MenuItem value="8">{8}</MenuItem>
						<MenuItem value="9">{9}</MenuItem>
						<MenuItem value="10">{10}</MenuItem>
					</DropDownMenu>
				</div>
			</Dialog>

		</div>

	);
}

function dataHasChanged(history, previousHistory) {
	history = history || [];
	previousHistory = previousHistory || [];
	if (history.length !== previousHistory.length) {
		return true;
	}
	for (let i = 0; i < history.length; i++) {
		if (history[i]._id !== previousHistory[i]._id) {
			return true;
		}
	}
	return false;
}

function handle_state(data) {
	return data.map(function (item) {
		var status = parseInt(item, 10);
		if (isNaN(status)) return item;
		return status.toString();
	});
}

function renderReportChip(component) {
	var state = component.state;
	return (
		<Chip className="ae-icon ae-fonticon"
			style={styles.back}
			onClick={par(showReport, component)}
			avatar={<Avatar style={styles.avatar} >
				<FontIcon className="fa fa-floppy-o fa-2x" style={styles.icon} />
			</Avatar>}
			label={state.localization.get("groupcall_report_button")}
		/>
	);
}

function renderShownFilterChip(component) {
	var state = component.state;
	var deleteOn = noop();
	var styleavatar = styles.avatarPrimary;
	var styleback = styles.backPrimary;

	if (!isEmpty(state.startShown) && !isEmpty(state.endShown)) {
		deleteOn = par(clearSearch, component, "shown");
		styleavatar = styles.avatar;
		styleback = styles.back;
	}
	return (
		<Chip className="ae-icon ae-fonticon"
			onDelete={deleteOn}
			style={styleback}
			onClick={par(showSearch, component, "shown")}
			avatar={<Avatar style={styleavatar}>
				<FontIcon className="fa fa-filter fa-2x" style={styles.icon} />
			</Avatar>}
			label={renderFilterLabel(component, "shown")}
		/>);
}

function renderRespondFilterChip(component) {
	var state = component.state;
	var deleteOn = noop();

	var styleavatar = styles.avatarPrimary;
	var styleback = styles.backPrimary;

	if (!isEmpty(state.startRespond) && !isEmpty(state.endRespond)) {
		deleteOn = par(clearSearch, component, "respond");
		styleavatar = styles.avatar;
		styleback = styles.back;
	}
	return (
		<Chip className="ae-icon ae-fonticon"
			onDelete={deleteOn}
			style={styleback}
			onClick={par(showSearch, component, "respond")}
			avatar={<Avatar style={styleavatar}>
				<FontIcon className="fa fa-filter fa-2x" style={styles.icon} />
			</Avatar>}
			label={renderFilterLabel(component, "respond")}
		/>);
}

function renderUserFilterChip(component) {
	var state = component.state;
	var deleteOn = noop();
	var styleavatar = styles.avatarPrimary;
	var styleback = styles.backPrimary;

	if (state.users.length) {
		deleteOn = par(clearSearch, component, "users");
		styleavatar = styles.avatar;
		styleback = styles.back;
	}
	return (
		<Chip className="ae-icon ae-fonticon"
			onDelete={deleteOn}
			style={styleback}
			onClick={par(showSearch, component, "users")}
			avatar={<Avatar style={styleavatar}>
				<FontIcon className="fa fa-filter fa-2x" style={styles.icon} />
			</Avatar>}
			label={renderFilterLabel(component, "users")}
		/>
	);
}

function renderStatusFilterChip(component) {
	var state = component.state;
	var deleteOn = noop();
	var styleavatar = styles.avatarPrimary;
	var styleback = styles.backPrimary;

	if (state.status.length) {
		deleteOn = par(clearSearch, component, "status");
		styleavatar = styles.avatar;
		styleback = styles.back;
	}
	return (
		<Chip className="ae-icon ae-fonticon"
			onDelete={deleteOn}
			style={styleback}
			onClick={par(showSearch, component, "status")}
			avatar={<Avatar style={styleavatar}>
				<FontIcon className="fa fa-filter fa-2x" style={styles.icon} />
			</Avatar>}
			label={renderFilterLabel(component, "status")}
		/>);
}

function renderHeader(headers) {
	return (
		<TableHeader>
			<TableRow >
				{headers.map(renderHeaderColumn)}
			</TableRow>
		</TableHeader>
	);
}

function renderHeaderColumn(column) {
	return (
		<TableHeaderColumn key={column.name}
			className="flex-spread">
			{column.content}
		</TableHeaderColumn>
	);
}

function renderBody(component, rows, headers) {
	return (
		<TableBody>
			{rows.map(par(renderRow, headers))}
		</TableBody>
	);
}

function renderRow(headers, row, index) {
	return (
		<TableRow key={index} >
			{headers.map(par(renderRowColumn, row))}
		</TableRow>
	);
}

function renderRowColumn(row, column, index) {
	return (
		<TableRowColumn key={index}>
			{row[column.name]}
		</TableRowColumn>
	);
}

/**
 * This function generates the data that populates the table.
 * NOTE: at the moment, time pending is produced but not included in the table.
 * If the state is 'pending', the record is not displayed.
 * @param  {[type]} people    [description]
 * @param  {[type]} reminders [description]
 * @param  {[type]} history   [description]
 * @return {[type]}           [description]
 */
function generate_data(people, reminders, history, localization) {
	return history.map(function (item) {

		var name = Utility.format_name(people.get(item.from));
		var shown = timestamp_text(item.history.shown);


		var state = get_type(item, localization);
		var text = reminders.get(item.reminder).content;
		var type = reminders.get(item.reminder).responseType;
		var responded = is_pending(localization, state)
			? "----"
			: timestamp_text(item.updated_at);

		if (!type) {
			type = localization.get("groupreminder_information");
		} else {
			var key = "groupreminder_" + type;
			type = localization.get(key);
		}
		if (text === "Loading..")
			text = localization.get("groupreminder_empty");

		return {
			shown: shown,
			responded: responded,
			name: name,
			state: state,
			text: text,
			type: type,
		};
	}).sort(by_shown);
}

/**
 * Sorts by time in descending order
 * @param  {[type]} x [description]
 * @param  {[type]} y [description]
 * @return {[type]}   [description]
 */
function by_shown(x, y) {
	var X = x.shown;
	var Y = y.shown;
	if (X < Y) return 1;
	if (X > Y) return -1;
	return 0;
}

function is_pending(localization, state) {
	return (state === localization.get("groupreminder_pending"));
}

function get_type(item, localization) {

	if (item.history.cleared)
		return localization.get("groupreminder_cleared");

	if (item.response || item.response === 0) {
		if (item.response.accepted)
			return localization.get("groupreminder_accepted");
		if (item.response.denied)
			return localization.get("groupreminder_refused");
		if(item.response === "Yes")
			return localization.get("search_exist_yes");
		if(item.response === "No")
			return localization.get("search_exist_no");
		return item.response;
	}

	return localization.get("groupreminder_pending");
}

function timestamp_text(item) {
	return Utility.timestamp(item);
}

function csvReport(component, localization) {
	var comp = component.state;
	var start = comp.startDate;
	var end = adjust_date(comp.endDate);
	var filename = comp.fileName;

	if (!filename) filename = localization.get("default_file_reminders");

	var language = comp.currentPerson.get("personal").language;
	var timezone = new Date().getTimezoneOffset();

	const users = component.state.users || [];

	var url = config.host +
		"/v2/org/reminders/history/report?token=" +
		config.token +
		"&start=" +
		encodeURIComponent(start) +
		"&end=" +
		encodeURIComponent(end) +
		"&filename=" +
		encodeURIComponent(filename) +
		"&language=" +
		encodeURIComponent(language) +
		"&timezone=" +
		encodeURIComponent(timezone);
	users.length && (url += "&filteredUsers=" + encodeURIComponent(users));

	return (
		<a
			key={url}
			className="ae-report-save"
			href={url}
			download={filename}
			onClick={() =>
				component.state.userMetrics.trackEvent("reminders-history: download report", {
					start,
					end,
					filename,
					language,
					timezone,
					users
				})
			}
		>
			{localization.get("report_save")}
		</a>
	);
}

function hideReport(component) {
	component.state.userMetrics.trackEvent("reminders-history: close download report popup");
	return (
		component.setState({
			report: false
		})
	);
}

function showReport(component) {
	component.state.userMetrics.trackEvent("reminders-history: open download report popup");
	return (component.setState({
		report: true
	}));
}

function updateFileNameChanged(component, event) {
	event.persist();
	var value = event.target.value;
	component.setState({
		fileName: value
	});
}

function updateChanged(component, name, event, date) {
	var value = date;
	if (event) {
		value = event._d;
	}
	var update = {};
	update[name] = value;
	component.setState(update);
}

/**
 * A function to reset a date by 1 day ahead.  Used to adjust the end date
 * on the date selector function because the default value is midnight of the
 * day selected instead of say, 23:59 of the day chosen or midnight of the next
 * day
 * @param  {String} date Date string
 * @return {String}      Date string
 */
function adjust_date(date) {
	if (isEmpty(date)) return "";
	var end = new Date(date);
	end.setDate(end.getDate() + 1);
	return end;
}

function showSearch(component, name) {
	if (name === "staff") component.setState({
		staffFilter: true
	});
	if (name === "users") component.setState({
		userFilter: true
	});
	if (name === "status") component.setState({
		statusFilter: true
	});
	if (name === "shown") component.setState({
		shownFilter: true
	});
	if (name === "respond") component.setState({
		respondFilter: true
	});
	const eventText = {
		status: "reminders-history: open filter by state popup",
		respond: "reminders-history: open filter by date responded popup",
		shown: "reminders-history: open filter by date shown popup",
		users: "reminders-history: open filter by user popup"
	}[name] || "reminders-history: open filter popup";
	component.state.userMetrics.trackEvent(eventText);
}

function hideSearch(component, name) {
	if (name === "staff") component.setState({
		staffFilter: false
	});
	if (name === "users") component.setState({
		userFilter: false
	});
	if (name === "status") component.setState({
		statusFilter: false
	});
	if (name === "shown") component.setState({
		shownFilter: false
	});
	if (name === "respond") component.setState({
		respondFilter: false
	});
	const eventText = {
		status: "reminders-history: close filter by state popup",
		respond: "reminders-history: close filter by date responded popup",
		shown: "reminders-history: close filter by date shown popup",
		users: "reminders-history: close filter by user popup"
	}[name] || "reminders-history: close filter popup";
	component.state.userMetrics.trackEvent(eventText);
}

function clearSearch(component, name) {
	var clear = component.state.filterClear;
	const eventText = {
		status: "reminders-history: clear state filter",
		respond: "reminders-history: clear date responded filter",
		shown: "reminders-history: clear date shown filter",
		users: "reminders-history: clear user filter"
	}[name] || "reminders-history: clear filter";
	component.state.userMetrics.trackEvent(eventText);

	if (name === "users") {
		component.setState({
			users: []
		});
		return clear("users");
	}
	if (name === "status") {
		component.setState({
			status: []
		});
		return clear("status");
	}
	if (name === "shown") {
		component.setState({
			startShown: "",
			endShown: new Date()
		});
		return clear("shown");
	}
	if (name === "respond") {
		component.setState({
			startRespond: "",
			endRespond: new Date()
		});
		return clear("respond");
	}
}

function renderFilterLabel(component, name) {
	var state = component.state;
	var localization = state.localization;
	if (name === "shown") {
		let start = state.startShown;
		let end = state.endShown;
		if (isEmpty(start) || isEmpty(end)) {
			const label = localization.get("search_dateShown");
			return label;
		} else {
			start = shortDate(start);
			end = shortDate(end);
			const label = start + " - " + end;
			return label;
		}
	}
	if (name === "respond") {
		let start = state.startRespond;
		let end = state.endRespond;
		if (isEmpty(start) || isEmpty(end)) {
			return localization.get("search_dateRespond");
		} else {
			start = shortDate(start);
			end = shortDate(end);
			const label = start + " - " + end;
			return label;
		}
	}
	if (name === "users") {
		const length = state.users.length;
		if (!length) return localization.get("search_patient");
		const label = localization.get("search_patient") + " (" + length + ")";
		return label;
	}
	if (name === "status") {
		const length = state.status.length;
		if (!length) return localization.get("search_state");
		const label = localization.get("search_state") + " (" + length + ")";
		return label;
	}
}

function shortDate(date) {
	var day = date.getDate();
	var month = date.getMonth() + 1;
	var year = date.getFullYear();
	return (day + "/" + month + "/" + year);

}

function handle_date(component, value) {
	var state = component.state;
	hideSearch(component, value);
	component.state.userMetrics.trackEvent("reminders-history: filter by date", {
		"start": state.startShown,
		"end": state.endShown
	});
	if (value === "shown") {
		const start = state.startShown;
		const end = adjust_date(state.endShown);
		if (!validate_dates(component, start, end, "shown")) return;
		return state.filterDateShown(start, end);
	}
	if (value === "respond") {
		const start = state.startRespond;
		const end = adjust_date(state.endRespond);
		if (!validate_dates(component, start, end, "respond")) return;
		return state.filterDateRespond(start, end);
	}
}

function validate_dates(component, start, end, value) {
	if (isNaN(Date.parse(start)) || isNaN(Date.parse(end))) {
		clearSearch(component, value);
		return false;
	} else {
		return true;
	}
}

function noop() { }
