/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */



import Store from "./store";
import localize from "ae-localizer";
import en from "../localization/localization-en.json";
import fr from "../localization/localization-fr.json";
import cn_t from "../localization/localization-cn-t.json";
import cn_s from "../localization/localization-cn-s.json";
import es from "../localization/localization-es.json";
import pt from "../localization/localization-pt.json";
import ta from "../localization/localization-ta.json";

var localization = {
	en,
	fr,
	cn_t,
	cn_s,
	es,
	pt,
	ta
};

export default LocalizationStore;

function LocalizationStore(api, events) {

	var store = new Store();

	store.localization = localization;

	store.setAll(localization.en);

	store.get = function(data) {
		if(!data) return;
		var parse = data.split(".");
		var translation = parse.reduce(function (acc, curr) {
			if (!curr) return acc;
			return acc[curr] ? acc[curr] : curr;
		}, this._data);
		return translation;
	};

	store.localize = function(data, args) {
		if(!data) return data;
		return localize(this._data, data, args);
	};

	refresh();

	events.on("mqtt:$/settings", refresh);

	return store;

	function refresh() {
		api.people.data().then(change_language);
	}

	function change_language(data) {
		var language = data.language;
		store.setAll(localization[language]);
	}
}
