/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */



/**
 * This store derives its data from an api call to /org/reminders.  It contains
 * data concerning the reminders history of members of a group.
 */

import Store from "./store";

import xtend from "xtend";

export default UserTablePages;

function UserTablePages(api) {
	var store = new Store();
	var tracking = [];

	var currentTableId = null;
	var currentId = null;

	var start = null;
	var end = null;

	store.raw_get = store.get;
	store.get = get;
	store.filter = filter;

	return store;

	function filter(startDate, endDate){
		start = startDate;
		end = endDate;
		updateTable();
	}

	function updateTable(){
		return getTableData(currentId, currentTableId);
	}

	function get(id, tableId){
		currentTableId = tableId;
		currentId = id;
		var trackId = `${id}_${tableId}`;
		if(tracking.indexOf(trackId) !== -1){
			var tableData = store.raw_get(id) || {};
			return tableData[tableId] || {};
		}

		getTableData(id, tableId);
		return 0;
	}

	function getTableData(id, tableId){
		var currentPerson = store.raw_get(id) || {};
		var timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

		return api.org.tables.pages(tableId, id, start, end, timezone)
			.then(results => {
				var update = {};
				update[tableId] = results;
				if(tracking.indexOf(`${id}+${tableId}`) === -1)
					tracking.push(`${id}_${tableId}`);
				store.set(id, xtend(currentPerson, update));
			});
	}
}