
/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */




import Store from "./store";
import par from "par";
import xtend from "xtend";

export default UserStatusStore;

function UserStatusStore(api, events, config) {
	var store = new Store();

	store.raw_get = store.get;
	store.get = get;

	var tracking = [];

	return store;

	function get(id) {
		var existing = store.raw_get(id);
		if (existing) return existing;

		if (!isTracking(id))
			track(id);

		return [];
	}

	function isTracking(id) {
		return tracking.indexOf(id) !== -1;
	}

	function track(id) {
		tracking.push(id);
		api.org.users.status(id).then(par(setUserStatus, id));
		var orgGroup = config.orgGroup;
		if(!orgGroup) return;
		events.on("mqtt:orggroup/" + orgGroup + "/u/" + id + "/workflow/current_status/updated", par(updateStatus, id));
		events.on("mqtt:orggroup/" + orgGroup + "/u/" + id + "/workflow/status/updated", par(updateStatus, id));
		events.on("mqtt:orggroup/" + orgGroup + "/u/" + id + "/observations/status/updated", par(updateObservationStatus, id));
	}

	function setUserStatus(id, data){
		store.set(id, data);
	}

	function updateObservationStatus(id, data){
		var update = {
			indicator_status: data.status
		};
		updateStatus(id, update);
	}

	function updateStatus(id, data){
		var existing = store.get(id);
		if(!existing) return;

		store.set(id, xtend(existing, data));
	}
}