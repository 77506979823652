/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import "./shared.css";
import "../../static/css/font-awesome.min.css";

import React from "react";
import CreateReactClass from "create-react-class";
import ReactPropTypes from "prop-types";
import AetonixTheme from "./AetonixTheme.js";

import { Header } from "@aetonix/web-ui";

export default CreateReactClass({
	displayName: "Header",
	childContextTypes: {
		muiTheme: ReactPropTypes.object
	},
	getChildContext: getChildContext,
	render: render
});

function getChildContext() {
	return {
		muiTheme: AetonixTheme
	};
}

function render() {
	return (
		<Header {...this.props} />
	);
}