/* © 2023 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import par from "par";

import xtend from "xtend";
import defineUI from "../shared/define";
import render from "./render.jsx";
import PersonStore from "../stores/person_store";
import UserStore from "../stores/user_active_store";
import LocalizationStore from "../stores/localization_store";
import CurrentPersonStore from "../stores/current_person_info_store";
import CareplanNotificationStore from "../stores/careplan_notice_store";
import ConnectionStore from "../stores/connection_store";
import UserThresholdStore from "../stores/user_threshold_store";
import GroupThresholds from "../stores/org_group_threshold_store";
import ManagerGroupsStore from "../stores/group_manager_groups_store";

import storeFactory from "../shared/stores";

var makeStores = storeFactory({
	people: PersonStore,
	currentPerson: CurrentPersonStore,
	localization: LocalizationStore,
	users: UserStore,
	managergroups: ManagerGroupsStore,
	careplanChangeList: CareplanNotificationStore,
	connection: ConnectionStore,
	userThresholds: UserThresholdStore,
	groupThresholds: GroupThresholds
});

function make_ui(api, events, config, userMetrics){
	var storeStore = makeStores(api, events, config);

	window.stores = storeStore.store;

	userMetrics.trackEvent("page view", {
		"page": "user-thresholds",
		"category": "user",
		"permission level": "staff",
	});

	return defineUI({
		render: render,
		getInitialState: getInitialState,
		componentDidMount: listenForChanges,
		componentWillUnmount: removeListeners
	});

	function removeListeners(){
		events.off();
	}

	function listenForChanges(){
		storeStore.listen(par(handle_change, this));
	}

	function handle_change(component, stores){
		component.setState({
			people: stores.people,
			userResources: stores.userResources,
			resources: stores.resources,
			users: stores.users.all(),
			managerGroups: stores.managergroups,
			showGroupManagers: {},
			currentPerson: stores.currentPerson,
			localization: stores.localization,
			careplanChangeList: stores.careplanChangeList,
			connection: stores.connection,
			userThresholds: stores.userThresholds,
			atEnd: stores.users.getAtEnd(),
			groupThresholds: storeStore.stores.groupThresholds.get("thresholds")
		});
	}

	function getInitialState(){
		return {
			users: [],
			managerGroups: storeStore.stores.managergroups,
			people: storeStore.stores.people,
			currentPerson: storeStore.stores.currentPerson,
			userResources: storeStore.stores.userResources,
			resources: storeStore.stores.resources,
			localization: storeStore.stores.localization,
			careplanChangeList: storeStore.stores.careplanChangeList,
			connection: storeStore.stores.connection,
			viewing: null,
			search: doSearch,
			showGroupManagers: true,
			searchGroup: doSearchGroup,
			loadMore: par(loadMore, storeStore.stores.users),
			searchExist: par(searchExist, storeStore.stores.users),
			userThresholds: storeStore.stores.userThresholds,
			groupThresholds: storeStore.stores.groupThresholds,
			filteredManagers: null,
			filteredManagerGroups: null,
			atEnd: false,
			currentIndicator: null,
			adding: false,
			editing: null,
			editingIndex: null,
			patientSelect: true,
			bloodOxygenORA: null,
			bloodOxygenORB: null,
			bloodOxygenOYA: null,
			bloodOxygenOYB: null,
			bloodOxygenHRA: null,
			bloodOxygenHRB: null,
			bloodOxygenHYA: null,
			bloodOxygenHYB: null,
			bloodPressureSRA: null,
			bloodPressureSRB: null,
			bloodPressureSYA: null,
			bloodPressureSYB: null,
			bloodPressureDRA: null,
			bloodPressureDRB: null,
			bloodPressureDYA: null,
			bloodPressureDYB: null,
			bloodPressurePRA: null,
			bloodPressurePRB: null,
			bloodPressurePYA: null,
			bloodPressurePYB: null,
			bloodSugarRA: null,
			bloodSugarRB: null,
			bloodSugarYA: null,
			bloodSugarYB: null,
			bodyTempRA: null,
			bodyTempRB: null,
			bodyTempYA: null,
			bodyTempYB: null,
			weightRA: null,
			weightRB: null,
			weightYA: null,
			weightYB: null,
			stepRA: null,
			stepRB: null,
			stepYA: null,
			stepYB: null,
			create: create,
			remove: remove,
			addContact: addContact,
			removeContact: removeContact,
			userMetrics: userMetrics,
			addContactGroup: addContactGroup,
			removeContactGroup: removeContactGroup,
		};
	}

	function doSearch(query) {
		var filter = {
			type: {
				exclude: ["atouchaway", "user:mobile", "simplified"]
			}
		};

		return api.people.search(query, filter);
	}

	function doSearchGroup() {
		return api.org.managergroups.list();
	}


	function searchExist(users, event){
		event.persist();
		var query = event.target.value;
		return users.search(query);
	}

	function loadMore(user){
		userMetrics.trackEvent("user-thresholds: load more");
		return user.more();
	}

	function filterIndex(index){
		return function(e, i){
			return i !== +index;
		};
	}

	function remove(thresholds, thresholdKey, index, who){
		if(index){
			var current = thresholds[thresholdKey];
			var key = Object.keys(current)[0];
			var currentKey = current[key];
			var isArray = Array.isArray(currentKey);
			if(isArray && current[key].length === 1)
				thresholds[thresholdKey] = null;
			else
				Object.keys(current).forEach((currentKeyMap) => {
					thresholds[thresholdKey][currentKeyMap] = thresholds[thresholdKey][currentKeyMap].filter(filterIndex(index));
				});
		} else {
			thresholds[thresholdKey] = null;
		}

		if(who === "group")	return api.org.thresholds.default.update(thresholds);
		else return api.org.thresholds.update(who, thresholds);
	}

	function create(thresholds, threshold, type, who){
		var newThresholds = {};
		newThresholds[type] = threshold;

		var finalThresholds = xtend(thresholds, newThresholds);

		if(who === "group") return api.org.thresholds.default.update(finalThresholds);
		else return api.org.thresholds.update(who, finalThresholds);
	}

	function addContact(who, current, contact){
		var currentContacts = (current || {}).contacts || [];

		var newContacts = currentContacts.concat(contact);

		var finalThresholds = xtend(current, {
			contacts: newContacts
		});

		userMetrics.trackEvent("user-thresholds: add mobile alert contact", {
			contact,
			who
		});

		if(who === "group") return api.org.thresholds.default.update(finalThresholds);
		else return api.org.thresholds.update(who, finalThresholds);
	}

	function addContactGroup(who, current, group){
		var currentGroups = (current || {}).groups || [];
		var currentGroupIds = currentGroups.map(gp=> gp._id);

		if(currentGroupIds.indexOf(group._id) !== -1) return;

		var newGroups = currentGroups.concat(group);

		var finalThresholds = xtend(current, {
			groups: newGroups
		});

		if(who === "group") return api.org.thresholds.default.update(finalThresholds);
		else return api.org.thresholds.update(who, finalThresholds);
	}

	function removeContact(who, current, contact){
		var currentContacts = (current || {}).contacts || [];

		var newContacts = currentContacts.filter(function(p){
			return p !== contact;
		});

		var finalThresholds = xtend(current, {
			contacts: newContacts
		});

		if(who === "group") return api.org.thresholds.default.update(finalThresholds);
		else return api.org.thresholds.update(who, finalThresholds);
	}

	function removeContactGroup(who, current, groups){
		var currentGroups = (current || {}).groups || [];

		var newGroups = currentGroups.filter(function(p){
			return p._id !== groups._id;
		});

		var finalThresholds = xtend(current, {
			groups: newGroups
		});
		if(who === "group") return api.org.thresholds.default.update(finalThresholds);
		else return api.org.thresholds.update(who, finalThresholds);
	}
}

export default make_ui;
