/* © 2023 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import par from "par";

import defineUI from "../shared/define";
import render from "./render.jsx";
import PersonStore from "../stores/person_store";
import UserStore from "../stores/user_active_store";
import UserResourceStore from "../stores/user_resources_store";
import LocalizationStore from "../stores/localization_store";
import CurrentPersonStore from "../stores/current_person_info_store";
import CareplanNotificationStore from "../stores/careplan_notice_store";
import ConnectionStore from "../stores/connection_store";
import ResourcesStore from "../stores/org_resources_store";
import makeStoreFactory from "../shared/stores";

const makeStores = makeStoreFactory({
	people: PersonStore,
	currentPerson: CurrentPersonStore,
	localization: LocalizationStore,
	users: UserStore,
	userResources: UserResourceStore,
	careplanChangeList: CareplanNotificationStore,
	connection: ConnectionStore,
	resources: ResourcesStore
});

function make_ui(api, events, config, userMetrics){
	var storeStore = makeStores(api, events, config);

	window.stores = storeStore.store;

	userMetrics.trackEvent("page view", {
		"page": "user-resources",
		"category": "user",
		"permission level": "staff",
	});

	return defineUI({
		render: render,
		getInitialState: getInitialState,
		componentDidMount: listenForChanges,
		componentWillUnmount: removeListeners
	});

	function removeListeners(){
		events.off();
	}

	function listenForChanges(){
		storeStore.listen(par(handle_change, this));
	}

	function handle_change(component, stores){
		component.setState({
			people: stores.people,
			userResources: stores.userResources,
			resources: stores.resources,
			users: stores.users.all(),
			currentPerson: stores.currentPerson,
			localization: stores.localization,
			careplanChangeList: stores.careplanChangeList,
			connection: stores.connection
		});
	}

	function getInitialState(){
		return {
			users: [],
			people: storeStore.stores.people,
			currentPerson: storeStore.stores.currentPerson,
			userResources: storeStore.stores.userResources,
			resources: storeStore.stores.resources,
			localization: storeStore.stores.localization,
			careplanChangeList: storeStore.stores.careplanChangeList,
			connection: storeStore.stores.connection,
			viewing: null,
			addResource: addResource,
			removeResource: removeResource,
			loadMore: par(loadMore, storeStore.stores.users),
			searchExist: par(searchExist, storeStore.stores.users),
			userMetrics: userMetrics
		};
	}

	function searchExist(users, event){
		event.persist();
		var query = event.target.value;
		return users.search(query);
	}

	function loadMore(user){
		return user.more();
	}

	function removeResource(user, resource){
		return api.org.resources.user.remove(resource, user);
	}

	function addResource(user, resource){
		return api.org.resources.user.add(resource, user);
	}
}

export default make_ui;
