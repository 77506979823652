/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";
import CreateReactClass from "create-react-class";
import ReactList from "react-list";
import Scroll from "./InfiniScroll.jsx";

export default CreateReactClass({
	displayName: "LazyList",
	render: render
});

function render() {
	var noScroll = this.props.noScroll;
	if(!this.handleRender)
		this.handleRender = handleRender.bind(this);

	var ScrollComponent = Scroll;
	if(noScroll)
		ScrollComponent = NoScroll;

	return (
		<ScrollComponent style={this.props.style} loadMore={this.props.loadMore}>
			<ReactList itemRenderer={this.handleRender} length={this.props.items.length} type="variable"/>
		</ScrollComponent>
	);
}

function NoScroll(props){
	return (
		<div style={props.style}>
			{props.children}
		</div>
	);
}

function handleRender(index) {
	var data = this.props.items[index];
	return this.props.renderItem(data);
}
