/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */
/* eslint-disable node/no-missing-require */

import React from "react";

import par from "par";
import xtend from "xtend";
import config from "../../configs/config.json";
import escape_regex from "escape-regexp";
import Header from "../shared/Header.js";
import Avatar from "@material-ui/1.5.1/Avatar";
import Button from "@material-ui/1.5.1/Button";
import Checkbox from "@material-ui/1.5.1/Checkbox";
import Dialog from "../shared/Dialog";
import FontIcon from "@material-ui/1.5.1/Icon";
import MuiDialog from "@material-ui/1.5.1/Dialog";
import IconButton from "@material-ui/1.5.1/IconButton";
import ListItem from "@material-ui/1.5.1/ListItem";
import ListItemText from "@material-ui/1.5.1/ListItemText";
import MenuItem from "@material-ui/1.5.1/MenuItem";
import Select from "@material-ui/1.5.1/Select";
import SubHeader from "@material-ui/1.5.1/ListSubheader";
import TextField from "@material-ui/1.5.1/TextField";
import Utility from "../shared/Utility";
import LazyList from "../shared/LazyList.jsx";
import HelperProvider from "../shared/contexts/HelperProvider";
import PermissionsList from "../shared/PermissionsList.js";
import { Fragment, useState } from "react";
import { PermissionsForm } from "./PermissionForm";
import { AddManagerModal } from "./AddManagerModal";
import { Tab, Tabs, DialogTitle, DialogContent, List, Divider } from "@material-ui/1.5.1";
import { default as PresetPermissionForm } from "./PresetPermissionForm";

// eslint-disable-next-line node/no-missing-require
import { useLocalize } from "../shared/contexts/LocalizeContext";

import { styled } from "@material-ui/styles";
import { PrimaryButton, ImportantButton } from "../shared/Buttons";
import { default as ListIcon } from "ae-base/List/ListIcon";
import { default as StyledIcon } from "ae-base/Base/StyledIcon";
import  AetonixTheme_mui_1_5_1 from "../shared/AetonixTheme_mui_1.5.1";
const Colors = AetonixTheme_mui_1_5_1.palette;
var SETTINGS_PERMISSIONS = PermissionsList.SettingsPermissions;

var CAREPLAN_PERMISSIONS = PermissionsList.CareplanPermissions;

// eslint-disable-next-line node/no-missing-require
import { BulkAdd, BulkRemove } from "./BulkUpdate";

var styles = {
	search: {
		color: Colors.primary.main,
		minWidth: "30%",
		padding: "8px"
	},
	input: {
		color: Colors.primary.main,
		padding: "8px"
	},
	inputProp: {
		style: {
			color: Colors.primary.main,
		}
	},
	editDefault: {
		color: Colors.primary.main
	}
};

var listItemClasses = {
	root: "ae-padding",
	gutters: "ae-padding",
	default: "ae-padding"
};

var iconButtonClasses = {
	root: "ae-inline"
};

var selectClasses = {
	root: "ae-width"
};

export default render;

function render() {
	var component = this;
	var state = component.state;

	var users = state.users;
	var localization = state.localization;
	var careplanNoticeList = state.careplanChangeList.all();
	var careplanChanges = !!careplanNoticeList.length;
	var offline = state.connection.get("offline");
	var currentPerson = state.currentPerson;
	var people = state.people;
	var permissionDialog = state.openPermissions;
	var defaultPermissionDialog = state.openDefaultPermissions;
	var sortedUsers = users.map(people.get).sort(by_name);
	var loadMore = state.loadMore;
	var searchExist = state.searchExist;
	var toRemove = state.toRemove;
	var userForms = state.userForms;
	var titleKey = "user_manage_title";

	var bulkAddOpen = state.openBulkAdd;
	var managers = [];
	var permissionPresetList = state.managerPreset;
	var userFormList = userForms.map(function (form) {
		return form._id;
	});

	var viewing = state.viewing;
	if (viewing)
		managers = state.managers.get(viewing).map(people.get).sort(by_name);

	var permissionActions = [
		<Button key={"permissions_submit"} color="primary" onClick={par(handle_changePermissions, component, userFormList)} >{localization.get("permissions_submit")}</Button>,
		<Button key={"permissions_done"} color="primary" onClick={par(handle_closePermissions, component)}>{localization.get("permissions_done")}</Button>
	];

	var remove_actions = [(
		<Button key={"user_ok"} onClick={par(confirmRemove, component, viewing, toRemove)}>{localization.get("users_ok")}</Button >
	), (
		<Button key={"users_cancel"} onClick={par(hideRemove, component)}>{localization.get("users_cancel")}</Button >
	)];
	var renderUserListItem = par(renderUser, component);
	var sortedManager = managers.sort(by_name);

	const bulkUpdateTabValue = state.bulkUpdateTabValue;

	return (
		<HelperProvider>
			<div className="flex-vertical flex-1">
				<Header
					careplanChanges={careplanChanges}
					offline={offline}
					currentPerson={currentPerson}
					localization={localization}
					titleKey={titleKey}
				>
					<IconButton aria-label={localization.get("bulk_add_button")} title={localization.get("bulk_add_button")} role="button" onClick={par(openBulkAdd, component)}>
						<FontIcon className="fa fa-trello" color="secondary" />
						{/* {BulkAddIcon} */}
					</IconButton >
					<IconButton aria-label={localization.get("permissions_settings_button")} title={localization.get("permissions_settings_button")} role="button" onClick={par(openDefaultPermissions, component)}>
						<FontIcon className="fa fa-cog" color="secondary" />
					</IconButton >
				</Header>
				<div className="flex-horizontal flex-1 ae-scrollable">
					<div className="flex-vertical flex-1 ae-left-margin ">
						<SubHeader>{localization.get("user_manage_users")}</SubHeader>
						<TextField placeholder={localization.get("search_onNameorpatientNum")} inputProps={{"aria-label": localization.get("search_onNameorpatientNum")}} onChange={searchExist} style={styles.search} InputProps={styles.inputProp} />
						<LazyList className=" ae-scrollable" loadMore={loadMore} renderItem={renderUserListItem} items={sortedUsers} />
					</div>
					<div className="flex-vertical flex-1">
						<div className="flex-vertical flex-1 ae-scrollable">
							<SubHeader>{localization.get("user_manage_managers")}</SubHeader>
							{showFilter(component, sortedManager)}
							{renderLazyList(component, managers)}
						</div>
						{renderAdd(component)}
					</div>
				</div>

				{state.showingAddManagerModal ? (
					<AddManagerModal
						addManagers={par(state.addManagerMulti, viewing)}
						search={state.search}
						getPresets={state.getPresets}
						localization={localization}
						dismissModal={par(closeAddManagerModal, component)}
						existingManagerIDs={state.managers.get(viewing)}
					/>
				) : undefined}

				<Dialog title={permissionsTitle(localization, component)} actions={permissionActions} modal={true} open={!!permissionDialog}>
					<div className="flex-vertical">
						{renderPermissionToggle(component, localization, userForms, userFormList)}
					</div>
				</Dialog>

				<MuiDialog modal={true} open={!!defaultPermissionDialog} maxWidth="md" fullWidth>
					{renderPermissionTab(component, localization, userForms, userFormList)}
				</MuiDialog>
				<MuiDialog modal={true} open={bulkAddOpen} maxWidth="md" fullWidth PaperProps={{style: {height: "100%"}}}>
					<DialogTitle>
					<Tabs
						fullWidth
						value={bulkUpdateTabValue}
						indicatorColor='primary'
						onChange={par(handleBulkUpdateTabChanged, component)}>
						<Tab value="add" label={localization.get("user_manage_multi_addupdate")}></Tab>
						<Tab value="remove" label={localization.get("user_manage_multi_remove")}></Tab>
					</Tabs>
					</DialogTitle>
					{bulkUpdateTabValue === "add" && <BulkAdd people={people} search={state.search} presetPermissionsList={permissionPresetList} closeDialog={par(closeBulkAdd, component)}></BulkAdd>}
					{bulkUpdateTabValue === "remove" && <BulkRemove people={people} search={state.search} presetPermissionsList={permissionPresetList} closeDialog={par(closeBulkAdd, component)}></BulkRemove>}
				</MuiDialog>

				<Dialog actions={remove_actions} open={!!toRemove} title={localization.get("managers_remove")}>
					{localization.get("managers_warning")}
					{Utility.format_name(people.get(toRemove))}
					?
				</Dialog>

			</div>
		</HelperProvider>
	);
}

function openAddManagerModal(component) {
	component.setState({
		showingAddManagerModal: true
	});
	component.state.userMetrics.trackEvent("user-management: open add manager popup");
}

function closeAddManagerModal(component) {
	component.setState({
		showingAddManagerModal: false
	});
	component.state.userMetrics.trackEvent("user-management: close add manager popup");
}

function openBulkAdd(component) {
	component.setState({
		openBulkAdd: true,
		bulkUpdateTabValue: "add"
	});
	component.state.userMetrics.trackEvent("user-management: open bulk add popup");
}

function closeBulkAdd(component) {
	component.setState({
		openBulkAdd: false
	});
	component.state.userMetrics.trackEvent("user-management: close bulk add popup");
}

function openDefaultPermissions(component) {
	component.setState({
		openDefaultPermissions: true
	});
	component.state.userMetrics.trackEvent("user-management: open default permissions popup");
}

function hideDefaultPermissions(component) {
	component.setState({
		openDefaultPermissions: false,
		defaultPermissionUpdates: {}
	});
	component.state.userMetrics.trackEvent("user-management: close default permissions popup");
}

function showFilter(component, sortedManager) {
	var state = component.state;
	var localization = state.localization;
	var viewing = state.viewing;
	if (!viewing) return;
	var managers = state.managers.get(viewing);
	if (!managers || !managers.length) return;

	return (
		<TextField key={viewing} placeholder={localization.get("search_onName")} inputProps={{"aria-label": localization.get("search_onName")}} onChange={par(searchManager, sortedManager, component)} style={styles.search} InputProps={styles.inputProp} />
	);
}

function renderLazyList(component, managers) {
	var show = [];
	if (!managers.length) {
		return null;
	}
	var renderManagerListItem = par(renderManager, component);
	var filtered = component.state.filteredManagers;
	if (!filtered.length) {
		show = managers;
	} else {
		show = filtered;
	}

	return (<LazyList loadMore={noop} renderItem={renderManagerListItem} items={show} />);
}

function by_name(x, y) {
	var X = x?.lname?.toLowerCase();
	var Y = y?.lname?.toLowerCase();
	if (X === Y) {
		X = x.fname.toLowerCase();
		Y = y.fname.toLowerCase();
	}
	return order(X, Y);
}

function order(X, Y) {
	if (X < Y)
		return -1;
	if (X > Y)
		return 1;
	return 0;
}

/**
 * Takes the existing permissions state and initiates a change to the database
 * @return {Object} component component state
 */
function handle_changePermissions(component, forms) {
	var state = component.state;
	var manager = state.currentManager;
	var userId = state.viewing;
	if (!manager || !userId)
		return;
	var managerId = manager._id;

	// Get new permissions
	var newPermissions = state.permissionUpdates;

	var groupForms = {};
	if (newPermissions.hasOwnProperty("groupforms"))
		groupForms.access = newPermissions.groupforms;

	forms.forEach(function (form) {
		if (newPermissions.hasOwnProperty(form)) {
			groupForms[form] = newPermissions[form];
			delete newPermissions[form];
		}
	});


	// Get existing permissions
	if (!managerId || !userId)
		return;
	var oldPermissions = state.permissions.get(managerId, userId);

	var oldGroupForms = oldPermissions.groupforms;

	if (Object.keys(groupForms).length) {
		var newGroupForms = xtend(oldGroupForms, groupForms);
		newPermissions.groupforms = newGroupForms;
	}
	// Merge old and new permissions
	var modPermissions = xtend(oldPermissions, newPermissions);

	state.updatePermissions(userId, managerId, modPermissions);
	handle_closePermissions(component);

	component.state.userMetrics.trackEvent("user-management: edit permissions of manager", {
		manager: managerId,
		patient: userId,
		permissions: modPermissions,
	});
}

/**
 * Takes the existing permissions state and updates a version of it
 * @param  {Object} component component state
 * @param  {String} key       key of property that has changed
 */
function handle_updatePermissions(component, key, e, changeCheck) {
	e.persist();
	var changeDrop = e.target.value;
	var state = component.state;
	var manager = state.currentManager;
	var user = state.viewing;
	var updates = state.permissionUpdates;
	if (!manager || !user)
		return;
	var update = {};

	// If key is not a "careplan"-related or the "manage" key convert the T/F values to 0/1
	if (SETTINGS_PERMISSIONS.indexOf(key) !== -1 && !key.includes("manage") && !key.includes("resources") && !key.includes("devices")) {
		update[key] = changeCheck ? 1 : 0;
	} else {
		update[key] = changeDrop;
	}

	//  This part takes the 'notes' key and translates
	//	it into two identical permissions for 'files' and 'forms'.
	if (key === "notes") {
		update["files"] = update["notes"];
		update["forms"] = update["notes"];
	}

	var newPermissions = xtend(updates, update);
	component.setState({
		permissionUpdates: newPermissions
	});

}

function permissionsTitle(localization, component) {
	var state = component.state;
	var manager = state.currentManager;
	var user = state.people.get(state.viewing);
	if (!manager || !user)
		return;

	var managing = Utility.format_name(manager);
	var managed = Utility.format_name(user);
	var title_1 = localization.get("permissions_title1");
	var title_2 = localization.get("permissions_title2");
	var title = title_1 + managing + title_2 + managed + " :";
	return (title);
}

function renderPermissionToggle(component, localization, forms, formsList) {
	var state = component.state;
	var manager = state.currentManager;
	var user = state.viewing;
	if (!manager || !user)
		return;

	var permissions = state.permissions.get(manager._id, user);

	if (permissions && permissions?.loading)
		return;

	var permissionUpdates = state.permissionUpdates;
	return renderPermissionsInputs(component, permissions, permissionUpdates, localization, forms, formsList, true, handle_updatePermissions);
}


const HeaderButton = styled(Button)({ float: "right" });
function renderPermissionTab(component, localization, forms) {
	const state = component.state;
	const person = state.currentPerson.get("personal");
	const language = person.language;

	const permissions = state.managerDefault || {};

	const permissionTabValue = state.permissionTabValue;
	const closeModal = par(hideDefaultPermissions, component);
	return (
		<Fragment>
			<DialogTitle>
				<div>
					<HeaderButton onClick={closeModal}>
						<StyledIcon name="close" size={30} />
					</HeaderButton>
				</div>
				<Tabs
					fullWidth
					value={permissionTabValue}
					indicatorColor="primary"
					onChange={par(handlePermissionTabChanged, component)}
				>
					<Tab value="default" label={localization.get("default_permissions")} />
					<Tab value="preset" label={localization.get("preset_permission.tab_title")} />
				</Tabs>
			</DialogTitle>
			<DialogContent>
				{permissionTabValue === "default" && (
					<div className='flex-vertical'>
						<PermissionsForm
							forms={forms}
							permissions={permissions}
							formsPermissions={permissions.groupFroms}
							language={language}
							onSubmit={data => {
								state.updateDefaultPermissions(data).then(closeModal);
								component.state.userMetrics.trackEvent("user-management: edit default permissions", {
									patient: data.patient,
									permissions: data.permissions
								});
							}}
						/>
					</div>
				)}

				{permissionTabValue === "preset" && renderPermissionPresetTab(component, localization)}
			</DialogContent>
		</Fragment>
	);
}

const PresetViewActionBox = styled("div")({
	marginLeft: "auto"
});

const PresetActionContainer = styled("div")({
	margin: "0 8px",
	display: "inline-flex"
});
const PresetView = ({ component }) => {
	const state = component.state;
	const person = state.currentPerson.get("personal");
	const language = person.language;

	const localize = useLocalize();
	const presetList = component.state.managerPreset || [];
	const [createMode, setCreateMode] = useState(false);
	const [editingPreset, setEditingPreset] = useState(null);
	const [deletingPreset, setDeletingPreset] = useState("");
	const showForm = !!createMode || !!editingPreset;

	const closeForm = () => {
		setCreateMode(false);
		setEditingPreset(null);
	};

	const handleCreate = data => {
		component.state.createPresetPermission(data).then(() => {
			closeForm();
		});
	};
	const handleUpdate = data => {
		component.state.updatePresetPermission(editingPreset._id, data).then(() => {
			closeForm();
		});
	};

	const confirmDelete = () => {
		component.state.removePresetPermission(deletingPreset).then(() => {
			setDeletingPreset("");
		});
		component.state.userMetrics.trackEvent("user-management: preset permission removed", {
			preset: deletingPreset
		});
	};
	const cancelDelete = () => {
		setDeletingPreset("");
		component.state.userMetrics.trackEvent("user-management: close preset permission remove popup");
	};
	var deletePresetActions = [
		<Button key={"permissions_delete"} color="primary" onClick={confirmDelete} >{localize("common.confirm")}</Button>,
		<Button key={"permissions_cancel"} color="primary" onClick={cancelDelete}>{localize("common.cancel")}</Button>
	];
	if (showForm) {
		if (createMode) {
			return (
				<PresetPermissionForm
					forms={component.state.userForms}
					onSubmit={handleCreate}
					onCancel={closeForm}
					language={language}
				/>
			);
		} else
			return (
				<PresetPermissionForm
					name={editingPreset.name}
					forms={component.state.userForms}
					permissions={editingPreset.permissions}
					onSubmit={handleUpdate}
					onCancel={closeForm}
					language={language}
				/>
			);
	} else
		return (
			<List>
				{presetList.map(preset => {
					return [
						<ListItem key={preset._id}>
							{preset.name}
							<PresetViewActionBox>
								<PresetActionContainer>
									<PrimaryButton
										variant="contained"
										onClick={() => {
											setEditingPreset(preset);
											component.state.userMetrics.trackEvent("user-management: open preset permission edit popup", {
												preset: preset._id
											});
										}}
									>
										{localize("common.edit")}
									</PrimaryButton>
								</PresetActionContainer>
								<PresetActionContainer>
									<ImportantButton
										variant="contained"
										onClick={() => {
											setDeletingPreset(preset._id);
											component.state.userMetrics.trackEvent("user-management: open preset permission remove popup", {
												preset: preset._id
											});
										}}
									>
										{localize("common.delete")}
									</ImportantButton>
								</PresetActionContainer>
							</PresetViewActionBox>
						</ListItem>,
						<Divider />
					];
				})}
				<ListItem
					button
					onClick={() => {
						setCreateMode(true);
						component.state.userMetrics.trackEvent("user-management: open preset permission create popup");
					}}
				>
					<ListIcon name="plus"></ListIcon>
					{localize("preset_permission.preset_new")}
				</ListItem>
				<Dialog title={localize("preset_permission.preset_delete_title")} actions={deletePresetActions} modal={true} open={!!deletingPreset}>
					{localize("preset_permission.preset_delete_confirm_message")}
				</Dialog>

			</List>
		);
};
function renderPermissionPresetTab(component) {
	return (<PresetView component={component}></PresetView>);

}

function renderPermissionsInputs(component, permissions, permissionUpdates, localization, forms, formsList, shouldUseDefaults, handle_update) {
	var expected_aetonixPermissions = SETTINGS_PERMISSIONS;
	var expected_careplanPermissions = CAREPLAN_PERMISSIONS;

	var renderFormsPermissions = forms.length ? (
		<div>
			{renderPermissionTitle(component, localization, "forms", formsList, shouldUseDefaults)}
			{renderFormPermissions(component, localization, forms, permissions, permissionUpdates, handle_update)}
		</div>
	) : null;

	var permission = expected_careplanPermissions;
	var hasCareplan = permission.some(function (key) {
		var access = permissions[key];
		return key.indexOf("careplan") !== -1 && permissions.hasOwnProperty(key) && access !== 0;
	});
	var renderCareplanPermissions = hasCareplan ? (
		<div>
			{renderPermissionTitle(component, localization, "careplan", {}, shouldUseDefaults)}
			{renderPermissions(component, localization, expected_careplanPermissions, permissions, permissionUpdates, handle_update)}
		</div>
	) : null;

	return (
		<div>
			{renderPermissionTitle(component, localization, "aetonix", {}, shouldUseDefaults)}
			{renderPermissions(component, localization, expected_aetonixPermissions, permissions, permissionUpdates, handle_update)}
			{renderFormsPermissions}
			{renderCareplanPermissions}
		</div>
	);
}


function renderFormPermissions(component, localization, forms, permissions, permissionUpdates, handle_update) {
	var state = component.state;
	var person = state.currentPerson.get("personal");
	var language = person.language;
	var groupFormPermissions = permissions.groupforms || {};

	return forms.map(function (form) {
		var access = groupFormPermissions[form._id] || 0;
		if (typeof permissionUpdates === "object" && permissionUpdates.hasOwnProperty(form._id))
			access = permissionUpdates[form._id];
		var localized_key = form.localization[language] || form.localization["en"];

		const isMulti = form.formType === "multi";

		const multiOnlyPermission = [<MenuItem value={-2}>{localization.get("permissions_readwrite_family")}</MenuItem>];

		return (
			<div key={form._id} className="flex-horizontal ae-justify ae-align-centre ae-bottom-margin">
				{localized_key}
				<Select classes={selectClasses} inputProps={{"aria-label": localized_key }} key={localized_key} value={access} onChange={par(handle_update, component, form._id)}>
					<MenuItem value={0}>{localization.get("permissions_noaccess")}</MenuItem>
					<MenuItem value={1}>{localization.get("permissions_readonly")}</MenuItem>
					<MenuItem value={2}>{localization.get("permissions_readwrite")}</MenuItem>
					{
						isMulti && multiOnlyPermission
					}
				</Select>
			</div>
		);
	});
}

function renderPermissions(component, localization, expected_permission, permissions, permissionUpdates, handle_update) {
	var permission = expected_permission;
	return (permission.map(function (key) {
		if (key === "_id" || key === "careplan_DocumentControl")
			return;

		/* This portion is a temporary insert allowing the use of 'notes'
			vice 'files' and 'forms'
			if the key is "files" or "notes" skip the rendering */
		if (key === "files" || key === "notes")
			return;

		/* if the permissions for files or forms is 'true', then create
			a key 'notes' and 'notes =  true' ... and vice versa */
		if (key === "files" || key === "forms") {
			key = "notes";
			if (permissions["files"] || permissions["forms"])
				permissions[key] = true;
		}

		// compose a key for localizations
		var composeKey = "permissions_" + key;
		var localized_key = localization.get(composeKey);

		// retrieve the permission value
		var access = permissions[key];

		if (typeof permissionUpdates === "object" && permissionUpdates.hasOwnProperty(key))
			access = permissionUpdates[key];

		// Normalize for backward compatibility and undefined values
		// Older versions may be either boolean or string
		// If a string then anything except "true" is deemed false and assigned '0'
		if (!access)
			access = 0;
		if (typeof access !== "number") {
			if (typeof access === "boolean")
				access ? access = 1 : access = 0;
			if (typeof access === "string")
				access === "true" ? access = 1 : access = 0;
		}

		var dropdown = null;
		if (key === "manage")
			dropdown = [
				(<MenuItem value={0}>{localization.get("permissions_noaccess")}</MenuItem>),
				(<MenuItem value={1}>{localization.get("permissions_viewonly")}</MenuItem>),
				(<MenuItem value={3}>{localization.get("permissions_viewmodify")}</MenuItem>)
			];
		else if (key === "resources")
			dropdown = [
				(<MenuItem value={0}>{localization.get("permissions_noaccess")}</MenuItem>),
				(<MenuItem value={1}>{localization.get("permissions_view")}</MenuItem>),
				(<MenuItem value={2}>{localization.get("permissions_readadjustaccess")}</MenuItem>)
			];
		else if (key === "devices")
			dropdown = [
				(<MenuItem value={0}>{localization.get("permissions_noaccess")}</MenuItem>),
				(<MenuItem value={1}>{localization.get("permissions_view")}</MenuItem>),
				(<MenuItem value={2}>{localization.get("permissions_viewedit")}</MenuItem>)
			];

		var checkornot = (key !== "manage" && key !== "resources" && key !== "devices")
			? (<Checkbox className="ae-widthauto" defaultChecked={!!access} key={localized_key + access} inputProps={{ "aria-label": localization.get("permissions_checkbox_for") + localized_key, "role": "checkbox"}} onChange={par(handle_update, component, key)} />)
			: (
				<div key={localized_key} className="ae-bottom-margin">
					<Select classes={selectClasses} inputProps={{"aria-label": localized_key}} key={localized_key} value={access} onChange={par(handle_update, component, key)}>
						{dropdown}
					</Select>
				</div>
			);

		if (key.indexOf("careplan") !== -1) {
			return (
				<div key={key} className="flex-horizontal ae-justify ae-align-centre ae-bottom-margin">
					{localized_key}
					<Select classes={selectClasses} key={localized_key} value={access} onChange={par(handle_update, component, key)}>
						<MenuItem value={0}>{localization.get("permissions_noaccess")}</MenuItem>
						<MenuItem value={1}>{localization.get("permissions_readonly")}</MenuItem>
						<MenuItem value={2}>{localization.get("permissions_readwrite")}</MenuItem>
						<MenuItem value={3}>{localization.get("permissions_readwriteapprove")}</MenuItem>
					</Select>
				</div>
			);
		} else {
			return (
				<div key={key} className="flex-horizontal flex-1 ae-justify ae-align-centre">
					{localized_key}
					{checkornot}
				</div>
			);
		}
	}));
}

function renderPermissionTitle(component, localization, title, forms, shouldUseDefaults) {
	var section_title = localization.get("permissions_" + title + "_section");
	var select = localization.get("permissions_select_all");
	const selectDefaults = localization.get("permissions_select_defaults");
	return (
		<div className="flex-vertical flex-1">
			<div className="ae-permission-section">
				{section_title}
			</div>
			<div className="ae-selectall">
				<span>
					<Button className="ae-widthauto" color="primary" onClick={par(setAll, component, localization, title, forms, shouldUseDefaults)}>
						{select}
					</Button>
					<Button className="ae-widthauto" color="primary" onClick={par(setDefaultPermissions, component, title, forms)}>
						{selectDefaults}
					</Button>
				</span>
			</div>
		</div>
	);
}

function setAll(component, localization, title, forms, shouldUseDefaults) {
	if (!title) {
		return;
	}
	var permissions;
	var change;

	var updater = shouldUseDefaults ? "permissionUpdates" : "defaultPermissionUpdates";
	var updates = component.state[updater];

	if (title === "aetonix") {
		change = 1;
		permissions = SETTINGS_PERMISSIONS;
	}
	if (title === "careplan") {
		change = 3;
		permissions = CAREPLAN_PERMISSIONS;
	}
	if (title === "forms") {
		change = 2;
		permissions = forms;
	}

	var update = permissions.reduce(function (curr, permission) {
		// temporary to until 'files' and 'forms' are used
		if (permission === "files" || permission === "forms") {
			curr[permission] = change;
			curr["notes"] = change;
			return curr;
		}
		if (permission !== "_id") {
			curr[permission] = change;
			return curr;
		}
		return curr;
	}, {});

	var newPermissions = xtend(updates, update);

	var stateUpdate = {};

	stateUpdate[updater] = newPermissions;

	component.setState(stateUpdate);

	component.state.userMetrics.trackEvent("user-management: set all permissions", {
		permissions: newPermissions,
		patient: component.state.viewing,
	});
}

function setDefaultPermissions(component, title, forms) {
	component.state.userMetrics.trackEvent("user-management: set default permissions", {
		patient: component.state.viewing,
		title,
	});

	if (!title) {
		return;
	}

	const defaults = component.state.managerDefault;
	if (!defaults) {
		return;
	}

	let permissions;
	if (title === "aetonix") {
		permissions = SETTINGS_PERMISSIONS;
	}
	if (title === "careplan") {
		permissions = CAREPLAN_PERMISSIONS;
	}
	if (title === "forms") {
		permissions = forms;
	}

	const defaultForms = defaults.groupforms || {};
	const defaultUpdate = xtend(defaults, defaultForms);
	const update = permissions.reduce((acc, permission) => {
		acc[permission] = defaultUpdate.hasOwnProperty(permission)
			? defaultUpdate[permission]
			: 0; // default to no access where a default doesn't already exist

		return acc;
	}, {});

	const updater = "permissionUpdates";
	const updates = component.state[updater];
	const newPermissions = xtend(updates, update);

	const stateUpdate = {};
	stateUpdate[updater] = newPermissions;
	component.setState(stateUpdate);
}

/**
 * The following functions open/close the dialogue box for permissions and set the state to reflect the
 * current Manager, the manager's permissions and the toggle for rendering
 *	the dialogue box.
 * @param	{Object} component component state
 * @param	{Object} manager	 current manager's data
 * @return {Object}					 sets three properties
 */
function handle_openPermissions(component, manager) {
	component.setState({
		openPermissions: true,
		currentManager: manager,
		permissionUpdates: {}
	});
	component.state.userMetrics.trackEvent("user-management: open edit permissions popup", {
		manager: manager._id,
		patient: component.state.viewing
	});
}

function handle_closePermissions(component) {
	component.setState({
		openPermissions: false,
		currentManager: null,
		permissionUpdates: {}
	});
	component.state.userMetrics.trackEvent("user-management: close edit permissions popup");
}

function renderAdd(component) {
	if (component.state.viewing)
		return (<Button variant="raised" onClick={par(openAddManagerModal, component)} color="secondary" >{component.state.localization.get("user_manage_label")}</Button>);
}

/**
* This renders each of a patient's managers. Tapping on the edit icon will cause
* a permission list to be displayed, tapping on the delete icon removes the
* manager from the patient's list of managers.
* @param	{Object} component Component state
* @param	{Object} manager	 Manager details
* @return {Component}					Manager avatar, name, and delete icon
*/
function renderManager(component, manager) {

	var state = component.state;
	var managerId = manager._id;
	var localization = component.state.localization;
	var avatar = <Avatar alt="Manager Avatar Image" src={config.image_cdn + manager.image} />;
	var text = Utility.format_name(manager);

	var viewing = state.viewing;
	var remove = par(proposeRemove, component, viewing, managerId);

	var background_colour = "ae-plain";
	var manager_viewed = state.manager_viewed;
	if (manager_viewed && managerId === manager_viewed)
		background_colour = "ae-hover-color";

	var button = (
		<div className="flex-horizontal">
			<IconButton classes={iconButtonClasses} disableRipple aria-label={localization.get("edit_button")} title={localization.get("edit_button")}  role="button" color="primary" className="fa fa-pencil-square-o" onClick={par(handle_openPermissions, component, manager)} />
			<IconButton classes={iconButtonClasses} disableRipple aria-label={localization.get("remove_button")} title={localization.get("remove_button")} role="button" color="primary" className="fa fa-user-times" onClick={remove} />
		</div>
	);
	return (
		<ListItem classes={listItemClasses} key={managerId} className={background_colour} onClick={par(set_manager_viewing, component, managerId)}>
			{avatar}
			<ListItemText primary={text} />
			{button}
		</ListItem>
	);
}

function set_manager_viewing(component, manager) {
	component.setState({
		manager_viewed: manager
	});
}

function renderUser(component, user, index) {
	if (!user._id) return;
	var avatar = <Avatar alt="User Avatar Image" src={config.image_cdn + user.image} />;
	var text = Utility.format_name(user);
	var userIndex = index + 1;
	var userId = user._id;
	var background_colour = "ae-plain";
	var viewing = component.state.viewing;
	if (userId === viewing)
		background_colour = "ae-hover-color";

	return (
		<ListItem key={user._id} button classes={listItemClasses} className={background_colour} onClick={par(viewManagersFor, component, userId, userIndex)}>
			{avatar}
			<ListItemText primary={text} />
		</ListItem>
	);
}

function viewManagersFor(component, user, userIndex) {
	component.setState({
		viewing: user,
		selectedIndex: userIndex,
		filteredManagers: []
	});
	component.state.userMetrics.trackEvent("user-management: view managers for patient", {
		patient: user
	});
}

function searchManager(managers, component, event) {
	event.persist();
	var query = event.target.value;
	var terms = escape_regex(query).split(" ").join("");
	var regex = new RegExp(terms, "i");

	var filtered = managers.filter(function (item) {
		var matches = (item.fname + item.lname).split(" ").join("").match(regex);
		if (matches) return item;
	});

	component.setState({
		filteredManagers: filtered
	});
}

function noop() { }

function proposeRemove(component, user, manager, event) {
	event.stopPropagation();
	component.setState({
		toRemove: manager
	});
	component.state.userMetrics.trackEvent("user-management: open remove manager popup", {
		manager: manager,
		patient: user
	});
}

function confirmRemove(component, user, manager) {
	var state = component.state;
	// remove manager from manager list
	state.removeManager(user, manager);
	// remove manager from search results (if there)
	if (state.filteredManagers) {
		var filtered = state.filteredManagers.filter(par(searchFilter, manager));
	}
	component.setState({
		filteredManagers: filtered
	});
	hideRemove(component);

	component.state.userMetrics.trackEvent("user-management: remove manager", {
		patient: user,
		manager,
	});
}

function searchFilter(manager, item) {
	return item._id !== manager;
}

function hideRemove(component) {
	component.setState({
		toRemove: null
	});
	component.state.userMetrics.trackEvent("user-management: close remove manager popup");
}

function handlePermissionTabChanged(component, event, value) {
	component.setState({
		permissionTabValue: value
	});
}

function handleBulkUpdateTabChanged(component, event, value) {
	component.setState({
		bulkUpdateTabValue: value
	});
	const eventText = {
		add: "user-management: navigate to bulk add tab in bulk add popup",
		remove: "user-management: navigate to bulk remove tab in bulk add popup"
	};
	component.state.userMetrics.trackEvent(eventText[value]);
}