/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

/**
 * This store derives its data from an api call to /org/devices.  It contains
 * data concerning the bracelet connection history of membe rs of a group.
 */

import Store from "./store";

import map from "fj-map";

import * as Sentry from "@sentry/react";

export default CustomIndicatorHistoryStore;

function CustomIndicatorHistoryStore(api) {
	var store = new Store();

	var loading = null;
	var page = 0;
	var user = [];
	var start = "";
	var end = "";
	let indicatorId = "";

	store.sorted = sorted;
	store.more = more;
	store.searchQuery = searchQuery;
	store.searchDate = searchDate;
	store.clearSearch = clearSearch;
	store.reset = reset;
	store.indicator = forIndicator;

	return store;

	function reset() {
		page = 0;
		store.clear();

		return api.org.devices.health
			.custom(indicatorId, user, page, start, end)
			.then(function(readings) {
				if (readings.length) {
					map(handle_create, readings);
				} else {
					store.clear();
				}
			})
			.catch(error => {
				store.error(error);
				Sentry.captureException(error);
			});
	}

	function more() {
		if (loading) {
			return loading;
		}

		loading = api.org.devices.health
			.custom(indicatorId, user, page, start, end)
			.then(map(handle_create))
			.then(next_page)
			.catch(error => {
				store.error(error);
				Sentry.captureException(error);
			})
			.then(finish);
	}

	function forIndicator(indicatorToSet) {
		indicatorId = indicatorToSet;
		return store;
	}

	function finish() {
		loading = null;
	}

	function sorted() {
		return store?.all?.()?.sort(by_time) ?? [];
	}

	function handle_create(data) {
		var id = data._id;
		store.set(id, data);
	}

	function next_page() {
		page += 1;
	}

	function searchQuery(filterquery) {
		user = user.concat(filterquery);
		return reset();
	}

	function searchDate(startdate, enddate) {
		start = startdate;
		end = enddate;
		if (!!start && !!end) {
			return reset();
		}
		return;
	}

	function clearSearch(name) {
		if (name === "user") user = [];
		if (name === "time") {
			start = "";
			end = "";
		}
		return reset();
	}
}

function by_time(prev, next) {
	var prev_time = new Date(prev.created_at).getTime();
	var next_time = new Date(next.created_at).getTime();

	var time_delta = next_time - prev_time;
	return time_delta;
}
