/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */



import Store from "./store";
import later from "call-later";
import map from "fj-map";
import head from "array-head";

import * as Sentry from "@sentry/react";

export default ReminderDataStore;

var defaultReminder = {
	content: "Loading.."
};

function ReminderDataStore(api) {
	var store = new Store();
	var tracking_list = [];

	store.raw_get = store.get;
	store.get = get;

	var fetch = later(fetchAllData);

	return store;

	function get(id) {
		var data = store.raw_get(id);
		if (data) return data;

		if (!isTracking(id)) {
			tracking_list.push(id);
			fetch(id);
		}

		return defaultReminder;
	}

	function isTracking(id) {
		return tracking_list.indexOf(id) !== -1;
	}

	function fetchAllData(ids) {
		ids = ids.map(head);
		api.org.reminders
			.groupData(ids)
			.then(map(setReminder))
			.catch(error => {
				Sentry.captureException(error);
				store.error(error);
			});
	}

	function setReminder(reminder) {
		var id = reminder._id;
		store.set(id, reminder);
	}
}
