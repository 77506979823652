/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, Feb 24, 2017
 * For information or permission request, email info@aetonixsystems.com
 */



import Store from "./store";
import par from "par";
var error = false;

export default UserPrivacyPreferenceStore;

function UserPrivacyPreferenceStore(api, events, config) {
	var store = new Store();

	store.raw_get = store.get;
	store.get = get;

	var tracking = [];

	return store;

	function get(id) {
		var existing = store.raw_get(id);
		if (existing) return existing;

		if (!isTracking(id))
			track(id);

		return { loading: true, error: error };
	}

	function isTracking(id) {
		return tracking.indexOf(id) !== -1;
	}

	function track(id) {
		tracking.push(id);

		api.org.users.data(id)
		.then(par(setPreferences, id))
		.catch((err) => {error = true; throw err;});

		var orgGroup = config.orgGroup;
		if(!orgGroup) return;
		events.on("mqtt:orggroup/" + orgGroup + "/u/" + id + "/settings", par(updatePreferences, id));
		events.on("mqtt:orggroup/" + orgGroup + "/u/" + id + "/followedUp/updated", par(updatePreferences, id));
	}

	function updatePreferences(id, data) {
		store.update(id, data);
	}

	function setPreferences(id, data) {
		store.set(id, data);
	}
}
