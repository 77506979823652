/* © 2022 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, February 11, 2022
 * For information or permission request, email info@aetonixsystems.com
 */

import Store from "./store";

export default ReportsStore;

function ReportsStore(api, events) {
	var store = new Store();

	var query = "";
	var page = 0;
	// var atEnd = false; // Will be used once more() is implemented

	api.org.id().then(listen_for_additions).catch(e => {throw(e);});

	store.makeDownloadURL = makeDownloadURL;
	store.more = more;
	store.search = search;
	store.getAtEnd = getAtEnd;

	reset().then(done).catch(e => {throw(e);});

	return store;

	function makeDownloadURL(Key) {
		const matchingReports = store.get("reports").filter((value) => value.Key === Key);
		const report = matchingReports.length ? matchingReports[0] : undefined;
		if (!report || report?.tooLarge)
			return;
		return api.org.reports.make_download_url(Key);
	}

	function done() {
		store.set("loading", false);
	}

	function reset() {
		page = 0;
		// atEnd = false;
		store.clear();
		store.set("loading", true);
		return api.org.reports.list(query, page)
			.then(add_reports);
	}

	function add_reports(reports) {
		store.set("reports", reports);
	}

	function add_report(report) {
		const oldReports = store.get("reports");
		store.set("reports", oldReports.concat(report));
	}

	function listen_for_additions(organizationID) {
		if (!organizationID) return;
		events.on("mqtt:org/" + organizationID.toString() + "/report/added", add_report);
	}

	function search(/*newQuery*/) {
		throw "Not implemented yet";
		/* Will look like:
		query = newQuery;
		return reset();*/
	}

	function getAtEnd() {
		throw "Not implemented yet";
		/* Will look like:
		return atEnd;*/
	}

	function more() {
		throw "Not implemented yet";
		/* Will look like:
		if (atEnd)
			return Promise.resolve();
		if (store.loading)
			return store.loading;
		page += 1;
		return api.org.reports
			.list(query, page);*/
	}

}
