/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */



import par from "par";

import defineUI from "../shared/define";
import render from "./render.jsx";
import PermissionsList from "../shared/PermissionsList.js";

var SETTINGS_PERMISSIONS = PermissionsList.SettingsPermissions;

import PersonStore from "../stores/person_store";
import UserStore from "../stores/user_store";
import UserData from "../stores/user_data_store";
import LocalizationStore from "../stores/localization_store";
import UserTokenStore from "../stores/user_token_store";
import CurrentPersonInfoStore from "../stores/current_person_info_store";
import UserInactive from "../stores/user_inactive";
import UsersPrivateStore from "../stores/user_privacy_store";
import InvitationStore from "../stores/email_invitation_store";
import ConnectionStore from "../stores/connection_store";
import CareplanNotificationStore from "../stores/careplan_notice_store";
import CoreFormStore from "../stores/core_form_store";
import ManagerGroups from "../stores/group_manager_groups_store";
import OrgGroupSettings from "../stores/staff_org_group_settings";
import DefaultManagerPermissions from "../stores/org_group_default_manager_permissions";

import * as Sentry from "@sentry/react";
import makeStoreFactory from "../shared/stores";

const makeStores = makeStoreFactory({
	people: PersonStore,
	users: UserStore,
	userData: UserData,
	invitations: InvitationStore,
	tokens: UserTokenStore,
	currentPerson: CurrentPersonInfoStore,
	localization: LocalizationStore,
	usersInactive: UserInactive,
	usersPrivate: UsersPrivateStore,
	connection: ConnectionStore,
	careplanChangeList: CareplanNotificationStore,
	coreforms: CoreFormStore,
	managerDefault: DefaultManagerPermissions,
	managerGroups: ManagerGroups,
	groupSettings: OrgGroupSettings
});

function make_ui(api, events, config, userMetrics, props) {
	var storeStore = makeStores(api, events, config);

	userMetrics.trackEvent("page view", {
		"page": "group-users",
		"category": "user",
		"permission level": "staff",
	});

	return defineUI({
		getInitialState: getInitialState,
		componentDidMount: listen_for_changes,
		render: render,
		componentWillUnmount: removeListeners
	});

	function removeListeners() {
		events.off();
	}

	function listen_for_changes() {
		storeStore.listen(par(handle_change, this));
	}

	function handle_change(component, stores) {
		component.setState({
			currentPerson: stores.currentPerson,
			localization: stores.localization,
			usersInactive: stores.usersInactive,
			usersPrivate: stores.usersPrivate,
			people: stores.people,
			userData: stores.userData,
			tokens: stores.tokens,
			users: stores.users.all(),
			invitations: stores.invitations.all(),
			connection: stores.connection,
			careplanChangeList: stores.careplanChangeList,
			coreforms: stores.coreforms,
			managerDefault: stores.managerDefault.get("default"),
			manageGroups: stores.managerGroups.all(),
			managerGroups: stores.managerGroups,
			keys: stores.users.keys(),
			groupSettings: stores.groupSettings.get("settings"),
			userMetrics: userMetrics,
		});
	}


	function getInitialState() {
		return {
			groupSettings: {},
			addCareCoordinator: addCareCoordinator,
			deleteCareCoordinator: deleteCareCoordinator,
			createUserATA: par(createUserATA, api),
			createCareCoordinator: par(createCareCoordinator, api),
			currentPerson: storeStore.stores.currentPerson,
			editUser: null,
			addEMRUser: addEMRUser,
			loadMore: par(loadMore, storeStore.stores.users),
			localization: storeStore.stores.localization,
			people: storeStore.stores.people,
			removeUser: api.org.users.remove,
			removeInvitation: api.org.users.invitation.remove,
			removeOrgGroupInvitation: api.org.users.invitation.remove_existing,
			search: par(search, storeStore.stores.users),
			careCoordinatorSearch: par(careCoordinatorSearch, api),
			searchOnStatus: par(searchOnStatus, storeStore.stores.users),
			searchOnManageGroup: par(searchOnManageGroup, storeStore.stores.users),
			emrUserSearch: par(emrUserSearch, api),
			tokens: storeStore.stores.tokens,
			usersPrivate: storeStore.stores.usersPrivate,
			tokenUser: null,
			toRemove: null,
			toRemoveInvitation: null,
			toremoveOrgGroupInvitation: null,
			toUpdate: null,
			newInactive: null,
			newFName: "",
			newLName: "",
			newNumber: "",
			newUserNotes: null,
			userNoteError: false,
			newManageGroup: null,
			currManageGroups: null,
			careCoordinators: [],
			newSearchHidden: null,
			updateUser: par(updateUser, api),
			usersInactive: storeStore.stores.usersInactive,
			updateInactive: par(updateInactive, api),
			updateCareplanNumber: par(updateCareplanNumber, api),
			updateUserType: par(updateUserType, api),
			users: [],
			userData: storeStore.stores.userData,
			invitations: [],
			emrUser: "",
			userStateFilter: [],
			filterManageGroupValue: [],
			connection: storeStore.stores.connection,
			careplanChangeList: storeStore.stores.careplanChangeList,
			coreforms: storeStore.stores.coreforms,
			tabValue: 0,
			sendInvitation: par(sendInvitation, api),
			confirming: null,
			removeManagers: null,
			managerDefault: storeStore.stores.managerDefault.get("default"),
			simplified: false,  // deprecated
			createUserMobile: par(createUserMobile, api),
			createUserBasic: par(createUserBasic, api),
			goToUser: goToUser,
			manageGroups: [],
			managerGroups: storeStore.stores.managerGroups,
			assignGroup: assignGroup,
			removeGroup: removeGroup,
			usersSelect: false,
			selectedUser: [],
			keys: [],
			loadAllUser: loadAllUser,
			manageGroupsDialog: false,
			removeManageGroup: false,
			filteredManageGroups: null,
			shouldClearManageGroupSearch: false,
			selectedManageGroup: null,
			openManageGroupAddConfirmation: false,
			openToast: false,
			toastSuccess: false,
			selectAllWarningToast: false,
			assignUsersToManageGroup: assignUsersToManageGroup,
			removeUsersFromManageGroups: removeUsersFromManageGroups,
			createUserType: "",
			createUserLanguage: "",
			formInlineErrors: {},
			enableTestMode: enableTestMode,
			disableTestMode: disableTestMode,
			enableTestModeBulk: enableTestModeBulk,
			disableTestModeBulk: disableTestModeBulk,
			userMetrics: userMetrics,
			previousList: [],
			isUserExists: false,
			newData: {},
		};
	}

	function goToUser(userId){
		props.history.push("/user-overview/" + userId);
	}

	function assignGroup(id, group){
		return api.org.managergroups.assignGroup(id, group);
	}


	function removeGroup(manageGroups, manageGroupsToRemove){
		return api.org.managergroups.removeGroup(manageGroups, manageGroupsToRemove);
	}

	function loadAllUser() {
		storeStore.stores.users.loadAllUser();
	}

	function assignUsersToManageGroup(arrOfIds, groupId) {
		return api.org.managergroups.assignUsersToManageGroup(arrOfIds, groupId);
	}

	function removeUsersFromManageGroups(arrOfIds) {
		return api.org.managergroups.removeUsersFromManageGroups(arrOfIds);
	}

	function enableTestMode(userId) {
		return api.org.users.testmode.enable(userId);
	}
	function disableTestMode(userId) {
		return api.org.users.testmode.disable(userId);

	}

	function enableTestModeBulk(userIds) {
		return api.org.users.testmode.enableBulk(userIds);
	}

	function disableTestModeBulk(userIds) {
		return api.org.users.testmode.disableBulk(userIds);

	}
}


function loadMore(users) {
	return users.more();
}

function addCareCoordinator(component, careCoordinator) {
	var existingCare = component.state.careCoordinators;
	if (existingCare.indexOf(careCoordinator) !== -1) return;
	var newCare = existingCare.concat(careCoordinator);
	component.setState({
		careCoordinators: newCare
	});
}

function addEMRUser(component, _id, person) {
	const udpatedFormInlineErrors = component.state.formInlineErrors;
	udpatedFormInlineErrors["newFName"] = null;
	udpatedFormInlineErrors["newLName"] = null;
	component.setState({
		emrUser: _id || true,
		newFName: person.fname,
		newLName: person.lname,
		newNumber: person.patientNumber,
		formInlineErrors: udpatedFormInlineErrors
	});
	component.refs?.emrSearchDialog?.dismiss();
}


function deleteCareCoordinator(component, careCoordinator) {
	var existingCare = component.state.careCoordinators;
	var newCare = existingCare.filter(function (item) {
		return item != careCoordinator;
	});
	component.setState({
		careCoordinators: newCare
	});
}

function createUserATA(api, component, fname, lname, patientNumber, lang, user_note, data) {
	if (!fname || !lname || !lang) return;
	return api.org.users
		.create(fname, lname, patientNumber, null, lang, user_note, data)
		.catch(function(err) {
			Sentry.captureException(err);
			if(err.response.message === component.state.localization.get("users_exists_err_msg")){
				component.setState({
					isUserExists: true
				});
			} else{
				component.setState({
					errorToast: err.response.message
				});
			}
		});
}

function createUserMobile(api, component, fname, lname, patientNumber, lang, user_note, data) {
	if (!fname || !lname || !lang) return;
	return api.org.users.createMobile(fname, lname, patientNumber, null, lang, user_note, data)
		.catch(function(err) {
			Sentry.captureException(err);
			if(err.response.message === component.state.localization.get("users_exists_err_msg")){
				component.setState({
					isUserExists: true
				});
			} else{
				component.setState({
					errorToast: err.response.message
				});
			}
		});
}

function createUserBasic(api, component, fname, lname, patientNumber, lang, user_note, data){
	if (!fname || !lname || !lang) return;
	return api.org.users.createSimplified(fname, lname, patientNumber, null, lang, user_note, data)
		.catch(function(err){
			Sentry.captureException(err);
			if(err.response.message === component.state.localization.get("users_exists_err_msg")){
				component.setState({
					isUserExists: true
				});
			} else{
				component.setState({
					errorToast: err.response.message
				});
			}
		});
}

function createCareCoordinator(api, component, user, manager) {
	var aetonix_permissions = SETTINGS_PERMISSIONS.reduce(function (acc, permission) {
		acc[permission] = 1;
		return acc;
	}, {});

	var defaults = component.state.managerDefault;
	if(defaults)
		aetonix_permissions = defaults;

	return api.org.manage.add(user, manager, aetonix_permissions)
		.then(function (data) {
			var message = data.message;
			if (message === "OK") {
				component.setState({
					managerAddSuccess: true
				});
			}
		})
		.catch(function (err) {
			Sentry.captureException(err);
		});
}

function search(users, event) {
	event.persist();
	var query = event.target.value;
	return users.search(query);
}

function careCoordinatorSearch(api, query) {

	var filter = {
		type: {
			exclude: ["atouchaway", "user:mobile", "simplified"]
		}
	};

	return api.people.search(query, filter);
}

function emrUserSearch(api, query) {
	if (query.length === 0) return;
	return api.org.users.emr(query);
}

function searchOnStatus(users, state) {
	users.filterSearch({ state });
}

function searchOnManageGroup(users, manageGroup) {
	users.searchManageGroup(manageGroup);
}

function updateUser(api, user_data, user_id) {
	return api.org.users.update(user_id, user_data);
}

function updateInactive(api, user_data, user_id, shouldRemoveFlags) {
	const { shouldRemoveManagers = false, shouldStopWorkflows = false, shouldRemoveContacts = false } = (shouldRemoveFlags || {});
	return api.org.users.inactive.update(user_id, user_data, shouldRemoveManagers, shouldStopWorkflows, shouldRemoveContacts);
}

function updateCareplanNumber(api, patientNumber, user_id) {
	return api.org.users.careplan.number.update(user_id, patientNumber);
}

function updateUserType(api, userType, user_id) {
	const updateType = userType === "user:mobile" ? "mobile" : userType;
	return api.org.users.typeUpdate(user_id, updateType);
}

function sendInvitation(api, email, careCoordinators, fname, lname, patientNumber, manageGroup, lang, user_note) {
	if (!email) return;
	var firstName = fname || "";
	var lastName = lname || "";
	var userNumber = patientNumber || "";
	var managementGroup = manageGroup || [];
	const language = lang || "en";
	return api.org.users.invitation.send(email, careCoordinators, firstName, lastName, userNumber, managementGroup, language, user_note);
}

export default make_ui;
