
/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */



/**
 * This store derives its data from an api call to /org/reminders.  It contains
 * data concerning the reminders history of members of a group.
 */

import Store from "./store";

import par from "par";

export default WorkflowOngoingStore;

function WorkflowOngoingStore(api) {
	var store = new Store();

	store.raw_get = store.get;
	store.get = get;
	store.findOngoingWorkflow = findOngoingWorkflow;

	var tracking = [];

	return store;

	function get(id) {
		var existing = store.raw_get(id);
		if (existing) return existing;

		if (!isTracking(id))
			track(id);

		return [];
	}

	function isTracking(id) {
		return tracking.indexOf(id) !== -1;
	}

	function track(id) {
		tracking.push(id);
		api.org.workflow.ongoing(id)
			.then(par(setDefinition, id));
	}

	function setDefinition(id, definition) {
		store.set(id, definition[0]);
		return definition[0];
	}

	function findOngoingWorkflow(definition) {
		var ongoingList = store.all();
		var ongoingWorkflow = ongoingList.find(function(ongoingData){
			return ongoingData.workflow === definition;
		});
		return ongoingWorkflow;
	}
}