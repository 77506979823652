import { FormSubmissionsProvider, useFormSubmissions, usePeople } from "@aetonix/hooks";
import { SpinningIcon } from "@aetonix/web-ui";
import { Button, Grid, Paper, TablePagination, List, ListItem, ListItemText } from "@material-ui/core";
import { Add, Delete, Edit, Lock, Sync, History } from "@material-ui/icons";
import StyledText from "ae-base/Base/StyledText";
import OutputForm from "ae-forms/src/Outputs/OutputForm";
import React, { useEffect, useState } from "react";
import { ListErrorBoundary } from "../../shared/ErrorBoundary";
import { useFormatDate } from "../../shared/contexts/FormatDateContext";
import { useLocalize } from "../../shared/contexts/LocalizeContext";
import { LocalizeFromConsumer, useLocalizeFrom } from "../../shared/contexts/LocalizeFromContext";
import { ErrorToast } from "./ErrorToast";
import { FormsFilter } from "./FormsFilter";
import { ConfirmationDialog } from "./dialogs/ConfirmationDialog";
import { CreateFormDialog } from "./dialogs/CreateFormDialog";
import { EditFormDialog } from "./dialogs/EditFormDialog";
import { useUserMetrics } from "@aetonix/user-metrics";
import { InfoDialog } from "./dialogs/InfoDialog";
import { useApi } from "../../shared/contexts/ResourceContext";

const styles = {
	buttonGroup: {
		display: "flex",
		gap: "1rem"
	},
	centerContent: {
		display: "flex",
		justifyContent: "center",
		padding: "1rem"
	},
	formContainer: {
		padding: "1rem"
	},
	pagination: {
		display: "flex",
		justifyContent: "flex-end"
	},
	title: {
		alignItems: "center",
		display: "flex",
		justifyContent: "space-between"
	}
};

function Pagination({ total, page, updatePage, recordsPerPage, handleRecordsPerPageChange, labelRowsPerPage }) {
	return (
		<Grid item xs={12} sm={5} style={styles.pagination}>
			<TablePagination
				count={total}
				page={page}
				onPageChange={updatePage}
				rowsPerPage={recordsPerPage}
				rowsPerPageOptions={[10, 15, 20, 25, 30]}
				onRowsPerPageChange={handleRecordsPerPageChange}
				labelRowsPerPage={labelRowsPerPage}
			/>
		</Grid>
	);
}

function NoResults() {
	const localize = useLocalize();
	return (
		<Grid item xs={12}>
			<Paper style={styles.centerContent}>{localize("No_Results")}</Paper>
		</Grid>
	);
}

function FormSubmission({ submission, deleteSubmission, lockSubmission, submitForm }) {
	const [showEditDialog, setShowEditDialog] = useState(false);
	const [showLockConfirm, setShowLockConfirm] = useState(false);
	const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
	const [showHistory, setShowHistory] = useState(false);

	const [peopleMap, setPeopleMap] = useState({});
	const api = useApi();

	const localize = useLocalize();
	const localizeFrom = useLocalizeFrom();
	const formatDate = useFormatDate();
	const { people, trackPerson } = usePeople();
	const { userMetrics } = useUserMetrics();

	const editHistory = submission.editHistory;

	const lastEdit = submission.editHistory[submission.editHistory.length - 1];
	const firstEdit = submission.editHistory[0];
	useEffect(() => {
		trackPerson(lastEdit.id);
	}, [trackPerson, lastEdit.id]);

	useEffect(() => {
		let peopleSet = new Set();
		editHistory && editHistory.forEach(item => {
			peopleSet.add(item.id);
		});
		let peopleToFetch = Array.from(peopleSet);
		peopleToFetch.length && api.people.public(peopleToFetch).then(res => {
			let peopleList = res;
			let peopleObj = peopleList.reduce(
				(obj, person) => ((obj[person._id] = person), obj),
				{}
			);
			setPeopleMap(peopleObj);
		});
	}, [editHistory]);

	const updatedAt = formatDate(lastEdit.updated_at, {
		day: "2-digit",
		month: "short",
		year: "numeric",
		hour: "2-digit",
		minute: "2-digit"
	});

	const createdAt = formatDate(firstEdit.updated_at, {
		day: "2-digit",
		month: "short",
		year: "numeric",
		hour: "2-digit",
		minute: "2-digit"
	});

	async function handleDelete() {
		await deleteSubmission(submission._id);
		setShowDeleteConfirm(false);
		userMetrics.trackEvent("user-overview: remove form submission");
	}

	async function handleLock() {
		await lockSubmission(submission._id);
		setShowLockConfirm(false);
		userMetrics.trackEvent("user-overview: lock form");
	}

	const editedBy = localize("groupforms.edit_by", people[lastEdit.id] || "");
	const createdBy = localize("groupforms.created_by", peopleMap[firstEdit.id] || "");

	const editLocalized = localize("groupforms.edit");
	const deleteLocalized = localize("groupforms.delete");
	const historyLocalized = localize("groupforms.history");
	const lockLocalized = localize("groupforms.lock");
	return (
		<Grid item xs={12}>
			<Paper style={styles.formContainer}>
				{!submission.locked && (
					<Grid container>
						<Grid item xs={8}>
							<StyledText size="largeHeading">{localizeFrom(submission.schema.localization, ".") || ""}</StyledText>
						</Grid>
						<Grid container item justifyContent="flex-end" xs={4}>
							<Button startIcon={<Edit />} aria-label={editLocalized} onClick={() => {
								setShowEditDialog(true);
								userMetrics.trackEvent("user-overview: open edit form submission popup");
							}}>
								{editLocalized}
							</Button>
							<Button startIcon={<Delete />} aria-label={deleteLocalized} onClick={() => {
								setShowDeleteConfirm(true);
								userMetrics.trackEvent("user-overview: open remove form submission popup");
							}}>
								{deleteLocalized}
							</Button>
							<Button startIcon={<History />} aria-label={historyLocalized} onClick={() => setShowHistory(true)}>
								{historyLocalized}
							</Button>
							<Button startIcon={<Lock />} aria-label={lockLocalized} onClick={() => {
                setShowLockConfirm(true);
                userMetrics.trackEvent("user-overview: open lock form popup");
              }}>
								{lockLocalized}
							</Button>
						</Grid>
					</Grid>
				)}
				<div style={styles.title}>
					<StyledText size="small">
						{updatedAt} {editedBy && `- ${editedBy}`}{" "}
					</StyledText>
				</div>
				<ListErrorBoundary>
					<OutputForm schema={submission.schema.schema} data={submission.data} noPdfFullScreen></OutputForm>
					<StyledText>{createdAt} {createdBy && `- ${createdBy}`}</StyledText>
				</ListErrorBoundary>
			</Paper>
			<EditFormDialog
				isOpen={showEditDialog}
				onClose={() => {
					setShowEditDialog(false);
					userMetrics.trackEvent("user-overview: close edit form submission popup");
				}}
				submission={submission}
				submitForm={submitForm}
			/>
			<ConfirmationDialog
				open={showLockConfirm}
				onConfirm={handleLock}
				onCancel={() => {
					setShowLockConfirm(false);
					userMetrics.trackEvent("user-overview: close lock form popup");
				}}
				title={localize("groupform.confirmTitle")}
				confirmText={localize("groupform.yesLock")}
				cancelText={localize("groupform.cancelLock")}
				message={localize("groupform.confirmLock")}
			/>
			<ConfirmationDialog
				open={showDeleteConfirm}
				onConfirm={handleDelete}
				onCancel={() => {
					setShowDeleteConfirm(false);
					userMetrics.trackEvent("user-overview: close remove form submission popup");
				}}
				title={localize("groupform.deleteTitle")}
				confirmText={localize("groupform.yesDelete")}
				cancelText={localize("groupform.cancelDelete")}
				message={localize("groupform.confirmDelete")}
			/>
			<InfoDialog
				open={showHistory}
				onCancel={() => setShowHistory(false)}
				title={localize("groupform.history", )}
				cancelText={localize("groupform.ok")}
				message={HistoryList(editHistory, peopleMap)}
			/>
		</Grid>
	);
}

const SubmissionsList = ({ currentPersonId, person}) => {
	const localize = useLocalize();
	const { userMetrics } = useUserMetrics();
	const [showCreateDialog, setShowCreateDialog] = useState(false);
	const {
		allForms,
		changePage,
		changeRecordsPerPage,
		changeSelectedForms,
		deleteSubmission,
		formsById,
		formSubmissions,
		getFormPrepopulate,
		isLoading,
		lockSubmission,
		page: pageIndex,
		recordsPerPage,
		retrieveSubmissions,
		submitForm,
		total,
		error,
		changeError
	} = useFormSubmissions();

	function updatePage(_, value) {
		changePage(value);
	}

	function updateSelectedForms(value) {
		changeSelectedForms(value);
		userMetrics.trackEvent("user-overview: edit selected forms", {
			forms: value,
		});
	}

	function handleRecordsPerPageChange(event) {
		changeRecordsPerPage(parseInt(event.target.value, 10));
	}

	const PaginationComponent = (
		<Pagination
			total={total}
			page={pageIndex}
			updatePage={updatePage}
			recordsPerPage={recordsPerPage}
			handleRecordsPerPageChange={handleRecordsPerPageChange}
			labelRowsPerPage={localize("groupforms.forms_per_page")}
		/>
	);

	return (
		<Grid container spacing={2}>
			<Grid item xs={4}>
				<Button startIcon={<Add />} onClick={() => {
					userMetrics.trackEvent("user-overview: open create form submission popup");
					setShowCreateDialog(true);
				}}>
					{localize("groupforms.addnew")}
				</Button>
			</Grid>
			<Grid container item justifyContent="space-between" alignItems="flex-end" spacing={2}>
				<FormsFilter
					allForms={allForms}
					formsById={formsById}
					onClose={updateSelectedForms}
					currentPersonId={currentPersonId}
				/>
				{PaginationComponent}
			</Grid>
			{formSubmissions.length === 0 && !isLoading && <NoResults />}
			{isLoading ? (
				<Grid item xs={12} style={styles.centerContent}>
					<SpinningIcon icon={Sync} spinning={true} />
				</Grid>
			) : (
				<>
					{formSubmissions.map(submission => (
						<FormSubmission
							key={submission._id}
							submission={submission}
							deleteSubmission={deleteSubmission}
							lockSubmission={lockSubmission}
							submitForm={async (data) => {
								userMetrics.trackEvent("user-overview: edit form submission");
								return submitForm(data);
							}}
						/>
					))}
					<Grid container item xs={12} justifyContent="flex-end">
						{PaginationComponent}
					</Grid>
				</>
			)}
			<CreateFormDialog
				forms={allForms}
				formsById={formsById}
				isOpen={showCreateDialog}
				submitForm={async (data) => {
					userMetrics.trackEvent("user-overview: create form submission");
					return submitForm(data);
				}}
				person= {person}
				retrieveSubmissions={retrieveSubmissions}
				getFormPrepopulate={getFormPrepopulate}
				onClose={() => {
					setShowCreateDialog(false);
					userMetrics.trackEvent("user-overview: close create form submission popup");
				}}
			/>
			<ErrorToast
				open={!!error}
				handleClose={() => {
					changeError("");
					userMetrics.trackEvent("user-overview: close error toast popup");
				}}
				message={error}
			/>
		</Grid>
	);
};

const HistoryList = (editHistory, peopleMap) => {
	return (
		<List>
			{editHistory && editHistory.map((item, idx) => {
				return <HistoryItem item={item} peopleMap={peopleMap} idx={idx} />;
			})}
		</List>
	);
};

const HistoryItem = ({ item, idx, peopleMap = {} }) => {
	const formatDate = useFormatDate();
	const localize = useLocalize();

	const date = formatDate(item.updated_at, {
		day: "2-digit",
		month: "short",
		year: "numeric",
		hour: "2-digit",
		minute: "2-digit"
	});

	const editedByTransKey = idx === 0 ? "groupforms.created_by" : "groupforms.edit_by";

	const editedBy =
		(item && localize(editedByTransKey, peopleMap[item.id])) ||
		"";

	const message = date + (editedBy && `- ${editedBy}`);
	return <ListItem button key={date}>
		<ListItemText primary={message} />
	</ListItem>;

};

export default function PatientFormPage({ state }) {
	const currentPerson = state.currentPerson;
	const person = state?.person;
	const personal = currentPerson.get("personal") || {};
	const currentPersonId = personal._id;
	const rawFilter = localStorage.getItem(`${currentPersonId}_group_forms_filter`);
	const filter = rawFilter ? JSON.parse(rawFilter) : [];
	const recordsPerPageKey = `${currentPersonId}_group_forms_records_per_page`;

	return (
		<FormSubmissionsProvider userId={state.who} initialFilter={filter} recordsPerPageKey={recordsPerPageKey}>
			<LocalizeFromConsumer>{() => <SubmissionsList currentPersonId={currentPersonId} person={person} />}</LocalizeFromConsumer>
		</FormSubmissionsProvider>
	);
}
