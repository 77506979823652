import React, { useState } from "react";
import { IconButton, Snackbar, SnackbarContent, withStyles } from "@material-ui/1.5.1";
import ErrorIcon from "@material-ui/icons/Error";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";

export const ErrorBarContext = React.createContext({
	showError: () => { },
	closeError: () => { }
});

export const useErrorBar = () => React.useContext(ErrorBarContext);

const ErrorBar = props => {
	const [errorQueue] = useState(() => []);
	const [errorInfo, setErrorInfo] = useState({
		key: "",
		message: ""
	});
	const [showingError, setShowingError] = useState(false);

	const queueError = error => {
		errorQueue.push({
			message: error,
			key: new Date().getTime()
		});

		if (showingError) {
			setShowingError(false);
		} else {
			processQueue();
		}
	};

	const handleClose = () => {
		setShowingError(false);
	};

	const handleExited = () => {
		processQueue();
	};
	const processQueue = () => {
		if (errorQueue.length > 0) {
			setErrorInfo(errorQueue.shift());
			setShowingError(true);
		}
	};

	return (
		<ErrorBarContext.Provider
			value={{
				showError: queueError,
				closeError: handleClose
			}}
		>
			<Snackbar
				anchorOrigin={{
					vertical: "top",
					horizontal: "center"
				}}
				key={errorInfo.key}
				// autoHideDuration={3000}
				open={showingError}
				onExited={handleExited}
				onClose={handleClose}
			>
				<ErrorSnackBarContentWrapper message={errorInfo.message} onClose={handleClose} />
			</Snackbar>
			{props.children}
		</ErrorBarContext.Provider>
	);
};

function ErrorSnackBarContent(props) {
	const { classes, className, message, onClose, ...other } = props;
	const Icon = ErrorIcon;

	return (
		<SnackbarContent
			className={clsx(classes.error, className)}
			aria-describedby='client-snackbar'
			message={
				<h2 id='client-snackbar' className={classes.message}>
					<Icon className={clsx(classes.icon, classes.iconVariant)} />
					{message}
				</h2>
			}
			action={[
				<IconButton key='close' aria-label='Close' color='inherit' className={classes.close} onClick={onClose}>
					<CloseIcon className={classes.icon} />
				</IconButton>
			]}
			{...other}
		/>
	);
}

const errorContentStyles = theme => ({
	error: {
		backgroundColor: theme.palette.error.dark
	},
	icon: {
		fontSize: 20
	},
	iconVariant: {
		opacity: 0.9,
		marginRight: theme.spacing.unit
	},
	message: {
		display: "flex",
		alignItems: "center",
		margin: 0
	}
});
export const ErrorSnackBarContentWrapper = withStyles(errorContentStyles)(ErrorSnackBarContent);
export default ErrorBar;
