/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, May 3, 2018
 * For information or permission request, email info@aetonixsystems.com
 */


import "./render.css";

import React from "react";
import config from "../../configs/config.json";
import par from "par";
import clsx from "clsx";
import Header from "../shared/Header.js";
import  AetonixTheme_mui_1_5_1 from "../shared/AetonixTheme_mui_1.5.1";
const Colors = AetonixTheme_mui_1_5_1.palette;

import TextField from "@material-ui/1.5.1/TextField";
import Button from "@material-ui/1.5.1/Button";
import List from "@material-ui/1.5.1/List";
import ListItem from "@material-ui/1.5.1/ListItem";
import ListItemText from "@material-ui/1.5.1/ListItemText";
import ListItemIcon from "@material-ui/1.5.1/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/1.5.1/ListItemSecondaryAction";
import LockIcon from "@material-ui/icons/Lock";
import LockOpen from "@material-ui/icons/LockOpen";
import NoEncryption from "@material-ui/icons/NoEncryption";
import BugReport from "@material-ui/icons/BugReport";
import Avatar from "@material-ui/1.5.1/Avatar";
import Switch from "@material-ui/1.5.1/Switch";
import Paper from "@material-ui/1.5.1/Paper";
import FormControl from "@material-ui/1.5.1/FormControl";
import FormControlLabel from "@material-ui/1.5.1/FormControlLabel";
import InputLabel from "@material-ui/1.5.1/InputLabel";
import Select from "@material-ui/1.5.1/Select";
import MenuItem from "@material-ui/1.5.1/MenuItem";
import Snackbar from "@material-ui/1.5.1/Snackbar";
import Checkbox from "@material-ui/1.5.1/Checkbox";
import FontIcon from "@material-ui/1.5.1/Icon";
import IconButton from "@material-ui/1.5.1/IconButton";
import Divider from "@material-ui/1.5.1/Divider";
import Utility from "../shared/Utility";
import Dialog from "../shared/Dialog";
import SearchMenu from "../shared/SearchMenu.jsx";
import { GlobalAlertContext } from "../shared/GlobalAlert";

import * as Sentry from "@sentry/react";

var styles = {
	icon: {
		fontSize: 20,
		color: Colors.canvas
	},
	avatar: {
		backgroundColor: Colors.primary.dark
	},
	back: {
		backgroundColor: Colors.primary.light,
		color: Colors.primary.main
	},
	backPrimary: {
		backgroundColor: "#E0E0E0"
	},
	avatarPrimary: {
		backgroundColor: "#BCBCBC"
	},
	inputProp: {
		style: {
			color: Colors.primary.main,
		}
	},
	avatarContainer: {
		alignSelf: "stretch",
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	info: {
		alignSelf: "stretch",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		flexDirection: "column",
		margin: "8px 0",
		gap: 2
	},
	nameInfo: {
		alignSelf: "stretch",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		flexDirection: "column",
		margin: "8px 0",
		gap: 3
	},
	name: {
		fontSize: "1.2em",
		color: Colors.primary.main
	},
	secondaryName: {
		fontSize: "1.0em",
		color: "#000000",
		userSelect: "text"
	},
	text: {
		fontSize: "0.8em",
		color: "#9f9f9f"
	},
	input: {
		color: "grey",
		marginTop: "8px",
		marginBottom: "8px"
	},
	propertyContainer: {
		flexDirection: "row",
		display: "flex",
		padding: "5px 10px",
		justifyContent: "space-between"
	},
	propertyField: {
		color: Colors.primary.main
	},
	propertyValue: {
		color: "black",
		paddingLeft: 5,
		userSelect: "text"
	},
	marginTop: { marginTop: 5 },
	marginRight: { marginRight: 5 },
	leftPadded: { paddingLeft: 10 },
	rightPadded: {paddingRight: 32 },
	userPaper: { marginTop: 5, width: "100%", padding: 10 },
	// userPaper: {maxWidth: 320, width: "100%", margin: "auto", paddingTop: 16},
	pageContainer: { display: "flex", flexDirection: "column" },
	personalContainer: { marginLeft: 15, display: "flex", flexGrow: 1, borderLeft: "1px solid rgba(0, 0, 0, 0.1)", padding: "60px 0px" },
	avatarBox: { display: "flex", flex: 1 },
	formControlStyle: {
		marginTop: "8px",
		marginBottom: "8px"
	},
	toast: {
		margin: "100px",
		padding: "10px",
		backgroundColor: "rgb(0, 172, 193)", //rgb(0, 199, 26)
		borderRadius: "10px"
	},
	toastText: {
		color: Colors.canvas,
		margin: "5px"
	},
	snackbar: {style: {backgroundColor: "red"}}
};

export default render;

var DISPLAY_PROPERTIES = [
	"fname",
	"lname",
	"dob",
	"type",
	"_id",
	"email",
	"language",
	"phone",
	"street",
	"postal",
	"city",
	"province",
	"country",
	"glucometerUnits",
	"weightUnits",
	"temperatureUnits",
	"inactive",
	"searchHidden",
	"created_at"
];

var DISPLAY_FIELD = {
	"fname": "First Name",
	"lname": "Last Name",
	"dob": "Date Of Birth",
	"type": "User Type",
	"email": "Email",
	"language": "Language",
	"phone": "Phone Number",
	"street": "Address",
	"postal": "Postal Code",
	"city": "City",
	"province": "Province / State / Region",
	"country": "Country",
	"glucometerUnits": "Unit of Glucometer",
	"weightUnits": "Unit of Weight",
	"temperatureUnits": "Unit of Temperature",
	"inactive": "User Active Status",
	"created_at": `Created At (TZ: ${Intl.DateTimeFormat().resolvedOptions().timeZone})`,
	"searchHidden": "Search Privacy",
	"_id": "User ID",
};

var EDIT_PROPERTIES = [
	"fname",
	"lname",
	"type",
	"language",
	"glucometerUnits",
	"weightUnits",
	"temperatureUnits",
	"searchHidden"
];

var DELETE_CONFIRM_FIELD = {
	"type": "User Type",
	"org": "Organization",
	"orgGroup": "Group"
};

function render() {
	var component = this;
	var state = component.state;
	var localization = state.localization;
	var careplanNoticeList = state.careplanChangeList.all();
	var careplanChanges = !!careplanNoticeList.length;
	var offline = state.connection.get("offline");
	var people = state.people;
	var currentPerson = state.currentPerson;
	var titleKey = "admin_user_management_title";
	var errorToast = state.errorToast;
	var openToast = state.openToast;
	var toastMessage = state.toastMessage;

	return (
		<div className="flex-vertical flex-1">
			<Header
				careplanChanges={careplanChanges}
				offline={offline}
				currentPerson={currentPerson}
				localization={localization}
				titleKey={titleKey}
			/>
			<div className="flex-vertical flex-1 ae-padded">
				<div className="flex-vertical">
					<SearchMenu
						action={par(handleSelected, component)}
						ref="searchDialog"
						org={state.org}
						orgGroup={state.orgGroup}
						search={state.search}
						people={people}
						localization={localization}
					/>
				</div>
				{state.selectedUserId && renderInfo(component, state.selectedUserId)}
			</div>
			<Snackbar open={openToast} autoHideDuration={3500} onClose={par(hideToast, component)}>
				<div className="flex-horizontal ae-dropshadow" style={styles.toast}>
					<FontIcon className="fa fa-check fa-2x" style={styles.icon} />
					<div style={styles.toastText}>
						{toastMessage}
					</div>
				</div>
			</Snackbar>
			<Snackbar
				ContentProps={styles.snackbar}
				open={errorToast}
				autoHideDuration={3500}
				onClose={par(hideToast, component)}
				message={errorToast}
				action={<IconButton className="fa fa-times" onClick={par(hideToast, component)}></IconButton>}
			/>
		</div>
	);
}

function hideToast(component) {
	component.setState({
		errorToast: false,
		openToast: false,
		toastMessage: ""
	});
}

function handleSelected(component, person) {
	component.setState({
		selectedUserId: person._id
	});
	component.state.userMetrics.trackEvent("admin-user-management: select user", {
		patient: person._id,
	});
}

function renderInfo(component, selectedUserId) {
	var state = component.state;
	var org = state.org;
	var orgGroup = state.orgGroup;
	var person = state.people.get(selectedUserId);
	var localization = state.localization;

	var orgData = org.get(person.organization) || {};
	var orgName = orgData.name;

	var groupData = orgGroup.get(person.group) || {};
	var groupName = groupData.name;

	var orgGroupData = orgGroup.get(person.orgGroup) || {};
	var orgGroupName = orgGroupData.name;

	var orgDescription = [];
	if (orgName && person.organization) orgDescription.push(orgName);
	if (groupName && person.group) orgDescription.push(groupName);
	else if (orgGroupName && person.orgGroup) orgDescription.push(orgGroupName);

	var handleLockChecked = (e) => {
		var checked = e.target.checked;
		component.state.userMetrics.trackEvent(`admin-user-management: ${checked ? "lock" : "unlock"} user`, {
			patient: person._id,
		});

		if (checked)
			state.lockApp(component, selectedUserId);
		else
			state.unlockApp(component, selectedUserId);
	};

	var isPatient = isOrgGroupUser(person);
	var hasCustomProperties = (state.customProperties.get(person.orgGroup) || []).length > 0;

	// eslint-disable-next-line no-unused-vars
	const handleTestModeChecked = (e) => {
		var checked = e.target.checked;
		component.state.userMetrics.trackEvent(`admin-user-management: ${checked ? "enable" : "disable"} test mode`, {
			patient: person._id,
		});
		if (checked) state.enableTestMode(selectedUserId);
		else state.disableTestMode(selectedUserId);
	};

	return (
		<div style={styles.pageContainer}>
			<Paper style={styles.userPaper}>
				<div className="flex-horizontal">
					<div style={styles.avatarBox} className="flex-vertical">
						<div style={styles.avatarContainer}>
							<Avatar
								className={clsx(
									"ae-avatar-big",
									"avatar-border",
									state.connectivity.get(selectedUserId) ? "avatar-available" : "avatar-unavailable"
								)}
								alt="avatar image"
								src={config.image_cdn + person.image}
							/>
						</div>
						<div style={styles.nameInfo}>
							<div style={styles.name}>{Utility.format_name(person)}</div>
							{orgDescription.length ? <div style={styles.secondaryName}>{orgDescription.join(" - ")}</div> : null}
						</div>
						{isPatient && (
							<List>
								<ListItem>
									<ListItemIcon style={styles.marginRight}>{getLockIcon(person.applocked)}</ListItemIcon>
									<ListItemText style={styles.rightPadded} primary="Lock" />
									<ListItemSecondaryAction>
										<Switch
											onChange={handleLockChecked}
											inputProps={{ "aria-label": localization.get("groupforms.lock"), role: "switch" }}
											key={`AppLocked_${person.applocked}`}
											checked={!!person.applocked}
										/>
									</ListItemSecondaryAction>
								</ListItem>
								<ListItem>
									<Button
										variant="raised"
										fullWidth
										color="primary"
										onClick={par(state.openSettings, component, selectedUserId)}
									>
										{"Open Android Settings"}
									</Button>
								</ListItem>
								<ListItem>
									<ListItemIcon style={styles.marginRight}>
										<BugReport></BugReport>
									</ListItemIcon>
									<ListItemText style={styles.rightPadded} primary={localization.get("testmode.title")} />
									<GlobalAlertContext.Consumer>
										{ga => (
											<ListItemSecondaryAction>
												<Switch
													onChange={e => {
														var checked = e.target.checked;

														if (checked) {
															state.enableTestMode(selectedUserId);
															state.userMetrics.trackEvent("admin-user-management: enable test mode", {
																patient: person._id,
															});
														} else {
															ga.show({
																affirmativeText: localization.get("common.ok"),
																negativeText: localization.get("common.cancel"),
																affirmativeAction: () => {
																	state.userMetrics.trackEvent("admin-user-management: disable test mode and close confirmation popup", {
																		patient: person._id,
																	});
																	return state.disableTestMode(selectedUserId);
																},
																negativeAction: () => {
																	state.userMetrics.trackEvent("admin-user-management: close disable test mode confirmation popup", {
																		patient: person._id,
																	});
																},
																title: localization.get("testmode.title"),
																description: localization.get("testmode.description")
															});
															state.userMetrics.trackEvent("admin-user-management: open disable test mode confirmation popup", {
																patient: person._id,
															});
														}
													}}
													checked={!!person.testModeEnabled}
													inputProps={{ "aria-label": localization.get("testmode.title"), role: "switch" }}
												/>
											</ListItemSecondaryAction>
										)}
									</GlobalAlertContext.Consumer>
								</ListItem>
							</List>
						)}
						<Button
							variant="raised"
							fullWidth
							onClick={par(openDeleteUserDialog, component)}
							style={{ marginBottom: 10 }}
						>
							{"Delete Permanently"}
						</Button>
						{!!person.email && !person.type.includes("login:none") && (
							<Button variant="raised" fullWidth onClick={par(openPasswordResetDialog, component)}>
								{"Reset Password"}
							</Button>
						)}
						{renderPasswordResetDialog(component, person)}
						{renderDeleteUserDialog(component, person)}
						{renderDeleteUserWarningDialog(component, person)}
						{renderPasswordResetWarningDialog(component, person)}
						{renderTokenDialog(component)}
					</div>
					<div style={styles.personalContainer}>
						<div className="personal-info-container">
							<div className="personal-info-header">{"Personal Information"}</div>
							<div className="grid-controls">
								<div className="flex-horizontal flex-spread">
									<Button
										variant="raised"
										onClick={() => {
											state.getControlToken(component, state.selectedUserId);
											setVisibilityTokenDialog(component, true);
											state.userMetrics.trackEvent("admin-user-management: open view token popup", {
												patient: person._id,
											});
										}}
										style={{ marginRight: 10 }}
									>
										{localization.get("users_token_title")}
									</Button>
									<Button variant="raised" onClick={par(openEditPersonalInfoDialog, component)}>
										EDIT
									</Button>
								</div>
							</div>
							{renderPersonalInfo(component, person)}
							{renderEditPersonalInfoDialog(component, person)}
						</div>
					</div>
				</div>
			</Paper>

			{isPatient && hasCustomProperties && (
				<Paper style={styles.userPaper}>
					<div className="personal-info-container">
						<div className="personal-info-header">{"Custom Properties"}</div>
						<div className="grid-controls">
							<div className="flex-horizontal flex-spread">
								<Button variant="raised" onClick={par(openEditCustomPropertiesDialog, component)}>
									EDIT
								</Button>
							</div>
						</div>
						{renderCustomProperties(component, person)}
						{renderEditCustomPropertiesDialog(component, person)}
					</div>
				</Paper>
			)}
		</div>
	);
}

function getLockIcon(applocker) {
	if (applocker === undefined || applocker === null)
		return (<NoEncryption />);
	else if (applocker)
		return (<LockIcon />);
	else
		return (<LockOpen />);
}

function renderPersonalInfo(component, person) {
	return DISPLAY_PROPERTIES.map(property => {
		var value = person?.[property];
		var display = DISPLAY_FIELD[property];

		if (typeof value === "boolean") {
			if (property === "inactive") value = value ? "Inactive" : "Active";
			else if (property === "searchHidden") value = value ? "Hidden from search" : "Visible in search";
		}

		if (property === "dob") {
			value = value ? new Date(value).toDateString() : "";
		}

		if (property === "created_at") {
			value = new Date(value).toString();
		}

		if (property === "language") {
			value = value ? component.state.localization.get(value) : "";
		}

		if (property === "type") {
			value = getUserType(person);
		}

		if (property === "country" || property.endsWith("Units")) {
			value = capitalizeFirstLetter(value);
		}

		return (
			<div style={styles.propertyContainer}>
				<div style={styles.propertyField}>{display + ":"}</div>
				<div style={styles.propertyValue}>{value}</div>
			</div>
		);
	});
}

function getEditPersonalInfoDefaultValues(component, person) {
	return EDIT_PROPERTIES.reduce((editObject, property) => {
		var value = person?.[property];

		if (property === "searchHidden") {
			value = value ? "Hidden from search" : "Visible in search";
		}

		if (property === "language") {
			value = value ? component.state.localization.get(value) : "";
		}

		if (property === "type") {
			value = getUserType(person);
		}

		if (property.endsWith("Units")) {
			value = capitalizeFirstLetter(value);
		}

		editObject[property] = value;
		return editObject;
	}, {});
}

function renderCustomProperties(component, person) {
	var state = component.state;
	var admin = state.currentPerson.get("personal");
	var language = admin.language;
	var orgGroupCustomProperties = state.customProperties.get(person.orgGroup) || [];
	var personCustomProperties = person.customProperties;

	var renderProperties = orgGroupCustomProperties.map(property => {
		var localization = property.localization || {};
		var display = localization[language] || localization[Object.keys(localization)[0]] || "Unknown";
		var defaultValue = personCustomProperties?.[property.key] || "";

		return (
			<div style={styles.propertyContainer}>
				<div style={styles.propertyField}>{display + ":"}</div>
				<div style={styles.propertyValue}>{defaultValue}</div>
			</div>
		);
	});

	return renderProperties;
}

function renderEditCustomPropertiesDialog(component, person) {
	var state = component.state;
	var open = state.isEditingCustomProperties;
	var title = "Edit Custom Properties";

	return renderEditDialog(component, person, open, title, rederEditCustomProperties, updateCustomProperties, hideEditCustomPropertiesDialog);
}

function rederEditCustomProperties(component, person) {
	var state = component.state;
	var admin = state.currentPerson.get("personal");
	var language = admin.language;
	var orgGroupCustomProperties = state.customProperties.get(person.orgGroup) || [];
	var personCustomProperties = person.customProperties || {};
	var defaultValues = orgGroupCustomProperties.reduce((defaultValuesObject, customProperty) => {
		var customPropertyKey = customProperty.key;
		defaultValuesObject[customPropertyKey] = personCustomProperties[customPropertyKey] || "";
		return defaultValuesObject;
	}, {});

	var updateHandlers = orgGroupCustomProperties.reduce((handlersObject, customProperty) => {
		var customPropertyKey = customProperty.key;
		handlersObject[`update_new_${customPropertyKey}`] = par(updateObjectActionChanged, component, "new_customProperties", `${customPropertyKey}`);
		return handlersObject;
	}, {});

	var textFields = orgGroupCustomProperties.map(customProperty => {
		var customPropertykey = customProperty.key;
		var customPropertyLocalization = customProperty.localization;
		return 	<TextField
				key={"edit_customProperties" + customPropertykey}
				fullWidth
				defaultValue={defaultValues[customPropertykey]}
				inputProps={{"aria-label": customPropertyLocalization[language]}}
				onChange={updateHandlers[`update_new_${customPropertykey}`]}
				label={customPropertyLocalization[language]} style={styles.input}
				InputProps={styles.inputProp}
			/>;
	});

	return (
		<div>
			{textFields}
		</div>
	);
}

function updateCustomProperties(component, person) {
	var state = component.state;
	var customPropertiesToUpdate = state.new_customProperties;
	if (!customPropertiesToUpdate) {
		hideEditCustomPropertiesDialog(component);
		return;
	}

	var orgGroupCustomProperties = state.customProperties.get(person.orgGroup) || [];
	var personCustomProperties = person.customProperties || {};
	var doUpdate = false;
	var newCustomProperties = {};

	for (var customProperty of orgGroupCustomProperties) {
		var customPropertyKey = customProperty.key;
		var originalCustomProperty = personCustomProperties[customPropertyKey];
		var customPropertyToUpdate = customPropertiesToUpdate[customPropertyKey];
		newCustomProperties[customPropertyKey] = originalCustomProperty;
		if (!customPropertyToUpdate) {
			if (customPropertyToUpdate === "") {
				delete newCustomProperties[customPropertyKey];
				doUpdate = true;
			}
		} else if (customPropertyToUpdate != originalCustomProperty) {
			doUpdate = true;
			newCustomProperties[customPropertyKey] = customPropertyToUpdate;
		}
	}

	var userId = person._id;
	if (doUpdate) {
		state.updateUserCustomProperties(component, userId, newCustomProperties)
			.catch(error => {
				Sentry.captureException(error);
				console.error.bind(console, error);
			});
	}

	component.state.userMetrics.trackEvent("admin-user-management: submit custom properties", {
		"changes": doUpdate,
		"patient": userId,
		"properties": newCustomProperties
	});

	hideEditCustomPropertiesDialog(component);
}


function getUserType(person) {
	var type = person.type;

	if (type.indexOf("admin") !== -1)
		return "Admin";
	else if (type.indexOf("org:manager") !== -1)
		return "Org Manager";
	else if (type.indexOf("staff") !== -1)
		return "Staff";
	else if (type.indexOf("user:mobile") !== -1)
		return "Mobile / Bring Your Own Device";
	else if (type.indexOf("atouchaway") !== -1)
		return "aTouchAway / Simplified";
	else if (type.indexOf("simplified") !== -1)
		return "Basic Remote Patient Monitoring";
	else
		return "Regular User (not in any organization/group)";
}

function getOrgGroupUserTypeValue(person) {
	var type = person.type;

	if (type.indexOf("user:mobile") !== -1)
		return "user:mobile";
	else if (type.indexOf("atouchaway") !== -1)
		return "atouchaway";
	else if (type.indexOf("simplified") !== -1)
		return "simplified";
}

function isOrgGroupUser(person) {
	var dashboardUserTypes = ["Admin", "Org Manager", "Staff"];
	if (dashboardUserTypes.indexOf(getUserType(person)) !== -1) return false;
	var userType = getOrgGroupUserTypeValue(person);
	var orgGroupUsersArray = [
		"user:mobile",
		"atouchaway",
		"simplified"
	];
	if (orgGroupUsersArray.indexOf(userType) !== -1) return true;
	return false;
}

function renderEditDialog(component, person, open, title, renderFunction, updateHandler, cancelHandler) {
	var state = component.state;
	var confirmUpdateActions = [(
		<Button key={"buttoncancel"} onClick={par(cancelHandler, component)}>Cancel</Button >
	), (
		<Button key={"buttonupdate"} disabled={(state.new_fname === null || state.new_lname === null) ? true : false} onClick={par(updateHandler, component, person)}>Update</Button >
	)];
	return (
		<Dialog actions={confirmUpdateActions} open={!!open} title={title}>
			{renderFunction(component, person)}
		</Dialog>
	);
}

function renderEditPersonalInfoDialog(component, person) {
	var state = component.state;
	var open = state.isEditingPersonalInfo;
	var title = "Edit Personal Information";

	return renderEditDialog(component, person, open, title, renderEditPersonalInfo, updatePersonalInfo, hideEditPersonalInfoDialog);
}

function renderEditPersonalInfo(component, person) {
	var defaultValues = getEditPersonalInfoDefaultValues(component, person);
	var state = component.state;
	var localization = component.state.localization;
	var updateHandlers = EDIT_PROPERTIES.reduce((handlersObject, field) => {
		handlersObject[`update_new_${field}`] = par(updateActionChanged, component, `new_${field}`);
		return handlersObject;
	}, {});
	var isOrgGroup = isOrgGroupUser(person);
	var textFields = [].concat(EDIT_PROPERTIES.map(field => {
		var fieldValue = state[`new_${field}`] ? state[`new_${field}`] : person[field];
		if (field === "type") {
			if (isOrgGroup) {
				var changeUserTypeValue = state["new_type"] ? state["new_type"] : getOrgGroupUserTypeValue(person);
				return (
					<FormControl fullWidth={true} style={styles.formControlStyle}>
						<InputLabel>{DISPLAY_FIELD[field]}</InputLabel>
						<Select className={"ae-useredit"} inputProps={{"aria-label": localization.get("user_type_select") }} onChange={updateHandlers[`update_new_${field}`]} value={changeUserTypeValue}>
							<MenuItem key={"type_atouchaway"} value={"atouchaway"}>aTouchAway / Simplified</MenuItem>
							<MenuItem key={"type_mobile"} value={"user:mobile"}>Mobile / Bring Your Own Device</MenuItem>
							<MenuItem key={"type_simplified"} value={"simplified"}>Basic Remote Patient Monitoring</MenuItem>
						</Select>
					</FormControl>);
			} else {
				return null;
			}
		} else if (field === "language") {
			return (
				<FormControl fullWidth={true} style={styles.formControlStyle}>
					<InputLabel>{DISPLAY_FIELD[field]}</InputLabel>
					<Select className={"ae-useredit"} inputProps={{"aria-label": localization.get("org_resources_language")}} onChange={updateHandlers[`update_new_${field}`]} value={fieldValue}>
						<MenuItem key={"language_en"} value={"en"}>English</MenuItem>
						<MenuItem key={"language_fr"} value={"fr"}>French (Français)</MenuItem>
						<MenuItem key={"language_cn_s"} value={"cn_s"}>Chinese Simplified ( 中文（简）)</MenuItem>
						<MenuItem key={"language_cn_t"} value={"cn_t"}>Chinese Traditional (中文（繁）)</MenuItem>
						<MenuItem key={"language_es"} value={"es"}>Spanish (Español)</MenuItem>
						<MenuItem key={"language_pt"} value={"pt"}>Portuguese (Portugués)</MenuItem>
						<MenuItem key={"language_ta"} value={"ta"}>Tamil (தமிழ்)</MenuItem>
					</Select>
				</FormControl>);
		} else if (field === "glucometerUnits") {
			return (
				<FormControl fullWidth={true} style={styles.formControlStyle}>
					<InputLabel>{DISPLAY_FIELD[field]}</InputLabel>
					<Select className={"ae-useredit"} inputProps={{"aria-label": localization.get("unit_glucometer")}} onChange={updateHandlers[`update_new_${field}`]} value={fieldValue}>
						<MenuItem key={"glucometerUnits_mmol"} value={"mmol"}>mmol/L</MenuItem>
						<MenuItem key={"glucometerUnits_mg"} value={"mg"}>mg/dL</MenuItem>
					</Select>
				</FormControl>);
		} else if (field === "weightUnits") {
			return (
				<FormControl fullWidth={true} style={styles.formControlStyle}>
					<InputLabel>{DISPLAY_FIELD[field]}</InputLabel>
					<Select className={"ae-useredit"} inputProps={{"aria-label": localization.get("unit_weight")}} onChange={updateHandlers[`update_new_${field}`]} value={fieldValue}>
						<MenuItem key={"weightUnits_kg"} value={"kg"}>kg</MenuItem>
						<MenuItem key={"weightUnits_lbs"} value={"lbs"}>lbs</MenuItem>
					</Select>
				</FormControl>);
		} else if (field === "temperatureUnits") {
			return (
				<FormControl fullWidth={true} style={styles.formControlStyle}>
					<InputLabel>{DISPLAY_FIELD[field]}</InputLabel>
					<Select className={"ae-useredit"} inputProps={{"aria-label": localization.get("unit_temperature")}} onChange={updateHandlers[`update_new_${field}`]} value={fieldValue}>
						<MenuItem key={"glucometerUnits_celsius"} value={"celsius"}>C</MenuItem>
						<MenuItem key={"glucometerUnits_fahrenheit"} value={"fahrenheit"}>F</MenuItem>
					</Select>
				</FormControl>);
		} else if (field === "searchHidden") {
			var originalSearchHidden = state[`new_${field}`] === null ? person[field] : state[`new_${field}`];
			return (
				<FormControl fullWidth={true} style={styles.formControlStyle}>
					<InputLabel>{DISPLAY_FIELD[field]}</InputLabel>
					<Select className={"ae-useredit"} inputProps={{"aria-label": localization.get("private_title") }} onChange={updateHandlers[`update_new_${field}`]} value={originalSearchHidden}>
						<MenuItem key={"searchHidden_false"} value={false}>Visible in search</MenuItem>
						<MenuItem key={"searchHidden_true"} value={true}>Hidden from search</MenuItem>
					</Select>
				</FormControl>);
		} else {
			return 	<TextField key={"edit_" + field} fullWidth defaultValue={defaultValues[field]} onChange={updateHandlers[`update_new_${field}`]} error={state[`new_${field}`] === null  ? true : false} helperText={state[`new_${field}`] === null  ? `${field === "fname" ? localization.get("errors.group_users.no_newFName") : field === "lname" ? localization.get("errors.group_users.no_newLName") : null} ` : null} inputProps={{"aria-label": (field === "fname") ? localization.get("users_fname") : localization.get("users_lname") }} label={DISPLAY_FIELD[field]} style={styles.input} InputProps={styles.inputProp} />;
		}
	}));

	return (
		<div>
			{textFields}
		</div>
	);
}

function updatePersonalInfo(component, person) {
	var state = component.state;
	var userId = person._id;
	var originalData = EDIT_PROPERTIES.reduce((dataObject, field) => {
		if (field === "type") dataObject[field] = getOrgGroupUserTypeValue(person);
		else dataObject[field] = person[field];
		return dataObject;
	}, {});
	if (!isOrgGroupUser(person)) delete originalData["type"];
	var doUpdate = false;
	var updateUserData = false;
	var doUpdateUserType = false;
	var newUserData = {};
	for (var field in originalData) {
		if (field === "searchHidden") {
			if (state[`new_${field}`] !== null && originalData[field] !== state[`new_${field}`]) {
				newUserData[field] = state[`new_${field}`];
				doUpdate = true;
				updateUserData = true;
			}
		} else if (state[`new_${field}`] && originalData[field] !== state[`new_${field}`]) {
			newUserData[field] = state[`new_${field}`];
			doUpdate = true;
			if (field === "type") doUpdateUserType = true;
			if (field !== "type") updateUserData = true;
		}
	}
	if (doUpdate) {
		newUserData.fname = newUserData.fname ? newUserData.fname : originalData.fname;
		newUserData.lname = newUserData.lname ? newUserData.lname : originalData.lname;

		state.updateUserInfo(component, userId, newUserData, updateUserData, doUpdateUserType)
			.catch(error => {
				Sentry.captureException(error);
				console.error.bind(console);
			});
	}
	component.state.userMetrics.trackEvent("admin-user-management: submit personal info", {
		"changes": doUpdate,
		"patient": userId,
		"properties": newUserData
	});
	hideEditPersonalInfoDialog(component);
}
function renderPasswordResetDialog(component, person){
	var state = component.state;
	var isResetingPassword = state.isResetingPassword;
	var gotEmailConfirm_PWR = state.gotEmailConfirm_PWR;
	var passwordResetWarning = "Warning! Are you sure you want to change this users password to a temporary one?";
	var confirmInfo = renderConfirmInfo(component, person, passwordResetWarning);
	var passwordResetActions = [(<Button key={"passwordcancel"} onClick={par(hidePasswordResetDialog, component)}>Cancel</Button>)];
	var isFnameConfirmed = state["doubleCheck_fname"] === person.fname;
	var isLnameConfirmed = state["doubleCheck_lname"] === person.lname;
	var userTypeValue = getOrgGroupUserTypeValue(person);
	var isMobileUserRegistered = person.type.indexOf("login:none") === -1;
	var confirmEmail = userTypeValue !== "atouchaway" && userTypeValue !== "simplified" && isMobileUserRegistered;
	var isEmailConfirmed = confirmEmail ? state["doubleCheck_email"] === person.email : true;
	var isAllConfirmed = isFnameConfirmed && isLnameConfirmed && isEmailConfirmed;
	if (isAllConfirmed) {
		passwordResetActions.push(<Button key={"buttonproceed"} onClick={par(openPasswordResetWarningDialog, component)}>Proceed to reset</Button >);
	}

	var checkGotEmailConfirm = (
		<FormControlLabel
			control={
				<Checkbox
					checked={gotEmailConfirm_PWR}
					onChange={par(updateActionChecked, component, "gotEmailConfirm_PWR")}
				/>
			}
			label={`Did you get the email confirmation from the user or its organization to change ${person.lname}, ${person.fname}'s password to a new temporary password?`}
		/>
	);

	var doubleCheckInfo = renderDeleteUserDoubleCheckInfo(component, person);
	return(
		<Dialog actions={passwordResetActions} open={!!isResetingPassword} title={`Create and set a new password for ${person.lname}, ${person.fname}`}>
			{confirmInfo}
			{checkGotEmailConfirm}
			{gotEmailConfirm_PWR && doubleCheckInfo}
		</Dialog>
	);
}

function renderDeleteUserDialog(component, person) {
	var state = component.state;
	var gotEmailConfirm = state.gotEmailConfirm;
	var isAdmin = person.type.indexOf("admin") !== -1;
	var deleteUserWarning = "Warning! Are you sure you want to delete this user permanently?";
	var confirmInfo = renderConfirmInfo(component, person, deleteUserWarning);
	var confirmUpdateActions = [(
		<Button key={"buttoncancel"} onClick={par(hideDeleteUserDialog, component)}>Cancel</Button >
	)];
	var isFnameConfirmed = state["doubleCheck_fname"] === person.fname;
	var isLnameConfirmed = state["doubleCheck_lname"] === person.lname;
	var userTypeValue = getOrgGroupUserTypeValue(person);
	var isMobileUserRegistered = person.type.indexOf("login:none") === -1;
	var confirmEmail = userTypeValue !== "atouchaway" && userTypeValue !== "simplified" && isMobileUserRegistered;
	var isEmailConfirmed = confirmEmail ? state["doubleCheck_email"] === person.email : true;
	var isAllConfirmed = isFnameConfirmed && isLnameConfirmed && isEmailConfirmed;
	if (isAllConfirmed) {
		confirmUpdateActions.push(<Button key={"buttonproceed"} onClick={par(openDeleteUserWarningDialog, component)}>Proceed to delete</Button >);
	}
	var checkGotEmailConfirm = (
		<FormControlLabel
			control={
				<Checkbox
					checked={gotEmailConfirm}
					onChange={par(updateActionChecked, component, "gotEmailConfirm")}
				/>
			}
			label={"Did you get the email confirmation from the user or its organization to delete this user?"}
		/>
	);
	var doubleCheckInfo = renderDeleteUserDoubleCheckInfo(component, person);
	if (isAdmin) {
		confirmInfo = "Cannot delete Admin user!";
		confirmUpdateActions = [(
			<Button key={"buttoncancel"} onClick={par(hideDeleteUserDialog, component)}>Cancel</Button >
		)];
		checkGotEmailConfirm = null;
		doubleCheckInfo = null;
	}

	return (
		<Dialog actions={confirmUpdateActions} open={!!state.isDeletingUser} title={`Delete ${person.lname}, ${person.fname} permanently`}>
			{confirmInfo}
			{checkGotEmailConfirm}
			{gotEmailConfirm && doubleCheckInfo}
		</Dialog>
	);
}

function renderConfirmInfo(component, person, warning) {
	var state = component.state;
	var orgData = state.org.get(person.organization) || {};
	var orgName = orgData.name;
	var gropuData = state.orgGroup.get(person.group) || {};
	var groupName = gropuData.name;
	var orgGroupData = state.orgGroup.get(person.orgGroup) || {};
	var orgGroupName = orgGroupData.name;
	var confirmInfo = Object.keys(DELETE_CONFIRM_FIELD).map(property => {
		var display = DELETE_CONFIRM_FIELD[property];
		var value = person?.[property];

		if (property === "type") {
			value = getUserType(person);
		}

		if (property === "org") {
			value = orgName ? orgName : "Not belong to any organization";
		}

		if (property === "orgGroup") {
			value = groupName ? groupName : (orgGroupName ? orgGroupName : "Not belong to any group");
		}

		return (
			<div style={styles.propertyContainer}>
				<div style={styles.propertyField}>{display + ":"}</div>
				<div style={styles.propertyValue}>{value}</div>
			</div>
		);
	});
	return (
		<div>
			{warning}
			<Divider />
			{confirmInfo}
			<Divider />
		</div>
	);
}

function renderDeleteUserDoubleCheckInfo(component, person) {
	var doubleCheckFields = ["fname", "lname"];
	var userTypeValue = getOrgGroupUserTypeValue(person);
	var isMobileUserRegistered = person.type.indexOf("login:none") === -1;
	if (userTypeValue !== "atouchaway" && userTypeValue !== "simplified" && isMobileUserRegistered) doubleCheckFields.push("email");
	var renderDoubleCheckFields = doubleCheckFields.map(field => {
		return <TextField key={"doubleCheck" + field} fullWidth onChange={par(updateActionChanged, component, `doubleCheck_${field}`)} label={DISPLAY_FIELD[field]} style={styles.input} InputProps={styles.inputProp} />;
	});
	return (
		<div>
			<Divider />
			{"Please confirm and re-enter the patient's infomation below to proceed."}
			{renderDoubleCheckFields}
		</div>
	);
}

function renderDeleteUserWarningDialog(component, person) {
	var state = component.state;
	var userId = person._id;
	var confirmDeleteActions = [(
		<Button key={"buttoncancel"} onClick={par(hideDeleteUserDialog, component)}>Cancel</Button >
	), (
		<Button key={"buttondelete"} onClick={par(state.handleAdminLoginAndUserDelete, component, userId)}>Delete</Button >
	)];
	return (
		<Dialog actions={confirmDeleteActions} open={!!state.isWarningDeleteUser} title={"User Deletion Warning"}>
			<p>
				{`User < ${person.lname}, ${person.fname} > will be deleted, all patient's data will be destroyed and cannot be recovered once deletion is succeed. Would you like to proceed?`}
			</p>
			<p>
				{"Please enter your current admin patient's login credential to proceed."}
			</p>
			<Divider />
			{renderLoginCredentialFields(component)}
		</Dialog>
	);
}

function renderPasswordResetWarningDialog(component, person) {
	var state = component.state;
	var userId = person._id;
	var done = false;
	if(state.newPassword) done = true;

	var cancelButton = done ? null : <Button key={"buttoncancel"} onClick={par(hidePasswordResetDialog, component)}>Cancel</Button >;
	var submitButton = done ? <Button key={"buttonreset"} onClick={par(hidePasswordResetDialog, component)}>Done</Button > : <Button key={"buttonreset"} onClick={par(state.handleAdminLoginAndPasswordReset, component, userId)}>Reset Password</Button >;
	var confirmPasswordResetActions = [cancelButton, submitButton];


	const copyToClipboard = () => {
		state.sendToast(component, "Password copied to clipboard!");
		global.navigator.clipboard.writeText(state.newPassword);
		component.state.userMetrics.trackEvent("admin-user-management: copy password to clipboard", {
			patient: userId
		});
	};

	return (
		<Dialog actions={confirmPasswordResetActions} open={!!state.isWarningPasswordReset} title={!done ? "Password Reset Warning" : "Password Reset New Password"}>
			{!state.newPassword ? (
				<div>
					<p>
						{`User ${person.lname}, ${person.fname} will have their password reset to a newly generated random password that can be used for account recovery. Make sure to provide the new password to the user. The users old password will be permanently lost, would you like to proceed?`}
					</p>
					<p>
						{"Please enter your current admin patient's login credential to proceed."}
					</p>
					<Divider />
					{renderLoginCredentialFields(component)}
				</div>
			) : (
				<div>
					<p>
						{`The password has been reset successfully for ${person.lname}, ${person.fname}. The newly created password is shown below.`}
					</p>
					<div>
						{"New Password: "}
						<b className="ae-selectable">{state.newPassword}</b>
						<IconButton className="fa fa-copy" onClick={copyToClipboard} />
					</div>
				</div>
			)}
		</Dialog>
	);
}

function renderLoginCredentialFields(component) {
	var state = component.state;
	return (
		<div>
			<div style={{ flex: 1, color: "red"}}>{state.loginErrors}</div>
			<div style={{ flex: 1 }}>
				<TextField
					autoFocus
					key="email"
					fullWidth
					label={"Email"}
					onChange={par(updateActionChanged, component, "adminLoginEmail")}
					type="email"
					style={styles.input}
					InputProps={styles.inputProp}
					required
				/>
				<TextField
					key="password"
					fullWidth
					label={"Password"}
					onChange={par(updateActionChanged, component, "adminLoginPassword")}
					type="password"
					style={styles.input}
					InputProps={styles.inputProp}
					required
				/>
			</div>
		</div>
	);
}

function renderTokenDialog(component) {
	var state = component.state;
	var buttonCancel = [
		<Button key={"buttoncancel"} onClick={() => {
			setVisibilityTokenDialog(component, false);
			component.state.userMetrics.trackEvent("admin-user-management: close view token popup", {
				token: state.token
			});
		}}>
			Cancel
		</Button>
	];
	return (
		<Dialog actions={buttonCancel} open={state.tokenModel} title={"User Token"}>
			<b className="ae-selectable">{`User Token: ${state.token}`}</b>
		</Dialog>
	);
}


function openEditPersonalInfoDialog(component) {
	component.state.userMetrics.trackEvent("admin-user-management: open edit personal info popup", {
		patient: component.state.currentPerson.get("personal")?._id
	});
	component.setState({isEditingPersonalInfo: true});
}

function setVisibilityTokenDialog(component, value) {
	component.setState({tokenModel: value});
}

function hideEditPersonalInfoDialog(component) {
	component.state.userMetrics.trackEvent("admin-user-management: close edit personal info popup");
	component.setState({
		isEditingPersonalInfo: null,
		new_fname: "",
		new_lname: "",
		new_type: "",
		new_language: "",
		new_measureunits: "",
		new_glucometerUnits: "",
		new_weightUnits: "",
		new_temperatureUnits: "",
		new_searchHidden: null
	});
}

function openDeleteUserDialog(component) {
	component.state.userMetrics.trackEvent("admin-user-management: open permanently delete user popup");
	component.setState({isDeletingUser: true});
}

function hideDeleteUserDialog(component) {
	component.state.userMetrics.trackEvent("admin-user-management: close permanently delete user popup");
	component.setState({
		isDeletingUser: null,
		gotEmailConfirm: false,
		doubleCheck_fname: "",
		doubleCheck_lname: "",
		doubleCheck_email: "",
		isWarningDeleteUser: null,
		dminLoginEmail: "",
		adminLoginPassword: "",
		loginErrors: ""
	});
}

function openPasswordResetDialog(component) {
	component.state.userMetrics.trackEvent("admin-user-management: open password reset popup", {
		patient: component.state.currentPerson.get("personal")?._id
	});
	component.setState({isResetingPassword: true});
}

function hidePasswordResetDialog(component) {
	component.state.userMetrics.trackEvent("admin-user-management: close password reset popup");
	component.setState({
		isResetingPassword: null,
		gotEmailConfirm_PWR: false,
		doubleCheck_fname: "",
		doubleCheck_lname: "",
		doubleCheck_email: "",
		isWarningPasswordReset: null,
		dminLoginEmail: "",
		adminLoginPassword: "",
		loginErrors: "",
		newPassword: null
	});
}

function openDeleteUserWarningDialog(component) {
	component.state.userMetrics.trackEvent("admin-user-management: open remove user warning popup", {
		patient: component.state.currentPerson.get("personal")?._id
	});
	component.setState({isWarningDeleteUser: true});
}
function openPasswordResetWarningDialog(component) {
	component.state.userMetrics.trackEvent("admin-user-management: open password reset warning popup", {
		patient: component.state.currentPerson.get("personal")?._id
	});
	component.setState({isWarningPasswordReset: true});
}

function openEditCustomPropertiesDialog(component) {
	component.state.userMetrics.trackEvent("admin-user-management: open edit custom properties popup", {
		patient: component.state.currentPerson.get("personal")?._id
	});
	component.setState({isEditingCustomProperties: true});
}

function hideEditCustomPropertiesDialog(component) {
	component.state.userMetrics.trackEvent("admin-user-management: close edit custom properties popup");
	component.setState({
		isEditingCustomProperties: null,
		new_customProperties: null
	});
}

function updateActionChanged(component, name, event) {
	event.persist();
	var value = event.target.value;
	var update = {};
	if (name === "new_fname" || name === "new_lname") {
        if (value.trim() === "") {
            update[name] = null;
        } else {
            update[name] = value;
        }
    } else {
        update[name] = value;
    }
	component.setState(update);
}

function updateObjectActionChanged(component, objectName, keyName, event) {
	var state = component.state;
	event.persist();
	var value = event.target.value;
	var updateObject = state[objectName] || {};
	updateObject[keyName] = value;
	var update = {};
	update[objectName] = updateObject;
	component.setState(update);
}

function updateActionChecked(component, name, event) {
	event.persist();
	var value = event.target.checked;
	var update = {};
	update[name] = value;
	component.setState(update);
}

function capitalizeFirstLetter(string) {
	if (string) return string.charAt(0).toUpperCase() + string.slice(1);
	return "";
}