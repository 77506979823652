import React from "react";
import { HealthTableHeader } from "./shared/HealthTableHeader";
import { HealthTableBody } from "./shared/HealthTableBody";

import Table from "@material-ui/1.5.1/Table";
import Utility from "../../shared/Utility";
import { useUserMetrics } from "@aetonix/user-metrics";

export function CustomIndicatorTabPanel({component, indicator}) {
	const state = component.state;
	const customIndicatorStores = component.state.customIndicatorStores;
	const localization = state.localization;
	const rowData = customIndicatorStores?.[indicator._id]?.sorted?.() ?? [];

	const { userMetrics } = useUserMetrics();

	const headers = [
		{ name: "value", content: indicator?.unit ?? localization.get("org_custom_indicators_value"), getData: (row) => row?.history?.value, getStatus: (row) => row?.history?.status },
		{ name: "time", content: localization.get("user_weight_history_time"), getData: (row) => Utility.timestamp(row?.created_at) }
	];

	const onClickRow = (row) => {
		component.setState({
			invalidRecord: row,
			recordInvalid: row?.invalid,
			recordNote: row?.notes
		});
		userMetrics.trackEvent("user-overview: open record notes popup");
	};

	return (
		<Table>
			<HealthTableHeader headers={headers} />
			<HealthTableBody headers={headers} rows={rowData} onClickRow={onClickRow} />
		</Table>
	);
}