/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
	* Unauthorized copying of this file, via any medium is strictly prohibited
	* Proprietary and confidential
	* Written by Aetonix, June 19, 2014
	* For information or permission request, email info@aetonixsystems.com
	*/

/**
 * This module keeps a list of all the 'core' forms.
 */

import Store from "./store";

import map from "fj-map";

export default CoreFormStore;

function CoreFormStore(api) {
	var store = new Store();

	reset();

	return store;

	function reset() {
		store.clear();
		return api.forms.list.core()
			.then(map(handle_create));
	}

	function handle_create(data) {
		var id = data.name;
		store.set(id, data);
	}
}
